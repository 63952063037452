import React                     from 'react'
import moment                    from 'moment'
import l_v 						 		from "store/languages.jsx"
import SL 								from 'sl-library'
import { Link }                  from 'react-router-dom'
import TimeLine 						from "react-time-line"
import TooltipWrapper            from '../component/TooltipWrapper.jsx'
import HintCalibrationInfo       from '../transportPage/HintCalibrationInfo.jsx'
import HintData                  from '../transportPage/HintData.jsx'
import ChangeRealSensor          from './ChangeRealSensor.jsx'
import ChangePeriodRealSensor    from './ChangePeriodRealSensor.jsx'
import ChangePeriodCalibration   from './ChangePeriodCalibration.jsx'
import AddCalibrationToSocket    from './AddCalibrationToSocket.jsx'
import InsertCalibrationToSocket from './InsertCalibrationToSocket.jsx'

export default class DetailSocketInfo extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			activeElInfo:        {},
			active_form_name:    null,
			selectedRealSensor:  null,
			selectedCalibration: null,
			defaultPeriod:       {from: null, to: null},
		}
	}
	componentDidMount() {
		if (!!this.props.info) {
			if (SL.arrayIsEmpty(this.props.info.realSensors)) {
				this.props.info.realSensors.map(key => {
					if (!!key.current) this.setState({ 
								selectedRealSensor: key, 
								activeElInfo: { //set by default real sensor in to view
									activeType: 'realSensor',
									activeId: key.id,
								} });
				})
			}
			if (SL.arrayIsEmpty(this.props.info.sensorCalibrationMappings)) {
				this.props.info.sensorCalibrationMappings.find(key => {
					if (!!key.current) this.setState({ selectedCalibration: key.id });
				})
			}
		}else {
			console.warn(`%c DetailSocketInfo this.props.info not object`, "color: pink; font-weight: bold; text-transform:uppercase", this.props.info)
		}
	}
	changeSelectedForm = ({ form_name, selectedRealSensor, selectedCalibration }) => {
		console.log(`%c selectedRealSensor, selectedCalibration-`, "color: green; font-weight: 400; text-transform:uppercase", selectedRealSensor, selectedCalibration)
		this.setState({
			selectedRealSensor:  selectedRealSensor,
			selectedCalibration: selectedCalibration,
			active_form_name: 	form_name,
			defaultPeriod: {
				from: SL.ifNoKey(selectedRealSensor, 'from'),
				to: 	SL.ifNoKey(selectedRealSensor, 'to'),
			}
		});

		// if (form_name == 'update_calibration_period') {
		// 	this.setState({
				
		// 		active_form_name:    form_name,
		// 		defaultPeriod: {
		// 			from: SL.ifNoKey(selectedCalibration, 'from'),
		// 			to: 	SL.ifNoKey(selectedCalibration, 'to'),
		// 		}
		// 	});
		// }
		// if (form_name == 'change_real_sensor' || form_name == 'change_period_real_sensor' || form_name == 'add_calibration') {

		// }
	}
	render(){
		const { info } = this.props;		
		const { activeElInfo, active_form_name, defaultPeriod, selectedRealSensor } = this.state;
		const itemProps = {
			onClick: activeElInfo => this.setState({
				activeElInfo: activeElInfo
			}),
			activeElInfo: activeElInfo
		};

		if(!!info){
			return 	<div className = 'detail_socket_info'>
						<p className = 'form_name'>{SL.doUperCase(l_v.socket)} - <b>{info.name}</b>, {SL.doUperCase(l_v.socket_id)} - <b>{info.id}</b></p>
						<div className = 'add_btns_block'>
							<button 	className = 'primari_btn'
										onClick = { () => this.changeSelectedForm({form_name: 'add_calibration', selectedRealSensor }) } >
										{l_v.add_calibration}
							</button>
							<button 	className = 'primari_btn'
										onClick = { () => this.changeSelectedForm({form_name: 'change_real_sensor', selectedRealSensor  }) } >		
										{l_v.change_real_sensor}
							</button>
						</div>
						<div className = 'mapping_info_container'>
									<div className = 'mapping_time_line_container'>
										<TimeLine items = { getTimeLineItems({ ...info, itemProps }) } format="DD-MM-YYYY HH:mm:ss:mm" />	
									</div>
									<div className = 'selected_mapping_container'>
									<AboutSensor 	activeId = 				{ activeElInfo.id }
														active_form_name = 	{ active_form_name }
														activeType = 			{ activeElInfo.type }
													 	info = 					{ info }
													 	changeSelectedForm = { this.changeSelectedForm }
													 	virtualSensors = 		{ this.props.virtualSensors } />

									<AboutCalibration activeId = 							{ activeElInfo.id }
															active_form_name = 				{ active_form_name }
															activeType = 						{ activeElInfo.type }
															info = 								{ info }
															changeSelectedForm = 			{ this.changeSelectedForm }
															removeCalibrationFromSocket = { this.props.removeCalibrationFromSocket }/>
									<SelectedForm 	socketId =     					{ info.id }
														active_form_name = 				{ active_form_name }
														info = 								{ activeElInfo }
														selectedRealSensor = 			{ this.state.selectedRealSensor }
														selectedCalibration = 			{ this.state.selectedCalibration }
														changeRealSensor =            { this.props.changeRealSensor }
														defaultPeriod = 					{ defaultPeriod }
														calibrations =                { this.props.calibrations }
														virtualSensors =              { this.props.virtualSensors }
														changeSensorPeriod =        	{ this.props.changeSensorPeriod }
														sensorInfo =                  { this.state.selectedCalibration }
														upMappingCalibrat =           { this.props.upMappingCalibrat }
														addCalibrationToSocket =    	{ this.props.addCalibrationToSocket }
														insertCalibrationToSocket = 	{ this.props.insertCalibrationToSocket } />
									</div>
						</div>
					</div>
		}else{
			return 	<div className = 'detail_socket_info'>
						{l_v.no_data}
						<Link className='link_to_device' to = 'https://www.youtube.com/watch?v=KvHU_3xg1sQ'>Послушай музыку</Link>
					</div>
		}
	}
}

function SelectedForm(props) {
	const {active_form_name} = props;
	let selectedForm = null;
	switch (active_form_name) {
		case 'change_real_sensor':
			selectedForm = <ChangeRealSensor 	previous =         { props.selectedRealSensor }
															defaultPeriod = 	 { props.defaultPeriod }
															info =             { props.info }
															changeRealSensor = { props.changeRealSensor }
															calibrations =     { props.calibrations }
															virtualSensors =   { props.virtualSensors } />
			break;
		case 'change_period_real_sensor':
			selectedForm = <ChangePeriodRealSensor sensorInfo = 			{ props.selectedRealSensor }
																defaultPeriod = 		{ props.defaultPeriod }
																changeSensorPeriod = { props.changeSensorPeriod }/>
			break;
		case 'update_calibration_period':
			selectedForm = <ChangePeriodCalibration 	sensorInfo =    { props.selectedCalibration }
																	defaultPeriod = { props.defaultPeriod}
																	socketId =      { props.socketId }
																	changePeriod =  { props.upMappingCalibrat }
			/>
			break;
		case 'add_calibration':
			selectedForm = <AddCalibrationToSocket addCalibrationToSocket = 	{ props.addCalibrationToSocket }
																defaultPeriod = 				{ props.defaultPeriod }
																socketId =     				{ props.socketId }
																info = 							{ props.selectedRealSensor }
																calibrations = 				{ props.calibrations }/>
			break;
		case 'insert_calibration':
			selectedForm = <InsertCalibrationToSocket insertCalibrationToSocket = 	{ props.insertCalibrationToSocket }
																	defaultPeriod =	 				{ props.defaultPeriod }
																	socketId =     					{ props.socketId }
																	info = 								{ props.selectedRealSensor }
																	calibrations = 					{ props.calibrations }/>
			break;
	}
	return selectedForm
}

function ItemInTimeLine(props) {
	const {onClick, type, activeElInfo, id} = props;
	let active = false;
	if(type == activeElInfo.type && activeElInfo.id == id){
		active = true
	}
	return 	<button 	className = 'btn_item_in_time_line' 
							style = 		{{ background: active ?'#54e1ad':'grey' }}
							onClick = 	{() => onClick({ id: id, type: type })}>
							{props.name}
				</button>
}

function AboutCalibration(props){
	const {activeId, info, changeSelectedForm, activeType, removeCalibrationFromSocket, active_form_name} = props;
	const getBtnStyle = btn_name => btn_name == active_form_name ? { backgroundColor: 'white', color: 'black', borderColor: 'black' } : null;	

	const calibrationInfo = info.sensorCalibrationMappings;
	const selectedCalibration = SL.arrayIsEmpty(calibrationInfo) ?  calibrationInfo.find(el => activeId == el.id) : null;
	return 	activeType == 'calibration' && selectedCalibration && activeId ?
					<div className = 'sensor_detail'>
						<div className = 'sensor_info'>
								<b>{l_v.calibration}</b>
								<p>{l_v.id_of_calibration}: {selectedCalibration.sensorCalibrationName}</p>
								<p>Используется: {!!selectedCalibration.current?l_v.yes: l_v.no}</p>
								<p>{l_v.montage_date}: {moment(selectedCalibration.from).format('DD.MM.YYYY - HH:mm:ss')}</p>
								<p>{l_v.demontage_date}: {!!selectedCalibration.to?moment(selectedCalibration.to).format('DD.MM.YYYY - HH:mm:ss'):l_v.no_data}</p>
								<p>{l_v.id_mapping_calibration}: {selectedCalibration.id}</p>
								<p>{l_v.id_of_calibration}: {selectedCalibration.sensorCalibrationId}</p>
						</div>
						<div className = "btns_block_in_sensor_info">
							<button 	className = 'primari_btn'  
										style = 		{ getBtnStyle('update_calibration_period') }
										onClick = 	{ () => changeSelectedForm({ form_name: 'update_calibration_period', selectedCalibration })}>
									{l_v.change_mapping_period}
							</button>
							<button 	className = 'primari_btn'  
										onClick = 	{ () => removeCalibrationFromSocket(info.id, activeId)}>
									{l_v.remove_mapping_calibration}
							</button>
						</div>
					</div> :
					null
}

function AboutSensor(props){
	const {activeId, info, changeSelectedForm, virtualSensors, activeType, active_form_name} = props;
	const activeSensor = findActiveRealSensor(info.realSensors, activeId);
	const getBtnStyle = btn_name => btn_name == active_form_name ? { backgroundColor: 'white', color: 'black', borderColor: 'black' } : null;	
	return 	activeType == 'realSensor' && activeSensor && activeId ?
				<div className = 'sensor_detail'>
					<div className = 'sensor_info'>
							<b>{ l_v.real_sensor }</b>
							<p>{ l_v.used }: { activeSensor.current ? l_v.yes : l_v.no }</p>
							<p>{ l_v.montage_date }: {moment(activeSensor.from).format('DD.MM.YYYY - HH:mm:ss')}</p>
							<p>{ l_v.demontage_date }: {!!activeSensor.to?moment(activeSensor.to).format('DD.MM.YYYY - HH:mm:ss'):l_v.no_data}</p>
							<p>Id реального сенсора: {activeSensor.realSensorId}</p>
							<p>{l_v.socket_id}: {activeSensor.realSensorSocketId}</p>
							<p>{ l_v.sensor_number } : {activeSensor.sensorNumber}</p>
							<p>{ l_v.virtual_sensor_id}: {activeSensor.virtualSensorId}</p>
							<p>{ l_v.data_revers} : {activeSensor.reverse?l_v.yes:l_v.no}</p>
							<p>{ l_v.start_from_zero }: {activeSensor.fromZero?l_v.yes:l_v.no}</p>
					</div>
					<div className = "btns_block_in_sensor_info">
						<button 	className = 'primari_btn'
									style = 		{ getBtnStyle('change_real_sensor') }
									onClick = 	{ () => changeSelectedForm({ form_name: 'change_real_sensor', selectedRealSensor: activeSensor }) } >								
								{l_v.change_real_sensor}
						</button>
						<button 	className = 'primari_btn'
									style = 		{ getBtnStyle('change_period_real_sensor') }
									onClick = 	{ () => changeSelectedForm({ form_name: 'change_period_real_sensor', selectedRealSensor: activeSensor }) }>
								{l_v.update_real_sensor_period}
						</button>
						<button 	className = 'primari_btn'
									style = 		{ getBtnStyle('add_calibration') }
									onClick = 	{ () => changeSelectedForm({form_name: 'add_calibration', selectedRealSensor: activeSensor}) }>
								{l_v.add_calibration}
						</button>
						<button 	className = 'primari_btn'
									style = 		{ getBtnStyle('insert_calibration') }
									onClick = 	{ () => changeSelectedForm({form_name: 'insert_calibration', selectedRealSensor: activeSensor}) }>
								{l_v.insert_calibration}
						</button>
					</div>
				</div> :
				null
}

function findActiveRealSensor(realSensors, activeId) {
	if(SL.arrayIsEmpty(realSensors)){
		return realSensors.find(el=>{
			if(activeId == el.realSensorId){
				return el
			}
		})
	}
}

function getTimeLineItems ({ realSensors, sensorCalibrationMappings, itemProps }) {
	const items = [];
	realSensors.forEach(key => {
		items.push({ ts: key.from, text: <ItemInTimeLine 	name = {`RealSensor - ${key.realSensorId} start mapping`} 
															           	type = 'realSensor'
		           														{...key}
		           														id = {key.realSensorId} 
		           														{...itemProps}/> });
		if (key.to) {
			items.push({ ts: key.to, text: <ItemInTimeLine 	name = {`RealSensor - ${key.realSensorId} end mapping`} 
																         type = 'realSensor'
																         id = 	{key.realSensorId}
			           													{...key} 
			           													{...itemProps}/>  })
		}
	});
	sensorCalibrationMappings.forEach(key => {
		items.push({ ts: key.from, text: <ItemInTimeLine 	name = {`Calibration - ${key.sensorCalibrationName} start mapping`}
																			type = 'calibration' 
		           														{...key} 
		           														id = {key.id}
		           														{...itemProps}/> });
		if (key.to) {
			items.push({ ts: key.to, text: <ItemInTimeLine 	name = {`Calibration - ${key.sensorCalibrationName} end mapping`} 
			           													type = 'calibration'
			           													{...key} 
			           													id = 	{key.id}
			           													{...itemProps}/> });
		}
	})
	return items.sort((a, b) => new Date(a.ts) - new Date(b.ts));
}
