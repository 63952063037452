import React, { Component } from 'react';
import moment               from 'moment'
import { ifNoKey, arrayIsEmpty }           from 'actions/ActionCreator.jsx'
import l_v 				from "store/languages.jsx"


export default class LogTable extends Component {


	render() {
        const {height, list} = this.props;
        const width = window.innerWidth - 50;
		return (
			<div className = 'logTable'>
				<TableHeader width = {width}/>
				<ViewTableContent {...this.props} width = {width}/>
			</div>
		);
	}
}
const exampleData = {
	dateTime: "2020-06-02T07:44:36.839Z",
	description: "User: test25 has been deleted by user: hydra",
	id: 33868,
	operation: "delete",
	performer: "hydra",
	subject: "user",
	subjectId: "test25",
	userActionIp: "195.138.67.134",
}

function TableHeader(props) {

	return <div className = 'head_line oneLine'>
				<div 	className = 'cell'
				        data-key =   "dateTime"
				        style = {{width: getWidth(props.width).dateTime}} >
				        { l_v.time }
		        </div>
		        <div 	className = 'cell'
		                data-key =       "description"
		        		style = {{width: getWidth(props.width).description}} >
		                { l_v.description }
		        </div>
				<div 	className = 'cell'
				        data-key =   "id"
				        style = {{width: getWidth(props.width).id}}>
				        Id
				</div>
				<div 	className = 'cell'
				        data-key =   "operation"
				        style = {{width: getWidth(props.width).operation}}>
				        { l_v.time }
				</div>
				<div 	className = 'cell'
				        data-key =   "performer"
				        style = {{width: getWidth(props.width).performer}}>
				        { l_v.perfomer }
				</div>
				<div 	className = 'cell'
				        data-key =   "subject"
				        style = {{width: getWidth(props.width).subject}}>
				        { l_v.subject}
				</div>
				<div 	className = 'cell'
				        data-key =   "subjectId"
				        style = {{width: getWidth(props.width).subjectId}}>
				       { l_v.subject_id}
				</div>
				<div 	className = 'cell'
				        data-key =   "ip"
				        style = {{width: getWidth(props.width).userActionIp}}>
				       IP
				</div>
			</div>
}

function ViewTableContent(props) {
	return 	arrayIsEmpty(props.list) ?
			props.list.map(key => <OneLine content = {key} key={key.id} width = {props.width}/>) :
			<div className = 'no_data' style = {{textAlign: 'center'}}>{l_v.no_data}</div>
}

function OneLine(props){
	const arrayOfKeyNames = Object.keys(props.content);
	return 	<div className = 'oneLine'>
				<Cell 	content = {props.content.dateTime}
         				name = 'dateTime'
         				key = 'dateTime'
         				width = {props.width} />
         		<Cell 	content = {props.content['description']}
         				name = {'description'}
         				key = {'description'}
         				width = {props.width} />
         		<Cell 	content = {props.content['id']}
         				name = {'id'}
         				key = {'id'}
         				width = {props.width} />
         		<Cell 	content = {props.content['operation']}
         				name = {'operation'}
         				key = {'operation'}
         				width = {props.width} />
         		<Cell 	content = {props.content['performer']}
         				name = {'performer'}
         				key = {'performer'}
         				width = {props.width} />
         		<Cell 	content = {props.content['subject']}
         				name = {'subject'}
         				key = {'subject'}
         				width = {props.width} />
         		<Cell 	content = {props.content['subjectId']}
         				name = {'subject'}
         				key = {'subjectId'}
         				width = {props.width} />
         		<Cell 	content = {props.content['userActionIp']}
         				name = {'userActionIp'}
         				key = {'userActionIp'}
         				width = {props.width} />

			</div>
}

function Cell(props) {
	const content = props.name == "dateTime" ?
					moment(props.content).format('DD.MM.YYYY - HH:mm:ss') :
					props.content ;

	return <div className = 'cell'
				data-key = {props.name}
				title = {content}
				style = {{
					width: getWidth(props.width)[props.name],
					whiteSpace: 'normal'
				}}>
                     {content}
                </div>

}

function getWidth(widthAll){
        let id = 60, operation = 125, dateTime = 200, ip = 150;
        const leftWidth = widthAll - id - (operation*4) - dateTime - ip;
        return {
            id: id + 'px',
            operation: operation + 'px',
            dateTime: dateTime + 'px',
            description:  leftWidth+ 'px',
            performer: operation + 'px',
            subject: operation,
            subjectId: operation,
            userActionIp: ip
        }
    }

// _contentRenderer = ({dataKey, parent, rowIndex}) =>{
//         const {list} = this.props;
//         const datum = list.get(rowIndex % list.size);
//         let content;
//         switch(dataKey){
//             case 'dateTime':
//                 content = moment(datum[dataKey]).format('DD.MM.YYYY - HH:mm:ss')
//             break;
//             default:
//                 content = datum[dataKey]
//             break;
//         }
//         return <div className = 'tableColumn'
//                     style = {{
//                                 whiteSpace: 'normal',
//                               }}>
//                     {content}
//                 </div>
//     }
//     _columnCellRenderer = ({dataKey, parent, rowIndex}) => {
//         const {list} = this.props;
//         const datum = list.get(rowIndex % list.size);
//         const content = doNice(datum.description);
//         return (
//             <CellMeasurer   cache =         {this._cache}
//                             columnIndex =   {0}
//                             key =           {dataKey}
//                             parent =        {parent}
//                             rowIndex =      {rowIndex}>
//                 <div    className = 'tableColumn'
//                         dangerouslySetInnerHTML = {{__html: content}}
//                         style = {{
//                                     whiteSpace: 'normal',
//                                 }}>

//                 </div>
//           </CellMeasurer>
//         );
//     };