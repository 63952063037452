import React, { Fragment, useState, Component } from 'react'
import { Link }                                 from 'react-router-dom'
import { connect }                              from 'react-redux'

import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react";
import SL                                       from 'sl-library'
import _                                        from 'lodash'
import Navigation                               from '../modules/AdminNavigation.jsx'
import CommandToDevice                          from './devicePage/CommandToDevice.jsx'
import WebsocketActions                         from 'actions/Websocket.js'
import deviceOnTransportApi                     from 'actions/deviceOnTransportApi.js'
import devicesApi                               from 'actions/devicesApi.js'
import { editImg }                              from './img.jsx'
import Pop_up                                   from './component/Pop_up.jsx'
import moment                                   from 'moment'
import SeparPermission, { permissionsFoRequst } from 'modules/component/SeparPermission.jsx'
import ReRequestData                            from 'modules/component/ReRequestData.jsx'
import Phone, {
	formatPhoneNumber,
	parsePhoneNumber,
	isValidPhoneNumber
}                                               from 'react-phone-number-input'
import SwitchInput                              from 'modules/component/SwitchInput.jsx'
import l_v                                      from 'store/languages.jsx'
import Preloader                                from './preloader.jsx'
import Helmet                                   from 'react-helmet';
import Users                                    from 'actions/users.js'
import { HintUsers }                            from './transportPage/HintData.jsx'
import SimpleSelectList                         from 'modules/component/SimpleSelectList.jsx'
import ConfirmRemove                            from './ConfirmRemove.jsx'
import BtnAddDevice, 
{EditIgnitionNavitrackForm}                     from './component/BtnAddDevice.jsx'
import {getDeviceColor} 								from "modules/transportPage/CurrentDeviceInfo.jsx"
import 'react-phone-number-input/style.css'


class DevicePage extends React.Component {
	constructor(props) {
		super(props);
		const { commmands_history } = this.props;		const { device_id } = this.props.match.params;
		
		this.state = {
			commmands_history:     commmands_history,
			serialNumber:          device_id,
			deviceAutorizCode:     null,
			comment:               null,
			imei:                  '',
			deviceType:            null,
			owner:                 null,
			lastKnownTime:         null,
			creator:               null,
			vehicle:               null,
			softwareVersion:       null,
			hardwareVersion:       null,
			simNumber:             null,
			simUpdatingDate:       null,
			simBelongsToOtherUser: false,
			
			deviceStatus:          null,
			ignored_status:        null,
			mappingList:           null,
		};
	}
	static getDerivedStateFromProps(nextProps, prevState){
		//regId, deviceId, serialNumber is the same identifier but have different names, may it's not all))
		//device_id it's my name of it in url params )

		const oldHistory = prevState.commmands_history;
		const { deviceAnswer, newDeviceConnectedStatus, newSW_and_HW_versions, stateFromSocket } = nextProps;
		const { serialNumber, deviceId, regId, commands_history, deviceStatus, authCode } = deviceAnswer;
		const currentSerialNumber = prevState.serialNumber;
		const serialNumberFromMessage = serialNumber || deviceId || regId;
		const newState = {
				...prevState, 
				...nextProps, 
		};
		
		if(deviceAnswer) newState.deviceAnswer = deviceAnswer;


		if(currentSerialNumber == serialNumberFromMessage){
			if(authCode) newState.deviceAutorizCode = authCode;
		}

		if(newDeviceConnectedStatus && newDeviceConnectedStatus.deviceId == currentSerialNumber){
			newState.deviceStatus = newDeviceConnectedStatus.deviceStatus
		}

		if(newSW_and_HW_versions && newSW_and_HW_versions.deviceId == currentSerialNumber){
			Object.assign(newState, newSW_and_HW_versions)
		}

		return newState
	}
	componentWillUnmount() {
		WebsocketActions.clearHistory()
	}
	componentDidMount() {
		
		const { device_id } = this.props.match.params;
		this.props.dispatch({//register d
			type: 'CURRENT_DEVICE_SERIAL',
			saveSerial: device_id
		});
		console.log(`%c COMPONENTDIDMOUNT DevicePage, `, "color: blue; font-weight: 400; text-transform:uppercase", device_id, this.props)
		const getDeviceInfo = () => {
			deviceOnTransportApi.getDeviceInfo(device_id, newDeviceInfo => {
				const {deviceType, vehicle} = newDeviceInfo;
				this.setState({ ...newDeviceInfo, accountType: getAccountType() });
				this.initialDeviceState = SL.copyOfObject(newDeviceInfo);
				//only GLOBUS devices have auth code
				if(deviceType == 'GLOBUS') deviceOnTransportApi.getAuthorizationCode(device_id, (answerJSON) => this.setState({ deviceAutorizCode: answerJSON.authorizationCode }));
				function getAccountType() {
					const typeInstallation = SL.ifNoKey(newDeviceInfo.vehicle, 'type');
					if(typeInstallation) return typeInstallation == 'GAS_STATION' ? 'TERES' : 'GLOBUS';
					return null
				}
			})
		};
		const getIgnoredStatus = () => devicesApi.getIgnoredDevice(device_id, (xzLIst) => {
			if(SL.arrayIsEmpty(xzLIst)) this.idInIgnoredDeviceList = xzLIst[0].id;
			this.setState({
				ignored_status: !!this.idInIgnoredDeviceList
			});
		});
		getDeviceInfo();
		permissionsFoRequst('SUPERADMIN', null, getIgnoredStatus);//get ignored status if have permission
		// devicesApi.getDeviceHistory(device_id, mappingList => this.setState({ mappingList: mappingList }));//list of vehicles what used device
	}
	updateStatusSIM = (value) => {
		let afterUpdateComment = (newDeviceInfo) => {
			let { simBelongsToOtherUser } = newDeviceInfo;
			this.initialDeviceState = newDeviceInfo;
			this.setState({
				simBelongsToOtherUser: simBelongsToOtherUser
			});
		}
		let currentState = SL.copyOfObject(this.initialDeviceState);
		currentState.simBelongsToOtherUser = value;
		devicesApi.updateDevice(currentState, afterUpdateComment)
	}
	updateIgnoredStatus = (newStatus) => {
		newStatus ?
			devicesApi.addToIgnoredDevices([this.props.match.params.device_id], (xzLIst) => {
				this.setState({ ignored_status: true });
				this.idInIgnoredDeviceList = xzLIst[0].id
			}) :
			devicesApi.removeFromIgnoredDevices(this.idInIgnoredDeviceList, () => this.setState({ ignored_status: false }));
	}
	addTextToInput = (e) => {
		const commandText = e.target.textContent || e.target.innerText;
		this.commandsComponent.updateEnteredCommand(commandText);
		
	}
	deleteDevice = () => {
		devicesApi.deleteDeviceFromServer(this.state.serialNumber, () => window.location.hash = 'devices')
	}
	callDeleteDevice = () => this.confirmDelete.open()
	updateDevice = (deviceDto) => {
		const { deviceType } = deviceDto.dataForSend;
		const initialDeviceState = SL.copyOfObject(this.initialDeviceState)
		devicesApi.updateDevice(
			Object.assign(initialDeviceState, deviceDto.dataForSend),
			(newDeviceDto) => this.setState({ ...newDeviceDto })
		)
	}
	render() {
		let {
				softwareVersion,
				hardwareVersion,
				imei, temp_imei,
				deviceType,
				vehicle, accountType,
				deviceAutorizCode,
				commmands_history, trash, owner,
				status_command, simBelongsToOtherUser,
				lastCommand, simNumber, deviceStatus,
				deviceAnswer, lastKnownTime, tempPhone, simUpdatingDate,
				imeiWasChaged, ignored_status, mappingList
			} = this.state;
		const { device_id } = this.props.match.params;
		const { comment } = this.state;
		tempPhone = tempPhone ? tempPhone : simNumber;
		const ownerInfo = owner ?
			<Link 	to = {`/client/${owner}/edit`}
					className = 'link_to_owner_to_on_device_page'>
        			{owner}
        	</Link> :
			l_v.not_specified;
		return (
		  <div className = 'device_page'>
		  <Helmet title = { `${l_v.device} № ${device_id}`} />
		  <SeparPermission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
		  					kindCapability = 	'editDevices'
							type =              'page'/>
		  	<Navigation />
		  	<div className='container content device_page'>
		      	<Link 	to=			"/devices"
		      				className = 'go_back'>{l_v.come_back}</Link>
		        	<h2 className =	"page_name_client">
		        	{l_v.device} {device_id}
		        	<BtnAddDevice 	btnname = {editImg("callEdit ")}
			        					itsEditingDevice = {true}
			        					getEditedDevice = {()=>this.state}
										dataFromform = {this.updateDevice} />
									
		        	</h2>
		        {
		        	trash ?
		        	<button className = 'btn_black delete_device_btn'
		        			name = 'deleteDevice'
		        			onClick = {this.callDeleteDevice}>
		        			{l_v.delete_device_from_server}
		        	</button> :
		        	null
		        }
		        <div className = 'device_info'>
			        <div className = 'name_client'>
			        	
			        	<span style = {{display: 'block'}}>{l_v.owner}: { ownerInfo } < /span> 
			        	<LinkToClient 	vehicleOwner = {SL.ifNoKey(vehicle, 'owner')}
			        					accountType = {accountType}/>
			        	<LinkToVehicle vehicle = {vehicle}/>
			        </div>
			        <DeviceInfo 	softwareVersion =       {softwareVersion}
					        			hardwareVersion =       {hardwareVersion}
					        			trash =                 {trash}
					        			imei =                  {imei}
					        			deviceAutorizCode =     {deviceAutorizCode}
					        			deviceType =            {deviceType}
					        			comment =               {comment}
					        			simNumber =             {simNumber}
					        			deviceStatus =          {deviceStatus}
					        			simUpdatingDate =       {simUpdatingDate}
					        			lastKnownTime =         {lastKnownTime}
					        			simBelongsToOtherUser = {simBelongsToOtherUser}
					        			updateIgnoredStatus =   {this.updateIgnoredStatus}
					        			ignored_status =        {ignored_status}
					        			updateStatusSIM =       {this.updateStatusSIM}/>
			        <button name = 		"disconect"
							className =	"btn_black addsensor"
							onClick = 	{() => devicesApi.disconectDevice(device_id)}>
							{l_v.disconnect}
						</button>
						{
							deviceType == 'NAVITRACK' ? 
							<EditIgnitionNavitrackForm device_id = {device_id} />	 :
							null
						}
						
			    </div>
			    <div className = 'right_part_on_device_page'>
    		        <ReRequestData serial = {device_id} />
    		        <MappingList mappingList = {mappingList} />
			    </div>
		        <CommandToDevice 	deviceSerial = {device_id}
		        					ref = { commandsComponent => this.commandsComponent = commandsComponent}/>

		        <h2 className = 'sub_name'>{l_v.command_history}:</h2>
					<HistoryOfComand 	/*commmands_history = { commmands_history }*/
											addTextToInput = 	{ this.addTextToInput } />
            	<ConfirmRemove ref = 			{confirmDelete => this.confirmDelete = confirmDelete}
					            	height = 		'225px'
	            					confirmText = 	{l_v.confirm_text_remove_device }
	            					clear = 		{()=>null}
	            					remove = 		{this.deleteDevice}/>
            	<Preloader ref = {preloader => this.preloader = preloader}/>
		    </div>
		  </div>
		)
	}
}

export default connect(
		state => ({
			token:                    state.authorizationR.token,
			thisKindUser:             state.authorizationR.kindUser,
			userPremission:           state.authorizationR.user_permission,
			deviceAnswer:             state.devicesR.deviceAnswer,
			newDeviceConnectedStatus: state.devicesR.newDeviceConnectedStatus,
			newSW_and_HW_versions: 	  state.devicesR.newSW_and_HW_versions,
			stateFromSocket:			  state.devicesR.stateFromSocket,
		})
)(DevicePage);

const HistoryOfComand = connect(
	state => ({
		commmands_history: state.devicesR.commands_history,
	})
)(HistoryOfComandView);


function MappingList(props) {
	const {mappingList} = props;
	return  <div className = 'vehicle_mapping_list'>
				<p>{l_v.mapping_history}</p>
				{
					mappingList ?
					mappingList.map(key =>
					    <div className = 'one_mapped_vehicle' key = {key.controlEntityId + key.from}>
			                <p> <LinkToVehicleFromMapping {...key}/> (<LinkToClient vehicleOwner = {key.login}/>) </p>
			                <p>
			                	<span className = 'grey_text'>
			                		{moment(key.from).format('hh:mm DD.MM.YYYY')} - {key.to ? moment(key.to).format('hh:mm DD.MM.YYYY') : '----'}
			                	</span>
			                </p>
					    </div>
					) :
					null
				}
			</div>
}

function LinkToVehicleFromMapping(props) {
	const {
		login,
		controlEntityName,
		licensePlate,
		controlEntityId,
		controlEntityType
	} = props;
	const lastPart = controlEntityType == 'VEHICLE' ? 'editVehicle' : 'editGasStation';
	return 	<Link to = {'/transport/'+ login + '/' + controlEntityName + '/' + controlEntityId + '/' + lastPart}>
        		{licensePlate} {controlEntityName}
        	</Link>
}

const listOfDevicesType = 	[
	{id: "GLOBUS", name: "GLOBUS", value: "GLOBUS"},
	{id: "TELTONIKA", name: "TELTONIKA", value: "TELTONIKA"},
	{id: "TESWELL", name: "TESWELL", value: "TESWELL"},
	{name:'NAVITRACK', value: "NAVITRACK", id: "NAVITRACK" },
	{name:'TAG PRO', value: "TAG_PRO", id: "TAG_PRO" }
];

function AlienSIM(props){
	const {simBelongsToOtherUser, updateStatusSIM} = props;
	return <div className='counter_switch'>
				<p className='text_info'>{l_v.clients_sim}:</p>
				<SwitchInput 	    result = 	{updateStatusSIM}
									isChecked = {simBelongsToOtherUser}
									name =		'counter_change_oil'
									tooltip =	{{on: l_v.yes, off: l_v.no}}/>
			</div>
}

function LinkToVehicle(props){
	let {vehicle} = props;
	return 	vehicle ?
			<Link to = {`/transport/${vehicle.owner}/${vehicle.name}/${vehicle.vehicleId}/editVehicle`}>
    			{l_v.transports}: {vehicle.name} {vehicle.licensePlate}
    		</Link>:
    		<span>{l_v.not_mounted_on_vehicle}</span>
}

function LinkToClient(props){
	let {userName, accountType, vehicleOwner, callEditOwnerForm} = props;
	return 	vehicleOwner ?
			<Fragment>
				<Link 	to =		{"/client/"+ vehicleOwner +"/edit"}
						className = 'link_to_owner_to_on_device_page'>
	        		{l_v.account}: {vehicleOwner}
	        	</Link>
	        </Fragment> :
	        <span>{l_v.account} {l_v.not_specified}  - </span>
}

function DeviceInfo(props){
	const {softwareVersion, trash, hardwareVersion, imei, deviceAutorizCode, showFormEditPhone, simBelongsToOtherUser, deviceStatus,
		updateStatusSIM, deviceType, comment, showFormEditComment, lastKnownTime, simNumber, simUpdatingDate,
		showFormEditDeviceType, updateIgnoredStatus, ignored_status
	} = props;
	const device_status_text = deviceStatus ? textOfDeviceStatus(deviceStatus, trash) : '---';
	return 	<Fragment>
				<p style = {{color: 'blue'}}><span className = 'line_name'>{l_v.data_on_server}:</span> <b>{toDate(lastKnownTime)}</b></p>
				<p ><span className = 'line_name'>{l_v.protocol}:</span> <b style = {{
									backgroundColor: getDeviceColor(deviceType),
									color: 'white',
									padding: '3px 5px', fontWeight: '500'
								}}>{deviceType?deviceType:'---'}</b></p>
				<p><span className = 'line_name'>SW:</span> <b>{!!softwareVersion?softwareVersion:'---'}</b></p>
				<p><span className = 'line_name'>HW:</span> <b>{!!hardwareVersion?hardwareVersion:'---'}</b></p>
				<p><span className = 'line_name'>IMEI:</span> <b>{imei ? imei : '---'}</b></p>
				<p><span className = 'line_name'>{l_v.authorisation_code}:</span> <b> {deviceAutorizCode?deviceAutorizCode:'---'}</b></p>
				<p><span className = 'line_name'>{l_v.device_status}:</span> <b style = {styleByStatus(deviceStatus)}>{device_status_text}</b></p>
				<p><span className = 'line_name'>{l_v.sim_number}:</span> <b>{simNumber?simNumber:'---'}</b> </p>
				<p><span className = 'line_name'>{l_v.sim_number_updated}:</span> <b>{toDate(simUpdatingDate)}</b></p>
				<p> <span className = 'line_name'>{l_v.comment}:</span> <b>{(!!comment)?comment:'---'}</b> </p>
				<AlienSIM  	simBelongsToOtherUser = {simBelongsToOtherUser}
								updateStatusSIM = {updateStatusSIM}/>
				<SeparPermission alowed = 'SUPERADMIN'>
					<IgnoredStatus 	updateIgnoredStatus = {updateIgnoredStatus}
											ignored_status = {ignored_status}/>
				</SeparPermission>
			</Fragment>
}

function IgnoredStatus(props){
	const {updateIgnoredStatus, ignored_status} = props;
	return <div className='counter_switch'>
				<p className='text_info'>{l_v.ignore_while_cleaning}:</p>
				<SwitchInput 	result =    { updateIgnoredStatus }
								isChecked = { ignored_status }
								name =      'ignored_status'
								tooltip =   { { on: l_v.on, off: l_v.off } }/>
			</div>
}
//lastCommand can be answer on command and answer about receiving command



function HistoryOfComandView(props){
	const {commmands_history, addTextToInput} = props;
	return <ul className = "command_history">
				{
					SL.arrayIsEmpty(commmands_history) ?					
					commmands_history.map((key, index) => 
                           <li key = {index} data-name = 'key.messageId'>
								<span style = {{ color: 'green' }}>
									{l_v.name} 	
									<b onClick = {addTextToInput} className='command_name'> <CommandText command_text = {key.commandText} /> </b>
								</span>
								<span style = {{ color: 'grey' }}>
									{l_v.recieved}: <b> {toDate(key.receivedTimestamp)} </b> 
								</span>
								<span style = {{ color: 'cadetblue' }}>
									{l_v.answer_time}: <b> {toDate(SL.ifNoKey(key, 'responseTimestamp'))} </b> 
								</span>
								<span style = {{ color: 'black' }}>
									{l_v.answer}: <b> <AnswerInCommandsList  {...key} /> </b> 
								</span>						
							</li>
					) :					
					<li> {l_v.empty_history} </li>
				}
			</ul>
}



function CommandText(props){
	return typeof props.command_text === 'string' && props.command_text.length ? props.command_text : l_v.nodata
}


function AnswerInCommandsList(props) {	
	let responseText = SL.ifNoKey(props, 'responseText');
	if(responseText){
		responseText = responseText.replaceAll('\r', '');
		const manyLinesAnswer = responseText.split('\n');
		if(manyLinesAnswer.length > 0){
			return manyLinesAnswer.map((lineContent, index) => <p key = {index} className = 'answer_line'>{lineContent}</p>)
		}
		return responseText
	}else{
		return l_v.nodata
	}
}

	// function sortByDate(commmands_history) {
	// 	if(SL.arrayIsEmpty(commmands_history)){
	// 		commmands_history.sort((elOne, elTwo)=>{
	// 			if( new Date(elOne.receivedTimestamp) > new Date(elTwo.receivedTimestamp) ){
	// 				return -1
	// 			}else{
	// 				return 1
	// 			}
	// 		})
	// 	}
	// }

function toDate(string_UTC_Date){
	return string_UTC_Date ? moment(string_UTC_Date).format("Do MMM YY,  HH:mm:ss") : l_v.no_data
}

export function styleByStatus(status) {
	if(status == 'CONNECTED'){
		return {color: 'green'}
	}
	if(status == 'DISCONNECTED'){
		return {color: 'red'}
	}
	else{
		return {color: 'grey'}
	}
}

function getIdInIgnoredDeviceList(ignoredDevicesList, deviceSerial) {
	return ignoredDevicesList.find(el => el.deviceId == deviceSerial)
}

export function textOfDeviceStatus(status, inTrash) {
	let textStatus;
	if(!inTrash){
		if(status == 'NO_DATA') { textStatus = l_v.was_never_connected }
		if(status == 'CONNECTED') { textStatus = l_v.in_touch }
		if(status == 'DISCONNECTED') { textStatus = l_v.out_of_touch }
	}else{
		textStatus = l_v.in_the_trash_can
	}

	return textStatus
}