import React                     from 'react'
import { connect }               from 'react-redux'
import { bindActionCreators }    from 'redux'
import WebsocketActions          from '../actions/Websocket.js'
import store                     from 'store/configureStore.jsx'
import { ifNoKey, IsJsonString } from '../actions/ActionCreator.jsx'
import { setText }               from '../actions/bugReport.jsx'
import devicesApi                from 'actions/devicesApi.js'
import FormAction                from 'actions/formAction.js'
import l_v                       from "store/languages.jsx"

window.devicesApi = devicesApi;
window.testDispatch = (answer) => 	store.dispatch({
				type: 		"ANSWER_DEVICE",
				setAnswer: 	answer
			})

function instructionOnRecieveMessage(e) {
	// DEV_SERVER ? console.log("%c WEBSOCKET received message -", coCSS, e) : null
	if(!!e){
	  	let answer = JSON.parse(e.data);
	  	const { name, action } = answer;
	  	if(answer.name == 'Device_Disconnect'){//need remove on refactoring device and transport page complete
	  		devicesApi.setNewConnectStatus({
	  			deviceStatus: 	'DISCONNECTED',
	  			deviceId: 		answer.deviceId
	  		})
	  		return;
	  	}
	  	if(answer.name == 'Device_Connected'){//need remove on refactoring device and transport page complete
	  		devicesApi.setNewConnectStatus({
	  			deviceStatus: 'CONNECTED',
	  			deviceId: 	  answer.deviceId
	  		})
	  		return;
	  	}
		if(answer.name == 'commandsHistoryList' && !!answer.commandsHistory ){
			store.dispatch({
				type: 		'COMMAND_HISTORY',
				setHistory:  answer.commandsHistory
			})
			return;
		}
		if(answer.name == 'receivedCommand' ) {//&& action == 'executeStringCommand' || action == 'getDeviceCommandsHistory'
			if(action == 'getDeviceCommandsHistory') answer.commandString = 'getDeviceCommandsHistory (клик по кнопке)';
			store.dispatch({
				type: 			'LAST_COMMANDS',
				setLastComm:  	answer
			})
			return;
		}
		if(name == "deviceAnswer" || //can be answer to command or new state, or other droch
		   	name == 'SwAndHwMessage' || //new state for device info
		   		name == 'SaveAuthCode' || //new state for device info
		   				name == 'Device_Connected' || //new state for device info
		   					name == 'Device_Disconnect' ){ //new state for device info
			//'SwAndHwMessage' DTO {"deviceId":71359,"hardwareVersion":"2","softwareVersion":"2.0.120","name":"SwAndHwMessage"}
			//Device_Connected {"deviceId":71359,"name":"Device_Connected"} || {"deviceId":71359,"name":"Device_Disconnect"}
			if(name == 'Device_Connected') answer.deviceStatus = 'CONNECTED'; //new state for device info
			if(name == 'Device_Disconnect') answer.deviceStatus = 'DISCONNECTED'; //new state for device info1	``
			store.dispatch({
				type: 		"ANSWER_DEVICE",
				setAnswer: 	answer
			})
			return;
		}
		if (answer.name == 'LockStateForDevice'){//new state for device info
			store.dispatch({
				type: 'LOCKSTATE',
				setLockState: answer
			})
			return
	   	}
	   	if(!answer.name && !!answer.serialNumber){//new state for device/vehicle or device info 
	   		store.dispatch({
					type: 'NEWSTATE',
					updateNewState: answer
				})
			return
	   	}
	   	if(answer.name == 'ping'){
	   		return
	   	}
		if(answer.name == 'error' && answer.cause == 'session terminated'){
			// window.location = '/'
			// alert('ошибка от вебсокета - смотри консоль')

			console.log("%c answer, answer.name", coCSS, answer, answer.name)
			return;
		}
		else{
			console.log('%c непонятно, что с этим делать',
						'background: yellow; color: black; padding: 2px 5px',
						answer)
		}
  			 return;
	}
}

class WebsocketRouter extends React.Component { //here I do routing data from websocket
	constructor(props) {
		super(props);
		this.state = {
			login: this.props.login,
			token: this.props.token,
		}
		this.lastMessageDate = new Date();
		this.intervalForCheckConnection = 30000;//30 seconds
		this.timeOutBetweenMessages = 30000;//30 seconds
		window.WebsocketRouter = this
		window.clearIntervalsReconect = this.clearIntervals;
	}
	static getDerivedStateFromProps(props, state) {
		return {
			login: props.login,
			token: props.token
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const { token, login } = this.state;
		const { socket } = this.props;
		if(!token || !login){
			if(socket && isOpen(socket)) this.props.socket.close();
			clearInterval(this.connectionTry)
		}else{
			this.socketConnect();
		}
	}
	componentDidMount() {

		if(!FormAction.isOrigin ) {
			this.socketConnect()
		}
	}
	componentWillUnmount() {
		this.intervalListenner ? clearInterval(this.intervalListenner) : null;
		this.connectionTry ? clearInterval(this.connectionTry) : null;
	}
	socketConnect = () => {
		const { websocket_url } = this.props;
		const { token, login } = this.state;
		if(token && login && !WebsocketActions.checkWebSocket()) {
			const socket = new WebSocket(websocket_url + '?token=' + token); //
			socket.onopen = (e) => {
				DEV_SERVER ? console.log("%c WEBSOCKET open", coCSS, e) : null;
				this.initMessageIntervalListenner(socket);
				this.socketListener(socket);
				if(this.connectionTry) {//recovery connection
					DEV_SERVER ? console.warn('WEBSOCKET trying  recovery connection') : null;
					clearInterval(this.connectionTry);
					setText(l_v.connection_restored);
					setTimeout(() => setText(null), 1500);
				}
				WebsocketActions.saveSocket(socket);

			}
			socket.onerror = (error) => {
				DEV_SERVER ? console.warn('WEBSOCKET error', error) : null;
				const tokenFailed = () => {
					this.clearIntervals();
					const { socket } = this.props;
					if(socket && isOpen(socket)) socket.close();
				}
				const tokenOkey = this.socketConnect;
				FormAction.checkToken(tokenOkey, tokenFailed);
			}
			socket.onclose = (e) => {
				DEV_SERVER ? console.warn(`WEBSOCKET close: ${token}`, e) : null;
				const { token, login } = this.state;//if user did logout
				if(!token || !login){
					this.clearIntervals();
					setText(null);
					if(this.intervalListenner){
						clearInterval(this.intervalListenner)
					}
					WebsocketActions.clearWebsocket(socket);
				}else{
					if(!this.connectionTry) {
						this.tryConnect();
					}
				}
				
				
			}
		} else {
			clearInterval(this.connectionTry)
		}
	}
	initMessageIntervalListenner = (socket) => {
		const isTimeToPing = () => (new Date() - this.lastMessageDate) > this.timeOutBetweenMessages;
		const sendPingMessage = () => {
			const { token, login } = this.state;
			if(!isOpen(socket) && token && login) {
				this.clearIntervals();
				this.tryConnect();
				return
			}
			if(!token || !login){
				this.clearIntervals();
				return
			}
			socket.send(JSON.stringify({ "name": "ping" }));
		}
		const pingConnection = () => isTimeToPing() ? sendPingMessage() : null;
		this.intervalListenner = setInterval(pingConnection, this.intervalForCheckConnection)
	}
	clearIntervals(){
		// console.log(`%c clearInterval`, "color: blue; font-weight: 400; text-transform:uppercase", this.state)
		if (this.intervalListener) clearInterval(this.intervalListener);
    	if (this.connectionTry) clearInterval(this.connectionTry);
		this.connectionTry =  null;
		this.intervalListenner = null;
		setText(null)
	}
	socketListener = (socket) => {
		socket.onmessage = (e) => {
			// console.log("%c ONMESSAGE", coCSS, JSON.parse(e.data))
			this.lastMessageDate = new Date();
			if(e.data && IsJsonString(e.data)) {
				const answer = JSON.parse(e.data);
				const { name, successful, description } = JSON.parse(e.data);
				if(name == 'auth' && !successful) {
					FormAction.logOut();
					return
				}
				if(name == "authorizationR" && successful) return
				if(name == "ping") return
				instructionOnRecieveMessage(e)
			}
		}
	}
	tryConnect = () => {
		// console.log(`%c tryConnect`, "color: blue; font-weight: 400; text-transform:uppercase",  this.state)
		let countOfAttempt = 0;
		const createConnect = () => {
			const { token, login } = this.state;//if user did logout
			// console.log(`%c createConnect`, "color: red; font-weight: 400; text-transform:uppercase", token, login)

			if(!token || !login){
				this.clearIntervals();
				setText(null);
				return
			}
			countOfAttempt += 1;
			let init = () => this.socketConnect('tryConnect');
			init();
			if(countOfAttempt > 100) { //100
				clearInterval(this.connectionTry);
				window.location.reload()
			}
			let dotsInTheEnd = Number.isInteger(countOfAttempt / 2) ? '...' : '..';
			setText(l_v.attempting_to_reconnect);
		}


		setText(l_v.trying_to_reconnect);
		// console.trace(`%c this.connectionTry set`, "color: #708090; font-weight: 500; text-transform:uppercase", this.connectionTry)
		this.connectionTry = setInterval(createConnect, 5000);
	}
	
	render() { return null }
}

function isOpen(ws) { return ws.readyState === ws.OPEN }


export default connect(
      state => ({
          token: 				state.authorizationR.token,
          websocket_url: 	state.authorizationR.websocket_url,
          socket: 			state.authorizationR.socket,
          login: 				state.authorizationR.login,
      }),
      dispatch => bindActionCreators(WebsocketActions, dispatch))
(WebsocketRouter)
