import React from 'react';
import { Provider, connect} from 'react-redux'
import { bindActionCreators, applyMiddleware} from 'redux'
import ActionCreator from '../../../actions/ActionCreator.jsx'
import language_variant from '../../../store/languages.jsx'
import createReactClass from 'create-react-class';
import Listener from '../mixins/listener';
import compareProps from '../utils/compare-props';
import events from '../events/marker.js'
import {ifNoKey} from '../../../actions/ActionCreator.jsx'
let hover = {
	url: '/images/arrow_on_map.svg#someId',

}
let  normal= {
	url: '/images/arrow_on_map_def.svg#someId',
}
// let normal = {
// 	path: 
// }

class Marker extends React.Component{
	constructor(props) {
		super(props);
		this.state ={
			lat: this.props.lat, 
			lng: this.props.lng,
			rotation: 50,
			normal: {
					url: '/images/arrow_on_map_def.svg#' + this.props.id,
				},
			hover:{
					url: '/images/arrow_on_map.svg#'+this.props.id,
				},
			choosed: '',
			infowindow: '',
			marker: undefined,
		}
		this.onClick = this.onClick.bind(this)
		this.rotation = this.rotation.bind(this)
		this.InfoWindow = this.InfoWindow.bind(this)
	}
	onClick(event){
		this.props.toUp(this.props.id)
	}
	returnCoord(data){
		this.props.return({
							lat: this.state.lat,
							lng: this.state.lng
						})
	}
	initMarker = (lat, lng)=>{
		if(!lat || !lng){
			lat=this.state.lat;
			lng=this.state.lng;
		}
		if(!lat || !lng){
			return
		}
		let marker, normal = this.state.normal;
		marker = new google.maps.Marker({
	           map: this.props.map,
	           draggable: false,
	           Icon: normal,
	           optimized: false,
	           labelClass: 'lableClass',
	           iconClass: 'iconCLass',
	           zIndex: 100,
	           position: {lat: lat, lng: lng},
	           title: this.props.title,
	           date: this.props.date,
	    });
	  
		
	    this.setState({
	    	marker: marker,
	    	lat: lat,
	    	lng: lng,
	    })
	   	
	    return marker
	}
	rotation(data){
		this.setState({
			rotation: data
		})
		let elem = document.querySelectorAll("img[src='/images/arrow_on_map.svg#" + this.props.id + "']");
		if (elem[0] == undefined){
			elem = document.querySelectorAll("img[src='/images/arrow_on_map_def.svg#" + this.props.id + "']");
		}
		if (elem[0] !== undefined){
			elem[0].style.cssText = "transform: rotate("+data+"deg)";
			if(elem[0].parentNode.classList.value !== 'marker'){
				elem[0].parentNode.classList.add('marker');
			}
		}
	}
	InfoWindow(infowindow){
		let map = this.props.map;
		let number, name, none, src;
		(this.props.number == undefined)?(number = ''):(number = this.props.number);
		(this.props.name == undefined)?(name = ''):(name = this.props.name);
		(this.props.src == undefined)?(src = '', none='none'):(src = this.props.src, none='inline-block');
		let inmidcourse, engine, speed, parking, time_parking, status, visibilitySpeed;
		status ="";
		time_parking="";
	    (inmidcourse === true)?(status  = language_variant.status_inmidcourse):(status);
	    (parking === true)?(status  = language_variant.parking):(status);
    	(speed !== undefined && speed !== 0)?(visibilitySpeed = 'visible'):(visibilitySpeed = 'hidden');
    	speed = this.state.speed;
	    (this.state.engine ==  true)?(engine = language_variant.engine + ' ' + language_variant.on):(engine = language_variant.engine + ' ' + language_variant.off)
			let contentString = '<div class="InfoWindow">'+
	            '<p class="auto_info">'+
	            	'<span class="transport_number">'+number+'</span>'+
	            	'<span class="transport_name">'+name+'</span>'+
	            	'<img class="driverAva" style="display:'+none+'" src='+src+'>'+
	            '</p>'+
	            '<div id="bodyContent">'+
	             '<p class="current_status">'+
			          '<svg class="icon_inmidcourse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">'+
			            '<g xmlns="http://www.w3.org/2000/svg">'+
			              '<path d="M8.5,1C12.7,1,16,4.4,16,8.5c0,4.1-3.4,7.5-7.5,7.5S1,12.7,1,8.5C1,4.4,4.4,1,8.5,1 M8.5,0C3.8,0,0,3.8,0,8.5   C0,13.2,3.8,17,8.5,17c4.7,0,8.5-3.8,8.5-8.5C17,3.8,13.2,0,8.5,0L8.5,0z"/>'+
			            '</g>'+
			            '<polygon xmlns="http://www.w3.org/2000/svg" points="8.7,14 7.3,9.8 3,8.3 12.2,4.8 "/>'+
			            '</svg>'+
			          '<svg class="icon_inmidcourse" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">'+
			            '<g >'+
			              '<path style = "fill: #FFFFFF" d="M3,16.5c-0.8,0-1.5-0.7-1.5-1.5V3c0-0.8,0.7-1.5,1.5-1.5h12c0.8,0,1.5,0.7,1.5,1.5v12   c0,0.8-0.7,1.5-1.5,1.5H3z"/>'+
			              '<path d="M15,2c0.6,0,1,0.4,1,1v12c0,0.6-0.4,1-1,1H3c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1H15 M15,1H3C1.9,1,1,1.9,1,3v12   c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V3C17,1.9,16.1,1,15,1L15,1z"/>'+
			            '</g>'+
			            '<path  d="M10,5H8H6v6v2h2v-2h2c1.1,0,3-1.1,3-3S11.1,5,10,5z M10,9H8V7h2c0,0,1,0.2,1,1S10,9,10,9z"/>'+
			          '</svg>'+
			          '<span>'+status+time_parking +'</span><span class="grey">'+language_variant.min +'</span>'+
			            '<span> · </span> '+
			          '<span >'+  engine +'</span>'+ 
			    '</p>'+
			    '<div class="clear"></div>'+
	            '<p class="button_on_info">'+
	            '<a href="/#/routeDetail/'+this.props.name+'/'+this.props.licensePlate+'/'+this.props.id +'" class="secondary_btn_small">'+
	            'Показать маршрут</a>'+
	            '<a href="/#/tasks/newTask"'+'/!'+name+'/'+number +' class="secondary_btn_small">'+
	            'Дать задание</a>' +
	            '</p>'+
	            '</div>'+
	            '</div>';
			
			let InfoWindow = new google.maps.InfoWindow({
	          content: contentString,
	          maxWidth: 345,
	        });
	        return InfoWindow
	}
    componentDidMount() {
		let map = this.props.map,
			onClick = this.onClick, 
			rotation = this.rotation,
			hover = this.state.hover,
			normal = this.state.normal,
			id = this.props.id,
			infowindow = this.InfoWindow(),
			activeinfowindow = null,
			marker;
		if (!!this.props.lat  && !!this.props.lng ){
			marker = this.initMarker(this.props.lat, this.props.lng);
		    let myoverlay = new google.maps.OverlayView();
			myoverlay.draw = function () {
               this.getPanes().markerLayer.id='markerLayer';
               let data = this.getPanes()
               let length =this.getPanes().markerLayer.children.length;
              for (let i=1; i< length; i++) {
                   this.getPanes().markerLayer.children[i].className='markerdiv'
              }   
	      	}
			myoverlay.setMap(this.props.map);
			 
			google.maps.event.addListener(map, 'click', function() {
							infowindow.close();
			});
			// google.maps.event.addListener(map, 'touch', function() {
			// 				infowindow.close();
			// });
			google.maps.event.addListener(marker,'click',function() {
				if(window.outerWidth>768){
					onClick();
				}
					infowindow.open(map, marker);
			});
			google.maps.event.addListener(infowindow, 'domready', function() {
				let length = document.querySelectorAll(".gm-style-iw").length;
				//container infowindow
				let iwOuter =document.querySelectorAll(".gm-style-iw")[length-1];
				iwOuter.style.background="transparent";
				iwOuter.style.boxShadow = "0 -2px 10px 0 rgba(0, 0, 0, 0.2)";
				iwOuter.firstChild.style.width = "100%";
				iwOuter.firstChild.style.maxWidth = "100%";
				let iwBackground = iwOuter.previousSibling;
				iwOuter.style.top = "38px";
				iwOuter.style.left = "24px";
				iwOuter.previousSibling.style.display = 'none';
				let corner = iwOuter.previousSibling.childNodes;
				// corner.style.display = 'none';
				corner[2].style.top='147px';
				corner[1].style.top='147px';

				iwBackground.lastChild.style.display= 'none';
			    // Removes white background DIV
			    iwBackground.lastChild.style.display= 'none';
			    iwBackground.lastChild.previousSibling.previousSibling.style.display= 'none';
			     // Reference to the div that groups the close button elements.
			     
				let iwCloseBtn = iwOuter.nextSibling;
					 // Apply the desired effect to the close button
				// iwCloseBtn.style.cssText="width: 13px; height: 13px; overflow: hidden; position: absolute; opacity: 1; z-index: 10000; cursor: pointer;";
				iwCloseBtn.style.right = '37px';
				iwCloseBtn.style.top = '48px';
				if(window.outerWidth < 768){
				 	iwCloseBtn.nextSibling.style.top = '37px';
				 	iwCloseBtn.nextSibling.style.right = '28px';
				}
				
				// console.log(iwOuter.parentNode,iwOuter.parentNode.style)
			})
			google.maps.event.addListener(marker, 'mouseover', function() {
				    marker.setIcon(hover);
			});
			google.maps.event.addListener(marker, 'mouseout', function() {
				    marker.setIcon(normal);
			});
		}
		this.setState({
		    	marker: marker
		})
	}
	getBearing(startLat,startLong,endLat,endLong){
		function radians(n) {
		  return n * (Math.PI / 180);
		}
		function degrees(n) {
		  return n * (180 / Math.PI);
		}
		  startLat = radians(startLat);
		  startLong = radians(startLong);
		  endLat = radians(endLat);
		  endLong = radians(endLong);
		  var dLong = endLong - startLong;
		  var dPhi = Math.log(Math.tan(endLat/2.0+Math.PI/4.0)/Math.tan(startLat/2.0+Math.PI/4.0));
		  if (Math.abs(dLong) > Math.PI){
		    if (dLong > 0.0)
		       dLong = -(2.0 * Math.PI - dLong);
		    else
		       dLong = (2.0 * Math.PI + dLong);
		  }
		  return (degrees(Math.atan2(dLong, dPhi)) + 360.0) % 360.0;
	}
	markerOnRouteDetail = (map, marker, lat1, lng1, currentLat, currentLng, date1, current_date)=>{
		if(!marker){
			marker = this.initMarker()
		}
		if (!!currentLat && !!currentLng && this.current_date !== current_date){//date comparing  need for prevent rotation marker if data  
			map.setCenter({lat: currentLat, lng: currentLng});
			marker.setPosition({lat: currentLat, lng: currentLng});				//come not from route detail (component also listen redux - websocket )
		}
		if(!!date1 && !!current_date && this.current_date !== current_date){
					if (date1.getTime() < current_date.getTime()) { //situation when data come from routeDetail
						this.rotation(this.getBearing(lat1, lng1, currentLat, currentLng))
					}else{
						this.rotation(this.getBearing(currentLat, currentLng, lat1, lng1))
					}
		}
		this.setState({
					lat: currentLat,
					lng: currentLng,
					date: current_date,
					marker: marker,
		})
		this.current_date = current_date;
	}
	onMouseHoverandLeave = (marker, onMouse)=>{
		// console.log('hover', marker, !marker)
		if(!marker){
			marker = this.initMarker()
		}
		if(!marker){
			console.log('no marker')
			return
		}
		if (!!onMouse.value){
				marker.setIcon(this.state.hover);
				marker.setZIndex(101);
				this.setState({
					choosed: true
				})
		}

		if (!onMouse.value){
				marker.setIcon(this.state.normal);
				marker.setZIndex(100);
				this.setState({
					choosed: false,
				})
		} 	
	}
	onClickByTransportOnList = (map, marker)=>{
		if(!marker){
			console.log('no marker')
			return
		}
		let west = map.getBounds().getSouthWest().lng();
		let center = map.getCenter().lng();
		let view = Math.abs(center-west);
		let bias = view/2; //0.017;
		if(window.outerWidth<768){
			bias=0;
		}
		this.setState({
			lookthis: !this.state.lookthis
		});
		map.setCenter({lat:this.state.lat, lng: this.state.lng - bias});//0.017 must be flexible depends by size window
		marker.setIcon(this.state.hover);
		 

		// map.setZoom(15);
		setTimeout(this.rotation(this.state.rotation), 700);
		// ActionCreator.choosed(null)//this deactivate look for
	}
	onUpdateLocation = (map, marker, currentLat, currentLng)=>{
		if(!marker){//if no marker create marker
				marker = this.initMarker(currentLat, currentLng)	
		}
		if(!!currentLat && !!currentLng){//if have dat
			// console.log('onUpdateLocation', marker, currentLat, currentLng)
				marker.setPosition({lat: currentLat, lng: currentLng});
		}
		if (this.state.lat!==currentLat || this.state.lng!==currentLng){
				this.rotation(this.getBearing(this.state.lat, this.state.lng, currentLat, currentLng))	
		}
		this.setState({
			marker: marker,
			lat: currentLat,
			lng: currentLng,
		})	
	}
	componentWillReceiveProps(nextProps, prevProps) {
		let map = this.props.map, marker = this.state.marker;
		if (!marker 
					&&!!nextProps.lat
										&&!!nextProps.lng){
				marker = this.initMarker(nextProps.lat, nextProps.lng)	
		}
		if(!!nextProps.routeDetail && this.props.id == nextProps.id){//
				this.markerOnRouteDetail( 	map, 
											marker, 
											this.state.lat, 
											this.state.lng, 
											ifNoKey(nextProps.goTo, "lat"), 
											ifNoKey(nextProps.goTo, "lng"), 
											this.state.date, 
											ifNoKey(nextProps.goTo, 'date')
										)
		}
		if(!nextProps.routeDetail 
									&& this.props.id == nextProps.location.id){
			this.onUpdateLocation( 	map,
									marker,
									nextProps.lat, 
									nextProps.lng,
			)
		}
		if(!nextProps.routeDetail 
									&& this.props.id == nextProps.onMouse.id){
				this.onMouseHoverandLeave(marker, nextProps.onMouse)
		}

		if(this.props.id == nextProps.goTo){
			this.onClickByTransportOnList(map, marker)
		}
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.location.id == this.props.id 
											||nextProps.hover.id == this.props.id
												||nextProps.goTo == this.props.id
													||nextProps.onMouse.id == this.props.id
													){
			return true
		}
		else {return false}
	}
    render(props, state) {
     	return (null)
    }
  }
export default connect(
      state => ({
        location: state.globalR.controlR.location,
        onMouse: state.globalR.controlR.onMouse,
        goTo: state.globalR.controlR.goto,
      }), 
      dispatch => bindActionCreators(ActionCreator, dispatch)
)(Marker)
