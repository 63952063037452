import React 						from 'react'
import {closeImg} 					from '../img.jsx'
import l_v                                  			 from "store/languages.jsx"

export default class EditCalibrairionOfRealSensor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			preloader: 		false,
			sensorId: 		'',
			newCalibration: '',
			errorStyle: 	'',
		}
		this.initState = this.state;
	}
	callEdit = (id) =>{
		this.setState({sensdorId: id});
	}
	enterToState = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	save = () =>{
		if(!!this.state.newCalibration){
			this.props.saveCalibration(this.state.newCalibration)
			this.setState(this.initState);
		}else{
			this.setState({errorStyle: 'formError'});
		}
		
	}
	render(){
		if(!!this.state.sensdorId){
			return 	<div className = 'updateCalibration'>
						{closeImg('close', ()=>this.setState({sensdorId: ''}))}
						<p className = 'form_name'>{l_v.set_new_calibration}: <b>{this.state.sensdorId}</b></p>
						<input 	value = 	{this.state.newCalibration} 
								className = {'input ' + this.state.errorStyle}
								name = 		'newCalibration'
								onChange = 	{this.enterToState} />
						<button name = 		'showForm' 
								className = 'btn_black addsensor'
								onClick = 	{this.save}>
								{l_v.save_sensor}
						</button>
					</div>
		}else{
			return null
		}
		
	}
}