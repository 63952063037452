import React, { useState, Fragment }  from 'react'
import { Link }                       from 'react-router-dom'
import { connect }                    from 'react-redux'
import Helmet                         from 'react-helmet';

import l_v from "store/languages.jsx"
import {
	ifNoKey,
	arrayIsEmpty,
	doCopy,
	removeElementFromList,
	checkOnSharp,
	removeSpaces
}                                     from 'actions/ActionCreator.jsx'
import SL 									  from "sl-library"
import transportsApi                  from 'actions/transportsApi.js'
import deviceOnTransportApi           from 'actions/deviceOnTransportApi.js'
import gasStationApi                  from 'actions/gasStationApi'
import devicesApi                     from 'actions/devicesApi.js'
import Calibration                    from 'actions/calibrations.js'
import Navigation                     from '../modules/AdminNavigation.jsx'
import Calendar                       from '../modules/InfiniteCalendar/Calendar.jsx';
import { FormChangeDesc }             from './ClientPage.jsx'
import Required                       from './component/Required.jsx'
import Nonull                         from './component/Nonull.jsx'
import SeparPermission                from './component/SeparPermission.jsx'
import ReRequestData                  from './component/ReRequestData.jsx'
import Calibrations                   from './calibrations/Calibrations.jsx'
import FormOwnerOfTransport           from "./transportPage/FormOwnerOfTransport.jsx"
import LinkToVehicleOwner             from "./transportPage/LinkToVehicleOwner.jsx"
import FormPeriod                     from './transportPage/FormPeriod.jsx'
import FromAddUser_forTransport       from './transportPage/FromAddUser_forTransport.jsx'
import EditMountedDeviceForm          from './transportPage/EditMountedDeviceForm.jsx'
import MountDeviceForm                from './transportPage/MountDeviceForm.jsx'
import FormInput                      from './transportPage/FormInput.jsx'
import CurrentInfo                    from './transportPage/CurrentDeviceInfo.jsx'
import MenuForPage                    from './transportPage/MenuForPage.jsx'
import Templates                      from './transportPage/Templates.jsx'
import VehicleSettings                from './transportPage/VehicleSettings.jsx'
import DeviceSettings                 from './transportPage/DeviceSettings.jsx'
import AddMultiSensor                 from './transportPage/AddMultiSensor.jsx'
import IgnitionCorrectionForm         from './transportPage/IgnitionCorrectionForm.jsx'
import GroupsVehicle                  from './groupManaging/GroupsVehicle.jsx'
import VirtualSensors                 from './transportPage/VirtualSensors.jsx'
import RealSensors                    from './socketInfo/RealSensors.jsx'
import ExistingMultisensors           from './socketInfo/ExistingMultisensors.jsx'
import { editImg, closeImg, goToClientAppImg }  from './img.jsx'
import Preloader                      from 'modules/preloader.jsx'
import ClearDataByDeviceForm 		  from 'modules/transportPage/ClearDataByDeviceForm.jsx'
import {
	SpeedCorrectionForm,
	ClearDataBySensorForm
} 		  from 'modules/transportPage/ClearDataBySensorForm.jsx'
import {setText, setConfirmMessage} 					  from "actions/bugReport.jsx"
import {HigLightContext, HighLightRowInstruction} 							from "modules/transportPage/HighLighterOfRows.js"
// с 6 - 22 есть данные
// 19.12.2021 20:37
// 21
export class TransportPage extends HighLightRowInstruction(React.PureComponent){
	constructor(props) {
		super(props);
		const { transport_id, transportName, owner } = this.props.match.params;
		this.state = {
			id:                 transport_id,
			newTransportName:   transportName,
			owner:              owner,
			sensor:             '',
			sensorWasSending:   '',
			device_:            undefined,
			deviceVStransport:  undefined,
			activeSerial:       undefined,
			activePeriodId:     undefined,
			formName:           false,
			showGraph:          false,
			groupsList:         undefined,
			row_filter_enabled: false
		}
		window.TP = this
		this.name;
	}
	getInitialInfo = () => {
		const { transport_id } = this.props.match.params;
		this.preloader.show();
		const setToState = vehicleInfo => {
			transportsApi.getVehicleSettings(
	                                        	transport_id,
												settings => this.setState({ 
																...vehicleInfo,
																settings: settings,
															})
											)
			if(this.mounted) this.preloader.hide();
		}
		if(!this.props.gasStation ){ //!== true
			transportsApi.getTransportInfo(transport_id, setToState)
		}else{
			gasStationApi.getGasStationById(transport_id, setToState)
		}
		deviceOnTransportApi.getIntervalList(transport_id);
	}
	componentDidMount() {
		this.mounted = true;
		this.getInitialInfo()
		this.name = this.props.match.params.userName;//this is transport name
		if(!this.state.globalCalibraition){ //not used?
			Calibration.getGlobal()
		}
	}
	componentWillReceiveProps(nextProps) {
		let activeSerial, activePeriodId, currentDeviceInfo;
		console.log("%c nextProps.ThisTransport", coCSS, nextProps)
		if(nextProps.ThisTransport){ //used on update  simple vehicle settings(need refactoring)
			delete nextProps.ThisTransport.settings //crutch because settings is also in the DTO of vehicle (but not have translate), for get using other request
			this.setState({...nextProps.ThisTransport});
		}
		if(Array.isArray(nextProps.deviceVStransport)){
			nextProps.deviceVStransport.map(key=>{
				if(!!key.to ){
					if(new Date(key.to) > new Date()){
						activeSerial = key.deviceSerial;
						activePeriodId = key.id;
						currentDeviceInfo = key;
					}
				}else{
						activeSerial = key.deviceSerial;
						activePeriodId = key.id;
						currentDeviceInfo = key;
				}
			})
		}
		devicesApi.setDeviceSettingsDTO(currentDeviceInfo)
		this.setState({
			currentDeviceInfo:  	currentDeviceInfo,
			deviceInfo: 			nextProps.deviceInfo,
			deviceVStransport: 	nextProps.deviceVStransport,
			activeSerial: 			activeSerial,
			activePeriodId: 		activePeriodId
		})
	}
	componentWillUnmount() {
		this.mounted = false;
		transportsApi.clearThisTransport()//not used?
	}
	enterToState = (e) =>{
		let value = e.target.value;
		if(e.target.name == 'newTransportName' || e.target.name == 'licensePlate'){
			value = checkOnSharp(value);
			value = value.replace('/','');
		}
		this.setState({
			[e.target.name]: value
		});
	}
	updateName = () =>{
		const {newTransportName, id} = this.state;
		const requestBody = {name: newTransportName};
		const redirect = (name) => {
			let {owner, transport_id} = {...this.props.match.params}
			const {gasStation} = this.props;
			const endURL = gasStation ? "/editGasStation" : "/editVehicle"
			window.location.hash = "#/transport/" + owner+'/' + name + "/" + transport_id + endURL; //ничё лучше не придумал. знаю, а шо делать
			console.log('если шо, редирект тут')
		}
		if(newTransportName.length){
			transportsApi.updateTransportInfo(id, requestBody, newTrailerInfo => redirect(newTrailerInfo.name));
			this.setState({
				formName: false,
			});
		}
	}
	updateDecs = (descriptionText) => transportsApi.updateTransportInfo(this.state.id, {description: descriptionText})
	updateTrans = (dataTransmission) =>{
		let forsend = {
			id: this.state.id,
			data: dataTransmission
		}
		let {gasStation} = this.props;
		const onUpdate = () =>{
			console.log("%c in form value must be equal answer", coCSS, )
		}
		if(!gasStation){
			transportsApi.updateTransmission(forsend);
		}else{
			gasStationApi.updateTransmission(forsend.id, dataTransmission, onUpdate)
		}
	}
	updateInformation = (dataForUpdate) => {
		let { gasStation } = this.props;
		const {id} = this.state;
		if(!gasStation) {
			transportsApi.updateTransportInfo(id, dataForUpdate, () => {
				if(typeof dataForUpdate.licensePlate == 'string') window.location.reload()
			});
		} else {
			gasStationApi.updateGasStation(id, dataForUpdate)
		}
	}
	removeSensor_from_Vehicle(data){
		ActionCreator.removeDeviceFromTransport(data)
	}
	addVirtualSensorToList = (sensor) =>{
		let {virtualSensors} = this.state;
		this.setState({virtualSensors: addToList(virtualSensors, sensor)});
	}
	addRealSensorsToList = (sensor) =>{
		let {realSensorSockets} = this.state;
		this.setState({realSensorSockets: addToList(realSensorSockets, sensor)});
	}
	addMultiSensorToList = (newMultiSensor) =>{
		let {multiSensors} = this.state;;
		this.setState({
			multiSensors: addToList(multiSensors, newMultiSensor)
		});
	}
	updateVirtualSensorInList = (sensorInfo) =>{
		let {virtualSensors} = this.state;
		virtualSensors.find(key=>{
			if(key.virtualSensorId == sensorInfo.sensorId){
				key = data;
			}
		})
		this.setState({virtualSensors: doCopy(virtualSensors)});
	}
	removeVirtualSensorFromList = (deletedSensorInfo) =>{
		window.location.reload();
		let {virtualSensors} = this.state;
		this.setState({
			virtualSensors: doCopy(removeElementFromList(virtualSensors, 'virtualSensorId',deletedSensorInfo.virtualSensorId))
		});
	}
	removeRealSensorFromList = (realSensorId) =>{
		let {realSensorSockets} = this.state;
		realSensorSockets.splice(getSocketIndex(realSensorId, realSensorSockets), 1)
		this.setState({
			realSensorSockets: doCopy(realSensorSockets)
		});
	}
	getDevicesSerialInPeriod = ({dateFrom, dateTo}) => {
		const {deviceVStransport} = this.state;
		const startDate = new Date(dateFrom);
		const endDate = new Date(dateTo);
		const getStartDate = (deviceMountStartDate) => {
			return moment(startDate > new Date(deviceMountStartDate) ? dateFrom : deviceMountStartDate).format();
		};
		const getEndDate = (deviceMountEndDate) => {
			return moment(endDate < new Date(deviceMountEndDate) || !deviceMountEndDate ? dateTo : deviceMountEndDate).format();
		}
		return 	SL.arrayIsEmpty(deviceVStransport) ? 
					deviceVStransport
					    .filter(({ from, to }) => {
					        const fromTime = new Date(from);
					        const toTime = new Date(to || from); // используем from как to, если to не указано, предполагая мгновенное событие
					        return (fromTime <= endDate && (toTime >= startDate || !to));
					    })
					    .map(({ deviceSerial, from, to }) => ({deviceSerial, from: getStartDate(from), to: getEndDate(to)})) :
					null
	}
	correctIgnition = (requestInfo) => {
		console.log(`%c requestInfo-`, "color: green; font-weight: 400; text-transform:uppercase", requestInfo)
		const {from, to} = requestInfo;
		const devicesSerialInPeriod = this.getDevicesSerialInPeriod({dateFrom: from, dateTo: to})
		console.log(`%c devicesSerialInPeriod-`, "color: green; font-weight: 400; text-transform:uppercase", devicesSerialInPeriod)
		const correctIgniotionForAllDevices = (devicesSerialInPeriod, requestInfo) => {
		  // Преобразование каждого запроса удаления в промис
		  const removalPromises = devicesSerialInPeriod.map(({deviceSerial, from, to}) =>
		    new Promise((resolve, reject) => {
		      const onCompleteRequest = () => resolve(`Correct ignition for device serial: ${deviceSerial}`);
		      const onError = (error) => reject(`${deviceSerial}`);
		      transportsApi.correctIgnitionRequest({deviceId: deviceSerial, ignitionOn: requestInfo.ignitionOn, from, to}, onCompleteRequest, onError);
		    })
		  );

		  return Promise.all(removalPromises);
		};
		if(SL.arrayIsEmpty(devicesSerialInPeriod)){
			if(this.mounted) this.preloader.show()
			correctIgniotionForAllDevices(devicesSerialInPeriod, requestInfo)
			    .then(results => {
			      if(this.mounted) this.preloader.hide();
			      setConfirmMessage(l_v.ignition_request_accept);
			    })
			    .catch(error => {
			      if(this.mounted) this.preloader.hide();
			      console.error('Failed to remove data for some devices:', error);
			      setConfirmMessage(l_v.ignition_request_decline);
			    });
			 }else{
			 	alert(l_v.no_active_device)
			 }
		
		// const {activeSerial} = this.state;
		// requestInfo.deviceId = activeSerial;
		// const onCompleteRequest = () => {
		// 	if(this.mounted) this.preloader.hide();
		// 	setConfirmMessage(l_v.ignition_request_accept);
		// }
		// const onError = () => {
		// 	if(this.mounted) this.preloader.hide();
		// 	setConfirmMessage(l_v.ignition_request_decline);
		// }
		// if(activeSerial){
		// 	if(this.mounted) this.preloader.show()
		// 	v.correctIgnitionRequest(requestInfo, onCompleteRequest, onError)
		// }else{
		// 	alert(l_v.no_active_device)
		// }
	}
	removeDataFromСassandra = (periodForRemove) => {
		const {from, to} = periodForRemove;
		const devicesSerialInPeriod = this.getDevicesSerialInPeriod({dateFrom: from, dateTo: to})
		this.preloader.show();
		

		const removeDataFromAllDevices = (devicesSerialInPeriod, periodForRemove) => {
		  // Преобразование каждого запроса удаления в промис
		  const removalPromises = devicesSerialInPeriod.map( ({deviceSerial, from, to}) =>
		    new Promise((resolve, reject) => {
		      const onCompleteRequest = () => resolve(`Data removed for device serial: ${deviceSerial}`);
		      const onError = (error) => reject(`Failed to remove data for device serial: ${deviceSerial}, Error: ${error}`);

		      devicesApi.removeDataFromCassandra({from, to}, deviceSerial, onCompleteRequest, onError);
		    })
		  );

		  return Promise.all(removalPromises);
		};
		if(SL.arrayIsEmpty(devicesSerialInPeriod)){
			removeDataFromAllDevices(devicesSerialInPeriod, periodForRemove)
			    .then(results => {
			      if(this.mounted) this.preloader.hide();
			      setConfirmMessage(l_v.device_data_remove_accept(Array.from(devicesSerialInPeriod, el => el.deviceSerial).join(', ')));
			    })
			    .catch(error => {
			      if(this.mounted) this.preloader.hide();
			      console.error('Failed to remove data for some devices:', error);
			      setConfirmMessage(l_v.device_data_remove_decline);
			    });
		}else{
			alert(l_v.no_active_device)
		}
	}
	removeDataBySensor = (requestInfo) => {
		const { from, to } = requestInfo;
		const devicesSerialInPeriod = this.getDevicesSerialInPeriod({ dateFrom: from, dateTo: to })
		

		const removeDataFromAllDevices = (devicesSerialInPeriod, requestInfo) => {
				// Преобразование каждого запроса удаления в промис
				const removalPromises = devicesSerialInPeriod.map(({ deviceSerial, from, to }) =>
						new Promise( (resolve, reject) => {
								const onCompleteRequest = () => resolve(`Data removed for device serial: ${deviceSerial}`);
								const onError = (error) => reject(`Failed to remove data for device serial: ${deviceSerial}, Error: ${error}`);
								devicesApi.removeDataBySensorNumber(
									deviceSerial, {
										sensorNums: requestInfo.sensorNums,
										from,
										to
									},
									onCompleteRequest,
									onError
								)
							})
				);
			return Promise.all(removalPromises);
		};
		if (SL.arrayIsEmpty(devicesSerialInPeriod)) {
			this.preloader.show();
			removeDataFromAllDevices(devicesSerialInPeriod, requestInfo)
				.then(results => {
					if (this.mounted) this.preloader.hide();
					setConfirmMessage(l_v.sensor_data_remove_accept(requestInfo.sensorNums));
				})
				.catch(error => {
					if (this.mounted) this.preloader.hide();
					console.error('Failed to remove data for some devices:', error);
					setConfirmMessage(l_v.sensor_data_remove_decline(requestInfo.sensorNums));
				});
		} else {
			alert(l_v.no_active_device)
		}
	}

	upateSpeedByPeriod  = requestInfo => {
		const {from, to} = requestInfo;
		const devicesSerialInPeriod = this.getDevicesSerialInPeriod({dateFrom: from, dateTo: to})
		const correctSpeedForAllDevices = (devicesSerialInPeriod, requestInfo) => {
		  // Преобразование каждого запроса удаления в промис
		  const removalPromises = devicesSerialInPeriod.map(({ deviceSerial, from, to }) =>
		    new Promise((resolve, reject) => {
		      const onCompleteRequest = () => resolve(`Data removed for device serial: ${deviceSerial}`);
		      const onError = (error) => reject(`${deviceSerial}`);

		      devicesApi.upateSpeedByPeriod({params: {deviceId: deviceSerial, speed: requestInfo.speed, from, to}, onOk: onCompleteRequest, onError});
		    })
		  );

		  return Promise.all(removalPromises);
		};
		if(SL.arrayIsEmpty(devicesSerialInPeriod)){
			this.preloader.show();
			correctSpeedForAllDevices(devicesSerialInPeriod, requestInfo)
			    .then(results => {
			      if(this.mounted) this.preloader.hide();
			      setConfirmMessage(l_v.update_speed_request_accept(Array.from(devicesSerialInPeriod, el => el.deviceSerial).join(', ')));
			    })
			    .catch(error => {
			      if(this.mounted) this.preloader.hide();
			      console.error('Failed to remove data for some devices:', error);
			      setConfirmMessage(l_v.update_speed_request_decline(error));
			    });
		 }else{
			alert(l_v.no_active_device)
		}
	}
	onUpdateRealSensorSocket = newRealSocketsDto => {
		
		console.log(`%c ONUPDATEREALSENSORSOCKET newRealSocketsDto -`, "color: green; font-weight: 400; text-transform:uppercase", newRealSocketsDto)
		this.setState({
			realSensorSockets: SL.copyOfObject(newRealSocketsDto) 
		});
	}
	render() {
		const {
			owner,
			activeSerial,
			licensePlate,
			type,
			virtualSensors,
			activePeriodId,
			calibrations,
			currentDeviceInfo,
			linked_CalibrationIds,
			linked_VirtualSensorIds,
			linked_RealSensorIds,
			row_filter_enabled,
			deviceInfo,
		} = this.state;
		const { transportName } = this.props.match.params;		
		const gasStation = type == 'VEHICLE'? false : true;
		const title = gasStation ? `${l_v.gas_station} - ` : `${l_v.auto} - `;
		const contextValue = {
			linked_CalibrationIds,
			linked_VirtualSensorIds,
			linked_RealSensorIds,
			row_filter_enabled,
			VirtualSensorRow:	this.VirtualSensorRow,
			RealSensorRow:	this.RealSensorRow,
			CalibrationRow:	this.CalibrationRow,
		};
	    return (
	      	<div className = 'transport_page'>
	      		<Helmet title= {title + transportName}/>

		      	<SeparPermission  	type = 'page'
			      					alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
			      					kindCapability = 'editVehicles'/>
		      	<Navigation />
		      	<div className='container content'>
		      		<PageName 	transportName = {this.props.match.params.transportName}
		      						licensePlate = {licensePlate}
			        		  			vehicleId = {this.props.match.params.transport_id}/>
               	<LinkToVehicleOwner owner = {owner}
               						vehicleInfo = {{
        				       							vehicleId: this.state.id,
        				       							vehicleName: transportName,
        				       							licensePlate: licensePlate
        				       						}}
               						vehicleId = {this.state.id}
               						gasStation = {gasStation}/>

			       	<CurrentInfo 	vehicleId = 		{this.state.id}
			       					activePeriodId = 	{activePeriodId}
			       					deviceSerial = 		{activeSerial}/>
			       	<FastCommandsToDevice 	vehicleId = 		{this.state.id}
					       					activePeriodId = 	{activePeriodId}
					       					deviceSerial = 		{activeSerial}/>
               	<SimpleVehicleSettings 	{...this.state}
						               	deviceType = 				{ifNoKey(deviceInfo, 'deviceType')}
				        		  				upateSpeedByPeriod =      {this.upateSpeedByPeriod}
				        		  				updateName =              {this.updateName}
				        		  				enterToState =            {this.enterToState}
				        		  				gasStation =              {this.props.gasStation}
				        		  				vehicleOwner =            {this.props.match.params.owner}
				        		  				vehicleId =               {this.props.match.params.transport_id}
				        		  				vehiclesGroups =          {this.state.groups}
				        		  				updateDecs =              {this.updateDecs}
				        		  				updateTrans =             {this.updateTrans}
				        		  				updateInformation =       {this.updateInformation}
				        		  				correctIgnition =         {this.correctIgnition}
				        		  				removeDataFromСassandra = {this.removeDataFromСassandra}
				        		  				removeDataBySensor =      {this.removeDataBySensor}
				        		  				user_permission =         {this.props.user_permission}/>
			        <div className='edit_device_on_transport_container' id = 'this_equipment'>
						<p className="nameForm">{l_v.mounted_trackers}</p>
						<LinkToActiveDevice owner = {owner} activeSerial = {activeSerial} />
						<DeviceOnTransport 	vehicleId = 		{this.state.id}
													isGasStation = 		{gasStation}
													deviceVStransport = {this.state.deviceVStransport} />
						<MountDeviceForm 	devices = 	{this.state.device_}
												isGasStation = {gasStation}
												onMounDevice = {this.getInitialInfo}//crutch
			       							vehicleId = {this.state.id}/>
					</div>
					<HigLightContext.Provider value = {contextValue}>
			       	<VirtualSensors 	list = 		{virtualSensors}
			       							addVirtualSensorToList = {this.addVirtualSensorToList}
			       							updateVirtualSensorInList = {this.updateVirtualSensorInList}
			       							removeVirtualSensorFromList = {this.removeVirtualSensorFromList}
												vehicleId = {this.state.id}/>
						<Calibrations 	vehicleId = {this.props.match.params.transport_id} />
						<RealSensors 	vehicleId =                  {this.state.id}
											sensorList =                 {this.state.realSensorSockets}
											calibrations =               {calibrations}
											virtualSensors =             {this.state.virtualSensors}
											addRealSensorsToList =       {this.addRealSensorsToList}
											onUpdateRealSensorSocket =   {this.onUpdateRealSensorSocket}
											removeRealSensorFromList =   {this.removeRealSensorFromList}/>
						<MultiSensors 	virtualSensors = {this.state.virtualSensors}
										realSensorSockets = {this.state.realSensorSockets}
										vehicleId = {this.props.transportId}
										addMultiSensorToList = {this.addMultiSensorToList}
										calibrationInfo = {calibrations}
										multiSensors = {this.state.multiSensors}
										removesensorID = {this.removesensorID}/>
					</HigLightContext.Provider>
			      	<SeparPermission  	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
				      					kindCapability = 'editSensors'>
						<Templates 	mountedDevice = 	{this.state.deviceVStransport}
									vehicleId = 		{this.state.id}/>
					</SeparPermission>

					<VehicleSettings 	vehicleId = {this.state.id}
											onUpdateSettings  = {(newDTO)=>this.setState({...newDTO})}
											additionalSettings = {
																{
																	// minimalAmountImpulse: this.state.minimalAmountImpulse, //not used
																	// fuelingWithoutConnection: {this.state.fuelingWithoutConnection} //not used
																	tankVolume: 		this.state.tankVolume,
																	tankVolumeStart: 	this.state.tankVolumeStart,
																	// syncRemainders: 	this.state.syncRemainders,//not used
																	isObtainFuelConsumptionByDut: this.state.obtainFuelConsumptionByDut,
																	// enableLightInterface: this.state.enableLightInterface,//not used
																}
															}
										type = 		{type}
										settings =  {this.state.settings}/>
					<DeviceSettings deviceSerial = {activeSerial} />
					<Preloader ref = {preloader => this.preloader = preloader} />
					{/*this.graphics(this.state.showGraph)*/}
		        </div>
	      	</div>
	    )
	}
}
export default connect(
	    state => ({
	    	login: 					state.authorizationR.login,
			token: 					state.authorizationR.token,
			user_permission: 		state.authorizationR.user_permission,
			ThisTransport: 		state.devicesR.ThisTransport,
			deviceInfo: 			state.devicesR.deviceInfo,
			deviceVStransport: 	state.devicesR.DeviceVStransport_connect,
	    })
)(TransportPage)



function FastCommandsToDevice(props) {
	const {vehicleId, activePeriodId, deviceSerial} = props;
	return 	<div className = 'fast_command'>
				<ReRequestData serial = {deviceSerial} linkHide = {true}/>
				<CommandButton deviceSerial = {deviceSerial} />
			</div>
}

function CommandButton(props){
	let {deviceSerial} = props;
	function sendCommand(e) {
		switch(e.target.name){
			case("reset"):
				alert("Мы думаем над этим, до 14.03.2020 должны придумать")
			break;
			case("disconect"):
				devicesApi.disconectDevice(deviceSerial)
			break;
			case("switchOff"):
				devicesApi.changeEnableStatus(deviceSerial, value, changeStatus, name)
			break;
		}
	}
	return <div className = "command_toDevice_in_mount">
				<button type = "button"
						name = "reset"
						className="btn_black addsensor"
						disabled = {true}
						onClick = {sendCommand}>
						{l_v.reset_authorization}
				</button>
				<button type="button"
						name = "disconect"
						className="btn_black addsensor"
						onClick = {sendCommand}>
						{l_v.disconnect}
				</button>
				{/*<button type="button"
						name = "switchOff"
						className="btn_black addsensor"
						onClick = {sendCommand}>
						Выключить
				</button>*/}
			</div>
}

function PageName(props){
	let {transportName, newTransportName, vehicleId, statusFormName, callHideFormName, callShowFormName, enterToState, updateName, licensePlate} = props;
	return 	<h1 className =	"page_name_transport">
	        	{transportName} <span className = 'license_plate'>{licensePlate}</span>
	        	<p className = 'id_in_head'>ID: {vehicleId} </p>
	        </h1>
}

function FormForName(props){
	let {status, enterToState, newTransportName, callHideFormName, callShowFormName, updateName} = props;

	return 	<div className='form_change_vehicle_name'>
					<p className = 'nameForm'>{l_v.auto_name}</p>
					<p className = 'form_message'>{newTransportName ? null : l_v.name_cannot_be_empty}</p>
					<input 	className = 	'input'
							type = 			'text'
							name = 			'newTransportName'
							value = 		{newTransportName}
							placeholder = 	{l_v.new_name}
							onChange = 		{enterToState}/>
				    <button className = 'btn_black'
				    		disabled = {newTransportName?false:true}
				    		onClick = 	{updateName}>
				    	{l_v.change_name}
				    </button>
			</div>
}

function LinkToActiveDevice(props){
	let {owner, activeSerial} = props;
	return !!activeSerial?
			<Link 	className='link_to_device'
					to = {`/device/${activeSerial}`}>
					{l_v.commands_to_device} №{activeSerial}
			</Link>:
			null
}

function DeviceOnTransport (props) {
	let { deviceVStransport, vehicleId } = props;
	return arrayIsEmpty(deviceVStransport) ?
			deviceVStransport.map(key => <EditMountedDeviceForm 	deviceData = 	{key}
													                      	isGasStation = {props.isGasStation}
																			   	key =			{key.id}
																			   	periodId = 		{key.id}
																			   	transportId = 	{vehicleId} />) :
			l_v.no_data
}

function SimpleVehicleSettings(props) {
	return 	<Fragment>	
					<div className = "form_block on_transportpage" id = "info_about">
						<div>
			    	       	<FormChangeDesc desc = 			{props.updateDecs}
			    							form_name = 	{l_v.description}
			    							placeholder = 	{`${l_v.current_desc}:`}
			    							currentdesc = 	{props.description}/>
		    				<FormOwnerOfTransport 	owner = {props.vehicleOwner}
		    										vehicleId = {props.vehicleId}/>
		    			</div>
		    			{
		    				props.deviceType == "GLOBUS" ?
				    			<FormPeriod dataFromform = {props.updateInformation}
					    						updateTrans = 	{props.updateTrans}
					    						form_name = 	{`${l_v.data_transfer_period}:`}
					    						name = 			{'reportPeriods'}
					    						kindUser = 		{ifNoKey(props.user_permission, 'kind')}
					    						input_value = 	{{
					    											period1:  props.period1,
					    											period2: props.period2,
					    											minPeriod1:  props.minimalPeriod1,
					    											minPeriod2:  props.minimalPeriod2
					    										}}
					    						returnMinimalPeriod = {props.returnMinimalPeriod}
					    						placeholder = 	{l_v.data_transfer_period}
					    						btntext = 		{l_v.change_period}/>: null
		    			}
		    			
		    			<div>
			    			<FormForName 	enterToState = {props.enterToState}
				        					newTransportName = {props.newTransportName}
				        					updateName = {props.updateName}/>
			    			{
			    				!props.gasStation?
			    				<FormInput	dataFromform = 	{props.updateInformation}
				    						form_name = 	{`${l_v.vehicle_number}:`}
				    						name = 			{'licensePlate'}
				    						input_value = 	{props.licensePlate}
				    						placeholder = 	{l_v.enter_vehicle_number}
				    						btntext = 		{l_v.change_number}/>:
			    				null
			    			}
			    			<FormInput	dataFromform = 	{props.updateInformation}
			    						form_name = 	{`${l_v.width_of_vehicle}:`}
			    						name = 			{'width'}
			    						input_value = 	{props.width}
			    						placeholder = 	{'1.5'}
			    						btntext = 		{l_v.update_width}/>

		    			</div>
		    			<div>
				    			<GroupsVehicle	vehicleOwner = {props.vehicleOwner}
					    							vehicleId  = {props.vehicleId}
					    							vehiclesGroup = {props.vehiclesGroups}/>
		    			</div>
		    		</div>
		    		<div className = 'correction_block'>
    				    			<SeparPermission 	alowed = 'ADMIN, SUPERADMIN'
    										    			type =           'component'
    										    			kindCapability = 'isTrusted'>
    				    				<IgnitionCorrectionForm correctIgnition = {props.correctIgnition} />
    			    				</SeparPermission>
    				    			<SeparPermission 	alowed = 'ADMIN, SUPERADMIN'
    										    			type =           'component'
    										    			kindCapability = 'isTrusted'>
    										    			<Fragment>
    					    					    				<ClearDataByDeviceForm removeDataFromСassandra = {props.removeDataFromСassandra} />
    					    					    				<ClearDataBySensorForm removeDataBySensor = {props.removeDataBySensor} />
    					    					    				<SpeedCorrectionForm upateSpeedByPeriod =  {props.upateSpeedByPeriod}	/>
    			    					    				</Fragment>
    			    				</SeparPermission>
		    		</div>
	    		</Fragment>
}

function MultiSensors(props) {
	return  <div className = 'one_block'>
				<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
							      	kindCapability = 'editSensors'>
					<AddMultiSensor vehicleId = 		{props.vehicleId}
		       						realSensorSockets = {props.realSensorSockets}
		       						virtualSensors = 	{props.virtualSensors}
		       						addMultiSensor = 	{props.addMultiSensorToList}
		       						calibrationInfo = 	{props.calibrationInfo}/>
				</SeparPermission>
	       	<ExistingMultisensors 	data = 				{props.multiSensors}
		       								removesensorID = 	{props.removesensorID}
		       								calibrationInfo = {props.calibrationInfo} />
			</div>
}

function sensorOnLastDevice(arr){
	if(Array.isArray(arr) && arr.length !== 0){
		return arr[arr.length-1].sensors
	}else return undefined
}

function addToList(arr, element){
	if(arrayIsEmpty(arr)){
		arr.push(element)
	}else{
		arr = [element];
	}
	return doCopy(arr)
}

function getSocketIndex(realSensorId, socketArray) {
	let indexOfSocket = socketArray.findIndex(key =>{
		let inThisKey = false;
		if(arrayIsEmpty(key.realSensors)){
			for(let realSensor in key.realSensors){
				if(key.realSensors[realSensor].realSensorId == realSensorId){
					inThisKey = true
				}
			}
		}
		if(inThisKey){
			return key
		}
	})
	return indexOfSocket
}

