import React from 'react'
import language_variant from '../../store/languages.jsx'
import CounterEditor from './Counter_editor.jsx'
import SwitchInput from '../component/SwitchInput.jsx'


class TransportCounters extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			counter_change_oil: true,
			change_oil_value: 8623,

			next_maintenance: true,
			next_maintenance_value: 5000,

			change_rubber: true,
			change_rubber_value: 3000,
		}
		
	}
	enterToStateInput = (value, name)=>{
		this.setState({
			[name]: value
		}, ()=>this.props.sendToServer())
	}
	render(ReactElement, DOMElement, callback){
		return (
				<div className='profile_container'>
					<div className='counter_container'>
						<div className='counter_switch'>
							<p className='text_info'>{language_variant.change_of_oil}</p>
							<SwitchInput 	    result = {this.enterToStateInput} 
												isChecked = {this.state.counter_change_oil}
												name='counter_change_oil' 
												tooltip={{on: language_variant.disable_counter, off: language_variant.enable_counter}}/>
						</div>
						<CounterEditor 	value={this.state.change_oil_value}
										disabled={this.state.counter_change_oil}
										result = {this.enterToStateInput}
										name='change_oil_value' 
										units={language_variant.km}
										/>
						
					</div>
					<div className='counter_container'>
						<div className='counter_switch'>
							<p className='text_info'>{language_variant.change_rubber}</p>
							<SwitchInput 	    result = {this.enterToStateInput} 
												isChecked = {this.state.change_rubber}
												name='change_rubber' 
												tooltip={{on: language_variant.disable_counter, off: language_variant.enable_counter}}/>
						</div>
						<CounterEditor 	value={this.state.change_rubber_value}
										disabled={this.state.change_rubber}
										result = {this.enterToStateInput}
										name='change_rubber_value' 
										units={language_variant.km}
										/>
						
					</div>
					<div className='counter_container'>
						<div className='counter_switch'>
							<p className='text_info'>{language_variant.next_maintenance}</p>
							<SwitchInput 	    result = {this.enterToStateInput} 
												isChecked = {this.state.next_maintenance}
												name='next_maintenance' 
												tooltip={{on: language_variant.disable_counter, off: language_variant.enable_counter}}/>
						</div>
						<CounterEditor 	value={this.state.next_maintenance_value}
										disabled={this.state.next_maintenance}
										result = {this.enterToStateInput}
										name='next_maintenance_value' 
										units={language_variant.km}
										/>
						
					</div>
				</div>
			)
	}
} 
export default TransportCounters;