import React 			from 'react';
import bindingToRoad 	from '../utils/bindingToRoad.js'
// import axios 			from 'axios'
import bugReport 		from '../../../actions/bugReport.jsx'
import {equals}			from '../../../actions/ActionCreator.jsx'

export default class GetDirections extends React.Component{ //use directionApi
	constructor(props) {
		super(props);
		this.state = {
			map: 				this.props.map,
			points: 			undefined,
			directionsDisplay: 	undefined,
			directions_info: 	undefined
		}
	}
	componentWillReceiveProps(nextProps){
		let points = nextProps.points;
		if (!!nextProps.points && nextProps.points.length>=2&& !this.state.points){
			this.setState({
				map: nextProps.map,
				points: nextProps.points
			})
			this.intialRoad(nextProps.points)
		} 
		if(!!nextProps.clear){
			this.clearWay();
		}
	}
	clearWay = () =>{
		let map = this.state.map,
			directionsDisplay = this.state.directionsDisplay
		if(!!directionsDisplay){
			directionsDisplay.setMap(null);
    		directionsDisplay = null;
    		this.setState({
				map: map,
				directionsDisplay: directionsDisplay,
				points: undefined,
			})
		}

	}
	intialRoad = (points)=>{
		let map = 				this.state.map,
		 	copy_points = 		points,
		 	waypts	=			[],
		 	directionsService = new google.maps.DirectionsService(),
			directionsDisplay = new google.maps.DirectionsRenderer({
																	    polylineOptions: {
																	      	strokeColor: '#4079f8',//
										   	 								strokeWeight: 6
																	    },
																	    draggable: this.props.draggable,

									});
			directionsDisplay.setMap(map);
			directionsDisplay.setOptions( { suppressMarkers: true } );//hide default marker
			// console.log( 'stopover', this.props.stopover, 'optimizeWaypoints', this.props.optimizeWaypoints, points );
		
		if(copy_points instanceof Array && copy_points.length>2){
			copy_points = copy_points.slice(1, copy_points.length-1);
			for(let i=0; i<=copy_points.length-1; i++){
				console.log('waypts', copy_points[i])
				waypts.push({
								location: new google.maps.LatLng(copy_points[i][0], copy_points[i][1]),
								stopover: this.props.stopover,
							})
			}
		}

	  	let request = {
			    origin: 	{
						      	"lat" : points[0][0],
			               		"lng" : points[0][1]
		               		},
		        waypoints: 	waypts,    
		        optimizeWaypoints: this.props.optimizeWaypoints,   	

			    destination:{
				               "lat" : points[points.length-1][0],
				               "lng" : points[points.length-1][1]
				            },
			    travelMode: 'DRIVING'
		};
		directionsService.route(request, function(response, status) {
			if (status == 'OK') {
				console.log('%c response', 'font-size: 21px;', response)
			  	directionsDisplay.setDirections(response);
			  	// computeTotalDistance(response);
			}
		});
		directionsDisplay.addListener('directions_changed', () => {
			this.setState({
				directionsDisplay: directionsDisplay,
				directions_info: directionsDisplay.getDirections()
			})
			this.props.returnPoly({
									info: 		directionsDisplay.getDirections(), 
									distance:  	computeTotalDistance(directionsDisplay.getDirections()),
									map: map,
								})
        });
        function computeTotalDistance(result) {
	        var total = 0;
	        var myroute = result.routes[0];
	        for (var i = 0; i < myroute.legs.length; i++) {
	          total += myroute.legs[i].distance.value;
	        }
	        total = total / 1000; //in km
	        return total
      	}
			this.setState({
				map: map,
				directionsDisplay: directionsDisplay,
				directions_info: directionsDisplay.getDirections()
			})
	}
	
	decode(encoded){//doesn't used but can  be need (return from coded polilne array of coordinates)
		    var points=[ ]
		    var index = 0, len = encoded.length;
		    var lat = 0, lng = 0;
		    while (index < len) {
		        var b, shift = 0, result = 0;
		        do {

		    b = encoded.charAt(index++).charCodeAt(0) - 63;//finds ascii                                                                                    //and substract it by 63
		              result |= (b & 0x1f) << shift;
		              shift += 5;
		             } while (b >= 0x20);
		       var dlat = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
		       lat += dlat;
		      shift = 0;
		      result = 0;
		     do {
		        b = encoded.charAt(index++).charCodeAt(0) - 63;
		        result |= (b & 0x1f) << shift;
		       shift += 5;
		         } while (b >= 0x20);
		     var dlng = ((result & 1) != 0 ? ~(result >> 1) : (result >> 1));
		     lng += dlng;
		 
		   points.push({latitude:( lat / 1E5),longitude:( lng / 1E5)})  
		 
		  }
		  return points
	}
	render(){
		return null
	}
}