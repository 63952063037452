import React, { Component } from 'react';
import Users                from 'actions/users.js'
import { connect }          from 'react-redux'
import Pop_up               from './Pop_up.jsx'
import SL                   from 'sl-library'
import l_v                  from "store/languages.jsx"



class CapabilityEditor extends Component {
	constructor(props){
		super(props);
		this.state = {
			kindUser: 	this.props.kindUser,
			capabilitiesOfUserWhoEdit: SL.ifNoKey(this.props.userProfileOfUserWhoEdit, 'capabilities'),
			availableCapabiliesForRole: null,

		}
		this.initialState = this.state;
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if(nextProps.kindUser !== prevState.kindUser){
			return  {
						kindUser: nextProps.kindUser,
					}
		}
		return null

	}
	componentDidUpdate() {
		const {kindUser, availableCapabiliesForRole} = this.state;
		const {userCapabilities} = this.props;
		!availableCapabiliesForRole ?
		Users.getCapabilityByRole(kindUser, availableCapabiliesForRole => {
			const capState = {};
			const modifiedAvailableCapabilities = removeCapabilityesDisabledInOwner()
			availableCapabiliesForRole.forEach(cap => capState[cap.name] = false);
			userCapabilities.forEach(cap => capState[cap.name] = cap.value);
			this.setState({
				availableCapabiliesForRole: availableCapabiliesForRole,
				...capState
			})
		}):
		null
	}
	updateCapability = (e) => {
		/*	const {userCapabilities} = this.props;
		userCapabilities[0].value = false;*/
		Users.updateCapabilityNewV(
				[{
					name: e.target.name,
					value: e.target.checked
				}],
				this.props.userLogin,
				(newCapability) => {
					const capState = {};
					const {kindUser, availableCapabiliesForRole} = this.state;
					const modifiedAvailableCapabilities = removeCapabilityesDisabledInOwner()
					availableCapabiliesForRole.forEach(cap => capState[cap.name] = false);
					newCapability.forEach(cap => capState[cap.name] = cap.value);
					this.setState({
						availableCapabiliesForRole: availableCapabiliesForRole,
						...capState
					})
				}
		)
	}

	render() {
		const {availableCapabiliesForRole} = this.state;
		return 	<div className = 'userCapability_form'>
					<p className = 'nameForm'>{l_v.user_rights}</p>
					<button className = 'btn_black'
							onClick = { ()=>this.capabilitiesForm.open() }>
							{l_v.change_user_rights}

					</button>
					<Pop_up head_text = 	{l_v.user_rights}
	        	            head_back = 	{{backgroundColor:"rgb(112, 128, 144)", color: 'white', fill: 'white'}}
	        	            ref = 			{(popup) => this.capabilitiesForm = popup}
	        	           	popUp_size = 	{{maxWidth: '99%', width: '500px', height: '600px'}}>
	        	           	<div className = 'pop_up_content'>
								<CapabiliesList updateCapability = 				{this.updateCapability}
												availableCapabiliesForRole = 	{availableCapabiliesForRole}
												state = 						{this.state} />
							</div>
					</Pop_up>
				</div>
	}
}

function CapabiliesList(props) {
	const {availableCapabiliesForRole} = props;
	return 	SL.arrayIsEmpty(availableCapabiliesForRole) ?
			availableCapabiliesForRole.map(el=> <CapCheckBox  	{...el}
			                               						checked = 			{props.state[el.name]}
			                               						updateCapability = 	{props.updateCapability}
								                              	key = 				{el.name}
			                               						/> ) :
			null
}

function CapCheckBox(props) {
	return <p 	key = {name}
				className = 'user_capabilities'>
				<input  type = 				"checkbox"
						id =				{props.name}
						name = 				{props.name}
						data-capname = 		{props.capname}
						className = 		'select_checkbox'
						defaultChecked = 	{props.checked}
						onChange = 			{props.updateCapability}/>
				<label 	htmlFor = 	{props.name}
						className = 'select_label'>
						{l_v[props.name]}
				</label>
			</p>
}



export default connect(
    state => ({
    	loginOfUserWhoEdit: 		state.authorizationR.login,
		token: 						state.authorizationR.token,
		userProfileOfUserWhoEdit: 	state.authorizationR.user_permission,
    })
)(CapabilityEditor)


function removeCapabilityesDisabledInOwner(ownerCapilies, roleCapabilites) {
	//algorithm
}


//вот те права которые запрещены для ролей
const forbidenCapabilitiesByRole = {
	DEALER: [
		'createAdminUsers',
		'editOther'
	],
	CLIENT: [
		'editOther',
		'editClients',
		'editSensors',
		'editDevices',
		'deleteSensorTypes',
		'seeStats',
		'recalcSensors',
		'createAdminUsers',
		'setBlocking',
		'getSmsAuthCode',
	],
	USER: [
		'editOther',
		'editClients',
		'editSensors',
		'editDevices',
		'deleteSensorTypes',
		'seeStats',
		'recalcSensors',
		'createAdminUsers',
		'setBlocking',
		'getSmsAuthCode',
		'rename',
		'changePeriods',
		'isAllowedToEnterAdmin',
	]
}
//вот то что обязательное для роли
const availableCapabiliesForRole = {
	ADMIN: [
		'receivePos',
		'setOuts',
		'isAllowedToEnterAdmin',
		'setLock',
		'setAlarmDone',
		'seeRoutes',
		'seeReports',
		'seeFuel',
		'seeCharts',
		'rename',
		'changePeriods',
		'editDriverNames',
		'editVehicles',
		'editZones',
	],
	CLIENT: [
		'setOuts',
		'changePeriods',
		'setLock',
		'setAlarmDone',
		'seeRoutes',
		'seeReports',
		'seeFuel',
		'seeCharts',
		'rename',
		'receivePos',
		'editZones',
		'editVehicles',
		'editDriverName',
	],
	DEALER: [
		'setAlarmDone',
		'changePeriods',
		'editDriverNames',
		'editVehicles',
		'editZones',
		'receivePos',
		'rename',
		'seeCharts',
		'seeFuel',
		'seeReports',
		'seeRoutes',
		'setLock',
		'isAllowedToEnterAdmin',
		'setOuts',
	],
	SUPERADMIN: [
		'setAlarmDone',
		'seeStats',
		'seeRoutes',
		'seeReports',
		'seeFuel',
		'changePeriods',
		'deleteSensorTypes',
		'createAdminUsers',
		'seeCharts',
		'rename',
		'receivePos',
		'recalcSensors',
		'getSmsAuthCode',
		'editZones',
		'editVehicles',
		'editSensors',
		'editOther',
		'editDriverNames',
		'editDevices',
		'editClients',
		'setOuts',
		'isAllowedToEnterAdmin',
		'setLock',
		'setBlocking',
	],
	USER: [
		'editDriverNames',
		'seeFuel',
		'seeRoutes',
		'seeReports',
		'editZones',
		'setAlarmDone',
		'setLock',
		'seeCharts',
		'setOuts',
		'receivePos',
	]
};
//А вот те которые можно добавить если их нет  (если у того, кто редактирует - есть, то он может добавить разрешение)
const optionalCapabilityForRole = {
	ADMIN: [
		'createAdminUsers',
		'deleteSensorTypes',
		'editClients',
		'editDevices',
		'editOther',
		'editSensors',
		'editVehicles',
		'recalcSensors',
		'seeStats',
		'setBlocking',
	],
	CLIENT: [
		'changePeriods',
		'editDriverNames',
		'editZones',
		'getSmsAuthCode',
		'receivePos',
		'rename',
		'seeCharts',
		'seeFuel',
		'seeReports',
		'seeRoutes',
		'setAlarmDone',
		'setLock',
		'setOuts',
	],
	USER: [
		'changePeriods',
		'editDriverNames',
		'editZones',
		'getSmsAuthCode',
		'receivePos',
		'rename',
		'seeCharts',
		'seeFuel',
		'seeReports',
		'seeRoutes',
		'setAlarmDone',
		'setLock',
		'setOuts',
	]
};
