import store from 'store/configureStore.jsx'
import SL 						from 'sl-library'

export default function infoStore(path) { //path must be without "state"
	let state = store.getState();
	if(!path) {
		return {
			login:            state.authorizationR.login,
			bonus_url_device: state.authorizationR.bonus_url_device,
			bonus_url_api:    state.authorizationR.bonus_url_api,
			bonus_url_user:   state.authorizationR.bonus_url_user,
			token:            state.authorizationR.token,
			base_url:         state.authorizationR.base_url,
			bonus_prefix:     state.authorizationR.bonus_prefix,
			url_before_dep:   state.authorizationR.urlbeforedep,
			admin_prefix: 	  state.authorizationR.admin_prefix,
			language: 		  state.languageR.language,
			langUppercase: 	  SL.doUperCase(state.languageR.language),
			accountType: 	  state.authorizationR.accountType,
			user_permission:  state.authorizationR.user_permission,

		}
	} else {
		if(typeof path == 'string') {
			let pathValue = path.split('.').reduce((obj, key) => {
				return obj && obj[key];
			}, state);
			return pathValue
		} else {
			console.log("%c path must be sting like 'key.secondKey', your path:", 'color: red; font-weight: bold;', path)
		}
	}
}