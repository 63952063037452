import React from 'react'
import language_variant from '../../store/languages.jsx'
import ClickOutside  from 'react-click-outside'//   npm install tj/react-click-outside
import SL from "sl-library"


class EditorValue extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			editable: SL.isBoolean(props.editable, true),
			value: this.props.value,
			form_is_active: false,
			temp_value: this.props.value
		}
	}

	static getDerivedStateFromProps(props, state) {
		return {
			value: 		props.value,
			editable:   SL.isBoolean(props.editable, true),
			temp_value: !state.form_is_active ? props.value : state.temp_value,
		}
	}
	save = () => {
		const { temp_value } = this.state;		
		const { name } = this.props;
		this.props.returned(temp_value, name)
		this.toogleActive()
	}
	enterToState = (e) => {
		const { name } = this.props;
		this.setState({
			temp_value: SL.transformParametersByRules(e.target.value, name)
		})
	}
	toogleActive = () => {
		this.setState({ form_is_active: !this.state.form_is_active })
	}
	cancelEdit = () => {
		const {value} = this.props;
		this.setState({ value: value, temp_value: value }, this.toogleActive)
	}
	onKeyPress = e => {
		if(e.key === "Enter" && !this.save_btn_is_disabled) this.save()
	}
	get save_btn_is_disabled(){
		const { temp_value, min, max } = this.state;
		const value_is_empty = typeof temp_value == 'string' && temp_value.length == 0;
		const value_is_less_then_min = SL.isNumeral(min) && min > temp_value;
		return value_is_less_then_min || value_is_empty;
	}
	render(ReactElement, DOMElement, callback){
		const { value, form_is_active, temp_value } = this.state;		
		const {units, editable, disabled, min} = this.props;
		const containerStyle = disabled ? { opacity: '0.3' } : null;
		const styleEditBtn = editable ? {visibility: 'visible' } : {visibility: 'hidden' }
		return 	<div className='editor_contrainer' style = {containerStyle}>
					<p className='text_info'>{this.props.text}</p>
					<div className='input_container'>
						<input 	type = 'text' 
								className = 'editor_input'  
								value = {temp_value} 
								disabled = {true}/>
						<span> {units}</span>
						<button className = 'edit_btn'
								onClick = 	{ this.toogleActive }
								disabled = 	{ !editable}
								style = 	{ styleEditBtn }></button>
					</div>
					{
						form_is_active ?
						<ClickOutside onClickOutside = { this.cancelEdit }>
								<div 	className='editor_form' 
										style = { {
											visibility: 'visible',
											opacity: 1,
										} } >							
										<input 	type = 		'text'
												className = 'form_input'
												value = 	{ temp_value }
												onChange = 	{ this.enterToState }
												autoFocus
												onFocus = 	 { e => e.target.select()}
												onKeyPress = { this.onKeyPress }/>
										<button className = 'primari_btn' 
												disabled = 	{ this.save_btn_is_disabled }
												onClick = 	{this.save}>									
												{language_variant.save}
										</button>					
								</div>
								<ValidateMessage min = { min } value = { value }/>
							</ClickOutside> :
						null

					}
				</div>
	}
	
}
export default EditorValue;





function ValidateMessage(props) {
	const validate_text_styles = {
			display: SL.isNumeral(props.min) ? 'block' : 'none',
			visibility: props.min <= props.value ? 'hidden' : 'visible'
		};
	return <div 	className = 'not_allowed'
	 				style = 	{validate_text_styles}>
	 			Должно быть не меньше { props.min }
	 		</div>
}