import React, { Component } from 'react'
import l_v from 'store/languages'
import { arrayIsEmpty } from 'sl-library'
import moment from 'moment'
import _ from 'lodash'
import "css/reports_page.scss"
// import ReportContext             from "./ReportContext.js"
export function ReportViewNoData(props) {
	const { reportData } = props;
	const textAboutProblem = reportData == 'Fuel sensor is not install' ? l_v.Fuel_sensor_is_not_install : l_v.try_other_period;
	console.log("%c reportData, reportData === null", coCSS, reportData, reportData === null)
	return reportData === null ?
		<div className = 'one_table' >
					<TableName {...props} />
					<div className = 'no_data_table'>
						<p className = 'no_data_period'>{l_v.no_data}</p>
						{/*<p className = 'grey'>{l_v.try_other_period}</p>*/}
					</div>
				</div> : //"some_problem"
		<div className = 'one_table' >
					<TableName {...props} />
					<div className = 'no_data_table'>
						<p className = 'no_data_period'>{l_v.no_data}</p>
						{/*<p className = 'grey'>{textAboutProblem}</p>*/}
					</div>
				</div>
}
export function ReportTable(props) {
	return <div className = 'one_table' >
					<TableName {...props} />
					{
						props.reportData.map((key, index) => <TableContent 	key = {index}
																			data = {key.rows}
								                							subNameTable = {key.reportTableName}/>)
					}


				</div>
}

function TableContent(props) {
	const { data, subNameTable } = props;
	let content;
	if(arrayIsEmpty(data)) {
		let headLine = data[0].cells;
		content = data.map((key, index) => {
			if(!!index) { //index==0 is header
				return <tr key = {index} className='one_line'>
							{
								key.cells.map((oneCell, index) => <OneCell key = {index} oneCell = {oneCell.values} />)
							}
						</tr>
			}
		})
	}
	if(arrayIsEmpty(data)) {
		return <div className = 'table_container'>
				{!!subNameTable ? <h3 className = 'subNameTable'>{subNameTable}</h3> : null }
				<table className='table'	cellSpacing="0">
						<tbody>
							<TableHeader  headCells = {data[0]}/>
							{content}
						</tbody>
				</table>
			</div>
	} else {
		return <div className = 'table_container'><p className = 'no_data'>{l_v.no_data}</p></div>
	}
}

function TableHeader(props) {
	let { headCells } = props;
	let { cells } = headCells;
	return <tr className='table_head'>
				{
					cells.map((key, index) => {
						let value = arrayIsEmpty(key.values) ? key.values[0] : ''
						return <th key = {index} style = {{ width: key.name == 'date' ? '150px' : '' }}>
									{value}
								</th>
					})
				}
		   </tr>
}

function OneCell(props) {
	let { oneCell } = props;
	if(arrayIsEmpty(oneCell)) {
	return 	<td>
					{
						oneCell.map((el, index) => <p key = {index}>{el}</p> )
					}
			</td>
	} else {
		return <td >
					</td>
	}
}

function TableName(props) {
	const { name, licensePlate } = props;
	const LicensePlate = licensePlate ?
						<span 	className = 'license_plate'
								style = {{paddingRight: '10px'}}>
								{licensePlate}
						</span> :
						null;

	return 	name || licensePlate ?
			<div className = 'table_name' >
					{ LicensePlate }
					<span className = 'vehicle_name'> {name} </span>
			</div> :
			null
}
