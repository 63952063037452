import React, { Component } from 'react';
import Users from 'actions/users.js'
import {ReportTable, ReportViewNoData} from "./ReportDefault.jsx"
import { ifNoKey, arrayIsEmpty } from 'actions/ActionCreator.jsx'
import Preloader from 'modules/preloader.jsx'
export default class StatisticForAdmin extends Component {
	constructor(props){
		super(props);
		this.state = {
			reportData: undefined
		}
		this.initialState = this.state;
	}
	componentDidMount() {
		this.mounted = true;
		this.preloader.show()
		let setStatisticToState = (reportInfo) => {
			if(this.mounted){
				const reportTables = ifNoKey(reportInfo, 'report_table');
				this.setState({
								reportData: reportTables ? reportTables : reportInfo,
							}, this.preloader.hide);
			}
		}
		Users.getStatistics(setStatisticToState)
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	render() {
		let {reportData} = this.state;
		return (
			<div className = 'statistic_by_device'>
				<div className = 'report'>
					<div className = 'report_tables' id = 'reports'>
						{
							arrayIsEmpty(reportData) ?
							<ReportTable {...this.props} reportData = {reportData} /> :
							<ReportViewNoData {...this.props} reportData = {reportData}/>

						}
					</div>
				</div>
				<Preloader ref = {preloader => this.preloader = preloader} />
			</div>
		);
	}
}
