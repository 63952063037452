import React         from "react"
import ActionCreator from 'actions/ActionCreator.jsx'
import { connect }   from 'react-redux'
import CreatePortal  from "modules/component/CreatePortal.jsx"
import l_v           from "store/languages.jsx"

class Notification extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
			container_className: '',
			text_answer: ''//null 'some error habdcfsa asdasdas asdasdasd asdasdas asdasdasd'
		}
	}
	showAnswer = () => {
		const { text_answer } = this.state;		
		if(!!this.mount && text_answer){
			const time_out_time = text_answer.length > 20 ? 7000 : 2000;
			this.setState({ container_className: 'showpopup' })			
			setTimeout( ()=>{
				this.setState({
					container_className: '',
					text_answer: ''
				})
				}, time_out_time
			)
			ActionCreator.clear('clear')
		}
	}
	componentDidMount() {
		this.mount = true;
	}
	componentWillUnmount(){
		this.mount = false;
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.serveranswer !== nextProps.serveranswer || nextProps.serveranswer !== 'clear'){
			this.setState({
				text_answer: nextProps.textanswer,
			})
			setTimeout(
				this.showAnswer, 100
			)
		}
	}
	render(){
		const {text_answer, container_className} = this.state;
		return 	text_answer ?
					<CreatePortal idOfMountPlace = 'modal-root'>
						<div className={'answer_for_user ' + container_className} >
							<p>{text_answer}</p>
						</div>
					</CreatePortal>:
					null
	}
}


export class Message_for_User extends React.Component{
	constructor(props) {
		super(props);
		let status;
		if(!!this.props.message && this.props.messageStatus !== 'message_hidden'){
			status = 'message_visible';
		}else{
			status = 'message_hidden';
		}
		this.state = {
			message_text: this.props.message,
			status:  status,
			messageStatus: this.props.messageStatus
		}
	}
	componentWillReceiveProps(nextProps) {
		let status;
		if(!!nextProps.message && nextProps.messageStatus !== 'message_hidden'){
			status = 'message_visible';
		}else{
			status = 'message_hidden';
		}
		this.setState({
			message_text: nextProps.message,
			messageStatus: nextProps.messageStatus
		})
	}
	hide_message = () =>{
		this.setState({
			status: 'message_hidden'
		})
		this.props.returnstatus('message_hidden')
	}
	render() {
		let {message_text} = this.state;
		if(!!message_text){
			message_text = message_text.replace(/\n/g, '<br>');
		}
		return 	message_text ?
				<CreatePortal idOfMountPlace = 'modal-root'>
					<div className={"message " + this.state.status}>
						<svg	xmlns="http://www.w3.org/2000/svg"
						 		aria-labelledby="title"
						 		className = 'close'
						 		viewBox="0 0 16 16"
						 		onClick = {this.hide_message}>
								<title id="title">{l_v.notifications}</title>
								<polygon points="12.9,4.4 11.5,3 8,6.5 4.4,3 3,4.4 6.5,8 3,11.5 4.4,12.9 8,9.4 11.5,12.9 12.9,11.5 9.4,8 "/>
						</svg>
						<div className = 'message_text'
							 dangerouslySetInnerHTML={{__html: message_text}}
						/>

					</div>
				</CreatePortal>:
				null
	}
}


export default connect(
      state => ({
		serveranswer: state.clientsR.answer,
		textanswer: state.clientsR.textanswer
      })
)(Notification)
