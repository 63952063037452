import LocalizedStrings from "react-localization";
import all_translates from './vocabularyRU_UA_ENG .js'
import moment from 'moment'
function getVocabularyInLibraryFormat() {
    const wordsEN = {}, wordsRU = {}, wordsUA = {};

    for(let i in all_translates){
        wordsEN[i] = all_translates[i].eng
        wordsRU[i] = all_translates[i].ru
        wordsUA[i] = all_translates[i].ukr
    }

    console.log({
        en: wordsEN,
        ru: wordsRU,
        ua: wordsUA
    })
    return {
        en: wordsEN,
        ru: wordsRU,
        ua: wordsUA
    }
}


const language_variant = new LocalizedStrings(getVocabularyInLibraryFormat());


window.language_variant = language_variant

export function setMomentLocale(lang_name){
    const locales = {
        ru: 'ru',
        ua: 'uk',
        en: 'en-gb',
    };
    moment.locale(locales[lang_name]);
}

export default language_variant;




export const d3Locale = {//moment locales
    ru: {
            "dateTime": "%A, %e %B %Y г. %X",
            "date":     "%d.%m.%Y",
            "time":     "%H:%M:%S",
            "periods":  ["AM", "PM"],
            "days":     ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"],
            "shortDays": ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
            "months":   ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"],
            "shortMonths": ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"]
        },
    ua: {
            "dateTime": "%A, %e %B %Y г. %X",
            "date":     "%d.%m.%Y",
            "time":     "%H:%M:%S",
            "periods":  ["AM", "PM"],
            "days":     ["неділя", "понеділок", "вівторок", "середа", "четвер", "п’ятниця", "субота"],
            "shortDays": ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
            "months":   ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"],
            "shortMonths": ["січ", "лют", "бер", "квіт", "трав", "черв", "лип", "серп", "вер", "жовт", "лист", "груд"]
        },
    en: {
            "dateTime": "%A, %e %B %Y г. %X",
            "date":     "%d.%m.%Y",
            "time":     "%H:%M:%S",
            "periods":  ["AM", "PM"],
            "days":     ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
            "shortDays": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            "months":   ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            "shortMonths": ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"]
        }
}