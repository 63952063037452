import React, {
    Suspense,
    Fragment,
    useEffect,
    useState
}                               from "react";
import {
    Route,
    Switch,
    StaticRouter,
    Redirect
}                               from 'react-router-dom';
import formAction               from 'actions/formAction.js'
import initHelps                from "developHelps.js"

initHelps();
import AuthForm                 from 'modules/AuthForm.jsx'
import Clients                  from 'modules/Clients.jsx'
import ClientPage               from 'modules/ClientPage.jsx'
import Admins                   from 'modules/Admins.jsx'
import SelfPage                 from 'modules/SelfPage.jsx'
import UsersPage                from 'modules/UsersPage.jsx'
// import UserPage                 from 'modules/UserPage.jsx'
import Devices                  from 'modules/Devices.jsx'
import DevicePage               from 'modules/DevicePage.jsx'
import Transports               from 'modules/Transports.jsx'
import TransportPage            from 'modules/TransportPage.jsx'
import ArchiveCalibrationsPage  from 'modules/ArchiveCalibrationsPage.jsx'
import NotFound                 from 'modules/NotFound.jsx'
import LogsPage                 from 'modules/LogsPage.jsx'
import InitAdmin                from 'modules/initPage.jsx'
import BillingPage              from 'modules/BillingPage.jsx'
import WhatIsNewAfterUpdatePage from 'modules/WhatIsNewAfterUpdatePage.jsx'
import Translation              from "modules/Translation.jsx"
import IgnoredDevicesPage       from "modules/IgnoredDevicesPage.jsx"
import IgnoredUsersPage         from "modules/IgnoredUsersPage.jsx"
import TokensStatisticPage      from "modules/TokensStatisticPage.jsx"
import AuthInAppFromLinkPage    from "modules/AuthInAppFromLinkPage.jsx"
import WebsocketRouter          from 'modules/websocket_logic.jsx'
import ReatranslationServerPage from "modules/ReatranslationServerPage.jsx"
import 'css/main.scss'

function Loading(props) {
    return <div>{ this.props.text } { props.text ? ' loading...' : null }</div>
}
function GasStationPage(props) {
    return <TransportPage gasStation = {true} match = {props.match}/>
}
const App = () => {
    useEffect(() => {
        window.addEventListener('hashchange', controlAuthInfo);

        function controlAuthInfo() {
            if(formAction.isOrigin) formAction.checkToken() //in different situation we get error on api request and check token from bug report
        };

        formAction.checkToken(); //check token on first render
    }, []);
    return  <Suspense fallback = { <Loading text = 'pages' /> }>
                <Switch >
                    <ProtectedRoute path = "/"  exact    component = {AuthForm}/>

                    <ProtectedRoute path = "/clients"                        component = { Clients    }/>
                    <ProtectedRoute path = "/client/:userName/edit"          component = { ClientPage }/>

                    <ProtectedRoute path = "/devices"                        component = { Devices    }/>
                    <ProtectedRoute path = "/device/:device_id"              component = { DevicePage }/>

                    <ProtectedRoute  path = "/transports"                    component = { Transports }/>
                    <ProtectedRoute  path = "/transport/:owner/:transportName/:transport_id/editVehicle"
                            component = { TransportPage }/>
                    <ProtectedRoute  path = "/transport/:owner/:transportName/:transport_id/editGasStation"
                                    component = { GasStationPage }/>

                    <ProtectedRoute path = "/dialers"                      component = { () => <Admins like = "DEALER" />} />
                    <ProtectedRoute path = "/administrators"               component = { Admins }/>
                    <ProtectedRoute path = "/administrator/:userName/edit" component = { SelfPage }/>

                    <ProtectedRoute path = "/users"                    component = { UsersPage }/>
                    <ProtectedRoute path = "/authInAppByLink"          component = { AuthInAppFromLinkPage }/>

                    <ProtectedRoute path = "/calibrations"             component = { ArchiveCalibrationsPage }/>

                    <ProtectedRoute path = "/billing"                  component = { BillingPage } />

                    <ProtectedRoute path = "/logspage"                 component = { LogsPage }/>
                    <ProtectedRoute path = "/info/:kind/:id/logs"      component = { LogsPage }/>

                    <ProtectedRoute path = "/initSuperUser"            component = { InitAdmin }/>
                    <ProtectedRoute path = "/WhatIsNewAfterUpdatePage" component = { WhatIsNewAfterUpdatePage }/>
                    <ProtectedRoute path = "/translation"              component = { Translation }/>
                    <ProtectedRoute path = "/ignoredDevices"           component = { IgnoredDevicesPage }/>
                    <ProtectedRoute path = "/ignoredUsers"             component = { IgnoredUsersPage }/>
                    <ProtectedRoute path = "/tokensSession"            component = { TokensStatisticPage }/>
                    <ProtectedRoute path = "/retranslation_server/:server_id"     component = { ReatranslationServerPage }/>
                    <Route path = "*"                         component = { NotFound }                   />
                </Switch>
                <WebsocketRouter />
            </Suspense>
};
export default App;


// Компонент-обёртка, который проверяет, разрешён ли маршрут
const ProtectedRoute = ({ component: Component, path, ...rest }) => (
  <Route {...rest} render={(props) => {
    const isAllowed = checkPathAgainstPatterns(path); // Проверяем, соответствует ли текущий путь одному из разрешённых
    if (isAllowed) {
      return <Component {...props} />;
    } else {
      // Рендерим компонент NotFound или перенаправляем на него
      return <Redirect to="/not-found" />;
    }
  }}/>
);

function checkPathAgainstPatterns(hash) {
  const path = hash.startsWith('#') ? hash.slice(1) : hash;

  const patterns = [
    /^\/$/,
    /^\/clients$/,
    /^\/client\/[^\/]+\/edit$/,
    /^\/devices$/,
    /^\/device\/[^\/]+$/,
    /^\/transports$/,
    /^\/transport\/[^\/]+\/[^\/]+\/[^\/]+\/editVehicle$/,
    /^\/transport\/[^\/]+\/[^\/]+\/[^\/]+\/editGasStation$/,
    /^\/dialers$/,
    /^\/administrators$/,
    /^\/administrator\/[^\/]+\/edit$/,
    /^\/users$/,
    /^\/authInAppByLink$/,
    /^\/calibrations$/,
    /^\/billing$/,
    /^\/logspage$/,
    /^\/info\/[^\/]+\/[^\/]+\/logs$/,
    /^\/initSuperUser$/,
    /^\/WhatIsNewAfterUpdatePage$/,
    /^\/translation$/,
    /^\/ignoredDevices$/,
    /^\/ignoredUsers$/,
    /^\/tokensSession$/,
    /^\/retranslation_server\/[^\/]+$/,

  ];

  // Проверяем, соответствует ли путь хотя бы одному из шаблонов
  return patterns.some(pattern => pattern.test(path));
}


// Примеры использования
console.log(checkPathAgainstPatterns("#/client/userName123/edit")); // true
console.log(checkPathAgainstPatterns("#/device/device123")); // true
console.log(checkPathAgainstPatterns("#/nonexistent/path")); // false
console.log('checkPathAgainstPatterns', checkPathAgainstPatterns("#/transport/ownerName/transportName123/transport_id123/editVehicle/asdasdas")); // true


// Использование ProtectedRoute вместо Route для защиты маршрутов
{/*<ProtectedRoute path="/somepath" component={SomeComponent} allowedPaths={allowedPaths} />*/}


