import React, {Fragment} 					from "react"
import {ifNoKey, arrayIsEmpty} 				from "sl-library"
import { Link }                             from 'react-router-dom'
import {editImg, deleteImg, closeImg}       from '../img.jsx'
import SeparPermission 						from './SeparPermission.jsx'
import l_v                  				from "store/languages.jsx"

export default class TransportList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data:                this.props.data,
			column_height:       '',
			win_width:           '',
			table_content_width: '',
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			data: nextProps.data,
		})
	}
	scroolTop = () => {
		const { column_height } = this.state;
		let table = document.querySelector('.table_content');
		table.scrollTop -= height;
	}
	scroolBottom = () => {
		const { column_height } = this.state;
		let table = document.querySelector('.table_content');
		table.scrollTop += height;
	}
	setwidth = (data) => {
		let head_line = data.head_line / data.table * 100;
		let win_width = window.innerWidth;
		let table_content = 100 - head_line;
		if(win_width < 720) {
			this.setState({
				column_height:       data.column_height + 1,
				table_content_width: table_content,
			})
		}
		this.setState({
			win_width: win_width,
		})
	}
	render(){
		const {data} = this.state;
		return 	arrayIsEmpty(data) ?
				<div className = 'transportlist' >
					<TableNavigationOnMobile />
					<TableHeader 	scroolTop = {this.scroolTop}
									{...this.props}
									scroolBottom = {this.scroolBottom}/>
					<div className = 'table_content'
						 /*style ={{
						 			height: this.state.column_height+'px',
						 			width:  this.state.table_content_width + '%'
								}}*/ >
					{
						data.map( key=> <OneTransport 	{...key}
						         						key =          { key.vehicleId }
						         						belongsTo =    { key.groups }
						         						kindUser = 	   {this.props.kindUser}
						         						deviceSerial = { getDeviceSerial(key) }
						         						owner =        { this.props.owner }
						         						delete =       { this.props.delete }
						         						gasStation =   { this.props.gasStation }
						         						returnwidth =  { this.setwidth } />)
					}
					</div>
				</div> :
				<p className='nodata'>{l_v.no_data}</p>
	}
}

function TableHeader(props) {
	const activeI = (nameOfColumn) => nameOfColumn == props.selectedParam ? {color: 'red'} : {}

	return  <div className = "oneLine head_line">
				<div className = "cell_id cell">
					ID
				</div>
				<div className = "cell_name cell" onClick = {()=>props.setSortParam('vehicleName')}>
					{l_v.name}
					<i className = 'sort_arrow' style = {activeI('vehicleName')}>{props.vehicleName ? '↑' : '↓'}</i>
				</div>
				<div className = "cell_licensePlate cell" onClick = {()=>props.setSortParam('vehicleLicensePlate')}>
					{l_v.plate_number}
					<i className = 'sort_arrow' style = {activeI('vehicleLicensePlate')}>{props.vehicleLicensePlate ? '↑' : '↓'}</i>
				</div>
				<div className = "cell_deviceSerial cell" onClick = {()=>props.setSortParam('deviceId')}>
					{l_v.device_number}
					<i className = 'sort_arrow' style = {activeI('deviceId')}>{props.sortByDeviceId ? '↑' : '↓'}</i>
				</div>
				<div className = "cell_belongsTo cell" onClick = {()=>props.setSortParam('vehicleGroupName')}>
					{l_v.group}
					<i className = 'sort_arrow' style = {activeI('vehicleGroupName')}>{props.sortByVehicleGroup ? '↑' : '↓'}</i>
				</div>
				<div className = "cell_desc cell">
					{l_v.description}
				</div>
				<div className = "cell_periods cell">
					{l_v.transfer_periods} on / off
				</div>
				<div className = "cell_delete cell">
				</div>
			</div>
}

function TableNavigationOnMobile(props) {
	return  <Fragment>
				<div className='triggerUp visible_on_mobile'
					 onClick={props.scroolTop}>
					 ▲
				</div>
				<div className='triggerDown visible_on_mobile'
					onClick={props.scroolBottom}>
					▼
				</div>
			</Fragment>
}

function LinkToDetailInfo(props) {
	const {owner, name, vehicleId, gasStation, lastKnownTime} = props;
	const nameOfPrefix = gasStation?'editGasStation':'editVehicle';
	let nameOfVehicle = name?name:' ';
	let url = "/transport/" + owner + '/'+ nameOfVehicle + '/'+ vehicleId +"/" + nameOfPrefix;
	return 	<Link 	to={url}
					style = {getStyleDependOnTimeOff(lastKnownTime)}
					className="link_client">{name?name:'name is empty'}
			</Link>
}

function GroupsView(props) {
	let {belongsTo} = props;
	return 	<div className = 'visibleOnHover'>
				{arrayIsEmpty(belongsTo)?
				belongsTo.map(key=>key.name + ', '):
				null}
			</div>
}

class OneTransport extends React.Component {
  	constructor(props) {
		super(props);
		this.state = {
			visibilityform_desc: '',
			visibilityform: '',
		}
  	}
 	componentDidMount() {
		let table = document.querySelector('.transportlist').clientWidth;
		let head_line = document.querySelector('.head_line').clientWidth;
		let column_height =document .querySelector('.table_content .oneLine').clientHeight ;
		this.props.returnwidth({
					  table:table,
					  head_line:head_line,
					  column_height: column_height
					})
	}
	render(){
	  	const {lastKnownTime, currentDeviceMapping, kindUser, name, vehicleId, deviceSerial} = this.props;
	  	const vehicleName = name?name:'name is empty'
		return 	<div className = "oneLine" style = {stylesForDeviceOn(ifNoKey(currentDeviceMapping, 'deviceOn'))}>
				<div className = "cell_id cell">
					{vehicleId}
				</div>
				<div className = "cell_name cell" >
					{
						kindUser == 'CLIENT' ?
						vehicleName :
						<LinkToDetailInfo 	{...this.props}/>
					}
				</div>
				<div className = "licensePlate cell">
					{this.props.licensePlate}
				</div>
				<div className = "cell_deviceSerial cell" >
				   		<Link 	to={`/device/${deviceSerial}`}
				   				style = {styleForhideUnmappedDevices(currentDeviceMapping)}
								className="link_client">
								{deviceSerial}
						</Link>
				</div>
				<div className = "cell_belongsTo cell">
					<GroupsView belongsTo = {this.props.belongsTo} />
				</div>
				<div className = "cell_desc cell">
						{this.props.description}
				</div>
				<div className = 'cell_periods cell'>
						{this.props.period1} / {this.props.period2}
				</div>
				<div className = "cell_delete cell">
					<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER'
										type =           'component'>

						{
							deleteImg("delete", () => this.props.delete({
								 id:    this.props.vehicleId,
								 name:  this.props.name
							}))
						}
					</SeparPermission>
				</div>
			</div>
	}
}

function stylesForDeviceOn(deviceOn) {
	return 	!deviceOn?
			{backgroundColor: '#E0E5E9'}:
			null
}

function getStyleDependOnTimeOff(timeWhenWasOnConnection) {
	timeWhenWasOnConnection = new Date(timeWhenWasOnConnection);
	const currentDate = new Date();
	const threeDayAgo = currentDate - 1000 * 60 * 60 * 24 * 3;
	return threeDayAgo > timeWhenWasOnConnection ? {'color': '#be6464'} : null
}



function styleForhideUnmappedDevices(mappedDevice) {
	let endDateOfMapping, dateNow = new Date();
	if(mappedDevice){
		endDateOfMapping = mappedDevice.to;
	}else{
		return { display: 'none' }
	}
	if(endDateOfMapping !== null && dateNow > new Date(endDateOfMapping)){//if date equal null, then date is open and device will be mapped infinity
		return { display: 'none' }
	}
	return null
	//algorithm
}

export function getDeviceSerial(transportInfo){
 	let currentDeviceMapping = ifNoKey(transportInfo, 'currentDeviceMapping')
 	if(currentDeviceMapping){
 		return currentDeviceMapping.deviceSerial
 	}else{
 		return ''
 	}
}