import React, { Component } from 'react';
import EditorValue          from './EditorValue.jsx'
import OneCheckBox          from 'modules/component/OneCheckBox.jsx'
import l_v     from 'store/languages'
import SL                   from 'actions/ActionCreator.jsx'
import SwitchInput 			from "modules/component/SwitchInput.jsx"
import TimeInput 			from "dd-date-time-input"

export class GasStationSettings extends Component {
	constructor(props) {
		super(props);
		let {settings, periods, additionalSettings } = this.props; //, alwaysIgnitionOn, outValsDto
		this.state = createStateOfSettings(
											settings,
											periods,
											additionalSettings,
											// minimalAmountImpulse,
											// outValsDto
											)

	}
	UNSAFE_componentWillReceiveProps(nextProps) {
		let {settings, periods, alwaysIgnitionOn, outValsDto, additionalSettings} = nextProps;

		this.setState(createStateOfSettings(
											settings,
											periods,
											additionalSettings,
											// minimalAmountImpulse
											// alwaysIgnitionOn,
											// outValsDto
											));
	}
	enterToStateInput = (value, name)=>{
		this.setState({
			[name]: value
		})
		switch(name){
			case 'period1' :
			 	this.props.updatePeriods(name, value);
			break;
			case 'period2' :
			 	this.props.updatePeriods(name, value);
			break;
			case 'minimal_fuelling_volume' :
			 	this.props.updateSettings(name, value);
			break;
			case 'minimal_defuelling_volume' :
			 	this.props.updateSettings(name, value);
			break;
			case 'maximum_duration' :
			 	this.props.updateSettings(name, value);
			break;
			default :
				this.props.updateTankSize(name, value);
			break;
		}
	}
	enterToStateCheckBox = (e) =>{
		this.enterToStateInput(e.target.checked, e.target.name)
		// this.setState({
		// 	[e.target.name]: e.target.checked
		// });
		// this.props.updateFuelingWithoutConnection(e.target.name, e.target.checked);
	}
	callClearCacheForPeriod = () => {
		const {from, to} = this.state;
		this.props.clearCacheForPeriod({
			from: 	from.format(),
			to: 	to.format()
		})
	}
	render() {
		const {period1, period2, minimal_fuelling_volume, minimal_defuelling_volume, maximum_duration,
				isObtainFuelConsumptionByDut, enableLightInterface, from, to,
			// minimalAmountImpulse, fuelingWithoutConnection,
			tankVolume, tankVolumeStart, minimalPeriod1, syncRemainders,
		minimalPeriod2} = this.state;
		const {settings} = this.props;
		return 	<div className="profile_container tab_content">
					<EditorValue 	editable = 	{true}
									disabled = 	{false}
									text = 		{l_v.volume}//l_v.fuel_tank_volume
									units = 	{l_v.l}
									value =		{SL.isNumeral(tankVolume)?tankVolume:0}
									min = 		{0}
									name = 		{'tankVolume'}
									returned = 	{this.enterToStateInput}/>
					<EditorValue 	editable = 	{true}
									disabled = 	{false}
									text = 		{l_v.level_at_the_time}//l_v.fuel_level_on_start
									units = 	{l_v.l}
									value =		{SL.isNumeral(tankVolumeStart)?tankVolumeStart:0}
									min = 		{0}
									name = 		{'tankVolumeStart'}
									returned = 	{this.enterToStateInput}/>
					<OneCheckBox 	name =				'isObtainFuelConsumptionByDut'
									checkbox_value = 	{isObtainFuelConsumptionByDut}
									onChangeCheckbox = 	{this.enterToStateCheckBox}
									label_text = 		{l_v.take_into_account_fuel}//l_v.isObtainFuelConsumptionByDut
									keySettings = 		'isObtainFuelConsumptionByDut'
									input_container = 	'none'
					/>
					<div className = 'clear_cache_block'>
						<p className = 'clear_cache_block_name'>{l_v.clear_cache_month}</p>
						<div>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
										name = 			'from'
										value = 		{from}
										maxDate = 		{to}
										class = 		{'input '}
										onChange = 		{(e)=> this.setState({from: e.value})}
										textLabel = 	{l_v.clear_cache_date}/>

							<TimeInput 	placeholder = 	'25.08.1988 9:45'
										name = 			'to'
										value = 		{to}
										class = 		{'input '}
										onChange = 		{(e) => this.setState({to: e.value})}
										textLabel =  	{l_v.date_of_period_end_to}/>
						</div>
						<button className = 'primari_btn'//l_v.clear_cache_for_month
								disabled = {!from || !to}
								onClick = {this.callClearCacheForPeriod}>
								{l_v.clear_cache_period}
						</button>
						<button className = 'primari_btn'//l_v.clear_cache_for_month
								onClick = {this.props.clearCacheForMonth}>
								{l_v.clear_cache_for_month}
						</button>
					</div>
				</div>
	}
}

export default GasStationSettings



function createStateOfSettings(arr, periods, additionalSettings) {//, alwaysIgnitionOn, outValsDto
	let state = {
		from: 	null,
		to: 	null,
	}
	if(SL.arrayIsEmpty(arr)){
		arr.forEach(key=>{
			state[key.name] = key.value;
		})
	}
	return Object.assign(state, periods, additionalSettings);
}

