import React, { Component } from 'react';
import ReactDOM 			from "react-dom"
export default class CreatePortal extends Component {
	constructor(props){
		super(props);
        const {idOfMountPlace} = this.props;
		this.mountPlace = idOfMountPlace?document.getElementById(idOfMountPlace):document.getElementsByTagName('body')[0];
	}

	render() {
		const {children} = this.props;
		const {mountPlace} = this;
        !mountPlace ? console.error("SOME PROBLEM, mountPlace - ", mountPlace) : null;
		return mountPlace ? ReactDOM.createPortal(children, mountPlace) : null
	}
}
