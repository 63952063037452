import React                              from 'react'
import moment                             from 'moment'
import TooltipWrapper                     from '../component/TooltipWrapper.jsx'
import TimeInput                          from "dd-date-time-input"
import HintData                           from '../transportPage/HintData.jsx'
import { connect }                        from 'react-redux'
import { modifyCalibrationListForSelect } from 'modules/socketInfo/AddRealSensor.jsx'
import SimpleSelectList                   from 'modules/component/SimpleSelectList.jsx'
import l_v                                from "store/languages.jsx"
import SL                                 from "sl-library"

class InsertCalibrationToSocket extends React.Component{
    constructor(props){
        super(props);
        let {info, socketId} = this.props;
        this.state = {
            realSensorSocketId:  socketId,
            sensorCalibrationId: '',
            dateFrom:            '',
            dateTo:              '',
            selectedCalibration: null,
            message:             'API FOR PUT CALIBRATION NOT AVAILABLE'//now server return error if from is early then current calibration
        }
    }
    enterToState = (e) =>{
        let name = e.name||e.target.name;
        let value = e.value || e.target.value;
        this.setState({
            [name]: value
        });
    }
    validate = (e) =>{
        e.preventDefault();
        let {realSensorSocketId, dateFrom, dateTo, sensorCalibrationId, selectedCalibration} = this.state;
        if(!!dateFrom && SL.isValidDate(dateFrom)){
            dateFrom =  moment(dateFrom).toISOString();
        }else{
            dateFrom = null;
            this.setState({
                message: l_v.id_real_and_date_are_required
            });
        }
        if(dateTo && SL.isValidDate(new Date(dateTo))){
            dateTo =  moment(dateTo).toISOString();
        }else{
            if(dateTo){//date to can be open, then we didn't send this parametr
                dateTo = '';
                this.setState({
                    message: l_v.date_end_are_wrong
                });
            }

        }
        if(!realSensorSocketId){
            this.realSensorSocketId.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
            this.setState({
                message:  l_v.id_real_and_date_are_required
            });
        }
        if(!selectedCalibration || !selectedCalibration.id){
            this.setState({
                sensorCalibrationId_empty: true,
                message:  'Не выбрана калибровка'
            });
            this.selectCalibration.setWarning()
        }
        if(dateFrom && realSensorSocketId && selectedCalibration && selectedCalibration.id){
            this.props.insertCalibrationToSocket(realSensorSocketId, selectedCalibration.id, dateFrom, dateTo)
        }
    }
    render(){
        const {selectedCalibration, message} = this.state;
        return  <form className = 'addCalibrationFor change_period_form'>
                        <p className = 'detail_socket_info_tub_header'>{l_v.insert_calibnrations_period}</p>
                        <div className = 'message' style = {{opacity: message ? 1:0}}>{message}</div>
                        <TooltipWrapper name = {l_v.id_of_socket_change}
                                        position = 'right'
                                        className = 'wrap'>
                            <input  type =          'text'
                                    ref =           {el=> this.realSensorSocketId = el}
                                    className =     {'input ' + this.state.sensorNumber_empty}
                                    name =          'realSensorSocketId'
                                    value =         {this.state.realSensorSocketId}
                                    onChange =      {this.enterToState}
                                    placeholder =   {`${l_v.id_of_socket_adding} *`}/>
                        </TooltipWrapper>
                        <div className = 'clear'></div>
                        <TooltipWrapper name = {l_v.id_of_calibration}
                                        position = 'right'
                                        className = 'wrap'>
                            <SimpleSelectList   list = {modifyCalibrationListForSelect(this.props.calibrations)}
                                                listHeight = {200}
                                                ref = {el => this.selectCalibration = el}
                                                class_name  = 'select_list calibration_select_list'
                                                selected_item = {selectedCalibration}
                                                placeholder = {l_v.id_of_calibration}
                                                returnValue   = {e => this.setState({selectedCalibration: e}) } />
                            {/*<HintData    name =          'sensorCalibrationId'//Id типа датчика датчика
                                        data =          {this.props.calibrations}
                                        value =         {this.state.sensorCalibrationId}
                                        check =         {this.state.sensorCalibrationId_empty}
                                        returnData =    {this.enterToState}
                                        placeholder =   {l_v.id_of_calibration}/>*/}
                        </TooltipWrapper>
                        <div className = 'clear'></div>
                        <TooltipWrapper name = {l_v.date_from_which_to_replace}
                                        position = 'right'
                                        className = 'wrap'>
                            <TimeInput  placeholder =   '25.08.1988 9:45'
                                        withSeconds =   {true}
                                        name =          'date_request'
                                        maxValue =      {this.state.dateTo}
                                        value =         {this.state.dateFrom}
                                        class =         'input'
                                        onChange =      {(e)=>this.setState({dateFrom: e.value})}
                                        labelHide =     {true}/>
                        </TooltipWrapper>
                        <div className = 'clear'></div>
                        <TooltipWrapper name =      {l_v.date_of_period_end}
                                        position = 'right'
                                        className = 'wrap'>
                            <TimeInput  placeholder =   '25.08.1988 9:45'
                                        withSeconds =   {true}
                                        name =          'date_request'
                                        minDate =       {this.state.dateFrom}
                                        value =         {this.state.dateTo}
                                        class =         'input'
                                        onChange =      {(e)=>this.setState({dateTo: e.value})}
                                        maxDis =        {true}
                                        labelHide =     {true}/>
                        </TooltipWrapper>
                        <div className = 'clear'></div>
                        <button className  =    'primari_btn'
                                style =         {{display: 'block'}}
                                onClick =       {this.validate}>
                            {l_v.add_calibration}
                        </button>

                </form>
    }
}
export default connect(
        state => ({
            calibrations:  state.devicesR.thisCalibrations,
        }), null, null, { forwardRef: true })(InsertCalibrationToSocket)