import store from 'store/configureStore.jsx'
import infoStore from './infoStore.js'

function updateLocation (data) {
	store.dispatch({
		type: "LOCATION",
		up_location: data
	})
}

function clearHistory (data) {
	store.dispatch({
		type: 'COMMAND_HISTORY',
		setHistory: undefined
	})
	store.dispatch({
		type: 'LAST_COMMANDS',
		setLastComm: undefined
	})
	store.dispatch({
		type: "SETANSWER",
		setAnswer: undefined
	})
}

function saveSocket (socket) {
	window.socket = socket;
	store.dispatch({
		type: 'WEBSOCKET',
		saveSocket: socket
	})
}

function checkWebSocket () {
	return window.socket && window.socket.readyState === window.socket.OPEN
}

function clearWebsocket () {
	const socket = infoStore('state.authorizationR.socket');
	if (socket) {
		socket.close()
		console.log(`%c soket was closed`, "color: orange; font-weight: 400; text-transform:uppercase",)
	}
	saveSocket(null)
}

const WebsocketActions = {
	saveSocket: saveSocket,
	clearWebsocket: clearWebsocket,
	clearHistory: clearHistory,
	checkWebSocket: checkWebSocket
}
export default WebsocketActions;