import store 	from 'store/configureStore.jsx'
import axios 	from 'axios'
import l_v 		from "store/languages.jsx"
import bugReport from './bugReport.jsx'
import { setText } from './bugReport.jsx'
import infoStore from "./infoStore.js"
const sensorsApi = {
	addVirtualSensor:                 addVirtualSensor,
	updateVirtualSensor:              updateVirtualSensor,
	deleteVirtualSensor:              deleteVirtualSensor,
	createRealSensor:                 createRealSensor,
	deleteRealSensor:                 deleteRealSensor,
	setRealSensorCalibration:         setRealSensorCalibration,
	createMultiSensor:                createMultiSensor,
	removeMultiSensor:                removeMultiSensor,
	connectRealToMultisensor:         connectRealToMultisensor,
	deleteConnectRealToMultisensor:   deleteConnectRealToMultisensor,
	changeRealSensor:                 changeRealSensor,
	updateMappingRealToSocket:        updateMappingRealToSocket,
	updateMappingCalibrationToSocket: updateMappingCalibrationToSocket,
	addCalibrationToSocket:           addCalibrationToSocket,
	updateRealSensor: 				  updateRealSensor,
	insertCalibrationToSocket: 		  insertCalibrationToSocket,
	deleteCalibrationFromSocket: 	  deleteCalibrationFromSocket,
}
export default sensorsApi;


function updateRealSensor(realSensorId, requestDto, callback) {
	let url = infoStore().bonus_url_device + 'vehicle';
	axios({
			baseURL: infoStore().base_url,
			url: url + '/vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/' + realSensorId,
			dataType: "JSON",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
			data: requestDto
		})
		.then( answer => {
			setText(l_v.information_was_updated, answer.status)
			if(!!callback) callback(answer.data);
		})
		.catch( serveranswer => {
			bugReport(serveranswer, store)
			console.log("%c serveranswer.data", coCSS, serveranswer.response.status)
			let answerText = `${l_v.failed_to_update_real_socket} ${realSensorId}`;
			setText(answerText, serveranswer.response.status)
		});
}

function updateVirtualSensor(sensorInfo, callback) { //updateSensorSensor
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/virtual-sensor',
			dataType: "json",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
			data: sensorInfo,
		})
		.then( answer => {
			let newsensor = answer.data;
			if(!!callback) {
				callback(answer.data)
			}
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function deleteVirtualSensor(sensorId, callback) { //deleteSensorSensor
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/virtual-sensor/',
			dataType: "json",
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
			params: {
				id: sensorId
			}
		})
		.then( answer => {
			if(!!callback) callback(answer.data);
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			if(serveranswer.response.status == 403) {
				store.dispatch({
					type: 'STATUS_REQUEST',
					setAnswer: serveranswer.response.data,
					settextanswer: l_v.before_remove_sensor
				})
			} else {
				bugReport(serveranswer, store)
			}
		});
}

function addVirtualSensor(sensorInfo, callback, onError) { //addvirtualSensor
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/virtual-sensor',
			dataType: "json",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			data: sensorInfo,
			// params: 	{
			// 	vehicleId: 	data.vehicleId,
			// }
		})
		.then( answer => {
			if(callback) {
				callback(answer.data)
			}
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			console.error("SOME PROBLEM, ADDVIRTUALSENSOR serveranswer - ", serveranswer)
			if(onError)onError()
			if( serveranswer.response.data == 'Real sensor name is already present') {
				data.status = l_v.failed_add_virtual_senspr
			} else {
				if(serveranswer.response.status == 409 && serveranswer.response.data == 'Sensor with such type is already present on vehicle') {
					store.dispatch({
						type: 'STATUS_REQUEST',
						setAnswer: answer.status,
						settextanswer: l_v.information_was_updated
					})
				} else {
					bugReport(serveranswer, store)
				}
			}
		});
}
//newApiRequest instuction https://docs.elsyton.com/pages/viewpage.action?pageId=9830645
function createRealSensor(sensorData, callback, onError) {
	console.log("%c CREATEREALSENSOR", coCSS, sensorData)
	let url = infoStore().bonus_url_device + 'vehicle';
	axios({
			baseURL: infoStore().base_url,
			url: url + '/real-sensor',
			dataType: "JSON",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			data: sensorData,
		})
		.then( answer => {
			let newsensor = answer.data;
			console.log("%c answer.status", coCSS, answer.status)
			setText(l_v.information_was_updated, answer.status)
			if(!!callback) callback(newsensor);
		})
		.catch(function(serveranswer) {
			if(onError) onError();
			bugReport(serveranswer, store);
		});
}

function deleteRealSensor(sensorId, callback) {
	let url = infoStore().bonus_url_device + 'vehicle';
	axios({
			baseURL: infoStore().base_url,
			url: url + '/real-sensor/' + sensorId,
			dataType: "json",
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
			// params: 	{real_sensor_id: sensorId}
		})
		.then( answer => {
			let oldSensor = answer.data;
			if(!!callback) callback(sensorId);
			setText(l_v.information_was_updated, answer.status)
		})
		.catch(function(answer) {
			if(answer.response.status == 409) {
				setText(l_v.failed_delete_real_sensor, answer.response.data)
			}
			bugReport(answer, store)
		});
}

function deleteCalibrationFromSocket(realSensorSocketId, sensorCalibrationMappingId, callback) {
	let url = infoStore().bonus_url_device + 'vehicle/vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/';
		axios({
				baseURL: infoStore().base_url,
				url: url + realSensorSocketId + '/calibration-mappings/' + sensorCalibrationMappingId,
				dataType: "json",
				method: 'DELETE',
				headers: { 'authorization': infoStore().token },
			})
			.then( answer => {
				console.log("%c answer", coCSS, answer, callback)
				if(!!callback) callback(answer.data);

				setText(l_v.calibration_removed_from_mapping, sensorCalibrationMappingId);
			})
			.catch(function(serveranswer) {
				setText(l_v.failed_update_calibration, serveranswer.data.status);
				bugReport(serveranswer, store)
			});
}

function setRealSensorCalibration(data, calibrationId, callback) {
	let url = infoStore().bonus_url_device + 'vehicle/vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/';
	axios({
			baseURL: infoStore().base_url,
			url: url + data.realSensorSocketId + '/calibration-mappings/' + data.sensorCalibrationMappingId,
			dataType: "json",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			params: { 'from': data.dateFrom, to: data.dateTo }
		})
		.then( answer => {
			if(!!callback) callback(answer.data);
			setText(l_v.calibration_updated, data);
		})
		.catch(function(serveranswer) {
			setText(l_v.failed_update_calibration, serveranswer.data.status);
			bugReport(serveranswer, store)
		});
}

function changeRealSensor(requestData, callback) {
	let url = infoStore().bonus_url_device + 'vehicle';
	axios({
			baseURL: infoStore().base_url,
			url: url + '/vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets',
			dataType: "JSON",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			data: requestData
		})
		.then( answer => {
			setText(l_v.information_was_updated, answer.status)
			if(!!callback) callback(answer.data);
			setText(l_v.real_sensor_for_virtual_received, answer.data.status)
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
			const answerText = l_v.failed_update_real_sensor_for_period(requestData.sensorId, requestData.from);
			setText(answerText, serveranswer.response.status)
		});
}
// vehicle/vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/real-sensors/{realSensorId}
function updateMappingRealToSocket(data, callback) {
	let url = infoStore().bonus_url_device + 'vehicle/vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/real-sensors/';
	// '/real-sensor-socket/real-sensor/' + data.realSensorId + '/mapping',
	axios({
			baseURL: infoStore().base_url,
			url: url + data.realSensorId,
			dataType: "JSON",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
			params: { from: data.from, to: data.to }
		})
		.then( answer => {
			setText(l_v.information_was_updated, answer.status)
			if(callback) callback(answer.data);
			setText(l_v.real_sensor_for_virtual_received, data)
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function updateMappingCalibrationToSocket(data, callback) {
	let url = infoStore().bonus_url_device + 'vehicle';
	axios({
			baseURL: infoStore().base_url,
			url: url + '/vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/' + data.realSensorSocketId + '/calibration-mappings/' + data.sensorCalibrationMappingId,
			dataType: "JSON",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
			params: {
				from: data.from,
				to: data.to
			}
		})
		.then( answer => {
			if(callback) callback(answer.data)
			if(setText) setText(l_v.calibration_period_updated, answer.status)
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
			if(!!status) {
				data.status = l_v.failed_get_real_sensors_for_virtual(virtualSensorsId, periodId);
				status(data)
			}
		});
}

function addCalibrationToSocket(data, callback) {
	let url = infoStore().bonus_url_device + 'vehicle/';
	url = url + 'vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/' + data.realSensorSocketId + '/calibrations/' + data.sensorCalibrationId;
	axios({
			baseURL: infoStore().base_url,
			url: url,
			dataType: "JSON",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			params: {
				from: data.from,
				to: data.to
			}
		})
		.then( answer => {
			if(callback) callback(answer.data)
			if(setText) setText(l_v.calibration_added, answer)
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
			if(status) {
				data.status = l_v.failed_calibration_adding
				status(data)
			}
		});
}

function insertCalibrationToSocket(data, callback) {
	let url = infoStore().bonus_url_device + 'vehicle/';
		url = url + 'vehicle-to-device-mappings/virtual-sensors/real-sensor-sockets/' + data.realSensorSocketId + '/calibrations/' + data.sensorCalibrationId;
		axios({
				baseURL: infoStore().base_url,
				url: url + '?from=' + data.from + '&to=' + data.to,
				dataType: "JSON",
				method: 'PUT',
				headers: { 'authorization': infoStore().token },
			})
			.then( answer => {
				if(!!callback) {
					callback(answer.data)
				}
				if(!!setText) {
					setText(l_v.calibration_inserted, answer)
				}
			})
			.catch(function(serveranswer) {
				bugReport(serveranswer, store)
				if(!!status) {
					data.status = l_v.failed_to_add_calibration_to_socket
					status(data)
				}
			});
}

function createMultiSensor(vehicleId, data, callback, onError) {
	let url = infoStore().bonus_url_device + 'vehicle/multi_sensor';
	axios({
			baseURL: infoStore().base_url,
			url: url,
			dataType: "JSON",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			data: data
		})
		.then( answer => {
			if(!!callback) {
				callback(answer.data)
			}
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			if(onError)onError()
			bugReport(serveranswer, store)
			if(!!status) {
				data.status = l_v.failed_create_multisensor
				status(data)
			}
		});
}

function removeMultiSensor(multiSensorId, callback) {
	let url = infoStore().bonus_url_device;
	axios({
			baseURL: infoStore().base_url,
			url: url + 'multi_sensor/' + multiSensorId,
			dataType: "JSON",
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
		})
		.then( answer => {
			if(!!callback) callback(answer.data.sensorId)
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function connectRealToMultisensor(multiSensorId, listRealSensorIds) {
	let url = infoStore().bonus_url_device + 'multi_sensor/';
	axios({
			baseURL: infoStore().base_url,
			url: url + multiSensorId + '/mapping',
			dataType: "JSON",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
			data: listRealSensorIds //тут нужно уточнить формат
		})
		.then( answer => {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
			if(!!status) {
				data.status = l_v.failed_to_connect
				status(data)
			}
		});
}

function deleteConnectRealToMultisensor(multiSensorId, listRealSensorIds) {
	let url = infoStore().bonus_url_device + 'multi_sensor/';
	axios({
			baseURL: infoStore().base_url,
			url: url + multiSensorId + '/mapping',
			dataType: "JSON",
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
			data: listRealSensorIds //тут нужно уточнить формат
		})
		.then( answer => {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
			if(!!status) {
				data.status = l_v.multisensor_connection_removed
				status(data)
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
			if(!!status) {
				data.status = l_v.failed_failed_to_remove_link
				status(data)
			}
		});
}

