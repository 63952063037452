import React                                 from 'react'
import { arrayIsEmpty }                      from 'sl-library'
import l_v                  				 		from "store/languages.jsx"
import sensorsApi                            from '../../actions/updateSensor.jsx'
import AddRealSensor, { EditRealSensorForm } from './AddRealSensor.jsx'
import { editImg, deleteImg }                from '../img.jsx'
import EditCalibrairionOfRealSensor          from './EditCalibrairionOfRealSensor.jsx'
import ConfirmRemove                         from '../ConfirmRemove.jsx'
import HintCalibrationInfo                   from '../transportPage/HintCalibrationInfo.jsx'
import PopUp                                 from '../component/Pop_up.jsx'
import DetailSocketInfo                      from './DetailSocketInfo.jsx'
import SeparPermission                		 	from 'modules/component/SeparPermission.jsx'
import {HigLightContext, FilterButtonInRow} 	from "modules/transportPage/HighLighterOfRows.js"

export default class RealSensors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			preloader: 		false,
			sensorList: 	this.props.sensorList,//real sensor sockets
			listForView: 	createListForView(this.props.sensorList),
			forDelete: 		undefined,
			detailInfo:  	undefined,
		}
	}
	componentWillReceiveProps(nextProps) {
		window.myHotCall({removeAllRealSensors: removeAllRealSensors(nextProps.sensorList)});
		this.setState({
			sensorList: 	nextProps.sensorList,
			listForView: 	createListForView(nextProps.sensorList),
		});
	}
	addNewSensor = (forSend) => {
		let callBack = (newSensor) =>{
			this.addForm.clearState();//clear form in addRealSensor
			this.popUp.close()
			this.props.addRealSensorsToList(newSensor)
		}
		sensorsApi.createRealSensor(forSend, callBack)
	}
	// componentDidMount() {
	// 	this.editSensor.open()
	// }
	callDelete = (id) =>{
		this.setState({
			forDelete: id,
			deleteType: 'realSensor'
		}, this.confirmForRemove.popUp_activation);
	}
	remove = () =>{
		let {sensorList, forDelete} = this.state;
		let callBack = (realSensorId) => {
			this.props.removeRealSensorFromList(realSensorId)
			this.setState({forDelete: null});
		}
		const sensorForEdit = sensorList.find(el => el.id == forDelete);
		const realSensorId = sensorForEdit.realSensors[0].realSensorId;//crutch for remove

		sensorsApi.deleteRealSensor(realSensorId, callBack)
	}
	saveNewCalibration = (idNewCalibration) =>{
		const onSaveCalibration = (newCalibration) =>{
			const {sensorList} = this.state;
			console.log(`%c RealSensors onSaveCalibration newCalibration`, "color: blue; font-weight: 400; text-transform:uppercase", newCalibration)
		}
		sensorsApi.setRealSensorCalibration(this.state.forEdit, idNewCalibration, onSaveCalibration)
	}
	showCalibrationInfo = (e) =>{//used in prev variant
		this.hintInfo.showCalibrationInfo(e)
	}
	hideCalibrationInfo = (e) =>{
		this.hintInfo.hideCalibrationInfo()
	}
	callDTO = (socketid) =>{
		let detailInfo;
		let sensorList = this.state.sensorList;
		sensorList.find(key=>{
			if(key.id == socketid){
				detailInfo = key
			}
		})
		this.setState({detailInfo: detailInfo});
		this.dto.open();
	}
	upMappingCalibratRequest = (socketId, sensorCalibrationId, dateFrom, to) => {
		const onSaveNewMapping = newSocketInfo => {
			const {sensorList} = this.state;
			const newRealSensorSockets = updateSocketsInfo({ currentSockets: sensorList, newSocketInfo})
			this.props.onUpdateRealSensorSocket(newRealSensorSockets);
			this.setState({
				detailInfo: newSocketInfo
			});
		}
		sensorsApi.updateMappingCalibrationToSocket({
			realSensorSocketId: 		socketId,
			sensorCalibrationMappingId: sensorCalibrationId,
			from: 						dateFrom,
			to: 						to
		}, onSaveNewMapping)
	}
	addCalibrationToSocket = (socketId, sensorCalibrationId, dateFrom, to) =>{
		const addNewCalibration = newSocketInfo => {
			const {sensorList} = this.state;
			const newRealSensorSockets = updateSocketsInfo({ currentSockets: sensorList, newSocketInfo})
			this.props.onUpdateRealSensorSocket(newRealSensorSockets);
			this.setState({
				detailInfo: newSocketInfo
			});
		}
		sensorsApi.addCalibrationToSocket({
			realSensorSocketId: 	socketId,
			sensorCalibrationId: sensorCalibrationId,
			from: 					dateFrom,
			to: 						to,

		}, addNewCalibration)
	}
	removeCalibrationFromSocket = () =>{
		const onComplete = newSocketInfo => {
			const {sensorList} = this.state;
			const newRealSensorSockets = updateSocketsInfo({ currentSockets: sensorList, newSocketInfo})
			this.props.onUpdateRealSensorSocket(newRealSensorSockets);
			this.setState({
				detailInfo: newSocketInfo,
				deleteType: null,
				forDelete: null,
			});
			this.realSensorSocketId = null;
			this.sensorCalibrationMappingId = null;
		}

		sensorsApi.deleteCalibrationFromSocket(this.realSensorSocketId, this.sensorCalibrationMappingId, onComplete)
	}
	callRemoveCalibrationFromSocket = (realSensorSocketId, sensorCalibrationMappingId) => {
		this.realSensorSocketId = realSensorSocketId;
		this.sensorCalibrationMappingId = sensorCalibrationMappingId;
		this.setState({
			deleteType: 'calibrationFromSocket',
			forDelete: sensorCalibrationMappingId,
		}, this.confirmForRemove.popUp_activation);
	}
	callEdit = (realSensorId) => {
		this.editSensor.open();
		const {sensorList, listForView} = this.state;
		const sensorForEdit = sensorList.find(el => el.id == realSensorId);
		const currentSensor = sensorForEdit.realSensors.find(el => !!el.current);
		this.realSensorSockeIdForRename = realSensorId;

		setTimeout(()=>this.editFormContent.setInfoForEdit({
			realSensorSocketName: 	sensorForEdit.name,
			realSensorNumber: 		currentSensor.sensorNumber,
			realSensorReverse: 		currentSensor.reverse,
			realSensorFromZero: 		currentSensor.fromZero,
		}), 500)
	}
	insertCalibrationToSocket = (socketId, sensorCalibrationId, dateFrom, to) =>{
		let addNewCalibration = (newSocketInfo) => {
			const {sensorList} = this.state;
			const newRealSensorSockets = updateSocketsInfo({ currentSockets: sensorList, newSocketInfo})
			this.props.onUpdateRealSensorSocket(newRealSensorSockets);
			this.setState({
				detailInfo: newInfo
			});
		}
		sensorsApi.insertCalibrationToSocket({
			realSensorSocketId: 	socketId,
			sensorCalibrationId: sensorCalibrationId,
			from: 					dateFrom,
			to: 						to,

		}, addNewCalibration)
	}
	changeRealSensorRequest = (realSensorInfo) =>{
		const {previousRealSensorId, sensorNumber, virtualSensorId, dateFrom, dateTo, reverse, fromZero} = realSensorInfo;
		const addNewRealSensor = newSocketInfo =>{
			try{
				const {sensorList} = this.state;
				const newRealSensorSockets = updateSocketsInfo({ currentSockets: sensorList, newSocketInfo})
				this.props.onUpdateRealSensorSocket(newRealSensorSockets);
			}catch(e){
				console.error("SOME PROBLEM, changeRealSensorRequest wrong value - ", newSocketInfo, sensorList,  e)
			}
			this.setState({
				detailInfo: newSocketInfo
			});
		}
		sensorsApi.changeRealSensor({
			sensorId:             virtualSensorId,
			sensorNumber:         sensorNumber,
			previousRealSensorId: previousRealSensorId,
			from:                 dateFrom,
			dateTo:               dateTo,
			reverse:              reverse,
			fromZero:             fromZero
		}, addNewRealSensor)
	}
	changeSensorPeriod = (realSensorId, dateFrom, to) =>{
		const setNewPeriod = newRealSensorInfo => {
			try{
				const {sensorList} = this.state;
				const newRealSensorSockets = updateRealSensorInSocketInfo({ currentSockets: sensorList, newRealSensorInfo})
				this.props.onUpdateRealSensorSocket(newRealSensorSockets);
			}catch(e){
				console.error("SOME PROBLEM, CHANGESENSORPERIOD wrong value - ", newSocketInfo, sensorList,  e)
			}
			this.setState({
				detailInfo: 	newRealSensorSockets,
				listForView: 	createListForView(newRealSensorSockets)
			});
		}
		sensorsApi.updateMappingRealToSocket({
			realSensorId: 	realSensorId,
			from: 			dateFrom,
			to: 			to
		}, setNewPeriod)
	}
	
	updateRealSensor = (newDto) => {
		const onUpdate = newSocketInfo => {
			try{
				const {sensorList} = this.state;
				const newRealSensorSockets = updateSocketsInfo({ currentSockets: sensorList, newSocketInfo})
				this.props.onUpdateRealSensorSocket(newRealSensorSockets);
				this.setState({
					sensorList: 	newRealSensorSockets,
					listForView: 	createListForView(newRealSensorSockets),
				});
			}catch(e){
				console.error("SOME PROBLEM, UPDATE REAL SENSOR wrong value - ", newSocketInfo, sensorList,  e)
			}
			this.editFormContent.setInitState();
			this.editSensor.close();
			this.realSensorSockeIdForRename = null;
		}
		sensorsApi.updateRealSensor(this.realSensorSockeIdForRename, newDto, onUpdate);
	}
	render() {
		const {deleteType} = this.state;
		const confirmText = deleteType == 'calibrationFromSocket' ?
							l_v.are_you_sure_remove_mapping(this.state.forDelete) :
							l_v.are_you_sure_remove_sensor(this.state.forDelete);
		const removeFunc = 	deleteType == 'calibrationFromSocket' ?
							this.removeCalibrationFromSocket :
							this.remove;
		return 	<div className = 'real_sensors' style = {{position: 'relative'}}>
						<p className = 'nameForm'>
							{l_v.real_sensor_period}
							<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
	 						      				kindCapability = 'editSensors'>
								<button 	name = 		'showForm'
											className = 'btn_plus'
											onClick = 	{()=>this.popUp.open()}>
										+
								</button>
							</SeparPermission>
						</p>
						{this.existingRealSensors(this.state.listForView)}
						<PopUp 	ref = 			{(popUp)=>this.popUp = popUp}
									head_text= 		{l_v.add_real_sensor}
									head_back = 	{{backgroundColor:"#708090", color: 'white', fill: 'white'}}
									popUp_size = 	{{maxWidth: '99%', width: '500px', height: '450px'}}
									popUp = 		{this.popUp_activation}>
								<AddRealSensor 	vehicleId = 		{this.props.vehicleId}
												ref = 				{(addForm)=>this.addForm = addForm}
												newSensor =     	{this.addNewSensor}
												deniedId = 			{createDeniedIdList(this.state.sensorList)}
												cancel = 			{()=>this.popUp.close()}
												calibrations =  	{this.props.calibrations}
												virtualSensors = 	{this.props.virtualSensors} />
						</PopUp>
						<PopUp 	ref = 			{(popUp)=>this.dto = popUp}
									head_text= 		{l_v.detailed_information_socket}
									head_back = 	{{backgroundColor:"#708090", color: 'white', fill: 'white'}}
									popUp_size = 	{{maxWidth: '100%', width: '100%', height: '100vh'}}
									popUp = 			{this.popUp_activation}>
				               <DetailSocketInfo 	info =                        { this.state.detailInfo }
				               							changeRealSensor =            { this.changeRealSensorRequest }
				               							changeSensorPeriod =          { this.changeSensorPeriod }
				               							virtualSensors =              { this.props.virtualSensors }
				               							calibrations =                { this.props.calibrations }
				               							addCalibrationToSocket =      { this.addCalibrationToSocket }
				               							insertCalibrationToSocket =   { this.insertCalibrationToSocket }
				               							removeCalibrationFromSocket = { this.callRemoveCalibrationFromSocket }
				               							upMappingCalibrat =           { this.upMappingCalibratRequest } />						
						</PopUp>
						<PopUp 	ref = 			{(popUp)=>this.editSensor = popUp}
									head_text= 		{l_v.update_real_sensor}
									head_back = 	{{backgroundColor:"#708090", color: 'white', fill: 'white'}}
									popUp_size = 	{{maxWidth: '99%', width: '500px', height: '330px'}}
									popUp = 			{this.popUp_activation}>
								<EditRealSensorForm 	ref = {editFormContent => this.editFormContent = editFormContent}
															cancel = {()=>this.editSensor.close()}
															updateRealSensor = {this.updateRealSensor} />
						</PopUp>
						<ConfirmRemove  confirmText = {confirmText}
										ref = {(confirmRemove)=>this.confirmForRemove = confirmRemove}
										remove =  {removeFunc}/>
						<HintCalibrationInfo 	ref = {hintInfo => this.hintInfo = hintInfo}/>


				</div>
	}
	existingRealSensors = (realSensorsList) =>{
		if(arrayIsEmpty(realSensorsList)){
			return 	<div className = "devicelist">
						<Header />
						<div className = 'table_content' ref = {(table_content)=>this.table_content = table_content}
							 style ={{
							 			height: this.state.column_height+'px',
							 			width: this.state.table_content_width + '%'
									}} >
						 	<TableContent 	realSensorsList =     {realSensorsList}
						 					callEdit =  		  {this.callEdit}
						 					callDelete =          {this.callDelete}
						 					callDTO =             {this.callDTO}
						 					showCalibrationInfo = {this.showCalibrationInfo}
						 					hideCalibrationInfo = {this.hideCalibrationInfo} />
						</div>
						<img 	src = 	'images/ring-alt.gif' className = 'preloader'
								style = {{visibility: !!this.state.preloader?'visible':'hidden'}}/>
					</div>
		}else{
			if(!!this.state.preloader){
				return <img 	src = 'images/ring-alt.gif' className = 'preloader' />
			}else{
				return <div className = 'no_data'>{l_v.no_sensors_yet}</div>
			}
		}
	}
	setwidth(data){
		let head_line = data.head_line/data.table * 100;
		let win_width = window.innerWidth;
		let table_content = 100 - head_line;
		if (win_width < 720){
			this.setState({
				column_height: data.column_height+1,
				table_content_width: table_content,
			})
		}
		this.setState({
			win_width: win_width,
		})
	}
	scroolTop(){
		let height = this.state.column_height;
		let name = this.table_content;
		name.scrollTop -= height;
	}
	scroolBottom(){
		let height = this.state.column_height;
		let name = this.table_content;
	}
}
function createDeniedIdList(list) {
	if(Array.isArray(list)){
		let arr = [];
		list.forEach(key =>{
			arr.push(key.virtualSensorId)
		})
		return arr
	}

}
function createListForView (list) {
	let newList;
	try {
		if (Array.isArray(list)) {
			newList = [];
			list.forEach((key, index) => {
				let sensorCalibrationId, realSensorId, virtualSensorId, sensorNumber, reverse, fromZero;
				let realSensors = key.realSensors;
				let calibrationMapping = key.sensorCalibrationMappings;
				if (arrayIsEmpty(calibrationMapping)) {
					calibrationMapping.forEach(key => {
						if (!!key.current) {
							sensorCalibrationId = key.sensorCalibrationId
						}
					})
				}
				if (arrayIsEmpty(realSensors)) {
					realSensors.forEach(key => {
						if (!!key.current) {
							realSensorId = key.realSensorId;
							virtualSensorId = key.virtualSensorId;
							sensorNumber = key.sensorNumber;
							reverse = key.reverse;
							fromZero = key.fromZero;
						}
					})
					realSensorId
				}
				newList.push({
					id: key.id,
					name: key.name,
					sensorCalibrationId: sensorCalibrationId,
					sensorNumber: sensorNumber,
					realSensorId: realSensorId,
					virtualSensorId: virtualSensorId,
					fromZero: fromZero,
					reverse: reverse
				})
			})
		}
	} catch (err) {
		console.error("SOME PROBLEM, wrong value - ", list, err)
	}
	return newList
}

function removeAllRealSensors(sensorsList) {
	const action = () => sensorsList.forEach(key => sensorsApi.deleteRealSensor(key.realSensors[0].realSensorId) )
	return action
}

function Header(props) {
	return 	<div className = "head_line oneLine">
					<div className = 'cell'> {l_v.socket_id}</div>
					<div className = 'cell'> {l_v.title}</div>
					<div className = 'cell'> {l_v.sensor_number}</div>
					<div className = 'cell'> {l_v.virtual_sensor_id}</div>
					<div className = 'cell'> {l_v.start_from_zero}</div>
					<div className = 'cell'> {l_v.calibration} </div>
					<div className = 'cell cell_info'> </div>
					<div className = 'cell cell_edit'></div>
					<div className = 'cell cell_delete'></div>
			</div>
}

function TableLine(props) {
	return  <HigLightContext.Consumer>
		{
			context => 	<div 	className = { props.reverse ? 'oneLine reversed_realSensor' : 'oneLine' }
									style = {context.RealSensorRow.getStyle(props.id)}
									onMouseEnter = {e => context.RealSensorRow.onMouseEnter(props.id)}
									onMouseLeave = {e => context.RealSensorRow.onMouseLeave(props.id)}
									data-virtual_sensor_id = {props.virtualSensorId}
									data-calibration_id =  {props.sensorCalibrationId}>
								<div className = 'cell'> 
									{props.id}
									<FilterButtonInRow 	toogleFilteringConnectedItems = {context.RealSensorRow.toogleFilteringConnectedItems}
																row_filter_enabled = {context.row_filter_enabled}/>
								</div>
								<div className = 'cell'> {props.name}</div>
								<div className = 'cell'> {props.sensorNumber}</div>
								<div className = 'cell'> {props.virtualSensorId}</div>
								<div className = 'cell'> {props.fromZero ? l_v.yes : l_v.no}</div>
								<div 	className = 'cell cell_title'
										onMouseEnter = {
											e => {
												props.showCalibrationInfo(e);
												context.RealSensorRow.onMouseEnter(props.id);
											}
										}
										onMouseLeave = {
											e => {
												props.hideCalibrationInfo(e);
												context.RealSensorRow.onMouseLeave(props.id);
											}
										}
										data-value =     {props.sensorCalibrationId}>
										{props.sensorCalibrationId}
								</div>
								<div className = 'cell cell_info'>
									<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
										      			kindCapability = 'editSensors'>
										<button className = 'showSocketInfo'
												onClick = {() => props.callDTO(props.id)}>
											{l_v.show_info}
										</button>
									</SeparPermission>
								</div>
								<div className = 'cell cell_edit'>
									<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
										      			kindCapability = 'editSensors'>
										{editImg('edit', () => props.callEdit(props.id))}
									</SeparPermission>
								</div>
								<div className = 'cell cell_delete'>
									<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
										      			kindCapability = 'editSensors'>
										{deleteImg('delete', () =>props.callDelete(props.id))}
									</SeparPermission>
								</div>

							</div>
		}
	</HigLightContext.Consumer>
}

function TableContent(props) {
	const { realSensorsList, callDelete, callDTO, showCalibrationInfo, hideCalibrationInfo } = props;
	return realSensorsList.map((key, index)=> <TableLine 	{...key}
															key =                 {index}
															{...props} />)
}

function updateSocketsInfo({currentSockets, newSocketInfo}) {
	const socketId = newSocketInfo.id;
	const socketIndex = currentSockets.findIndex(key => socketId == key.id);
	currentSockets[socketIndex] = newSocketInfo;
	return currentSockets;
}

function updateRealSensorInSocketInfo ({ currentSockets, newRealSensorInfo }) {
	const index_realSensor = sensorList.realSensors.findIndex(el => el.realSensorId == newRealSensorInfo.realSensorId);
	currentSockets.realSensors[index_realSensor] = newRealSensorInfo;
	return currentSockets
}



//on update calibration period answer
// {
//     "id": 1661,
//     "name": "del3",
//     "virtualSensorId": 1105,
//     "vehicleId": 2,
//     "realSensors": [
//         {
//             "realSensorId": 7334,
//             "sensorNumber": 5,
//             "position": 2,
//             "vehicleToDeviceMappingId": null,
//             "vehicleId": 2,
//             "reverse": false,
//             "realSensorSocketId": 1661,
//             "from": "2019-04-09T17:54:48Z",
//             "to": "2019-05-04T18:00:12.711Z",
//             "virtualSensorId": 1105,
//             "fromZero": false,
//             "current": false
//         }
//     ],
//     "sensorCalibrationMappings": [
//         {
//             "id": 8189,
//             "sensorCalibrationId": 6201,
//             "sensorCalibrationName": "Test",
//             "from": "2022-02-23T16:00:00Z",
//             "to": "2023-05-31T23:01:59Z",
//             "current": false
//         },
//         {
//             "id": 14574,
//             "sensorCalibrationId": 8,
//             "sensorCalibrationName": "температура",
//             "from": "2023-05-31T23:02:00Z",
//             "to": "2023-07-03T22:00:00.155Z",
//             "current": false
//         }
//     ]
// }

//answer on update period real sensor

// {
//     "realSensorId": 20856,
//     "sensorNumber": 665,
//     "position": 0,
//     "vehicleToDeviceMappingId": 109567,
//     "vehicleId": 6001,
//     "reverse": false,
//     "realSensorSocketId": 15195,
//     "from": "2018-03-01T19:30:00Z",
//     "to": "2018-03-01T19:55:18.906Z",
//     "virtualSensorId": 26243,
//     "fromZero": true,
//     "current": false
// }