import React from 'react'
import Pop_up from './component/Pop_up.jsx'
import language_variant from '../store/languages.jsx'

export default class ConfirmRemove extends React.Component{
	constructor(props) {
		super(props);
		this.popUp_activation = this.popUp_activation.bind(this)
	}
	componentWillReceiveProps(nextProps) {
		if (!!nextProps.data_about){
			this.popUp_activation({active:true})
		}
	}
	popUp_activation(e){
		let value = true;
		(e !== undefined)?(value = e.active):(value)
		this.confirmRemove.setState({
	      active: value
	    })
	    if(!value && !!this.props.clear){
	    	this.props.clear()//clear parrents data about candidate to deleting
	    }
	}
	open = () =>{
		this.confirmRemove.open()
	}
	remove=()=>{
		//here will call script for remove
		//now I have one type on delete it's employer, props.data_about={id: '..', first_name: '..', last_name: '..'}
		this.props.remove()
		this.popUp_activation({active:false})

	}
	render(ReactElement, DOMElement, callback){
		const {confirmText, confirm_header_text, confirmTextYes, height} = this.props;
		return (<Pop_up ref = 			{ confirmRemove => this.confirmRemove = confirmRemove }
		                head_text = 	{ confirm_header_text ? confirm_header_text : language_variant.deleting }
		                head_back = 	{ {backgroundColor:"rgb(255, 77, 77)", color: 'white', fill: 'white'} }
		                popUp_size = 	{ {maxWidth: '99%', width: '500px', height: height ? height : '192px'} }
		                popUp = 		{ this.popUp_activation }>
		                <div className="remove_container">
		                	<p className='confirm_text' dangerouslySetInnerHTML={{__html: confirmText }}></p>
		                	<button className='primari_btn' onClick={this.remove}>
		                	{ confirmTextYes ? confirmTextYes : language_variant.remove }</button>
	                		<button className='secondary_btn' onClick={()=>this.popUp_activation({active:false})}>
	                			{language_variant.cancel}
	                		</button>
		                </div>
		         </Pop_up>
		        )
	}
}