import Users from '../../actions/users.js'
import { getDomain, getLocalDomain, getProdTestDomain } from "reducers/authorization_reducer.jsx"
let ctrlPressed = false;
let altPressed = false;
let shiftPressed = false;
document.body.onclick = function(e) {
	var KeyID = (window.event) ? event.keyCode : e.keyCode;
	if(e.ctrlKey || e.metaKey) {
		ctrlPressed = true;
	} else {
		ctrlPressed = false;
	}
	if(e.altKey || e.shiftKey) {
		altPressed = true
		shiftPressed = true;
	} else {
		altPressed = false;
		shiftPressed = false;
	}
}

function saveAccountType(accountType, vehicleInfo) {
	function generateLinkForAuthInClient(sid, login) {
		const local_URL = 'http://192.168.1.161:8000/';
		let urlOnClientApp;
		if(!ctrlPressed) {
			if(altPressed || shiftPressed) {
				urlOnClientApp = getProdTestDomain(accountType)
			} else {
				urlOnClientApp = getDomain(accountType)
			}
		} else {
			urlOnClientApp = getLocalDomain(accountType)
		}
		if(typeof vehicleInfo == 'string'){ // typeof vehicleInfo !== 'string' - CRUTCH for test/develop on mobile when double tap opening local_URL (look in ClintPage.jsx)
			urlOnClientApp = local_URL;
		}
		urlOnClientApp += `/#/commingFromAdmin/${sid}/${login}`;
		if(vehicleInfo && typeof vehicleInfo !== 'string') { // typeof vehicleInfo !== 'string' - CRUTCH for test/develop on mobile when double tap opening local_URL (look in ClintPage.jsx)
			urlOnClientApp += `/${vehicleInfo.vehicleId}`;
			urlOnClientApp += `/${vehicleInfo.vehicleName}`;
			urlOnClientApp += `/${vehicleInfo.licensePlate}`;
		}
		const win = window.open(urlOnClientApp, '_blank');
		if(!win)alert('увы. открыть новое окно не удалось ');//хз как это у юзеров получается
		win.focus();
	}
	return generateLinkForAuthInClient
}

function authInClient(login, accountType, vehicleInfo) {
	const generateLinkForAuthInClient = saveAccountType(accountType, vehicleInfo)
	Users.getSidForAuthInClient(login, generateLinkForAuthInClient)
}
export default authInClient;
