import React                                      from "react"
import {deleteImg, closeImg, editImg, restoreImg, arrows} from '../img.jsx'
import { Link }                                   from 'react-router-dom'
import moment                                     from 'moment';
import {textOfDeviceStatus}                       from '../DevicePage.jsx'
import {ifNoKey}                                  from "actions/ActionCreator.jsx"
import l_v from "store/languages.jsx"
import { goToClientAppImg, qrIcon }     from 'modules/img.jsx'
import authInClient                     from 'modules/component/authInClient.js'
export default class DeviceList extends React.Component {
	constructor(props) {
		super(props);
		this.state ={
						// data: [],
						column_height: '',
						win_width: '',
						table_content_width: '',
					}

	}
	componentDidMount() {
		    // let table = document.querySelector('.device_list').clientWidth;
            // let head_line = document.querySelector('.head_line').clientWidth;
            // let column_height = document.querySelector('.table_content .oneLine').clientHeight ;
            // this.setwidth({
            //               table:table,
            //               head_line:head_line,
            //               column_height: column_height
            //             })
	}
	scroolTop(){
		let height = this.state.column_height;
		let name = document.querySelector('.table_content');
		name.scrollTop -= height;
	}
	scroolBottom(){
		let height = this.state.column_height;
		let name = document.querySelector('.table_content');
		name.scrollTop += height;
	}
	setwidth(data){
		let head_line = data.head_line/data.table * 100;
		let win_width = window.innerWidth;
		let table_content = 100 - head_line;
		if (win_width < 720){
			this.setState({
				column_height: data.column_height+1,
				table_content_width: table_content,
			})
		}
		this.setState({
			win_width: win_width,
		})
	}
	render(){
		let setwidth = this.setwidth.bind(this);
       	if (Array.isArray(this.props.data) && !!this.props.data.length){
      		return 	<div className = 'device_list'>
						{/*<div className='triggerUp visible_on_mobile'
							 onClick={this.scroolTop.bind(this)}
						>▲</div>
						<div className='triggerDown visible_on_mobile'
							onClick={this.scroolBottom.bind(this)}
						>▼</div>*/}
						<HeadLine 	setSortParam = {this.props.setSortParam}
									sortByOwner = {this.props.sortByOwner}
									selectedParam = {this.props.selectedParam}
									sortByName = {this.props.sortByName}
									deviceId  = {this.props.deviceId}/>
						<div className = 'table_content'
							 style = 	{{
								 			height: this.state.column_height+'px',
								 			width: this.state.table_content_width + '%'
										}} >
							{
								this.props.data.map( (key, index) => {
									if(index < 1000){//lag on render, All design is bad and user not need long lenth
										return <OneDevice 	key = 			{index}
															index = 		{index}
															deleteDevice = 	{this.props.delete}
															restoreDevice = {this.props.restoreDevice}
															{...key}
															returnwidth = 	{setwidth} />
									}
								})
							}
						</div>
					</div>
		}
		else {
			return  <p className='nodata'>{l_v.no_data}</p>
		}
	}
}

function HeadLine(props) {
	const activeI = (nameOfColumn) => nameOfColumn == props.selectedParam ? {color: 'red'} : {}

	return <div className = "oneLine head_line">
			<div className = "cell_name cell" onClick = {()=>props.setSortParam('deviceId')}>
				{l_v.serial_number}
				<i className = 'sort_arrow' style = {activeI('deviceId')}>{props.deviceId ? '↑' : '↓'}</i>
			</div>
			<div className = "cell_desc cell" onClick = {()=>props.setSortParam('controlEntityName')}>
				{l_v.machine}
				<i className = 'sort_arrow' style = {activeI('controlEntityName')}>{props.sortByName ? '↑' : '↓'}</i>
			</div>
			<div className = "cell_desc cell" onClick = {()=>props.setSortParam('owner')}>
				{l_v.account}
				<i className = 'sort_arrow' style = {activeI('owner')}>{props.sortByOwner ? '↑' : '↓'}</i>
			</div>
			<div className = "cell_comment cell">
				{l_v.comment}
			</div>
			<div className = "cell_date cell">
				{l_v.last_data}
			</div>
			<div className = "cell_status cell">
				{l_v.status}
			</div>
			<div className = "cell_edit cell">

			</div>
			<div className = "cell_delete cell">

			</div>
		</div>
}

function OneDevice(props) {
	const {serialNumber, owner, comment, deviceStatus, lastKnownTime, deleteDevice, vehicle, trash, restoreDevice} = props;
	const currentDeviceMapping = vehicle?vehicle.currentDeviceMapping:null;
	const deviceOn = ifNoKey(currentDeviceMapping, 'deviceOn');
	const styles = !deviceOn ? { backgroundColor: '#E0E5E9' } : null;
	const className = `oneLine ${trash ? 'device_in_trash' : ''} ${deviceStatus == 'DISCONNECTED' ? ' device_is_discontected': ''}`
	return(
            <div 	className = { className }
            		style = { styles }>
				<div className = "cell_name cell">
					<Link to = { `device/${serialNumber}` }>{ serialNumber }</Link>
				</div>
				<div className = "cell_owner cell">
				    <LinkToVehicle vehicle = {vehicle}/>
				    {
				    	vehicle?.type == 'VEHICLE' ?
    				    <button onClick = {()=>authInClient(owner, vehicle.type, vehicle)}
	       		       			style = {{display: 'inline-block', float: 'none',}}
	       						className = 'goToClient'>
	       						{goToClientAppImg('goToClientIcon', vehicle.type == 'VEHICLE' ? 'GLOBUS': "TERES")}
	       				</button> :
	       				null
				    }
				</div>
{/*				<div className = "cell_owner cell">
				    {owner?owner:'notSet'}
				</div>*/}
				<div className = "cell_owner cell">
					<LinkToClient login = {ifNoKey(vehicle, 'owner')} />
				</div>
				<div className = "cell_comment cell">
				    {comment}
				</div>
				<div className = "cell_date cell">
				  {moment(lastKnownTime).format('Do MMMM YYYY, H:mm:ss')}
				</div>
				<div className = "cell_status cell" style = {
																trash ?
																{ fontWeight: 'bold' } :
																null
															}>
				    { textOfDeviceStatus(deviceStatus, trash) }
				</div>
				<div className = "cell_edit cell">
				</div>
				<div className = "cell_delete cell">
				            {
				            	trash ?
				            		restoreImg('delete', () => restoreDevice({
				            			serialNumber: serialNumber,
				            			kindDevice: 'Devices',
				            		})) :
				            		deleteImg("delete", () => deleteDevice({
				            			serialNumber: serialNumber,
				            			kindDevice: 'Devices',
				            		}))
				            }
				</div>
            </div>
          )
}

function LinkToClient(props) {
	const { login } = props;
	return 	login ?
			<Link to = { `client/${login}/edit` }>{login}</Link>:
			null
}

function LinkToVehicle(props) {
	const { vehicle } = props;
	return 	vehicle ?
			<Link to = { `transport/${vehicle.owner}/${vehicle.name}/${vehicle.vehicleId}/editVehicle` }>
				{vehicle.name}
			</Link> :
			null
}



