import React, { useState, useEffect, useRef } from 'react';
import './CustomSelect.css';

const CustomSelect = ({ options, placeholder = '', defaultValue = '', onOptionSelect }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState(defaultValue);
	const selectRef = useRef(null);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (option) => {
		setSelectedOption(option);
		setIsOpen(false);
		if (onOptionSelect) {
			onOptionSelect(option);
		}
	};

	const handleClickOutside = (event) => {
		if (selectRef.current && !selectRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	return (
		<div className="custom-select" ref={selectRef}>
			<div className="select-header" onClick={toggleDropdown}>
				{selectedOption || placeholder}
				<span className={`arrow ${isOpen ? 'open' : ''}`}></span>
			</div>
			{isOpen && (
				<ul className="select-options">
					{options.map((option, index) => (
						<li
							key={index}
							className="select-option"
							onClick={() => handleOptionClick(option)}
						>
							{option}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default CustomSelect;
