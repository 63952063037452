import React                               from 'react'
import { Link }                            from 'react-router-dom'
import Navigation                          from '../modules/AdminNavigation.jsx'
import { Provider, connect }               from 'react-redux'
import { ifNoKey, arrayIsEmpty }           from '../actions/ActionCreator.jsx'
import logsApi                             from '../actions/logsApi.js'
// import Immutable                           from 'immutable';
// import DynamicHeightTableColumn            from './logsPage/DynamicHeightTableColumn.jsx'
import moment                              from 'moment'
import HintData                            from './transportPage/HintData.jsx'
import Helmet                              from 'react-helmet';
import TableFooter, { TableFooterMethods } from "modules/devicePage/TableFooter.jsx"
import Search                              from './component/Search.jsx'
import LogTable                            from "./logsPage/LogTable.jsx"
import { getPage }                         from "./Devices.jsx"
import _                                   from 'lodash'
import TimeInput 						   from "dd-date-time-input"
import l_v from "store/languages.jsx"
import Preloader from "modules/preloader.jsx"
class LogsPage extends TableFooterMethods(React.Component) {
	constructor(props) {
		super(props);
		this.state = {
			login: 				this.props.login,
			loadedRowCount: 	0,
      		loadedRowsMap: 		{},
      		loadingRowCount: 	0,
      		list: 				null,
      		subjectId: 			'',
      		subject: 			'' ,
      		kindUser: 			'',
      		from:				null,
      		to: 				null,
            ...this.paginationState

		}
		this.searchParam = '';
		this.handleFilterChange = _.debounce(this.handleFilterChange, 800);
	}
	availableSubject = [
		{name: l_v.devices, 		id: 'device'},
		{name: l_v.sensors,   	id: 'sensor'},
		{name: l_v.transports,  id: 'vehicle'},
		{name: l_v.users,   		id: 'user'},
		{name: l_v.rfid_cards, 	id: 'rfid_card'},
		{name: l_v.fueling, 		id: 'gas_station'}
	]
	componentDidMount() {
		let subject = ifNoKey(this.props.match.params, 'kind');
		let subjectId = ifNoKey(this.props.match.params, 'id');
		this.setState({
			subject: !!subject?subject:'',
			subjectId: !!subjectId?subjectId:'',
		});
		let heightHead = this.headDiv.clientHeight + 48;
		this.height = window.innerHeight - heightHead;
		if(!!this.state.kindUser){
			this.doRequest()
		}
	}
	doRequest = () => {
		const {
			size,
			searchParam,
			kindUser,
			current_page,
			count_lines_for_view,
			from, to
		} = this.state;
		this.preloader.show();
		logsApi.getLog({
			size:        count_lines_for_view,
			page:        this.pageNumber,
			searchParam: this.searchParam,
			groupRole:   kindUser,
			from: 		 from ? moment(from).format() : null,
			to: 		 to ? moment(to).format() : null
		}, contentInfo => this.setState({
				list:   		contentInfo.content,
				totalElements: 	contentInfo.totalElements

			}, this.preloader.hide))
	}
	valueForSort = (value) =>{
		this.searchParam = value;
		// this.handleFilterChange();
	}
	handleFilterChange = (e) => {
		const { kindUser, count_lines_for_view } = this.state;
		if(this.searchParam !== undefined) {
			let searchQuery = this.searchParam;
			let { count_lines_for_view } = this.state;
			logsApi.getLog({
				page:        0,
				size:        count_lines_for_view,
				searchParam: searchQuery,
				groupRole:   kindUser,
			}, contentInfo => this.setState({
				start_index:   0,
				end_index:     count_lines_for_view,
				list:          contentInfo.content,
				totalElements: contentInfo.totalElements
			}))
		}
	}
	hint_enter_to_state = (e) =>{
		this.setState({[e.name]: e.value});
	}
	enterToState = (e) => {
		this.setState({[e.target.name]: e.target.value});
	}
  	render() {
        const { start_index, end_index, count_lines_for_view, totalElements, from, to } = this.state;

	    return (
		    <div className = 'logs'>
		    	<Helmet title = {l_v.log} />

		      	<Navigation />
		      	<div 	className='container content'
		      			ref = {headDiv =>this.headDiv = headDiv}>
		       		<h1 className="page_name">{l_v.log}</h1>
		       		<div className = 'one_filter'>
						<p>{l_v.user_actions} </p>
			       		<HintData  	index = 			'no_index'
											name = 			'kindUser'
											data = 			{[{id: 'client', name: l_v.client}, {id: 'admin', name: l_v.admin}]}
											check = 			{this.state.check}
											value = 			{this.state.kindUser}
											returnData = 	{this.hint_enter_to_state}
											placeholder = 	{l_v.admin}/>
					</div>
					<div className = 'one_filter'>
						<p>{l_v.log} </p>
			       		<Search 	placeholder = 	{l_v.logs_search_placeholder}
										onChange = 		{this.valueForSort}/>
					</div>
					<div className = 'one_filter'>
						<p>{l_v.period_start} </p>
						<TimeInput 	placeholder = 	'25.08.1988 9:45'
									name = 			'from'
									value = 		{from}
									onChange = 		{(e)=>this.setState({from: e.value})} />
					</div>
					<div className = 'one_filter'>
						<p>{l_v.end_period} </p>
						<TimeInput 	placeholder = 	'25.08.1988 9:45'
										name = 			'to'
										value = 			{to}
										onChange = 		{(e)=>this.setState({to: e.value})} />
					</div>
					<button name = 		'sendRequest'
							className = 'btn_black'
							disabled = 	{!!this.state.kindUser?false:true}
							onClick = 	{this.doRequest}>{l_v.request_data}</button>
					<LogTable list = {this.state.list} />
					<TableFooter    changePage = 				{this.changePage}
					                show_pagination = 			{totalElements?true:false}
					                count_of_available_lines = 	{totalElements}
					                count_lines_for_view = 		{count_lines_for_view}
					                setCountsLineForView = 		{this.updateCountForView}
					                start_index = 				{start_index}
					                end_index = 				{end_index} />
		      	</div>
		      	<Preloader ref = {el => this.preloader = el }/>
		    </div>
	    )
	}

}
export default connect(
      state => ({
        login: state.authorizationR.login,
        answer: state.clientsR.answer,
        kindUser: state.authorizationR.kindUser,
      }),
)(LogsPage)
