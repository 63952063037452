function initHelps() {
    window.coCSS = 'color: green; font-weight: bold;';
    window.myHotCall = function(obj) {
        this._myHotCall_ = this._myHotCall_ ? Object.assign(this._myHotCall_, obj) : { ...obj };
    };
    const devHelpers = {
        initDevMode: function() { //Visibility fast menu, may some else, search in project condition with 'its_developer'
            const durability = 365 * 24 * 60 * 60 * 1000; //1 year
            const expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + durability);
            document.cookie = `its_developer=true; expires=${expireDate.toUTCString()}; path=/`;//for fast menu in bottom of page
        }
    };
    window.myHotCall(devHelpers);
    window.hint = 'You can use hot call functions for manage transport settings, enter name _myHotCall_ in console '
    window.a = window.hint;
}

export default initHelps;