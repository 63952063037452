import React from 'react';
import createReactClass from 'create-react-class';
import Listener from '../mixins/listener';
import compareProps from '../utils/compare-props';
import events from '../events/marker.js'
import 'images/marker_place.svg'
let marker_icon = {url: '/images/marker_place.svg'};

export default class MarkerPlace extends React.Component{
	constructor(props) {
		super(props);
		this.state={
			marker: ''
		}
	}
    componentDidMount() {
    	let map = this.props.map,
    	marker = this.initMarker(this.props.lat, this.props.lng);
    }
    componentWillReceiveProps(nextProps){
    	let map = this.props.map, marker = this.state.marker;
    	if(!!nextProps.lat && !!nextProps.lng){
    		if(marker) {
    			marker.setPosition({lat:nextProps.lat,	lng:nextProps.lng})
    		}else{
    			this.initMarker(nextProps.lat, nextProps.lng)
    		}
    	}
    }
    initMarker = (lat, lng)=>{
		if(!lat || !lng){
			lat=this.state.lat;
			lng=this.state.lng;
		}
		if(!lat || !lng){
			console.log('no location')
			return
		}
		let marker;
		marker = new google.maps.Marker({
	           map: 		this.props.map,
	           draggable: 	false,
	           Icon: 		marker_icon,
	           optimized: 	false,
	           labelClass: 	'lableClass',
	           iconClass: 	'iconCLass',
	           zIndex: 		100,
	           position: 	{lat: lat, lng: lng},
	           title: 		this.props.title,
	           date: 		this.props.date,
	    });


	    this.setState({
	    	marker: marker,
	    	lat: lat,
	    	lng: lng,
	    })

	    return marker
	}
    render(props, state) {
     	return (null)
    }
}