import React 	from 'react'
import _ 		from 'lodash'
class MenuForPage extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			active: false,
		}
		this.lookForPosition = _.throttle(this.lookForPosition, 800)
		this.updateState = _.debounce(this.updateState, 400)
	}
	scrollIntoView = (id) =>{
		var element = document.getElementById(id);
		element.scrollIntoView({block: "start", behavior: "smooth"});
	}
	componentWillMount(){
		window.addEventListener('mousemove', this.lookForPosition)
	}
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount(){
		window.removeEventListener('mousemove', this.lookForPosition);
		this.mounted = false;
	}
	updateState = (value) =>{
		// console.log("%c updateState", 'color: green; font-weight: bold;', new Date())
		this.setState({active: value});
	}
	lookForPosition = (e) =>{
		if(this.mounted){
			const {active} = this.state;
			const menuWidth = this.menu.clientWidth;
			if(window.outerWidth - e.clientX < 20){
				this.updateState(true)
			}else{
				if(this.state.active && window.outerWidth - e.clientX > menuWidth){
					this.updateState(false)

				}
			}
		}

	}
	selectItem = (id) =>{
		this.updateState(false)
		this.scrollIntoView(id)
	}
	render() {
		if(Array.isArray(this.props.info)){
			return 	<ul className = 'help_menu'
						ref = {menu => this.menu = menu}
						style = {this.style(this.state.active)}>
					{
						this.props.info.map(key=>{
							return <li key={"menu" + key.id} onClick = {()=>this.selectItem(key.id)}>{key.name}</li>
						})
					}
					</ul>
		}else return null

	}
	style(active){
		if(!!active){
			return {
				opacity: 0.8,
				visibility: 'visible'
			}
		}else{
			return {
				opacity: 0,
				visibility: 'hidden'
			}
		}
	}
}
export default MenuForPage;