import React                 from 'react'
import { Provider, connect } from 'react-redux'
import WebsocketActions      from 'actions/Websocket.js'
import devicesApi            from 'actions/devicesApi.js'
import { setText }           from "actions/bugReport.jsx"
import l_v                   from "store/languages.jsx"
import Preloader             from 'modules/preloader.jsx'

class CommandToDevice extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			command_text: 		'',
			deviceSerial: 		this.props.deviceSerial,
			commmands_history: 	this.props.commmands_history,
		}
	}
	componentWillReceiveProps(nextProps) {
		const {
			socket,
			deviceSerial,
			commmands_history
		} = nextProps;

		this.setState({
			deviceSerial: deviceSerial,
			commmands_history: commmands_history
		});
		this.deviceSerial = nextProps.deviceSerial;

		if (!this.webSocketObj && socket && deviceSerial) {
			this.webSocketObj = socket;
			this.registerSessionByDevice()
		}
	}
	onPressEnter = e => {
		if(e.key === "Enter") this.sendMessage()
	}
	componentWillUnmount() {
		this.mounted = false;		

	}
	componentDidMount() {
		this.mounted = true;		
		const onGetHistory = () => {if(this.mounted) this.preloader.hide()};
		this.webSocketObj = this.props.socket;
		this.deviceSerial = this.props.deviceSerial;
		if(this.props.deviceSerial) { //disabled for debug?
			this.preloader.show()
			devicesApi.getCommandsHistory(this.props.deviceSerial, onGetHistory)
		}
		if(this.webSocketObj) this.registerSessionByDevice();
		this.inputCommandText.focus();

	}
	enterToState = e => {
		this.setState({
			command_text: e.target.value
		});
	}
	updateEnteredCommand = value => { //used for update from parent component from outside
		this.setState({
			command_text: value.trim()
		}, () => {
			this.inputCommandText.focus();
			this.inputCommandText.select();
		});
	}
	registerSessionByDevice = () => this.sendToWebsocket({
													"regId":         Number(this.deviceSerial),
													"name":          "registerSessionByDevice",
													"commandString": "registerSessionByDevice"
												})
	sendToWebsocket = messageObj => {
		if(this.webSocketObj) {
			this.webSocketObj.send(JSON.stringify(messageObj))
		}else{
			setText(l_v.no_connection, null);
			console.warn(
							`%c sendToWebsocket this.webSocketObj is not present`, 
							"color: pink; font-weight: bold; text-transform:uppercase", 
							this.webSocketObj, messageObj
			             )
		}
	}
	sendMessage = e => {
		e ? e.preventDefault() : null;
		
		const { socket } = this.props;		
		const { command_text, deviceSerial } = this.state;
		
		this.sendToWebsocket({
			"name": 			"executeStringCommand",
			"regId": 			deviceSerial,
			"commandString": 	command_text
		});
		
		this.inputCommandText.focus();
		this.inputCommandText.select();
		
		setText(l_v.command_sent(command_text), null);
	}
	getHistoryList = e => { //not used
		e ? e.preventDefault() : null;
		this.sendToWebsocket({
			"regId":         Number(this.state.deviceSerial),
			"name":          "getDeviceCommandsHistory",
			"commandString": "getDeviceCommandsHistory",
		})
	}
	
	
	render() {
		const {deviceSerial} = this.state;
		const btnStyles = { display: deviceSerial ? 'inline-block' : 'none' };		
		return (
			<form className = 'command_to_device' autoComplete = 'on'>
					<Preloader ref = {el => this.preloader = el}/>
					<input 	type = 			'text'
								ref = 			{el => this.inputCommandText = el}
								className = 	'input'
								name = 			'command'
								placeholder = 	{l_v.enter_command}
								autoComplete = 'on'
								value = 			{this.state.command_text}
								onChange = 		{this.enterToState}/>

					<button className='btn_black' onClick = {this.sendMessage}>
							{l_v.send}
					</button>
					<button onClick = 	{this.getHistoryList}
							type = 		"submit"
							className = 'btn_black' >
							{l_v.get_command_list}
					</button>

				</form>
		);
	}
}
export default connect(
      state => ({
        // login: state.authorizationR.login,
        token: 			state.authorizationR.token,
        thisKindUser: 	state.authorizationR.kindUser,
        userPremission: state.authorizationR.user_permission,
        socket: 		state.authorizationR.socket,
      }), null, null, { forwardRef: true }
)(CommandToDevice)