import React, { Component, Fragment } from 'react';
import logsApi from 'actions/logsApi.js'
import { ifNoKey, arrayIsEmpty, IsJsonString, doCopy } from 'actions/ActionCreator.jsx'
import Helmet from 'react-helmet';
import Navigation from './AdminNavigation.jsx'
import SeparPermission                from './component/SeparPermission.jsx'
import l_v             from "store/languages.jsx"

export default class TokensStatisticPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            loginToTokensAmount:     null,
            loginToSessionsAmount: null,
            sessionsAmount: null,
            tokensAmount: null,
        }
        this.initialState = this.state;
    }


    componentWillMount() {
        logsApi.getTokenStatistic(statistic => this.setState(statistic))
    }
    removeAllTokens = () => {
        logsApi.removeAllTokens(() => logsApi.getTokenStatistic(statistic => this.setState(statistic)) )
    }
    render() {
        const {
            loginToTokensAmount,
            loginToSessionsAmount,
            sessionsAmount,
            tokensAmount
        } = this.state;
        const arrLoginToTokensAmount = getKeys(loginToTokensAmount);
        const arrLoginToSessionsAmount = getKeys(loginToSessionsAmount);
        return  <Fragment>
                    <Helmet title = {l_v.tokens_session_statistic} />
                    <Navigation />
                    <SeparPermission    alowed =            'SUPERADMIN'
                                        type =              'page'/>
                    <div className = 'statistic_page content'>

                            <h1 className="page_name">{l_v.tokens_session_statistic}</h1>
                            <div className = 'one_block flex_container'>
                                <div>
                                    <p>{l_v.number_of_sessions} {sessionsAmount}</p>
                                    <p>{l_v.number_of_tokens} {tokensAmount}</p>
                                </div>
                                <div>
                                    <button className = 'primari_btn'
                                            onClick = {this.removeAllTokens}>
                                        {l_v.remove_tokens}
                                    </button>
                                </div>
                            </div>
                            <div className = 'one_block'>
                                <h3>{l_v.login_amount_token}</h3>
                                {
                                    arrayIsEmpty(arrLoginToTokensAmount) ?
                                    arrLoginToTokensAmount.map(login => <div key = {login}>
                                                                            <i style = {{minWidth: '120px', display: 'inline-block'}}>{login} -</i> {loginToTokensAmount[login]}
                                                                        </div>) :
                                    l_v.no_data
                                }
                            </div>
                            <div className = 'one_block'>
                                <h3>{l_v.login_amount_sessions}</h3>
                                {
                                    arrayIsEmpty(arrLoginToSessionsAmount) ?
                                    arrLoginToSessionsAmount.map(login =>   <div key = {login}>
                                                                                <i style = {{minWidth: '120px', display: 'inline-block'}}>{login} - </i> {loginToSessionsAmount[login]}
                                                                            </div>) :
                                    l_v.no_data
                                }
                            </div>
                    </div>
                </Fragment>

    }
}

function getKeys(object) {
    return object ? Object.keys(object) : null
}