import React 	              from 'react'
import axios                  from 'axios'
import language_variant       from "store/languages.jsx"

import 'css/error_boundary.scss'
import 'images/frontEnd_crash.jpg';
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    				hasError: false,
    				error: false,
					errorInfo: false,
                    visible: true,
                    lastClick: {
                        class: '',
                        text: '',
                        tagName: '',
                        type: ''
                    }
				};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidMount() {
      this.mount = true;
  }
  componentWillUnmount() {
      this.mount = false;
      window.removeEventListener('click', this.listenner, false)
  }
  listenner = (e)=> {
        if(!this.state.hasError && this.mount){
            e = e || window.event;
            let target = e.target || e.srcElement,
                text = target.textContent || target.innerText,
                tagName = e.target.tagName,
                type = e.target.type;
                this.setState({
                    lastClick: {
                        class: target.getAttribute('class'),
                        text: text,
                        tagName: tagName,
                        type: type
                    }
                });
        }

    }
  componentWillMount() {
    window.addEventListener('click', this.listenner, false)
  }
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
	   this.setState({
	      error: error,
	      errorInfo: info
	    })
	   console.log("%c this.props.children", 'color: green; font-weight: bold;',  this.props.children)
  }
  reloadPage(){
    document.location.reload(true);
  }
  sendInfoAboutError = () =>{
    let {error, errorInfo, lastClick} = this.state;
    let info =  error.toString() + '\n' + errorInfo.componentStack + '\n';
    info += 'USER_LOGIN: ' + localStorage.getItem('login') + '\n';
    info += 'LOCATION: ' + location.href + '\n';
    info += 'LAST_CLICK - '  + '\n';
    info += 'ELEMENT_CLASS: ' + lastClick.class + '\n';
    info += 'ELEMENT_TEXT: ' + lastClick.text + '\n';
    info += 'ELEMENT_TAGNAME: ' + lastClick.tagName + '\n';
    info += 'ELEMENT_TYPE: ' + lastClick.type + '\n';
    setTimeout(() => axios({
            url: "https://hooks.slack.com/services/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",
            method: 'POST',
            data: JSON.stringify({
                "icon_emoji":   ":hugging_face:",
                "userName":     this.login,
                "text": '*front end bug*'+ '\n'+ '```\n' + info + ' \n```'
            })
        }).then(this.hideErrorInfo), 1000) //for user tranquility
  }
  hideErrorInfo = () =>{
    this.setState({
        visible: false
    });
  }
  render() {
    let {visible} = this.state;
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div className = 'error_boundary' style = {{visibility: visible?'visible':'hidden'}}>
                <img src = 'images/frontEnd_crash.jpg' className = 'error_back'/>
                <div className = "error_info_container">
          			<h1>{language_variant.something_went_wrong}</h1>
      				<details style={{ whiteSpace: 'pre-wrap' }}>
    		            {this.state.error && this.state.error.toString()}
    		            <br />
    		            {this.state.errorInfo.componentStack}
                         <br />
                    </details>
                    <div>
                        <h3>{language_variant.please_send_error}</h3>
                        <button className = 'primari_btn' name = 'sendInfo' onClick = {this.sendInfoAboutError}>{language_variant.send_info}</button>
                        <button className = 'primari_btn' name = 'reloadPage' onClick = {this.reloadPage}>{language_variant.reload_page}</button>
                        <button className = 'primari_btn' name = 'hideInfo' onClick = {this.hideErrorInfo}>{language_variant.hide_notif}</button>
                    </div>

                 </div>
      		</div>
    }

    return this.props.children;
  }
}