const initialState = {
	clientsData: "test",
	AdminData: [
		// {
		// 	login: '',
		// 	description: '',
		// 	capabilities: {},
		// 	blocked: '',
		// 	belongsTo: '',
		// 	blocked: '',
		// }
	],
	ClientData: undefined,
	DealerData: undefined,
	UserData: [],
	answer: "",
	textanswer: '',
	user_capabilities: '',
	user_login: '',
	user_blocked: '',
	user_description: '',
	user_group: '',
	user_expires: '',
	filter_data: '',
}
export default function clientsR(state = initialState, action) {
	switch (action.type) {
		case 'setUSER':
			return {
				UserData: action.setUSERData,
			};
		case 'setSUPERADMIN':
			return {
				AdminData: action.setSUPERADMINData,
			};
		case 'setADMIN':
			return {
				AdminData: action.setADMINData,
			};
		case 'setCLIENT':
			return {
				...state,
				ClientData: action.setCLIENTData,
			};
		case 'setDEALER':
			return {
				DealerData: action.setDEALERData,
			};
		case 'CURRENT_USER':
			return {
				...state,
				thisUser: action.setThisUser
			};
		case 'STATUS_REQUEST':
			return {
				...state,
				answer: action.setAnswer,
				textanswer: action.settextanswer,
			};
		case 'UPDATE_CAPABILITIES':
			return {
				...state,
				user_capabilities: action.update_capabilities,
				user_login: action.update_username,
				user_blocked: action.update_blocked,
				user_description: action.updatedescription,
				user_group: action.updateusergroup,
				user_expires: action.updateuser_expires,
			};
		case 'GROUP_FILTER':
			return {
				...state,
				filter_data: action.upadate_filter_data
			};
		default:
			return state;
	}
}