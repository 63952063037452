import store            from 'store/configureStore.jsx'
import axios            from 'axios'
import infoStore        from './infoStore.js'
import WebsocketActions from "./Websocket.js"
import l_v 					from "store/languages.jsx"
import bugReport 			from "actions/bugReport.jsx"
import SL 					from "sl-library"
const formAction = {
	get kindUser(){
		return getKindUser()
	},
	get isOrigin(){
		return isOrigin()
	},
	savePermission:           savePermission,
	saveSession:              saveSession,
	saveKindUser:             saveKindUser,
	saveLogin:                saveLogin,
	logOut:                   logOut,
	saveAccountType:          saveAccountType,
	saveUrlBeforeDeportation: saveUrlBeforeDeportation,
	getUrlBeforeDeportation:  getUrlBeforeDeportation,
	redirectUser:             redirectUser,
	checkingVersionApp: 	  checkingVersionApp,
	newAuthorization: 		  newAuthorization,
	checkToken: 			  checkToken,
	clearLoginInfo: 		  clearLoginInfo

}
export default formAction;

function setAnswerOnError(data) {
	store.dispatch({
		type: 'FORM_AUTH_FAILED',
		answerForUser: data
	});
}

function newAuthorization(login, password, setAnswerOnError) {
	const dataForSend = {
		baseURL: infoStore().base_url,
		url:     `/api/auth/admin/v2/login`,
		method:  'POST',
		headers: { "Content-Type": "multipart/form-data", },
		timeout: 15000,
		data:    createFormData(login, password)

	};
	axios(dataForSend).then( answer => {
			const accountInfo = answer.data;
			savePermission(accountInfo);
			saveSession(answer.headers.authorization);
			saveKindUser(accountInfo.kind);
			saveLogin(accountInfo.login);
			redirectUser(accountInfo)
		})
		.catch( error => {
			consoleLogError(error)
			const errorMessage = error.response ? error.response.data.message : error.message;
			let answertext = '';
			if(error.message == 'Network Error') {
				answertext = l_v.server_not_available;
			} else {
				if(error.response.status === 403) {
					answertext = l_v.errorauthorization;
				}
				if(error.response.status === 404) {
					answertext = l_v.errorconnection;
				}
			}
			if(errorMessage  == 'Too many login failures'){
				answertext = l_v.too_many_login_attempts
			}
			if(!answertext){
				answertext = ` ${error.response.status}`
			}
			setAnswerOnError(answertext);
		})

	function createFormData(login, password) {
		const formData = new FormData();
		formData.append("username", login);
		formData.append("password", password);
		return formData
	}
}

export function logOut(callback){
	const requestInfo = {
		baseURL: infoStore().base_url,
		url:             '/api/v2/logout',
		method:          'GET', // "PUT",//,
		dataType:        "JSON", //формат
		withCredentials: true,
		headers:         { authorization: infoStore().token },
	}
	axios(requestInfo)
		.then(answer => {
			clearLoginInfo()
			window.location.hash = '';
			localStorage.setItem('url_before_deportation', null);
		})
		.catch(serveranswer => {
			clearLoginInfo();
			window.location.hash = '';
			localStorage.setItem('url_before_deportation', null);
		})
}

function consoleLogError(error){
	console.log("%c error", coCSS, error)
	console.log("%c error.data", coCSS, error.data)
	console.log("%c error.response", coCSS, error.response)
	console.log("%c .status", coCSS, error.status)
	console.log("%c .message", coCSS, error.message)
	console.log("%c .code", coCSS, error.code)
	console.log("%c .stack", coCSS, error.stack)
}

export function checkingVersionApp() {
	const dataForSend = {
		baseURL: window.location.origin,
		url: "version/",
		method: 'GET',
		headers: { "Content-Type": "text/html" },
		withCredentials: true,
	};

	axios(dataForSend)
		.then((answer) => {
			const versionAppOnHosting = answer.data;
			const scriptVersion = APP_VERSION;

			let normalizedScriptVersion = scriptVersion.replace(/^V-/, '').replace(/-/g, '.');
			normalizedScriptVersion = normalizedScriptVersion.replace(/\.$/, '');

			console.log("%c CHECKINGVERSIONAPP answer", coCSS, answer);
			console.log("%c scriptVersion", coCSS, normalizedScriptVersion);
			console.log("%c versionAppOnHosting", coCSS, normalizedScriptVersion);
			console.log("%c normalizedScriptVersion !== versionAppOnHosting", coCSS, normalizedScriptVersion !== versionAppOnHosting);

			if (versionAppOnHosting && normalizedScriptVersion !== versionAppOnHosting) {
				window.location.reload();
			}
		})
		.catch(e => console.log("%c unvailable app version", coCSS, e));
}


function redirectUser(accountInfo) {
	let page_after_login;
	if(!accountInfo){
		console.warn('accountInfo not valid', accountInfo);
		page_after_login = '';
		window.location.hash = page_after_login;
		return
	}else{
		if(accountInfo.kind == 'ADMIN' || accountInfo.kind == "SUPERADMIN" || accountInfo.kind == "DEALER") {
			page_after_login = setUrl("clients");
		}
		if(accountInfo.kind == 'CLIENT') {
			saveAccountType(accountInfo.accountType)
			page_after_login = setUrl("transports/" + accountInfo.accountType);
		}
		if(!accountInfo.kind) {
			console.log("%c auth answer is accountInfo", 'color: red; font-weight: bold;', accountInfo)
			page_after_login = ''
		}
	}

	console.log("%c page_after_login", coCSS, page_after_login)
	// alert(page_after_login)
	window.location.hash = page_after_login;
	function setUrl(place){
		const url_before_dep = getUrlBeforeDeportation();
		console.log("%c url_before_dep", coCSS, url_before_dep)
		// return url_before_dep ? url_before_dep : place
		return place
	}
}

function savePermission(data) {
	store.dispatch({
		type: 'USER_PREMISSION',
		getpremission: data
	})
	let sObj = JSON.stringify(data);
	localStorage.setItem("user_permission", sObj)
}

export function saveSession(sid) {
	localStorage.setItem('sid', sid);
	store.dispatch({
		type: 'SESSION_TOKEN',
		savetoken: sid
	})

}

function saveKindUser(kind) {
	store.dispatch({
		type: 'KIND_USER',
		savekindUser: kind
	})
	localStorage.setItem('kindUser', kind);
}

function getKindUser() {
	return localStorage.getItem('kindUser');
}

function saveAccountType(type) {
	store.dispatch({
		type: 'ACCOUNT_TYPE',
		setType: type
	})
	localStorage.setItem('accountType', type);
}

function getAccountType() {
	return localStorage.getItem('accountType', type);
}

export function saveLogin(login) {
	localStorage.setItem('login', login);
	store.dispatch({
		type: 'FORM_AUTH_LOGIN_UPDATE',
		login: login,
	})
}

function saveUrlBeforeDeportation() {
	window.location.hash !== "" ?
	localStorage.setItem('url_before_deportation', window.location.href) :
	localStorage.setItem('url_before_deportation', null)
}

function getUrlBeforeDeportation(arg) {
	return localStorage.getItem('url_before_deportation') == 'null' ? null : localStorage.getItem('url_before_deportation')
}

function clearLoginInfo() {
	saveUrlBeforeDeportation(null);
	saveSession(null);
	saveLogin(null);
	savePermission(undefined);
	saveKindUser(undefined);
	WebsocketActions.clearWebsocket(null);

//require refactoring
	// saveKindUser(null);
	// saveAccountType(null);
	// savePermission(null);
}

function checkToken(okeyFunc, onProblemFunc) {
	const state = store.getState();
	const token = infoStore().token;
	if(!token) {
		if(!isOrigin()) {
			window.location.hash = '';
			clearLoginInfo();
		}
		return
	}
	const requestInfo = {
		baseURL:         infoStore().base_url,
		url:             "/api/check-token-for-expiration",
		method:          'POST',
		headers:         { authorization: token },
		withCredentials: true,
		params:          { token: token },
	};
	axios(requestInfo).then( answer => {
		if(okeyFunc) {
			setTimeout(okeyFunc, 3000);
		} else {
			if(isOrigin()) redirectUser(infoStore().user_permission)
		}
	}).catch( answer => {
		if(SL.ifNoKey(answer, 'status') === 401 && onProblemFunc) {
			setTimeout(onProblemFunc, 1000)//for custom methods, def instruction in bugReport
			window.location.hash = '';
			clearLoginInfo();
		}

		bugReport(answer, store)
	})
}
export function isOrigin() {
	return window.location.hash === '' || window.location.hash === '#/';
}
