import React                 from "react"
import Required              from './Required.jsx'
import RequireWrapper        from './RequireWrapper.jsx'
import SeparPermission       from './SeparPermission.jsx'
import SL                    from "actions/ActionCreator.jsx"
import { closeImg }          from '../img.jsx'
import EnterListennerForForm from "modules/component/EnterListennerForForm.jsx"
import l_v 					 from "store/languages.jsx"
export default class BtnAddTransport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bonusinput: 		'',
			name: 				'',
			owner: 				this.props.defaultowner,
			description: 		'',
			group: 				'',
			licensePlate: 		'',
			period1: 			30,
			period2: 			300,
			sensor: 			[],
			lastKnownTime: 		null,
			tub: 				this.props.type,
			formIsVisible: 		false,
			permissionForGroup: this.props.permissionForGroup,
			kindUser: 			this.props.kindUser,
			visibilityOwner: 	'',
			btnvisibility: 		'',
			number: 			'',
			check: 				false,
			fuelingWithoutConnection: false,
			minimalAmountImpulse: 1
		}
		this.initialState = SL.copyOfObject(this.state);
		this.requires = {};
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			permissionForGroup: nextProps.permissionForGroup,
			owner: nextProps.defaultowner,
		})
	}
	componentWillMount() {
		let display = 'block';
		let btnvisibility = 'visible';
		if (this.props.kindUser == 'CLIENT'){
				display = 'none';
		}
		this.setState({
			visibilityOwner: display,
			btnvisibility: btnvisibility
		})
	}
	toogleFormVisibility = () => {
		const { formIsVisible } = this.state;
		this.setState({
			formIsVisible: !formIsVisible,
		})
	}
	changeTub = (e) =>{
		this.setState({tub: e.target.name});
	}
	enterToState = (e) => {
		let value = e.target.value
		if(e.target.name == 'name' || e.target.name == 'licensePlate'){
			value = SL.checkOnSharp(value);
			value = value.replace('/','');
		}
		this.setState({
			[e.target.name]: value
		})
	}
	setChecboxValue = (e) =>{
		this.setState({
			[e.target.name]: e.target.checked
		});
	}
	addition = (event) =>{
		let {minimalAmountImpulse, fuelingWithoutConnection, tub} = this.state;
		const dataForSend = {
								name: 			this.state.name,
								licensePlate: 	this.state.licensePlate,
								group: 			this.state.group,
								description: 	this.state.description,
								period1: 		Number(this.state.period1),
								period2: 		Number(this.state.period2),
								owner:  		this.state.owner,
								type: 	 		tub,
								// sensors: 		this.state.sensor,
							};
		if(tub == 'GAS_STATION'){
			dataForSend.minimalAmountImpulse = minimalAmountImpulse;
			dataForSend.fuelingWithoutConnection = fuelingWithoutConnection;
		}
		this.props.dataFromform(dataForSend)
		this.setState(this.initialState);
	}
	updateFieldState(data){
		console.log('updateFieldState(data)', data)
		this.setState({
			sensor: data,
		})
	}
	onSubmit = (e) =>{
		console.log("%c CHECKREQUIRE e", coCSS, e)
		e.preventDefault()
		this.setState({
			check: true,
		});
		let validate = true;
		let name;
		Object.keys(this.requires).map(key=>{
			if(this.requires[key] == false){
				validate = false;
				name = key;
			}
		})
		if(!!validate){
			this.setState({
					message: 	'',
					check: 		false,
			})
			this.addition()
		}else{
			this.setState({
				message: `${l_v.fill_marked_fields}`,
			})
		}
	}
	requireElem = (name, value) =>{
		if(name == 'owner' && !!this.props.defaultowner){
			this.requires[name] = true;
		}else{
			this.requires[name] = value;
		}
	}
	render() {
		const {tub, fuelingWithoutConnection, minimalAmountImpulse, formIsVisible} = this.state;
		return <div className = 'add_info '>
					<span 	className = 'btn_black'
							onClick = {this.toogleFormVisibility}>
							{this.props.btnname}
					</span>
					{
						formIsVisible ?
						<EnterListennerForForm onPressEnter = { this.addition }>
							<form 	className = {'add_info_form transport_form show_form'}
									onSubmit = {this.onSubmit}>
								{closeImg('close', this.toogleFormVisibility)}
								<div className = 'form_message'>
									<p>{this.state.message}</p>
								</div>
								<div className = 'flex_container'>
									{this.props.bonusinput}
									<RequireWrapper checkRequire = {this.requireElem}
													checkIt = {this.state.check}>
										<input 	type = 			'text'
												name = 			'name'
												className = 	"input"
												value = 		{this.state.name}
												placeholder = 	{`${l_v.vehicle_name} *`}
												onChange = 		{this.enterToState} />
									</RequireWrapper>
									<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, CLIENT'
														kindCapability = 'editVehicles'
														type =           'component'>
										<RequireWrapper checkRequire = 	{this.requireElem}
														checkIt = 		{this.state.check}>
											<input 	type = 			'text'
													name = 			"owner"
													className = 	"input belongsTo"
													value = 		{this.state.owner}
													placeholder = 	{`${l_v.owner} *`}
													onChange = 		{this.enterToState} />
										</RequireWrapper>
									</SeparPermission>
									<textarea 	name = 		"description"
												value = 	{this.state.description}
												type = 		"text"
												className = "form_text_area"
												placeholder = {l_v.description}
												onChange = 	{this.enterToState.bind(this)}/>
									{
										tub == 'VEHICLE'?
										<input 	name = 			"licensePlate"
												value = 		{this.state.licensePlate}
												type = 			"text"
												className = 	{'input '}
												placeholder = 	{l_v.plate_number}
												onChange = 		{this.enterToState}/> :
										null
									}
								</div>
								<div>
									<RequireWrapper checkRequire = {this.requireElem}
													checkIt = {this.state.check}>
										<input 	name = 					"period1"
												value = 				{this.state.period1}
												type = 					"number"
												min = 					{1}
												className = 			{'input ' }
												placeholder = 			{`${l_v.transfer_period} 1*`}
												onChange = 				{this.enterToState} />
									</RequireWrapper>
									<RequireWrapper checkRequire = {this.requireElem}
												checkIt = {this.state.check}>
										<input 	name = 					"period2"
												value = 				{this.state.period2}
												type = 					"number"
												min = 					{1}
												className = 			{'input period2 '}
												placeholder = 			{`${l_v.transfer_period} 2*`}
												onChange = 				{this.enterToState}/>
									</RequireWrapper>
								</div>
								<GasStationSettings minimalAmountImpulse = {minimalAmountImpulse}
													enterToState = {this.enterToState}
													setChecboxValue = {this.setChecboxValue}
													fuelingWithoutConnection = {fuelingWithoutConnection}
													tub = {tub} />
								<button type="submit"
 										className = 'btn_black'>
										{ tub == 'VEHICLE' ? l_v.add_vehicle : l_v.add_gas_station }
								</button>
							</form>
						</EnterListennerForForm>	:
						null
					}

				</div>
		}
}

function TubsForChange(props) {
	const {changeTub, tub} = props;
	let styleForActive = {
		backgroundColor: '#4179f8',
		color: 'white'
	}
	return <div className = 'tubs_container'>
				<button className = 'vehicle_tub btn_black'
						name = 'VEHICLE'
						style = {tub == 'VEHICLE' ? styleForActive : null}
						onClick = {changeTub}>
						{l_v.transports}
				</button>
				<button className = 'gas_station_tub btn_black'
						style = {tub == 'GAS_STATION' ? styleForActive : null}
						name = 'GAS_STATION'
						onClick = {changeTub}>
						{l_v.gas_station}
				</button>
			</div>
}

function GasStationSettings(props) {
	const {minimalAmountImpulse, tub, enterToState, fuelingWithoutConnection, setChecboxValue} = props;
	return 	tub == 'GAS_STATION'?
			<div className = 'gas_station'>
				<input 	type = 			'text'
						name = 			'minimalAmountImpulse'
						className = 	"input"
						value = 		{minimalAmountImpulse}
						placeholder = 	{`${l_v.min_impulse_period} *`}
						onChange = 		{enterToState} />
				<label 	className = 'checkbox_label'>
					{l_v.filling_without_connection}
					<input 	type = 	'checkbox'
							id =	'checkbox_fuelingWithoutConnection'
							name = 	'fuelingWithoutConnection'
							className = 'checkbox'
							checked = 	{fuelingWithoutConnection}
							onChange = 	{setChecboxValue} />
				</label>

			</div>:
			null
}