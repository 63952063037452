import React 				from 'react'
import language_variant 	from '../../store/languages.jsx'
import ClickOutside  		from 'react-click-outside'

export default class SimpleSelectList extends React.Component{
	//props:
	// list=[{id:'..', name: '..', value: '..'}],
	//default_value = {id:'..', name: '..', value: '..'},
	//class_name (sting) - class name of container
	//class_on_active (sting) - class name of itemlist
	//height (integer) - value in pixels
	//return_value return result of select
	constructor(props) {
		super(props);
		this.defValue = !!this.props.default_value ? this.props.default_value : { id: null, name: !!this.props.placeholder ? this.props.placeholder : '', value: null };
		this.state = {
			list: this.props.list,
			selected_item: !!this.props.selected_item ? this.props.selected_item : this.defValue,
			active: false,
			warning: false,
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			list: nextProps.list,
			selected_item: !!nextProps.selected_item ? nextProps.selected_item : this.defValue,
		})
	}
	setWarning = () => this.setState({ warning: true });
	item_list = (data) => {
		return data.map(key => <li  	key =	{key.id}
									value =	{key.value}
									style =	{this.styleSelected_item(key.value)}
									onClick={this.selectValue}>
									{key.name}
								</li>)
	}
	selectValue = (e) => {
		let selected_item, value;
		let { list } = this.state;
		value = e.target.attributes.getNamedItem('value').value;
		selected_item = list.find((key) => {
			if (value == key.value) {
				return key
			}
		})
		this.setState({
			active: false,
			warning: false,
		})
		this.props.returnValue(selected_item)
	}
	render(){
		const {active, selected_item, list, warning} = this.state;
		let {class_name, class_on_active, listHeight} = this.props;
		class_on_active = !!class_on_active?class_on_active:'list_open';
		listHeight = !!listHeight?listHeight:150;
		return(
				<ClickOutside 	className = 		{!!class_name?class_name:'container_of_select_list'}
								onClickOutside =	{()=>this.setState({active: false})}>
					<div className = 'simple_select_list'>
						<button 	name = 		'currentItem'
									style = 	{{boxShadow: !!warning?'inset 0px 0px 17px -5px rgba(240, 36, 36, 0.88)':null}}
									className =		'selected_item'
									onClick =		{(e) => {
											e.preventDefault()
											this.setState({active: !active})
										}
									}>
									{selected_item.name}
									<i className = 'triangle' style={this.styleTriangle(active)}></i>
						</button>
						<ul className = {!!active?'list ' + class_on_active:'list'}
							style = 	{{maxHeight: !!active?listHeight+'px':null}}>
							{
								!!list?
								this.item_list(list):
								<li>{language_variant.no_data}</li>
							}
						</ul>
					</div>
				</ClickOutside>
			)
	}
	styleSelected_item = (value) =>{
		return value === this.state.selected_item.value?{fontWeight: 'bold'}:null
	}
	styleTriangle = (active)=>{
		return !!active?
				{
					borderLeft: '4px solid transparent',
				    borderRight: '4px solid transparent',
				    borderBottom: '4px solid black',
				    borderTop: 'none',
				}:
				{
					borderLeft: '4px solid transparent',
				    borderRight: '4px solid transparent',
				    borderBottom: 'none',
				    borderTop: '4px solid black',
				}
	}
}