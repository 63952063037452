import React 								from 'react'
import language_variant 					from '../../store/languages.jsx'
import ClickOutside  						from 'react-click-outside'
import OneTransport 						from './OneTransport.jsx'
import {closeIcon} 							from 'images/simple_svg_icons.jsx'
import {arrayIsEmpty, toLowerCase} 			from '../../actions/ActionCreator.jsx'

export default class CreateGroup extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			groupName: '',
			transportList: this.props.transportList,
			vehiclesInGroup: [],
		}
	}
	enterToState = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	showFrom = () =>{
		this.setState({
			active: true,
			transportList: this.props.transportList,
		});
	}
	hideForm = () => {
		this.setState({	active: false})
	}
	handleChange = (event) => {
		this.setState({
			groupName: event.target.value
		})
	}
	handleSearch = (event) => {
		this.setState({
			transportList: filterTransportByNameVSLiscensePlate(this.props.transportList, event.target.value)
		});
	}
	addThis = (vehicleId) => {
		let vehiclesInGroup = this.state.vehiclesInGroup;
		vehiclesInGroup.push(vehicleId)
		this.setState({
			vehiclesInGroup: vehiclesInGroup
		})
	}
	removeThis = (vehicleId) => {
		let vehiclesInGroup = this.state.vehiclesInGroup;
		let index = vehiclesInGroup.indexOf(vehicleId);
		if(index !== -1) {
			vehiclesInGroup.splice(index, index)
			this.setState({
				vehiclesInGroup: vehiclesInGroup
			})
		}
	}
	save = () => {
		this.props.saveNewGroup(this.state.groupName, this.state.vehiclesInGroup)
	}
	render(){
		const {active, groupName} = this.state;
		return	active ?
				<ClickOutside className='edit_group' onClickOutside = {this.hideForm}>
						<p className="head_edit_group">
							<span>{language_variant.createGroup}</span>
							{closeIcon('close', this.hideForm)}
						</p>
						<input  type = 			"text"
			                  	className = 	"group_name_input"
			                  	placeholder = 	{language_variant.name_group}
			                  	onChange = 		{this.enterToState}
			                  	name = 			'groupName'
			                  	value = 		{this.state.groupName}/>
		                <div className = 'help_div_edit_group'>
			                <ul className = 'list_edit_group'>
			                <li className = 'only_text'>
			                	{language_variant.selectVehicles}
			                </li>
			               	 <TransportList transportList = {this.state.transportList}
			               	 				addThis = {this.addThis}
			               	 				vehiclesInGroup = {this.state.vehiclesInGroup}
											removeThis = {this.removeThis}/>
			                </ul>
			            </div>
			            <div className = "filter_list">
				            <input  type = 			"text"
					                className = 	"transport_search"
					                placeholder = 	{language_variant.transport_search_placeholder}
					                onChange = 		{this.handleSearch}/>
				            <span> {language_variant.transport_search}</span>
			            </div>
			            <div className = "footer_edit_group">
				            <span>M
					            <button className = "cancel no_style_btn"
					            		onClick = {this.hideForm}>
					            	{language_variant.cancel}
					            </button>
					            <button className = "primari_btn"
					            		disabled = {groupName ? false : true}
				                 		onClick = 	{this.save}>
				                 	{language_variant.createGroup}
				                </button>
			                </span>
			            </div>


				</ClickOutside> :
				null

	}
	transportList = (transportList) =>{

		}
}
export function TransportList(props) {
	let {transportList, addThis, removeThis, groupName, vehiclesInGroup} = props;
	if(arrayIsEmpty(transportList)){
		return <div>{transportList.map(key => <OneTransport 	key = 			{key.id}
																group =			{key.group}
																name =			{key.name}
																licensePlate = 	{key.licensePlate}
																active = 		{isInList(key.id, vehiclesInGroup)}
																addThis = 		{addThis}
																removeThis = 	{removeThis}
																currentGroup = 	{groupName}
																id = 			{key.id}/>
								)}
				</div>
	}
	else{
		return <li >{language_variant.nodata}</li>
	}
}
function isInList(vehicleId, vehiclesInGroup){
	if(arrayIsEmpty(vehiclesInGroup)){
		if( vehiclesInGroup.indexOf(vehicleId) !== -1){
			return true
		}else{
			return false
		}
	}else{
		return false
	}
}

export function filterTransportByNameVSLiscensePlate(currentList, searchValue) {
	if(arrayIsEmpty(currentList)){
		let searchQuery = toLowerCase(searchValue)
		let displayedTransport = currentList.filter(function(el) {
			let name = toLowerCase(el.name),
				licensePlate = toLowerCase(el.licensePlate);
			let searchValue = name + licensePlate;
			return searchValue.indexOf(searchQuery) !== -1;
		});
		return displayedTransport
	}else{
		return null
	}

}
