import React, { Component } from 'react';
import {connect} from 'react-redux'
import Users 									from 'actions/users.js'
import l_v from "store/languages.jsx"

export default class InitAdmin extends Component {
	constructor(props){
		super(props);
		this.state = {
			login: '',
			password: ''
		}
		this.initialState = this.state;
	}
	showForm = (e) => {
		this.setState({[e.target.name]: e.target.value})
	}
	send = () =>{
		let {login, password} = this.state;
		let afterCreate = () => {
			alert('SUPERADMIN успешно создан, логин: '+ login +'пароль: '+ password);
			window.location = '/'
		}
		Users.createSuperAdmin(login, password, afterCreate)
	}
	render() {
		return <div>Был план сделать форму но увы)</div>
		return (
			<div>
				<input 	type="text"
			            name="login"
			            placeholder={language_variant.login_placeholder}
			            className="form_input"
			            value = {this.state.login}
						onChange = {this.enterToState}
			            required/>
	            <input 	type = 			"password"
		            	name =  		"password"
		            	placeholder = 	{language_variant.password_placeholder}
		            	className = 	"form_input"
		            	value = 		{this.state.password}
						onChange = 		{this.enterToState}
						required/>
				<Button type="button"
			            name="send"
			            className="btn"
			            value={language_variant.button_send}
			            onClick={this.send}>
			            {l_v.create_super_user}
	            </Button>


			</div>
		);
	}
}
