import React from 'react';
import 'images/ring-alt.gif'
export default class Preloader extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			active: this.props.active,
		}
	}
	hide = () => this.setState({active: false})
	show = () => this.setState({active: true})
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	render(ReactElement, DOMElement, callback){
		const { active } = this.state;
		return 	active ?
				preloaderHTMLCode :
				null
	}
}

const preloaderHTMLCode = <div className="lds-default">
								<div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
							</div>;