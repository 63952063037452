import React from 'react';
import SL from 'sl-library';
import transportsApi from 'actions/transportsApi.js';
import l_v from 'store/languages.jsx';
import TransportCounters from 'modules/transportPage/TransportCounters.jsx';
import TransportParameters from 'modules/transportPage/TransportParameters.jsx';
import GasStationSettings from 'modules/transportPage/GasStationSettings.jsx';
import Preloader from 'modules/preloader.jsx';

class VehicleSettings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			selected_content: 'settings',
			settings: undefined,
		};
		console.log('%c [Constructor] Component Initialized', 'color: green');
	}

	selectContent = (event) => {
		console.log(`%c [selectContent] Changing content to ${event.target.name}`, 'color: blue');
		this.setState({
			selected_content: event.target.name
		});
	}

	componentDidMount() {
		this.mounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.settings !== this.props.settings || prevProps.additionalSettings !== this.props.additionalSettings) {
			this.setState({
				settings: SL.copyOfObject(this.props.settings),
				...this.props.additionalSettings
			});
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		const shouldUpdate = nextProps.settings !== this.props.settings ||
			nextProps.additionalSettings !== this.props.additionalSettings ||
			nextState.selected_content !== this.state.selected_content ||
			nextState.settings !== this.state.settings;

		return shouldUpdate;
	}

	updateSettings = (value, name) => {

		const { settings } = this.state;
		const settingInfo = settings.find(el => el.name === name);

		if (settingInfo && settingInfo.value === value) {
			return;
		}

		this.setState(prevState => {
			const updatedSettings = prevState.settings.map(setting =>
				setting.name === name ? { ...setting, value } : setting
			);
			return { settings: updatedSettings };
		});

		const onUpdateSettings = (newSettings) => {
			if (this.mounted) {
				const updatedSettings = SL.copyOfObject(this.props.settings);
				const indexOfSetting = updatedSettings.findIndex(el => el.name === name);

				if (updatedSettings[indexOfSetting].value !== value) {
					updatedSettings[indexOfSetting].value = value;
					this.props.onUpdateSettings({ settings: updatedSettings });
				}
			}
		};

		if (name === "mileage_algorithm_type") {
			let settingValue;
			if (typeof value === 'boolean') {
				settingValue = value ? "HAVERSINE" : "DEFAULT";
			} else {
				settingValue = value;
			}

			transportsApi.updateVehicleSettings(this.props.vehicleId,
				[{ value: settingValue, name: name }],
				onUpdateSettings
			);
		} else {
			const settingValue = settingInfo.typeValue === "BOOLEAN" ? value : parseInt(value, 10);

			transportsApi.updateVehicleSettings(this.props.vehicleId,
				[{ value: settingValue, name: name }],
				onUpdateSettings
			);
		}
	};



	render() {
		console.log('%c [render] Rendering component', 'color: purple');
		const { selected_content } = this.state;
		const { type } = this.props;
		const tubActiveStyle = {
			opacity: 1,
			borderColor: 'rgb(14, 14, 14)',
		};

		return (
			<div className='transport_profile'>
				<div className='_header'>
					<p className='transport_name'>
						<span className='plate'> {type === 'GAS_STATION' ? l_v.gas_station_settings : l_v.transport_settings}</span>
					</p>
					<button className='default_link tabs'
									name='settings'
									style={selected_content === 'settings' ? tubActiveStyle : null}
									onClick={this.selectContent}>
						{l_v.parameters}
					</button>
					{type === 'GAS_STATION' && (
						<button className='default_link tabs'
										name='gasStationSettings'
										style={selected_content === 'gasStationSettings' ? tubActiveStyle : null}
										onClick={this.selectContent}>
							{l_v.gas_station_params}
						</button>
					)}
				</div>
				{this.activeTub()}
				<Preloader ref={preloader => this.preloader = preloader} />
			</div>
		);
	}

	activeTub = () => {
		const { selected_content, settings } = this.state;
		console.log(`%c [activeTub] Active tab: ${selected_content}`, 'color: purple');
		if (selected_content === 'settings') {
			return <MemoizedTransportParameters settingsArray={settings} sendToServer={this.updateSettings} />;
		}
		if (selected_content === 'counters') {
			return <MemoizedTransportCounters data={this.props.counters_data} sendToServer={this.updateCounter} />;
		}
		if (selected_content === 'gasStationSettings') {
			return (
				<MemoizedGasStationSettings
					settings={this.state.settings}
					clearCacheForMonth={this.clearCacheForMonth}
					clearAllCache={this.clearAllCache}
					clearCacheForPeriod={this.clearCacheForPeriod}
					updateTankSize={this.updateTankSize}
					updatePeriods={this.updatePeriods}
					updateSettings={this.updateAdditionalSettings}
					updateFuelingWithoutConnection={this.updateFuelingWithoutConnection}
					additionalSettings={{
						tankVolume: this.state.tankVolume,
						tankVolumeStart: this.state.tankVolumeStart,
						isObtainFuelConsumptionByDut: this.state.isObtainFuelConsumptionByDut,
					}}
				/>
			);
		}
	}
}

const MemoizedTransportParameters = React.memo(TransportParameters);
const MemoizedTransportCounters = React.memo(TransportCounters);
const MemoizedGasStationSettings = React.memo(GasStationSettings);

export default VehicleSettings;
