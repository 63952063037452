import React, {Fragment} 		from 'react'
import { Provider, connect} 	from 'react-redux'
import Navigation 				from '../modules/AdminNavigation.jsx'
import Calibration 				from '../actions/calibrations.js'
import Search 					from './component/Search.jsx'
import Calibrations 			from './calibrations/Calibrations.jsx'
import SeparPermission 			from './component/SeparPermission.jsx'
import AddCalibration 			from './calibrations/AddCalibration.jsx'
import Helmet               	from 'react-helmet';
import moment 					from "moment"
import _  					    from "lodash"
import l_v                         from "../store/languages.jsx"

class ArchiveCalibrationsPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			calibrations: this.props.calibrations
		}
		this.createCalibration = (infoForSend) => console.log("%c it's function not updated", coCSS, infoForSend);
		this.crutchForCreateGlobalCalibration = (infoForSend) => this.createCalibration(infoForSend);
		this.handleFilterChange = _.debounce(this.handleFilterChange, 400)
	}
	componentWillReceiveProps(nextProps) {
		this.setState({ calibrations: nextProps.calibrations })
	}
	handleFilterChange = (searchQuery) =>Calibration.getArchive(searchQuery, this.calibrations.setAfterFilter)
	render(){
		const additinalHeadCells = <Fragment>
										<div className = 'cell'>{l_v.device_number}</div>
										<div className = 'cell'>{l_v.removed}</div>
										<div className = 'cell'>{l_v.sensor_number}</div>
										<div className = 'cell'>{l_v.auto_id}</div>
									</Fragment>;
		const 	additinalCells = (calibrationInfo)	=> 	<Fragment>
											<div className = 'cell'>{calibrationInfo.deviceId}</div>
											<div className = 'cell' title = {moment(calibrationInfo.deletedAt).format('DD.MM.YYYY - HH.mm')}>
												{moment(calibrationInfo.deletedAt).format('DD.MM.YYYY - HH.mm')}
											</div>
											<div className = 'cell'>{calibrationInfo.sensorNum}</div>
											<div className = 'cell'>{calibrationInfo.controlEntityId}</div>
										</Fragment>;
		return <div>
		 			<Helmet title = { l_v.archive_calibration} />
					<Navigation />
						<div className='container content calibrations'>
		        			<h1 className =	"page_name_client" >{l_v.archive_calibration}</h1>
		        			<div className = 'head_group' ref = {mountPlace => this.mountPlace = mountPlace}>
			        			<Search 	placeholder = 	{l_v.enter_device_number}
			        						name = 			'imei'
						                	onChange = 		{this.handleFilterChange}/>
							    {/*<AddCalibration 	dataFromForm = {this.crutchForCreateGlobalCalibration}
								       				globalCalibrations = {true}/>*/}
					        </div>
							<Calibrations 	isArchiveCalibrations = { true }
											additinalHeadCells =    { additinalHeadCells }
											additinalCells =        { additinalCells }
											updateListInChild =     { createCalibration => this.createCalibration = createCalibration }
											mountPlace =            { this.mountPlace }
											ref =                   { calibrations => this.calibrations = calibrations }
											dataFromform =          { () => alert('непонятно что делать') } />
						</div>
				</div>

	}
}
export default connect(
	    state => ({
	    	login: 				state.authorizationR.login,
			token: 				state.authorizationR.token,
			user_permission: 	state.authorizationR.user_permission,
			thisUser: 			state.clientsR.thisUser,
	    })
)(ArchiveCalibrationsPage)
