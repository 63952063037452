import {arrayIsEmpty, copyOfObject} 			from "actions/ActionCreator.jsx"
import Calibration 				from "actions/calibrations.js"
import sensorsApi 				from "actions/updateSensor.jsx"


class InstallTemplate {
	constructor(context) {
		this.typeDevice =                   context.typeDevice;
		this.multiSensors =                 context.multiSensors;
		this.realSensors =                  context.realSensors;
		this.virtualSensors =               context.virtualSensors;
		this.calibrations =                 context.calibrations;
		this.vehicleId =                    context.vehicleId
		this.deviceId =                     context.deviceId;
		this.wasErrorOnInstall = false;
		this.errorLog = ['all info not installed'];
	}
	install() {
		const onInstallRealSensors = () => {
			this.installMultiSensors()
		}
		const onInstallVirtualSensors = () => {
			this.installRealSensors(onInstallRealSensors);
		}
		this.installVirtualSensors(onInstallVirtualSensors);
	}
	installMultiSensors() {
		if(arrayIsEmpty(this.multiSensors)) {
			this.multiSensors.forEach(multiSensorDTO => {
				multiSensorDTO.realSensorsKey.forEach(realSensorKey => {
					console.log("%c realSensorKey", coCSS, realSensorKey,  this.realSensors[realSensorKey])
					console.log("%c multiSensorDTO", coCSS, multiSensorDTO)
					const realSensorId = this.realSensors[realSensorKey].id
					multiSensorDTO.realSensorSocketIds.push(realSensorId);
				})
				const requiredVirtualSensor = this.virtualSensors.find(el=>multiSensorDTO.virtualSensorType == el.sensorType)
				multiSensorDTO.sensorId = requiredVirtualSensor.virtualSensorId
			})
			console.log("%c this.multiSensors", coCSS, this.multiSensors)
			this.multiSensors.forEach(key => {
				console.log("%c MULTISENSORS key", coCSS, key)
				sensorsApi.createMultiSensor(this.vehicleId, key, ()=>{
					this.errorLog.push(key);
					this.wasErrorOnInstall = true;
				})
			});
		}
		if(!this.wasErrorOnInstall){
			window.location.reload()
		}else{
			alert(JSON.stringify(this.errorLog))
		}
	}
	installVirtualSensors(onInstallVirtualSensors) {
		if(arrayIsEmpty(this.virtualSensors)) {
			const virtualSensorsInstalled = new Array();
			this.virtualSensors.forEach(virutalSensorDto => {
				virtualSensorsInstalled.push(new Promise((resolve, reject) => {
					this.installVirtualSensor(virutalSensorDto, resolve)
				}))
			})
			console.log("%c virtualSensorsInstalled", coCSS, virtualSensorsInstalled)
			Promise.all(virtualSensorsInstalled).then(values => {
				console.log("%c promise all VIRTUALSENSORSINSTALLED", coCSS, values)
				onInstallVirtualSensors()
			});
		}
	}
	installVirtualSensor(virutalSensorDto, resolve) {
		const dataForSaveVirtualSensor = Object.assign({}, virutalSensorDto);
		const setIdToRealSensors = (virtualSensorId) => {
			virutalSensorDto.dependentRealSensors.forEach(keyOfRealSensor => {
				console.log("%c this.realSensors", coCSS, this.realSensors)
				console.log("%c keyOfRealSensor", coCSS, keyOfRealSensor)

				this.realSensors[keyOfRealSensor].sensorId = virtualSensorId
			})
		}
		const callback = (virtualSensorDtoFromServer) => {
			virutalSensorDto.virtualSensorId = virtualSensorDtoFromServer.virtualSensorId;
			setIdToRealSensors(virtualSensorDtoFromServer.virtualSensorId)
			resolve(true);
		}
		delete dataForSaveVirtualSensor.dependentRealSensors;
		dataForSaveVirtualSensor.vehicleId = this.vehicleId;
		sensorsApi.addVirtualSensor(dataForSaveVirtualSensor, callback, ()=>{
			this.errorLog.push(virutalSensorDto);
			this.wasErrorOnInstall = true
		})
	}
	installRealSensors(onInstallRealSensors) {
		const realSensorsIsInstalled = new Array();
		for(let realSensor in this.realSensors) {
			const promiseInstallRealSensor = new Promise((resolve, reject) => {
				const installRealSensor = (calibrationId) => {
					this.realSensors[realSensor].sensorCalibrationId = calibrationId;
					const onInstallRealSensor = (realSensorInfo) => {
						this.realSensors[realSensor].id = realSensorInfo.id;
						resolve(true);
					}
					sensorsApi.createRealSensor(this.realSensors[realSensor], onInstallRealSensor, ()=>{
						this.errorLog.push(this.realSensors[realSensor]);
						this.wasErrorOnInstall = true
					});
				}
				this.installCalibrationsForRealSensors(this.realSensors[realSensor], installRealSensor)
			});
			realSensorsIsInstalled.push(promiseInstallRealSensor)
		}
		Promise.all(realSensorsIsInstalled).then(values => {
			onInstallRealSensors()
		})
	}
	installCalibrationsForRealSensors(realSensorDTO, installRealSensor) {
		const calibrationKey = realSensorDTO.keyNameCalibration;
		console.log("%c calibrationKey, realSensorDTO", coCSS, calibrationKey, realSensorDTO)
		const onInstallCalibration = (calibrationInfo) => {
			this.calibrations[calibrationKey].id = calibrationInfo.id; //write id into template
			installRealSensor(calibrationInfo.id)
		}
		Calibration.addIndividual(this.vehicleId, this.calibrations[calibrationKey], onInstallCalibration, ()=>{
			this.errorLog.push(this.calibrations[calibrationKey]);
			this.wasErrorOnInstall = true
		})
	}
	end() {}
}

export default InstallTemplate