import 'babel-polyfill'
import React          from 'react'
import { HashRouter } from 'react-router-dom'
import { render }     from 'react-dom'
import store          from 'store/configureStore.jsx'
import { Provider }   from 'react-redux'
import {setInitialMomentLocale} from "reducers/language_reducer.jsx"
import App            from './app.jsx'
import ConfirmNotification from "modules/component/ConfirmNotification.jsx"

setInitialMomentLocale();

render(
    <Provider store = { store }>
        <HashRouter>
            <App />
            <AppVersion />
            <ConfirmNotification />
        </HashRouter>
  </Provider>,
    document.getElementById('app_mountpoint')
);

function AppVersion() {
    return <div className = "app-version-div" title = { BUILD_DATE }>{ APP_VERSION }</div>
}

//preload images
import 'images/sprite.png'
import 'images/favicon.ico'
import 'images/faviconDevServer.ico'
import 'images/faviconForMaster.ico'
import 'images/logo.png'
import 'images/marker_place.svg'