import {clear, setgroupfilter, setMessageStatus} from './small_actions.jsx'

const actionCreators = {
		clear : 						clear,
		setgroupfilter: 				setgroupfilter,
		setMessageStatus: 				setMessageStatus,
		ifNoKey: 						ifNoKey,
        isNumeral:                      isNumeral,
        arrayIsEmpty:                   arrayIsEmpty,
        stringToBoolean:                stringToBoolean,
        checkOnSharp: checkOnSharp,
        removeSpaces: removeSpaces,
        copyOfObject: copyOfObject,
};

export function encodeLinkInfo(linkInfoObj) {
    try{
        const jsonString = JSON.stringify(linkInfoObj)
        const encodedLinkInfo = window.btoa(jsonString);
        return encodedLinkInfo
    }catch(e){
        console.warn('ENCODELINKINFO problem linkInfoObj, e', linkInfoObj, e)
        return null
    }
}


export function getLinkInfoParam(stringLocation) {
    let url, searchParams, linkInfoBase64;
    const info = {
        get searchParams(){
            return  searchParams
        },
        get linkInfoBase64(){
            return linkInfoBase64
        },
        get decodedLinkInfo() {
            return linkInfoBase64 ? window.atob(linkInfoBase64) : null
        },
        get isValid() {
            return IsJsonString(this.decodedLinkInfo) ? true : false
        },
        check: function(value){
            return IsJsonString(value) ? true : false
        },
        get obj() {
            return this.isValid ? JSON.parse(this.decodedLinkInfo) : null;
        },
    }
    window.LinkInfoParam = info;
    try {
        url = new URL(stringLocation ? stringLocation : document.location);
        searchParams = new URLSearchParams(url.search);
        linkInfoBase64 = searchParams.get('linkInfo');
        return info
    } catch (e) {
        console.warn('GETLINKINFOPARAM - have problem with link e', e)
        return info
    }



    return info
}

export function stringToBoolean(value) {
    if(value === 'true'){
        value = true
    }
    if(value === 'false'){
        value = false
    }
    return value
}

export function isNumeral(value) {
    value = parseInt(value)
    if(value instanceof Number)
        value = value.valueOf(); // Если это объект числа, то берём значение, которое и будет числом
    return isFinite(value) && value === parseInt(value, 10);
}

export function removeProhibitedSymbol(value) {
   const regex = new RegExp('[^\\w]', 'g');
   return value.replace(regex, '')
}

export function matchPronhibitedSymbol(value) {
    const regex = new RegExp('[^\\w]', 'g');
    return regex.test(value)
}

 // value = checkOnSharp(value);
 //    value = removeSpaces(value);

function getNumberKey(obj, key) {
	let listKeys, index;
	if(obj instanceof Object){
		listKeys = Object.keys(obj)
		index = listKeys.findIndex((el) => {
					if(el == key) return el
				})
	}
	return {listKeys: listKeys, index: index}
}
/*Object.size = function(obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};*/

export function checkPass(pass, patterns, min, max) {
    const defpattern = {
        'numeric': '0-9',
        //         'special':     '^\w\s',
        'latin_lower': 'a-zA-Z',
        //         'latin_upper': 'A-Z'
    }
    const minCountSymbols = {
        numeric: 3,
    }
    const defMin = 7;
    const defMax = 13;

    patterns = !patterns ? defpattern : patterns;
    min = !min ? defMin : min;
    max = !max ? defMax : max;
    const regex = createRegExpForAllowedSymbols();

    if(regex.test(pass) && compareMinCounts(pass) && checkOnSpaces(pass)) {
        return true
    } else {
        return false
    }

    function compareMinCounts(pass) {
        let countNumeric = 0; let countNotNumeric = 0
        const numeric = '['+defpattern.numeric+']';
        for (let i = 0; i < pass.length; i++) {
            if(new RegExp(numeric).test(pass.charAt(i)) ){
                countNumeric += 1;
            }
        }
        for (let i = 0; i < pass.length; i++) {
            if(!new RegExp(numeric).test(pass.charAt(i)) ){
                countNotNumeric += 1;
            }
        }
        return countNumeric >= minCountSymbols.numeric && countNotNumeric >= 5
    }

    function createRegExpForAllowedSymbols() {
        let regex = '';
        let rules = Object.keys(patterns);
        for(let i = 0; i < rules.length; i++) {
            regex += '(?=.*[' + defpattern[rules[i]] + ']{1,})';
        }
        regex += '.{' + min + ',' + max + '}';
        return new RegExp(regex, 'g');
    }
    function checkOnSpaces(pass){
        let spaceReg = new RegExp(/[\t\s]/,'g')
       return  !spaceReg.test(pass)
    }
}

export function doCopy(obj) {
    if(obj instanceof Object) return JSON.parse(JSON.stringify(obj))
}

export function noNull(value) {
    if(value == null || value == undefined) {
        value = '';
    }
    return value
}

export function checkKey(data) {
    if(typeof data == 'string') {
        data = parseInt(data)
    }
    if(!!data || data === 0) {
        return true
    } else return false
}

export function arrayIsEmpty(arr) {
    if(Array.isArray(arr) && !!arr.length) {
        return arr
    } else {
        return false
    }
}

Object.size = function(obj) {
    var size = 0,
        key;
    for(key in obj) {
        if(obj.hasOwnProperty(key)) size++;
    }
    return size;
};

export function IsJsonString(str) {
    try {
        return !!JSON.parse(str) ? true : false;
    } catch (e) {
        return false;
    }
}

export function tryStringify(json) {
    try {
        return JSON.stringify(json)
    } catch (e) {
        console.log("%c try stringify object, but can't json - ", 'color: red; font-weight: bold;', json)
        return "can't stringify"
    }
}

export function ifNoKey(el, key, value, valIfNoKey) {
    if(el instanceof Object) {
        if(key in el) {
            if(value !== undefined) {
                el[key] = value;
                return el;
            }
            return el[key];
        }
        for(let k in el) {
            let rec = ifNoKey(el[k], key, value, valIfNoKey);
            if(value !== undefined) {
                if(typeof rec == 'object') {
                    el[k] = rec;
                    return el;
                }
            }
            if(rec || rec === false || parseInt(rec) === 0) return rec;
        }
        return valIfNoKey;
    }
    return valIfNoKey;
}
/*  use inside component
    let settings = [//wait list of keys
            {
                selector:       'name',//selector what will be used for search dom node (tagName, ., #)
                value:          'zone_name', //value for search dom element
                stateName:      'zoneId' //name values from element in state (optional)
            },
    ]
    let validation = validateElements.bind(this)
        validation = Object.assign(validation(), settings)
        return validation.result*/

export function checkOnSharp(value) {
    if(value.indexOf('#')!== -1){
        alert('Символ # запрещен');
        value = value.replace(/#/g,'');
        return value
    }else{
        return value
    }
}

export function removeSpaces(value) {
    if(value.match(/\t{1,}|\s{1,}/, 'g')){
        value = value.replace(/\t{1,}|\s{1,}/g,'');
        return value
    }else{
        return value
    }
}
import ReactDOM from "react-dom"; //need for this

export function validateElements() {
    const settingsValidation = {
        style: null,
        class: null,
        requiredList: null,
        state: !!this.state ? this.state : null,
        result: null,
        space: null,
        emptyElements: [],
        elementFromHere: function(selector, value) {
            let searchValue;
            if(selector.charAt(0) == '.' || selector.charAt(0) == '#') {
                searchValue = selector;
            } else {
                searchValue = '[' + selector + '=' + value + ']';
            }
            return this.space.querySelector(searchValue);
        },
        checkElements: function() {
            if(Array.isArray(this.requiredList) && this.requiredList.length > 0) {
                let result = true;
                this.requiredList.forEach(key => {
                    const stateName = key.stateName ? key.stateName : key.value;
                    let valueInState = this.state[stateName];
                    if(this.validate(valueInState, key.customValidate)) {
                        this.clearStyle(this.elementFromHere(key.selector, key.value))
                    } else {
                        result = false;
                        let element = this.elementFromHere(key.selector, key.value);
                        this.setStyle(element)
                        this.addEmptyElement(element)
                    }
                })
                this.result = result;
            } else console.log("%c requiredList is empty or has bad format:", 'color: red; font-weight: bold;', this.requiredList)
        },
        validate: function(valueInState, customValidate){
            const isValidBoolean =  typeof valueInState === "boolean";
            const isNumber =  typeof valueInState === 'number';
            const isString = typeof valueInState === 'string';
            const isValidString = isString && valueInState;
            const answer = customValidate ? customValidate(valueInState) : isValidString ;
            // console.log("%c valueInState, customValidate", coCSS, valueInState, customValidate)
            // console.log("%c answerCustomValidation, isValidString", coCSS, answerCustomValidation, isValidString)
            return answer
        },
        setStyle: function(element) {
            if(!!element) {
                if(!!this.style) element.setAttribute('style', this.style);
                if(!!this.class) element.classList.add(this.class);
            }
        },
        clearStyle: function(element) {
            if(!!element) {
                if(!!this.style) element.setAttribute('style', null);
                if(!!this.class) element.classList.remove(this.class);
            }
        },
        addEmptyElement: function(element) {
            this.emptyElements.push(element)
        },
        set space(val) {
            this._space_ = ReactDOM.findDOMNode(val);
        },
        get space() {
            return !!this._space_ ? this._space_ : document
        },
        set result(value) {
            this._result_ = value;
        },
        get result() {
            this.checkElements();
            return this._result_
        }
    }
    return settingsValidation
}

export function removeElementFromList(list, identificator, value) {
    if(arrayIsEmpty(list)) {
        let elemIndex = list.findIndex(key => {
            if(key[identificator] == value) {
                return key
            }
        })
        if(elemIndex == undefined) {
            console.log("%c removeElementFromList: element not found in list, identificator, value, list -",
                'color: pink; font-weight: bold;', identificator, value, list)
        }else{
            list.splice(elemIndex, 1)
        }
    } else {
        console.log("%c removeElementFromList: problem with list, list-",
            'color: red; font-weight: bold;', list)
    }
    return list
}

export function elementFromHere(space, selector, value) { //xz)
    const node = ReactDOM.findDOMNode(space);
    let searchValue;
    if(selector.charAt(0) == '.' || selector.charAt(0) == '#') {
        searchValue = selector;
    } else {
        searchValue = '[' + selector + '=' + value + ']';
    }
    return node.querySelector(searchValue);
}

export function equals(firstArr, array) {
    // if the other array is a falsy value, return
    if(!array || !firstArr) {
        return false;
    }
    // compare lengths - can save a lot of time
    if(firstArr.length != array.length) {
        return false;
    }
    for(var i = 0, l = firstArr.length; i < l; i++) {
        // Check if we have nested arrays
        if(firstArr[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if(equals(!firstArr[i], array[i])) {
                return false;
            }
        } else if(firstArr[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}
//for me
export function exportToTxt(json, keyArray) {
    let content = new String();
    if(!Array.isArray(json) && typeof json !== 'object') {
        alert('на входе должен быть массив или json')
    }
    if(Array.isArray(json)) {
        json.map(key => {
            content += ',' + JSON.stringify(key);
        })
    }
    if(!!keyArray) {
        console.log('la la la')
    } else {
        content = JSON.stringify(json)
    }
    let a = document.createElement("a");
    let file = new Blob([content], { type: 'text/plain' });
    a.href = URL.createObjectURL(file);
    a.download = 'json.txt';
    a.click();
}

export function copyOfObject(obj) {
    let objectIsValid;
    try {
        if(obj && JSON.stringify(obj)){
            objectIsValid = true
        }
    }
    catch (e) {
        objectIsValid = false;
    }

    return objectIsValid ? JSON.parse(JSON.stringify(obj)) : null;
}

export function concatLists(firstList, secondList){
    firstList = doArrayIfIsNot(firstList);
    secondList = doArrayIfIsNot(secondList);
    const newList = firstList.concat(secondList)
    function doArrayIfIsNot(arr) {
        if(!Array.isArray(arr) ){
            arr = new Array()
        }
        return arr
    }

    return newList

}
export function toLowerCase(str){
    if(typeof str == 'number'){
        str = str.toString();
    }
    if(typeof str == "string"){
        return str.toLowerCase()
    }else return null
}
export function toUpperCase(str) {
    if(typeof str == 'number'){
            str.toString();
        }
        if(typeof str == "string"){
            return str.toUpperCase()
        }else return null
}
export function copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isEqualDate(firstDate, secondDate) { //compare to date on occasion if date is same
    firstDate = new Date(firstDate);
    secondDate = new Date(secondDate);
    if(isValidDate(firstDate) && isValidDate(secondDate)) {
        if(new Date(firstDate).getTime() == new Date(secondDate).getTime()) {
            return true
        } else return false
    }
}


export function leaveNumberWithDot(value) {
    if(value){
        value = replaceCommaToDot(value.toString());
        value = removeSecondsDot(value);
        const replacedArray = Array.from(value.split(/\./), value => replaceAllIsNotNumber(value));
        value = replacedArray.join('.');
        if(value.charAt(0) == '.') {
            value = '0' + value
        }
    }

    return value
}
export function leaveNegativeNumberWithDot(value) {
    if(value){
        if(value.indexOf('-') !== -1){
           value = '-'+leaveNumberWithDot(value)
        }else{
            value = leaveNumberWithDot(value)
        }
    }
    return value
}
export function replaceAllIsNotNumber(value) {
    return value.replace(/\D/g, '')
}
export function replaceCommaToDot(value) {
    return value.replace(/,/g,'.')
}
export function removeSecondsDot(value){
    const arrValues = value.split(/\./);
    let newVal = new String('');
    if(arrValues.length>1){
        for(let strIndex in arrValues){
            if(strIndex == 0){
                 newVal += arrValues[strIndex] + '.';
            }else{
                newVal += arrValues[strIndex];
            }
        }
    }else{
        newVal = value
    }

    return newVal
    //value.replace(/(?<=\..{0,})\./g, ''); don't work in safari
}

export default actionCreators





