import React                                             from 'react'
import RequireWrapper                                    from '../component/RequireWrapper.jsx'
import { closeImg }                                      from '../img.jsx'
import sensorsApi                                        from '../../actions/updateSensor.jsx'
import HintData                                          from '../transportPage/HintData.jsx'
import { arrayIsEmpty, checkKey, replaceAllIsNotNumber } from '../../actions/ActionCreator.jsx'
import Pop_up                                            from '../component/Pop_up.jsx'
import l_v                                  			 		from "store/languages.jsx"
import TooltipWrapper                                    from '../component/TooltipWrapper.jsx'
import { connect }                                       from 'react-redux'
import SimpleSelectList                                  from 'modules/component/SimpleSelectList.jsx'
import EnterListennerForForm                             from "modules/component/EnterListennerForForm.jsx"

class AddRealSensor extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			active:                    false,
			sensorNumber:              '',
			sensorNumber_empty:        '',
			sensorCalibrationId:       '',
			sensorCalibrationId_empty: false,
			virtualSensorId:           '',
			preloader:                 false,
			requeiredInputEmpty:     	false,
			userUnderstand:            false,
			name:                      '',
			reverse:                   false,
			fromZero:                  false,
			selectedCalibration: 	   null,
			selectedVirtualSensor: 	   null,
		}
		this.initialState = this.state;
	}
	enterToState = (e) =>{
		let name = e.name||e.target.name;
		let value = e.value || e.target.value;
		if(e.target && e.target.type == 'checkbox'){
			value = e.target.checked
		}
		if(name == 'sensorNumber'){
			value = replaceAllIsNotNumber(value);
		}
		this.setState({
			[name]: value
		});
	}
	save = (e) => {
		if(e) {
			e.preventDefault();
			e.stopPropagation();

		}		
		if(this.validateForm()){
			this.setState({
				preloader: true
			});
			const {selectedCalibration, selectedVirtualSensor} = this.state;
			let forSend = {
				sensorNumber:        this.state.sensorNumber,
				sensorCalibrationId: selectedCalibration ? selectedCalibration.id: '',
				sensorId:            selectedVirtualSensor ? selectedVirtualSensor.id : null,
				name:                this.state.name,
				reverse:             this.state.reverse,
				fromZero:            this.state.fromZero
			};
			this.props.newSensor(forSend)
		}
	}
	cancel =(e) =>{
		e.preventDefault();
		this.clearState();
		this.props.cancel();
	}
	validateForm = () => {
		let { reverse,  selectedCalibration, selectedVirtualSensor, sensorNumber, name } = this.state;
		let	sensorCalibrationId = selectedCalibration ? selectedCalibration.id: '';
		let	virtualSensorId =     selectedVirtualSensor ? selectedVirtualSensor.id : null;
		let typeOfVirtual;
		if(arrayIsEmpty(this.props.virtualSensors) && checkKey(virtualSensorId)) { //get virtualSensor
			let sensor = this.props.virtualSensors.find(key => virtualSensorId == key.sensorId)
			if(sensor) {
				typeOfVirtual = sensor.sensorType;
			}
		}
		if(checkKey(sensorNumber) && checkKey(virtualSensorId) && name && sensorCalibrationId) { //if required field are field
			return true

			// if(typeOfVirtual == "FUEL_LEVEL" || typeOfVirtual == "FUEL_CONSUMPTION") { //if it's fuel and empty then ask user
			// 	if(checkKey(sensorCalibrationId)) { //if are filled then ok
			// 		return true
			// 	} else {
			// 		if(!!this.state.userUnderstand) { //if user understanded problem, then ok
			// 			this.warning.setState({ active: false }) //call pop with alert
			// 			return true
			// 		} else {
			// 			this.warning.setState({ active: true }) //call pop with alert
			// 			return false //break sending
			// 		}
			// 	}
			// } else {
			// 	return true
			// }
		} else {
			if(!checkKey(virtualSensorId) || !name || !checkKey(sensorNumber)) {
				this.selectCalibration.setWarning()
				this.selectIdVirtual.setWarning()
				this.setState({
								requeiredInputEmpty: true,
								message: l_v.fill_marked_fields
							 });
				return false //break sending
			} else {
				this.setState({ requeiredInputEmpty: false , message: ''});
			}


		}
	}
	clearState = () =>{
		this.setState(this.initialState);
	}
	returnToCreate = () =>{
		this.warning.setState({active: false})
		this.setState({
        				userUnderstand: false,
        				sensorCalibrationId_empty: true
        			})
	}
	render(){
		const {selectedCalibration, selectedVirtualSensor, reverse, requeiredInputEmpty, name, sensorNumber} = this.state;
		return 	<EnterListennerForForm onPressEnter = { this.save }>
						<div className = 'sensor_field_container ' >
							<img 	src = 	'images/ring-alt.gif' className = 'preloader'
									style = 	{{visibility: !!this.state.preloader?'visible':'hidden'}}/>
							<p className = 'notif' style = {{ color: 'red' }}>{this.state.message}</p>

							<form 	className = 'add_realSensor_form'
										onSubmit = {this.save}>
								<TooltipWrapper name = {l_v.sensor_number}
												className = 'wrap'>
									<input 	type = 			'text'
											className = 	{`input ${requeiredInputEmpty && !checkKey(sensorNumber) ? 'formError' : ''}` }
											name = 			'sensorNumber'
											value = 		{sensorNumber}
											onChange = 		{this.enterToState}
											placeholder = 	{`${l_v.sensor_number} *`}/>
								</TooltipWrapper>
								<TooltipWrapper name = {l_v.id_of_calibration}
												className = 'wrap'>
									<SimpleSelectList 	list = {modifyCalibrationListForSelect(this.props.calibrations)}
														listHeight = {200}
														class_name  = 'select_list calibration_select_list'
														selected_item = {selectedCalibration}
														placeholder = {`${l_v.id_of_calibration} *`}
														ref = { el => this.selectCalibration = el}
														returnValue   = {e => this.setState({selectedCalibration: e}) } />
									{/*<HintData 	name = 			'sensorCalibrationId'//Id типа датчика датчика
												data = 			{this.props.calibrations}
												value = 		{this.state.sensorCalibrationId}
												check = 		{this.state.sensorCalibrationId_empty}
												returnData = 	{this.enterToState}
												placeholder = 	{l_v.id_of_calibration}/>*/}
								</TooltipWrapper>
								<TooltipWrapper name = {l_v.virtual_sensor_id}
												className = 'wrap'>
									<SimpleSelectList 	list = {modifyVirtualSListForSelect(this.props.virtualSensors)}
														listHeight = {200}
														class_name  = 'select_list virtual_sensors_select_list'
														selected_item = {selectedVirtualSensor}
														placeholder = {`${l_v.virtual_sensor_id} *`}
														ref = { el => this.selectIdVirtual = el}
														returnValue   = {e => this.setState({selectedVirtualSensor: e}) } />
									{/*<HintData 	name = 			'virtualSensorId'//а по факту нужен sensorId
												data = 			{this.props.virtualSensors}
												value = 		{this.state.virtualSensorId}
												check = 		{this.state.requeiredInputEmpty}
												returnData = 	{this.enterToState}
												placeholder = 	'Id виртуального сенсора *'/>*/}
								</TooltipWrapper>
								<TooltipWrapper name = {l_v.sensor_name}
												className = 'wrap last'>
									<input 	type = 			'text'
											className = 	{`input ${requeiredInputEmpty && !name ? 'formError' : ''}`}
											name = 			'name'
											value = 		{name}
											onChange = 		{this.enterToState}
											placeholder = 	{`${l_v.sensor_name} *`}/>
								</TooltipWrapper>
								{/*<div className = 'clear'></div>*/}
								<TooltipWrapper name = {l_v.data_revers}
												className = 'wrap'>
									<label className = 'reverse'>
									<input 	type = 			'checkbox'
											className = 	{'select_checkbox ' }
											name = 			'reverse'

											checked = 		{reverse}
											onChange = 		{this.enterToState}/>
											{l_v.data_revers}
									</label>
								</TooltipWrapper>

								<TooltipWrapper name = {l_v.start_from_zero}
												className = 'wrap last'>
									<label 	htmlFor =	{"fromZero"}
											className = 'reverse'>
											<input 	name = 				"fromZero"
													autoComplete =      "on"
													id = 				{"fromZero"}
													type = 				"checkbox"
													className = 		'select_checkbox'
													checked = 			{this.state.fromZero}
													onChange = 			{this.enterToState}/>
											{l_v.start_from_zero}
									</label>
								</TooltipWrapper>
								<div className = 'clear'></div>
								<button className  = 	'primari_btn'
										type="submit"
										style = 		{{display: 'block'}}
										>
									{l_v.save_data}
								</button>
				        		<button className = 'secondary_btn' onClick = {this.cancel}>
				        			{l_v.cancel}
				        		</button>
							</form>
							<Pop_up 	ref = 			{warning => this.warning = warning}
			   		            head_text = 	{`${l_v.attention}!`}
					               head_back = 	{{backgroundColor:"#d7d9dc", color: 'white', fill: 'white'}}
					               	popUp_size = 	{{maxWidth: '99%', width: '500px', height: '192px'}}>
				               	 	<div className = "confirmText_container">
						                	<p className = 'confirm_text'>{l_v.no_calibration}</p>
						                	<button className = 'primari_btn'
						                			onClick = {	(e)=>{
						                							this.setState({userUnderstand: true}, this.save(e));

						                						}}>
						                		{l_v.continue}
						                	</button>
					                		<button className = 'secondary_btn'
					                				onClick = 	{this.returnToCreate}>
					                			{l_v.cancel}
					                		</button>
						            </div>
			   		        </Pop_up>
						</div>
					</EnterListennerForForm>
	}
}

export default connect(
	    state => ({
	    	calibrations:  state.devicesR.thisCalibrations,
	    }), null, null, { forwardRef: true })(AddRealSensor)


export class EditRealSensorForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		    "realSensorSocketName" : '',
		    "realSensorNumber" : '',
		    "realSensorReverse": false,
		    "realSensorFromZero": false
		}
		this.initialState = this.state;
	}
	setInfoForEdit = (editInfo) => this.setState(editInfo)
	setInitState = () => this.setState(this.initialState);
	enterToState = (e) =>{
		let name = e.name||e.target.name;
		let value = e.value || e.target.value;
		if(e.target && e.target.type == 'checkbox'){
			value = e.target.checked
		}
		if(name == 'realSensorNumber'){
			value = replaceAllIsNotNumber(value)
		}
		this.setState({
			[name]: value
		});
	}
	save = () => {
		const {realSensorNumber, realSensorSocketName} = this.state;
		if(!new String(realSensorNumber).length || !realSensorSocketName.length) {
			this.setState({ sensorNumber_empty: 'formError', message: l_v.fill_marked_fields });
			return false //break sending
		} else {
			this.setState({ sensorNumber_empty: '' })
			delete this.state.sensorNumber_empty
			delete this.state.message
		}
		this.props.updateRealSensor(this.state)
	}
	cancel = () => {
		this.setInitState();
		this.props.cancel()
	}
	render(){
		return 	<div className = 'sensor_field_container add_realSensor_form edit_real_sensor' >
					<TooltipWrapper name = {l_v.sensor_number}
									className = 'wrap'>
						<input 	type = 			'text'
								className = 	{'input ' + this.state.sensorNumber_empty}
								name = 			'realSensorNumber'
								value = 		{this.state.realSensorNumber}
								onChange = 		{this.enterToState}
								placeholder = 	{`${l_v.sensor_number} *`}/>
					</TooltipWrapper>
					<TooltipWrapper name = {l_v.sensor_number}
									className = 'wrap last'>
						<input 	type = 			'text'
								className = 	{'input ' + this.state.sensorNumber_empty}
								name = 			'realSensorSocketName'
								value = 		{this.state.realSensorSocketName}
								onChange = 		{this.enterToState}
								placeholder = 	{`${l_v.sensor_number} * `}/>
					</TooltipWrapper>
					<TooltipWrapper name = {l_v.data_revers}
									className = 'wrap'>
						<label className = 'reverse'>
						<input 	type = 			'checkbox'
								className = 	{'select_checkbox ' }
								name = 			'realSensorReverse'
								checked = 		{this.state.realSensorReverse}
								onChange = 		{this.enterToState}/>
								{l_v.data_revers}
						</label>
					</TooltipWrapper>

					<TooltipWrapper name = {l_v.start_from_zero}
									className = 'wrap last'>
						<label 	htmlFor =	{"fromZero"}
								className = 'reverse'>
						<input 	name = 				"realSensorFromZero"
								id = 				{"fromZero"}
								type = 				"checkbox"
								className = 		'select_checkbox'
								checked = 			{this.state.realSensorFromZero}
								onChange = 			{this.enterToState}/>
								{l_v.start_from_zero}
						</label>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<button className  = 	'primari_btn'
							style = 		{{display: 'block'}}
							onClick = 		{this.save}>
						{l_v.save_data}
					</button>
	        		<button className = 'secondary_btn' onClick = {this.cancel}>
	        			{l_v.cancel}
	        		</button>
				</div>
	}
}


export function modifyCalibrationListForSelect(calibrationsList) {
	return 	arrayIsEmpty(calibrationsList)?
			Array.from(calibrationsList, calibrationDto => {
									return {
												id: calibrationDto.id,
												name: calibrationDto.id + ' ' + calibrationDto.name,
												value: calibrationDto.id
											}
									}
								):
			[]
}

function modifyVirtualSListForSelect(virtualSensorsList) {
	return 	arrayIsEmpty(virtualSensorsList)?
			Array.from(virtualSensorsList, virtualSensorDto => {
									return {
												id: virtualSensorDto.virtualSensorId,
												name: virtualSensorDto.virtualSensorId + ' ' + virtualSensorDto.title + ' ' + virtualSensorDto.sensorType,
												value: virtualSensorDto.virtualSensorId
											}
									}
								):
			[]
}
