import React                    from 'react'
import moment                   from 'moment'
import TooltipWrapper           from '../component/TooltipWrapper.jsx'
import HintData                 from 'modules/transportPage/HintData.jsx'
import TimeInput                from "dd-date-time-input"
import SL 							  from "sl-library"
import l_v                      from "store/languages.jsx"

export default class ChangeRealSensor extends React.Component{
	constructor(props) {
		super(props);
		const { info, previous, defaultPeriod } = this.props;

		this.state = {
			sensorNumber:         !!SL.ifNoKey(previous, 'sensorNumber') ? previous.sensorNumber : '',
			virtualSensorId:      !!SL.ifNoKey(previous, 'virtualSensorId') ? previous.virtualSensorId : '',
			previousRealSensorId: !!SL.ifNoKey(previous, 'realSensorId') ? previous.realSensorId : '',
			dateFrom:             defaultPeriod.from ? moment(defaultPeriod.from) : '',
			dateTo:               defaultPeriod.to ? moment(defaultPeriod.to) : '',
			reverse:              false,
			fromZero:             false
		}
	}
	enterToState = (e) => {
		let name = e.name || e.target.name;
		let value = e.value || e.target.value;
		if(e.target && e.target.type == 'checkbox') {
			value = e.target.checked
		}
		this.setState({
			[name]: value
		});
	}

	render(){
		return 	<form className = 'add_realSensor_form change_real_sensor'>
                 	<p className = 'detail_socket_info_tub_header'>{l_v.change_real_sensor}</p>
						<TooltipWrapper name = {l_v.id_of_the_sensor_to_be_changed}
										position = 'right'
										className = 'wrap'>
							<input 	type = 			'text'
										className = 	{'input ' + this.state.sensorNumber_empty}
										name = 			'previousRealSensorId'
										value = 			{this.state.previousRealSensorId}
										onChange = 		{this.enterToState}
										placeholder = 	{`${l_v.previous_sensor_id} *`}/>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<TooltipWrapper 	name = 		{l_v.sensor_number}
												position = 	'right'
												className = 'wrap'>
							<input 	type = 			'text'
										className = 	{'input ' + this.state.sensorNumber_empty}
										name = 			'sensorNumber'
										value = 			{this.state.sensorNumber}
										onChange = 		{this.enterToState}
										placeholder = 	{`${l_v.sensor_number} *`}/>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<TooltipWrapper name = 			{l_v.virtual_sensor_id}
											className = 	'wrap'>
							<HintData 	name = 			'virtualSensorId'//а по факту нужен sensorId
											data = 			{this.props.virtualSensors}
											value = 			{this.state.virtualSensorId}
											check = 			{this.state.virtualSensorId_empty}
											returnData = 	{this.enterToState}
											placeholder = 	{`${l_v.virtual_sensor_id} *`}/>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<TooltipWrapper 	name = {l_v.date_from_which_to_replace}
												position = 'right'
												className = 'wrap'>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
											name = 			'date_request'
											maxDate = 		{this.state.dateTo}
											value = 			{this.state.dateFrom}
											class = 			'input'
											onChange = 		{(e)=> this.setState({dateFrom: e.value}) }
											labelHide = 	{true}/>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<TooltipWrapper 	name = {l_v.date_of_period_end}
												position = 'right'
												className = 'wrap'>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
											name = 			'dateTo'
											minDate =  		{this.state.dateFrom}
											value = 			{this.state.dateTo}
											class = 			'input'
											onChange = 		{(e)=>this.setState({dateTo: e.value})}
											maxDis = 		{true}
											labelHide = 	{true}/>
						</TooltipWrapper>
						<TooltipWrapper 	name = {l_v.data_revers}
												className = 'wrap last'>
							<label className = 'reverse'>
								<input 	type = 			'checkbox'
											className = 	{'select_checkbox '}
											name = 			'reverse'
											value = 			{this.state.reverse}
											onChange = 		{this.enterToState}/>
											{l_v.data_revers}
							</label>
						</TooltipWrapper>
						<TooltipWrapper 	name = {l_v.start_from_zero}
												className = 'wrap last'>
							<label 	htmlFor =	{"fromZero"}
										className = 'reverse'>
								<input 	name = 				"fromZero"
											id = 					"fromZero"
											type = 				"checkbox"
											className = 		'select_checkbox'
											defaultChecked = 	{this.state.fromZero}
											onChange = 			{this.enterToState}/>
											{l_v.start_from_zero}
							</label>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<button className  = 	'primari_btn'
								style = 		{{display: 'block'}}
								onClick = 		{this.validate}>
							{l_v.change_sensor}
						</button>
				</form>
	}
	validate = (e) => {
		e.preventDefault();
		e.stopPropagation();
		let { dateFrom, dateTo, sensorNumber, virtualSensorId, previousRealSensorId, reverse, fromZero } = this.state;
		if(!!dateFrom && SL.isValidDate(new Date(dateFrom)) !== 'Invalid Date') {
			dateFrom = moment(dateFrom).toISOString();
		} else {
			// this.dateIput.element.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
			dateFrom = null;
		}
		if(dateTo && SL.isValidDate(new Date(dateTo))) {
			dateTo = moment(dateTo).toISOString();
		}
		if(!!sensorNumber && !!virtualSensorId && !!previousRealSensorId && !!dateFrom) {
			const realSensorDto = {
				previousRealSensorId: previousRealSensorId,
				sensorNumber:         sensorNumber,
				virtualSensorId:      virtualSensorId,
				dateFrom:             dateFrom,
				dateTo:               dateTo,
				reverse:              reverse,
				fromZero:             fromZero,
			}
			this.props.changeRealSensor(realSensorDto)
		}
	}
}
