import React, { Component } from 'react';
import Helmet               from 'react-helmet';
import translationsApi 		 from "actions/translationsApi.js"
import Navigation           from './AdminNavigation.jsx'
import l_v                  from "store/languages.jsx"
import SL  						 from "sl-library"
import Preloader            from 'modules/preloader.jsx'

export default class ReatranslationServerPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			deviceForMapping: 	this.props.deviceForMapping,
		}
		this.initialState = this.state;
	}
	componentDidMount() {
		const {server_id} = this.props.match.params;
		this.mounted = true;
		this.preloader.show();

		translationsApi.getServerDTO({
			server_id, 
			callback: (answer) => {
				if(this.mounted) this.preloader.hide()
				this.setState(answer);
			},
			callbackOnError: () => {
				if(this.mounted) this.preloader.hide()

			}
		})
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	render() {
		const {
			id,
			serverHost,
			serverPort,
			serverName,
			retranslatorProtocolType,
			comment,
			retranslateDeviceIds,
		} = this.state;
		return 	<div>
			       	<Helmet title = {`${l_v.server_name} - ${serverName}`} />
						<Navigation />
						<div className = 'container content'>
							<div className = 'retranslation_server_page'>
								<div className = 'server_info'>
									<p><b>ID</b> - {id}</p>
									<p><b>{l_v.server_name}</b> - {serverName}</p>
									<p><b>{l_v.host}</b> - {serverHost}</p>
									<p><b>{l_v.port}</b> - {serverPort}</p>
									<p><b>{l_v.comment}</b> - {comment}</p>
									<p><b>{l_v.relay_protocol}</b> - {retranslatorProtocolType}</p>
									<div className = 'retranslate_list'>
										<h4>{l_v.broadcast_list}:</h4> 
										{
											SL.arrayIsEmpty(retranslateDeviceIds) ? 
											retranslateDeviceIds.map((item, index) => <p key = {index}>{item}</p>) :
											null
										}
									</div>
								</div>
								<Preloader ref = {el => this.preloader = el} />
							</div>	
						</div>	
					</div>	
	}
}

    // "id": 3,
    // "serverHost": "127.0.0.1",
    // "serverPort": 9000,
    // "serverName": "test_1",
    // "retranslatorProtocolType": "TELTONIKA",
    // "comment": "server",
    // "retranslateDeviceIds": [
    //     456,
    //     11111111111,
    //     765645653456,
    //     123123,
    //     123
    // ]