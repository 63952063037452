import React, { Fragment }  from 'react'
import moment               from 'moment'
import SL                   from "sl-library"
import l_v                  from 'store/languages.jsx'
import ClickOutside         from 'react-click-outside'
import TimeInput            from "dd-date-time-input"
import deviceOnTransportApi from 'actions/deviceOnTransportApi.js'
import Preloader            from 'modules/preloader.jsx'
import RequireWrapper       from 'modules/component/RequireWrapper.jsx'
import SwitchInput          from 'modules/component/SwitchInput.jsx'
import HintData             from './HintData.jsx'
import SerialHintData       from './SerialHintData.jsx'

export default class MountDeviceForm extends React.Component {
		constructor(props) {
			super(props);
			this.state = {
				message:          '',
				displaycalendar:  'none',
				displaycalendar2: 'none',
				serial:           '',
				devices:          this.props.devices,
				check:            false,
				copyVirtual:      true,
				remove_date:      null,
				setup_date:       moment(),//current date
				active:           false
			}
			this.initialState = SL.copyOfObject(this.state);
		}
	componentWillReceiveProps(nextProps) {
		this.setState({
			devices: nextProps.devices,
		})
	}
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	enterToState = (event) => this.setState({ [event.target.name]: event.target.value })
	hint_enter_to_state = (data) => this.setState({ [data.name]: data.value })
	setFromSwitch = (value, name) => this.setState({ [name]: value })
	mountDevice = data => {
		this.preloader.show();
		const requestInfo = {
			vehicleId: this.props.vehicleId,
			copyVirtualSensors: this.state.copyVirtual,	
			data: {
				deviceSerial: this.state.deviceSerial,
				from:         this.state.setup_date,
				to:           this.state.remove_date
			},
		}
		
		deviceOnTransportApi.mountDevice({
					requestInfo,
					isGasStation:    this.props.gasStation,
					callbackOnOk:    () => this.setInitialState(),
					callbackOnError: () => this.mounted ? this.preloader.hide() : null,
		});
	}
	setInitialState = () =>{
		this.setState(this.initialState);
		// this.props.onMounDevice()//crutch
		if(this.mounted) this.preloader.hide()
	}
	checkDatesForConflict = (dateFrom, dateTo) => {
		if (dateFrom && dateTo) {
			if (new Date(dateFrom) > new Date(dateTo)) {
				this.setState({ message: l_v.mount_unmount_date });
				return false
			} else {
				return true
			}
		} else {
			if (dateFrom) {
				return true
			} else { 
				return false 
			}
		}
	}
	verifyRequire = (data, event) =>{
		this.setState({
			check: true,
		});
		let validate = true;
		let name;
		if(!this.state.deviceSerial && (!this.state.id && this.state.id !== 0)){
			validate = false
		}
		if(!this.state.setup_date){
			validate = false
			document.getElementById('setup_dateinput').style.boxShadow = 'inset 0px 0px 17px -5px rgba(240, 36, 36, 0.88)';
		}else{
			document.getElementById('setup_dateinput').style.boxShadow = 'none';

		}
		if(!!validate){
			validate = this.checkDatesForConflict(this.state.setup_date, this.state.remove_date)
			if(!!validate){
				this.setState({
					message: 	'',
					check: 		false,
				})
				this.mountDevice()
			}

		}else{
			this.setState({
				message: `${l_v.fill_marked_fields}`,
			})
		}
	}
	render(){
		let {displaycalendar2, displaycalendar, check, message, devices, copyVirtual, active, setup_date, remove_date} = this.state;
		return 	<Fragment>
					<Preloader 	ref = 		{ preloader => this.preloader = preloader}
									message = 	'hide'
									data = 		{true}
									handle = 	{true}/>
					{
						active ?
						<form className = 'mount_form ' ref = {mount_form => this.mount_form = mount_form}>
						   	<p className = "nameForm"> {l_v.eqipment_binding} </p>
							<p className = 'form_message ' style = {{color: 'red'}}>{message}</p>
							<SerialHintData returnData = 	{this.hint_enter_to_state}
											type = 			"text"
											name = 			'deviceSerial'
											check = 		{check}
											placeholder = 	{`${l_v.tracker_number} *`}
											data = 			{devices}/>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
											id = 'setup_date_mount_form_input'
							
										name = 			'setup_date'
										maxDate = 		{remove_date}
										value = 		{setup_date}
										class = 		{'input '}
										onChange = 		{(e)=>this.setState({setup_date: e.value})}
										textLabel = 	{`${l_v.mount_date} *`}/>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
											id = 'removeDate_mount_form_input'
											name = 			'remove_date'
											minDate = 		{setup_date}
											value = 		{remove_date}
											class = 		{'input '}
											onChange = 		{(e)=>this.setState({remove_date: e.value})}
											textLabel = 	{l_v.device_unmount_date}/>
							<div className = 'one_switch' onClick = {this.debug}>
								<SwitchInput 	result = 	{this.setFromSwitch}
												isChecked = {copyVirtual}
												name = 		'copyVirtual'
												tooltip = 	{{on: l_v.copy, off: l_v.do_not_copy}}/>
								<span className = 'name_switch'>{l_v.copy_sensors_from_previous}</span>
							</div>
							
							<button type =		"button"
									className = "btn_black"
									onClick = {()=>this.setState({active: false})}>
									{l_v.cancel}
							</button>
							<button type =		"button"
									className = "btn_black"
									onClick = 	{this.verifyRequire}>
									{l_v.save_settings}
							</button>
						</form> :
						
						<button className = 'primari_btn' onClick = {()=>this.setState({active: true})}>
							{l_v.add_new_tracker}
						</button>
					}
				</Fragment>
	}
}
