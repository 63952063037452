import React 			from 'react'
import SL  				from 'sl-library'

export default class FormInput extends React.Component{
	constructor(props) {
		super(props);
		const {input_value} = this.props;
		this.state = {
			value: 			SL.noNull(input_value),
			crutch_value: 	null,

		}
	}
	static getDerivedStateFromProps(props, state){
		return {
			value: SL.noNull(props.input_value),
		}
	}
	enterToState = (e) => this.setState({crutch_value: e.target.value});
	addition = (e) => {
		e.preventDefault();//prevent page reload
		const { requiredfield, value, crutch_value } = this.state;
		const { name } = this.props;
		this.props.dataFromform({
			[name]: typeof crutch_value == 'string' ? crutch_value : value
		})

	}
	render() {
		const { checkinput, value, crutch_value } = this.state;
		const { placeholder, name, btntext } = this.props;
		const value_for_show = typeof crutch_value == 'string' ? crutch_value : value;
		return (
			<form>
				<p className="nameForm">{this.props.form_name} </p>
				<p className = 'form_message '>{this.state.message}</p>
				<input 	name = 			{name}
						type = 			'text'
						value = 		{value_for_show}
						className = 	{`input`}
						placeholder = 	{placeholder}
						onChange = 		{this.enterToState} />
				<button 	className = 'btn_black'
							type = 'button'
							name = 'send'
							onClick = {this.addition}>
					{btntext}
				</button>
			</form>
		)
	}
}