import React, { Component } from 'react';
import SL from "sl-library"
import { filterIcon }                from 'images/simple_svg_icons.jsx'

export const HigLightContext = React.createContext({});

class RowInstruction {
	get onMouseLeave() {
		const {row_filter_enabled}	= this.context.state;
		return !row_filter_enabled ? this.setEmptyState : () => null
	}
	get onMouseEnter(){
		const {row_filter_enabled}	= this.context.state;
		
		const getValuesAndSetToState = value => {
			this._onMouseEnter_(value);
			this.context.setState({
				linked_CalibrationIds: this.context.linked_CalibrationIds,
				linked_VirtualSensorIds: this.context.linked_VirtualSensorIds,
				linked_RealSensorIds: this.context.linked_RealSensorIds
			});
		}
		return !row_filter_enabled ? getValuesAndSetToState : ()=>null
	}
	toogleFilteringConnectedItems = (e) =>{
		const {row_filter_enabled}	= this.context.state;
		this.context.setState({row_filter_enabled: !row_filter_enabled});
		// this.context.row_filter_enabled = !this.context.row_filter_enabled;
	}
	setEmptyState() {
		this.context.linked_CalibrationIds = [];
		this.context.linked_VirtualSensorIds = [];
		this.context.linked_RealSensorIds = [];
		this.context.setState({
			linked_CalibrationIds: this.context.linked_CalibrationIds,
			linked_VirtualSensorIds: this.context.linked_VirtualSensorIds,
			linked_RealSensorIds: this.context.linked_RealSensorIds
		});
	}
	get listsOfLinkedIds(){
		return [
			this.context.linked_CalibrationIds,
			this.context.linked_VirtualSensorIds,
			this.context.linked_RealSensorIds,
		]
	}
	get anyRowIsSelected(){
		return SL.arrayIsEmpty(this.listsOfLinkedIds.filter(el => SL.arrayIsEmpty(el)))
	}
	
	getStyle(currentId) {
		const { row_filter_enabled } = this.context.state;
		
		if(row_filter_enabled && this.anyRowIsSelected) return !this.rowPresentInList(currentId) ? { display: 'none' } : null;
		return this.rowPresentInList(currentId) ? { backgroundColor: this.color } : null;
	}
	rowPresentInList(rowId) {
		return this.selfList.find(idInList => idInList === rowId) ? true : false
	}
}

const VirtualSensorRow = class VirtualSensorRow extends RowInstruction {
	constructor(context) {
		super(context);
		this.context = context;
	}
	get selfList(){
		return this.context.linked_VirtualSensorIds	
	}
	color = '#dfe6ec'
	_onMouseEnter_ = virtualSensorId => {
		const { realSensorSocketIds, calibrationIds } = collectIdsByVirtualSensor(virtualSensorId, this.context.realSensorSockets);
		this.context.linked_CalibrationIds = calibrationIds;
		this.context.linked_VirtualSensorIds = [virtualSensorId];
		this.context.linked_RealSensorIds = realSensorSocketIds;
	}
}

const RealSensorRow = class RealSensorRow extends RowInstruction {
	constructor(context) {
		super(context);
		this.context = context;
	}
	get selfList(){
		return this.context.linked_RealSensorIds
	}
	color = '#dfe6ec'
	_onMouseEnter_ = realSensorSocketId => {
		const realSensorDto = this.context.state.realSensorSockets.find(el => el.id == realSensorSocketId);
		this.context.linked_RealSensorIds = [realSensorSocketId];
		this.context.linked_CalibrationIds = collectCalibrationIds(realSensorDto);
		this.context.linked_VirtualSensorIds = collectVirtualSensors(realSensorDto);
	}
}

const CalibrationRow = class CalibrationRow extends RowInstruction {
	constructor(context) {
		super(context);
		this.context = context;
		console.log("%c CalibrationRow constructor context", coCSS, context)
	}
	get selfList(){
		return this.context.linked_CalibrationIds
	}
	color = '#dfe6ec'
	_onMouseEnter_(calibrationId){
		const { realSensorSocketIds, virtualSensorIds } = collectIdsByCalibrationId(this.context.realSensorSockets, calibrationId);
		this.context.linked_CalibrationIds = [calibrationId];
		this.context.linked_VirtualSensorIds = virtualSensorIds;
		this.context.linked_RealSensorIds = realSensorSocketIds;
	}
}

export const HighLightRowInstruction = Base => class HighLighterOfRowsContext extends Base {
	linked_CalibrationIds = [];
	linked_VirtualSensorIds = [];
	linked_RealSensorIds = [];
	get realSensorSockets() {
		return this.state.realSensorSockets
	}
	get virtualSensors() {
		'they not used in current code'
		return this.state.virtualSensors
	}
	get multiSensors() {
		return this.state.multiSensors
	}
	get calibrations() {
		'they not used in current code'
		return null
	}
	VirtualSensorRow = new VirtualSensorRow(this)
	RealSensorRow = new RealSensorRow(this)
	CalibrationRow = new CalibrationRow(this)
}

function collectIdsByVirtualSensor(virtualSensorId, realSensorSockets) {
	const realSensorSocketIds = [];
	const calibrationIds = [];
	if(SL.arrayIsEmpty(realSensorSockets)){
		realSensorSockets.forEach(item => {
			if(item.virtualSensorId === virtualSensorId) {
				calibrationIds.push(...collectCalibrationIds(item));
				realSensorSocketIds.push(item.id)
			}
		});
	}
	return { realSensorSocketIds, calibrationIds }
}

function collectCalibrationIds(realSensorDto) {
	const calibrationIds = [];
	if(realSensorDto && SL.arrayIsEmpty(realSensorDto.sensorCalibrationMappings)){
		for(const mapping of realSensorDto.sensorCalibrationMappings) {
			calibrationIds.push(mapping.sensorCalibrationId);
		}
	}
	
	return calibrationIds;
}

function collectVirtualSensors(realSensorDto) {
	const virtualSensorListIds = [];
	for(const item of realSensorDto.realSensors) {
		virtualSensorListIds.push(item.virtualSensorId)
	}
	return virtualSensorListIds
}

function collectIdsByCalibrationId (realSensorSockets, sensorCalibrationId) {
	const realSensorSocketIds = [];
	const virtualSensorIds = [];
	if (SL.arrayIsEmpty(realSensorSockets)) {
		realSensorSockets.forEach(item => SL.arrayIsEmpty(item.sensorCalibrationMappings) ?
			item.sensorCalibrationMappings.forEach((mapping) => {
				if (mapping.sensorCalibrationId === sensorCalibrationId) {
					realSensorSocketIds.push(item.id);
					virtualSensorIds.push(item.virtualSensorId)
				}
			}) :
			null
		);
	}
	return { realSensorSocketIds, virtualSensorIds };
}

export function FilterButtonInRow(props) {
	const title = props.row_filter_enabled ? "disable filtering mapped elements" : "Enable filter mapped elements ";
	return 	<button 	title = {title}
							onClick = {props.toogleFilteringConnectedItems} 
							className = 'filter_icon_in_row'>
						{filterIcon(props.row_filter_enabled)}
				</button>
}