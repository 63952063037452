import React, { Component } from 'react';
import TooltipWrapper 		from '../component/TooltipWrapper.jsx'
import 	{validateElements}  from 'actions/ActionCreator.jsx'
import l_v                  from "store/languages.jsx"

export default class AddServerForm extends Component {
	constructor(props){
		super(props);
		this.state = this.props.serverForEdit ? this.props.serverForEdit : {comment: ''}
		this.initialState = this.state;
	}
	enterToState = (e) => {
		this.setState({[e.target.name]: e.target.value});
	}
	validateForm() {
		let settingsForValidation = {
					requiredList:[
										{
											selector: 	'name',
											value: 		'serverName',
										},
										{
											selector: 	'name',
											value: 		'serverHost',
											stateName:  'serverHost',
											customValidate: function(value) {
												value = String(value)
												return !!value//value && ValidateIPaddress(value)
											}
										},
										{
											selector: 	'name',
											value: 		'serverPort',
											stateName:  'serverPort',
											customValidate: function(value) {
												value = value?String(value):value
												const isValidPort = value && value.match('^([0-9]{1,5})') && Number(value)<66000;
												return isValidPort
											}
										},
										{
											selector: 	'name',
											value: 		'retranslatorProtocolType',
											stateName:  'retranslatorProtocolType',

										},
										// {
										// 	selector: 	'name',
										// 	value: 		'comment',
										// },
									],
					space: 	this,
					style: 	'box-shadow: inset 0 2px 8px 0 rgba(243, 135, 135, 0.33)',
				}
  		let validation = validateElements.bind(this)
        	validation = Object.assign(validation(), settingsForValidation)
        	console.log("%c validation, validation.result", 'color: green; font-weight: bold;',  validation, validation.result)
        return validation.result
	}
	render() {
		const {
			id,
			serverName,
			serverHost,
			serverPort,
			retranslatorProtocolType,
			comment
		 } = this.state;
		const {serverForEdit} = this.props;
		const idPrefix = id ? id : '';
		return  !serverForEdit ?
				<div className = 'add_server_form_content '>
					<TooltipWrapper name = {l_v.server_name}
									className = 'wrap'>
						<input 	type =			"text"
								className = 	'input'
								name = 			'serverName'
								value = 		{serverName}
								onChange = 		{this.enterToState}
								placeholder = 	{`${l_v.server_name} *`}/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper name = {l_v.host}
									className = 'wrap'>
						<input 	type = 			"text"
								name = 			'serverHost'
								className = 	'input'
								value = 		{serverHost}
								onChange = 		{this.enterToState}
								placeholder = 	'127.0.0.1 *'/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper name = {l_v.server_port}
									className = 'wrap'>
						<input 	type = 			"text"
								name = 			'serverPort'
								className = 	'input'
								value = 		{serverPort}
								onChange = 		{this.enterToState}
								placeholder = 	'9000 *'/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper name = {l_v.relay_protocol}
									className = 'wrap'>
						<RadioButtons 	retranslatorProtocolType = {retranslatorProtocolType}
										idPrefix = {idPrefix}
										enterToState = {this.enterToState}/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper name = {l_v.comment}
									className = 'wrap full_width'>
						<textarea 	name = 			'comment'
									className = 	"form_text_area  "
									type = 			'text'
									placeholder = 	'Сервер Гоши *'
									value = 		{comment}
									onChange = 		{this.enterToState}>
						</textarea>
					</TooltipWrapper>
					<div className = 'clear'></div>
				</div> :
				<div className = 'add_server_form_content '>
					<TooltipWrapper name = {l_v.comment}
									className = 'wrap full_width'>
						<textarea 	name = 			'comment'
									className = 	"form_text_area  "
									type = 			'text'
									placeholder = 	'Сервер Гоши *'
									value = 		{comment}
									onChange = 		{this.enterToState}>
						</textarea>
					</TooltipWrapper>
				</div>
	}
}
function RadioButtons(props) {
	return listRB.map(retranslatorType => <OneButton 	{...retranslatorType}
								                  	{...props}
	                  								key = {retranslatorType.name}/>)
}
function OneButton(props) {
	const {name, text, enterToState, idPrefix, retranslatorProtocolType} = props;
	return <p className="radioP" key = {name} >
				<input 	type = 			"radio"
						defaultChecked = {name == retranslatorProtocolType}
						name = 			{'retranslatorProtocolType'}
						value =			{name}
						onChange = 		{enterToState}
						id = 			{name + idPrefix}/>
				<label htmlFor = { name + idPrefix} >{text}</label>
			</p>
}
const listRB = [
		{
			name: "TELTONIKA",
			text: "TELTONIKA"
		},
		{
			name: "WIALON_RETRANSLATOR",
			text: "WIALON_RETRANSLATOR"
		},
];

function ValidateIPaddress(ipaddress) {
  if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
    return (true)
  }
  alert("You have entered an invalid IP address!")
  return (false)
}

//  Example of VALID IP address
// 115.42.150.37
// 192.168.0.1
// 110.234.52.124
// Example of INVALID IP address
// 210.110 – must have 4 octets
// 255 – must have 4 octets
// y.y.y.y – only digits are allowed
// 255.0.0.y – only digits are allowed
// 666.10.10.20 – octet number must be between [0-255]
// 4444.11.11.11 – octet number must be between [0-255]
// 33.3333.33.3 – octet number must be between [0-255]