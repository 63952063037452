import React                                   from "react"
import { Link }                                from 'react-router-dom'
import { Provider, connect }                   from 'react-redux'
import { bindActionCreators, applyMiddleware } from 'redux'
import ActionCreator, { ifNoKey }              from 'actions/ActionCreator.jsx'
import Users                                   from 'actions/users.js'
import { editImg, deleteImg, closeImg }        from '../img.jsx'
import { FormChangePass, FormChangeDesc }      from './OneClient.jsx'
import l_v                                     from "store/languages.jsx"
import { checkPass, removeSpaces } 				  from 'actions/ActionCreator.jsx'
import Pop_up 											  from "modules/component/Pop_up.jsx"
import {FormDataBlocking} 							  from "modules/ClientPage.jsx"

class OneAdmin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login: 					this.props.login,
			capabilities: 				this.transfromToKeyValue(this.props.capabilities),
			password: 					'',
			message: 					'',
			password_confirm: 			'',
			password_error: 			'',
			visibilityform: 			'',
			visibilityform_belongTo: 	'',
			visibilityform_desc: 		'',
			blocked: 					this.props.blocked,
			belongsTo: 					this.props.belongsTo,
			user_description: 			this.props.description,
			targetstyle: 				'',
			data_for_filter: 			''
		}
	}
	componentWillReceiveProps(nextProps) {
		if(this.state.login == nextProps.user_login) {
			this.setState({
				capabilities:     this.transfromToKeyValue(nextProps.user_capabilities),
				blocked:          nextProps.user_blocked,
				belongsTo:        nextProps.user_group,
				user_description: nextProps.user_description,
			})
		}
		if(nextProps.filter_group == this.state.belongsTo) {
			if(this.state.targetstyle == '') {
				this.setState({
					targetstyle: '#7faef5'
				})
			}
		} else {
			this.setState({
				targetstyle: ''
			})
		}
	}
	componentDidMount() {
		this.mounted = true;
		let premissions =   document.querySelector('.premissions').clientWidth;
		let table =         document.querySelector('.administratorList').clientWidth;
		let cell_login =    document.querySelector('.cell_login').clientWidth;
		let column_height = document.querySelector('.table_content .oneLine').clientHeight;
		this.props.returnwidth({
			premissions:   premissions,
			table:         table,
			cell_login:    cell_login,
			column_height: column_height
		})
		/*if(this.props.login == 'admin') {
			this.EditAdminPopup.open();
		}*/
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	transfromToKeyValue(data) {
		let newFormat = {};
		if(Array.isArray(data)) {
			data.map(key => {
				newFormat[key.name] = key.value
			})
		}
		return newFormat
	}
	updateUser = (event) => {
		let value = event.target.value;
		const login = event.target.name
		let capname = event.target.attributes.getNamedItem('data-capname').value;
		(value === 'true') ? (value = false) : (value = true);
		if(capname == 'blocked') {
			Users.editValueOfUser({
				login: event.target.name,
				newValue: {
					blocked: value
				}
			})
		} else {
			Users.updateCapabilityNewV(
				[{
					"name": capname,
					"value": value
				}],
				login,
				(newCapability)=>this.setState({capabilities: this.transfromToKeyValue(newCapability)})
			)
		}
	}
	setBlocking = (e) => {
		console.log(`%c setBlocking-`, "color: green; font-weight: 400; text-transform:uppercase", e)
	}
	deleteuser = () => {
		Users.deleteUser({
			userlogin: this.state.login,
			kindUser: 'ADMIN',
		});
	}
	showform = () => {
		let visibility = (this.state.visibilityform == '') ? ('show_form') : ('');
		this.setState({
			visibilityform: visibility,
		})
	}
	showformGroup = () => {
		let visibility = (this.state.visibilityform_belongTo == '') ? ('show_form') : ('');
		this.setState({
			visibilityform_belongTo: visibility,
		})
	}
	showFormChangeDesc = () => {
		let visibility = (this.state.visibilityform_desc == '') ? ('show_form') : ('');
		this.setState({
			visibilityform_desc: visibility,
		})
	}
	updateGroup = (data) => {
		Users.belongsTo({
			"login": this.state.login,
			belongsTo: {
				"belongsTo": data
			}
		})
		this.showformGroup();
	}
	updateDesc = (new_description) => {
		Users.editValueOfUser({
			"login": this.state.login,
			"newValue": {
				"description": new_description
			}
		});
		if(this.mounted) this.EditAdminPopup.close();
	}
	sendpassword = (new_password) => {
		Users.editPassword({
			"login": this.state.login,
			"password": {
				"previousPassword": null,
				"newPassword": new_password
			}
		});
		if(this.mounted) this.EditAdminPopup.close();
	}
	filterbyGroup = (e) => {
		let targetstyle;
		let target_data = e.target.getAttribute('data-value');
		let data_for_filter;
		(this.state.targetstyle == '') ? (data_for_filter = target_data) : (data_for_filter = '');
		ActionCreator.setgroupfilter(data_for_filter);
		this.props.filterbyGroup(data_for_filter);
	}
	premission = (username, capname, name, data) =>{
		let text;
		let checked;
		if (data == true){
					text=l_v.yes;
					checked = true;
		}
		else {
			text = l_v.no;
			checked = false;
		}
		return (
				<p key = {name}>
					<input  type = 			"checkbox"
							id =			{name}
							name =			{username}
							data-capname = 	{capname}
							className = 	'select_checkbox'
							value = 		{data}
							defaultChecked = {checked}
							onChange = 		{this.updateUser}/>
					<label 	htmlFor = 	{name}
							className = 'select_label'>
							{text}
					</label>
				</p>
			)
	}
	render(){
		const { capabilities, login, blocked, user_description } = this.state;
		const blockedStyle = blocked ? {backgroundColor: 'rgb(253, 161, 161)'}:{};
		return(
			<div className = "oneLine" key = {login} style = {{top: this.props.scrollpos, ...blockedStyle}}>
				<Pop_up 	head_text =     {`${login} - ${l_v.edit}`}
							head_back =     {{backgroundColor:"#708090", color: 'white', fill: 'white'}}
							ref = 			 {(popup) => this.EditAdminPopup = popup}
							popUp_size =    {{maxWidth: '99%', width: '500px', height: '500px'}}>
							<EditAdministrator 	delete = 			{
																					() => this.props.delete({
																						userlogin: this.state.login,
																						kindUser: 'ADMIN',
																					})
																				}
														setBlocking = 		{ this.setBlocking }
														updatePassword = 	{ this.sendpassword }
														blocked = 			{ blocked }
														description = 		{ user_description }
														updateDescription = 		{ this.updateDesc } />
	        </Pop_up>
				{/*<FormChangePass visibilityform = 	{this.state.visibilityform}
								password =  		{this.sendpassword}
								hideform = 			{this.showform}/>*/}
				{/*<FormChangeDesc visibilityform = 	{this.state.visibilityform_desc}
								desc = 				{this.updateDesc}
								hideform = 			{this.showFormChangeDesc}/>*/}
				<FormChangeGroup 	visibilityform =	{this.state.visibilityform_belongTo}
									group = 			{this.updateGroup}
									hideform = 			{this.showformGroup}/>
				<div className = "cell_login cell tooltip" >
					{login}
					{/*<Link to={"/info/"+this.props.kindUser +'/' + login + "/logs"} className="link_client">
						{login}
					</Link>*/}
					
				</div>
				<div className = 'cell_desc cell'>
						{this.state.user_description}
						{/*{editImg("edit", this.showFormChangeDesc)}*/}
				</div>
				<div 	className = "cell_byWhom cell"
					 	style = 		{{display: this.props.visible_belongsTo}}>
						<div 	className =		'target_group'
								data-value = 	{this.state.belongsTo}
								style = 		{{borderColor: this.state.targetstyle}}
								onClick = 		{()=>null }>{/*this.filterbyGroup*/}
								<span 	className =		"group_name"
										data-value = 	{this.state.belongsTo}>
										{this.state.belongsTo}
								</span>
						</div>
						{editImg("edit edit_belongsTo", this.showformGroup)}
				</div>
				<div 	className = 'premissions'
						style = 	{{width: this.props.premissionsWidth}}>
						<div 	className = "cell_editClients cell "
								style = 	{{display: this.props.visible_editClients, width: this.props.editClients_width}}>

								{this.premission(login, 'editClients', login +'editClients', capabilities.editClients)}

						</div>
						<div
							className = "cell_editOther cell "
							style = {{display: this.props.visible_editOther, width: this.props.editOther_width}}>
							{this.premission(login, 'editOther',login + 'editOther', capabilities.editOther)}
						</div>
						<div
							className = "cell_editDevice cell"
							style = {{display: this.props.visible_editDevices, width: this.props.editDevices_width}}>
							{this.premission(login, 'editDevices', login + 'editDevices', capabilities.editDevices)}
						</div>
						<div
							className = "cell_editSensor cell"
							style = {{display: this.props.visible_editSensor, width: this.props.editSensor_width}}>
							{this.premission(login, 'editSensors', login + 'editSensors', capabilities.editSensors)}
						</div>
						<div
							className = 'cell_editVehicles cell '
							style = {{display: this.props.visible_editVehicles, width: this.props.editVehicles_width}}
							ref='editVehicles_width'>
							{this.premission(login, 'editVehicles', login + 'editVehicles', capabilities.editVehicles)}
						</div>
						<div
							className = "cell_deleteSensor cell"
							style = {{display: this.props.visible_deleteSensorTypes, width: this.props.deleteSensorTypes_width}}>
							{this.premission(login, 'deleteSensorTypes', login + 'deleteSensor', capabilities.deleteSensorTypes)}
						</div>
						<div
							className = "cell_seeStatistic cell"
							style = {{display: this.props.visible_seeStats, width: this.props.seeStats_width}}>
							{this.premission(login, 'seeStats', login + 'seeStats', capabilities.seeStats)}
						</div>
						<div
							className = "cell_recalculateDataSensor cell "
							style = {{display: this.props.visible_recalcSensors, width: this.props.recalcSensors_width}}>
							{this.premission(login, 'recalcSensors', login + 'recalcSensors', capabilities.recalcSensors)}
						</div>
						<div
							className = "cell_createAdmin cell"
							style = {{display: this.props.visible_createAdminUsers, width: this.props.createAdminUsers_width}}>
							{this.premission(login, 'createAdminUsers', login + 'createAdminUsers', capabilities.createAdminUsers)}
						</div>
						<div
							className = "cell_setUpBlocking cell"
							style = {{display: this.props.visible_setBlocking, width: this.props.setBlocking_width}}>
							{this.premission(login, 'setBlocking',  login + 'setBlocking', capabilities.setBlocking)}
						</div>
						{/*<div
							className = 'cell_blocking cell'
							style = {{display: this.props.visible_setBlocking, width: this.props.blocking_width}}>
							{this.premission(login, 'blocked',  login + 'blocked', this.state.blocked)}
						</div>*/}
						<div
							className = 'cell_is_trusted  cell'
							style = {{display: this.props.visible_isTrusted, width: this.props.blocking_width}}>
							{this.premission(login, 'isTrusted',  login + 'isTrusted', capabilities.isTrusted)}
						</div>
				</div>
				<div className = "cell_edit cell">
					{ editImg("edit", () => this.EditAdminPopup.open()) }
				</div>
				{/*<div className = "cell_delete cell">
					{
						deleteImg("delete", () => this.props.delete({
							userlogin: this.state.login,
							kindUser: 'ADMIN',
						}))
					}
				</div>*/}
				</div>
			)
	}
}

class EditAdministrator extends React.Component{
	constructor(props){
		super(props);
		const {description} = this.props;
		this.state = {
			password: 			'',
			password_confirm: '',
			description: 		description ? description : '',
			message: 			''
		}
		this.initialState = this.state;
	}
	enterToState = (e) =>{
		let { value, name }	= e.target;
		if(name == 'password' || name == 'password_confirm'){
			value = removeSpaces(value)
		}
		this.setState({
			[name]: value
		})
		if(e.target.name == "password"){
			if(!checkPass(value)){
				this.setState({
					message: language_variant.newPassMustBe,
					new_pass_error: 'formError',
				})
			}else{
				this.setState({
					message: '',
					new_pass_error: '',
				})
			}
		}
	}
	sendpassword = () => {
		const { password, password_confirm } = this.state;
		if(!password || !password_confirm) {
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		} else {
			if(password !== password_confirm) {
				this.setState({
					message: l_v.pass_not_match,
					password_error: 'formError'
				})
			} else {
				if(!checkPass(password)) {
					this.setState({
						message: l_v.newPassMustBe,
						password_error: 'formError'
					})
				} else {
					this.setState({
						message: '',
						password_error: ''
					})
					this.props.updatePassword(password);
					this.setState(this.initialState);
				}
			}
		}
	}
	sendDescription = () => {
		const { description } = this.state;
		this.props.updateDescription(description);
		this.setState(this.initialState);

	}
	render(){
		const { password, password_confirm, description, message, password_error } = this.state;
		return  	<div className = "edit_administrator">
						<div className = 'btns_edit_administrator'>
							<button 	className = 'btn_black'
										type = 		'button'
										name = 		'send'
										onClick = 	{this.props.delete}>
								{l_v.remove}
							</button>
							<button 	className = 'btn_black'
										type = 		'button'
										name = 		'send'
										onClick = 	{this.props.setBlocking}>
								{this.props.blocked ? l_v.unlock : l_v.block_now}
							</button>
						</div>
						<p>{message}</p>
						<form className = 'form_change_admin_pass'>
							<input 	className =   {`input ${password_error}`}
										type =        'password'
										name =        'password'
										value =       {password}
										onChange =    {this.enterToState}
										placeholder = {`${l_v.enter_password} *`}/>
							<input 	className =   {`input ${password_error}`}
										type =        'password'
										value =       {password_confirm}
										name =        'password_confirm'
										onChange =    {this.enterToState}
										placeholder = {`${l_v.confirm_password} *`}/>
							<button 	className = 'btn_black'
										type = 		'button'
										name = 		'send'
										onClick = 	{this.sendpassword}>
									{l_v.change_password}
							</button>
						</form>
						<form className = 'form_change_admin_desc'>
							<input 	className = 	{'input'}
										type = 			'text'
										value = 			{description}
										name = 			'description'
										onChange = 		{this.enterToState}
										placeholder = 	{`${l_v.enter_user_desc} *`}/>
							<button 	className = 'btn_black'
										type = 		'button'
										name = 		'send'
										onClick = 	{this.sendDescription}>
								{l_v.change_description}
							</button>
						</form>
						
					</div>
	}
}

class FormChangeGroup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visibilityform: this.props.visibilityform,
			error_emty_field: '',
			message: '',
			belongsTo:'',
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			visibilityform: nextProps.visibilityform
		})
	}
	sendData = () => {
		if (!this.state.belongsTo){
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		}
		else{
			this.setState({
					message: '',
					error_emty_field: ''
				});
			this.props.group(this.state.belongsTo);
		}
	}
	hide = () => {
		this.props.hideform()
	}
	editData = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	render(){
		return (
				<div className={"form_to_belongsToChange " + this.state.visibilityform}>
					<p className = 'form_message '>{this.state.message}</p>
					<form >
						<svg xmlns="http://www.w3.org/2000/svg"
						 		aria-labelledby="title"
						 		className = 'close'
						 		viewBox="0 0 16 16"
						 		onClick = {this.hide}>
						 		<title id="title">{l_v.close}</title>
								<polygon points="12.9,4.4 11.5,3 8,6.5 4.4,3 3,4.4 6.5,8 3,11.5 4.4,12.9 8,9.4 11.5,12.9 12.9,11.5 9.4,8 "/>
						</svg>
						<input 	className =   {'input ' + this.state.error_emty_field}
									type =        'text'
									name =        'belongsTo'
									onChange =    {this.editData}
									placeholder = {`${l_v.enter_group_name} *`} />
						<button 	className = 'btn_black'
									type =      'button'
									name =      'send'
									onClick =   {this.sendData} >
							{l_v.edit_group}
						</button>
					</form>
				</div>
		)
	}
}


export default connect(
	state => ({
		user_capabilities: state.clientsR.user_capabilities,
		user_login:        state.clientsR.user_login,
		user_blocked:      state.clientsR.user_blocked,
		user_description:  state.clientsR.user_description,
		user_group:        state.clientsR.user_group,
		filter_group:      state.clientsR.filter_data
	}),
	dispatch => bindActionCreators(ActionCreator, dispatch)
)(OneAdmin)