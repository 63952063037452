import React, {useState, Fragment}		from 'react'
import {connect} 						from 'react-redux'
import { Link } 						from 'react-router-dom'
import { goToClientAppImg, qrIcon }     from 'modules/img.jsx'
import authInClient                     from 'modules/component/authInClient.js'
import language_variant 		from 'store/languages.jsx'

function LinkToVehicleOwner(props) {
	const {owner, ClientData, kindCreator, vehicleId, gasStation, vehicleInfo} = props;
	const showOwnerForThisUser  = kindCreator == 'ADMIN' || kindCreator == 'SUPERADMIN' ||  kindCreator == 'DEALER';
	const accountType = gasStation?'TERES':'GLOBUS';
	return 	showOwnerForThisUser?
			<Fragment>
				<div className = 'clear'></div>
				<div className = 'vehicle_owner' style = {{display: 'inline-flex', alignItems: 'center'}}>
					<Link 	to =		{"/client/"+owner +"/edit"}
							style = {{display: 'inline-block', marginRight: '10px', marginBottom: '0px'}}
			       			className = "name_client">
			       			{language_variant.account} - {!!owner?owner:language_variant.account_not_specified}
			       	</Link>
			       	{
			       		!gasStation ?
	       		       	<button onClick = {()=>authInClient(owner, accountType, vehicleInfo)}
	       		       			style = {{display: 'inline-block', float: 'none',}}
	       						className = 'goToClient'>
	       						{goToClientAppImg('goToClientIcon', accountType)}
	       				</button> :
	       				null

			       	}

				</div>
			</Fragment> :
			null
}
export default connect(
	    state => ({
	    	login: 				state.authorizationR.login,
			token: 				state.authorizationR.token,
			kindCreator: 		state.authorizationR.kindUser,
			user_permission: 	state.authorizationR.user_permission,
			ClientData:     	state.clientsR.ClientData,

	    })
)(LinkToVehicleOwner)