import React                        from "react"
import ActionCreator, { checkPass } from 'actions/ActionCreator.jsx'
import { connect }                  from 'react-redux'
import { Link }                     from 'react-router-dom'
import moment                       from 'moment';
import Users                        from 'actions/users.js'
import SeparPermission              from 'modules/component/SeparPermission.jsx'
import l_v                          from "store/languages.jsx"
import authInClient                 from 'modules/component/authInClient.js'
import {
	deleteImg,
	closeImg,
	editImg,
	goToClientAppImg,
	qrIcon
}                                   from '../img.jsx'
import Preloader                    from 'modules/preloader.jsx'


export const QrCodeContext = {};

class OneClient extends React.Component {
	constructor(props) {
		super(props);
		// magic with blocking
		const {
			login, description, belongsTo, expires, blocked
		} = this.props;
		this.state = {
			login: 				login,
			user_description: 	description,
			belongsTo: 			belongsTo,
			expire: 			expires,
			blocked: 			magickofblocking(expires, blocked).blocked,
			visibility_form_name: '',
			visibilityform: 	'',
			color: 				magickofblocking(expires, blocked).color,
			expires: 			magickofblocking(expires, blocked).expires,
		}
	}
	componentDidMount() {
		const table =  		document.querySelector('.clientlist').clientWidth;
		const head_line = 	document.querySelector('.head_line').clientWidth;
		const column_height = document.querySelector('.table_content .oneLine').clientHeight ;
		this.mounted = true;
		this.props.returnwidth({
									table:table,
									head_line:head_line,
									column_height: column_height
								})
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	componentWillReceiveProps(nextProps) {
		const {
			user_group, user_description, user_expires, user_blocked, user_login, filter_group
		} = nextProps;
		const {
			belongsTo, login, targetstyle
		} = this.state;
		if(login == user_login) {
			this.setState({
				belongsTo: 			user_group,
				user_description: 	user_description,
				blocked: 			magickofblocking(user_expires, user_blocked).blocked,
				expires: 			magickofblocking(user_expires, user_blocked).expires,
				color: 				magickofblocking(user_expires, user_blocked).color,
			})
		}
		if(filter_group == belongsTo && targetstyle == '') {
			this.setState({
				targetstyle: '#7faef5'
			})
		}else {
			this.setState({
				targetstyle: ''
			})
		}
	}
	updateUser(event){
		let value = event.target.value;
		let capname = event.target.attributes.getNamedItem('data-capname').value;
		this.preloader.show();
		(value === 'true') ? (value = false):(value = true);
		Users.updateCapability({
					login:      event.target.name,
					marker:     'clientPage',
					adddata:    '/info',
					capability: { expires: null }

		}, () => {
			if(this.mounted) this.preloader.hide();
		})
		Users.updateCapability({
			"login":      event.target.name,
			'adddata':    '/info',
			"capability": { [capname]: value }

		}, () => {
			if(this.mounted) this.preloader.hide();
		})
	}
	updateBlocked = (e) => {
		const value = e.target.checked;
		this.preloader.show();
		Users.updateCapability({
					login:      this.state.login,
					marker:     'clientPage',
					adddata:    '/block',
					capability: { blocked: value }

		}, () => {
			if(this.mounted) this.preloader.hide();
		})
	}
	showFormChangeDesc = () =>{
		let visibility = (this.state.visibility_form_name == '') ? ('show_form'): ('');
		this.setState({
			visibility_form_name: visibility,
		})
	}
	showform = () =>{ //password FormChangePass
		let visibility = (this.state.visibilityform == '') ? ('show_form'): ('');
		this.setState({
			visibilityform: visibility,
		})
	}
	updateName = (data) =>{
		Users.editValueOfUser({
					"login":       this.state.login,
					"newValue": { "name": data }
				});
		this.showFormChangeDesc();
	}
	sendpassword(data){
		Users.editPassword({
			"login": this.state.login,
			"password": {
				"newPassword": data,
				"previousPassword": null,
			}
		});
		this.showform();
	}
	render(){
		const { accountType, login, profileDto } = this.props;
		// check permission to show correct state of checkbox
		return (
			<div className = "oneLine" key = {login} style = {{background: this.state.color}}>
					<FormChangePass visibilityform = 	{this.state.visibilityform}
									password = 			{this.sendpassword.bind(this)}
									hideform = 			{this.showform.bind(this)}/>
					<FormChangeDesc visibilityform = 	{this.state.visibility_form_name}
									desc = 		{this.updateName}
									hideform = 	{this.showFormChangeName}/>
					<div className = "cell_name cell">
						< Link 	to = { `/client/${login}/edit`}
								className = "link_client"> {login} </Link>
						<button onClick = { () => authInClient(login, accountType) }
								className = 'goToClient'>{ goToClientAppImg('goToClientIcon', accountType) }</button>
						<button onClick = { () => QrCodeContext.showQR(login) }
								className = 'showQR' >{qrIcon('qr_icon')}</button>
					</div>
					<div className = "cell_desc cell">
						{this.props.name}
						{/*{editImg('edit', this.showFormChangeName)}*/}
					</div>
					<div className = "cell_admin cell">
						{this.state.belongsTo}
					</div>
					<div className = "cell_expire cell">
						{this.state.expires}
					</div>

					<div className = "cell_blocked cell">
						<SeparPermission 	alowed = 'ADMIN, SUPERADMIN, DEALER'
											kindCapability = 'setBlocking'>
							<BlockedCheckbox   	updateBlocked = {this.updateBlocked}
												blocked = 		{this.state.blocked}
												login = 		{login}/>
						</SeparPermission>
					</div>
					<div className = "cell_edit cell">
						<Link to={"/client/" + login +  "/edit"}>
							{ editImg("edit") }
						</Link>
					</div>
					<div className = "cell_delete cell">
						{
							deleteImg("delete", () => this.props.deleteuser({
								userlogin: login,
								kindUser: 'CLIENT',
							}))
						}
					</div>
					<Preloader ref = {preloader => this.preloader = preloader} />

				</div>
		)
	}
}
function BlockedCheckbox(props) {
	const {blocked, updateBlocked, login, id} = props;

	return 	<p key = {name}>
				<input 	type =           "checkbox"
						id =             { login }
						name =           {  blocked + login}
						className =      'select_checkbox'
						checked = 		 {blocked}
						onChange =       {updateBlocked }/>
				<label 	htmlFor = 	{name}
						className = 'select_label'>
						{ blocked ? l_v.yes : l_v.no }
				</label>
			</p>
}

export class FormChangeDesc extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visibilityform:   this.props.visibilityform,
			error_emty_field: '',
			message:          '',
			description:      '',
			color:            '',
		}
		this.senddata = this.senddata.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			visibilityform: nextProps.visibilityform
		})

	}
	senddata(){
		if (!this.state.description){
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		}
		else{
			this.setState({
					message: '',
					error_emty_field: ''
				});
			this.props.desc(this.state.description);
		}
	}
	hide = () => {
		this.props.hideform()
	}
	editdata(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}

	render(){
		return (
				<div className={"FormChangeDesc " + this.state.visibilityform}>
					<p className = 'form_message '>{this.state.message}</p>
					<form >
						<svg 	xmlns="http://www.w3.org/2000/svg"
						 		aria-labelledby="title"
						 		className = 'close'
						 		viewBox="0 0 16 16"
						 		onClick = {this.hide}>
					 		<title id="title">{l_v.close}</title>
							<polygon points="12.9,4.4 11.5,3 8,6.5 4.4,3 3,4.4 6.5,8 3,11.5 4.4,12.9 8,9.4 11.5,12.9 12.9,11.5 9.4,8 "/>
						</svg>
						<textarea 	className = {'form_text_area ' + this.state.error_emty_field}
									type = 'text'
									name = 'description'
									onChange = {this.editdata.bind(this)}
									placeholder = {`${l_v.enter_user_desc} *`}>
						</textarea>
						<button 	className = 'btn_black'
									type = 'button'
									name = 'send'
									onClick = {this.senddata.bind(this)}>
							{l_v.change_name}
						</button>
					</form>
				</div>
		)
	}
}


export class FormChangePass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visibilityform: this.props.visibilityform,
			password: '',
			password_confirm: '',
			error_emty_field: '',
			message: ''
		}
		this.sendpassword = this.sendpassword.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			visibilityform: nextProps.visibilityform
		})
	}
	sendpassword(){
		if (!this.state.password || !this.state.password_confirm){
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		}
		else{
			if (this.state.password !== this.state.password_confirm){
				this.setState({
					message: l_v.pass_not_match,
					password_error: 'formError'
				})
			}
			else{
				if(checkPass(this.state.password)){
					this.setState({
						message: '',
						password_error: ''
					});
					this.props.password(this.state.password);
				}else{
					this.setState({
						message: l_v.newPassMustBe,
						password_error: 'formError'
					});
				}
			}
		}
	}
	hide = () => this.props.hideform()
	editpass(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	render(){
		const { visibilityform } = this.state;
		return (
				<div className = { `form_to_passwordChange ${visibilityform}` }>
					<p className = 'form_message'>{this.state.message}</p>
					<form >
						<svg 	xmlns = "http://www.w3.org/2000/svg"
						 		aria-labelledby = "title"
						 		className = 'close'
						 		viewBox = "0 0 16 16"
						 		onClick = {this.hide}>
					 		<title id="title">{l_v.notifications}</title>
							<polygon points="12.9,4.4 11.5,3 8,6.5 4.4,3 3,4.4 6.5,8 3,11.5 4.4,12.9 8,9.4 11.5,12.9 12.9,11.5 9.4,8 "/>
						</svg>
						<input 	className = {'input ' + this.state.password_error}
								type = 'password'
								name = 'password'
								onChange = {this.editpass.bind(this)}
								placeholder = {`${l_v.enter_password} *`}/>
						<input 	className = {'input ' + this.state.password_error}
								type = 'password'
								name = 'password_confirm'
								onChange = {this.editpass.bind(this)}
								placeholder = {`${l_v.confirm_password} *`}/>
						<button 	className = 'btn_black'
									type = 'button'
									name = 'send'
									onClick = {this.sendpassword.bind(this)}>
								{l_v.change_password}
						</button>
					</form>
				</div>
		)
	}
}
export default connect(
      state => ({
		// user_capabilities: state.clientsR.user_capabilities,
        user_login: 		state.clientsR.user_login,
        user_blocked: 		state.clientsR.user_blocked,
        user_description: 	state.clientsR.user_description,
        user_group: 		state.clientsR.user_group,
        user_expires: 		state.clientsR.user_expires,
        filter_group: 		state.clientsR.filter_data
      })
)(OneClient)

function magickofblocking(dataexp, blocked){//sarcasm
	let color;
	let expires = '';
 	if (!!dataexp){
		// dataexp = dataexp * 1000; //because Roma return in seconds
		 // console.log(dataexp, new Date(dataexp))
		expires = moment(dataexp).format('Do MMMM YYYY  HH:mm');
		color = '#f3f3c4';
		if(moment().isAfter(moment(dataexp)) ){
			expires = moment(dataexp).format('Do MMMM YYYY  HH:mm');
			color = '#fda1a1';
			blocked  = true;
		}

	}
	if (!!blocked){
			color = '#fda1a1';
	}
	return {
				color: color,
				blocked: blocked,
				expires: expires,
			}
}