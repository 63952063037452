import React, { Component } from 'react';
import TooltipWrapper 		from '../component/TooltipWrapper.jsx'
import 	{validateElements, replaceAllIsNotNumber}    from 'actions/ActionCreator.jsx'
import l_v               from "store/languages.jsx"

export default class AddDeviceForm extends Component {
	constructor(props){
		super(props);
		this.state = this.props.deviceForEdit ?
					 this.props.deviceForEdit :
					 {
					 	deviceId:   '',
					 	deviceImei: '',
					 	blocked: 	false
					 };
		this.initialState = this.state;
	}
	enterToState = (e) => {
		let {value, name} = e.target;
		if(name == 'deviceId' || name == 'deviceImei'){
			value = replaceAllIsNotNumber(value)
		}
		this.setState({[e.target.name]: e.target.value});
	}
	enterToCheckbox = (e) => {
		this.setState({[e.target.name]: e.target.checked});
	}
	getFormData = () => {
		this.validateForm() ? false : this.state;
	}
	validateForm() {
		let settingsForValidation = {
					requiredList:[
										{
											selector: 	'name',
											value: 		'deviceId',
											customValidate: function(value) {
												return !!new Number(value).valueOf()
											}
										},
										{
											selector: 	'name',
											value: 		'deviceImei',
											customValidate: function(value) {
												return !!new Number(value).valueOf()
											}
										}
									],
					space: 	this,
					style: 	'box-shadow: inset 0 2px 8px 0 rgba(243, 135, 135, 0.33)',
				}
  		let validation = validateElements.bind(this)
        	validation = Object.assign(validation(), settingsForValidation)
        	console.log("%c validation, validation.result", 'color: green; font-weight: bold;',  validation, validation.result)
        return validation.result
	}
	render() {
		const {
			deviceId,
			deviceImei,
			blocked
		 } = this.state;
		 const {deviceForEdit} = this.props;
		return  !deviceForEdit ?
				<div className = 'add_device_form_content '>
					<TooltipWrapper name = {l_v.device_id}
									className = 'wrap'>
						<input 	type =			"text"
								className = 	'input'
								name = 			'deviceId'
								value = 		{deviceId}
								onChange = 		{this.enterToState}
								placeholder = 	{`${l_v.device_id} *`}/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper name = {l_v.IMEI_device}
									className = 'wrap'>
						<input 	type = 			"text"
								name = 			'deviceImei'
								className = 	'input'
								value = 		{deviceImei}
								onChange = 		{this.enterToState}
								placeholder = 	'80008000 *'/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper name = {l_v.blocking}
									className = 'wrap checkBoxWrapper'>
						<input 	type = 			"checkbox"
								id = 			'blocked_checkbox'
								name = 			'blocked'
								className = 	'input'
								checked = 		{blocked}
								onChange = 		{this.enterToCheckbox}/>
						<label htmlFor = 'blocked_checkbox'>{l_v.blocked}</label>
					</TooltipWrapper>
					<div className = 'clear'></div>
				</div> :
				<div className = 'add_device_form_content '>
					<TooltipWrapper name = {l_v.blocking}
									className = 'wrap checkBoxWrapper'>
						<input 	type = 			"checkbox"
								id = 			'blocked_checkbox'
								name = 			'blocked'
								className = 	'input'
								checked = 		{blocked}
								onChange = 		{this.enterToCheckbox}/>
						<label htmlFor = 'blocked_checkbox'>{l_v.blocked}</label>
					</TooltipWrapper>
				</div>
	}
}

/*{
    "deviceId": 71359,
    "deviceImei": "80008000",
    "blocked": false
}*/