import React from 'react'
import {deleteImg, editImg, closeImg} 	from '../img.jsx'
import moment 						from 'moment'
export default function OneNotifInfo(props) {
	let {text, id, date, removeNotif, updateNotif} = props;
	return 	<div className = 'one_update_notif'>
				<p className = 'update_notif_date'>{moment(date).format('YYYY-MM-DD HH:mm')}</p>
				<p className = 'update_notif_text'>{text}</p>
				<button className =	"edit_btn"
						onClick =	{()=>updateNotif({
							text: text,
							id: id,
							date: date
						})}>
						{editImg()}
              	</button>
	 			<button className =	'delete_btn'
						onClick =	{()=>removeNotif(id)}>
						{deleteImg()}
				</button>
			</div>
}