import React, { Component } from 'react';
import UsersApi             from "actions/users.js"
import Navigation           from './AdminNavigation.jsx'
import SeparPermission      from './component/SeparPermission.jsx'
import Helmet               from 'react-helmet';
import { deleteImg }        from './img.jsx'
import {removeElementFromList} from 'actions/ActionCreator.jsx'
import l_v                     from "store/languages.jsx"

export default class IgnoredUsersPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            ignoredUsers:     null,
        }
        this.initialState = this.state;
    }
    componentDidMount() {
        UsersApi.getIgnoredUsers(ignoredUsers => this.setState({ignoredUsers: ignoredUsers}) )
    }
    removeDeviceFromList = (id) => {
        const onRequestComplete = ()=>{
            const {ignoredUsers} = this.state;
            this.setState({
                ignoredUsers: removeElementFromList(ignoredUsers, id, 'id')
            });
        }
        UsersApi.removeFromIgnoredUsers(id, onRequestComplete)
    }
    render() {
        const {ignoredUsers} = this.state;
        return (
            <div>
                <Helmet title = { l_v.ignored_devices_list} />
                <SeparPermission    alowed = 'SUPERADMIN'
                                    type =              'page'/>
                <Navigation />
                <div className='container content'>
                    <h1 className="page_name">{l_v.ignored_devices_list}</h1>
                    <div className = 'devicelist i'>
                        <div className = 'table_content'>
                            <HeadLine />
                            {
                                ignoredUsers ?
                                ignoredUsers.map(key => <OneUser    key = {key.id}
                                                                    removeDeviceFromList = {this.removeDeviceFromList}
                                                                    {...key}/>) :
                                null
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

function HeadLine(props) {
    return  <div className = "oneLine head_line">
                <div className = "cell_id cell">
                    {l_v.id_in_list}
                </div>
                <div className = "cell_deviceSerial cell">
                    {l_v.login}
                </div>
                <div className = "cell_date cell">
                    {l_v.date_added}
                </div>
                <div className = "cell_delete cell">

                </div>
            </div>
}

function OneUser(props) {
    const {login, createdAt, id, removeDeviceFromList} = props;
    return(
            <div className = 'oneLine'>
                <div className = "cell_id cell">
                    {id}
                </div>

                <div className = "cell_deviceSerial cell">
                    {login}
                </div>
                <div className = "cell_createdAt cell">
                    {moment(createdAt).format('DD.MM.YYYY - hh:mm')}
                </div>
                <div className = "cell_delete cell" style = {{
                                                                marginRight: '0px',
                                                                marginLeft: 'auto'
                                                            }}>
                    {
                        deleteImg("delete", ()=>removeDeviceFromList(id))
                    }
                </div>
            </div>
          )
}