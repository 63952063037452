import React 								from 'react'
import language_variant 					from '../../store/languages.jsx'
import {iconV} 								from 'images/simple_svg_icons.jsx'
export default function OneTransport(props) {
	let {licensePlate, name, group, id, active, addThis, removeThis} = props;
	function click(){
		!!active?removeThis(id):addThis(id)
	}
	return <li 	onClick = 	{click}
				className = {active?'activeStyle':null}>
				<b>{licensePlate}</b> {name}
				{iconV()}
			</li>
}