import React, { Component, Fragment } from 'react';

class EnterListennerForForm extends Component {
	listennerKeyPress = (e) => {
		if(e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) this.props.onPressEnter()
	}
	componentDidMount() {
		window.addEventListener('keypress', this.listennerKeyPress);
	}
	componentWillUnmount() {
		window.removeEventListener('keypress', this.listennerKeyPress);
	}
	render() {
		return 	this.props.children
	}
}

export class EscapeListennerForForm extends Component {
	listennerKeyPress = (e) => {
		if(e.key === 'Escape' && !e.shiftKey && !e.ctrlKey) this.props.onPressEscape()
	}
	componentDidMount() {
		window.addEventListener('keydown', this.listennerKeyPress);
	}
	componentWillUnmount() {
		window.removeEventListener('keydown', this.listennerKeyPress);
	}
	render() {
		return 	this.props.children
	}
}

export default EnterListennerForForm;