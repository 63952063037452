import React, { Component, Fragment }                                                          from 'react';
import SL 																												  from "sl-library"
import { deleteImg, editImg, closeImg }                                                        from '../img.jsx'
import TooltipWrapper                                                                          from '../component/TooltipWrapper.jsx'
import { arrayIsEmpty, replaceAllIsNotNumber, leaveNumberWithDot, leaveNegativeNumberWithDot } from '../../actions/ActionCreator.jsx'
import SimpleSelectList                                                                        from '../component/SimpleSelectList.jsx'
import CreatePortal                                                                            from "modules/component/CreatePortal.jsx"
import SwitchInput                                                                             from '../component/SwitchInput.jsx'
import CopyCalibration                                                                         from '../component/CopyCalibration.jsx'
import l_v                                                                                     from "store/languages.jsx"
import Pop_up                                                                                  from "modules/component/Pop_up.jsx"
import EnterListennerForForm                                                    					  from "modules/component/EnterListennerForForm.jsx"


const sensorKindNotSelected = { name: l_v.unknown, value: l_v.unknown, id: l_v.unknown };
const getSensorKindForSelect  = (sensorKind) =>  ({ name: sensorKind, value: sensorKind, id: sensorKind });


export default class CalibrationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active:           false,
			calibrationTable: '',
			max_value:        2147483647,
			min_value:        0,
			name:             '',
			scale:            1,
			offset:           0,
			message:          '',
			sensorKind:       { name: l_v.unknown, value: l_v.unknown, id: l_v.unknown },
			reflection:       false,
			positioner:       false,
			reaper:           false,
		}
		this.initialState = this.state;
		this.hintForCalibraion = l_v.table_of_values;
		this.formUseCopiedInfo =  false;
	}
	componentWillReceiveProps(nextProps) {
		let {
			calibrationTable,
			positionerCalibrationTable,
			logicalCalibrationTable,
			max_value,
			min_value,
			name,
			scale,
			offset,
			sensorKind, reflection, isArchiveCalibrations, id
		} = nextProps;
		/*const defCalibrationTable = calibrationTable ? transformCalibrationForView(calibrationTable, reflection) : '';
		const positionerCalibration = positionerCalibrationTable ? transformPositionerCalibrationForView(positionerCalibrationTable, reflection) : '';
		const reaperCalibrationTable = logicalCalibrationTable ? transformReaperCalibrationForView(logicalCalibrationTable) : '';*/
		
		
		this.setState({
			...getFormStateFromServerData(nextProps),
			max_value:  max_value ? max_value : 2147483647,
			min_value:  min_value ? min_value : 0,
			name:       name ? name : '',
			scale:      scale ? scale : 1,
			offset:     offset ? offset : 0,
			sensorKind: sensorKind ? getSensorKindForSelect(sensorKind) : sensorKindNotSelected,
			message:    ''
		});
	}
	activation = () => {
		this.setState({ active: true });
	}
	enterToState = (e) => {
		let value = e.target.value;
		let name = e.target.name;
		if(name == 'calibrationTable'){
			value = replaceComma(value)
		}
		if(name == 'scale'){// || name == 'offset'
			value = leaveNumberWithDot(value)
		}
		if(name == 'offset'){
			value = leaveNegativeNumberWithDot(value)
		}
		if(name == 'max_value'){
			if(Number(value) > 2147483647 ){
				value = 2147483647
			}
			value = leaveNumberWithDot(value)
		}
		if(name == 'min_value'){
			if(Number(value) < 0 ){
				value  = 0
			}
			value = leaveNumberWithDot(value)
		}
		this.setState({
			[name]: value
		});
	}
	saveKindSensor = (e) => {
		this.setState({sensorKind: e});
	}
	sendData = (e) => {
		if(e) e.preventDefault()
		let {
			calibrationTable,
			max_value,
			min_value,
			name,
			scale,
			offset,
			sensorKind,
			reflection,
			positioner,
			reaper
		} = this.state;
		const {itsAdd} = this.props;
		let nameOfTransform;
		let calibrationTableForSend;
		if(positioner) {
			nameOfTransform = 'positioner'
		}
		if(reaper) {
			nameOfTransform = 'reaper'
		}
		switch (nameOfTransform) {
			case 'positioner':
				calibrationTableForSend = { positionerCalibrationTable: transformCalibrationPositioner(calibrationTable, reflection) }
				break;
			case 'reaper':
				calibrationTableForSend = { logicalCalibrationTable: transformCalibration(calibrationTable, false, true) }
				break;
			default:
				calibrationTableForSend = { calibrationTable: transformCalibration(calibrationTable, reflection) }
				break;
		}
		for(let el in calibrationTableForSend) {
			if(!calibrationTableForSend[el]) {
				this.setState({ message: 'Невалидная калибровка' });
			} else {
				let dataForSend = {
					...calibrationTableForSend,
					max_value:  max_value,
					min_value:  min_value,
					name:       name,
					scale:      scale,
					offset:     offset,
					sensorKind: sensorKind.value,
					vehicle_id: this.props.vehicleId
				};
				if(!!this.props.id) {
					dataForSend.id = this.props.id
				}
				if(!name) { //|| (!this.exclusionsRules(offset, scale) && !calibrationTable)
					this.setState({
						message: 'Заполните всё поля'
					});
				} else {
					if(this.formUseCopiedInfo || itsAdd){
						this.props.createCalibration(dataForSend)
					}else{
						this.props.sendCalibrationInfo(dataForSend)
					}
					this.setState(this.initialState, () => this.formUseCopiedInfo =  false);
				}
			}
		}
	}
	setCalibrationType = e => {
		const { name, value } = e;
		// const {positioner, reaper} = this.state;
		if (name == 'positioner') {
			if (value) {
				this.setState({
					positioner: value,
					reaper: false
				});
			} else {
				this.setState({
					positioner: value
				})
			}
		}
		if (name == 'reaper') {
			if (value) {
				this.setState({
					positioner: false,
					reaper:     value,
					reflection: false
				});
			} else {
				this.setState({
					reaper: value
				})
			}
		}
	}
	setSavedData = (copiedInfo) => {
		this.formUseCopiedInfo = true;

		this.setState({
			...copiedInfo,
			...getFormStateFromServerData(copiedInfo),
			active: true
		})
	}
	render() {
		const { min_value, max_value, name, scale, offset, calibrationTable, active, sensorKind, reflection,
				positioner, reaper	} = this.state;
		const { isArchiveCalibrations, controlEntityId, deviceId, deletedAt, id } = this.props;
		const nameFromProps =               this.props.name;
		const nameOnUpdate =                nameFromProps ? `${l_v.update_calibration}: ${nameFromProps} - id: ${id}` : l_v.adding_calibration;
		const archiveInfoAboutCalibration = `ID: ${id},  DEVICE-ID: ${deviceId}, УДАЛЕНА: ${moment(deletedAt).format('DD.MM.YYYY - HH:mm:ss')} `;
		const nameForm =                    isArchiveCalibrations ? archiveInfoAboutCalibration : nameOnUpdate;
		return	active ?//maybe need refactoring
					<Pop_up  openAlways =  { true }
								head_text =   { nameForm }
								onClose =     { () => this.setState({ active: false }) }
								head_back =   { { backgroundColor: "#708090", color: 'white', fill: 'white', } }
								ref =         {  popup => this.qrPopup = popup }
								popUp_size =  { { maxWidth: '99%', width: '650px', height: '650px', } } >
								<EnterListennerForForm onPressEnter = { this.sendData }>
									<div className = 'sensor_types update_sensor' >
										<p className = 'form_message '>{this.state.message}</p>
										<div className="input_block">
											<TooltipWrapper name = {l_v.name_and_sensor}
															className = 'wrap'>
												<input 	name =  		'name'
														className = 	"input "
														type = 			'text'
														placeholder = 	{l_v.name_and_sensor}
														defaultValue = 	{name}
														onChange = 		{this.enterToState} />
											</TooltipWrapper>
											<TooltipWrapper name = {l_v.coefficient}
															className = 'wrap'>
												<input 	name = 			'scale'
														className = 	"input "
														type = 			'text'
														placeholder = 	{l_v.enter_coefficient}
														value = 			{scale}
														onChange = 		{this.enterToState}/>
											</TooltipWrapper>
											<TooltipWrapper name = {l_v.error}
															className = 'wrap'>
												<input 	name = 			'offset'
														className = 	"input "
														type = 			'text'
														/*style = 		{{cursor: !!calibrationTable?'not-allowed':'pointer'}}*/
														placeholder = 	{l_v.enter_offset}
														value = 		{offset}
														/*readOnly = 		{!!calibrationTable}*/
														onChange = 		{this.enterToState} />
											</TooltipWrapper>
											<TooltipWrapper name = {l_v.minimum_value}
															className = 'wrap'>
												<input 	name = 			'min_value'
														className = 	"input "
														type = 			'text'
														min = 			{0}
														value = 		{min_value}
														placeholder = 	{l_v.enter_minimum_value}
														onChange = 	{this.enterToState} />
											</TooltipWrapper>
											<TooltipWrapper name = {l_v.maximum_value}
															className = 'wrap'>
												<input 	name = 			'max_value'
														className = 	"input "
														type = 			'text'
														placeholder = 	{l_v.enter_maximum_value}
														max = 			{2147483647}
														value = 		{max_value}
														onChange = 	{this.enterToState} />
											</TooltipWrapper>
											<TooltipWrapper name = {l_v.model_fuel_sensor}
															className = 'wrap'>
												<p className = 'name_select_list'>{l_v.model_dut}</p>
												<SimpleSelectList 	list = {[
																				{name: l_v.unknown, value: l_v.unknown, id: l_v.unknown },
																				{name:'F1', value: "F1", id: "F1" },
																				{name:'F2', value: "F2", id: "F2" },
																				{name:'F3', value: "F3", id: "F3" },
																				{name: l_v.arm, value: "Стрела", id: "Стрела" },
																				{name:'Игла', value: "Игла", id: "Игла" }
																			]}
																			listHeight = {216}
																			class_name  = 'select_list'
																			selected_item = {sensorKind}
																			default_value = {sensorKind}
																			returnValue   = {this.saveKindSensor} />

											</TooltipWrapper>
										</div>
										<div className='textarea_container'>
											<label 	htmlFor = "liter"
														className = 'label_textarea'>
													<LableText 	reflection = {reflection}
																reaper = 	 {reaper}
																positioner = {positioner}/>
											</label>
											<TooltipWrapper name = {
																		!!this.exclusionsRules(offset, scale) ?
																		l_v.offset_factor_or_table :
																		this.hintForCalibraion
																	}
															className = 'wrap'>
												<textarea 	name = 			'calibrationTable'
															className = 	"form_text_area  "
															type = 			'number'
															placeholder = 	{l_v.enter_the_volume_min_}
															id =			'liter'
															value = 		{calibrationTable}
															/*style = 		{{cursor: this.exclusionsRules(offset, scale)?'not-allowed':"pointer"}}
															readOnly = 		{this.exclusionsRules(offset, scale)}*/
															onChange = 		{this.enterToState}>
												</textarea>

											</TooltipWrapper>
											<div className='counter_switch'>
												<p className='text_info'>{l_v.invert_values}:</p>
												<SwitchInput 	result = {(value)=>this.setState({reflection: value})}
																disabled = { !!reaper }
																isChecked = {reflection}
																name='counter_change_oil'
																tooltip={{on: l_v.inverted, off: l_v.not_inverted}}/>
											</div>
											<div className='counter_switch'>
												<p className='text_info'>{l_v.positioner}:</p>
												<SwitchInput 	result = { value => this.setCalibrationType({name: 'positioner', value: value}) }
																isChecked = {positioner}
																name = 'positioner'
																tooltip={{on: l_v.yes, off: l_v.no}}/>
											</div>
											<div className='counter_switch'>
												<p className='text_info'>{l_v.reaper}:</p>
												<SwitchInput 	result = { value => this.setCalibrationType({name: 'reaper', value: value}) }
																isChecked = {reaper}
																name = 'reaper'
																tooltip={{on: l_v.yes, off: l_v.no}}/>
											</div>
										</div>
										<div className = 'footer_add_calibration'>
											
											<button 	className = 'btn_black'
														type = 		'button'
														name = 		'send'
														onClick = {()=>this.setState(this.initialState)}>
														{!isArchiveCalibrations ? l_v.cancel :  l_v.close}
											</button>
											{
												!isArchiveCalibrations ?
												<button className = 'btn_black'
														type = 		'button'
														name = 		'send'
														onClick = 	{this.sendData}>
														{
															this.props.name ? l_v.update : l_v.save_calibrations
														}
												</button> :
												null
											}
										</div>
									</div>
								</EnterListennerForForm>
					<CopyCalibration setSavedData = {this.setSavedData}/>
				</Pop_up> :
				<CopyCalibration setSavedData = {this.setSavedData}/>
	}
	exclusionsRules(){
		let {offset, scale} = this.state;
		let statusScale = (scale < 1 && !!scale) || (!!scale && Number(scale) !== 1);

		let disabled = offset||statusScale;
		// return disabled
		return false
	}
}

function getFormStateFromServerData(recievedData) {
	//in archive calibration can be string, default calibration is Array values and can be in different keys object
	const {
		calibrationTable,
		positionerCalibrationTable,
		logicalCalibrationTable,
		max_value,
		min_value,
		maxValue,
		minValue,
		name,
		scale,
		offset,
		sensorKind,
		reflection,
		isArchiveCalibrations,
		id
	} = recievedData;

	const defCalibrationTable = calibrationTable ? transformCalibrationForView(calibrationTable, reflection) : '';
	const positionerCalibration = positionerCalibrationTable ? transformPositionerCalibrationForView(positionerCalibrationTable, reflection) : '';
	const reaperCalibrationTable = logicalCalibrationTable ? transformReaperCalibrationForView(logicalCalibrationTable) : '';
	const archiveCalibration = typeof calibrationTable == 'string' ? calibrationTable : '';

	return {
		calibrationTable: defCalibrationTable || positionerCalibration || reaperCalibrationTable || archiveCalibration,
		positioner: !!positionerCalibration,
		reaper: !!reaperCalibrationTable,
		max_value: SL.isNumeral(max_value) ? max_value : maxValue,
		min_value: SL.isNumeral(min_value) ? min_value : minValue,
		sensorKind: sensorKind ? getSensorKindForSelect(sensorKind) : sensorKindNotSelected,
		name,
		scale,
		offset,
		reflection,
		id
	}

}


function LableText(props){
	let {reflection, positioner, reaper} = props;
	let textValue = l_v.frequency, textResult = l_v.literage;
	if(positioner){
		textValue = l_v.orig_value;
		textResult = l_v.result_value;
	}
	if(reaper){
		textValue = l_v.min_val;
		textResult = l_v.max_val;
	}
	return 	reflection?
		 	<Fragment> {textResult} <span> {textValue}</span> {positioner? <span> Маска</span> : null}</Fragment>:
		 	<Fragment>{positioner? <span style = {{marginLeft: 0, marginRight: '20px'}}>Маска    </span> : null} {textValue} <span> {textResult}</span></Fragment>
}

function transformReaperCalibrationForView(calibrationTable) {
	if(arrayIsEmpty(calibrationTable)) {
		let stringView = '';
		stringView = calibrationTable.map((key, index) => key.max_value + '\t' + key.max_value + '\n' )
		stringView = stringView.join('')
		return stringView
	}else {
		return ''
	}
}

function transformCalibrationForView(calibrationTable, reflection) {
		if(arrayIsEmpty(calibrationTable)) {
			let stringView = '';
			stringView = calibrationTable.map((key, index) => {
				if(reflection){
					return key.result_value + '\t' + key.orig_value + '\n';
				}else{
					return key.orig_value + '\t' + key.result_value + '\n';
				}
			})
			stringView = stringView.join('')
			return stringView
		}else {
			return ''
		}
}

function transformPositionerCalibrationForView(calibrationTable, reflection) {
	if(arrayIsEmpty(calibrationTable)) {
		let stringView = '';
		stringView = calibrationTable.map((key, index) => {
			if(reflection){
				return key.result_value + '\t' + key.orig_value + '\t' +  key.mask + '\n';
			}
			else{
				return key.mask + '\t' + key.orig_value + '\t' + key.result_value + '\n';
			}
		})
		stringView = stringView.join('')
		return stringView
	}else {
		return ''
	}
}

function transformCalibration(calibrationString, reflection, reaper) { //convert to api format(
	// ((61 . 0) (441 . 50) (765 . 100) (1061 . 150) (1363 . 200) (1667 . 250) (1955 . 300) (2257 . 350) (2558 . 400) (2861 . 450) (3169 . 500) (3482 . 550) (3821 . 600))	 `-/**/
	//for check 	// ((6 1 . 0) (44`1 . 50) (765 . 100) (10v61 . 150) (1363 . 200) (1667 . 250) (1955 . 30	0) (2257 . 350) (2558 . 400) (2861 . 450) (3169 . 500) (3482 . 550) (3821 . ))	 `-/**/

	let parsedArray = [];
	if(!!calibrationString) {
		if(calibrationString.search(/\)/) !== -1) {
			let temp = calibrationString.replaceAll(/\(/g, '\n');
			temp = temp.replaceAll(/\)/g, '');
			temp = temp.replaceAll(/\s\.\s/g, ' ')
			parsedArray = getParsedArray(temp)
		} else {
			parsedArray = getParsedArray(calibrationString)
		}
		if(!arrayIsEmpty(parsedArray)){
			alert('С калибровкой, что-то не так"' + calibrationString + '"')
			return null
		}
	}
	function getParsedArray(calibrationString) {
		const arrayLines = removeAccidentalSpaces(calibrationString).split(/\n/);
		// console.log("%c arrayLines", coCSS, arrayLines)
		for(let el in arrayLines){
			if(arrayLines[el].length) {
				const arrValues = arrayLines[el].split(/\t{1,}|\s{1,}/g); //[\t{1,}|\s{1,}]
				if(!checkArrayValue(arrValues, 2)){
					alert('где то тут не верный формат калибровки: ' + arrayLines[el])
					return null
				}
				parsedArray.push(
					reflection ?
					{ orig_value: Number(arrValues[1]), result_value: Number(arrValues[0]) } :
					{ orig_value: Number(arrValues[0]), result_value: Number(arrValues[1]) }
				)
			}
		}
		return parsedArray
	}
	function transfromToReaperFormat(el) {
		return {min_value: el.orig_value, max_value: el.result_value }
	}
	return reaper ?  Array.from(parsedArray, transfromToReaperFormat) : parsedArray
}

function transformCalibrationPositioner(calibrationString, reflection) {
	if(calibrationString){
		const arrayLines = removeAccidentalSpaces(calibrationString).split(/\n/);
		const parsedArray = [];
		for(let el in arrayLines){
			if(arrayLines[el].length){
				const arrValues = arrayLines[el].split(/\t{1,}|\s{1,}/g);//[\t{1,}|\s{1,}]
				if(!checkArrayValue(arrValues, 3)){
					alert('где то тут не верный формат калибровки: "' + arrayLines[el] + '"')
					return null
				}
				parsedArray.push(
			        reflection ?
					{mask: Number(arrValues[2]), orig_value: Number(arrValues[1]), result_value: Number(arrValues[0])} :
					{mask: Number(arrValues[0]), orig_value: Number(arrValues[1]), result_value: Number(arrValues[2])}
				)
			}
		}
		if(!arrayIsEmpty(parsedArray)){
			alert('С калибровкой, что-то не так"' + calibrationString + '"')
			return null
		}
		// console.log("%c parsedArray", coCSS, parsedArray)
		return parsedArray
	}else return []
}

function replaceComma(value) {
	return value.replace(/,/g, '\.')
}



function checkArrayValue(arr, lengthMustBe) {
	if(arrayIsEmpty(arr) && arr.length == lengthMustBe){
		for(let i in arr){
			if(i<= lengthMustBe){//ignore space after
				if(arr[i] == null || !arr[i] || valueContainWrongNumber(arr[i]) || isNaN(Number(arr[i]))){
					return false
				}
			}

		}
		return true
	}else{
		return false
	}
}

function valueContainWrongNumber(value) {
	let valueWithOutSpaces = value.replace(/\t{1,}|\s{1,}/g, '')
	return valueWithOutSpaces.search(/[^0-9\.-]/g) !== -1
}

function removeAccidentalSpaces(string) {
	return removeSpaceIfItIsInTheEnd(removeEmtyLines(string))
}


function removeEmtyLines(string) {
	const arrLines = string.split('\n');
	const cleanArrLines = arrLines.filter(oneLine => {
		const regExp = new RegExp('\\d', 'g');
		return regExp.test(oneLine)
	});
	// let temp = string.replaceAll(/(?<=\n)\t*(?=\n)|(?<=\n)\s*(?=\n)/g, 'temp')//not supported safari
	// return temp.replace(/temp\n/g, '');
	return cleanArrLines.join('\n')
}

function removeSpaceIfItIsInTheEnd(string) {
	const arrLines = string.split('\n');
	const cleanArrLines = Array.from(arrLines, oneLine => {
		for(let i = oneLine.length-1; i>=0; i--){
			const regExp = new RegExp('[\\t\\s]', 'g');
			const lastSymbol = oneLine.charAt(i)
			if(regExp.test(lastSymbol)){
				oneLine = oneLine.slice(0, i);
			}else{
				break;
			}
		}
		return oneLine
	})
	console.log("%c cleanArrLines", coCSS, cleanArrLines)
	return cleanArrLines.join('\n')
	//return string.replace(/\t*(?!.)|\s*(?!.)/g, '')//not supported safari
}