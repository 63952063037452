import React, {Fragment, Component}            from "react"
import { bindActionCreators } from 'redux'
import { connect }                   from 'react-redux'
import Helmet                                  from 'react-helmet';
import SL                       			   from "sl-library"
import Select, { defaultTheme, mergeStyles }   from 'react-select';
import l_a                        			   from "store/languages.jsx"
import store                               	   from 'store/configureStore.jsx'
import formAction                              from 'actions/formAction.js'
import infoStore 							   from "actions/infoStore.js"
import Preloader                      		   from 'modules/preloader.jsx'
import 'css/form.scss'

class FormAuthView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			password:      '',
			answerForUser: '',
			savedata:      '',
			login:         '',
			password:      '',
		}
	}
	componentDidMount() {
		this.mounted = true;
		window.addEventListener('keydown', this.enter);
	}
	componentWillUnmount() {
		this.mounted = false;
		window.removeEventListener('keydown', this.enter)
	}
	crutchForCromePassManager = e => {
		//had tried remove psevdo input from Chrome Password Manager and updating label position on auto fill
		//for label find css decision, psevdo input not available from DOM
		const setDataFromPassManager = (element) => {
			console.log("%c CRUTCHFORCROMEPASSMANAGER e, element", coCSS, e, element)
			console.log("%c element.name, element.value", coCSS, element.name, element.value)
			element.select();
			element.focus();
			const rehKey = Object.keys(element).filter(function(item) {
				return item.indexOf('__reactEventHandlers') >= 0
			});
			console.log("%c CRUTCHFORCROMEPASSMANAGER rehKey", coCSS, rehKey)
			console.log("%c CRUTCHFORCROMEPASSMANAGER this.element[rehKey]", coCSS, element[rehKey[0].value])
			const value = element[rehKey[0]].value;
			element.value = '';
			this.setState({
				[element.name]: value
			});
			element.blur()
		}
		setTimeout(() => {
			if(this.inputPassword.matches(':-internal-autofill-selected')) {
				this.inputPassword.style = 'border: 1px solid red; background:transparent!important;'
				// this.inputPassword.backgroundColor = 'transparent';
			}
		}, 500);
		setDataFromPassManager(this.inputPassword);
		setDataFromPassManager(this.inputLogin);
	}
	enter = (e) => {
		if(e.key === "Enter") this.submit()
	}
	enterToState = (e) => {
		this.setState({ [e.target.name]: SL.removeSpaces(e.target.value) })
	}
	onError = (answerText) => {
		this.setState({ answerForUser: answerText });
		if(this.mounted) this.preloader.hide();
	}
	submit = (e) => {
		if(e) e.preventDefault();
		const { login, password, answerForUser } = this.state;
		const sendRequest = () => formAction.newAuthorization(login, password, this.onError);
		this.preloader.show();

		if(answerForUser){//clear old message
			this.setState({answerForUser: ''}, sendRequest);
		}else{
			sendRequest()
		}
	}
	render() {
		const {login, savedata, password, answerForUser}  = this.state;
		const title = `${DEV_SERVER ? '☢' : ''} Admin Panel`;
		return <div id = 'form_container'>
					<Helmet title =  {title}/>
			        <div className = "login-box"   >
			          	<h4>{answerForUser}</h4>
				        <form >
				            <div className="user-box">
				              <input 	type =         "text"
				              			ref =          { el => this.inputLogin = el }
				              			name =         "login"
				              			value =        { login }
				              			onChange =     { this.enterToState }
				              			autoComplete = "true"
				              			valid =        "true"
				              			/>
				              <label>{l_a.login_placeholder}</label>
				            </div>
				            <div className="user-box">
				              <input 	type =         "password"
				              			ref =          { el => this.inputPassword = el }
				              			name =         'password'
				              			value =        { password }
				              			onChange =     { this.enterToState }
				              			autoComplete = "true"
				              			valid =        "true"
				              			/>
				              <label>{l_a.password_placeholder}</label>
				            </div>
				            < button onClick = { this.submit } >
				            <span></span>
								<span></span>
								<span></span>
								<span></span>
								{l_a.button_send}
				            </ button>
				        </form>
				        <LanguagesController styles = { styles }/>
				        <Preloader ref = { preloader => this.preloader = preloader} />
			        </div>

				</div>
	}
};

export default connect(
	state => ({
		login:              state.authorizationR.login,
		password:           state.authorizationR.password,
		token:           	state.authorizationR.token,
		answerForUser:      state.authorizationR.answerForUser,
		user_permission:    state.authorizationR.user_permission,
		kindUser: 			state.authorizationR.kindUser,
		language:           state.languageR.language, //not used but call form rendering on update value
	}),
)(FormAuthView);

const boxShadow = '0 0 5px #03e9f4, 0 0 25px #03e9f4, 0 0 50px #03e9f4, 0 0 100px #03e9f4';
const boxColors = {
	background: '#141e30',
	borderColor: '#141e30',
};
const colors = {
	color: '#03e9f4',
	borderColor: '#141e30',
	'&:hover': {
		color: '#03e9f4',
		borderColor: '#141e30',
	},
	'&:active': {
		color: '#03e9f4',
		borderColor: '#141e30',
	},
	'&:visited': {
		color: '#03e9f4',
		borderColor: '#141e30',
	},
	'&:focus': {
		color: '#03e9f4',
		borderColor: '#141e30',
	},
};

const styles = {
	container: baseStyles => {
		const currColor = SL.copyOfObject(colors);
		currColor['&:hover'].boxShadow = boxShadow;
		currColor.transition = 'all 0.4s ease-in-out';
		const desctopStyle = {
			bottom: -140, //for mobile devices
		};
		const mobileStyle = {
			top: -80, //for mobile de
		};
		const st = window.innerHeight > 700 ? desctopStyle : mobileStyle;
		return {
			...baseStyles,
			width: 100,
			boxShadow: '0 15px 25px rgb(0 0 0 / 60%)',
			left: 0,
			right: 0,
			margin: 'auto',
			position: 'absolute',
			borderRadius: 10,
			...currColor,
			...boxColors,
			...st
		}
	},
	singleValue: baseStyles => ({
		...baseStyles,
		...colors
	}),
	control: baseStyles => ({
		...baseStyles,
		width: 100,
		boxShadow: 'none',
		...boxColors,
		...colors,
	}),
	option: baseStyles => ({
		...baseStyles,
		...boxColors,
		'&:hover': {
			background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(3,233,244,0.65) 52%, rgba(3,233,244,0.65) 55%, rgba(255,255,255,0) 100%)',
			color: boxColors.background
		}
	}),
	dropdownIndicator: baseStyles => ({ ...baseStyles, ...colors }),
	menu: baseStyles => {
		return { ...baseStyles, ...boxColors, ...colors }
	},
	input: baseStyles => ({
		...baseStyles,
		...colors,
	})
};

function SelectLanguage(props) {
	const { language, languageUpdate, langList, styles } = props;
	const defaultValue = langList.find( el => el.value == language );
	console.log("%c defaultValue", coCSS, defaultValue)
	const selectProps = {
		defaultValue: 		[defaultValue],
		className: 			"react-select-container",
		classNamePrefix:	"react-select",
		onChange: 			languageUpdate ,
		name:            	"colors",
		options:         	langList ,
		className:       	"basic-multi-select",
		classNamePrefix: 	"select",
	}
	if(styles) selectProps.styles = styles;
	return 	<Select  {...selectProps} />
};

export const LanguagesController = connect(
	state => ({
		language: state.languageR.language,
		langList: state.languageR.langList,
	}),
	dispatch => bindActionCreators({
		languageUpdate(e) {
			return {
				type: 'LANGUAGE_UPDATE',
				language: e.value,
			}
		},
	}, dispatch)
)(SelectLanguage);
