import bugReport 			from './bugReport.jsx'
import axios 				from 'axios'
import infoStore 			from './infoStore.js'
import SL 	 				from './ActionCreator.jsx'
import store 				from 'store/configureStore.jsx'
import devicesApi                         from "./devicesApi.js"
import { ifNoKey, arrayIsEmpty, doCopy } from './ActionCreator.jsx'

const gasStationApiFromTERES = {
	getFuellingStatistic:               getFuellingStatistic,
	getGasStationById:                  getGasStationById,
	setGasStationListToStore:           setGasStationListToStore,
	getGasStationListByClient:          getGasStationListByClient,
	updatePeriodsGasStation:            updatePeriodsGasStation,
	updateGasStationSettings:           updateGasStationSettings,
	updateGasStationFuelLevel:          updateGasStationFuelLevel,
	updateTankSize:                     updateTankSize,
	getGasStationWithCoordinatesCRUTCH: getGasStationWithCoordinatesCRUTCH,
	getDataForCharts:                   getDataForCharts,
	getDataForCalibrations:             getDataForCalibrations,
	getRegistationCardsOnGasStation:    getRegistationCardsOnGasStation,
	clearCacheForMonth:  				clearCacheForMonth,
	clearAllCache: 						clearAllCache,
	clearCacheForPeriod: 				clearCacheForPeriod,

}
const gasStationApi = {
	getGasStations: getGasStations,
	getGasStationById: getGasStationById,
	deleteGasStation: deleteGasStation,
	updateTransmission: updateTransmission,
	addGasStation: addGasStation,
	updateGasStation: updateGasStation,
	...gasStationApiFromTERES
}

export default gasStationApi;

function clearCacheForPeriod(gasStationId, periodInfo, callback) {
	const requestData = {
		method: 'DELETE',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'teres/clean-dates-cache/' + gasStationId,
		dataType: "json", //формат
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: periodInfo
	}
	axios(requestData)
		.then(answer => {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: 'Кеш очищен'
			})
			callback(answer.data)
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "clearCacheForMonth"))
}

function clearCacheForMonth(gasStationId, callback) {
	const requestData = {
			method: 'GET',
			baseURL: infoStore().base_url, //this was in the start
			url: infoStore().bonus_url_api + 'teres/clean-month-cache/' + gasStationId,
			dataType: "json", //формат
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
		}
		axios(requestData)
		.then(answer => {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: 'Кеш очищен'
			})
			callback(answer.data)
		})
		.catch(serveranswer => bugReport(serveranswer, requestData, "clearCacheForMonth"))
}

function clearAllCache(gasStationId, callback) {
	const requestData = {
				method: 'GET',
				baseURL: infoStore().base_url, //this was in the start
				url: infoStore().bonus_url_api + 'teres/clean-all-cache/' + gasStationId,
				dataType: "json", //формат
				withCredentials: true,
				headers: { 'authorization': infoStore().token },
		}
		axios(requestData)
		.then(answer => callback(answer.data) )
		.catch(serveranswer => bugReport(serveranswer, requestData, "clearAllCache"))
}

function getRegistationCardsOnGasStation(params, callback) {
	const requestData = {
			method: 'GET',
			baseURL: infoStore().base_url, //this was in the start
			url: infoStore().bonus_url_api + 'rfid-register',
			dataType: "json", //формат
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
			params: params,
	}
	axios(requestData)
	.then(answer => callback(answer.data) )
	.catch(serveranswer => bugReport(serveranswer, requestData, "getRegistationCardsOnGasStation"))
}

function getDataForCalibrations(gasStationId, params, callback) {
	const lang = infoStore().language;

	params.timeZone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
	params.locale = lang?lang.toUpperCase():'UA'
	const requestData = {
			method: 'GET',
			baseURL: infoStore().base_url, //this was in the start
			url: infoStore().bonus_url_api + 'teres/reverse-calibration/' + gasStationId,
			dataType: "json", //формат
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
			params: params,
	}
	axios(requestData)
		.then(answer => callback(answer.data) )
		.catch(serveranswer => bugReport(serveranswer, requestData, "getDataForCalibrations"))
}

function getDataForCharts(requestInfo, callBack) {
	const {gasStationId, dateFrom, dateTo} = requestInfo;
	const lang = infoStore().language;
	const requestData = {
		method: 'GET',
		baseURL: infoStore().base_url, //this was in the start
		url: infoStore().bonus_url_api + 'track',
		dataType: "json", //формат
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: {
			vehicleId: gasStationId,
			from: dateFrom,
			to: dateTo,
			locale: infoStore().langUppercase,
		}
	}
	axios(requestData)
		.then((answer) => callBack(answer.data) )
		.catch(serveranswer => bugReport(serveranswer, requestData, "getTrack"))
}

function getGasStationWithCoordinatesCRUTCH(callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "vehicle/state",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	};
	axios(requestData).then(answer => callback(filterGasStation(answer.data))).catch(answer => bugReport(answer, requestData, 'getGasStationWithCoordinatesCRUTCH'));
}

function filterGasStation(vehicleList) {
	let gasStationList = [];
	vehicleList.forEach(el =>{
		if(el.type == 'GAS_STATION'){
			gasStationList.push(el)
		}
	})
	return gasStationList
}

function updateTankSize(tankSiseInfo, id, callback) {//also this function used for update obtainFuelConsumptionByDut
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "gas-station/"+ id + "/volume",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: tankSiseInfo,

	};
	axios(requestData).then(answer => callback(answer.data)).catch(answer => bugReport(answer, requestData, 'updateTankSize'));
}

function updateGasStationFuelLevel(fuelLeveleInfo, callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "gas-station/fueling",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: fuelLeveleInfo,
		params: {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}
	};
	axios(requestData).then(answer => callback(answer.data))
	.catch(answer => {
		callback();
		bugReport(answer, requestData, 'updateGasStationFuelLevel')
	});
}

function getFuellingStatistic(gasStationId, callback) {
	const lang = infoStore().language;
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "teres/" + gasStationId,
		method: 'GET',
	  	timeout: 60000*30,
		headers: { 'authorization': infoStore().token },
		params: {
			locale: infoStore().langUppercase,
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
		}
	};

	axios(requestData).then(answer => callback(answer.data)).catch(answer => bugReport(answer, requestData, 'getFuellingStatistic'));
}

function getGasStationListByClient(callback) {
	let requestData = {
		method: 'GET',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'gas-stations',
		dataType: "JSON",
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: {owner: infoStore().login}
	}
	axios(requestData).then(answer => {
		setGasStationListToStore(answer.data);
		if(callback){
			callback(answer.data)
		}
	}).catch(serveranswer => bugReport(serveranswer, requestData, "getGasStationListByClient"))
}

function updatePeriodsGasStation(gasStationId, newPeriods, callback) {
	console.log("%c newPeriods", coCSS, newPeriods)
	let requestData = {
		method: 'POST',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'gas-station/' + gasStationId + '/period',
		dataType: "JSON",
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: newPeriods
	}
	axios(requestData).then(answer => {
		if(callback){
			callback(answer.data)
		}
	}).catch(serveranswer => bugReport(serveranswer, requestData, "updatePeriodsGasStation"))
}

function updateGasStationSettings(gasStationId, newData, callback) {
	let requestData = {
		method: 'PUT',
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'gas-station/' + gasStationId + '/settings' ,
		dataType: "JSON",
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: newData
	}
	axios(requestData).then(answer => {
		if(callback){
			callback(answer.data)
		}
	}).catch(serveranswer => bugReport(serveranswer, requestData, "updateGasStationSettings"))
}

function setGasStationListToStore(gasStationList) {
	store.dispatch({
		type: 'UPDATE_GAS_STATION_LIST',
		setList: gasStationList
	})
}



function getGasStations(params, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'gas-stations/page',
		dataType: "json",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		params: params
	})
	.then(answer=>callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store));
}

function addGasStation(data, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'gas-station',
		dataType: "json",
		method: 'PUT',
		headers: { 'authorization': infoStore().token },
		data: data
	})
	.then(answer=>callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store));
}

function updateGasStation(gasStationId, newData, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'gas-station/' + gasStationId,
			dataType: "json",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			data: newData,
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'GET_one_transport',
				setThisTransport: answer.data
			})
			if(!!data.data.name && !!callback) {
				callback(data.data.name)
			}
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: 'Информация обновлена'
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function updateTransmission(gasStationId, newPeriods, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'gas-station/' + gasStationId + '/transmission',
		dataType: "json",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: newPeriods,
	})
	.then(function answer(answer) {
		store.dispatch({
			type: 'GET_one_transport',
			setThisTransport: answer.data
		})
	})
	.catch(function(serveranswer) {
		bugReport(serveranswer, store)
	});
}

function getGasStationById(gasStationId, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore('authorizationR.bonus_url_api') + 'gas-station/' + gasStationId,
		dataType: "json",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		withCredentials: true,
	})
	.then(function answer(answer) {
		if(callback) callback(answer.data);
		const currentDeviceMapping = answer.data.currentDeviceMapping;
		devicesApi.setDeviceSettingsDTO(ifNoKey(currentDeviceMapping, 'deviceSettingsDto'))
	})
	.catch(function(serveranswer) {
		bugReport(serveranswer, store)
	})
}

function deleteGasStation(gasStationId, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'admin/gas-station/' + gasStationId,
		dataType: "json",
		method: 'DELETE',
		headers: { 'authorization': infoStore().token },
	})
	.then(answer=>callback(gasStationId))
	.catch(serveranswer => bugReport(serveranswer, store));
}