import React 			from 'react'
import { Link } 		from 'react-router-dom'
import transportsApi 	from 'actions/transportsApi.js'
import TimeInput 		from "dd-date-time-input"
import moment 			from 'moment'
import l_v 				from "store/languages.jsx"

class ReRequestData extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			date: moment().startOf('day'),//.format("YYYY-MM-DDTHH:mm")
			answer: l_v.re_request_data,
		}
		this.initialState = this.state;

	}
	update = (date) =>{
		if(!!this.state.date){
			transportsApi.getDataFromPeriod(this.props.serial, date, this.callback)
		}
	}
	callback = () =>{
		this.setState({date: '', answer: l_v.data_was_rerequested});
		this.h3.style.color = 'green';
		// this.dateIput.element.style.boxShadow = 'none'
		let returnToDef = () =>{
			this.setState(this.initialState);
			this.h3.style.color = 'black';
		}
		setTimeout(returnToDef, 2000)

	}
	validate = (e) =>{
		e.preventDefault();
		e.stopPropagation();
		let date = this.state.date;
		if(!!date && new Date(date) !== 'Invalid Date'){
			// date =  moment(date).toISOString();
			// date = moment(date).format('YYYY-MM-DDTHH:mm:ss');//[Z]
			this.update(moment(date).format())
			// this.dateIput.element.style.boxShadow = 'none'
		}
		else{
			// this.dateIput.element.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
		}
	}
	render(){
		const { linkHide, serial } = this.props;
		return 	<div className = 're_request_data current_info'	>
					<div className = 'date_for'>
						<h3 ref = {h3=> this.h3 = h3}>{this.state.answer}</h3>
						<div>
							<TimeInput  placeholder =   '25.08.1988 9:45'
							            class =         'input'
							            name =          'date_request'
							            value =         {this.state.date}
							            onChange =      { e=> this.setState({date: e.value}) }
							            textLabel =     {l_v.request_date}/>
							<button name = 		"date_request"
								className = "btn_black"
								onClick = 	{this.validate}>
								OK
							</button>
						</div>
					</div>
					<Link 	to = {`/info/device/${serial}/logs`}
							style = { { visibility: linkHide ? 'hidden' : 'visible' } }>
							{l_v.check_logs}
					</Link>
				</div>
	}
}
export default ReRequestData;