import React  from "react";
import actionCreators from '../actions/ActionCreator.jsx'
import l_v 				from "store/languages.jsx"

export var svgicons = {
	starIcon:{
		deactiv: 'M8.9,3l1.7,2.8c0.2,0.4,0.6,0.6,1,0.7L14.9,7l-2.3,2.4c-0.3,0.3-0.4,0.7-0.4,1.1l0.2,3.3l-2.7-1.3 c-0.2-0.1-0.4-0.1-0.6-0.1c-0.2,0-0.4,0-0.6,0.1l-2.7,1.3l0.2-3.3c0-0.4-0.1-0.8-0.4-1.1L3.1,7l3.2-0.6c0.4-0.1,0.8-0.3,1-0.7   L8.9,3 M8.9,1.4c-0.1,0-0.3,0.1-0.4,0.2L6.4,5.2C6.3,5.3,6.2,5.4,6.1,5.4L1.9,6.2C1.5,6.3,1.4,6.7,1.7,7l3,3.2 			c0.1,0.1,0.1,0.2,0.1,0.3l-0.2,4.2c0,0.3,0.2,0.5,0.4,0.5c0.1,0,0.1,0,0.2,0l3.6-1.7c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0l3.6,1.7  c0.1,0,0.1,0,0.2,0c0.2,0,0.5-0.2,0.4-0.5l-0.2-4.2c0-0.1,0-0.2,0.1-0.3L16.3,7c0.2-0.3,0.1-0.7-0.2-0.7l-4.3-0.8 	c-0.1,0-0.2-0.1-0.3-0.2L9.3,1.6C9.2,1.5,9.1,1.4,8.9,1.4L8.9,1.4z',
		active: 'M13.2,10.1L16.3,7c0.2-0.3,0.1-0.7-0.2-0.7l-4.3-0.8c-0.1,0-0.2-0.1-0.3-0.2L9.3,1.7   c-0.2-0.3-0.6-0.3-0.7,0L6.4,5.3C6.4,5.4,6.3,5.4,6.1,5.5L1.9,6.2C1.6,6.3,1.5,6.7,1.7,7l3,3.2c0.1,0.1,0.1,0.2,0.1,0.3l-0.2,4.2   c0,0.3,0.3,0.6,0.6,0.4l3.6-1.7c0.1-0.1,0.3-0.1,0.4,0l3.6,1.7c0.3,0.1,0.6-0.1,0.6-0.4l-0.2-4.2C13.1,10.3,13.1,10.2,13.2,10.1z',
	},
	iconNotification:{
		deactiv: {
			firstpath: 'M16,10.3l0-4.1C16,3,13.5,0.1,10.2,0C6.8-0.1,4,2.6,4,6v4.3c0,0.4-0.2,0.9-0.5,1.2L2,13v1c0,0.6,0.4,1,1,1h14  c0.6,0,1-0.4,1-1v-1l-1.5-1.5C16.2,11.2,16,10.8,16,10.3z',
			secondpath: 'M10,19c1.1,0,2-0.9,2-2H8C8,18.1,8.9,19,10,19z',
			color: '#ffffff'
		},
		active: {
			color: '#ffffff'
		}

	},
	

};
export class IconNotification extends React.Component {
	constructor(props) {
			super(props);
			this.state = {
					active:false,
					fillone: svgicons.iconNotification.deactiv.firstpath,
					filltwo: svgicons.iconNotification.deactiv.secondpath,
					event_counter: 5,
					class: 'some'
			}
	}
	fix_it(e){
  		if (this.state.active ==  false){
		  			this.setState({
		  				active: true,
						color: svgicons.iconNotification.active.color,
		  			});	
  		}
  		if (this.state.active == true){
		  			this.setState({
		  				color: svgicons.iconNotification.deactiv.color,
		  				active: false,
		  			});
		}
		var css = (this.state.class === "some") ? "show" : "some";
	    this.setState({"class":css});
	    this.props.updateTofix(true);
  	}
	render(){
		return(
			<div className="event">
				<p className={"tooltip " + this.state.class}>some text</p> 
				<span className='counter_events notification_counter'>{this.state.event_counter}</span>
				<svg className={"icon_notification" + " " + this.state.class}
					 xmlns="http://www.w3.org/2000/svg"  
					 aria-labelledby="title" >
					<title id="title">{l_v.notifications}</title>
					<path d= {this.state.fillone} fill="#FFF"/>
					<path d= {this.state.filltwo} fill="#FFF"/>
				</svg>
			</div>
			
		)
	}
}
export class IconStar extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
				active: this.props.fix,
				fill: svgicons.starIcon.deactiv,
				className: 'transparent',
				list_place: this.props.current_list_place,
				transport_key: this.props.transport_key

		}
		this.fix_it = this.fix_it.bind(this);
	}
	componentWillMount() {
		if (this.props.list_place === 1){
			this.setState({
				className: 'show',
				fill: svgicons.starIcon.active
			})
		}
	}
  	fix_it(e){
  		  const { fix, actions } = this.props;
  		  	let fill = (this.state.fill === svgicons.starIcon.active) ? svgicons.starIcon.deactiv : svgicons.starIcon.active;
  		  	var css = (this.state.className === "transparent") ? "show" : "transparent";
  		   this.setState({
  		   		className: css,
  				fill: fill,
  				
  			})
  		    actionCreators.fixation(this.state.transport_key)
  	}
	render(){
		return(
			<div>
			<svg 
				className={"star_icon" + " " + this.state.className}
				xmlns="http://www.w3.org/2000/svg"  
				aria-labelledby="title"
				onClick={this.fix_it}
			>
				<path d= {this.state.fill}
				/>
			</svg>
			</div>
		)
	}
}
	function toggleClass(value){
		if (value !== true){
			return 'displaynone'
		}
		else {return ''}

	}


