import React from "react"
import {leaveNumberWithDot} from 'actions/ActionCreator.jsx'
export default class Required extends React.Component{
	constructor(props) {
		super(props);
		const {defValue} = this.props;
		this.state = {
			error_emty_field: '',
			empty: true,
			value: defValue && defValue.length ? defValue :'',
		}
	}
	componentWillMount() {
		if(!this.props.defValue){
			this.props.data_from_required({
				empty: this.state.empty,
				name: this.props.name,
			})
		}

	}
	componentDidMount() {
		const {style, name}  = this.props;
		if (style !== undefined){
			if (style.display !== 'block'){
				this.props.data_from_required({
					empty: false,
					name:  name,
				})
				this.setState({
					error_emty_field: '',
					empty:            false,
				})
			}
		}
	}
	enterToThisState(e){
		let empty;
		let value = e.target.value;
		const name = e.target.name;
		if(name == 'width'){
			value = leaveNumberWithDot(value);
		}
		this.setState({
			value: value
		})
		if (!!value.length){
			empty = false;
			this.setState({
				error_emty_field: '',
				empty: false,
			})


		} else {
			empty = true;
			this.setState({
				error_emty_field: 'formError',
				empty: true,

			})
		}
		this.props.data_from_required(	{
											empty: empty,
											value: value,
											name: name,
										}, e)
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value,
		})
		if (nextProps.value !== this.state.value){
			this.props.data_from_required({
				empty:   nextProps.value && nextProps.value.length ? false : true,
				value: 	 nextProps.value,
				name:  	 this.props.name,
			})
		}
		if (nextProps.checkinput !== null){
			if (this.state.empty == true){
				this.setState({
					error_emty_field: 'formError',
				})
			}
			else {
				this.setState({
					error_emty_field: ''
				})
			}
		}
	}

	render(){
		let data  = {...this.props};
		return <input 	name = 			{data.name}
						type = 			{data.type}
						value = 		{this.state.value}
						defaultValue = 	{data.defaultValue}
						className = 	{data.className + this.state.error_emty_field}
						style = 		{data.style}
						placeholder = 	{data.placeholder}
						onChange = 		{data.onChange}
						key = 			{data.name}
						onChange = 		{this.enterToThisState.bind(this)} />
	}
}
