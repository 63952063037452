import React, { Component }                     from "react"
import { Provider, connect }                    from 'react-redux'
import { bindActionCreators, applyMiddleware }  from 'redux'
import Navigation                               from './AdminNavigation.jsx'
import l_v                                      from "store/languages.jsx"
import Search                                   from './component/Search.jsx'
import BtnAddAdmin                              from './component/BtnAddAdmin.jsx'
import AdministratorList                        from './component/AdministratorList.jsx'
import ActionCreator, { removeElementFromList } from '../actions/ActionCreator.jsx'
import Users                                    from '../actions/users.js'
import SeparPermission                          from './component/SeparPermission.jsx'
import ConfirmRemove                            from './ConfirmRemove.jsx'
import { withRouter }                           from 'react-router'
import { getPage }                              from "./Devices.jsx"
import TableFooter, { TableFooterMethods }      from "modules/devicePage/TableFooter.jsx"
import Helmet                                   from 'react-helmet';

class AdminPage extends TableFooterMethods(Component) {
    constructor(props) {
        super(props);
        const count_lines_for_view = 20;
        this.state = {
                        AdminData: null,
                        user_login: '',
                        dealerGroupsList: [],
                        ...this.paginationState
        }
        this.kindUsersForShow = this.props.like?this.props.like:'ADMIN';
        // redirect user if he is not admin
    }
    componentWillMount() {
        window.onhashchange = this.getInfo;
    }
    componentDidMount() {
        this.doRequest()
        if(this.kindUsersForShow == 'DEALER'){
            Users.getDealersGroups(groupsList => this.setState({dealerGroupsList: groupsList}))
        }

    }
    doRequest = () => {
        const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
        Users.getData(this.kindUsersForShow, {
            page: getPage(totalElements, end_index, count_lines_for_view),
            size: count_lines_for_view,
        }, contentInfo => this.setState({
            AdminData: contentInfo.content,
            totalElements: contentInfo.totalElements

        }))
    }
    // valueForSort = (e) =>{
    //     this.value = e.target.value;
    //     this.handleFilterChange();
    // }
    handleFilterChange = (value) =>{

            // let searchQuery = this.value.toLowerCase();
            let searchQuery = value;
            let {count_lines_for_view} = this.state;
            Users.getData(this.kindUsersForShow, {
                page: 0,
                size: count_lines_for_view,
                searchParam: searchQuery,
            }, contentInfo => this.setState({
                start_index: 0,
                end_index: count_lines_for_view,
                clientsList: contentInfo.content,
                totalElements: contentInfo.totalElements

            }))
        //     if(this.value !== undefined){
        // }
    }
    componentWillUnmount() {
        window.onhashchange = null
    }
    filterByGroup(data){
        let searchQuery = data.toLowerCase();
        let list;
        if(this.props.like == 'DEALER'){
            list = this.props.DealerData
        }else{
            list = this.props.AdminData

        }
        let displayedUser = list.filter(function(el){
                let searchValue;
                if( el.belongsTo !== undefined){
                    searchValue = el.belongsTo.toLowerCase();
                }
                else {searchValue = ''}
            return searchValue.indexOf(searchQuery) !== -1;
        });
        this.setState({
            AdminData: displayedUser,
        });
    }
    updateUser = (dataForRequst) => {
        const addUserToList = (newUser) => {
            const {AdminData, dealerGroupsList} = this.state;
            dealerGroupsList.unshift(newUser.login)
            AdminData.unshift(newUser)
            this.setState({
                AdminData: AdminData,
                dealerGroupsList: dealerGroupsList
            });
        }
        Users.addUser(dataForRequst, addUserToList);
    }
    clear(data){
        ActionCreator.clear(data);
    }
    deleteuser = () =>{
        const removeUserFromList = (userLogin) =>{
            const {AdminData} = this.state;
            this.setState({
                AdminData: removeElementFromList(AdminData, 'login', userLogin)
            });
        }
        Users.deleteUser(this.state.forDelete, removeUserFromList);
        this.refs.delete.popUp_activation({
            active: false
        })
        this.setState({
            forDelete: null,
        });
    }
    callPopUp = (e) =>{
        this.setState({
            forDelete: e,
        });
        this.ConfirmTextforRemove = l_v.are_you_sure  + e.userlogin +'?';
        this.refs.delete.popUp_activation({
            active: true
        })
    }
    render(){
        const { start_index, end_index, count_lines_for_view, totalElements, dealerGroupsList } = this.state;
        const title = this.kindUsersForShow == 'ADMIN' ? l_v.admins : l_v.dealers;
        return(
            <div>
                <Helmet title = {title} />
                <Navigation />
                <SeparPermission    alowed =            'SUPERADMIN, ADMIN, DEALER'
                                    kindCapability =    {['createAdminUsers']}//'editOther',
                                    type =              'page'/>
                <div className='container content'>
                    <h1 className="page_name">{(this.kindUsersForShow == 'ADMIN')?l_v.admins:l_v.dealers_list}</h1>
                    <Search     placeholder = {l_v.search}
                                onChange = {this.handleFilterChange}>

                    </Search>
                    <SeparPermission    alowed =            {'SUPERADMIN, ADMIN' }
                                        type =              'component'
                                        kindCapability =    'createAdminUsers'>
                        <BtnAddAdmin    pasword_placeholder = {l_v.enter_password}
                                        name_placeholder =    {l_v.enter_login}
                                        dealerGroupsList =    {dealerGroupsList}
                                        kindUser =            {this.kindUsersForShow}
                                        btnname =             {this.kindUsersForShow == 'ADMIN' ? l_v.add_admin : l_v.add_dealer}
                                        dataFromform =        {this.updateUser}
                                        userPremission =      {this.props.userPremission} />
                    </SeparPermission>
                    <AdministratorList  userPremission =    {this.props.userPremission}
                                        delete =            {this.callPopUp}
                                        kindUser =          {this.kindUsersForShow}
                                        data =              {this.state.AdminData}
                                        filterGroupByThis = {this.filterByGroup.bind(this)}/>
                    <TableFooter    changePage = {this.changePage}
                                    show_pagination = {totalElements?true:false}
                                    count_of_available_lines = {totalElements}
                                    count_lines_for_view = {count_lines_for_view}
                                    setCountsLineForView = {this.updateCountForView}
                                    start_index = {start_index}
                                    end_index = {end_index} />
                </div>
                <ConfirmRemove  ref =           'delete'
                                confirmText =   {this.ConfirmTextforRemove }
                                clear =         {()=>this.setState({
                                                        forDelete: undefined
                                                })}
                                remove =    {this.deleteuser}/>
              </div>
        )
    }
}


export default connect(
      state => ({
        login:          state.authorizationR.login,
        token:          state.authorizationR.token,
        kindUser:       state.authorizationR.kindUser,
        AdminData:      state.clientsR.AdminData,
        DealerData:     state.clientsR.DealerData,
        userPremission: state.authorizationR.user_permission,
      }),
)(withRouter(AdminPage))
