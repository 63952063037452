import React, { Component, Fragment } from 'react';
import SeparPermission                from './component/SeparPermission.jsx'
import Navigation                     from '../modules/AdminNavigation.jsx'
import StatisticForAdmin              from './component/StatisticForAdmin.jsx'
import logsApi                        from 'actions/logsApi.js'
import Helmet                         from 'react-helmet';
import Preloader                      from 'modules/preloader.jsx'
import language_variant                         from "../store/languages.jsx"

export default class BillingPage extends Component {
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	getReportByStatisticsActualDevice = ()=>{
		this.preloader.show();
		const hidePreloader = () => {
			if(this.mounted) this.preloader.hide()
		}
		logsApi.getReportByStatisticsActualDevice(hidePreloader)
	}
	render() {
		return (
			<Fragment>
		       	<Helmet title={language_variant.statistics} />
		      	<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
									type =              'page'
	                        		kindCapability =    'editClients'/>
		      	<Navigation />
		      	<div className='container content'>
			      	<div className = 'flex_container' style = {{alignItems: 'center'}}>
			        	<h1 className="page_name_client">{language_variant.statistics}</h1>
			        	<button className="primari_btn" onClick = {this.getReportByStatisticsActualDevice}>
			        		{language_variant.download_statistics}
			        	</button>
		        	</div>
		        	<StatisticForAdmin />
		        	<Preloader ref = {preloader => this.preloader = preloader} />
				</div>
			</Fragment>
		);
	}
}
