import infoStore			from './infoStore.js'
import axios 		from 'axios'
import bugReport 	from './bugReport.jsx'
import store from 'store/configureStore.jsx'

import l_v 				from "store/languages.jsx"

function getReportByStatisticsActualDevice(callback) {
	let locale  = infoStore('languageR.language')?infoStore('languageR.language'):'UA';

	let requestData = {
		baseURL: 	infoStore().base_url,
		url: 		infoStore().bonus_url_device + 'device/statistic/csv' ,
		dataType: 	"json",
		method: 	'POST',//because)
		headers: 	{'authorization': infoStore().token},
		params: 	{
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			locale: locale.toUpperCase()
		},
	}
	axios(requestData)
	.then((answer) =>{
			callback ? callback() : null
			download(
				answer.data,
				'active_devices.csv',
				'data:text/' + 'csv' + ';charset=utf-8'
			)

    })
    .catch(function(serveranswer) {
	    	callback ? callback() : null
		  	bugReport (serveranswer, store)
	});
}

function getLog(params, callback) {
	const requestData = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'protocols/actions',
			dataType: "JSON",
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			params: params,
		}
	axios(requestData)
	.then(answer => {
		callback(answer.data)
		store.dispatch({
			type: 'STATUS_REQUEST',
			setAnswer: answer.status,
			settextanswer: l_v.information_was_updated
		})
	})
	.catch(serveranswer => bugReport(serveranswer, store));
}

function setUserLogging(login, locking){
	axios({
		baseURL: 	infoStore().base_url,
		url: 		infoStore().bonus_url_device + '/protocols/user-locking/'+ login ,
		dataType: 	"json",
		method: 	'PUT',
		headers: 	{'authorization': infoStore().token},
		params: 	{
			locking: locking
		},
	})
	.then(function answer(answer){
			let logs = answer.data;

        	console.log("%c logs", 'color: green; font-weight: bold;',  logs)
			store.dispatch({
					type: 'STATUS_REQUEST',
					setAnswer: answer.status,
					settextanswer: l_v.information_was_updated
			})
    })
    .catch(function(serveranswer) {
		  bugReport (serveranswer, store)
	});
}

function download(file, filename, type) {
	var file = new Blob([file], {name:filename}, {type:type});
	if(window.navigator.msSaveOrOpenBlob){ // IE10+
			window.navigator.msSaveOrOpenBlob(file);
	}else { // Others
		var a = document.createElement("a"),
			url = URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url);
		}, 0);
	}
}

function getTokenStatistic(callback) {
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'tokens-sessions',
		dataType: "JSON",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
	.then(answer => {
		callback(answer.data)
		store.dispatch({
			type: 'STATUS_REQUEST',
			setAnswer: answer.status,
			settextanswer: l_v.information_was_updated
		})
	})
	.catch(serveranswer => bugReport(serveranswer, store));
}

function removeAllTokens(callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_device + 'tokens',
		dataType: "JSON",
		method:   'DELETE',
		headers:  { 'authorization': infoStore().token },
	}
	axios(requestData)
	.then(answer => {
		callback(answer.data)
		store.dispatch({
			type: 'STATUS_REQUEST',
			setAnswer: answer.status,
			settextanswer: l_v.tokens_deleted_successfully
		})
	})
	.catch(serveranswer => bugReport(serveranswer, store));
}
// download(['some'],'active_devices.csv',
// 				'data:text/' + 'csv' + ';charset=utf-8')

function printFile(file, filename, type) {
	// let file = new Blob([data]);
	let width = window.outterWidth;
	var strWindowFeatures = "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,width=" + width;
	let newWin = window.open("about:blank", filename, strWindowFeatures);
	let test = newWin.document.createElement('div');
	test.textContent = 'olala';
	test.setAttribute('onClick', 'isLoaded()');
	let url = (newWin.URL || newWin.webkitURL).createObjectURL(file)
	let embed = newWin.document.createElement('embed');
	embed.setAttribute('src', url);
	embed.setAttribute('width', '100%');
	embed.setAttribute('height', '100vh');
	embed.setAttribute('src', url);
	embed.setAttribute('type', 'application/pdf');
	embed.setAttribute('id', 'file');
	embed.setAttribute('name', 'file');
	embed.setAttribute('onload', "isLoaded()");
	var a = document.createElement("a");
	// url = URL.createObjectURL(file);
	a.href = url;
	a.textContent = 'lalalal'
	a.download = filename;
	newWin.document.body.appendChild(a);
	newWin.document.head.innerHTML = '<title>Print ' + filename + '</title>'
	newWin.document.body.appendChild(test);
	newWin.document.body.appendChild(embed);
	window.newWin = newWin;
	let script = newWin.document.createElement('script')
	script.textContent = "function isLoaded(){alert('will');var pdfFrame = document.embeds['file'];pdfFrame.focus();pdfFrame.print();}";
	newWin.document.head.appendChild(script);
	console.log("%c iframe", 'color: green; font-weight: bold;', embed)
	newWin.console.log('newWin.document', newWin.document)
}

const logsApi = {
	getLog:                            getLog,
	setUserLogging:                    setUserLogging,
	getReportByStatisticsActualDevice: getReportByStatisticsActualDevice,
	getTokenStatistic:                 getTokenStatistic,
	removeAllTokens:                   removeAllTokens
}
export default logsApi;