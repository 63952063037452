import React from 'react'
import language_variant from 'store/languages.jsx'
import CreatePortal                  from "modules/component/CreatePortal.jsx"
import {EscapeListennerForForm} from 'modules/component/EnterListennerForForm.jsx'

export default class Pop_up extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: !!this.props.openAlways ? this.props.openAlways : false,
        }
        this.close = this.close;
    }
    close = (e) => {
        console.log(`%c close e-`, "color: green; font-weight: 400; text-transform:uppercase", e)
        const onClose = () => this.props.onClose ? this.props.onClose() : null;
        this.setState({
            active: false
        }, onClose)
    }
    open = () => {
        this.setState({
            active: true
        });
    }
    render() {
        const { active } = this.state;
        const { dataName } = this.props;
        return active ?
        <EscapeListennerForForm onPressEscape = {this.close}>
            <CreatePortal idOfMountPlace = 'modal-root'>

                <div className = {this.props.class} data-name = { `pop container ${dataName}` }>
                    <div    className = "pop_mask"
                            onClick = {this.close}>
                    </div>
                    <div className = "pop_up" style = { {...this.props.popUp_size, maxHeight: '100vh', maxWidth: '100vw'} }>
                        <div className = "pop_header" style = {this.props.head_back}>
                        <span>{ this.props.head_text }</span>
                        <button className = "btn_close close"
                                onClick = {this.close}>
                          { close_icon }
                        </button>
                        </div>
                        { this.props.children }
                    </div>
                </div> 
            </CreatePortal>
        </EscapeListennerForForm> :
        null
    }
}

export let close_icon = <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                  <g id="Task" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="New-Task-cards-states" transform="translate(-1908.000000, -884.000000)" fill="#0E0E0E">
                          <g id="Route---search-Copy-2" transform="translate(1523.000000, 812.000000)">
                              <g id="input-1" transform="translate(0.000000, 49.000000)">
                                  <g id="cross" transform="translate(376.000000, 14.000000)">
                                      <g id="cross_small" transform="translate(9.000000, 9.000000)">
                                          <polygon className = "symbol" id="Page-1" points="8.485 0.00048 4.95 3.53548 1.414 0.00048 0 1.41448 3.535 4.94948 0 8.48548 1.414 9.89948 4.95 6.36448 8.485 9.89948 9.899 8.48548 6.364 4.94948 9.899 1.41448"></polygon>
                                      </g>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </svg>;