import store 				from 'store/configureStore.jsx'

export function clear(data){
		store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: data
		})
}
export function setgroupfilter(data){
		store.dispatch({
			type: "GROUP_FILTER",
			upadate_filter_data: data
		})
}
export function setMessageStatus(data){
		store.dispatch({
			type: "MESSAGE_STATUS",
			setstatus: data
		})
}
export function setCopy(info) {
		info ? localStorage.setItem('copyInfo', JSON.stringify(info)) : localStorage.setItem('copyInfo','');
		store.dispatch({
			type: "SETCOPY",
			setCalibratioForCopy: info
		})
}
export function setDeportaionUrl(url){
	store.dispatch({
			type: 'URL_BEFORE_DEPORTATION',
			setURL: url,
		})
}