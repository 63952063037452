import React, { Component }                             from 'react';
import { IsJsonString, encodeLinkInfo,  getLinkInfoParam }                                 from "actions/ActionCreator.jsx"
import formAction                                       from "actions/formAction.js"
import { getDomain, getLocalDomain, getProdTestDomain } from "reducers/authorization_reducer.jsx"
import {prevPageIcon}                                   from "modules/img.jsx"
import Users                                            from "actions/users.js"
import l_v from "store/languages.jsx"

/*import Navigation               from '../modules/AdminNavigation.jsx'
                    <Navigation />*/
export default class AuthInAppFromLinkPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            link:   '',
        }
        this.initialState = this.state;
        this.linkInfo = {};
    }
    enterToState = (e) =>{
        this.linkInfo = getLinkInfoParam(e.target.value);
        const variativeState =  this.linkInfo && this.linkInfo.isValid ?
                                {
                                    linkIsBad: false,
                                    startingRedirect: true,
                                } :
                                {
                                    linkIsBad: true,
                                };
        this.setState({
            [e.target.name]: e.target.value,
            ...variativeState
        });

    }

    componentDidUpdate() {
        if(this.linkInfo.isValid) {
            const redirectToApp = (SID) => window.location.href = generateLinkForAuthInClient(this.linkInfo, SID);
            const callbackOnError = () => {
                alert(`Авторизация под пользователем c логином ${this.linkInfo.obj.login}  недоступна для вас`)
                formAction.redirectUser({ kind: formAction.kindUser })
            };
            Users.getSidForAuthInClient(this.linkInfo.obj.login, redirectToApp, callbackOnError)
        } else {
            // formAction.redirectUser({ kind: formAction.kindUser })
        }
    }
    render() {
        const { link,  startingRedirect, linkIsBad} = this.state;
        return <div className = "container auth_in_app_from_link_page">
                    <div style={{display: 'inline-block'}}>
                            {
                                linkIsBad ?
                                <div className = 'input_label' >
                                    <p className = 'red_color'>{l_v.link_is_bad}</p>
                                    <button className = 'btn_go_back'
                                            onClick =   { () => history.back() }>
                                        {l_v.come_back}
                                    </button>
                                </div> :
                                <div className = 'input_label' >
                                    <p>{l_v.you_will_be_authorized}</p>
                                    <button className = 'btn_go_back'
                                            onClick =   { () => history.back() }>
                                        {l_v.come_back}
                                    </button>
                                </div>
                            }
                            <textarea   className = {`form_text_area ${linkIsBad ? 'formError' : ''}`}
                                        type = 'text'
                                        name = 'link'
                                        value = {link}
                                        onChange = {this.enterToState}
                                        placeholder = {l_v.you_will_be_authorized_placeholder}>
                            </textarea>
                            {
                                startingRedirect ?
                                <p className = "centered_message_text">Выполняется авторизация ...</p> :
                                null
                            }
                    </div>
        </div>
    }
}


function generateLinkForAuthInClient(linkInfo, SID) {
    const { login } = linkInfo.obj;
    const domain = DEVELOPMENT ? getLocalDomain('GLOBUS') : getDomain('GLOBUS');
    return `${domain}?linkInfo=${linkInfo.linkInfoBase64}#/commingFromAdmin/${SID}/${login}`; //for teres not allowed
}