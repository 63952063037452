import React 				from 'react'
import logsApi 						from '../../actions/logsApi.js'
import l_v 					 from "store/languages.jsx"

export default class EnDisUserLogging extends React.PureComponent {
	sendRequest = (e) =>{
		logsApi.setUserLogging(this.props.login, e.target.value)
	}
	render(){
		return <div className = 'edit_user_logging'> 
					<button name = 'sendRequest'
							className = 'btn_black'
							value = {false}
							onClick = {this.sendRequest}>
							{l_v.enable_logging}
					</button>
					<button name = 'sendRequest'
							className = 'btn_black'
							value = {true}
							onClick = {this.sendRequest}>
							{l_v.disable_logging}
					</button>
				</div>
	}
}