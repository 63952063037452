import React 			from 'react'
import Required 		from '../component/Required.jsx'
import RequireWrapper   from '../component/RequireWrapper.jsx'
import SeparPermission, {byKind} 	from '../component/SeparPermission.jsx'
import l_v 				   from 'store/languages.jsx'

export default class FormPeriod extends React.Component {
	constructor(props) {
		super(props);
		let first = this.props.input_value.period1;
		let second = this.props.input_value.period2;
		let minFirst = this.props.input_value.minPeriod1;
		let minSecond = this.props.input_value.minPeriod2;
		let {returnMinimalPeriod} = props;

		this.state = {
			message: '',
			dataforsend: '',
			first: removeWarming(first),
			second: removeWarming(second),
			minFirst: removeWarming(minFirst),
			minSecond: removeWarming(minSecond),
			returnMinimalPeriod: !!returnMinimalPeriod?returnMinimalPeriod:false,
			check: false,
		}
		this.requires = {};
	}
	componentWillReceiveProps(nextProps) {
		let first = nextProps.input_value.period1;
		let second = nextProps.input_value.period2;
		let minFirst = nextProps.input_value.minPeriod1;
		let minSecond = nextProps.input_value.minPeriod2;
		let {returnMinimalPeriod} = nextProps;
		this.setState({
			first: removeWarming(first),
			second: removeWarming(second),
			minFirst: removeWarming(minFirst),
			minSecond: removeWarming(minSecond),
			returnMinimalPeriod: !!returnMinimalPeriod?returnMinimalPeriod:false,
		})
	}
	enterToState = (e) =>{
		this.setState({
			[e.target.name]:e.target.value
		});
	}
	addition = (event) =>{
		let minFirst = this.state.minFirst, //ask wtf? backend can't do one point for one form I need save current state, before update from global store
			minSecond = this.state.minSecond,
			first = this.state.first,
			second = this.state.second,
			returnMinimalPeriod = this.state.returnMinimalPeriod;
		if(byKind(this.props.kindUser, 'ADMIN, SUPERADMIN, DEALER')){
			if(this.props.input_value.period1 !== this.state.first
					|| this.props.input_value.period2 !== this.state.second){
				this.props.dataFromform({
					period1: Number(first),
					period2:  Number(second),
				})
			}
			if(this.props.returnMinimalPeriod !== this.state.returnMinimalPeriod){
				setTimeout(()=>{
					this.props.dataFromform({
						returnMinimalPeriod: returnMinimalPeriod,
					})
				}, 500)
			}
			if(this.props.input_value.minPeriod1 !== this.state.minFirst
					|| this.props.input_value.minPeriod2 !== this.state.minSecond){
				setTimeout(()=>{
				this.props.updateTrans({
					minimalPeriod1: Number(minFirst),
					minimalPeriod2: Number(minSecond),
				})},1500)
			}

		}else{
			this.props.dataFromform({
				period1: Number(this.state.first),
				period2:  Number(this.state.second),
			})
		}

	}
	checkRequire = (data, event) =>{
		this.setState({
			check: true,
		});
		let validate = true;
		let name;
		Object.keys(this.requires).map(key=>{
			if(this.requires[key] == false){
				validate = false;
				name = key;
			}
		})
		if(!!validate){
			this.setState({
					message: 	'',
					check: 		false,
			})
			this.addition()
		}else{
			this.setState({
				message: `${l_v.fill_marked_fields}`,
			})
		}
	}
	requireElem = (name, value) =>{
		this.requires[name] = value;
	}
	render(){
		return(
			<form >
				<p className = "nameForm">{this.props.form_name}</p>
				<p className = 'form_message '>{this.state.message}</p>
				<div className = 'transmit_period_block'>
					<p className = 'transmit_period_block_name'>{l_v.current}:</p>
					{l_v.when_ignition_on}
					<RequireWrapper checkRequire = {this.requireElem}
									checkIt = {this.state.check}>
						<input 	type = 			"number"
								name = 			"first"
								value = 		{this.state.first}
								min = 			{(this.props.kindUser == "CLIENT")?this.state.minFirst:0}
								className = 	{'input '}
								placeholder = 	{this.props.placeholder}
								onChange = 	{this.enterToState} />
					</RequireWrapper>
					{l_v.when_parking}
					<RequireWrapper checkRequire = {this.requireElem}
									checkIt = 		{this.state.check}>
						<input	type = 			"number"
								name= 			"second"
								value = 		{this.state.second}
								min = 			{(this.props.kindUser == "CLIENT")?this.state.minSecond:0}

								className = 	{'input '}
								placeholder = 	{this.props.placeholder}
								onChange = 		{this.enterToState}/>
					</RequireWrapper>
				</div>
				<SeparPermission alowed = "ADMIN, SUPERADMIN, DEALER">
				<div>
					<p className = 'transmit_period_block_name'>{l_v.minimal}:</p>
					{l_v.minimal_ignition_on}
					<RequireWrapper checkRequire = {this.requireElem}
									checkIt = 		{this.state.check}>
						<input	type = 			"number"
								name= 			"minFirst"
								value = 		{this.state.minFirst}
								className = 	{'input '}
								placeholder = 	{this.props.placeholder}
								onChange = 		{this.enterToState}/>
					</RequireWrapper>
					{l_v.minimal_when_stopped}
					<RequireWrapper checkRequire = {this.requireElem}
									checkIt = 		{this.state.check}>
						<input	type = 			"number"
								name= 			"minSecond"
								value = 		{this.state.minSecond}
								className = 	{'input '}
								placeholder = 	{this.props.placeholder}
								onChange = 		{this.enterToState}/>
					</RequireWrapper>
						<p className = 'checkbox_container'>
							<input 	type =		"checkbox"
									name =		'returnMinimalPeriod'
									id = 'returnMinimalPeriod'
									className = 'select_checkbox'
									onChange =  {()=>this.setState({returnMinimalPeriod: !this.state.returnMinimalPeriod})}
									onClick = 	{()=>this.setState({returnMinimalPeriod: !this.state.returnMinimalPeriod})}
									checked =   {this.state.returnMinimalPeriod}/>
							<label 	htmlFor =	"returnMinimalPeriod"
									className = 'select_label'>
									{l_v.reset_at_midnight}
							</label>
						</p>
				</div>
				</SeparPermission>
				<button
							className = 'btn_black'
							type = 		'button'
							name = 		'send'
							onClick = 	{this.checkRequire}>
				{this.props.btntext}
				</button>
			</form>
		)
	}
}
function removeWarming(value){
	value = (!!value || value === false)?value:'';
	return value
}