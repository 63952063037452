import React          from 'react'
import moment         from 'moment'
import SL 	          from 'sl-library'
import TooltipWrapper from '../component/TooltipWrapper.jsx'
import TimeInput      from "dd-date-time-input"
import l_v            from "store/languages.jsx"

export default class ChangePeriodCalibration extends React.Component {
	constructor(props) {
		super(props);
		let { sensorInfo, defaultPeriod } = this.props;
		this.state = {
			calibrationIdMapping: SL.ifNoKey(sensorInfo, 'id'),
			dateFrom:             SL.ifNoKey(defaultPeriod, 'from') ? moment(defaultPeriod.from).format('YYYY-MM-DDTHH:mm:ss') : '',
			dateTo:               SL.ifNoKey(defaultPeriod, 'to') ? moment(defaultPeriod.to).format('YYYY-MM-DDTHH:mm:ss') : '',
			message:              ''
		}
	}
	enterToState = (e) => {
		let name = e.name || e.target.name;
		let value = e.value || e.target.value;
		this.setState({
			[name]: value
		});
	}
	validate = (e) => {
		e.preventDefault();
		let { calibrationIdMapping, dateFrom, dateTo } = this.state;
		if (!!dateFrom && SL.isValidDate(new Date(dateFrom))) {
			dateFrom = moment(dateFrom).toISOString();
		} else {
			// this.dateFrom.element.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
			dateFrom = null;
			this.setState({
				message: l_v.calibration_id_and_start_date_are_required
			});
		}
		if (!!dateTo && SL.isValidDate(new Date(dateTo))) {
			dateTo = moment(dateTo).toISOString();
		} else {
			if (dateTo) { //dateTo can be empty
				// this.dateTo.element.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
				dateTo = null;
				this.setState({
					message: l_v.date_end_are_wrong
				});
			}
		}
		if (!calibrationIdMapping) {
			this.calibrationIdMapping.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
			this.setState({
				message: l_v.сalibration_mapping_required_field
			});
		}
		if (!!dateFrom && !!calibrationIdMapping) {
			// this.dateFrom.element.style.boxShadow = 'none';
			this.calibrationIdMapping.style.boxShadow = 'none';
			// this.dateTo.element.style.boxShadow = 'none';
			this.props.changePeriod(this.props.socketId, calibrationIdMapping, dateFrom, dateTo)
		}
	}
	render() {
		return <form className = 'change_period_form' onSubmit = {this.validate}>
               <p className = 'detail_socket_info_tub_header'>{l_v.update_calibration_period}</p>
					<div className = 'message'>{this.state.message}</div>
					<div className = 'clear'></div>
					<TooltipWrapper 	name = 		{l_v.id_mapping_calibration}
											position = 	'right'
											className = 'wrap'>
						<input 	type = 			'text'
									ref = 			{realSensorId =>this.calibrationIdMapping = realSensorId}
									className = 	{'input ' + this.state.sensorNumber_empty}
									name = 			'calibrationIdMapping'
									value = 			{this.state.calibrationIdMapping}
									onChange = 		{this.enterToState}
									placeholder = 	{`${l_v.id_mapping_calibration} *`}/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper 	name = 		'Дата начала'
											position = 	'right'
											className = 'wrap'>
						<TimeInput 	placeholder = 	'25.08.1988 9:45'
										withSeconds = 	{true}
										name = 			'dateFrom'
										maxDate = 		{this.state.dateTo}
										value = 			{this.state.dateFrom}
										class = 			'input'
										onChange = 		{(e)=>this.setState({dateFrom: e.value})}
										labelHide = 	{true}/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<TooltipWrapper 	name = {l_v.date_of_period_end}
											position = 'right'
											className = 'wrap'>
						<TimeInput 	placeholder = 	'25.08.1988 9:45'
										withSeconds = 	{true}
										name = 			'dateTo'
										minDate = 		{this.state.dateFrom}
										value = 			{this.state.dateTo}
										class = 			'input'
										onChange = 		{(e)=>this.setState({dateTo: e.value})}
										maxDis = 		{true}
										labelHide = 	{true}/>
					</TooltipWrapper>
					<div className = 'clear'></div>
					<button className  = 	'primari_btn'
							style = 		{{display: 'block'}}
							onClick = 		{this.validate}>
						Обновить период
					</button>
				</form>
	}
}