import React 			from 'react'
import Required 		from '../component/Required.jsx'
import l_v    			from 'store/languages'

export default class FromAddUser_forTransport extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			checkinput: null,
			requiredfield: [],
	     	message: '',
		}
	}
	userequireddata = (data, event) => {
		let array = this.state.requiredfield;
		let write;
		array.map(function(key)  {
			if (key.name == data.name){
				key.value = data.value;
				key.empty = data.empty;
				write = true;
				return key 
			} else {} 

		})
		if (write !== true) {
			array.push(data)	
		}
		this.setState({
			requiredfield: array,
		})
		if (event !== undefined){
			this.setState({
				[event.target.name]:event.target.value,
			})
		}
	}
	addition = event => {
		this.setState({
			checkinput: 'check',
		})
		let array = this.state.requiredfield;
		let aftercheck;
		array.map(function(key){
			
			if (key.empty == true){
				aftercheck = false;
			}
		})
		if (aftercheck == false){
			this.setState({
				message: `${l_v.fill_marked_fields}`,
			})
		}
		else{
			this.setState({
					message: '',
					number_error: ''
			})
			this.props.dataFromform(this.state.login)	
		}
	}

	render(){
			return(
				<form className = 'connect_user'>
						<p className="nameForm">{l_v.connect_user_to_transport}</p>
						<p className = 'form_message '>{this.state.message}</p>
						<Required 
										checkinput = {this.state.checkinput}
										data_from_required = {this.userequireddata}
										type = "text"
										name='login'
										className = {'input '}
										placeholder = {l_v.enter_user_login}
						/>
						<button
									className = 'btn_black'
									type = 'button'
									name = 'send'
									onClick = {this.addition}
						>
						{l_v.add}
						</button>
				</form>
			)
	}
}