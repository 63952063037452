import store 				from 'store/configureStore.jsx'
import axios 				from 'axios'
import bugReport, {setText} from './bugReport.jsx'
import infoStore			from './infoStore.js'
import {copyOfObject, arrayIsEmpty, concatLists} 		from "./ActionCreator.jsx"
import l_v 				from "store/languages.jsx"

const devicesApi = {
	toggleDeviceStatusInTrash: toggleDeviceStatusInTrash,
	setNewConnectStatus:       setNewConnectStatus,
	addDevice:                 addDevice,
	getDevices:                getDevices,
	requestToDevice:           requestToDevice,
	getCommandsHistory:        getCommandsHistory,
	disconectDevice:           disconectDevice,
	changeEnableStatus:        changeEnableStatus,
	changeIgnitionIgnore:      changeIgnitionIgnore,
	updateDevice:              updateDevice,
	upateStateFromSocket:      upateStateFromSocket,
	setDeviceSettingsDTO:      setDeviceSettingsDTO,
	setNewHW_and_SW_versions:  setNewHW_and_SW_versions,
	saveNewAuthCode: 		   	saveNewAuthCode,
	deleteDeviceFromServer:    deleteDeviceFromServer,
	getIgnoredDevices: 		   getIgnoredDevices,
	addToIgnoredDevices: 	   addToIgnoredDevices,
	removeFromIgnoredDevices:  removeFromIgnoredDevices,
	getIgnoredDevice: 		   getIgnoredDevice,
	removeDataFromCassandra:   removeDataFromCassandra,
	getDeviceHistory: 		   getDeviceHistory,
	getDeviceId: 		   	   getDeviceId,
	removeDataBySensorNumber:  removeDataBySensorNumber,
	upateSpeedByPeriod:			upateSpeedByPeriod,
	getNavitrackUnid: 			getNavitrackUnid,
	saveNavitrackIgnition: 		saveNavitrackIgnition,
	deleteNavitrackIgnition: 	deleteNavitrackIgnition
}

window.devicesApi = devicesApi;
export default devicesApi;

function deleteNavitrackIgnition(deviceSerial, onSuccess) {
	axios({
					baseURL: 	infoStore().base_url,
					url: 			infoStore().bonus_url_device + `device/navitrack-ignition-sensor-mapping/globus-device-id/${deviceSerial}`,
					dataType: 	"JSON",
					method: 		'DELETE',
					headers: 	{ 'authorization': infoStore().token },
				})
				.then( answer => {
					onSuccess(answer.data) 
				})
				.catch( serveranswer => {
					bugReport(serveranswer, store)
				})
}

function saveNavitrackIgnition({requestInfo, onSuccess, onError}) {
	axios({
				baseURL: 	infoStore().base_url,
				url: 			infoStore().bonus_url_device + `device/navitrack-ignition-sensor-mapping`,
				dataType: 	"JSON",
				method: 		'POST',
				headers: 	{ 'authorization': infoStore().token },
				data: 		requestInfo,
			})
			.then( answer =>  onSuccess(answer.data)  )
			.catch( serveranswer => {
				bugReport(serveranswer, store)
			})
}

function getNavitrackUnid(deviceSerial, callbackOnSuccess, callbackOnFailure) {
	axios({
			baseURL: 	infoStore().base_url,
			url: 			infoStore().bonus_url_device + `device/navitrack-ignition-sensor-mapping/globus-device-id/${deviceSerial}`,
			dataType: 	"json",
			method: 		'GET',
			headers: 	{ 'authorization': infoStore().token },
		})
		.then( answer => {
			console.log(`%c callbackOnSuccess-`, "color: green; font-weight: 400; text-transform:uppercase", callbackOnSuccess, answer)
			callbackOnSuccess(answer.data) 
		})
		.catch( serveranswer => {
			if(callbackOnFailure) callbackOnFailure()
			bugReport(serveranswer, store)
		})
}

function toggleDeviceStatusInTrash(deviceInfo, callback) {//trash: true/false
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/trash/device',
			dataType: "json",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			params: {
				deviceId: deviceInfo.serialNumber
			}
		})
		.then(function answer(answer) {
			let currentList = infoStore("devicesR." + deviceInfo.kindDevice + "Data");
			let updatedDevice = answer.data;
			callback(updatedDevice)
			// let key_action = 'set' + deviceInfo.kindDevice + 'Data';
			// let newlist = currentList.filter(function(key) {
			// 	return key.serialNumber !== deviceInfo.serialNumber
			// });
			// let indexUpdatedDevice = currentList.findIndex(el=>{
			// 	if(el.serialNumber == updatedDevice.serialNumber){
			// 		return el
			// 	}
			// })
			// currentList[indexUpdatedDevice] = updatedDevice;
			// store.dispatch({
			// 	type: 'GET_devices',
			// 	[key_action]: copyOfObject(currentList)
			// })
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		})
}

function addDevice(data, trackActivation, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/device/' + data.number,
			dataType: "json",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
			data: data.dataForSend,
			params: {trackActivation: trackActivation}
		})
		.then(answer => {
			let device_info = answer.data;
			callback(device_info)
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.device_added_text(data.number)
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function getDevices(params, callback) {
	// let concatedStringParams = '?';
	// for(let i in params){
	// 	concatedStringParams += i + '=' + params[i]+'&'
	// }
	// concatedStringParams += stringParams;
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/devices',
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			async: true,
			params: params,
		})
		.then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function setListsToStore(availableDevices) {
	function dispatchLists(devicesFromTrash) {
		store.dispatch({
			type: 'GET_devices',
			setDevicesData: concatLists(availableDevices, devicesFromTrash)
		})
	}
	return dispatchLists
}

function getDevicesFromTrash(callback) {
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/trash/devices',
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		async: true
	};
	axios(requestData).then( answer => callback(answer.data) ).catch( serveranswer => bugReport(serveranswer, store) );

}
function requestToDevice(data) {
	axios({
			baseURL: infoStore().base_url_port,
			url: infoStore().bonus_url_device + 'device/' + data.serial + '/command',
			dataType: "string",
			method: 'POST',
			headers: { 'authorization': infoStore().token }, //пока не поддерживается
			data: data.command,
		})
		.then(function answer(answer) {
			console.log("%c requestToDevice answer", 'color: green; font-weight: bold;', answer)
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function getCommandsHistory(deviceSerial, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + `vehicle/device/${deviceSerial}/commands/history`,
			dataType: "string",
			method: 'GET',
			headers: { 'authorization': infoStore().token }, //пока не поддерживается
		})
		.then((answer)=>{
			store.dispatch({
				type: 		'COMMAND_HISTORY',
				setHistory:  answer.data
			})
			callback()
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function disconectDevice(deviceSerial) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + '/vehicle/device/' + deviceSerial + '/commands/device-disconnect',
			dataType: "string",
			method: 'GET',
			headers: { 'authorization': infoStore().token }, //пока не поддерживается
		})
		.then(function answer(answer) {
			setText(l_v.connection_reset, answer.status)
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function changeEnableStatus(deviceSerial, status, callback, name) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/device/' + deviceSerial + '/commands/device-on',
			dataType: "string",
			method: 'GET',
			headers: { 'authorization': infoStore().token }, //пока не поддерживается
			params: { deviceOn: status }
		})
		.then((answer) => {
			if(!!callback) {
				callback(answer.data, name)
				setText(l_v.device_status_updated, answer.status)
			}
		})
		.catch((answer) => {
			bugReport(answer, store)
		});
}

function changeIgnitionIgnore(deviceSerial, status, callback, name) {//infoStore().bonus_url_device +
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_api + 'vehicle/device/' + deviceSerial + '/commands/always-ignition-on',
			dataType: "string",
			method: 'GET',
			headers: { 'authorization': infoStore().token }, //пока не поддерживается
			params: { alwaysIgnitionOn: status }
		})
		.then(function answer(answer) {
			console.log("%c changeIgnitionIgnore answer", 'color: green; font-weight: bold;', answer)
			if(!!callback) {
				callback(answer.data, name)
				setText(l_v.status_for_ignition_always_on_changed, answer.status)
			}
		})
		.catch(function(answer) {
			if(!!callback) {
				callback(answer.data, name)
			}
			bugReport(answer, store)
		});
}

function updateDevice(deviceInfo, callback){
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/device',
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		async: true,
		data: {...deviceInfo}
	}
	axios( requestData )
	.then( answer => callback(answer.data) )
	.catch( serveranswer => bugReport(serveranswer, store) );
}

function deleteDeviceFromServer(deviceSerial, callback) {
	let requestData = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/device/' + deviceSerial,
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
			async: true,
		}
		axios( requestData )
		.then( answer => callback(answer.data) )
		.catch( serveranswer => bugReport(serveranswer, store) );
}

function upateStateFromSocket(newState) {
	console.log("%c UPATESTATEFROMSOCKET NEWSTATE", coCSS, newState)
	store.dispatch({
		type: 'NEWSTATE',
		updateNewState: copyOfObject(newState)
	})
}
// alert('from here you need add new device status to transport page')
export function setDeviceSettingsDTO(deviceSettingsDTO) {
	store.dispatch({
		type: 'SET_DEVICESETTINGSDTO',
		setDeviceSettingsDTO: copyOfObject(deviceSettingsDTO)
	})
}

function setNewConnectStatus(newStatus) {
	store.dispatch({
		type: 'SET_NEW_CONNECTED_STATUS',
		setNewStatus: newStatus
	})
}

function setNewHW_and_SW_versions(newInfo) {
	store.dispatch({
		type: 'SET_NEW_HW_SW_VERSIONS',
		setNew: newInfo
	})
}

function saveNewAuthCode(newInfo) {
	store.dispatch({
		type: 'SET_NEW_AUTH_CODE',
		setNewCode: newInfo
	})
}

function getIgnoredDevices(callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/devices',
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			async: true,
		})
		.then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function addToIgnoredDevices(deviceSerialsArray, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/devices',
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			async: true,
			data: deviceSerialsArray
		})
		.then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function removeFromIgnoredDevices(idInIgnoredList, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/device',
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
			async: true,
			params: {id: idInIgnoredList}
		})
		.then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function getIgnoredDevice(deviceSerial, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/devices',
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			async: true,
			params: {deviceId: deviceSerial}
		})
		.then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function removeDataFromCassandra(periodForRemove, deviceSerial, callback, onError) {
	
	axios({
				baseURL: infoStore().base_url,
				url: infoStore().bonus_url_device + 'data-device/'+deviceSerial,
				method: 'DELETE',
				headers: { 'authorization': infoStore().token },
				async: true,
				params: periodForRemove
			})
			.then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
			.catch(serveranswer => {
				onError()
				bugReport(serveranswer, store)
			});
}

function getDeviceHistory(deviceSerial, callback) {
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'device/device-mapping-history',
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		params: {deviceId: deviceSerial}
	}
	axios(requestData).then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
			.catch(serveranswer => bugReport(serveranswer, store));
}

function getDeviceId(deviceType, callback) {
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'device/next-device-id',
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		params: {deviceType: deviceType}
	}
	axios(requestData).then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
			.catch(serveranswer => bugReport(serveranswer, store));
}

function removeDataBySensorNumber(deviceSerial, params, callback, onError) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'sensor-data-device/'+deviceSerial,
		method: 'DELETE',
		headers: { 'authorization': infoStore().token },
		async: true,
		params: params
	})
	.then( answer => callback(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
	.catch(serveranswer => {
		onError()
		bugReport(serveranswer, store)
	});
}

function upateSpeedByPeriod({params, onOk, onError}) {
	console.log(`%c params`, "color: blue; font-weight: 400; text-transform:uppercase", params)
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'device/gps-data',
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		async: true,
		params: params
	}
	axios(requestData)
	.then( answer => onOk(answer.data))//getDevicesFromTrash( setListsToStore(answer.data) ))
	.catch(serveranswer => {
		onError()
		bugReport(serveranswer, store)
	});
}

