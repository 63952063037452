import React, {Fragment} 				from 'react'
import Calibration 						from 'actions/calibrations.js'
import {deleteImg, editImg, closeImg} 	from '../img.jsx'
import {setCopy} 						from '../../actions/small_actions.jsx'
import TooltipWrapper 					from '../component/TooltipWrapper.jsx'
import CalibrationForm 					from './CalibrationForm.jsx'
import AddCalibration 					from './AddCalibration.jsx'
import ConfirmRemove 					from 'modules/ConfirmRemove.jsx'
import SeparPermission                	from 'modules/component/SeparPermission.jsx'
import l_v                  			from "store/languages.jsx"
import SL 								from "sl-library"
import {HigLightContext, FilterButtonInRow} 							from "modules/transportPage/HighLighterOfRows.js"

export default class Calibrations extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			calibrationsList:    null,
			frozenList:          null,
			column_height:       '',
			win_width:           '',
			table_content_width: '',
			editing_data:        undefined,
		}
		this.initialState = this.state;
	}

	componentWillMount() {
		const {updateListInChild, isArchiveCalibrations, vehicleId} = this.props;
		if(vehicleId) {
			Calibration.getIndividual(vehicleId, this.setCalibrations);
		}
		if(isArchiveCalibrations) {
			// Calibration.getGlobal(this.setCalibrations);
			Calibration.getArchive('', this.setCalibrations);

		}
		//и так сойдёт))
		//https://www.google.com/imgres?imgurl=https://i.pinimg.com/originals/3b/d2/3b/3bd23be73a9ff93459a08e36ec878b66.png&imgrefurl=
		typeof updateListInChild == "function" ? this.props.updateListInChild(this.createCalibration) : null
	}
	componentDidMount() {
		this.mount = true
	}
	componentWillUnmount() {
		this.mount = false
	}
	setCalibrations = (calibrationsList) => {
		window.myHotCall({removeCalibrations: removeAllCalibrationFromHere(calibrationsList)})
		if(this.mount){
			this.setState({
				calibrationsList: calibrationsList,
				frozenList: calibrationsList,
			});
			Calibration.updateStoreThisCalibrations(calibrationsList)
		}
	}
	setAfterFilter = (calibrationsList) => { //this data retured from parent fater handleFilter
		this.setState({
			calibrationsList: calibrationsList,
		});
	}
	callConfirmDelete = (id) => this.setState({idForDelete: id}, this.confirmDelete?this.confirmDelete.open:null)
	deleteC = (id) => {
		const {idForDelete} = this.state;
		let type;
		if(!!this.props.vehicleId) {
			type = 'custom'
		} else {
			type = 'global'
		}
		let updateListAfterDel = (removedId) => {
			let currentList = this.state.frozenList;
			let indexForDelete = currentList.findIndex(key => {
				if(key.id == removedId) {
					return key
				}
			})
			currentList.splice(indexForDelete, 1)
			this.setState({
				calibrationsList: currentList,
				frozenList: currentList,
				idForDelete: null
			});
		}
		Calibration.deleteThis(idForDelete, type, updateListAfterDel)
	}
	editC = (id, index) => {
		let editing_data = this.state.calibrationsList[index];
		const {isArchiveCalibrations} = this.props;
		if(isArchiveCalibrations){

		}
		if(editing_data.id == id) {
			this.setState({ editing_data: editing_data });
			this.calibrationForm.activation()
		} else {
			console.log('some problem')
		}
	}
	callCopy = (e) => {
		const index = e.currentTarget.value;
		const calibrationDto = this.state.calibrationsList[e.currentTarget.value]
		// const copyInfo = {
		// 	calibrationTable: calibrationDto.calibrationTable,
		// 	max_value:        calibrationDto.maxValue,
		// 	min_value:        calibrationDto.minValue,
		// 	name:             calibrationDto.name,
		// 	scale:            calibrationDto.scale,
		// 	offset:           calibrationDto.offset,
		// 	sensorKind:       calibrationDto.sensorKind,

		// }
		setCopy(calibrationDto)
	}
	setwidth = (data) => {
		let head_line = data.head_line / data.table * 100;
		let win_width = window.innerWidth;
		let table_content = 100 - head_line;
		if(win_width < 720) {
			this.setState({
				column_height: data.column_height + 1,
				table_content_width: table_content,
			})
		}
		this.setState({
			win_width: win_width,
		})
	}
	scroolTop = () => {
		let height = this.state.column_height;
		let name = this.table_content;
		name.scrollTop -= height;
	}
	scroolBottom = () => {
		let height = this.state.column_height;
		let name = this.table_content;
	}
	updateCalibration = (calibrationInfo) => {
		let updateCalibration = (calibrationInfo)=>{
			let {frozenList, calibrationsList} = this.state;
			this.setState({
				calibrationsList: 	updateCalibrationList(calibrationsList, calibrationInfo),
				frozenList: 		updateCalibrationList(frozenList, calibrationInfo),
			});
		}
		Calibration.update(calibrationInfo, updateCalibration)
	}
	createCalibration = (calibrationInfo) => {
		let updateCalibrationsList = (newCalibration) => {
			let list = this.state.calibrationsList;
			list.push(newCalibration)
			this.setState({
				calibrationsList: list
			});
		}
		Calibration.addIndividual(this.props.vehicleId, calibrationInfo, updateCalibrationsList)
		/*if(!!this.props.isArchiveCalibrations) {
			Calibration.addGlobal(calibrationInfo, updateCalibrationsList)
		} else {
			Calibration.addIndividual(this.props.vehicleId, calibrationInfo, updateCalibrationsList)
		}*/
	}
	render(){
		const {isArchiveCalibrations} = this.props;
		return (
		    <Fragment>
				<div className={'existings_sensorType Mshow'} id = 'sensor_types'>

					{	!isArchiveCalibrations ?
						<Fragment>
	 						<p className="nameForm">{l_v.calibrations}</p>
	 						<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
	 						      				kindCapability = 'editSensors'>
		 						<AddCalibration createCalibration = 	{this.createCalibration}
		 										mountPlace = 			{this.props.mountPlace}
		 										isArchiveCalibrations = {this.props.isArchiveCalibrations}/>
							</SeparPermission>
	 					</Fragment> :
						null
					}
					<ListOfCalibrations calibrationsList = 		{this.state.calibrationsList}
										additinalCells = 		{this.props.additinalCells}
										isArchiveCalibrations = 			{this.props.isArchiveCalibrations}
										additinalHeadCells = 	{this.props.additinalHeadCells}
										deleteC = 				{this.callConfirmDelete}
										column_height = 		{this.state.column_height}
										table_content_width = 	{this.state.table_content_width}
										setwidth = 				{this.setwidth}
										scroolTop = 			{this.scroolTop}
										callCopy = 				{this.callCopy}
										scroolBottom = 			{this.scroolBottom}
										setTableContent = 		{table_content => this.table_content = table_content}
										editC = 				{this.editC}/>

					<CalibrationForm 	{...this.state.editing_data}
										isArchiveCalibrations = { isArchiveCalibrations }
										vehicleId = 			{ this.props.vehicleId }
										sendCalibrationInfo = 	{ this.updateCalibration }
										createCalibration =  	{ this.createCalibration }
										ref = {calibrationForm => this.calibrationForm = calibrationForm}/>

				</div>
				<ConfirmRemove 	ref = 			{confirmDelete => this.confirmDelete = confirmDelete}
								confirmText = 	{'Вы уверены, что хотите удалить калибровку?' }
								clear = 		{()=>this.setState({ idForDelete: null })}
								remove = 		{this.deleteC}/>
			</Fragment>
		)
	}
}


function ListOfCalibrations(props){
	const {
		calibrationsList,
		setwidth,
		scroolTop,
		deleteC,
		editC,
		column_height,
		callCopy,
		scroolBottom,
		table_content_width,
		setTableContent,
		additinalCells,
		additinalHeadCells,
		isArchiveCalibrations
	} = props;
	if(!!SL.arrayIsEmpty(calibrationsList)){
		return 	<div className = "devicelist">
					{/*	<div 	className =	'triggerUp visible_on_mobile'
							 	onClick =	{scroolTop}>
							▲
						</div>
						<div 	className =	'triggerDown visible_on_mobile'
								onClick =	{scroolBottom}>
							▼
						</div>*/}
						<div className = "head_line oneLine">
											<div className = 'cell'> Id</div>
											{additinalHeadCells ? additinalHeadCells : null}
											{
												isArchiveCalibrations ?
												null :
												<div className = 'cell'>{l_v.name}</div>
											}
											<div className = 'cell'> {l_v.coefficient}</div>
											<div className = 'cell'> {l_v.bias}</div>
											{
												isArchiveCalibrations ?
												null :
												<div className = 'cell'> {l_v.model_fuel_sensor}</div>
											}
											<div className = 'cell'> {l_v.minimum_value}</div>
											<div className = 'cell'> {l_v.maximum_value}</div>
											<div className = 'cell'> {l_v.calibration}</div>
											<div className = 'cell_copy cell'> </div>
											<div className = 'cell_edit cell'> </div>
											<div className = 'cell_delete cell'> </div>
						</div>
						<div className = 'table_content' ref = {(table_content)=>setTableContent(table_content)}
								 style ={{
								 			height: column_height+'px',
								 			width: table_content_width + '%'
										}} >
							{
								calibrationsList.map((key, index) =>
		                     <HigLightContext.Consumer key = {index}>
										{
											context => <OneCalibrationLine 	{...key}
																						context = {context}
															                    	additinalCells = {additinalCells}
															                    	key = {index}
															                    	isArchiveCalibrations = {isArchiveCalibrations}
						                     									callCopy = {callCopy}
						                     									index = {index}
						                     									editC = {editC}
						                     									deleteC = {deleteC} />

									
									   }
									</HigLightContext.Consumer>
								)
							}
						</div>
				</div>
	}else{
		return <div className = 'no_data'>{l_v.no_data}</div>
	}
}

function CalibrationInfoBtn(props) {
	return  <div className = 'edit calibrationInfo_i' onClick = {props.onClick}> <i>i</i></div>
}

function OneCalibrationLine(props) {
	const {
			id,
			name,
			scale,
			offset,
			sensorKind,
			min_value,
			minValue,
			max_value,
			maxValue,
			callCopy,
			editC,
			deleteC,
			index,
			calibrationTable,
			positionerCalibrationTable,
			logicalCalibrationTable,
			additinalCells,
			isArchiveCalibrations, context
		} = props;
	let tableView = calibrationForView(calibrationTable);
	/*if(isArchiveCalibrations){
		tableView = <Fragment>
						<div className="shorted_calibration_info">{calibrationTable}</div>
						<div className = 'table_view'> {calibrationTable} </div>
					</Fragment>
	}*/
	if(SL.arrayIsEmpty(positionerCalibrationTable)){
		tableView = calibrationForView(positionerCalibrationTable)
	}
	if(SL.arrayIsEmpty(logicalCalibrationTable)){
		tableView = calibrationForViewReaper(logicalCalibrationTable)
	}
	const lineStyle = !isArchiveCalibrations ? context.CalibrationRow.getStyle(id) : null;
	const onMouseEnter = !isArchiveCalibrations ? e => context.CalibrationRow.onMouseEnter(id) : (e) => null;	
	const onMouseLeave = !isArchiveCalibrations ? e => context.CalibrationRow.onMouseLeave(id) : (e) => null;
	const filterButton = !isArchiveCalibrations ? <FilterButtonInRow 	toogleFilteringConnectedItems = {context.CalibrationRow.toogleFilteringConnectedItems}
																							row_filter_enabled = {context.row_filter_enabled}/> :
																	null;
	return  <div 	className = "oneLine" 
									key = {index}
									style  = {lineStyle}
									onMouseEnter = {onMouseEnter}
									onMouseLeave = {onMouseLeave}>
									<div className = 'cell'> 
										{id}
										{filterButton}
									</div>
									{additinalCells ? additinalCells(props) : null}
									{
										isArchiveCalibrations ?
										null :
										<div className = 'cell'> {name}</div>
									}
									<div className = 'cell'> {scale}</div>
									<div className = 'cell'> {offset}</div>
									{
										isArchiveCalibrations ?
										null :
										<div className = 'cell'> {sensorKind}</div>
									}
									<div className = 'cell'> { isArchiveCalibrations ? minValue : min_value }</div>
									<div className = 'cell'> { isArchiveCalibrations ? maxValue : max_value }</div>
									<div className = 'cell calibration_cell'>{tableView}</div>

										<div className = 'cell_copy cell'>
											<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
											      				kindCapability = 'editSensors'>
												<button 	className = 'copy_btn'
															value = 	{index}
															onClick = 	{callCopy}>
														<i className = 'top_square'></i>
														<i className = 'bottom_square'></i>
												</button>
											</SeparPermission>
										</div>
										<div className = 'cell_edit cell'>
											{
												isArchiveCalibrations ?
												<CalibrationInfoBtn 	onClick = {()=>editC(id, index)}/> :
												<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
					 						      					kindCapability = 'editSensors'>
						 						    {editImg('edit', ()=>editC(id, index))}
												</SeparPermission>
											}
										</div>
										<div className = 'cell_delete cell'>
											<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
				 						      					kindCapability = {['editSensors', 'deleteSensorTypes']}>
												{deleteImg('delete', ()=>deleteC(id))}
											</SeparPermission>
										</div>

							</div>
}

function calibrationForView(calibrationTable) {
	let calValues, stringView;
	if(SL.arrayIsEmpty(calibrationTable)) {
		calValues = calibrationTable.map((key, index) => {
			return <p key = {index}>{`${key.orig_value} ${key.result_value}`}</p>;
		})
		stringView = calibrationTable.map((key, index) => `\n${key.orig_value} ${key.result_value}`)
	} else {
		calValues = "-";
	}
	if(typeof calibrationTable == 'string' && calibrationTable.length){
		let temp = calibrationTable.split('\n');
		calValues = temp.map((key, index) => <p key = {index}>{key}</p>)
		stringView = calibrationTable;
	}

	return 	<Fragment>
					<div className="shorted_calibration_info" title = {stringView}>{stringView}</div>
					{/*<div className = 'table_view'> {calValues} </div>*/}
				</Fragment>
}

function calibrationForViewPositioner(calibrationTable) {
	let calValues, stringView;
	if(SL.arrayIsEmpty(calibrationTable)) {
		calValues = calibrationTable.map((key, index) => {
			return <p key = {index}>{key.orig_value + '\t' + key.result_value + '\t' + key.mask}</p>;
		})
		stringView = calibrationTable.map((key, index) => {
			return key.orig_value + '\t' + key.result_value + '\t' + key.mask + '\n';
		})
	} else {
		calValues = "-";
	}
	return <Fragment>
				<div className="shorted_calibration_info" title = {stringView}>{stringView}</div>
				{/*<div className = 'table_view'> {calValues} </div>*/}
			</Fragment>
}

function calibrationForViewReaper(calibrationTable) {
	let calValues, stringView;
	if(SL.arrayIsEmpty(calibrationTable)) {
		calValues = calibrationTable.map((key, index) => {
			return <p key = {index}>{key.min_value + '\t' + key.max_value}</p>;
		})
		stringView = calibrationTable.map((key, index) => {
			return key.min_value + '\t' + key.max_value + '\n';
		})
	} else {
		calValues = "-";
	}
	return <Fragment>
				<div className="shorted_calibration_info" title = {stringView} >{stringView}</div>
				{/*<div className = 'table_view'> {calValues} </div>*/}
			</Fragment>
}

function updateCalibrationList(calibrationsList, newCalibration) {
	let indexForUpdate = calibrationsList.findIndex(key => {
		if(key.id == newCalibration.id) {
			return key
		}
	})
	let newList = JSON.parse(JSON.stringify(calibrationsList))
	newList[indexForUpdate] = newCalibration;
	return newList;
}

function removeAllCalibrationFromHere(calibrationsList) {
	const action = () => calibrationsList.forEach(key => Calibration.deleteThis(key.id, 'custom', null))
	return action
}