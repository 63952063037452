import store 		from 'store/configureStore.jsx'
import axios 		from 'axios'
import bugReport 	from './bugReport.jsx'
import {ifNoKey} 	from './ActionCreator.jsx'
import infoStore			from './infoStore.js'
import devicesApi 	from './devicesApi.js'
import l_v 				from "store/languages.jsx"

const deviceOnTransportApi = {
	getDeviceInfo: 				getDeviceInfo,
	getAuthorizationCode: 		getAuthorizationCode,
	getIntervalList: 				getIntervalList,
	mountDevice: 					mountDevice,
	unmountDevice: 				unmountDevice,
	updateDevicePeriod:     	updateDevicePeriod,
	addSensor_for_Device: 		addSensor_for_Device,
	removeSensor_from_Device: 	removeSensor_from_Device,
	removeIntervalFromVehicle: 	removeIntervalFromVehicle,
}

function getDeviceInfo(serial, callback){
	axios({
		baseURL: 	infoStore().base_url,
		url: 		infoStore().admin_prefix + 'vehicle/' + 'device/'+serial,
		method: 	'GET',
		headers: 	{'authorization': infoStore().token},
	})
	.then( answer => {
			store.dispatch({
        		type: 'SET_DEVICE_INFO',
        		setInfo: answer.data
			});
			if(!!callback) callback(answer.data)
    })
    .catch( serveranswer => bugReport (serveranswer, store, 'getDeviceInfo') );
}

function getAuthorizationCode(deviceId, callback){
	axios({
		baseURL: 	infoStore().base_url,
		url: 		infoStore().admin_prefix  + 'device/authorization-code/'+ deviceId,
		method: 	'GET',
		headers: 	{'authorization': infoStore().token},
	})
	.then(function answer(answer){
		if(!!callback){
			callback(answer.data)
		}
    })
    .catch(function(serveranswer) {
    	if(ifNoKey(serveranswer, "status") !== 404){
    		bugReport (serveranswer, store, 'getAuthorizationCode')
    	}else{
    		if(!!callback){
				callback(ifNoKey(serveranswer, "data"))
			}
    	}
	});
}

function getIntervalList(data){
	axios({
		baseURL: 	infoStore().base_url,
		url: 		infoStore().admin_prefix + 'vehicle/'+data + '/data',
		method: 	'GET',
		headers: 	{'authorization': infoStore().token},
	})
	.then(function answer(answer){
			store.dispatch({
        		type: 'GET_deviceVStransport_connect',
        		setDeviceVStransport_connect: answer.data
			})
    })
    .catch(function(serveranswer) {
    		bugReport (serveranswer, store, 'getIntervalList')
	});
}

function mountDevice({requestInfo, isGasStation, callbackOnOk, callbackOnError}){
	axios({
		baseURL: 	infoStore().base_url,
		url: 			`${ infoStore().admin_prefix }${ isGasStation ? 'gas-station' : 'vehicle' }/${ requestInfo.vehicleId }/data`,		
		method: 		'PUT',
		headers: 	{'authorization': infoStore().token},
		params: 		{ createPreviousVirtualSensors: requestInfo.copyVirtualSensors },
		data: 		requestInfo.data,
	})
	.then(function answer(answer){
		let currentdata = infoStore('devicesR.DeviceVStransport_connect');
		let newdata = currentdata.concat(answer.data);
		store.dispatch({
    		type: 'GET_deviceVStransport_connect',
    		setDeviceVStransport_connect: newdata
		})
		store.dispatch({
			type: 'STATUS_REQUEST',
			setAnswer: answer.status,
			settextanswer: l_v.information_was_updated
		})
		callbackOnOk()
    })
    .catch(function(error) {
		callbackOnError()
    	bugReport(error, store, 'mountDevice')
	});
}

function unmountDevice({ requestInfo, isGasStation, callbackOnOk, callbackOnError }) {
	axios({
		baseURL: 	infoStore().base_url,
		url: 			`${infoStore().admin_prefix}${ isGasStation ? 'gas-station' : 'vehicle' }/${requestInfo.idvehicle}/data/${requestInfo.timeId}`,
		method: 		'DELETE',
		headers: 	{'authorization': infoStore().token},
	})
	.then(function answer(answer){
		let currentdata = infoStore('devicesR.DeviceVStransport_connect');
		let index;
		let newdata = [];
		currentdata.map((key) =>{
			if(key.id == answer.data.dataId){
					index = currentdata.indexOf(key);
			}
		})
		currentdata.splice(index, 1)
		newdata = newdata.concat(currentdata);
		store.dispatch({
    		type: 'GET_deviceVStransport_connect',
    		setDeviceVStransport_connect: newdata
		})
		store.dispatch({
			type: 'STATUS_REQUEST',
			setAnswer: answer.status,
			settextanswer: l_v.device_removed_from_transport
		})
		callbackOnOk()
    })
    .catch(function(error) {
    	callbackOnError();
    	bugReport(error, store, 'unmountDevice')
	});
}

function updateDevicePeriod({requestInfo, callbackOnOk, callbackOnError, isGasStation}) {
	axios({
		baseURL: 	infoStore().base_url,
		url: 			`${ infoStore().admin_prefix }${ isGasStation ? 'gas-station' : 'vehicle' }/${ requestInfo.idvehicle }/data/${ requestInfo.timeId }`,
		method: 		'POST',
		headers: 	{'authorization': infoStore().token},
		data: 		requestInfo.forsend
	})
	.then( answer => {
			let currentdata = infoStore('devicesR.DeviceVStransport_connect');
			let dispatch_data = [];
			let newdata = currentdata.map(function(key){
				if (key.id == answer.data.id){
					key.from = answer.data.from;
					key.to = answer.data.to;
				}
				return key
			})
			dispatch_data = dispatch_data.concat(newdata);
			store.dispatch({
        		type: 'GET_deviceVStransport_connect',
        		setDeviceVStransport_connect: dispatch_data
			});
			store.dispatch({
					type: 'STATUS_REQUEST',
					setAnswer: answer.status,
					settextanswer: l_v.time_period_updated
			});
			callbackOnOk();
    })
    .catch(error => {
    		bugReport(error, store, 'updateDevicePeriod')
    		callbackOnError();
	});
}


function removeIntervalFromVehicle({requestInfo, callbackOnOk, callbackOnError, isGasStation}) {
	axios({
		baseURL: 	infoStore().base_url,
		url: 			`${infoStore().admin_prefix}${ isGasStation ? 'gas-station' : 'vehicle' }/${requestInfo.vehicleId}/data/${requestInfo.timeId}`,
		method: 		'DELETE',
		headers: 	{'authorization': infoStore().token},
	})
	.then(function answer(answer){
			let dispatch_data = [];
			let list = infoStore('devicesR.DeviceVStransport_connect');
			list.map((key, index)=>{
				if(key.id == answer.timeId){
					list.splice(index, 1)
				}
			})
			dispatch_data = dispatch_data.concat(list);
			store.dispatch({
        		type: 'GET_deviceVStransport_connect',
        		setDeviceVStransport_connect: dispatch_data
			})
			store.dispatch({
					type: 'STATUS_REQUEST',
					setAnswer: answer.status,
					settextanswer: l_v.list_updated
			})
			callbackOnOk();
    })
    .catch(function(error) {
    	callbackOnError();
    	bugReport(error, store, 'removeIntervalFromVehicle')
	});
}

function addSensor_for_Device(data, callback){
	axios({
		baseURL: 	infoStore().base_url,
		url: 			infoStore().admin_prefix +'vehicle/'+data.idvehicle+'/data/'+data.timeId+'/sensor/'+data.sensorId,
		method: 		'PUT',
		headers: 	{'authorization': token},
		data: 		data.forsend
	})
	.then(function answer(answer){
			let dispatch_data = [];
			let list = infoStore('devicesR.DeviceVStransport_connect');
			list.map(key=>{
				if(key.id == data.timeId){
					let removedId = answer.data.sensorId;
					key.sensors.push(answer.data);
				}
			})
			dispatch_data = dispatch_data.concat(list);
			store.dispatch({
        		type: 'GET_deviceVStransport_connect',
        		setDeviceVStransport_connect: dispatch_data
			})
			store.dispatch({
					type: 'STATUS_REQUEST',
					setAnswer: answer.status,
					settextanswer: l_v.list_updated
			})
			if(!!callback){
				data.status = l_v.added_to_device;
				callback(data)
			}
    })
    .catch(function(error) {
    	   bugReport(error, store, 'addSensor_for_Device')
    	   if(!!callback){
				data.status = l_v.failed_to_add_a_sensor_to_the_device;
				callback(data)
			}
	});
}

function removeSensor_from_Device(data){
	axios({
		baseURL: 	infoStore().base_url,
		url: 		infoStore().admin_prefix +'vehicle/'+data.idvehicle+'/data/'+data.timeId+'/sensor/'+data.sensorId,
		method: 'DELETE',
		headers: {'authorization': infoStore().token},
	})
	.then(function answer(answer){
			let dispatch_data = [];
			let list = infoStore('devicesR.DeviceVStransport_connect');
			list.map(key=>{
				if(key.id == data.timeId){
					let removedId = answer.data.sensorId;
					let sensors = key.sensors;
					let forRemove;
					if(Array.isArray(sensors)){
						sensors.map((key, index)=>{
							if(key.id == removedId){
								forRemove = index
							}
						})
						key.sensors.splice(forRemove, 1);
					}
				}
			})
			dispatch_data = dispatch_data.concat(list);
			store.dispatch({
        		type: 'GET_deviceVStransport_connect',
        		setDeviceVStransport_connect: dispatch_data
			})
			store.dispatch({
					type: 'STATUS_REQUEST',
					setAnswer: answer.status,
					settextanswer: l_v.list_updated
			})
    })
    .catch(function(error) {
    	bugReport(error, store, 'removeSensor_from_Device')
	});
}


export default deviceOnTransportApi;

// /admin/vehicle/{id}/data -> /admin/gas-station/{id}/data GET
// /admin/vehicle/{id}/data -> /admin/gas-station/{id}/data PUT
/*/admin/vehicle/data -> /admin/gas-station/data PUT
/admin/vehicle/{id}/data/{dataId} -> /admin/gas-station/{id}/data/{dataId} POST
/admin/vehicle/{id}/data/{dataId} -> /admin/gas-station/{id}/data/{dataId} DELETE*/