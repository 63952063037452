import React 								from "react"
import {connect} 								from 'react-redux'
import SwitchInput 							from '../component/SwitchInput.jsx'
import devicesApi 							from '../../actions/devicesApi.js'
import {ifNoKey, arrayIsEmpty} 					from '../../actions/ActionCreator.jsx'
import l_v 									from 'store/languages.jsx'

function DeviceSettings(props) {
	let {deviceSerial, current_deviceSettingsDto} = props;
	let deviceOff = false, deviceOn = false, alwaysIgnitionOn = false;
	let stateCurrentDevice;
	if(deviceSerial == ifNoKey(current_deviceSettingsDto, 'deviceSerial')){
		deviceOff = ifNoKey(current_deviceSettingsDto, 'deviceOff')
		deviceOn = ifNoKey(current_deviceSettingsDto, 'deviceOn')
		alwaysIgnitionOn = ifNoKey(current_deviceSettingsDto, 'alwaysIgnitionOn');
	}
	const nameF = <p className = 'nameForm'>{l_v.device_settings}</p>;
	const yes_tooltip = !current_deviceSettingsDto ? 'Mapped device not found': l_v.yes;
	const no_tooltip = !current_deviceSettingsDto ? 'Mapped device not found' : l_v.no;
	if(current_deviceSettingsDto == 424){
		return 	<div  className = 'device_settings'>
					{nameF}
					{l_v.no_connection}
				</div>;
	} else {
		return 	<div className = 'device_settings'>
					{nameF}
					<div className = 'profile_container'>
						<div  className = 'editor_contrainer'>
							<span className = "text_info" >
								{l_v.data_reception_on}:
							</span>
							<SwitchInput    isChecked = {deviceOn?deviceOn:false} //boolean
											disabled = {!current_deviceSettingsDto}
						                    result =    {
						                    				(value, name) => devicesApi.changeEnableStatus(deviceSerial, value, saveStatus, name)
						                    			}// onChange, return (value, name)
						                    name =      {'deviceOn'}//name of value
						                    tooltip =   {{on: yes_tooltip, off: no_tooltip}} //text for tooltip
						    />
					    </div>
					    <div  className = 'editor_contrainer'>
								<span className = "text_info">
										{l_v.ugnition_always_on}:
								</span>
								<SwitchInput    isChecked = {alwaysIgnitionOn?alwaysIgnitionOn:false} //boolean
												disabled = {!current_deviceSettingsDto}
									
							                    result =    {
							                    				(value, name) => devicesApi.changeIgnitionIgnore(deviceSerial, value, saveStatus, name)
							                    			}// onChange, return (value, name)
							                    name =      {'alwaysIgnitionOn'}//name of value
							                    tooltip =   {{on: yes_tooltip, off: no_tooltip}} //text for tooltip
							    />
						</div>
					</div>
				</div>
	}

}

function saveStatus(data, name){
	devicesApi.setDeviceSettingsDTO(data)
	devicesApi.upateStateFromSocket(data)
}



export default connect(
      state => ({
		lockState: 	state.devicesR.lockState,
		socket: 	state.authorizationR.socket,
		current_deviceSettingsDto: state.devicesR.current_deviceSettingsDto,
		// stateFromSocket: state.devicesR.stateFromSocket,
      }),
)(DeviceSettings)