import React from "react";
import ReactTooltip from 'react-tooltip';
import SL from 'sl-library';
import PropTypes from 'prop-types';

class SwitchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.isChecked
    };
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isChecked !== this.props.isChecked) {
      setTimeout(() => {
        if (this.mounted) {
          this.setState({ isChecked: this.props.isChecked });
        }
      }, 500);
    }
  }

  render() {
    const { name, tooltip, disabled } = this.props;
    const isChecked = this.state.isChecked;
    const tooltipText = isChecked ? SL.ifNoKey(tooltip, 'on') : SL.ifNoKey(tooltip, 'off');
    return (
      <div className="switch-container"
           data-class='my_tooltip'
           data-tip={true}
           data-for={name}
           data-place='top'
           data-effect='solid'>
        <label>
          <input  checked={typeof isChecked === 'boolean' ? isChecked : false}
                  onChange={e => this.props.result(!isChecked, name)}
                  disabled={disabled}
                  className="switch"
                  type="checkbox" />
          <div>
            <div></div>
          </div>
        </label>
        <ReactTooltip id={name} getContent={[() => tooltipText, 500]} />
      </div>
    );
  }
}

export default SwitchInput;
