import store 			from 'store/configureStore.jsx'
import axios            from 'axios'
import l_v 				from "store/languages.jsx"
import bugReport        from './bugReport.jsx'
import infoStore 	    from './infoStore.js'
import {copyOfObject}   from 'actions/ActionCreator.jsx'

const Users = {
			addUser:                       addUser,
			getUserData:                   getUserData,
			belongsTo:                     belongsTo,
			editPassword:                  editPassword,
			editMessage:                   editMessage,
			editValueOfUser:               editValueOfUser,
			getData:                       getData,
			updateCapability:              updateCapability,
			getCapabilityByRole:           getCapabilityByRole,
			deleteUser:                    deleteUser,
			getSidForAuthInClient:         getSidForAuthInClient,
			getStatistics:                 getStatistics,
			updateCapabilityNewV:          updateCapabilityNewV,
			getDealersGroups:              getDealersGroups,
			getListAvailableCustomReports: getListAvailableCustomReports,
			getSelectedReportsForUser:     getSelectedReportsForUser,
			saveSelectedReportsForUser:    saveSelectedReportsForUser,
			deleteSelectedReportsForUser:  deleteSelectedReportsForUser,
			getIgnoredUsers: 			   getIgnoredUsers,
			addToIgnoredUsers: 			   addToIgnoredUsers,
			removeFromIgnoredUsers: 	   removeFromIgnoredUsers,
			getIgnoredUser: 			   getIgnoredUser,
}
export default Users;




function getIgnoredUsers(callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/users',
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			async: true,
		})
		.then( answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function addToIgnoredUsers(loginsArray, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/users',
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			async: true,
			data: loginsArray
		})
		.then( answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function removeFromIgnoredUsers(idInIgnoredList, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/user',
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
			async: true,
			params: {id: idInIgnoredList}
		})
		.then( answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function getIgnoredUser(deviceSerial, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'ignored/users',
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			async: true,
			params: {deviceId: deviceSerial}
		})
		.then( answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}



function getCapabilityByRole(kindUsed, callback) {
	const requestData = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_user + kindUsed + '/capabilities',//api/v2/hydra-auth/ADMIN/capabilities
			dataType: 'application/json',
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			params:   { locale: infoStore().langUppercase }
		}
	axios(requestData)
		.then((answer) =>callback(answer.data) )
		.catch(serveranswer => bugReport(serveranswer, store) )
}

function getDealersGroups(callback) {
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_user + 'users/dealer-groups',
		dataType: 'application/json',
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then((answer) =>callback(answer.data) )
		.catch(serveranswer => bugReport(serveranswer, store) )
}

export function updateCapability(data, callback) {
	let state = store.getState();
	let login = state.authorizationR.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let bonus_url_user = state.authorizationR.bonus_url_user;
	let marker = data.marker;
	axios({
			baseURL: base_url,
			url: infoStore().bonus_url_api + 'user/' + data.login + data.adddata,
			// url: bonus_url_user + 'user/' + data.login + data.adddata,
			dataType: "application/json",
			method: 'POST',
			headers: { 'authorization': token },
			data: data.capability
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'UPDATE_CAPABILITIES',
				update_username: answer.data.login,
				update_capabilities: answer.data.capabilities,
				update_blocked: answer.data.blocked,
				updatedescription: answer.data.description,
				updateusergroup: answer.data.belongsTo,
				updateuser_expires: answer.data.expires,
			})
			if(!!callback) {
				callback(answer.data.capabilities)
			}
			if(marker == 'clientPage') {
				store.dispatch({
					type: 'CURRENT_USER',
					setThisUser: answer.data
				})
				store.dispatch({
					type: 'STATUS_REQUEST',
					setAnswer: answer.status,
					settextanswer: l_v.block_status_updated
				})
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		})
}

function updateCapabilityNewV(capabilities, login, callback) {
		let requestData = {
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_api + 'user/' + login + '/capabilities',
			dataType: 'application/json',
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			data: capabilities,

		}
	axios(requestData)
		.then((answer) =>callback(answer.data.capabilities) )
		.catch(serveranswer => bugReport(serveranswer, store) )
}

export function addUser(data, callback) {
	let state = store.getState();
	let login = data.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let bonus_url_user = state.authorizationR.bonus_url_user;
	let kindUser = data.kindUser;
	let dispatch_key = 'set' + kindUser + 'Data';
	let currentList = state.clientsR[fuckingRoma(kindUser) + "Data"];
	console.log('data', data)
	axios({
			baseURL: base_url,
			url: infoStore().bonus_url_api + kindUser + '/user/' + login,
			// url: bonus_url_user + kindUser + '/user/' + login,
			dataType: 'application/json',
			method: 'PUT',
			headers: { 'authorization': token },
			data: data.dataForSend
		})
		.then(function answer(answer) {
			callback(answer.data)
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.user_added
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function createProfile(login) {
	let state = store.getState();
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	axios({
			method: 'POST',
			baseURL: base_url, //this was in the start
			url: '/ns/profile',
			dataType: 'application/json', //формат
			withCredentials: true,
			headers: { 'authorization': token },
			data: {
				"ownerLogin": login,
				"interfaceLanguage": "ua",
			},
		})
		.then((answer) => {
			console.log(answer.data)
			store.dispatch({
				type: "USER_INFO",
				setUserInfo: answer.data
			})
		})
		.catch((serveranswer) => console.log(serveranswer))
}

export function getUserData(data) {
	let state = store.getState();
	let login = state.authorizationR.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let bonus_url_user = state.authorizationR.bonus_url_user;
	let clients_info;
	let userLogin = data.userLogin;
	let kindUser = data.kindUser;
	let key_action = 'set' + kindUser + 'Data';
	axios({
			baseURL: base_url,
			// url: bonus_url_user + 'user/' + userLogin,
			url: infoStore().bonus_url_api + 'user/' + userLogin,
			method: 'get',
			headers: { 'authorization': token },
		})
		.then(function answer(answer) {
			// get user permission for display for him data and remove current user from list
			// need transfer it to form on production
			// let this_user_info;
			// clients_info = answer.data.filter(function(key){
			// 	if(key.login !== login){
			// 		return key
			// 	} else if(key.login == login){
			// 		this_user_info = key;
			// 	}
			// })
			store.dispatch({
				type: 'CURRENT_USER',
				setThisUser: answer.data
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}
export function belongsTo(data) {
	let state = store.getState();
	let login = data.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url
	let bonus_url_user = state.authorizationR.bonus_url_user;
	axios({
			baseURL: base_url,
			url: bonus_url_user + 'user/' + data.login + '/belongs-to',
			dataType: 'application/json',
			method: 'POST',
			headers: { 'authorization': token },
			data: data.belongsTo
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.group_updated
			})
			store.dispatch({
				type: 'UPDATE_CAPABILITIES',
				update_username: answer.data.login,
				update_capabilities: answer.data.capabilities,
				update_blocked: answer.data.blocked,
				updatedescription: answer.data.description,
				updateusergroup: answer.data.belongsTo,
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}
export function editPassword(data) {
	let state = store.getState();
	let login = data.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url
	let bonus_url_user = state.authorizationR.bonus_url_user;
	axios({
			baseURL: base_url,
			url: infoStore().bonus_url_api + 'user/' + data.login + '/password',
			// url: bonus_url_user + 'user/' + data.login + '/password',
			dataType: 'application/json',
			method: 'POST',
			headers: { 'authorization': token },
			data: data.password
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.password_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}
export function editMessage(data) {
	let state = store.getState();
	let login = data.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let bonus_url_user = state.authorizationR.bonus_url_user;
	let marker = data.marker;
	console.log(data.description)
	axios({
			baseURL: base_url,
			url: infoStore().bonus_url_api + 'user/' + data.login + '/info',
			// url: bonus_url_user + 'user/' + data.login + '/info',
			dataType: 'application/json',
			method: 'POST',
			headers: { 'authorization': token },
			data: data.description
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.message_updated
			})
			if(marker == 'clientPage') {
				store.dispatch({
					type: 'CURRENT_USER',
					setThisUser: answer.data
				})
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}
export function editValueOfUser(data) {
	let state = store.getState();
	let login = data.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let bonus_url_user = state.authorizationR.bonus_url_user;
	let marker = data.marker;
	console.log(data.newValue)
	axios({
			baseURL: base_url,
			url: infoStore().bonus_url_api + 'user/' + data.login + '/info',
			// url: bonus_url_user + 'user/' + data.login + '/info',
			dataType: 'application/json',
			method: 'POST',
			headers: { 'authorization': token },
			data: data.newValue
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.description_updated
			})
			store.dispatch({
				type: 'UPDATE_CAPABILITIES',
				update_username: answer.data.login,
				update_capabilities: answer.data.capabilities,
				update_blocked: answer.data.blocked,
				updatedescription: answer.data.description,
				updateusergroup: answer.data.belongsTo,
			})
			if(marker == 'clientPage') {
				store.dispatch({
					type: 'CURRENT_USER',
					setThisUser: answer.data
				})
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}
export function getData(kindUser, params, callback) {
	let state = store.getState();
	let login = state.authorizationR.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let bonus_url_user = state.authorizationR.bonus_url_user;
	let clients_info;
	let key_action = 'set' + kindUser + 'Data';
	axios({
			baseURL: base_url,
			url: infoStore().bonus_url_api + kindUser + '/users',
			// url: bonus_url_user + kindUser + '/users',
			method: 'get',
			headers: { 'authorization': token },
			params: params /*{
								page: 0,
								size: 20,
								searchParam: some text
							}*/
		})
		.then(answer => callback?
		      			callback(answer.data):
		      			console.error("SOME PROBLEM in getData, kindUser, params, callback", kindUser, params, callback, answer.data))
		.catch(function(serveranswer) {
			console.log(serveranswer)
			bugReport(serveranswer, store)
		});
}
export function deleteUser(data, callback) {
	let state = store.getState();
	let login = state.authorizationR.login;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let bonus_url_user = state.authorizationR.bonus_url_user;
	let reducerplace = fuckingRoma(data.kindUser) + 'Data';
	let currentList = state.clientsR[reducerplace];
	let key_action = 'set' + data.kindUser + 'Data';
	axios({
			baseURL: base_url,
			url: infoStore().bonus_url_api + 'user/' + data.userlogin,
			// url: bonus_url_user + 'user/' + data.userlogin,
			dataType: 'application/json',
			method: 'DELETE',
			headers: { 'authorization': token },
		})
		.then( answer => callback(data.userlogin) )
		.catch( answer => bugReport(answer, store) )
}

function getSidForAuthInClient(login, callback, callbackOnError) {
	let authInfo = new FormData();
	authInfo.append("login", login);
	let requestData = {
		baseURL: infoStore().base_url,
		url:   infoStore().bonus_url_device + 'auth/login/impersonate',//,
		dataType: 'application/json',
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: authInfo
	}
	axios(requestData)
		.then(answer => callback(answer.headers.authorization, login))
		.catch(serveranswer => {
			bugReport(serveranswer, store);
			if(callbackOnError)callbackOnError()
		})
}

function getStatistics(callback) {
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'admin/statistic',
		dataType: 'application/json',
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => {
			bugReport(serveranswer, store);
			callback(null)
		})
}

function createSuperAdmin(login, password){//not used
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/device/' + data.serialNumber,
			dataType: 'application/json',
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
		})
	.then(function answer(answer){!!callback?callback(answer):null})
	.catch(serveranswer=>bugReport(serveranswer, store))
}

function getListAvailableCustomReports(accountType, callback) {
	// const urlPath = accountType == 'GLOBUS' ? 'custom-report-templates' : 'teres/custom-report-templates'; //vehicles or gas station
	const urlPath = 'custom-report-templates';
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_prefix + urlPath,
		dataType: 'application/json',
		method:   'GET',
		headers:  { 'authorization': infoStore().token },
		params:   { locale: infoStore().langUppercase }
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => {
			bugReport(serveranswer, store);
			callback(null)
		})
}

function getSelectedReportsForUser(userLogin, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'custom-report-templates/' + userLogin,
		dataType: 'application/json',
		method:   'GET',
		headers:  { 'authorization': infoStore().token },
		params:   { locale: infoStore().langUppercase }
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => {
			bugReport(serveranswer, store);
			callback(null)
		})
}

function saveSelectedReportsForUser(userLogin, idsList, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_prefix + 'user/reports/' + userLogin,
		dataType: 'application/json',
		method:   'PUT',
		headers:  { 'authorization': infoStore().token },
		params:   { reportId: idsList.join(',') }
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => {
			bugReport(serveranswer, store);
			callback(null)
		})
}

function deleteSelectedReportsForUser(userLogin, idsList, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_prefix + 'user/reports/' + userLogin,
		dataType: 'application/json',
		method:   'DELETE',
		headers:  { 'authorization': infoStore().token },
		params:   { reportId: idsList.join(',') }
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => {
			bugReport(serveranswer, store);
			callback(null)
		})
}

// curl -X PUT --header 'Content-Type: application/json' --header 'Accept: application/json'
// --header 'authorization: 34c6c7043186272559baa7f220e7f632a35584a24b7ba71da7bc36e8159a68f2' -d
// '{
//    "password": "string",
//    "capabilities": [
//      {
//        "name": "string",
//        "value": true
//      }
//    ],
//    "belongsTo": "string",
//    "description": "string"
//  }'
//  'http://dev.elsyton.com/auth/v1/Admin/user/test2'
function fuckingRoma(value) {
	if(!!value){
		value = value.toLowerCase();
		value = value.replace(value[0], value[0].toUpperCase())
		return value
	}
}