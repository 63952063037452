import React, {Fragment}           from 'react'
import Helmet                      from 'react-helmet';
import Navigation                  from 'modules/AdminNavigation.jsx'
import { connect }                 from 'react-redux'
import { checkPass, removeSpaces } from 'actions/ActionCreator.jsx'
import Users                       from 'actions/users.js'
import language_variant            from "store/languages.jsx"
import { LanguagesController }     from 'modules/AuthForm.jsx';
import l_a                         from "store/languages.jsx"

class AdminPage extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			login: this.props.login,
			password: '',
			password_confirm:  '',
			message: '',
			formError: ''
		}
	}
	enterToState = e => {
		let { value, name }	= e.target;
		if(name == 'password' || name == 'password_confirm'){
			value = removeSpaces(value)
		}
		this.setState({
			[name]: value
		})
		if(e.target.name == "password"){
			if(!checkPass(value)){
				this.setState({
					message: language_variant.newPassMustBe,
					new_pass_error: 'formError',
				})
			}else{
				this.setState({
					message: '',
					new_pass_error: '',
				})
			}
		}
	}
	updatePassword = e => {
		if (!this.state.password || !this.state.password_confirm){
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		}
		else{
				if(checkPass(this.state.password)){
					this.setState({
						message: '',
						pass_error: ''
					})
					Users.editPassword({
							"login": this.state.login,
							"password":{
								"newPassword": this.state.password,
    							"previousPassword": this.state.password_confirm
							}
						})
				}
				else{
					this.setState({
						message: language_variant.newPassMustBe,
						new_pass_error: 'formError'
					})
				}
		}
	}
  	render() {
  		const {pass_error, error_emty_field, formError, new_pass_error, message} = this.state;
  		const langStyles = 	{ 
  								container: baseStyles => ({ ...baseStyles, width: 155, })
							};
    	return  <Fragment>
			      	<Helmet title = {l_a.profile} />
			      	<Navigation />
			      	<div className='container content self_page'>

			       		<h3 className="page_name">{l_a.changing_password_title} <span>{this.props.match.params.userName}</span> </h3>
						<form className='admin_page'>
		       					<div className={'form_message ' + this.state.formError} >
									<p>{message}</p>
								</div>
								<input 	type = 			"text"
										className = 	{ `input ${pass_error} ${error_emty_field}` }
										name = 			'password_confirm'
										placeholder = 	{ `${l_a.current_pass} *` }
										onChange = 		{ this.enterToState }/>
								<input 	type = 			"text"
										name = 			'password'
										className = 	{ `input ${new_pass_error} ${error_emty_field}` }
										placeholder = 	{ `${l_a.new_pass} *` }
										onChange = 		{ this.enterToState }/>

								<button type = 		"button"
										className = 'btn_black'
										onClick = 	{ this.updatePassword }>
										{l_a.change_password}
								</button>
						</form>
			       		<h3 className="page_name">{l_a.interfaceLanguage}</h3>
			       		<LanguagesController styles = { langStyles } onUpdateLanguage = {() => this.forceUpdate()}/>


			      	</div>
			      </Fragment>
  }
}
export default connect(
      state => ({
        login: 		state.authorizationR.login,
        language:   state.languageR.language, //for render on update (not used in component)
		answer: 	state.clientsR.answer,
      }),
)(AdminPage)