import Navigation 				from './AdminNavigation.jsx'
import { Link } 				from 'react-router-dom'
import Helmet               from 'react-helmet';
import React, { useEffect }        from "react";
import l_v             from "store/languages.jsx"

import formAction                              from 'actions/formAction.js'


const NotFound = () => {
    useEffect(() => formAction.checkToken() );
    return 	<div>
			  	<Helmet title = {l_v.page_not_found} />

			  	<Navigation />
			  	<div className='container content NotFound'>
				    <h3>{l_v.page_not_found}</h3>
				    <p>We are sorry but the page you are looking for does not exist.</p>
				    <Link className='link_to_device' to = 'https://www.youtube.com/watch?v=KvHU_3xg1sQ'>Послушай музыку</Link>
			    </div>
			  </div>
}


export default NotFound;