import React 							from 'react'
import {deleteImg, editImg, closeImg} 	from '../img.jsx'
import {ifNoKey, arrayIsEmpty} 			from 'sl-library'
import HintData 						from './HintData.jsx'
import RequireWrapper   				from '../component/RequireWrapper.jsx'
import sensorApi 						from '../../actions/updateSensor.jsx'
import SelectListOfRealSensor 			from './SelectListOfRealSensor.jsx'
import EnterListennerForForm 			from "modules/component/EnterListennerForForm.jsx"
import l_v 				from "store/languages.jsx"

export default class AddMultiSensor extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			deviceSensors:   null,
			virtualSensorId: '',
			aggregate:       'sum', //null,
			addsensor:       false,
			title:           l_v.fuel_level,
		}
		this.initialState = this.state;
		this.requires = {};
		this.availableRealSensors; //list of real sensors. It is need for select items
	}
	componentWillMount() {
		let setRealSensors = (data) => {
			this.availableRealSensors = createNamedList(this.props.virtualSensors, data);
		}
		// sensorApi.getRealSensorsOnPeriod(this.props.periodId, setRealSensors)
	}
	enterToState = (event) => {
		let index = event.target.attributes.getNamedItem('data-index').value;
		let array = this.state.deviceSensors;
		let inputName = event.target.name;
		inputName = inputName.split('_')[0];
		let value = event.target.value;
		if(index !== undefined) {
			array.map(function(key) {
				if(array.indexOf(key) == index) {
					if(!!value.length) {
						key[inputName] = Number(value);
					} else {
						key[inputName] = '';
					}
				}
				return key
			})
			this.setState({
				deviceSensors: array
			})
		} else {
			this.setState({
				[event.target.name]: event.target.value
			})
		}
	}
	enterToState_noindex = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}
	addFieldSensor = () => { //for old api, will remove later
		let pushfield = {
			num: '',
			typeId: '',
		}
		let array = this.state.deviceSensors.slice();
		array.push(pushfield);
		this.setState({
			deviceSensors: array,
			check: false,
		})
	}
	removeField = (e) => { //for old api, will remove later
		let array = this.state.deviceSensors;
		// delete array[e.target.name];
		array.splice([e.target.name], 1)
		array.splice(name, 1)
		delete this.requires['num_' + name];
		this.setState({
			array: array,
		})
	}
	hint_enter_to_state = (data) => {
		this.setState({
			[data.name]: data.value,
		})
	}
	use_hint = (data) => {
		let index = data.index;
		let array = this.state.deviceSensors;
		let inputName = data.name;
		let value = data.value;
		if(index !== undefined) {
			array.map(function(key) {
				if(array.indexOf(key) == index) {
					key[inputName] = value;
				}
				return key
			})
			this.setState({
				deviceSensors: array
			})
		}
	}
	addSensor_for_Device = () =>{
		let data = {
		    aggregate:      		this.state.aggregate,
		    sensorId: 				this.state.virtualSensorId,
		    realSensorSocketIds:  	this.state.deviceSensors,
		    title: 					this.state.title,
		};
		sensorApi.createMultiSensor(this.props.vehicleId, data, this.props.addMultiSensor)
		this.setState(this.initialState);
	}
	verifyRequire = () =>{
		this.setState({
			check: true,
		});
		let validate = true;
		let name, real = true;
		let boxReal = document.getElementById('realSensorList');
		if(!arrayIsEmpty(this.state.deviceSensors)){
			real =  false;
			boxReal.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
		}
		if(!this.state.aggregate){
				validate = false
				this.radioBtn.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
		}
		if(!!validate && !!real){
			this.radioBtn.style.boxShadow  = 'none';
			boxReal.style.boxShadow = 'none';
			this.setState({
					message: 	'',
					check: 		false,
			})
			this.addSensor_for_Device()
		}else{
			if(!real){
				this.setState({
					message: l_v.choose_real_sensors,
				})
			}else{
				this.setState({
					message: `${l_v.fill_marked_fields}`,
				})
			}

		}
	}
	requireElem = (name, value) =>{
		this.requires[name] = value;
	}
	selectedSensorToState = (list) =>{
		let listForApi = [];
		if(arrayIsEmpty(list)){
			list.forEach(key=>{
				listForApi.push(key.id)
			})
		}
		this.setState({deviceSensors: listForApi});
	}
	render(){
		const {aggregate} = this.state;
		if(!this.state.addsensor){
			return 	<div className = "new_sensor_mapping">
						<p className = 'nameForm'>{l_v.multisensors}</p>
						<button className = "btn_plus"
								onClick = 	{()=>this.setState({addsensor: true})}>
								+
						</button>
					</div>

		}else{
			return 	<EnterListennerForForm onPressEnter = { this.verifyRequire }>
						<div className = "new_sensor_mapping">
							<p className = 'nameForm'>{l_v.multisensors}</p>
							<div className='sensor_mapping'>
								<p className = 'nameForm'>{l_v.multisensor_add}</p>
								<HintData 	index = 		'no_index'
										  	name = 			'virtualSensorId'
										  	data = 			{this.props.virtualSensors}
										  	value = 		{this.state.virtualSensorId}
										  	check = 		{this.state.check}
										  	returnData= 	{this.hint_enter_to_state}
										  	placeholder = 	{l_v.enter_virtual_sensor_id}/>
								<input 	type = 			'text'
										className = 	'input'
										name = 			'title'
										placeholder = 	{l_v.multisensor_name}
										value = 		{this.state.title}
										onChange  = 	{this.enterToState_noindex} />
								<SelectListOfRealSensor list = 					{this.props.realSensorSockets}
														returnSelectedItem = 	{this.selectedSensorToState}/>
								<div className = 'aggregate' ref = {(radioBtn)=>this.radioBtn = radioBtn}>
									    <p className = 'name_form_block'>{l_v.operations_sensors_data}</p>
										<p className = "radioP" >
											<input 	type = 		"radio"
													name =		{"aggregate"}
													value =		"sum"
													checked = 	{aggregate == 'sum'}
													onClick = 	{this.enterToState_noindex}
													id =		{"sum"}/>
											<label 	htmlFor = {'sum'} >{l_v.summ}</label>
										</p>
										<p className = "radioP">
											<input 	type =		"radio"
													name =		{"aggregate"}
													value =		"minus"
													checked = 	{aggregate == 'minus'}
													onClick = 	{this.enterToState_noindex}
													id =		{'minus'}/>
											<label htmlFor = {'minus'} >{l_v.subtract}</label>
										</p>
										<p className = "radioP">
											<input 	type =		"radio"
													name =		{"aggregate"}
													value =		"average"
													checked = 	{aggregate == 'average'}
													onClick = 	{this.enterToState_noindex}
													id =		{'average'}/>
											<label htmlFor = {"average"}>{l_v.average}</label>
										</p>
								</div>
								<button type = "button"
										className = "btn_black"
										onClick = {()=>this.setState({addsensor: false})}>
										{l_v.cancel}
								</button>
								<button type="button"
										className="btn_black addsensor"
										onClick = {this.verifyRequire}>
										{l_v.save_multisensor}
								</button>
							</div>

						</div>
					</EnterListennerForForm>

		}
	}
}
function createNamedList(virtualList, realList){
	if(arrayIsEmpty(realList) && arrayIsEmpty(virtualList)){
		realList.forEach(key=>{
			virtualList.forEach(virt=>{
				if(key.virtualSensorId == virt.virtualSensorId){
					key.name = virt.title
				}
			})
		})
	}
	return realList
}
