import React, { Component, Fragment, useState, useEffect } 	from 'react';
import ReactDOM 											from "react-dom";
import ClickOutSide 										from "react-click-outside"
import {
	nextPageIcon,
	prevPageIcon,
	lastPageIcon,
	firstPageIcon
} 															from "../img.jsx"
import l_v from "store/languages.jsx"

import 'css/tableFooter.scss'

export const TableFooterMethods = Base => class TableFooterMethods extends Base {
	constructor(context){
		super(context);
		this.consElName = this.constructor.name;
		const count_lines_for_view = 	localStorage.getItem(this.consElName+'_count_for_view') ?
										localStorage.getItem(this.consElName+'_count_for_view') :
										20;
		this.paginationState = {
			count_lines_for_view:  count_lines_for_view,
			current_page:          0,
			start_index:           0,
			end_index:             count_lines_for_view,
			max_page:              undefined,
			min_page:              0,
			totalElements:         0,
			selectedParam: 		   null,
		}


	}
	createListDisabledSort = (sortParameterName, newSortParameterValue) => {
		const stateObject = {};
		if(newSortParameterValue){
			this.sortParams.forEach(el => {
				if(el == sortParameterName){
					stateObject[el] = newSortParameterValue
				}else{
					stateObject[el] = false
				}
			})
		}else{
			this.sortParams.forEach(el => stateObject[el] = false)
		}

		return stateObject
	}
	createParams = (defaultParams) => {
		let valueForRequest, stringSortParams = '';
		const {selectedParam} = this.state;
		const sortParamValue = this.state[selectedParam]
		const params = new URLSearchParams();
		for(let i in defaultParams){
			params.append(i, defaultParams[i])
		}
		if(selectedParam){
			if(sortParamValue){
				valueForRequest = 'asc'
			}else{
				valueForRequest = 'desc'
			}
			params.append('sort', selectedParam + ',' + valueForRequest);
		}


		// this.sortParams.forEach((sortParamName, index) => {
		// 	const sortParamValue = this.state[sortParamName];
		// 	if(sortParamValue){
		// 		valueForRequest = 'asc'
		// 		params.append('sort', sortParamName + ',' + valueForRequest);
		// 	}/*else{ //don't work good on backend
		// 		valueForRequest = 'desc'
		// 	}*/


		// 	// const sortParamValue = this.state[sortParamName];
		// 	// const lastSymbol = index !==this.sortParams.length-1 ? '&':'';
		// 	// if(sortParamValue){
		// 	// 	valueForRequest = 'asc'
		// 	// }else{
		// 	// 	valueForRequest = 'desc'
		// 	// }
		// 	// stringSortParams += 'sort='+sortParamName+','+valueForRequest + lastSymbol;
		// })
		return params
		// return stringSortParams
	}
	changePage = (e) =>{
		let { count_lines_for_view, start_index, end_index, totalElements } = this.state;
		count_lines_for_view = Number(count_lines_for_view);
		start_index = Number(start_index);
		end_index = Number(end_index);
		switch (e.currentTarget.name) {
			case 'go_to_start':
				this.setState({
					start_index: 0,
					end_index:   count_lines_for_view
				}, this.doRequest)
				return;
			case 'go_to_end':
				this.setState({
					start_index: totalElements - count_lines_for_view,
					end_index:   totalElements
				}, this.doRequest)
				return;
			case 'go_to_next':
				this.setState({
					start_index: start_index + count_lines_for_view,
					end_index:   end_index + count_lines_for_view
				}, this.doRequest);
				return;
			case 'go_to_prev':
				this.setState({
					start_index: start_index - count_lines_for_view < 0 ? 0 : start_index - count_lines_for_view,
					end_index:   end_index - count_lines_for_view < count_lines_for_view ? count_lines_for_view : end_index - count_lines_for_view
				}, this.doRequest);
		}
	}
	updateCountForView = (e) =>{
		const {count_lines_for_view, start_index} = this.state;
		const newCountForView = e.target.value;
		localStorage.setItem(this.consElName+'_count_for_view', newCountForView);
		this.setState({
			count_lines_for_view: newCountForView,
			end_index:            Number(start_index) + Number(newCountForView)
		}, this.doRequest)
	}
	_pageNumber_() {
		const {totalElements, end_index, count_lines_for_view} = this.state;
		return end_index > totalElements && (totalElements - count_lines_for_view < 0) ? 0  : Math.round(end_index/count_lines_for_view) - 1
	}
	get pageNumber(){
		return this._pageNumber_()
	}
}

function filterFromRange(list, start_index, end_index) {
	return list.slice(start_index, end_index > list ? list.length : end_index)
}

export default function TableFooter(props) {
	const {
		changePage,
		count_of_available_lines,
		start_index,
		end_index,
		setCountsLineForView,
		count_lines_for_view,
		show_pagination
	} = props;
	const disable_go_to_next = end_index >= count_of_available_lines ? true : false;
	const disable_go_to_prev = start_index <= 0 ? true : false;
	return 	show_pagination && count_of_available_lines > 20 ?
			<div className = 'table_footer'>
				<div className = 'count_of_lines_cell'>
					{l_v.number_of_strings} <CountLinesSelectList 	count_of_available_lines = {count_of_available_lines}
															count_lines_for_view = {count_lines_for_view}
															setCountsLineForView = {setCountsLineForView}/>
				</div>
				<div className = 'lines_info'>
					<span className = 'index_info'>{start_index} - {end_index}</span>
					{l_v.from}
					<span className = 'all_available_lines'>{count_of_available_lines}</span>
				</div>
				<div className = 'pagination_block'>
					<button className = 'no_style_btn pagintation_button'
							disabled  = {disable_go_to_prev}
							name = 'go_to_start' onClick = {changePage}>{firstPageIcon('paginationIcons')}</button>
					<button className = 'no_style_btn pagintation_button'
							disabled  = {disable_go_to_prev}
							name = 'go_to_prev' onClick = {changePage}>{prevPageIcon('paginationIcons')}</button>
					<button className = 'no_style_btn pagintation_button'
							disabled  = {disable_go_to_next}
							name = 'go_to_next' onClick = {changePage}>{nextPageIcon('paginationIcons')}</button>
					<button className = 'no_style_btn pagintation_button'
							disabled  = {disable_go_to_next}
							name = 'go_to_end'  onClick = {changePage}>{lastPageIcon('paginationIcons')}</button>
				</div>
			</div> :
			null
}

function CountLinesSelectList(props) {
	const [active, setActive] = useState(active);
	const { count_of_available_lines, setCountsLineForView, count_lines_for_view } = props;

	function updateCountLine(e) {
		setCountsLineForView(e);
		setActive(false);
	}
	return active ?
		<Fragment>
				<button onClick = {()=>setActive(false)} className = 'selected_value no_style_btn'>
					{count_lines_for_view}<i className = 'triangle' style = {styleTriangle(active)}></i>
				</button>
				<ClickOutSide className = 'count_of_lines_list' onClickOutside = {()=>setActive(false)}>
					<ItemForSelect 	updateCountLine = {updateCountLine}
									count_lines_for_view = {count_lines_for_view}
									value = {20}/>
					<ItemForSelect 	updateCountLine = {updateCountLine}
									count_lines_for_view = {count_lines_for_view}
									value = {50}/>
					<ItemForSelect 	updateCountLine = {updateCountLine}
									count_lines_for_view = {count_lines_for_view}
									value = {100}/>
					<ItemForSelect 	updateCountLine = {updateCountLine}
									count_lines_for_view = {count_lines_for_view}
									value = {150}/>

				</ClickOutSide>
			</Fragment> :
		<button onClick = {()=>setActive(true)} className = 'selected_value no_style_btn'>
			{count_lines_for_view}<i className = 'triangle' style = {styleTriangle(active)}></i>
		</button>
}

function ItemForSelect(props) {
	const { updateCountLine, value, count_lines_for_view } = props;
	return 	<p className = 'item_of_count_line'>
				<button onClick = {updateCountLine}
						style = {activeStyle(value, count_lines_for_view)}
						value = {value}
						className = 'no_style_btn count_line'>{value}</button>
			</p>
}

function activeStyle(value, selected_value) {
	value == selected_value ? { fontWeight: '700' } : null
}

function styleTriangle(active){
		return !!active?
				{
					borderLeft: '4px solid transparent',
				    borderRight: '4px solid transparent',
				    borderBottom: '4px solid black',
				    borderTop: 'none',
				}:
				{
					borderLeft: '4px solid transparent',
				    borderRight: '4px solid transparent',
				    borderBottom: 'none',
				    borderTop: '4px solid black',
				}
	}