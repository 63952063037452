import React, { Component, Fragment }          from 'react';
import transportsApi                           from 'actions/transportsApi.js'
import { arrayIsEmpty, removeElementFromList } from 'actions/ActionCreator.jsx'
import { editImg, deleteImg, closeImg }        from '../img.jsx'
import Pop_up                                  from 'modules/component/Pop_up.jsx'
import { iconV }                               from 'images/simple_svg_icons.jsx'
import l_v                                     from 'store/languages.jsx'

export default class GroupsVehicle extends Component {
	constructor(props){
		super(props);
		this.state = {
			groupsList: 	undefined,
			vehiclesGroup: 	this.props.vehiclesGroup,
			newGroupName: 	'',
		}
		this.initialState = this.state;
	}
	componentDidMount() {
		this.mount = true;
		let {vehicleOwner, vehicleId} = this.props;
		let updateState = (groupsList)=>{
			if(this.mount){
				this.setState({groupsList: groupsList})
			}
		}
		transportsApi.getGroupsList(vehicleOwner, updateState)
		// this.addToGroup.open();
	}
	componentWillUnmount(){
		this.mount = false;
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			vehiclesGroup: nextProps.vehiclesGroup
		});
	}
	enterToState = (e) =>{
		this.setState({
			[e.target.name]:e.target.value
		});
	}
	addVehicleToGroup = (groupId) =>{
		let {vehicleOwner, vehicleId} = this.props;
		let afterAddToGroup = (infoAboutMapping) =>{
			console.log("%c infoAboutMapping", coCSS, infoAboutMapping)
			let {groupId} = infoAboutMapping[0];
			let {groupsList, vehiclesGroup} = this.state;
			let groupInfo = groupsList.find(group=> group.id == groupId)
			if(!!groupInfo){
				vehiclesGroup.push(groupInfo)
				if(this.mount) this.setState({ vehiclesGroup: vehiclesGroup });
			}

			this.addToGroup.close();
		}
		transportsApi.addVehiclesToGroup(groupId, [vehicleId], vehicleOwner, afterAddToGroup)
	}
	removeVehicleFromGroup = (groupId)  => {
		let {vehicleOwner, vehicleId} = this.props;
		let afterRemoveFromGroup = (infoAboutMapping) =>{
			let {groupId} = infoAboutMapping;
			let {groupsList, vehiclesGroup} = this.state;
			this.setState({
				vehiclesGroup: removeElementFromList(vehiclesGroup, 'id', groupId)
			});
		}
		transportsApi.removeVehiclesFromGroup(groupId, [vehicleId], vehicleOwner, afterRemoveFromGroup)
	}
	addGroup = () =>{
		let {newGroupName, groupsList} = this.state;
		let {vehicleOwner} = this.props;
		let afterAddGroup = (newGroup) =>{
			groupsList.push(newGroup);
			// this.addVehicleToGroup(newGroup.id)
			this.setState({
				groupsList: groupsList,
				newGroupName: '',
			});
			this.greateNewGroup.close();
		}
		transportsApi.createGroup(newGroupName, vehicleOwner, afterAddGroup)
	}
	render() {
		return (
			<div className = 'vehicles_group'>
				<p className = 'nameForm'>{l_v.vehicle_groups}</p>
				<GroupsList vehiclesGroup = {this.state.vehiclesGroup}
								removeGroup = {this.removeVehicleFromGroup}/>
				<button className = 'btn_black'
							onClick = {()=>this.addToGroup.open()}>{l_v.add_to_group}</button>
				<button 	className = 'btn_black'
							onClick = {()=>this.greateNewGroup.open()}>{l_v.create_new_group}</button>
				<Pop_up 	head_text = 	{l_v.select_group}
			            head_back = 	{{backgroundColor:"#00bcd4", color: 'white', fill: 'white'}}
			            ref = {(addToGroup) => this.addToGroup = addToGroup}
			           	popUp_size = 	{{maxWidth: '99%', width: '500px', height: '350px'}}>
			           	<AddToGroupForm 	groupsList = {this.state.groupsList}
					           					vehiclesGroup = {this.state.vehiclesGroup}
					           					addVehicleToGroup = {this.addVehicleToGroup}/>
		    	</Pop_up>
		    	<Pop_up 	head_text = 	{l_v.create_a_new_group}
			            head_back = 	{{backgroundColor:"#00bcd4", color: 'white', fill: 'white'}}
			            ref = 			{(greateNewGroup) => this.greateNewGroup = greateNewGroup}
			           	popUp_size = 	{{maxWidth: '99%', width: '500px', height: '110px'}}>
					<CreateNewGroupForm 	newGroupName = {this.state.newGroupName}
												enterToState = {this.enterToState}
												addGroup = {this.addGroup}/>
				</Pop_up>
			</div>
		);
	}
}
function GroupsList(props) {
	let {vehiclesGroup, removeGroup} = props;
	return 	arrayIsEmpty(vehiclesGroup)?
			<div className = 'groups_on_vehiclePage'>
				{vehiclesGroup.map(key =><div key={key.id}
				                   				className = 'one_group'>
				                   			{key.name}
				                   			<button className = 'delete'
				                   					onClick = {()=>removeGroup(key.id)}>
				                   					{deleteImg()}</button></div>)}
			</div>:
			null
}
function AddToGroupForm(props) {
	let {groupsList, vehiclesGroup, addVehicleToGroup} = props;
	let groupForShow = removeVehicleGroupFromAvailable(groupsList, vehiclesGroup);
	return 	arrayIsEmpty(groupForShow)?
			<div className = 'addToGroup'>
				{groupForShow.map(key=>{
					return <button 	key = {key.id}
									className = {'one_group'}
									onClick = {()=>addVehicleToGroup(key.id)}>
									{key.name}
							</button>
				})}
			</div>:
			<div className = 'addToGroup'>
				<div className = 'no_data'>{l_v.groups_in_which_the_car_is}</div>
			</div>
}
function CreateNewGroupForm(props) {
	let {enterToState, addGroup, newGroupName} = props;
	return 	<div className = 'addGroupForm'>
				<input 	name = 'newGroupName'
							value = {newGroupName}
							className = 'input'
							onChange = {enterToState}/>
				<button 	onClick = {addGroup}
							className = 'btn_black'>
							{l_v.add}
				</button>
			</div>
}
function removeVehicleGroupFromAvailable(groupsList, vehiclesGroup){
	let otherGroup = [];
	if(arrayIsEmpty(groupsList) && arrayIsEmpty(vehiclesGroup)){
		otherGroup = groupsList.filter(totalGroup => !vehiclesGroup.find(vehicleGroup => totalGroup.id == vehicleGroup.id))
	}else{
		if(arrayIsEmpty(groupsList)) otherGroup = Array.from(groupsList)
	}
	return otherGroup
}