import React, { Fragment, Component }                               from "react"
import { connect }                                                  from 'react-redux'
import Navigation                                                   from './AdminNavigation.jsx'
import l_v                                             				from "store/languages.jsx"
import {SearchVsButton}                                             from './component/Search.jsx' //m?
import BtnAddTransport                                              from './component/BtnAddTransport.jsx'
import TransportList, { getDeviceSerial }                           from './component/TransportList.jsx'
import transportsApi                                                from '../actions/transportsApi.js'
import SeparPermission                                              from './component/SeparPermission.jsx'
import ConfirmRemove                                                from './ConfirmRemove.jsx'
import Filter_transport, {getIdSelectedGroup} 							  from './groupManaging/Filter_transport.jsx'
import gasStationApi                                                from 'actions/gasStationApi.js'
import { removeElementFromList, arrayIsEmpty, doCopy, toLowerCase } from 'actions/ActionCreator.jsx'
import Helmet                                                       from 'react-helmet';
import _                                                            from "lodash"
import { getPage }                                                  from "./Devices.jsx"
import TableFooter, { TableFooterMethods }                          from "modules/devicePage/TableFooter.jsx"
import Trailers                                                     from "./transportPage/Trailers.jsx"
import Preloader 									  				from 'modules/preloader.jsx'
//for merge requests merge to master
class Transports extends TableFooterMethods(Component) {
	constructor(props) {
		super(props);
		let kindUser;
		const accountType = this.props.accountType || this.props.accountTypeOfUser;
		const count_lines_for_view = 20;
		this.state = {
			transport_list:       this.props.TransportData,
			selectedTub:          accountType == 'GLOBUS' ?'VEHICLE' : 'GAS_STATION',
			gasStationList:       null,
			'device.deviceId':    false,//sort parameter
			'vehicleGroup.name':  false,//sort parameter
			vehicleLicensePlate:  false,//sort parameter
			vehicleName: 		  false,//sort parameter
			...this.paginationState
		}
		this.searchQuery = '';
		this.groupIdForFilter = getIdSelectedGroup(this.getOwner());
		this.sortParams = ['vehicleGroupName', 'vehicleName', 'vehicleLicensePlate', 'deviceId' ];
	}
	componentDidMount() {
		this.mounted = true;
		this.groupIdForFilter = getIdSelectedGroup(this.getOwner());
		this.doRequest();
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	getParams = () =>{
		const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
		console.log(`%c this.groupIdForFilter-`, "color: green; font-weight: 400; text-transform:uppercase", this.groupIdForFilter)
		return {
			page: getPage(totalElements, end_index, count_lines_for_view),
			size: count_lines_for_view,
			owner: this.getOwner(),
			groupId: this.groupIdForFilter,
			searchParam: this.searchQuery
		}
	}
	doRequest = () => {
		const accountType = this.props.accountType || this.props.accountTypeOfUser;
		this.tooglePreloader(true);
		const setGasStations = (contentInfo) => this.setState({
			gasStationList: contentInfo.content,
			totalElements: contentInfo.totalElements
		}, () => this.tooglePreloader(false));
		const setVehicles = (contentInfo) => this.setState({
			transport_list: contentInfo.content,
			totalElements: contentInfo.totalElements

		}, () => this.tooglePreloader(false));

		const params = this.createParams(this.getParams());
		accountType == 'TERES' ?
		gasStationApi.getGasStations(params, setGasStations) :
		transportsApi.getTransports(params, setVehicles)
	}
	tooglePreloader = (status) => {
		if(this.mounted) status ? this.preloader.show() : this.preloader.hide()
	}
	saveValueForSort = searchQuery => {
		this.searchQuery = searchQuery;
	}
	clearEntered = () => this.searchQuery = ''
	addVehicle = (vehicleInfo) =>{
		const updateList = (newVehicle) =>{
			let {transport_list} = this.state;
			transport_list.push(newVehicle);
			this.setState({
				transport_list: transport_list
			});
		}
		transportsApi.addTransport(vehicleInfo, updateList);
	}
	addGasStation = (gasStationInfo) =>{
		const updateList = (newGasStation) =>{
			let {gasStationList} = this.state;
			gasStationList.push(newGasStation);
			this.setState({
				gasStationList: gasStationList
			});
		}
		gasStationApi.addGasStation(gasStationInfo, updateList);
	}
	delete = () =>{
		let {selectedTub, gasStationList, transport_list} = this.state;
		if(selectedTub == 'VEHICLE'){
			transportsApi.deleteVehicle(this.state.forDelete.id,  (id)=>{
				this.setState({
					transport_list: removeElementFromList(transport_list, 'vehicleId', id)
				});
			});
		}else{
			gasStationApi.deleteGasStation(this.state.forDelete.id, (id)=>{
				this.setState({
					gasStationList: removeElementFromList(gasStationList, 'vehicleId', id)
				});
			})
		}
        this.setState({
			forDelete: null,
		});
	}
	callDeletePopUp = (e) =>{
		this.setState({
			forDelete: e,
		});
		this.ConfirmTextforRemove = l_v.are_you_sure  + e.name +'?';
		this.refs.delete.popUp_activation({
          	active: true
        })
	}
	updateFilterGroup = (filteredGroupId) =>{

        this.groupIdForFilter = filteredGroupId == 'all' ? '' : filteredGroupId;
        this.doRequest();
       	// const transport_list = this.props.TransportData;
        // const displayedTransport = filteredGroupId == 'all' ? transport_list : filterVehicles();
        // this.setState({
        //     transport_list: displayedTransport
        // })

        // function filterVehicles() {
        // 	const condition = (group) => group.id == filteredGroupId;
        // 	function findRequired(el) {
        // 		return el.groups.find(group => condition(group)? group : null);
        // 	}
        // 	return transport_list.filter(findRequired);
        // }
	}
	onSelectTub = (e) =>{
		let tubName = e.target.name;
		let {gasStationList} = this.state;
		const setGasStations = (gasStationList) =>{
			console.log("%c gasStationList", coCSS, gasStationList)
			this.setState({
				gasStationList: gasStationList
			});
		}
		!gasStationList?gasStationApi.getGasStations(this.getOwner(), setGasStations):null
		this.setState({selectedTub: tubName})
	}
	getOwner = () =>{
		if(this.props.thisKindUser !== "CLIENT"){
			 return this.props.login
		}else{
			return this.props.asOwner
		}
	}
	setSortParam = (sortParameterName) => {
		this.setState({
			[sortParameterName]: !this.state[sortParameterName],
			selectedParam: sortParameterName,
			...this.createListDisabledSort(sortParameterName, !this.state[sortParameterName])
		}, this.doRequest);
	}
	exportToCSV = () => transportsApi.getCSVFileByUser(this.props.match.params.userName)
	render(){
		let owner = this.getOwner();
		let {selectedTub, transport_list, gasStationList, selectedParam} = this.state;
		const { start_index, end_index, count_lines_for_view, totalElements } = this.state;
		let {thisKindUser} = this.props;
		const title = thisKindUser == 'CLIENT' ? <Helmet title = {l_v.vehicles_list} /> : null;
		return 	<div className='transports'>
					{ title }
					{ !this.props.kindUser ? <Navigation /> : null }
					<div className = 'container content'>
						{/*<TubsStationsVsVehicles selectedTub = {selectedTub}
												onSelect = {this.onSelectTub}/>*/}
						{
							selectedTub == 'VEHICLE' ?
							<VehiclesContent  	saveValueForSort = 		{this.saveValueForSort}
												doRequest = 			{this.doRequest}
												totalElements = 		{totalElements}
												setSortParam = 			{this.setSortParam}
												sortByDeviceId =   		{this.state['deviceId']}
												sortByVehicleGroup = 	{this.state['vehicleGroupName']}
												selectedParam = 		{selectedParam}
												vehicleLicensePlate = 	{this.state.vehicleLicensePlate}
												vehicleName = 			{this.state.vehicleName}
												transport_list = 		{transport_list}
												searchQuery = 			{this.searchQuery}
												updateFilterGroup = 	{this.updateFilterGroup}
												addVehicle = 			{this.addVehicle}
												kindUser = 				{thisKindUser}
												owner = 				{owner}
												callDeletePopUp = 		{this.callDeletePopUp}/> :
							<GasStationsContent owner =          { owner }
												saveValueForSort = 		{this.saveValueForSort}
												doRequest = 			{this.doRequest}
												setSortParam =   { this.setSortParam }
												totalElements =  { totalElements }
												gasStationList = { gasStationList }
												selectedParam =  { selectedParam }
												callPopUp =      { this.callDeletePopUp }
												addGasStation =  { this.addGasStation }/>
						}
						<TableFooter 	changePage = {this.changePage}
										show_pagination = {totalElements?true:false}
						  				count_of_available_lines = {totalElements}
						  				count_lines_for_view = {count_lines_for_view}
						  				setCountsLineForView = {this.updateCountForView}
						  				start_index = {start_index}
						  				end_index = {end_index} />
		  				<Preloader ref = { el => this.preloader = el }/>
					</div>
					<ConfirmRemove 		ref = 			'delete'
										confirmText = 	{this.ConfirmTextforRemove }
										clear = 		{()=>this.setState({
																forDelete: undefined
														})}
										remove = 	{this.delete}/>
					{
						selectedTub == 'VEHICLE' ?
						<Trailers login = {owner}/> :
						null
					}


				</div>
	}
}
export default connect(
	state => ({
		asOwner: 			state.authorizationR.login,
		token: 				state.authorizationR.token,
		thisKindUser: 		state.authorizationR.kindUser,
		TransportData: 		state.devicesR.TransportData,
		DevicesData: 		state.devicesR.DevicesData,
		userPremission: 	state.authorizationR.user_permission,
		accountTypeOfUser: 	state.authorizationR.accountType,
	})
)(Transports)

function VehiclesContent(props) {
	let { transport_list, updateFilterGroup, addVehicle, kindUser, callDeletePopUp, totalElements,
		owner, searchQuery} = props;
	return 	<Fragment>
					<h1 className = "page_name">
						{l_v.vehicles_list}
						<CountOfVehicles itemsList = {transport_list}  totalElements = {totalElements}/>
						<div className = 'decoration_div'></div>
					</h1>
					<div className = 'space_between'>
					<SearchVsButton onChange = 	  {props.saveValueForSort}
									name = 		  'name'//because in create form this field has input name - 'name'
									placeholder = {l_v.enter_auto_name_or_number}
									sendRequest = {props.doRequest}/>

						<Filter_transport 	userLogin  = 		{owner}
				          					updateFilterGroup = {updateFilterGroup}
				          					transport_list = 	{transport_list}/>
						<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
											kindCapability = 	'editVehicles'
											type =           	'component'>

							<BtnAddTransport 	defaultowner =       { owner }
												kindUser =           { kindUser }
												number_placeholder = {l_v.enter_device_number}
												type =               'VEHICLE'
												btnname =            {l_v.add_vehicle}
												dataFromform =       { addVehicle }/>
						</SeparPermission>
						<ExportButton login = {owner}/>
					</div>
					<TransportList 	data = {transport_list}
									{...props}
									owner = {owner}
									kindUser = {kindUser}
									delete = {callDeletePopUp}/>

			</Fragment>
}

function ExportButton(props){
	const exportToCSV = () => transportsApi.getCSVFileByUser(props.login)

	return  <button className = 'btn_black'
					onClick = 	{exportToCSV}>
					{l_v.export}
			</button>
}
function GasStationsContent(props) {
	let {kindUser, addGasStation, callPopUp, owner, gasStationList, totalElements} = props;
	return 	<Fragment>
					<h1 className = "page_name">
						{l_v.list_of_gas_stations}
						<CountOfVehicles itemsList = {gasStationList} totalElements = {totalElements} />
					</h1>
					<div className = 'space_between'>
						<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER'
											type =   'component'>

							<BtnAddTransport 	defaultowner = 			{owner}
												kindUser = 				{kindUser}
												number_placeholder = 	{l_v.enter_device_number}
												type = 					'GAS_STATION'
												btnname = 				{l_v.add_gas_station}
												dataFromform = 			{addGasStation} />
						</SeparPermission>
					</div>
					<TransportList 	data = {gasStationList}
									kindUser = {kindUser}
									owner = {owner}
									delete = {callPopUp}
									gasStation = {true}/>
			</Fragment>
}
function TubsStationsVsVehicles(props) {
	let {selectedTub, onSelect} = props;
	let btnStyle = styleForSelectedTub(selectedTub);
	return 	<div className = 'tubs_vehicle_gasStation'>
				<button className = 'tub_button btn_black left_btn' name = 'VEHICLE'
						onClick = 	{onSelect}
						style = 	{btnStyle('VEHICLE')}>
						{l_v.vehicles}
				</button>
				<button className = 'tub_button btn_black right_btn' name = 'GAS_STATION'
						onClick = 	{onSelect}
						style = 	{btnStyle('GAS_STATION')}>
						{l_v.gas_stations}
				</button>
			</div>
}
function styleForSelectedTub(selectedTub) {
	function styles(btn_name){
		return 	btn_name == selectedTub ?
				{
					backgroundColor: '#4179f8',
					color: 'white'
				}:
				null
	}
	return styles
}
function CountOfVehicles(props) {
	const {itemsList, totalElements} = props;
	return 	arrayIsEmpty(itemsList) ?
	 		<div className = 'count_connected_vehicles'>
	 			{l_v.all}: {totalElements ? totalElements : 0} {l_v.online_on_page}: {calculateVehicles(itemsList)}
	 		</div> :
	 		null
}
function calculateVehicles(itemsList) {
	let count = 0;
	itemsList.forEach(key => count += checkOnConnected(key.lastKnownTime))
	return count
}
function checkOnConnected(timeWhenWasOnConnection) {
	timeWhenWasOnConnection = new Date(timeWhenWasOnConnection);
	const currentDate = new Date();
	const threeDayAgo = currentDate - 1000 * 60 * 60 * 24 * 3;
	return threeDayAgo < timeWhenWasOnConnection ? 1 : 0
}
