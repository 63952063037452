import React 				from 'react'
import { Provider, connect} from 'react-redux';
import {arrows} 			from '../img.jsx'
import _ 					from 'lodash'
import {ifNoKey, arrayIsEmpty, copyOfObject}                from 'actions/ActionCreator.jsx'
import Preloader     		from 'modules/preloader.jsx'

import 'css/permittedList.scss'
export default class PermittedList extends React.Component {
	constructor(props) {
		super(props);
		const {allAvailableElements, permittedElements, pronhibitedElements } = this.props;
		this.state = {
			allAvailableElements: allAvailableElements,
			permittedElements:  permittedElements?permittedElements:[],
			pronhibitedElements: getPronhibitedElements(permittedElements, allAvailableElements),  //def decison when will completed on back end need devide
		}
	}
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
		
	}
	permitOperations = (removeFrom, elementId) => {
		this.preloader.show();
		const { permittedElements, pronhibitedElements } = this.state;
		const onSave = (newLists) =>{
			this.setState(newLists);
			if(this.mounted) this.preloader.hide();
			this.props.setPermittedList(newLists.permittedElements)
		};
		const moveElementBetweenArrays = moveLogic(permittedElements, pronhibitedElements, this.props.login);
		const resultingLists = 	removeFrom == "permittedElements" ?
								moveElementBetweenArrays(elementId, 'prohibit', onSave) :
								moveElementBetweenArrays(elementId, 'permit', onSave);
	}
	permitAll = () =>{
		let {allAvailableElements, pronhibitedElements} = this.state;
		let onSave = () => {
			this.setState({
				permittedElements: allAvailableElements,
				pronhibitedElements: []
			});
			this.props.setPermittedList(allAvailableElements)
		}
		onSave()
		// transportsApi.permitGasStationForUser(this.props.login, createArrIds(pronhibitedElements), onSave);
	}
	pronhibitAll = () =>{
		let {allAvailableElements, permittedElements} = this.state;
		let onSave = () =>{
			this.setState({
				permittedElements: [],
				pronhibitedElements: allAvailableElements,
			});
			this.props.setPermittedList([])
		}
		onSave()
		// transportsApi.removeFromPermitedGasStationForUser(this.props.login, createArrIds(permittedElements), onSave);
	}
	drop = (e) => {
		this.permitOperations(this.dragedFrom, this.dropId)
	}
	dragover = (e) => {
		e.preventDefault()
	}
	dragStart = (e) => {
		this.dragedFrom  = e.currentTarget.parentElement.id;
		this.dropId = e.currentTarget.id
	}
	doubleClick = (e) => {
		this.permitOperations(e.currentTarget.parentElement.id, e.currentTarget.id)
	}
	render() {
		const {permittedElements, pronhibitedElements}	= this.state;
		const {namePermittedBlock, namePronhibitedBlock} = this.props;
		return (
			<div className = 'droppZone'>
				<div 	className = 	"drop_block"
						id = 			'permittedElements'
						onDragOver  = 	{this.dragover}
						onTouchEnd = 	{this.drop}
						onDrop = 		{this.drop}>

						<p className =	"name_block">{namePermittedBlock}:</p>
						<ElementsForPermitOperation list = {permittedElements}
													dragStart = {this.dragStart}
													touchstart = {this.dragStart}
													doubleClick ={this.doubleClick}/>
				</div>
				<div className ='arrowContainer'>
					<button name = 		'changeAll'
							className = 'no_style_btn arrows_btn'
							onClick = 	{this.permitAll}>
							{arrows.leftArrow("arrows")}
					</button>
					<button name = 		'changeAll'
							className = 'no_style_btn arrows_btn'
							onClick = 	{this.pronhibitAll}>
							{arrows.rightArrow("arrows")}
					</button>
				</div>
				<div 	className = 	"drop_block"
						id = 			'pronhibitedElements'
						onDragOver = 	{this.dragover}
						onTouchEnd = 	{this.drop}
						onDrop = 		{this.drop}>
						<p className =	"name_block">{namePronhibitedBlock}:</p>
						<ElementsForPermitOperation list = {pronhibitedElements}
													dragStart = {this.dragStart}
													doubleClick ={this.doubleClick}/>
				</div>
				<Preloader ref = {preloader => this.preloader = preloader}/>
			</div>
		);
	}
}

function ElementsForPermitOperation(props) {
	const { list, dragStart, doubleClick } = props;
	return 	arrayIsEmpty(list) ?
			list.map(key => <p 	key = 			{key.id}
								draggable = 	{true}
								onDragStart = 	{dragStart}
								onDoubleClick = {doubleClick}
								onTouchStart = 	{dragStart}
								className = 	"item"
								id = 			{key.id}>
								{key.serverName ? key.serverName : key.id}
							</p>) :
			null
}

function getPronhibitedElements(permittedElements, allOwnerGasStations) {
	let pronhibitedElements;
	if(arrayIsEmpty(permittedElements)) {
		pronhibitedElements = _.differenceWith(allOwnerGasStations, permittedElements, (first, second) => first.id === second.id);
	} else {
		pronhibitedElements = allOwnerGasStations
	}
	return pronhibitedElements
}

function moveLogic(permittedElements, pronhibitedElements, login) {
	function moveElement(elementId, operationName, funcOnCompleteOperations) {
		const operation = {
			setOperatedElementInfo: function(listForGetELemnt){
				const {indexElementForMove, element} = getElementById(listForGetELemnt, elementId);
				this.indexElementForMove = indexElementForMove;
				this.element = element;
			},
			doProhibit: function() {
				this.setOperatedElementInfo(permittedElements);
				this.onCompletePronhibit();
				// const callback = () => this.onCompletePronhibit();
				// transportsApi.removeFromPermitedGasStationForUser(login, elementId, callback);
			},
			doPermit: function() {
				this.setOperatedElementInfo(pronhibitedElements)
				this.onCompletePermit();
				// const callback = () => this.onCompletePermit();
				// transportsApi.permitGasStationForUser(login, elementId, callback);
			},
			onCompletePronhibit: function() {
				pronhibitedElements.push(this.element);
				permittedElements.splice(this.indexElementForMove, 1);
				funcOnCompleteOperations(this.returnArrays())
			},
			onCompletePermit: function() {
				permittedElements.push(this.element);
				pronhibitedElements.splice(this.indexElementForMove, 1);
				funcOnCompleteOperations(this.returnArrays())
			},
			returnArrays: function() {
				const newLists = {
					permittedElements: permittedElements,
					pronhibitedElements: pronhibitedElements
				};
				return newLists;
			},
			get prohibit() {
				this.doProhibit()
				return null
			},
			get permit() {
				this.doPermit()
				return null
			},
		};
		return operation[operationName]
	}
	return moveElement
}

function getElementById(arr, elementId) {
	let indexElementForMove = arr.findIndex(key => {
		if(key.id == elementId) {
			return key;
		}
	});
	let element = arr[indexElementForMove];
	return { indexElementForMove: indexElementForMove, element: element }
}

function createArrIds(arr) {
	let newArr = [];
	if(arrayIsEmpty(arr)) {
		arr.forEach(key => {
			newArr.push(key.id)
		})
	}
	return newArr
}