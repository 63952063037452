import React, { Component } 	from 'react';
import Users                	from 'actions/users.js'
import SL 						from 'sl-library'
import Preloader 				from 'modules/preloader.jsx'
import l_v 						from "store/languages.jsx"

export default class CustomReportsMapping extends Component {
	constructor(props){
		super(props);
		this.state = {
			reportsList: 	[],
			selectedReportsList: []
		}
		this.initialState = this.state;
	}
	componentDidMount() {
		this.mounted = true;
		Users.getListAvailableCustomReports(this.props.accountType, reportsList => this.setState({reportsList: reportsList} ));
		Users.getSelectedReportsForUser(this.props.login, selectedReportsList => this.setState({selectedReportsList: selectedReportsList} ));
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	onSelect = (e) => {
		this.preloader.show()
		const {selectedReportsList, reportsList} = this.state;

		if(!this.requestInProcess){//specially for fast hand tester
			const idCheckbox = e.target.name;
			if(e.target.checked) {
				// selectedReportsList.push(e.target.name)
				this.requestInProcess = true;
				Users.saveSelectedReportsForUser(this.props.login, [idCheckbox], ()=> {
					if(this.mounted) this.preloader.hide()
					this.requestInProcess = false;
					selectedReportsList.push(reportsList.find(el => el.id == idCheckbox))
					this.setState({
						selectedReportsList: selectedReportsList
					});
				})

			} else {
				this.requestInProcess = true;
				Users.deleteSelectedReportsForUser(this.props.login, [idCheckbox], ()=> {
					if(this.mounted) this.preloader.hide();
					this.requestInProcess = false;
					selectedReportsList.splice(selectedReportsList.findIndex(el => el.id == idCheckbox), 1)
					this.setState({
						selectedReportsList: SL.copyOfObject(selectedReportsList)
					});
				})

			}

		}


	}
	render() {
		const {selectedReportsList, reportsList} = this.state;
		return (
			<div className = 'additional_reports_block content' style = {{position: 'relative'}}>
				<h3 className = 'block_name'>{l_v.additional_reports}</h3>
				<ReportsListView 	selectedReportsList = {selectedReportsList}
									onSelect = {this.onSelect}
									reportsList = {reportsList}/>
				<Preloader ref = {preloader => this.preloader = preloader} />
			</div>
		);
	}
}

function ReportsListView(props) {
	const {reportsList, selectedReportsList} = props;
	return 	SL.arrayIsEmpty(reportsList) && Array.isArray(selectedReportsList) ?
			reportsList.map(oneReport => <OneReportView {...oneReport}
	                       								key = {oneReport.id}
	                       								onSelect = {props.onSelect}
	                       								checked = {selectedReportsList.find(el => el.id == oneReport.id) ? true : false}/>) :
			null
}

function OneReportView(props) {
	const {templateName, id, onSelect, checked} = props;
	return  <p>
				<label>
					<input 	type = 'checkbox'
							checked = {checked}
							name = {id}
							onChange = {onSelect}
							id = {'report' + id} />
					{templateName}
				</label>
			</p>
}