import React, { useState }   from "react"
import {
	removeProhibitedSymbol,
	matchPronhibitedSymbol,
	removeSpaces,
	copyOfObject,
	checkPass
}                            from "actions/ActionCreator.jsx"
import ClickOutside          from 'react-click-outside'
import l_v      from "store/languages.jsx"
import EnterListennerForForm from "modules/component/EnterListennerForForm.jsx"

export default class BtnAdd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login:             '',
			password:          '',
			password_confirm:  '',
			textarea:          '',
			message:           '',
			password_error:    '',
			belongsTo:         '',
			error_emty_field:  '',
			capabilities:      [],
			serveranswer:      '',
			visibilityanswer:  '',
			showanswer:        '',
			classpopup:        '',
			...getCheckBoxVisibilityByOwner(this.props.userPremission.capabilities),
			thisbelongsTo:     this.props.userPremission.belongsTo,
			form_is_visible: 	false,
		}
		this.initialState = copyOfObject(this.state);
	}
	toogleShowForm = () => {
		const {form_is_visible} = this.state;
		console.log("%c this.initialState", coCSS, this.initialState)
		this.setState({
			...copyOfObject(this.initialState),
			form_is_visible: !form_is_visible
		});
	}
	enterToState = (e) => {
		let value = e.target.value
		const {kindUser} = this.props;
		if(e.target.name == 'login'){
			if(matchPronhibitedSymbol(value)){
				this.setState({
					message: "Разрешены только латинские символы и цифры"
				});
				value = removeProhibitedSymbol(value)
			}else{
				this.setState({
					message: null
				});
			}
		}
		if(e.target.name == 'password' || e.target.name == 'password_confirm' || e.target.name == 'login'){
			value = removeSpaces(value);
		}
		this.setState({
			[e.target.name]: value
		})
		if(e.target.name == 'login' && kindUser == 'DEALER'){
			this.setState({
				belongsTo: value
			})
		}
	}
	addition = (event) => {
		const {password, password_confirm, login} = this.state;
		if(!password || !password_confirm || !login) {
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		} else {
			if(password !== password_confirm) {
				this.setState({
					message: l_v.pass_not_match,
					password_error: 'formError'
				})
			} else {
				if(!checkPass(password)){
					this.setState({
						message: l_v.newPassMustBe,
						password_error: 'formError'
					})
				}else{
					this.setState({
						message: '',
						password_error: ''
					})
					let belongsTo;
					if(!this.state.thisbelongsTo) { //if user has group, he can't set up other group for child
						belongsTo = this.state.belongsTo
					} else { belongsTo = this.state.thisbelongsTo }
					this.props.dataFromform({
						kindUser: 	this.props.kindUser,
						login: 		this.state.login,
						dataForSend: {
							password: this.state.password,
							capabilities: this.state.capabilities,
							belongsTo: 		belongsTo,
							description: 	this.state.description
						}
					})
					this.toogleShowForm();
				}

			}
		}
	}
	setCapability = (e) => {
		const name = e.target.name;
		const value = e.target.checked;
		const {capabilities} = this.state;
		const indexCapability = capabilities.findIndex(el => el.name == name);
		if(indexCapability != -1){
			capabilities[indexCapability].value = value;
		}else{
			capabilities.push({
				name: name,
				value: value,
			})
		}
		this.setState({
			capabilities: copyOfObject(capabilities)
		})
	}
	render() {
		const {kindUser, dealerGroupsList} = this.props;
		const {thisbelongsTo, belongsTo, form_is_visible} = this.state;
		const displayBelongsTo = thisbelongsTo ? 'none' : '';//if user has group, he can't set up other group for child

		return  <div className = 'add_info add_admin'>
					<div className={'answer_for_user ' + this.state.classpopup} >
							<p>{this.state.showanswer}</p>
					</div>
					<span 	className = 'btn_black'
							onClick = 	{this.toogleShowForm}>
							{this.props.btnname}
					</span>
					{
						form_is_visible ?
						<EnterListennerForForm onPressEnter = { this.addition }>
							<form 	className = {'add_info_form show_form'}>
								<CloseIcon onClick = {this.toogleShowForm}/>
								<div className='form_message'> <p>{this.state.message}</p></div>
								<input 	type = 			"text"
										name = 			'login'
										className = 	{'input ' + this.state.error_emty_field }
										value = 		{this.state.login}
										onChange = 		{this.enterToState}
										placeholder = 	{this.props.name_placeholder +' *'}/>
								{
									kindUser == 'ADMIN' ?
									<input 	type = 			"text"
											name = 			'belongsTo'
											style = 		{{display: displayBelongsTo}}
											value = 		{belongsTo}
											onChange = 		{this.enterToState}
											className = 	{'input belongsTo'}
											placeholder = 	{`${l_v.select_group} *`} /> :
									<BelongsToView 	select = {(value) => this.setState({belongsTo: value})}
													dealerGroupsList = {dealerGroupsList}
													style = {{display: displayBelongsTo}}
													belongsTo = {belongsTo}/>
								}
								<textarea 	type = 			"text"
											className = 	"form_text_area"
											name = 			'description'
											value =			{this.state.description}
											placeholder = 	{l_v.description}
											onChange = {this.enterToState}/>
								<div className = 'clear'></div>
								<input 	type = 			"text"
										name = 			'password'
										className = 	{'input ' + this.state.password_error + ' ' +this.state.error_emty_field }
										value = 		{this.state.password}
										placeholder = 	{`${l_v.enter_password} *`}
										onChange = 		{this.enterToState} />
								<input 	type =        "text"
										className =   { 'input ' + this.state.password_error + ' ' + this.state.error_emty_field }
										name =        'password_confirm'
										value =       { this.state.password_confirm }
										placeholder = 	{`${l_v.confirm_password}  *`}
										onChange =    { this.enterToState }/>
								{this.listselect()}
								<button type="button"
										className = 'btn_black'
										onClick = {this.addition}>
										{l_v.add}
								</button>
							</form>
						</EnterListennerForForm>
						:
						null
					}

				</div>
	}
	listselect = () =>{
		const selectcapabilities = [
			{
				name: 'editClients',
				label: l_v.edit_clients,
				visibility: this.state.editClients
			},
			{
				name: 'editOther',
				label: l_v.edit_others,
				visibility: this.state.editOther
			},
			{
				name: 'editDevices',
				label: l_v.edit_devices,
				visibility: this.state.editDevices
			},
			{
				name: 'editSensors',
				label: l_v.edit_sensors,
				visibility: this.state.editSensors
			},
			{
				name: 'editVehicles',
				label: l_v.edit_vehicle,
				visibility: this.state.editVehicles
			},
			{
				name: 'deleteSensorTypes',
				label: l_v.cailbrations_remove,
				visibility: this.state.deleteSensorTypes
			},
			{
				name: 'seeStats',
				label: l_v.see_statistics,
				visibility: this.state.seeStats
			},
			{
				name: 'recalcSensors',
				label: l_v.sensor_setup,
				visibility: this.state.recalcSensors
			},
			{
				name: 'createAdminUsers',
				label: l_v.create_admins,
				visibility: this.state.createAdminUsers
			},
			{
				name: 'setBlocking',
				label: l_v.do_blocking,
				visibility: this.state.setBlocking
			},

		];
		console.log("%c selectcapabilities", coCSS, selectcapabilities)
		return selectcapabilities.map((key)=> {
					if(this.props.kindUser == 'DEALER' && key.name == 'createAdminUsers'){
						return false
					}else{
						return(
								<p key={key.name} style={{display: key.visibility}}>
									<input 	type =      "checkbox"
											className = 'select_checkbox'
											id =        { key.name + '_add_form'}
											name =      { key.name }
											onChange =   { this.setCapability }
											checked = 	{ check(key.name, this.state.capabilities)}/>
									<label 	htmlFor =	{key.name}
											className = 'select_label'>
											{key.label}
									</label>
								</p>
							)
					}
			});
	}
}






function getCheckBoxVisibilityByOwner(capabilities) {
	const settings = {
		editOther:         'none',
		editDevices:       'none',
		editSensors:        'none',
		deleteSensorTypes: 'none',
		seeStats:          'none',
		recalcSensors:     'none',
		createAdminUsers:  'none',
		setBlocking:       'none',
		editClients:       'none',
		editVehicles:      'none',
	};
	if(capabilities){
		console.log("%c capabilities", coCSS, capabilities, capabilities.editSensors)
		for(let el in capabilities){
			settings[el] = settings[el]?'':'none'
		}
	}
	console.log("%c settings", coCSS, settings)
	return settings
}


function BelongsToView(props) {
	const {belongsTo, dealerGroupsList, select, style} = props;
	const [active, setActive] = useState(active);
	function onSelect(selectedGroup) {
		setActive(false);
		select(selectedGroup)
	}
	return 	<div className = 'belongsTo_dealer_block' style = {style}>
				<input 	type = 			"text"
						name = 			'belongsTo'
						style = 		{{display: belongsTo}}
						value = 		{belongsTo}
						onClick = 		{() => setActive(true)}
						readOnly = 		{true}
						className = 	{'input belongsTo'}
						placeholder = 	{`${l_v.select_group} *`} />
				<i className='triangle' style={styleTriangle(active)} onClick = {() => setActive(!active)}></i>
				{
					active ?
					<ClickOutside 	className = 'dealer_groups_list'
									onClickOutside =	{()=>setActive(false)}>
						<GroupsList dealerGroupsList = {dealerGroupsList} onSelect = {onSelect}/>
					</ClickOutside> :
					null
				}
			</div>
}

function GroupsList(props) {
	return  props.dealerGroupsList.map(key => <p onClick = {()=>props.onSelect(key)} key = {key}>{key}</p>)
}

function check(name, arr) {
	let res = false;
	if(Array.isArray(arr) && arr.length && !!name) {
		// console.log(arr)
		arr.map((key) => {
			// console.log(key.name == name, key.name, name, key.value)
			if(key.name == name) {
				res = key.value
			}
		})
	} else res = false
	return res
}

function styleTriangle(active){
	return !!active?
			{
				borderLeft: '4px solid transparent',
			    borderRight: '4px solid transparent',
			    borderBottom: '4px solid black',
			    borderTop: 'none',
			}:
			{
				borderLeft: '4px solid transparent',
			    borderRight: '4px solid transparent',
			    borderBottom: 'none',
			    borderTop: '4px solid black',
			}
}

function CloseIcon(props) {
	const {onClick} = props;
	return 	<svg 	xmlns="http://www.w3.org/2000/svg"
	 				aria-labelledby="title"
	 				className = 'close'
		 			viewBox="0 0 16 16"
		 			onClick = {onClick}>
					<title id="title">{l_v.close}</title>
					<polygon points="12.9,4.4 11.5,3 8,6.5 4.4,3 3,4.4 6.5,8 3,11.5 4.4,12.9 8,9.4 11.5,12.9 12.9,11.5 9.4,8 "/>
			</svg>
}
