import React from 'react'
export default class Nonull extends React.Component{
	render(){
		let data = {...this.props};
		if (data.value == null || data.value == undefined){
			data.value = '';
		}
		let name = 'data-index';
		let index = data[name];
		return <input
						name =         { data.name }
						data-index =   { index }
						type =         { data.type }
						className =    { data.className }
						placeholder =  { data.placeholder }
						value =        { data.value }
						onChange =     { data.onChange }
				/>
	}
}