import React                                        from 'react'
import sensorsApi                                   from 'actions/updateSensor.jsx'
import { ifNoKey, arrayIsEmpty }                    from 'actions/ActionCreator.jsx'
import AddVirtualSensor, {VirtualSensorFormContent, FooterEditForm}                             from './AddVirtualSensor.jsx'
import PopUp                                        from '../component/Pop_up.jsx'
import Nonull                                       from '../component/Nonull.jsx'
import { deleteImg, editImg, closeImg }             from '../img.jsx'
import l_v                                          from 'store/languages.jsx'
import TooltipWrapper                               from '../component/TooltipWrapper.jsx'
import ConfirmRemove                                from '../ConfirmRemove.jsx'
import SeparPermission                              from 'modules/component/SeparPermission.jsx'
import {HigLightContext, FilterButtonInRow} 							from "modules/transportPage/HighLighterOfRows.js"

	

export default class VirtualSensors extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			list: 	this.props.list,
		}
	}
	componentWillReceiveProps(nextProps) {
		window.myHotCall({removeAllVirtualSensors: removeAllVirtualSensors(nextProps.list)})
		this.setState({list: nextProps.list});
	}
	updateFieldState = (data) =>{
		this.setState({
			sensor: data,
		})
	}
	addVirtualSensor = (sensorInfo) =>{
		sensorInfo.vehicleId = this.props.vehicleId;
		let callback = (sensorInfo) =>{
			this.addSensorForm.setInitialState()
			this.popUp.close()
			this.props.addVirtualSensorToList(sensorInfo)
		}
		sensorsApi.addVirtualSensor(sensorInfo, callback);
	}
	updateVirtualSensor = (sensorInfo, completedUpdate) =>{
		sensorInfo.vehicleId = this.props.vehicleId;
		let callback = (newSensorInfo) =>{
			completedUpdate()
			this.props.updateVirtualSensorInList(newSensorInfo)
		}
		sensorsApi.updateVirtualSensor(sensorInfo, callback);
	}
	deleteVirtualSensor = (sensorId, completedUpdate) => {
		sensorsApi.deleteVirtualSensor(sensorId, this.props.removeVirtualSensorFromList);
	}
	render(){
	  		return <div className = 'transport_form  transport_page_sensor' >
					<ExistingVirtualSensors data = 			{this.state.list}
													callPopUp = 	{()=>this.popUp.open()}
													returndata = 	{this.updateVirtualSensor}
													fordelete = 	{this.deleteVirtualSensor}/>
					<PopUp 	ref = 			{(popUp)=>this.popUp = popUp}
			                head_text= 		{l_v.add_sensor_type}
			                head_back = 	{{backgroundColor:"#708090", color: 'white', fill: 'white'}}
			                popUp_size = 	{{maxWidth: '99%', width: '642px', height: '650px'}}
			                popUp = 		{this.popUp_activation}>
		                	<AddVirtualSensor 	save = 			{this.addVirtualSensor}
		                						ref = 			{addSensorForm =>this.addSensorForm = addSensorForm}
		                						deniedId = 		{createDeniedIdList(this.state.list)}
		                						cancel = 		{()=>this.popUp.close()}
		                						periodId = 		{this.props.periodId}/>

		         	</PopUp>
				</div>
	}
}

class ExistingVirtualSensors extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
					number_error: 		'',
					error_emty_field: 	'',
					virtualSensorsList: 				this.props.virtualSensorsList,
					active: 			false,
					editingData: 		null,
					column_height: 		'',
					win_width: 			'',
					table_content_width: '',
					forDelete: 	{}

		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			virtualSensorsList: nextProps.data,
		})
	}
	enterToState = (e) =>{
		const {editingData} = this.state;
		const {type, name, value, checked} = e.target;
		editingData[name] = type == 'checkbox' ? checked: value;
		this.setState({
			editingData: editingData
		})
	}
	update = (e) =>{
		e.preventDefault()
		let completedUpdate = () => {
			this.popUp.close();
			this.setState({editingData: null});
		}
		this.props.returndata(this.state.editingData, completedUpdate)
	}
	callDelete = (id, virtualSensorId) =>{
		this.setState({forDelete: {id: id, virtualSensorId: virtualSensorId}});
		this.confirmForRemove.popUp_activation();
	}
	deleteS = () =>{
		const {forDelete} = this.state;
		this.props.fordelete(forDelete.virtualSensorId)
	}
	callEdit = (virtualSensorInfo)=>{
		console.log("%c virtualSensorInfo", coCSS, virtualSensorInfo)
		 this.setState({
		 	editingData: virtualSensorInfo,
		 });
		 this.popUp.open();
	}
	cancelEdit = (e) =>{
		e.preventDefault()
		this.setState({
			editingData: null,
		});
		this.popUp.close()
	}
	render(){
		const {editingData, error_emty_field, number_error} = this.state;
		const formFooter = <FooterEditForm 	update = {this.update}
											cancel = {this.cancelEdit}/>;
		return 	<div className = {"existing_sensors Mshow"}>
						<p className = "nameForm">
							{l_v.virtyal_types_tracker}
							<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
							      				kindCapability = 'editSensors'>
								<button className  = 	'btn_plus'
										onClick = 		{this.props.callPopUp}>
									+
								</button>
							</SeparPermission>
						</p>
						{this.existingData() }
						<PopUp 	ref = 			{(popUp)=>this.popUp = popUp}
				                head_text= 		{l_v.edit_sensor_type}
				                head_back = 	{{backgroundColor:"#666666", color: 'white', fill: 'white'}}
				                popUp_size = 	{{maxWidth: '99%', width: '642px', height: '650px'}}
				                popUp = 		{this.popUp_activation}>
		                	<VirtualSensorFormContent 	enterToState = {this.enterToState}
				                								editableVirtualSensor = {editingData}
				                								error_emty_field = {error_emty_field}
																	number_error = {number_error}
																	formFooter = {formFooter} />

		         		</PopUp>
		         		<ConfirmRemove  confirmText = 	{l_v.delete_sensor_type(this.state.forDelete.virtualSensorId)}
										ref = 			{(confirmRemove)=>this.confirmForRemove = confirmRemove}
										remove =  		{this.deleteS}/>
				</div>
	}
	existingData = () =>{
		const {virtualSensorsList} = this.state;
		if(Array.isArray(virtualSensorsList) && virtualSensorsList.length){
			return 	<div className = "devicelist">
						<div className = "head_line oneLine">
											<div className = 'cell'> Id</div>
											<div className = 'cell'> {l_v.title}</div>
											<div className = 'cell'> {l_v.type} </div>
											<div className = 'cell'> {l_v.numbers_after_comma}</div>
											<div className = 'cell'> {l_v.units}</div>
											<div className = 'cell'> {l_v.visible}</div>

											<div className = 'cell'> {l_v.growing}</div>
											<div className = 'cell_edit cell'> </div>
											<div className = 'cell_delete cell'> </div>
						</div>
						<div 	className = 'table_content'
								ref = { table_content => this.table_content = table_content}
								style = {{
								 			height: this.state.column_height+'px',
								 			width: this.state.table_content_width + '%'
										}}>
							 	{
							 		virtualSensorsList.map( key => 
							 		<HigLightContext.Consumer key = {key.virtualSensorId}>
										{
											context =>	<VirtualSensorRowView  	
																							context = {context}
																							virtualSensorDto = {key}
																							callEdit = {this.callEdit}
																							callDelete = {this.callDelete}/>
										}
									</HigLightContext.Consumer>)
							 		
								}
						</div>
					</div>
		}else{
			return <div className = 'no_data'> {l_v.no_data} </div>
		}
	}
	setwidth(data){
		let head_line = data.head_line/data.table * 100;
		let win_width = window.innerWidth;
		let table_content = 100 - head_line;
		if (win_width < 720){
			this.setState({
				column_height: data.column_height+1,
				table_content_width: table_content,
			})
		}
		this.setState({
			win_width: win_width,
		})
	}
	scroolTop(){
		let height = this.state.column_height;
		let name = this.table_content;
		name.scrollTop -= height;
	}
	scroolBottom(){
		let height = this.state.column_height;
		let name = this.table_content;
	}

}

function VirtualSensorRowView(props) {
	const {virtualSensorDto} = props;
	return  	<div 	className = "oneLine"  
						key = {virtualSensorDto.virtualSensorId}
						style  = {props.context.VirtualSensorRow.getStyle(virtualSensorDto.virtualSensorId)}
						onMouseEnter = {e =>props.context.VirtualSensorRow.onMouseEnter(virtualSensorDto.virtualSensorId)}
						onMouseLeave = {e =>props.context.VirtualSensorRow.onMouseLeave(virtualSensorDto.virtualSensorId)}
						data-virtual_sensor_id = {virtualSensorDto.virtualSensorId}>
						<div className = 'cell'> 
							{virtualSensorDto.virtualSensorId}
							<FilterButtonInRow 	toogleFilteringConnectedItems = {props.context.VirtualSensorRow.toogleFilteringConnectedItems}
														row_filter_enabled = {props.row_filter_enabled}/>
						</div>
						<div className = 'cell cell_title'>
							<p className = 'this_name'>{virtualSensorDto.title}</p>
						</div>
						<div className = 'cell'> {virtualSensorDto.sensorType}</div>
						<div className = 'cell'> {virtualSensorDto.fracDigits}</div>
						<div className = 'cell'> {virtualSensorDto.units}</div>
						<div className = 'cell'> {(!!virtualSensorDto.visible)?l_v.yes:l_v.no}</div>
						<div className = 'cell'> {(!!virtualSensorDto.increasing)?l_v.yes:l_v.no}</div>
						<div className = 'cell_edit cell'>
							<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
							      				kindCapability = 'editSensors'>
								{editImg("edit", () => props.callEdit(virtualSensorDto))}
							</SeparPermission>
						</div>
						<div className = 'cell_delete cell'>
							<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
							      				kindCapability = 'editSensors'>
								{deleteImg('delete', ()=>props.callDelete(virtualSensorDto.sensorId, virtualSensorDto.virtualSensorId))}
							</SeparPermission>
						</div>
				</div>
					
}



function createDeniedIdList(list) {
	if(Array.isArray(list)){
		let arr = [];
		list.forEach(key =>{
			arr.push(key.virtualSensorId)
		})
		return arr
	}

}
function removeAllVirtualSensors(sensorsList) {
	const action = () => sensorsList.forEach(key=>sensorsApi.deleteVirtualSensor(key.virtualSensorId, null))
	return action
}
