import React            from 'react'
import SL               from 'sl-library'
import transportsApi    from 'actions/transportsApi.js'
import ClickOutside     from 'react-click-outside'
import EditGroup        from './EditGroup.jsx'
import CreateGroup      from './CreateGroup.jsx'
import { plusIcon }     from 'images/simple_svg_icons.jsx'
import language_variant from 'store/languages.jsx'
import Preloader        from '../preloader.jsx'

export default class Filter_transport extends React.Component{
	constructor(props) {
		super(props);
		const { userLogin } = this.props;
		this.state = {
			selectedGroup:         getDefaultGroup(userLogin) ? getDefaultGroup(userLogin) : { name: language_variant.allTransport, id: 'all' },
			visiblityStatus:       false,
			hover:                 '',
			groupsList:            null,
			activationEdetidGroup: null,
			activationCreateGroup: null,
		}
	}
	componentDidMount() {
		this.mounted = true;
		transportsApi.getGroupsList(this.props.userLogin, (groupsList)=>this.setState({groupsList:groupsList}))
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	toogleStatus = (e) => {
			this.setState({
				visiblityStatus: !this.state.visiblityStatus
			})
	}
	filterThisGroup = (e) => {
		const {userLogin} = this.props;

		let selectedId = e.target.value;
		this.props.updateFilterGroup(selectedId);
		let {groupsList} = this.state;

		let selectedGroup;
		if(selectedId == 'all'){
			selectedGroup = {name: language_variant.allTransport, id: 'all'}
		}else{
			selectedGroup= groupsList.find(key=>{
				if(key.id == 'all'){
					return
				}else{
					if(key.id == e.target.value){
						return key
					}
				}
			})
		}
		this.setState({
			selectedGroup: selectedGroup,
			visiblityStatus: false
		}, () => saveSelectedGroup(userLogin, selectedGroup))
	}
	callAddNewGroup = () => {
		this.createGroup.showFrom()
	}
	saveNewGroup = (groupName, vehicleIdsList) => {
		let saveVehicleToGroup = (newGroup) =>{
			let completeCreateGroup = (vehiclesInGroup) =>{
				this.createGroup.hideForm()
				if(this.mounted) this.preloader.hide()
				let {groupsList} = this.state;
				groupsList.push(newGroup)
				this.setState({
					groupsList: groupsList
				});
				transportsApi.updateOnAddGroupInVehicleList(vehiclesInGroup, newGroup)
			}
			transportsApi.addVehiclesToGroup(newGroup.id, vehicleIdsList, this.props.userLogin, completeCreateGroup)
		}
		this.preloader.show()
		transportsApi.createGroup(groupName, this.props.userLogin, saveVehicleToGroup)
	}
	removeGroup = (groupId) =>{
		let removeFromList = () =>{
			let {groupsList} = this.state;
			this.setState({
				groupsList: SL.removeElementFromList(groupsList, 'id', groupId)
			});
			this.editGroup.hideForm()
		}
		transportsApi.deleteGroup(groupId, this.props.userLogin, removeFromList)
	}
	callEditGroup = (e) => {
		let {groupsList} = this.state;
		let group = groupsList.find(key=>{
			if(key.id == e.target.value){
				return key
			}
		})
		this.editGroup.showForm(group)
	}
	updateGroup = (groupId, vehicleForAdd, vehicleForRemove, newName) =>{
		this.preloader.show()
		let updateName = (newInfoAboutGroup) => {
			let {groupsList} = this.state;
			let groupIndex = groupsList.findIndex(key=>{
				if(key.id == newInfoAboutGroup.id){
					return key
				}
			})
			groupsList[groupIndex] = newInfoAboutGroup;
			this.setState({
				groupsList: groupsList
			});

			if(this.mounted) this.preloader.hide()
		}
		let callbackOnAdd = (listOfVehiclesInGroup ) =>{
			let {groupsList} = this.state;
			let group = groupsList.find(key=>{
				if(key.id == groupId){
					return key
				}
			})
			transportsApi.updateOnAddGroupInVehicleList(listOfVehiclesInGroup, group);
			if(this.mounted) this.preloader.hide()
		}
		let callbackOnRemoveGroup = (listOfVehiclesInGroup) =>{
			transportsApi.updateOnRemoveGroupInVehicleList(listOfVehiclesInGroup)
			if(this.mounted) this.preloader.hide()
		}
		if(!!newName){
			transportsApi.updateGroupName(groupId, newName, this.props.userLogin, updateName)
		}
		if(SL.arrayIsEmpty(vehicleForAdd)){
			transportsApi.addVehiclesToGroup(groupId, vehicleForAdd, this.props.userLogin, callbackOnAdd)
		}
		if(SL.arrayIsEmpty(vehicleForRemove)){
			transportsApi.removeVehiclesFromGroup(groupId, vehicleForRemove, this.props.userLogin, callbackOnRemoveGroup)
		}
		this.editGroup.hideForm()
	}
	hideList = () => {
		this.setState({
			visiblityStatus: false
		});
	}
	render(ReactElement, DOMElement, callback){
		let {groupsList, selectedGroup, visiblityStatus} = this.state;
	    return 	<ClickOutside 	className =			"filter_transport"
		          				onClickOutside = 	{this.hideList} >
		            <span>{language_variant.group}</span>
		            <button className = "selected_value"
			                onChange = 	{this.filterThisGroup}
			                onClick = 	{this.toogleStatus}
			                value = 	{selectedGroup.id}>
			            {selectedGroup.name} <span className = {"select_triangle " + this.triangle()}></span>
		            </button>
		            <ViewOfGroupList 	groupsList = {groupsList}
		            					visiblityStatus = {visiblityStatus}
		            					selectedGroup = {selectedGroup}
		            					filterThisGroup = {this.filterThisGroup}
		            					callEditGroup = {this.callEditGroup}
		            					addNewGroup = {this.callAddNewGroup}/>

	      			<EditGroup 	ref = 		{editGroup => this.editGroup = editGroup}
				      			updateGroup = {this.updateGroup}
				      			removeGroup = {this.removeGroup}
		              			transportList =		{this.props.transport_list}/>
		            <CreateGroup 	ref = 				{createGroup => this.createGroup = createGroup}
		            				saveNewGroup = 		{this.saveNewGroup}
		              				transportList =		{this.props.transport_list}/>
		           <Preloader 	ref = { preloader => this.preloader = preloader } />		        
				</ClickOutside>
  	}
  	triangle = () =>{
	    if (!!this.state.visiblityStatus){
	      return 'triangle_top';
	    }else{
		    return 'triangle_bottom'
	    }
  	}
}

function ViewOfGroupList(props) {
	let {groupsList, filterThisGroup, callEditGroup, addNewGroup, selectedGroup, visiblityStatus} = props;
	return 	!!visiblityStatus?
			<ul className = {'hidden_select_list '}
               	style = 	{{paddingBottom: '0px'}}>
               	<li>
	                <button className = 	"option_filter"
							value = 		'all'
							style = 		{styleOfSelectedValue('all', selectedGroup.id)}
							onClick =		{filterThisGroup} >
	                  	{language_variant.allTransport}
	                </button>
                </li>
			   	<GroupsList {...props}/>
              	<li>
	                <button className =	"newGroup option_filter"
	                  		onClick =	{addNewGroup}>
		                {plusIcon()}
						{language_variant.createGroup}
	                </button>
            	</li>
           	</ul>:
           	null
}

function GroupsList(props) {
	let {filterThisGroup, groupsList, callEditGroup, selectedGroup} = props;
	if(SL.arrayIsEmpty(groupsList)){
	 return <div>
	 			{groupsList.map(el => <li  key = {el.id}>
	 				                      	<button className =	"option_filter"
	 						                        style =		{styleOfSelectedValue(el.id, selectedGroup.id)}
	 						                        value =		{el.id}
	 						                        onClick =	{filterThisGroup} >
	 					                        {el.name}
	 					                    </button>
	 				                      	<button className =	"edit_svg edit_btn"
	 					                      		value =		{el.id}
	 												onClick =	{callEditGroup}>
	 				                      	</button>
	 				                    </li>
	 				                )}
			</div>
	}else return null
}

function styleOfSelectedValue(thisGroup, selectedGroup) {
	if(selectedGroup == thisGroup || (thisGroup == 'showAllTransport' && selectedGroup == '')) {
		return { fontWeight: 'bold' }
	}
}

function getDefaultGroup(login) {
	const value = localStorage.getItem(`${login}-selected-group`)
	return value ? JSON.parse(value) : null
}

function saveSelectedGroup(login, group) {
	localStorage.setItem(`${login}-selected-group`, JSON.stringify(group))
}

export function getIdSelectedGroup(login) {
	console.log(`%c login-`, "color: green; font-weight: 400; text-transform:uppercase", login)
	const groupInfo = getDefaultGroup(login);
	return groupInfo && groupInfo.id !== 'all' ? groupInfo.id : ''
}