import React, {useState, useEffect} from "react"
import _ 										from 'lodash'
import l_a from 'store/languages.jsx'
const ref = React.createRef();
const Search = React.forwardRef((props, ref) =>{
	const [value, setValue] = useState('')
	function onChange(e) {
		setValue(e.target.value)
		props.onChange(e.target.value)
	}
	function clearValue() {
		setValue('')
		props.onChange('')
	}
	return 	<div className = 'search_wrapper'>
				<input 	name =          { `${ props.name ? props.name : 'search-GAP'}` }
						ref =           { ref }
						type =          'search'
						className =     "search_input"
						placeholder =   { props.placeholder }
						value =         { value }
						onChange =      { onChange }
						inputMode =     "search"
						autoComplete =  "on"
						incremental =   "true"
						mozactionhint = "true" />
						{
							value ?
							<button className = 'reset_btn'
									onClick = {clearValue}>
									{XIcons}
							</button>:
							null
						}
			</div>
})

export default Search;

export function SearchVsButton(props) {
	const [enteredValue, setValue] = useState('')
	useEffect(() => {
		const onPressEnter = (e) => {
			if(e.key === "Enter" &&  ref.current === document.activeElement){
				e.preventDefault();
				sendRequest()
			}
		};
		const addListenerForEnter = () => window.addEventListener('keydown', onPressEnter)
		const removeListenerForEnter = () => window.removeEventListener('keydown', onPressEnter)
		addListenerForEnter();
		return removeListenerForEnter
	})
	function updateValue(newValue) {
		setValue(newValue);
		props.onChange(newValue);
	}
	function onChange(newValue) {
		if(newValue === '' && enteredValue !== ''){
			updateValue(newValue);
			props.sendRequest();
		}else{
			updateValue(newValue);
		}
	}
	function sendRequest(e){
		e ? e.preventDefault() : null;
		props.sendRequest();
	}
	return 	<form className="search_vs_button" autoComplete = 'on' >
				<Search placeholder = 	{props.placeholder}
						name = 			{props.name}
						ref =			{ref}
						onChange = 		{onChange}/>
				<button className = 'btn_black btn_call_search_request'
						type =		"submit"
						onClick = 	{ sendRequest }> { l_a.search }</button>
			</form>
}


const XIcons = 	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="22" height="22" viewBox="0 0 22 22">
				    <defs>
				        <rect id="a" width="260" height="30" rx="15"/>
				    </defs>
				    <g fill="none" fillRule="evenodd">
				        <g>
				            <circle cx="11" cy="11" r="11" fill="#DFE6EC"/>
				            <path className='symbol' fill="#424F5B" d="M14.485 6L10.95 9.535 7.414 6 6 7.414l3.535 3.535L6 14.485 7.414 15.9l3.536-3.535 3.535 3.535 1.414-1.414-3.535-3.536 3.535-3.535z"/>
				        </g>
				    </g>
				</svg>;