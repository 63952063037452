import React 							from 'react'
import sensorApi 						from 'actions/updateSensor.jsx'
import l_v 								from "store/languages.jsx"
export default class AddRealSensorToMapping extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			list: 			'',
			showTextArea: 	false,
			error_emty_field: '',
		}
		this.initialState;
	}
	enterToState = (e) =>{
		this.setState({
			[e.target.name]:e.target.value
		});		
	}
	addMapping = () =>{
		
		if(!!this.state.list.length){
			let arr = this.state.list.split(',');
			sensorApi.connectRealToMultisensor(this.props.multiSensorId, arr)
			this.setState(this.initialState);
		}else{
			this.setState({error_emty_field: 'formError'});
		}
		
	}
	render() {
		return (
			<div>
				<button className = 'btn_black showTextArea'
						onClick = 	{()=>this.setState({showTextArea: !this.state.showTextArea})}>
						{l_v.add_sensors_to_mapping}
				</button>
				<div style = 	{this.visible(this.state.showTextArea)}>
					<textarea 	className = 	{'form_text_area ' + this.state.error_emty_field}
								type = 			'text'
								name = 			'list'
								value =		 	{this.state.list}
								onChange = 		{this.enterToState}
								placeholder = 	{l_v.enter_num}>
					</textarea>
					<div className = 'block_btn'>
						<button className = 'btn_black'
								onClick = 	{()=>this.setState({showTextArea: !this.state.showTextArea})}>
								{ l_v.cancel }
						</button>
						<button className = 'btn_black'
								onClick = 	{this.addMapping}>
								{l_v.add_mapping}
						</button>
					</div>
				</div>
			</div>
		);
	}
	visible(show){
		if(!!show){
			return {
				visibility: 'visible',
			}
		}else{
			return {
				visibility: 'hidden',
				maxHeight: 	"0px"
			}
		}
	}
}