import authorizationR 		from './authorization_reducer.jsx';
import languageR 			from './language_reducer.jsx'
import clientsR 			from './clients_reducer.jsx'
import devicesR 			from './device_reducer.jsx'
import {combineReducers} 	from 'redux'
export default combineReducers({
  languageR,
  authorizationR,
  clientsR,
  devicesR,
})

