import React, { Fragment }                     from "react"
import { Provider, connect }                   from 'react-redux'
import { bindActionCreators, applyMiddleware } from 'redux'
import transportsApi                           from 'actions/transportsApi.js'
import devicesApi                              from 'actions/devicesApi.js'
import l_v 									   from 'store/languages.jsx'

import {
	ifNoKey,
	arrayIsEmpty,
	doCopy,
	copyOfObject
}                                              from 'actions/ActionCreator.jsx'
import moment                                  from 'moment'
import { Gmaps, MarkerPlace }                  from '../gmaps/index.js'
import SwitchInput                             from '../component/SwitchInput.jsx'
import Phone, {
	formatPhoneNumber,
	parsePhoneNumber,
	isValidPhoneNumber
}                                              from 'react-phone-number-input'
import Pop_up                                  from '../component/Pop_up.jsx'
import { EditPhoneForm }                       from '../DevicePage.jsx'
import { editImg }                             from '../img.jsx'
import {
	styleByStatus,
	textOfDeviceStatus } 					   from "modules/DevicePage.jsx"
import WebsocketActions 					   from "actions/Websocket.js"



class CurrentInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sensors:             this.props.sensors,
			idVehicle:           this.props.idVehicle,
			info:                undefined,
			date:                undefined,
			lockState:           undefined,
			didntConecting:      false,
			tempPhone:           '',
			timestamp_connected: null,
			filter_old_data:     true
		}
	}
	componentWillReceiveProps(nextProps) {
		let { lockState, deviceSerial, socket, newDeviceConnectedStatus, newAuthCode } = nextProps;
		if(!!deviceSerial && !this.state.info && !this.requestWas) {
			transportsApi.currentTransportState(deviceSerial)
			transportsApi.getActualSignalization(deviceSerial, this.setSignalization)
			this.requestWas = true;
		}
		if(!!lockState && ifNoKey(lockState, 'deviceId') == this.props.deviceSerial) {
			this.setSignalization(lockState)
		}
		try {
			if(!!socket && !this.subscribeOnDevice && !!deviceSerial) {
				socket.send(JSON.stringify({
					"name": "registerSessionByDevice",
					"regId": deviceSerial,
					"commandString": "registerSessionByDevice"
				}))
				this.subscribeOnDevice = true
			}
		}catch(e) {
			console.warn(`%c socket.send e`, "color: orange; font-weight: bold; text-transform:uppercase", e)
		}
		
		//need global refactoring transport page vs store crutch))
		const deviceSerialFromWebsocket = 	ifNoKey(nextProps.stateFromSocket, 'serialNumber')
											|| ifNoKey(nextProps.stateFromSocket, 'deviceSerial')
											|| ifNoKey(nextProps.stateFromSocket, 'deviceId');
		if(newAuthCode && newAuthCode.deviceId == deviceSerial)	{
			this.setState({
				newAuthorizationCode: newAuthCode.authCode
			});
		}
		if(!!deviceSerial && deviceSerialFromWebsocket == deviceSerial) {
			this.rulesOnGetMessageFromSocket(nextProps.stateFromSocket)
		}
		if(newDeviceConnectedStatus && newDeviceConnectedStatus.deviceId == deviceSerial){
			this.setState({
				deviceStatusFromWS: newDeviceConnectedStatus.deviceStatus
			});
		}
	}
	rulesOnGetMessageFromSocket = (stateFromSocket) => {
		let {info} = this.state;
		if(!info){
			info = {};
		}
		if(stateFromSocket.name == 'SwAndHwMessage'){
			info = {...info, ...stateFromSocket}
		}
		if(stateFromSocket.name == 'SaveAuthCode'){

			info = {...info, authorizationCode: stateFromSocket.authCode }
		}
		if(!stateFromSocket.name){
			const oneWireSensors = updateOneSensorValues(info.oneWireSensors, stateFromSocket.oneWireSensors);
			const analogSensors = updateOneSensorValues(info.analogSensors, stateFromSocket.analogSensors);
			info = {...info, ...stateFromSocket, oneWireSensors: oneWireSensors, analogSensors: analogSensors};
		}


		if(info == 424) {
			this.setState({ didntConecting: true, info: null });
		} else {
			this.initialDeviceState = info;
			this.setState({
				info: copyOfObject(info),
				didntConecting: false
			});
		}
		// this.setInfo(nextProps.stateFromSocket)
	}
	setSignalization = (info) => {
		this.setState({
			lockStateTime: info.lockStateTime,
			lockState: info.lockState
		});
	}
	setInfo = (info) => {
		if(info == 424) {
			this.setState({ didntConecting: true, info: null });
		} else {
			this.initialDeviceState = info;
			this.setState({
				info: info,
				didntConecting: false
			});
		}
	}
	setCode = (code) =>
	 {
		this.setState({ deviceAutorizCode: code })
	}
	enterToState = (e) => {
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	onMapCreated(map) {
		map.setOptions({
			disableDefaultUI: true,
		});
	}
	update = (e) => {
		if(e.target.name == 'date_request') {
			transportsApi.getDataFromPeriod(this.props.deviceSerial, this.state.date)
		} else {
			transportsApi.currentTransportState(this.props.deviceSerial, this.setInfo)
		}
	}
	setLock = (value) => {
		let callback = (lockState) => {
			let ErrorSetLock = ifNoKey(lockState, 'name') == 'StartLockingForDevice' || ifNoKey(lockState, 'name') == 'EndLockingForDevice' ? true : false;
			this.setState({
				lockStatus: ifNoKey(lockState, 'lockState'),
				ErrorSetLock: ErrorSetLock,
			});
		}
		transportsApi.updateSignalization(this.props.deviceSerial, value, callback)
	}
	render(){
		let {
			lockState,
			lockStateTime,
			info,
			didntConecting,
			tempPhone,
			filter_old_data,
			deviceStatusFromWS,
			connectedRfidCard,
			connectedTrailer,
			newAuthorizationCode,
		} = this.state;
		let {deviceSerial, activePeriodId, currentDeviceInfo} = this.props;
		let alwaysIgnitionOn = ifNoKey(info, 'alwaysIgnitionOn');
		let simNumber = ifNoKey(info, 'simNumber');
		let simUpdatingDate = ifNoKey(info, 'simUpdatingDate');
		const realDeviceStatus = deviceStatusFromWS ? deviceStatusFromWS : ifNoKey(info, 'deviceStatus');
		if(!!info){
			return 	<div className = 'current_info' id = "current_info">
						<div className = "status_device">
							<div>
								<p>{l_v.tracker_number}: <span>{deviceSerial}</span></p>
								<p >{l_v.protocol}: 	<span  style = {{
									backgroundColor: getDeviceColor(info.deviceType),
									color: 'white',
									padding: '3px 5px', fontWeight: '500'
								}} >{info.deviceType}</span></p>
								<p>HW: <span>{info.hardwareVersion}</span>, SW: <span>{info.softwareVersion}</span></p>
								{!!info.imei?<p>IMEI: <span>{info.imei}</span></p>:null}
								<PhoneNumber 	simNumber = {simNumber}
												simUpdatingDate = {simUpdatingDate}/>

							</div>

						</div>
						<div className = 'one_switch'>
							<span className='name_switch'>{l_v.show_new_data}</span>
							<SwitchInput 	result = {(e)=>this.setState({filter_old_data: e})}
											isChecked = {filter_old_data}
											name='filter_old_data'
											ref="switch"
											tooltip={{on: l_v.show, off: l_v.hide}}/>
						</div>
						<InfoBlock 	alwaysIgnitionOn = {alwaysIgnitionOn} info = {info}
									deviceStatus = {realDeviceStatus}
									newAuthorizationCode = {newAuthorizationCode}
									filter_old_data = {filter_old_data}
									lockStateTime = {lockStateTime} lockState = {lockState}/>
						<Gmaps 	params =		{paramsMap}
								width =			{'500px'}
								height =		{'500px'}
								className = 	{"map"}
								lat =			{ifNoKey(this.state.info, 'latitude')}
	  							lng =			{ifNoKey(this.state.info, 'longitude')}
	  							loadingMessage=	{'Be happy'}
	  							zoom=			{12}
								onMapCreated=	{this.onMapCreated}>
								<MarkerPlace 	lat =	{ifNoKey(this.state.info, 'latitude')}
	      										lng =	{ifNoKey(this.state.info, 'longitude')}/>
						</Gmaps>


					</div>
		}else {
			return 	<div className = 'current_info' id = "current_info">
							{
								didntConecting ?
								l_v.device_added_not_connected :
								l_v.no_data_current_state
							}
					</div>
		}
	}

}
export default connect(
      state => ({
		lockState: 	state.devicesR.lockState,
		socket: 	state.authorizationR.socket,
		stateFromSocket: state.devicesR.stateFromSocket,
		newDeviceConnectedStatus: state.devicesR.newDeviceConnectedStatus,
		newAuthCode: 			  state.devicesR.newAuthCode,
      }),
)(CurrentInfo)

export 	const paramsMap =      {v: '3.exp', key: 'AIzaSyAtvLWQRKn-krgnNQBFx3anEdYPwe_7v9g'};//all apiKey   //AIzaSyBwFzhYEM1A-xAAV-ssLore445V0ZbCdAI

function ViewAnalog(props) {
	const {analogSensors, filter_old_data, timeStampLastData} = props;
	if(arrayIsEmpty(analogSensors)){
		return 	<div style = {{color: 'grey'}} className = 'block_sensors'>
					<div className = 'info_line'><span className = "key_name">{l_v.analog_sensors}</span></div>
				{
					analogSensors.map((key, index) =>{
						if(filter_old_data){
							if(key.timestamp == timeStampLastData){
								return 	<div 	style = {{color: 'grey'}}
												className = 'analog_sensor'
												key = {key.sensorNumber + ' analogSensors' + index}>
												<span className = "key_name">[{key.sensorNumber}] : </span>{key.value} ({moment(key.timestamp).format('DD.MM.YYYY в HH:mm:ss')})
										</div>
							}
						}else {
							return <div 	style = {{color: 'grey'}}
											className = 'analog_sensor'
											key = {key.sensorNumber + ' analogSensors' + index}>
											<span className = "key_name">[{key.sensorNumber}] : </span>{key.value} ({moment(key.timestamp).format('DD.MM.YYYY в HH:mm:ss')})
									</div>
						}

					}

					)
				}
				</div>
	}else{
		return 	<div style = {{color: 'grey'}} className = 'block_sensors'>
					<div><span className = "key_name">{l_v.analog_sensors}: </span> {l_v.there_is_no}</div>
				</div>
	}
}

function ViewWire(props){
	const {oneWireSensors, filter_old_data, timeStampLastData} = props;
	if(arrayIsEmpty(oneWireSensors)){
		return 	<div style = {{color: 'grey'}} className = 'block_sensors'>
					<div className = 'info_line'><span className = "key_name">{l_v['1_wire_sensors']}</span></div>
				{
					oneWireSensors.map((key, index) =>{
						if(filter_old_data){
							if(key.timestamp == timeStampLastData){//if date is from last websocket message
								return <div 	style = {{color: 'grey'}}
												className = 'analog_sensor'
												key = {key.sensorNumber + ' analogSensors' + index}>
												<span className = "key_name">[{key.sensorNumber}] : </span>{key.value} ({moment(key.timestamp).format('DD.MM.YYYY в HH:mm:ss')})
										</div>
							}
						}else{
							return <div 	style = {{color: 'grey'}}
											className = 'analog_sensor'
											key = {key.sensorNumber + ' analogSensors' + index}>
											<span className = "key_name">[{key.sensorNumber}] : </span>{key.value} ({moment(key.timestamp).format('DD.MM.YYYY в HH:mm:ss')})
									</div>
						}

					})
				}
				</div>
	}else{
		return 	<div style = {{color: 'grey', paddingBottom: '10px'}} className = 'block_sensors'>
					<div><span className = "key_name">{l_v['1_wire_sensors']}: </span> {l_v.no}</div>
				</div>
	}
}

function PhoneNumber(props) {
	const {simNumber, simUpdatingDate} = props;
	return 	<Fragment>
				<div className = 'info_line'>
					<span className = "key_name" >SIM:</span> { simNumber ? simNumber : '---' } (<span className = "key_name" >{l_v.updated}:</span> {toDate(simUpdatingDate)})
				</div>
			</Fragment>
}

function InfoBlock(props) {
	const { info, alwaysIgnitionOn, lockStateTime, lockState, filter_old_data, deviceStatus, newAuthorizationCode } = props;
	return 	<div className = "info_block">
				
				<div  className = 'info_line'>
					<span 	className = "key_name"
						  	style = {{color: !info.deviceOff?'green':'red'}}>{/*{I think deviceOff is artefact}*/}
							{l_v.recieving_data_server} - <span style = {{color: !info.deviceOn?'red':'inherit'}}>{!!info.deviceOn?l_v.device_is_ON:l_v.device_is_OFF}</span>
					</span>
				</div>
				<InfoLine 	line_name =  { l_v.data_on_server } 
							line_value = { moment(info.timestamp).format('DD.MM.YYYY в HH:mm:ss') }
							lineStyle =  {{ color: 'blue' }}/>

				<InfoLine 	line_name =  { l_v.device_status_on_server } 
							line_value = { deviceStatus ? textOfDeviceStatus(deviceStatus) : '---'}
							lineStyle =  { styleByStatus(deviceStatus) }/>

				<InfoLine 	line_name =  { l_v.longtitude } 
							line_value = { info.longitude }
							lineStyle =  {{ color: 'brown' }}/>

				<InfoLine 	line_name =  { l_v.latitude } 
							line_value = { info.latitude }
							lineStyle =  {{ color: 'brown' }}/>

				<InfoLine 	line_name =  { l_v.speed } 
							line_value = { `${convertMettersToKilometers(info.speed)} ${l_v.kmH}` }
							lineStyle =  {{ color: 'black' }}/>

				<InfoLine 	line_name =  { l_v.engine } 
							line_value = { info.ignitionOn ? l_v.device_is_ON : l_v.device_is_OFF }
							lineStyle =  {{ color: info.ignitionOn ? 'green' : 'red' }}/>

				<InfoLine 	line_name =  { l_v.digital_sensors } 
							line_value = { info.logicalInputs ? info.logicalInputs : l_v.missing }
							lineStyle =  {{ color: 'grey' }}/>

				<ViewAnalog analogSensors = 	{info.analogSensors}
							filter_old_data = 	{filter_old_data}
							timeStampLastData = {info.timestamp}/>

				<ViewWire oneWireSensors = 		{info.oneWireSensors}
							filter_old_data = 	{filter_old_data}
							timeStampLastData = {info.timestamp}/>

				<PeriodTramsmit info = {info}/>

				<InfoLine 	line_name =  { l_v.authorisation_code } 
							line_value = { newAuthorizationCode ? newAuthorizationCode : info.authorizationCode }
							lineStyle =  {{ color: 'orange' }}/>

				<InfoLine 	line_name =  { l_v.signaling } 
							line_value = { `${ lockState ? l_v.on : l_v.off } ( ${ lockStateTime ? moment(lockStateTime).format('DD.MM.YYYY в HH:mm:ss') : null })` }
							lineStyle =  {{ fontWeight: 'bold' }}/>

				<InfoLine 	line_name =  { l_v.ignition } 
							line_value = { alwaysIgnitionOn ? l_v.always_on : l_v.from_sensor }
							lineStyle =  {{ color: !alwaysIgnitionOn?'green':'red' }}/>
				<InfoLine 	line_name =  { l_v.trailer } 
							line_value = { 
											info.connectedTrailer ?
											`${info.connectedTrailer.name} [${info.connectedTrailer.idSerial}]` :
											'---' 
										 }
							lineStyle =  { null }/>
				<InfoLine 	line_name =  { l_v.rfid_card } 
							line_value = { 
											info.connectedRfidCard ?
											`${info.connectedRfidCard.driverName} [${info.connectedRfidCard.number}]` :
											'---'
										 }
							lineStyle =  { null }/>
			</div>
}

function InfoLine(props) {
	const { line_name, line_value, lineStyle } =  props;	
		return 	<div  className = 'info_line'>
					<span className = "key_name" style = { lineStyle ? lineStyle : null }>
						{ line_name }:</span> { line_value ? line_value : '---' }

				</div>
}


function PeriodTransmitView(props) {
	const {ThisTransport, info} = props;
	let period1, period2;
	if(ThisTransport){
		period1 = ThisTransport.period1;
		period2 = ThisTransport.period2;
	}else{
		period1 = info.period1;
		period2 = info.period2;
	}
	return  <div 	style = {{color: 'purple'}} className = 'info_line'>
				<span className = "key_name">{l_v.transfer_periods}: </span>{period1}/{period2} {l_v.sec}
			</div>
}

const PeriodTramsmit =  connect(
	    state => ({
			ThisTransport: 		state.devicesR.ThisTransport,
	    })
)(PeriodTransmitView)

function hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length== 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',0.2)';
    }
    throw new Error('Bad Hex');
}

const colors = ['#4179f8','#8B0000','#B22222','#FF0000','#FA8072','#FF6347','#FF7F50','#FF4500','#D2691E','#F4A460','#FF8C00','#FFA500',
	'#B8860B','#DAA520','#FFD700','#808000','#FFFF00','#9ACD32','#ADFF2F','#7FFF00','#7CFC00','#008000','#00FF00',
	'#32CD32','#00FF7F','#00FA9A','#40E0D0','#20B2AA','#48D1CC','#008B8B','#00FFFF','#00CED1','#00BFFF','#1E90FF',
	'#4169E1','#000080','#00008B','#0000CD','#0000FF','#8A2BE2','#9932CC','#9400D3','#800080','#8B008B','#FF00FF',
	'#C71585','#FF1493','#FF69B4','#DC143C','#A52A2A','#CD5C5C','#BC8F8F','#F08080','#FFFAFA','#FFE4E1','#E9967A',
	'#FFA07A','#A0522D','#FFF5EE','#8B4513','#FFDAB9','#CD853F','#FAF0E6','#FFE4C4','#DEB887','#D2B48C','#FAEBD7',
	'#FFDEAD','#FFEBCD','#FFEFD5','#FFE4B5','#F5DEB3','#FDF5E6','#FFFAF0','#FFF8DC','#F0E68C','#FFFACD','#EEE8AA',
	'#BDB76B','#F5F5DC','#FAFAD2','#FFFFE0','#FFFFF0','#6B8E23','#556B2F','#8FBC8F','#006400','#228B22','#90EE90',
	'#98FB98','#F0FFF0','#2E8B57','#3CB371','#F5FFFA','#66CDAA','#7FFFD4','#2F4F4F','#AFEEEE','#E0FFFF','#F0FFFF',
	'#5F9EA0','#B0E0E6','#ADD8E6','#87CEEB','#87CEFA','#4682B4','#F0F8FF','#708090','#778899','#B0C4DE','#6495ED',
	'#E6E6FA','#F8F8FF','#191970','#6A5ACD','#483D8B','#7B68EE','#9370DB','#4B0082','#BA55D3','#DDA0DD','#EE82EE',
	'#D8BFD8','#DA70D6','#FFF0F5','#DB7093','#FFC0CB','#FFB6C1'];

function convertMettersToKilometers(meterPerSecond) {
	if(Number(meterPerSecond)){
		meterPerSecond = Math.round(meterPerSecond*3.6*100)/100;
	}
	return 	meterPerSecond
}

function toDate(data){
	if(!!data){
		return moment(data).format("DD MMM YY,  HH:mm:ss");
	}else{return '---'}
}

function updateOneSensorValues(oldOneWireSensors, newOneWireSensors) {
	if(!arrayIsEmpty(oldOneWireSensors)){
		oldOneWireSensors = newOneWireSensors;
	}
	if(arrayIsEmpty(newOneWireSensors)){
		newOneWireSensors.forEach(newOneSensor => {
			const indexEqualSensor = oldOneWireSensors.findIndex(oldOneSensor => oldOneSensor.sensorNumber == newOneSensor.sensorNumber);
			oldOneWireSensors[indexEqualSensor] = newOneSensor;
		})
	}
	return oldOneWireSensors
}

export function getDeviceColor(deviceName) {
    switch (deviceName) {
        case 'GLOBUS':
            return '#357ac5'; // или '#367bcc', если требуется соответствие лого
        case 'TELTONIKA':
            return '#0D1777';
        case 'TESWELL':
            return '#ae370f';
        case 'NAVITRACK':
            return '#9DB700';
        case 'BITREK':
            return '#00557f';
        case 'TAG_PRO':
            return '#e88927';
        case 'WIALON_IPS_1_1':
            return '#e86885'; // или '#e43644', если требуется альтернативный цвет
        default:
            return '#63777f'; // Цвет для моделей, не указанных в списке
    }
}
