import React, { Fragment }                     from "react"
import Users                                   from 'actions/users.js'
import { Provider, connect }                   from 'react-redux'
import { bindActionCreators, applyMiddleware } from 'redux'
import { Link }                                from 'react-router-dom'
import SL  												  from "sl-library"
import moment                                  from 'moment';
import { FormChangePass, FormChangeDesc }      from './OneClient.jsx'
import SeparPermission                         from './SeparPermission.jsx'
import {
	editImg,
	deleteImg,
	closeImg,
	goToClientAppImg,
	qrIcon
}                                              from '../img.jsx'
import authInClient                            from './authInClient.js'
import l_v                                     from "store/languages.jsx"

export const QrCodeContext = {};

class OneUser extends React.Component {
	constructor(props) {
		super(props);
		// magic with blocking
		const {expires, blocked} = this.props;
		this.state = {
			login: 				this.props.login,
			user_description: 	this.props.description,
			belongsTo: 			this.props.belongsTo,
			blocked: 			magickofblocking(expires, blocked).blocked,
			visibilityform_desc: '',
			visibilityform: 	'',
			color: 				magickofblocking(expires, blocked).color,
			expires: 			magickofblocking(expires, blocked).expires,
		}
	}
	componentWillReceiveProps(nextProps) {
			if (this.state.login == nextProps.user_login){
				this.setState({
					belongsTo: nextProps.user_group,
					user_description: nextProps.user_description,
					blocked: magickofblocking(nextProps.user_expires, nextProps.user_blocked).blocked,
					color: magickofblocking(nextProps.user_expires, nextProps.user_blocked).color,
				})

			}
			if (nextProps.filter_group == this.state.belongsTo){
				if (this.state.targetstyle == ''){
					this.setState({
						targetstyle: '#7faef5'
					})
				}
			}
			else {
				this.setState({
						targetstyle: ''
				})
			}
	}
	setBlocking = (event) => { //da, droch)
		let value = event.target.value;
		let capname = event.target.attributes.getNamedItem('data-capname').value;
		(value === 'true') ? (value = false):(value = true);
		Users.updateCapability({
					"login": event.target.name,
					'adddata': '/block',
					"capability":
										{
											[capname]: value,
										}

				})
	}
	showFormChangeDesc = () => {
		let visibility = (this.state.visibilityform_desc == '') ? ('show_form'): ('');
		this.setState({
			visibilityform_desc: visibility,
		})
	}
	showform = () =>{ //password FormChangePass
		let visibility = (this.state.visibilityform == '') ? ('show_form'): ('');
		this.setState({
			visibilityform: visibility,
		})
	}
	updateDesc = (data) =>{
		Users.editValueOfUser({
					login: this.state.login,
					newValue:{
						description: data
					}
				});
		this.showFormChangeDesc();
	}
	sendpassword = (data) =>{
			Users.editPassword({
					"login": this.state.login,
					"password":{
						"newPassword": data
					}
				});
			this.showform();
	}
	componentDidMount() {
		let table = document.querySelector('.userlist').clientWidth;
		let head_line = document.querySelector('.head_line').clientWidth;
		let column_height = document.querySelector('.table_content .oneLine').clientHeight ;
		this.props.returnwidth({
									table:table,
									head_line:head_line,
									column_height: column_height
								})
	}
	// deleteuser = () =>{

 //            Users.deleteUser(this.state.forDelete, updateListAferDelete);
 //            this.refs.delete.popUp_activation({
 //                active: false
 //            })
 //            this.setState({
 //                forDelete: null,
 //            });
 //    }
 //    callPopUp = (e) =>{
 //        this.setState({
 //            forDelete: e,
 //        });
 //        this.ConfirmTextforRemove = language_variant.are_you_sure  + e.userlogin +'?';
 //        this.refs.delete.popUp_activation({
 //            active: true
 //        })
 //    }
	render(){
		const {belongsTo} = this.state;
		const {profileDto, expires} = this.props;
		const creationDate = SL.ifNoKey(profileDto, 'creationDate');

		return(
				<div 	className = "oneLine"
						key = { this.props.login}
						style = {{ background: this.state.color }}>
					<FormsForEdit 	visibilityformPass = {this.state.visibilityform}
									sendpassword = {this.sendpassword}
									hideformPass = {this.showform}
									visibilityform_desc = {this.state.visibilityform_desc}
									updateDesc = {this.updateDesc}
									showFormChangeDesc = {this.showFormChangeDesc}/>
						<CellLogin 	login = {this.state.login}
									accountType = {this.props.accountType}
									belongsTo = {this.state.belongsTo}/>
					<div className = "cell_byWhom cell">
						< Link 	to = { `/client/${belongsTo}/edit`}
									className = "link_client"> {belongsTo} </Link>
					</div>
					<CellDescription 	user_description = {this.state.user_description}
										showFormChangeDesc = {this.showFormChangeDesc}
										display = {this.props.display}/>
					<div className = "cell_created cell">
						{creationDate ? moment(creationDate).format('DD.MM.YYYY HH:mm') : ''}
					</div>
					<CellBlocked 	login = {this.props.login}
										expires = {expires}
										status = {this.state.blocked}
										setBlocking = {this.setBlocking}
										display = {this.props.display} />
					{/*<CellEdit showform = {this.showform} display = {this.props.display} />
					<CellDelete delete = {this.props.delete}
								login = {this.state.login}
								display = {this.props.display}/>*/}

				</div>
			)
	}
}


function magickofblocking(dataexp, user_blocked) { //sarcasm
	let color;
	let expires = '';
	if(dataexp) {
		expires = moment(dataexp).format('Do MMMM YYYY');
		color = '#f3f3c4';
		if(moment().isAfter(moment(dataexp))) {
			expires = moment(dataexp).fromNow();
			color = '#fda1a1';
			user_blocked = true;
		}
	}
	if(user_blocked) {
		color = '#fda1a1';
	}
	return {
		color: 	 color,
		blocked: user_blocked,
		expires: expires,
	}
}

export default connect(
  state => ({
	// user_capabilities: state.clientsR.user_capabilities,
    user_login: 		state.clientsR.user_login,
    user_blocked: 		state.clientsR.user_blocked,
    user_description: 	state.clientsR.user_description,
    user_group: 		state.clientsR.user_group,
    user_expires: 		state.clientsR.user_expires,
    filter_group: 		state.clientsR.filter_data
}))(OneUser);




function CellLogin(props) {
	const {belongsTo, login, accountType} = props;
	return 	<div className = "cell_login cell">
				{login}{/*<Link to = {"/user/" + belongsTo + "/" + login + '/edit'}>{login}</Link>*/}
				<SeparPermission 	alowed = 'ADMIN, SUPERADMIN, DEALER'>
					<button onClick = { ()=>authInClient(login, accountType) }
							className = 'goToClient'>
							{ goToClientAppImg('goToClientIcon', accountType) }
					</button>

				</SeparPermission>
				<button onClick = {()=>QrCodeContext.showQR(login)}
						className = 'showQR' >{qrIcon('qr_icon')}</button>
			</div>
}
//

function CellBlocked(props) {
	return 	<div className = "cell_blocked cell">
					<SeparPermission 	alowed = 'ADMIN, SUPERADMIN, DEALER'
											kindCapability = 'setBlocking'>
						<CheckBoxBlocked {...props} />
					</SeparPermission>
					{props.expires ? `- ${l_v.blocked} ${moment(props.expires).format('DD.MM.YYYY HH:mm')}` : ''}

			</div>
}

function CheckBoxBlocked(props) {
	return 	<p key = {props.login}>
					<span className = 'select_label' >{props.status ? l_v.yes : l_v.no}</span>
				</p>
}



function CellDescription(props) {
	return 	<div className = "cell_desc cell">
				{props.user_description}
				{editImg("edit", props.showFormChangeDesc, {display: props.display})}
			</div>
}

function CellEdit(props) {
	return 	<div className = "cell_edit cell" style={{display: props.display}}>
				{editImg("edit", props.showform)}
			</div>
}



function CellDelete(props) {
	return 	<div className = "cell_delete cell" style={{display: props.display}}>



			</div>
}

/*{
deleteImg("delete", ()=>props.delete({
									userlogin: props.login,
									kindUser: 'USER',
								}))
								}*/
function FormsForEdit(props) {
	return  <Fragment >
				<FormChangePass visibilityform = 	{props.visibilityformPass}
								password = 			{props.sendpassword}
								hideform = 			{props.hideformPass} />
				<FormChangeDesc visibilityform = 	{props.visibilityform_desc}
								desc = 				{props.updateDesc}
								hideform = 			{props.showFormChangeDesc}/>
			</Fragment>
}
