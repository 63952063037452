import axios                from 'axios'
import { setDeportaionUrl } from './small_actions.jsx'
import store                from 'store/configureStore.jsx'
import SL 					from "sl-library"
import infoStore 			from './infoStore.js'
import formAction           from 'actions/formAction.js'
import l_v 					from "store/languages.jsx"

let status;

export default function bugReport(error, dataForRequest, actionName) {
	console.log("%c BUGREPORT", coCSS, error, error.message === "Network Error")//errorStatus == 502
	const errorStatus = SL.ifNoKey(error, "status");

	if(error.response) {
		console.log('error data', error.response.data);
		console.log('error status', error.response.status);
		console.log('error headers', error.response.headers);
		status = error.response.status;
		setDeportaionUrl(window.location.href)
		let message = error.response.status;
		let data = (error.response.data.message || error.response.data);
		if(error.response.status == 422){
			rulesForError422(SL.ifNoKey(error.response, 'data'))
			return
		}
		if(error.response.status == 403) {
			if403(error.response.data)
			return
		}
		if(error.response.status == 401 || (!error.response.error && error.response.status == 401)) {
			let state = store.getState();
			let token = state.authorizationR.token;
			let base_url = state.authorizationR.base_url;
			let bonus_url_user = state.authorizationR.bonus_url_user;
			formAction.clearLoginInfo();
			window.location.hash = '';
			return
		}
		if(error.response.status == 409) {
			if409(error.response.data, true)
			return
		}
		if(error.response.status == 429) {
			setText(l_v.request_limit_exceeded)	
		}
		setText('Упс, что то пошло не так: ошибка ' + message);

	}
	if(error == 'Error: Network Error' || errorStatus == 502) {
		setText('Проблемы со связью с сервером. Попробуйте позже или проверьте интернет соединение');
		return
	}
	!DEVELOPMENT ?
	requestToSlack(
			               infoStore.login, 
			               convertDataForSlackApi( collectInfoForMessageAboutError(error, dataForRequest,  actionName)), 
			               () => setText('Message about error was send to support')
		               ) :
	null
}

function saveUrl() {
	localStorage.setItem('url_before_deportation', window.location.href)
}

function if403(data) {
	switch (true) {
		case data.indexOf('owner is not valid') >= 0:
			setText(l_v.vehicle_owner_can_be_only_client)
			break;
		case data.indexOf('Access is forbidden') >= 0:
			setText(l_v.your_premission_is_not_sufficient)
			break;
		case data.indexOf('User with such login already exists') >= 0:
			setText(l_v.userVS_this_login_exist)
			break;
		case data.indexOf('New period is not valid') >= 0:
			setText(l_v.period_conflict)
			break;
		case data.indexOf('Real sensor name is already present') >= 0:
			setText(l_v.current_real_sensor_oldready_added)
			break;
		default:
			setText('error 403 ' + data)
			break;
	}
}

function rulesForError422(errorText) {
	console.log("%c errorText", coCSS, errorText)
	const text = new getСoincidenceText(errorText);
	if(text.compareText("No gmail account for")){// "No gmail account for " + gmailAddress
		setText(l_v.Account + text.variable + l_v.absent_in_gmail);
		return
	}
	if(text.compareText("rfidCard number:")){// "rfidCard number: " + getNumber + " already exist"
		setText(l_v.rfidCard_number + text.variable + l_v.already_exists);
		return
	}
	if(text.compareText("Expiry date:")){// "Expiry date: " + getExpiryDate + " can'n be in past"
		setText(l_v.expiry_date + text.variable + l_v.cant_be_in_the_past);
		return
	}
	if(text.compareText("Vehicle with id:", 'belongs to the group already')){// "Vehicle with id " + vehicleId + " belongs to the group already."
		setText(l_v.vehicle_with_id + text.variable + l_v.belongs_to_the_group_already, 422);
		return
	}
	if(text.compareText("Vehicle with id:", 'does not belong to the group')){// "Vehicle with id " + vehicleId + " does not belong to the group."
		setText(l_v.vehicle_with_id + text.variable + l_v.does_not_belong_to_the_group, 422);
		return
	}
	if(text.compareText("No required parameter GeoCoding")){// "No required parameter GeoCoding"
		setText(l_v.No_required_parameter_GeoCoding, 422);
		return
	}
	if(text.compareText("geoCoding can not be null")){// "No required parameter GeoCoding"
		setText(l_v.geoCoding_cant_be_null, 422);
		return
	}
	if(text.compareText("equipmentWidth can not be null")){// "equipmentWidth can not be null"
		setText(l_v.equipmentWidth_cant_be_null, 422);
		return
	}
	if(text.compareText("longerThen can not be null")){// "longerThen can not be null " + longerThen
		setText(l_v.longerThen_cant_be_null + text.variable, 422);
		return
	}
	if(text.compareText("ZoneType can not be null")){// "ZoneType can not be null " + zoneTypesId
		setText(l_v.ZoneType_cant_be_null + text.variable, 422);
		return
	}
	if(text.compareText("zone type with id:")){// "zone type with id: " + getZoneTypeId + " not exist"
		setText(l_v.zone_type_with_id + text.variable + l_v.not_exist, 422);
		return
	}
	if(text.compareText("Impossible create zone from square with coordinate <=1")){// "Impossible create zone from square with coordinate <=1"
		setText(l_v.Impossible_create_zone_from_square_with_coordinate, 422);
		return
	}
	if(text.compareText("not exist device in with id:")){// "not exist device in with id: " + getDeviceId
		setText(l_v.not_exist_device_in_with_id + text.variable, 422);
		return
	}
	if(text.compareText("Invalid device type:")){// "Invalid device type: " + deviceType
		setText(l_v.not_exist_device_in_with_id + text.variable, 422);
		return
	}
	if(text.compareText("tankVolumeStart can'n be more than tankVolume")){// "tankVolumeStart can'n be more than tankVolume"
		setText(l_v.tankVolumeStart_cant_be_more_tankVolume, 422);
		return
	}
	if(text.compareText("fuel volume exceeded tank volume")){// "fuel volume exceeded tank volume"
		setText(l_v.fuel_volume_exceeded_tank_volume, 422);
		return
	}
	if(text.compareText("Can't to find FUEL LEVEL BEFORE EVENT, because tank volume start can't be determine!")){// "Can't to find FUEL LEVEL BEFORE EVENT, because tank volume start can't be determine!"
		setText(l_v.Cant_find_FUEL_LEVEL_BEFORE_EVENT, 422);
		return
	}
	if(text.compareText("Rfid card can not be empty")){// "Rfid card can not be empty"
		setText(l_v.Rfid_card_can_not_be_empty, 422);
		return
	}
	if(text.compareText("Rfid card id")){// "Rfid card id: " + rfidCardId + " can not be null"
		setText(l_v.Rfid_card_id_can_not_be_null, 422);
		return
	}
	if(text.compareText("No actual device mapping for control entity with id:")){// "No actual device mapping for control entity with id: " + getId
		setText(l_v.No_actual_device_mapping_for_control_entity + text.variable, 422);
		return
	}
	if(text.compareText("Current vehicle with id:")){// "Current vehicle with id: " + vehicleId + " does not have current device mapping"
		setText(l_v.Current_vehicl_with_id + text.variable  + l_v.does_not_have_current_device_mapping, 422);
		return
	}
	if(text.compareText("Vehicle has incorrect width:")){// "Current vehicle with id: " + vehicleId + " does not have current device mapping"
		setText(l_v.Vehicle_has_incorrect_width + text.variable, 422);
		return
	}
	if(text.compareText('Trailer serial id:')){
		setText('Прицеп с id-' + text.variable + ' уже существует', 422);
		return
	}
	setText(errorText, 422)
	// "Vehicle has incorrect width: " + getWidth
	function getСoincidenceText(errorText) {
		this.partOfMessage = null;
		this.variable = null;

		this.compareText = function(partOfMessage, secondPartOfMessage) {
			this.partOfMessage = partOfMessage;
			let result = errorText.indexOf(partOfMessage) !== -1;
			result ? this.getVariable() : null;
			if(secondPartOfMessage){
				let secondResult = errorText.indexOf(secondPartOfMessage) !== -1;
				if(secondResult && result){
					return true
				}else{
					return false
				}
			}
			return result
		}
		this.getVariable = function(){
			const regExpForFilter = new RegExp('(?<=\\b' + this.partOfMessage + '\\s).*?(?=\\s|$)', 'g');
			console.log("%c errorText, errorText.match(regExpForFilter)", coCSS, errorText, errorText.match(regExpForFilter))
			this.variable = errorText.match(regExpForFilter)
		}
	}
}

function if409(data, truth) {
	switch (data, truth) {
		case 'Data interval is overlapped with existing mapping or incorrect input times':
			setText(l_v.period_conflict_text)
			break;
		case 'One or several real sensors not belong to virtual sensor that multisensor belongs to':
			setText(l_v.real_sensor_type_wrong)
			break;
		case 'One or several real sensors have not consistent vehicle to device mapping':
			setText(l_v.real_sensor_used_in_another_period)
			break;
		case 'Sensor with such sensorId is already present':
			setText(l_v.sensor_with_this_id_olready_used)
			break;
		case 'Sensor with such type is already present on vehicle':
			setText(l_v.sensor_with_this_type_present_on_car)
			break;
		case 'One or several real sensor sockets does not belong to virtual sensor that multisensor belongs to':
			setText(l_v.do_not_match_the_virtual_sensor_type)
			break;
		case (data.indexOf('Sensor with type') !== -1 && data.indexOf('is already present in vehicle') !== -1):
			setText(l_v.virtual_sensor_typ_already_on_the_car)
			break;
		default:
			setText('error 409 ' + data)
			break;
	}
}

export function setText(text, statusFromfunc) {
	console.log("%c SETTEXT text, statusFromfunc", coCSS, text, statusFromfunc)
	// status = !status ? statusFromfunc : status; //
	store.dispatch({
		type: 'STATUS_REQUEST',
		setAnswer: statusFromfunc,
		settextanswer: text
	})
}

export function setConfirmMessage(messageText) {
	store.dispatch({
		type: 'SET_CONFIRM_MESSAGE',
		confirm_message: messageText,
	})
}



function collectInfoForMessageAboutError(error, request, name) {
	console.log("%c error", coCSS, error.response, error )
	// console.log("%c error.response.message", coCSS, error.response.message)
	// console.log("%c error.response.status", coCSS, error.response.status)
	const errorInfo = error.response ? error.response : error.message;
	return {
		nameAction: 	name,
		place: 			window.location.href,
		userLogin: 		infoStore().login,
		errorInfo: 		errorInfo,
		version_app: 	APP_VERSION,
		browser: 		window.navigator.userAgent,
		...request,
	}
}

function consoleLogError(error){
	console.log("%c error", coCSS, error)
	console.log("%c .status", coCSS, error.status)
	console.log("%c .message", coCSS, error.message)
	console.log("%c .code", coCSS, error.code)
	console.log("%c .stack", coCSS, error.stack)
}

export function requestToSlack(login, message, callback) {
	const requestInfo = {
        url: "https://hooks.slack.com/services/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",
        method: 'POST',
        data: JSON.stringify({
            "icon_emoji": 	`::bug::`,
            "userName": 	login,
            "text": 		'*ADMIN PANEL - API bug* ``` \n' + message + '\n```\n'
            ,
            "channel": "C01C9QCG3K8",//"YOUR_CHANNEL_ID",
        })
    };
    function sendMessage() {
    	const ifRequestBlocked = error => {
    		const eInfo = collectInfoForMessageAboutError(error, {}, 'send message to support');
   			consoleLogError(error);
   			setText(`Не вдалося надіслати повідомлення про помилку, причина: ${SL.tryStringify(eInfo.errorInfo)}`)
   		}
   		axios(requestInfo)
   		.then(callback)
   		.catch(ifRequestBlocked)
   	};
    setTimeout(sendMessage,  1000)
}
window.requestToSlack = requestToSlack;
function convertDataForSlackApi(messageInfo) {
    let message = ''
    for(let el in messageInfo){
        if(typeof messageInfo[el] == 'string'){
            message += el + ' :' + messageInfo[el] +'\n';
        }else{
            const info = messageInfo[el];
            message += el + ' :{\n';
            for(let partInfo in info){
                if(partInfo !== 'config' ){
                    const stringPart = typeof info[partInfo] == 'object' ? SL.tryStringify(info[partInfo]) : info[partInfo];
                    message += partInfo + ': ' + stringPart + '\n';
                }

            }
             message += '}\n';
        }
    }
    return message
}