import store                  from 'store/configureStore.jsx'
import axios                  from 'axios'
import bugReport, { setText } from './bugReport.jsx'
import infoStore           from './infoStore.js'

const translationsApi = {
	getServersList:         getServersList,
	getDevisesList:         getDevisesList,
	addServer:              addServer,
	updateServer:           updateServer,
	deleteServer:           deleteServer,
	addDevice:              addDevice,
	updateDevice:           updateDevice,
	deleteDevice:           deleteDevice,
	addServerToDevice:      addServerToDevice,
	removeServerFromDevice: removeServerFromDevice,
	getServerDTO: 				getServerDTO
}
export default translationsApi;

function getServersList(callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_prefix + 'retranslate-servers',
		dataType: "json",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	})
	.then(answer =>  callback(answer.data) )
	.catch(serveranswer => bugReport(serveranswer, store));
}

function addServer(serverInfo, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_prefix + 'retranslate-server',
		dataType: "json",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: serverInfo
	})
	.then(answer=>callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store));
}

function updateServer(serverInfo, callback) {
	const {id} = serverInfo;
	delete serverInfo.id ;
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_prefix + 'retranslate-server/' + id,
		dataType: "json",
		method: 'PUT',
		headers: { 'authorization': infoStore().token },
		data: {comment: serverInfo.comment}
	})
	.then(answer=>callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store));
}

function deleteServer(serverId, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_prefix + 'retranslate-server/' + serverId,
			dataType: "json",
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
		})
		.then(answer=>callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function getServerDTO({server_id, callback, callbackOnError}) {
	axios({
				baseURL: infoStore().base_url,
				url: infoStore().bonus_prefix + 'retranslate-servers/' + server_id,
				dataType: "json",
				method: 'GET',
				headers: { 'authorization': infoStore().token },
			})
			.then(answer => callback(answer.data))
			.catch(serveranswer => {
				bugReport(serveranswer, store)
			});
}

function getDevisesList(callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_prefix + 'retranslate-devices',
		dataType: "json",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
	})
	.then(answer=>callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store));
}

function addDevice(deviceInfo, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_prefix + 'retranslate-device',
		dataType: "json",
		method: 'POST',
		headers: { 'authorization': infoStore().token },
		data: deviceInfo
	})
	.then(answer=>callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store));
}

function updateDevice(deviceId, deviceInfo, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_prefix + 'retranslate-device/' + deviceId,
		dataType: "json",
		method: 'PUT',
		headers: { 'authorization': infoStore().token },
		data: {blocked : deviceInfo.blocked}
	})
	.then(answer=>callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store));
}

function deleteDevice(deviceId, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_prefix + 'retranslate-device/' + deviceId,
			dataType: "json",
			method: 'DELETE',
			headers: { 'authorization': infoStore().token },
		})
		.then(answer=>callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function addServerToDevice(deviceId, serverId, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_prefix + 'retranslate-device/map-server-to-device/' + deviceId+'/'+serverId, //retranslate-device/map-server-to-device/71359/3
			dataType: "json",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
		})
		.then(answer=>callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}

function removeServerFromDevice(deviceId, serverId, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_prefix + 'retranslate-device/unmap-server-to-device/' + deviceId+'/'+serverId, //retranslate-device/map-server-to-device/71359/3
			dataType: "json",
			method: 'PUT',
			headers: { 'authorization': infoStore().token },
		})
		.then(answer=>callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store));
}