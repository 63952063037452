import React from 'react'
import language_variant from 'store/languages.jsx'

class CounterEditor extends React.Component {
	constructor(props) {
		super(props);
		this.state={
			active: 'deactive',
			counter_change_oil: true,
			value: this.props.value,
		}
		this.activation_list = this.activation_list.bind(this)
		this.deactivation_list = this.deactivation_list.bind(this)
		
	}

	reset_counter = () =>{
		this.props.result(0, this.props.name)
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			value: nextProps.value
		})
	}
	activation_list(){
		this.setState({
			active: "active"
		})
	}
	deactivation_list(){
		this.setState({
			active: "deactive"
		})
	}
	render(ReactElement, DOMElement, callback){
		return (
				<div className='counter_editor_container' style={this.editorStyle()}>
					<div className='input_container'>
						<input type='number' className='editor_input'  value={this.state.value} disabled={true}/>
						<span>{this.props.units}</span>
					
						<div className='counter_editor_menu'>
							<button className="three_point"
									onMouseOver={this.activation_list.bind(this)}>
									<svg width="13px" height="3px" viewBox="0 0 13 3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
									    <g id="Task" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									        <g id="Tasks" transform="translate(-1219.000000, -252.000000)" fill="#0E0E0E">
									            <g id="task-1" transform="translate(20.000000, 159.000000)">
									                <g id="controls" transform="translate(1124.000000, 81.000000)">
									                    <g id="more" transform="translate(68.000000, 0.000000)">
									                        <g id="More" transform="translate(7.000000, 7.000000)" >
									                            <circle className="symbol" id="Oval-4" cx="1.5" cy="6.5" r="1.5"></circle>
									                            <circle className="symbol" id="Oval-4-Copy" cx="6.5" cy="6.5" r="1.5"></circle>
									                            <circle className="symbol" id="Oval-4-Copy-2" cx="11.5" cy="6.5" r="1.5"></circle>
									                        </g>
									                    </g>
									                </g>
									            </g>
									        </g>
									    </g>
									</svg>
							</button>
								<ul className={'hidden_select_list '+ this.state.active}
									onMouseLeave={this.deactivation_list}
									onMouseOver={this.activation_list}
									>
									<li><button className="option_filter" 
												value="showAllTransport" 
												onClick= {this.reset_counter}
												>
												{language_variant.reset_counter}
										</button>
									</li>
									<li><button className="option_filter" 
												value="showAllTransport" 
												>
												{language_variant.settings}
										</button>
									</li>
								</ul>
						</div>
					</div>
				</div>
			)
	}
	editorStyle = () =>{
		if(!this.props.disabled){
			return{
				opacity: '0.3',
			}
		}
		
	}
} 
export default CounterEditor;