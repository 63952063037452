import React, {
	Fragment,
	useState
}
from 'react'
import TooltipWrapper from '../component/TooltipWrapper.jsx'
import Nonull         from '../component/Nonull.jsx'
import l_v            from 'store/languages.jsx'
import SL             from "sl-library"
import EnterListennerForForm                                                    from "modules/component/EnterListennerForForm.jsx"


export default class AddVirtualSensor extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			number_error: '',
			error_emty_field: '',
			badId: '',
			message: '',
			sensorInfo: {
				// id: '',
				title: '',
				sensorType: 'RAW_SENSOR',
				fracDigits: '1',
				visible: 	true,
				units: 		'',
				increasing: false
			},
		}
		this.initialState = this.state;
		this.requredInputs = ['title', 'sensorType', 'fracDigits'];
	}

	enterToState = e => {
		const {
			sensorInfo
		} = this.state;
		const {
			type,
			name,
			value,
			checked
		} = e.target;
		sensorInfo[name] = type == 'checkbox' ? checked : value;
		this.setState({
			sensorInfo: SL.copyOfObject(sensorInfo)
		})
		// if(type == 'button') e.preventDefault();//prevent default form event
	}
	// onKeyPress = e => {
	// 	if(e.key === "Enter") this.validate(e)
	// }
	validate = e => {
		e ? e.preventDefault() : null;		
		const { sensorInfo } = this.state;
		let validate = true;
		if (!sensorInfo.sensorType) {
			this.setState({
				message: l_v.choose_sensor_type,
				notSelected: 'formError'
			});
			validate = false;
		}
		this.requredInputs.forEach(key => {
			if (!sensorInfo[key]) {
				validate = false;
				this.setState({
					message: l_v.fill_marked_fields
				});
				this.setState({
					error_emty_field: 'formError'
				});
			}
		})
		if (validate) this.props.save(sensorInfo)
	}
	

	setInitialState = e => {
		e ? e.preventDefault() : null;
		this.setState(this.initialState);
		this.props.cancel();
	}
	render() {
		const {
			error_emty_field,
			number_error,
			message,
			sensorInfo
		} = this.state;
		const formFooter = <FooterAddForm 	update = {this.validate}
														cancel = {this.setInitialState} />

		return 	<EnterListennerForForm onPressEnter = { this.validate }>
						<VirtualSensorFormContent 	enterToState = {this.enterToState}
														className = {null}
														editableVirtualSensor = {null}
														onKeyPress = { this.onKeyPress }	
														{...sensorInfo}
														error_emty_field = {error_emty_field}
														number_error = {number_error}
														message = {message}
														formFooter = {formFooter} />
					</EnterListennerForForm>									
	}
}
export function VirtualSensorFormContent(props) {
	const {
		enterToState,
		error_emty_field,
		number_error,
		formFooter,
		editableVirtualSensor
	} = props;
	const sensorDto = editableVirtualSensor ? editableVirtualSensor : props;
	const {
		virtualSensorId,
		title,
		fracDigits,
		units,
		visible,
		increasing,
		fromZero,
		consumptionForHundred,
		sensorType
	} = sensorDto;
	const idPrefix = virtualSensorId ? virtualSensorId : '';
	return 	<form name = {`add_virtual_sensor-${idPrefix}`} 
						// onKeyPress = { props.onKeyPress }
						className = "sensor_field_container edit_virtual" >
					{
						virtualSensorId ?
							<TooltipWrapper 	name = 		{l_v.sensor_id}
													className = 'wrap'>
							<input 	name = 			'id'
										id =				{idPrefix + 'id'}
										type = 			"text"
										className = 	{`input ${number_error} ${error_emty_field} `}
										placeholder = 	{`${l_v.enter_device_id}*`}
										value = 			{virtualSensorId}
										onChange = 		{enterToState}
										disabled />
						</TooltipWrapper> :
							null
					}
					<TooltipWrapper name = 		{l_v.sensor_name} className = 'title'>
					<Nonull 	name = 			'title'
								id = 				{idPrefix + 'title'}
								type = 			"text"
								className = 	{'input  ' + number_error + ' ' +error_emty_field }
								placeholder = 	{l_v.enter_sensor_name}
								value = 			{title}
								onChange = 		{enterToState} />
					</TooltipWrapper>
					
					<SelectSensors selectedSensorType = {sensorType}
										idPrefix = 				{idPrefix}
										enterToState = 		{enterToState}/>
					{/*<RadioButtons 	selectedSensorType = {sensorType}
										idPrefix = {idPrefix}
										enterToState = {enterToState}/>*/}
					<TooltipWrapper name = 		'fracDigits'
										 className = 'wrap'>
						<Nonull name = 			"fracDigits"
								id = 			{idPrefix + "fracDigits"}
								type = 			"text"
								className = 	{`input ${number_error} ${error_emty_field} `}
								placeholder = 	{l_v.numbers_after_comma}
								value = 		{fracDigits}
								onChange = 		{enterToState} /> 
					</TooltipWrapper> 
					<TooltipWrapper 	name = { l_v.units }
									   	className = 'units' >
							<Nonull name =			"units"
								id = 			{idPrefix +  "units"}
								type = 			"text"
								className = 	{`input ${number_error} ${error_emty_field}`}
								placeholder = 	{l_v.units}
								value = 		{units}
								onChange = 		{enterToState} /> 
					</TooltipWrapper>
					<Checkbox 	id =             	{`${idPrefix}-visible-AddVirtualSensorFormContent`}
									name =           	"visible"
									checked_value = 	{ visible }
									label_text = 		{ l_v.visible }
									onChange =       	{ enterToState }/>
					<Checkbox 	id =             	{ `${idPrefix}-increasing-AddVirtualSensorFormContent` }
									name =           	"increasing"
									checked_value = 	{ increasing }
									label_text = 		{ l_v.growing}
									onChange =       	{ enterToState }/>

					{/*<p className="newCheckbox">
						<input 	type = 				"checkbox"
									id = 					{idPrefix +  "visible"}
									name = 				"visible"
									className = 		'select_checkbox'
									value = 				{visible}
									defaultChecked = 	{ increasing }
									onChange = 			{enterToState}
									/>
						<label 	htmlFor =	{idPrefix +  "visible"}
									className = 'select_label'>
								{l_v.visible}
						</label>
					</p>*/}
					
					{/*<p className="newCheckbox">
						<input 	type = 			  "checkbox"
									id =             { `${idPrefix}-increasing-AddVirtualSensorFormContent` }
									name =           "increasing"
									className =      'select_checkbox'
									value = 				{ increasing }
									defaultChecked = 	{ increasing }
									onChange =       	{ enterToState }
									
									/>
						<label 	htmlFor =	{ `${idPrefix}-increasing-AddVirtualSensorFormContent` }
									className = 'select_label'>
								{l_v.growing}
						</label>
					</p>*/}

					<div className = 'clear'></div>
					{formFooter}
				</form>
}


function Checkbox(props) {
	return 	<p className="newCheckbox">
						<input 	type = 				"checkbox"
									id = 					{props.id}
									name = 				{props.name}
									className = 		'select_checkbox'
									checked  = 			{props.checked_value}
									// defaultChecked = 	{props.checked_value}
									onChange = 			{props.onChange}
									// onClick = 			{props.enterToState}
									/>
						<label 	htmlFor =	{props.id}
									className = 'select_label'>
								{props.label_text}
						</label>
					</p>
}

function getAllSensorTypes() {
	const allSensorTypes = []
	listRB.forEach((key, index) => index ? allSensorTypes.push(...key.sensorTypes) : null )
	return allSensorTypes;
}

const listRB = [ 
   {
   	blockName: l_v.all_sensor_types,
   	get sensorTypes(){
   		return getAllSensorTypes()
   	}
   },
	{
		blockName: l_v.all,
		sensorTypes: [ {
				name: "RAW_SENSOR",
				text: l_v.raw_sensor
			},
			{
				name: "VOLTAGE",
				text: l_v.voltage
			},
			{
				name: "THERMAL",
				text: l_v.thermal_sensor
			},
		]
	},
	{
		blockName: l_v.fuel,
		sensorTypes: [ {
				name: "FUEL_LEVEL",
				text: l_v.fuel_level
			},
			{
				name: "GYROSCOPE",
				text: l_v.gyroscope
			},
			{
				name: "X_AXIS_GYROSCOPE",
				text: l_v.x_axis
			},
			{
				name: "Y_AXIS_GYROSCOPE",
				text: l_v.y_axis
			},
			{
				name: "FLOW_METER",
				text: l_v.flow_meter
			},
		]
	},
	{
		blockName: l_v.CAN,
		sensorTypes: [ {
			name: "MOTOHOURS_CAN",
			text: l_v.motohours_can
		}, ]
	},
	{
		blockName: l_v.gas_stations,
		sensorTypes: [ {
			name: "FUEL_CONSUMPTION",
			text: l_v.fuel_consumption_tanker_
		}, ]
	},
	{
		blockName: l_v.security,
		sensorTypes: [ {
			name: "TACHOMETER",
			text: l_v.tachometer
		}, ]
	},
	{
		blockName: l_v.signaling,
		sensorTypes: [ {
			name: "SOS_ALARM_SENSOR",
			text: l_v.sos_button
		}, ]
	},
	{
		blockName: l_v.agricultural_equipment,
		sensorTypes: [ {
				name: "GRAIN_SENSOR",
				text: l_v.grain_counter
			},
			{
				name: "HOPPER_AUGER",
				text: l_v.hopper_auger
			},
			{
				name: "REAPER_SENSOR",
				text: l_v.reaper
			},
		]
	},
	{
		blockName: l_v.locomotive,
		sensorTypes: [ {
				name: "LOCO_AMPEREMETER",
				text: l_v.ammeter
			},
			{
				name: "LOCO_VOLTMETER",
				text: l_v.voltmeter
			},
			{
				name: "LOCO_POSITIONER",
				text: l_v.positioner
			},
			{
				name: "LOCO_TEMP_OIL",
				text: l_v.oil_temperature
			},
			{
				name: "LOCO_TEMP_WATER",
				text: l_v.water_temperature
			},
			{
				name: "LOCO_POWER",
				text: l_v.LOCO_POWER
			},
		]
	}
];

window.listRB = listRB;
function SelectSensors(props) {
	const [selected_sensor_group, setGroup] = useState(listRB[0]);
	return  	<TooltipWrapper 	name = 	{l_v.virtyal_types_tracker}
										className = 'container_select_virtualSensorType'>
					<div className = 'container_list_typesOfsensorTypes'>
						{
							listRB.map((key, index) => <button 	className = 'virtual_sensor_type_group'
													         key = 		{	key.blockName}
							           						style = { 	selected_sensor_group.blockName == key.blockName ? 
							           										{ backgroundColor: '#127bca', color: 'white'} : null
							           									}
							           						onClick = { 
																				e => {
																					e.preventDefault();
																					setGroup(key)
																				}
																			}>
														{key.blockName}
													</button>)
						}
					</div>
					<div className = 'container_list_sensorTypes'>
						{
							selected_sensor_group.sensorTypes.map(key => <OneSensorType {...key} key = {key.name} {...props}/>)
						}
					</div>
				</TooltipWrapper>;
}

function OneSensorType(props) {
	const {
		name,
		text,
		enterToState,
		idPrefix,
		selectedSensorType
	} = props;
 	return 	<button 	onClick = 	{enterToState}
 							name = 		{'sensorType'}
 							value = 		{name}
 							type = 		'button'
 							className = 'one_virtual_sensor_type'
 							style = {name == selectedSensorType ? { backgroundColor: '#127bca', color: 'white'} : null}>
 							{text}
 				</button>
}


function RadioButtons( props ) {
	return <div className = 'virtual_sensors_types_select_list'>
				{
					listRB.map( oneGroup => (
								<div key = {oneGroup.blockName} className = 'radio_buttons_group'>
						           <p className = 'block_name'>{oneGroup.blockName}</p>
						           {
						           		oneGroup.sensorTypes.map( sensorTypeInfo => <OneButton 	{...sensorTypeInfo}
	        										                  	{...props}
	        			                  								key = {sensorTypeInfo.name}/> )
						           }
					  	 		</div>
					  	)
					)
				}
			</div>
}




function OneButton(props) {
	const {
		name,
		text,
		enterToState,
		idPrefix,
		selectedSensorType
	} = props;
	return <p className="radioP" key = {name} >
				<input 	type = 			"radio"
							defaultChecked = {name == selectedSensorType}
							name = 			{'sensorType'}
							value =			{name}
							onChange = 		{enterToState}
							id = 			{name + idPrefix}/>
				<label htmlFor = { name + idPrefix} >{text}</label>
			</p>
}
export function FooterEditForm( props ) {
	const {
		update,
		cancel
	} = props;
	return <Fragment>
				<button 	className  = 	'primari_btn'
							type = 'button'
							style = 		{{display: 'block'}}
							onClick = 		{update}>
					{l_v.update_info}
				</button>
        		<button className = 'secondary_btn'
			        		type = 'button'
        					onClick = {cancel}>
        			{l_v.cancel}
        		</button>
			</Fragment>
}
export function FooterAddForm ( props ) {
	const {
		update,
		cancel
	} = props;
	return 	<Fragment>
					<button className  = 	'primari_btn'
							type = 'button'
							style = { { display: 'block' } }
							onClick = { update } >
						{l_v.save_data}
					</button>
					<button 	className = 'secondary_btn'
								type = 'button'
								onClick = { cancel } >
	        			{l_v.cancel}
	        		</button>
				</Fragment>
}