import l_a, {setMomentLocale} from "store/languages.jsx"

const defLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ua';
updateLanguage(defLang);

export function setInitialMomentLocale() {
	setMomentLocale(defLang);
}

export default function languageR(state = {
	language: defLang, //defLang
	langList: [{
			label: "Укр",
			value: "ua"
		},
		{
			label: "Eng",
			value: "en"
		},
		{
			label: "Рус",
			value: "ru"
		}
	]
}, action) {
	switch (action.type) {
		case 'LANGUAGE_UPDATE':
			localStorage.setItem('lang', action.language);
			updateLanguage(action.language)
			return {
				...state,
				language: action.language,
			};
		default:
			return state;
	}
}

function updateLanguage(lang_name) {
	setMomentLocale(lang_name);
	l_a.setLanguage(lang_name);
}

