import React, { Component } from 'react';
import {noNull, validateElements} from 'actions/ActionCreator.jsx'
import l_v 				from "store/languages.jsx"

export default class FormUpdateNotification extends Component {
	constructor(props){
		super(props);
		this.state = {
			notificationText: 	this.props.text,
		}
		this.initialState = this.state;
	}
	enterToState = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	validtionForm() {
		let settings = [{
			selector: 'notificationText', //selector what will be used for search dom node (tagName, ., #)
			value: '#text_notif', //value for search dom element
			// stateName:      'zoneId' //name values from element in state (optional)
			},]
		let validation = validateElements.bind(this);
		validation = Object.assign(validation(), settings)
		return validation.result
	}

	render() {
		return (
			<div className = 'form_update_notification'>
				<label htmlFor = 'text_notif' className = 'form_header'>{l_v.notification_text}
				</label>
				<textarea 	id = 'text_notif' placeholder = {l_v.enter_notifi_text}
							className = 'form_text_area'
							onChange = 		{this.enterToState}
							name = 'notificationText'
							value = {this.state.notificationText}></textarea>
			</div>
		);
	}
}
