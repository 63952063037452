import React, { Fragment }            from "react";
import NavLink                        from './component/NavLInk.jsx'
import { Provider, connect }          from 'react-redux'
import { bindActionCreators }         from 'redux'
import ActionCreator, { ifNoKey }     from '../actions/ActionCreator.jsx'
import language_variant               from "../store/languages.jsx"
import { IconNotification }           from "../store/svgicons.jsx"
import Notification                   from './component/Notification.jsx'
import { Message_for_User }           from './component/Notification.jsx'
import { logOut, checkingVersionApp } from '../actions/formAction.js'
import SeparPermission                from './component/SeparPermission.jsx'
import { Link } 					  					from 'react-router-dom'
import SL             								from "sl-library"

//import Notifications 		from './notifications/Notifications.jsx'
import 'images/logo.png'
class AdminNavigation extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			menu_triger: 	'',
			show_menu: 		'',
			darknes_active: '',
			messageStatus: 	this.props.messageStatus,
			login: this.props.login
		}
	}
	componentDidMount() {
		let normalizedScriptVersion = APP_VERSION.replace(/^V-/, '').replace(/-/g, '.');
		normalizedScriptVersion = normalizedScriptVersion.replace(/\.$/, '');

		console.log("%c APP_VERSION", coCSS, normalizedScriptVersion, BUILD_DATE);
		if(!DEVELOPMENT) setInterval(checkingVersionApp, 300000)//15 minutes
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		return 	{
			// user_permission: nextProps.user_permission,
			// createAdminUsers: this.props.createAdminUsers,
			messageStatus: nextProps.messageStatus,
			login: nextProps.login,

		}
	}
	activation_menu = () => {
		let state, show_menu, darknes_active;
		(this.state.menu_triger == '')?(state = 'menu_active'):(state = '');
		(this.state.menu_triger == '')?(show_menu = 'show_menu'):(show_menu = '');
		(this.state.menu_triger == '')?(darknes_active = 'darknes_active'):(darknes_active = '');
		this.setState({
			menu_triger: state,
			show_menu: show_menu,
			darknes_active: darknes_active,
		})
	}
	setstatus(data){
		ActionCreator.setMessageStatus(data);
	}
  render() {
  	const {accountType} = this.props;
  	const {login} = this.state;
    return (
            <Fragment>
        	    <header>
        		    <div className="container">
           				<img src='/images/logo.png' className="logo"/>
        				<nav className={"main_menu " + this.state.show_menu }>
        					<ul className="left_part">
        						<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
        											kindCapability =    'editClients'>
        							<li ><NavLink to="/clients" >{language_variant.clients}</NavLink></li>
        						</SeparPermission>
        						<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER, CLIENT'>
        							<li ><NavLink to="/users" >{language_variant.users}</NavLink></li>
        						</SeparPermission>
        						<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
        											kindCapability =    'editDevices'>
        							<li ><NavLink to="/devices" >{language_variant.devices}</NavLink></li>
        						</SeparPermission>
        						<SeparPermission 	alowed = 'CLIENT'
        											kindCapability =    'editVehicles'>
        							<li ><NavLink to="/transports" >{language_variant.transports}</NavLink></li>
        						</SeparPermission>
        						<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
        											kindCapability =    'createAdminUsers'>
        							<li ><NavLink to="/dialers" >{language_variant.dealers}</NavLink></li>
        						</SeparPermission>
        						<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN'
        											kindCapability =    'createAdminUsers'>
        							<li ><NavLink to="/administrators" >{language_variant.admins}</NavLink></li>
        						</SeparPermission>
        						<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER'
        											kindCapability =    'editDevices'>
        							<li style={{}}><NavLink to="/calibrations" title = {language_variant.archive_calibration}>{language_variant.calibrations}</NavLink></li>
        						</SeparPermission>

        						<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER' >
        							<li className="subMenu_item">
        								<p className = 'sub_menu_no_item'>{language_variant.control}</p>
        								<div className="hidden_content">
        									<p>
        										<NavLink to="/billing" >{language_variant.statistics}</NavLink>
        									</p>
        									<p>
        										<SeparPermission 	alowed = 'SUPERADMIN'>
        											<NavLink to="/WhatIsNewAfterUpdatePage" >{language_variant.updates}</NavLink>
        										</SeparPermission>
        									</p>
        									<p>
        										<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER'
        															kindCapability =    'recalcSensors'>
        											<NavLink to="/logspage" >{language_variant.log}</NavLink>
        										</SeparPermission>
        									</p>
        									<p>
        										<SeparPermission 	alowed = 'SUPERADMIN'>
        											<NavLink to="/translation" >{language_variant.broadcasts}</NavLink>
        										</SeparPermission>
        									</p>
        									<p>
        										<SeparPermission 	alowed = 'SUPERADMIN'>
        											<NavLink to="/ignoredDevices" >{language_variant.ignored_devices}</NavLink>
        										</SeparPermission>
        									</p>
        									<p>
        										<SeparPermission 	alowed = 'SUPERADMIN'>
        											<NavLink to="/ignoredUsers" >{language_variant.ignored_users}</NavLink>
        										</SeparPermission>
        									</p>
        									<p>
        										<SeparPermission 	alowed = 'SUPERADMIN'>
        											<NavLink to="/tokensSession" >{language_variant.token_session_statistics}</NavLink>
        										</SeparPermission>
        									</p>
        								</div>
        							</li>
        						</SeparPermission>
        					</ul>

        					<ul className="right_part">
        						<li className = 'go_to_app_from_user_link'>
        							<NavLink to={"/authInAppByLink" }>
        								<i className = 'icon_go_to_user_link'>GO</i>
        							</NavLink>
        						</li>
        						<li className = 'login_link'>
        							<NavLink to={"/administrator/" + login + "/edit" }>
        								{avo4ka('avo4ka')} {login}
        							</NavLink>
        						</li>
        						<li className='exit_link'>
        							<NavLink to="/#" onClick = {logOut}>{exit("exit")}
        								{language_variant.exit}
        							</NavLink>
        						</li>
        					</ul>
        				</nav>
        				<div 	className={"menu_triger visible_on_mobile "+this.state.menu_triger }
        						onClick={this.activation_menu}>
        		                <div className="first"></div>
        		                <div className="second"></div>
        		                <div className="third"></div>
        		        </div>
        			</div>
        			{this.props.children}
        			<Notification />
        		</header>
        		<ClientsAndVehiclesForTest />
    		    <div className = 'instead_header'>
				</div>
        		<div className={"darknes "+this.state.darknes_active}></div>
        		<Message_for_User 	message = 		{ifNoKey(this.state.user_permission, "message")}
		        							returnstatus = 	{this.setstatus}
		        							messageStatus =	{this.props.messageStatus}/>


        	</Fragment>

    )
  }
};

export default connect(
      state => ({
        login: 				state.authorizationR.login,
        kindUser: 			state.authorizationR.kindUser,
        user_permission: 	state.authorizationR.user_permission,
        createAdminUsers: 	ifNoKey(state.authorizationR.user_permission, 'createAdminUsers'),
        editOther: 			ifNoKey(state.authorizationR.user_permission, 'editOther'),
        messageStatus: 		state.authorizationR.messageStatus,
        accountType: 		state.authorizationR.accountType,
        language:   		state.languageR.language, //for render on update (not used in component)

      }),
    dispatch => bindActionCreators(ActionCreator, dispatch)
)(AdminNavigation)

//ollaa//ollaa//ollaa

function avo4ka(nameOfClass){
	return <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
				className = {nameOfClass}
				style={{enableBackground: "new 0 0 300 300", width: "19px", height: "14px"}}
				xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
 				viewBox="0 0 300 300" width='15px' height = '15px' xmlSpace="preserve">
			<g>
				<g>
					<path fill="white" d="M150.9,300L150.9,300l-1.8,0H20c0-94.4,82.9-94.4,101.3-119l2.1-11.3C97.6,156.6,79.3,125,79.3,88.1
						C79.3,39.4,111,0,150,0s70.7,39.4,70.7,88.1c0,36.6-17.9,68-43.4,81.3l2.4,12.8C199.8,205.7,280,207.2,280,300H150.9z"/>
				</g>
			</g>
		</svg>
};

function exit(nameOfClass){
	return <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				className = {nameOfClass}  viewBox="0 0 28.1 28.1" height="18px" style={{enableBackground:"new 0 0 28.1 28.1"}} xmlSpace="preserve">
				<g>
					<g id="c186_exit">
						<path className="st0" d="M15.4,23.1H2.8V5h12.6v2.6c1.9,0,0.5,0,2.4,0V2.4H0.5v23.3h17.3v-5.2c-0.8,0-1.6,0-2.4,0V23.1z"/>
						<path className="st0" d="M27.4,13.5c-0.6-0.6-6.3-6.1-6.3-6.1s-0.8-0.8-0.8,0.2c0,1,0,2.6,0,2.6s-0.4,0-1.1,0c-2.1,0-9.2,0-10.8,0
							c0,0-0.4,0-0.4,0.5s0,5.8,0,6.5c0,0.7,0.4,0.5,0.4,0.5c1.6,0,8.6,0,10.8,0c0.8,0,1.2,0,1.2,0s0,2,0,2.8c0,0.8,0.7,0.1,0.7,0.1
							l6.1-6.3C27.4,14.4,27.8,14,27.4,13.5z"/>
					</g>
				</g>
			</svg>
}

function triangle(status) {
	if(!!status) {
		return {
			borderLeft: '4px solid transparent',
			borderRight: '4px solid transparent',
			borderBottom: '5px solid black',
			borderTop: 'none',
			height: '5px',
		}
	} else {
		return {
			borderLeft: '4px solid transparent',
			borderRight: '4px solid transparent',
			borderTop: '5px solid black',
			borderBottom: 'none',
			height: '5px',
		}
	}
}

const clientListForTest = [
	'fuel',
	'test',
	'Travel'
];
const vehiclesListForTest = [
	{name: 'Матрёшка', path: '/transport/Travel/Матрёшка/74501/editVehicle'},
	{name: 'Трактор', path: '/transport/fuel/%D0%A2%D1%80%D0%B0%D0%BA%D1%82%D0%BE%D1%80%20%D0%9C%D0%A2%D0%97/6001/editVehicle'},
	{name: 'Козырь', path: '/transport/test/%D0%9A%D0%BE%D0%B7%D1%8B%D1%80%D1%8C/2/editVehicle'},

]
function ClientsAndVehiclesForTest() {
	if(DEVELOPMENT) return null //visible only for me
	return DEVELOPMENT ?
		<div className = 'block_for_fast_navigation' title = 'Это меню есть только на дев сервере... \_(ツ)_/¯'>
					<i title = 'Якось так)'>\_(ツ)_/¯</i>
					{ clientListForTest.map(client_login => <NavLink key = {client_login} to={`/client/${client_login}/edit`} className="link_client">{client_login}</NavLink>) }
					{ vehiclesListForTest.map(vehicle => <NavLink key = {vehicle.name} to={vehicle.path} className="link_client">{vehicle.name}</NavLink>)}
				</div> :
		null
}
