import React 				from 'react'
import devicesApi 			from "actions/devicesApi.js"
import _ 					from 'lodash'
import l_v 					from "store/languages.jsx"

export default class SerialHintData extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			// data: [],
			value: '',
			valuefromhint: '',
			data_show: [],
			visibility: 'none',
			check: false,
		}
		this.doRequst = _.debounce(this.doRequst, 800);
	}
	componentDidMount() {
		devicesApi.getDevices({page: 0, size: 20}, deviceData => this.setState({data_show: deviceData.content}))
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			check: 	nextProps.check
		})
	}
	doRequst = (searchQuery) =>{
		devicesApi.getDevices({page: 0, size: 20, searchParam: searchQuery}, deviceData => this.setState({data_show: deviceData.content}))
	}
	enterToState(e){
		let searchQuery = e.target.value;
		let {data_show} = this.state;
		this.setState({
			value: searchQuery
		})
		this.doRequst(searchQuery)
		if (searchQuery.length > 0){
			if (data_show.length == 0){
				this.setState({
					visibility: 'none',
				})
			}
			if (data_show.length >= 1){
					this.setState({
						visibility: 'block',
					})
			}
		}
		if (searchQuery.length == 0){
				this.setState({
					visibility: 'none',
				})
		}

		this.returndata(searchQuery)
	}
	showhint(){
		this.setState({
					visibility: 'block',
					// data_show: this.state.data
				})
	}
	hidehint(){
		setTimeout( ()=>
			this.setState({
						visibility: 'none',
					}), 700
		)
	}
	enterToStateAttr = (e) =>{
		let value;
		value = e.target.attributes.getNamedItem('data-value').value;
		this.setState({
			value: value,
		})
		this.returndata(value)
		setTimeout( ()=>
					this.setState({
						visibility: 'none',
					})
					, 700
				)
	}
	returndata(data){
		this.props.returnData({
			value: data,
			index: this.props.index,
			name: this.props.name,
			})


	}
	render(){
		console.log(`%c Hints-listForHint`, "color: green; font-weight: 400; text-transform:uppercase", this.state.data_show)

		return(
			<div className='hint_container'>
				<input 	type = "text"
						name = 			{this.props.name}//Id датчика
						data-index = 	{this.props.index}
						className = 	{'input '}
						value =			{this.state.value}
						placeholder = 	{this.props.placeholder}
						style = 		{this.style(this.state.check, this.state.value)}
						autoComplete =	"off"
						onChange = 		{this.enterToState.bind(this)}
						onFocus = 		{this.showhint.bind(this)}
						onBlur = 		{this.hidehint.bind(this)}/>
				<div className="hint_data" style={{display: this.state.visibility}}>
				{this.hint(this.state.data_show)}
				</div>
			</div>
		)
	}
	hint = (array) =>{
		if(Array.isArray(array) && !!array.length){
			const getVehicleInfo = vehicleInfo => {
				if(vehicleInfo){
					const { name, licensePlate } = vehicleInfo;
					return `(${name ? name : ''} ${licensePlate ? licensePlate : ''})`				
				}else{
					null
				}
			}
			return  array.map((key, index)=>{
							if(array.length >= 0){
								return (<p 	data-value = 	{key.serialNumber}
												key =			{key.serialNumber}
												onClick =		{this.enterToStateAttr}>
											[{key.serialNumber}] {key.owner} {key.title} {getVehicleInfo(key.vehicle)}
										</p>)
							}
					})
		}else{
			return <p>{l_v.device_list_empty} </p>
		}
	}
	style(check, value){
		if(!!check && value.length == 0){
			return {boxShadow: "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset"}
		}else{
			return null
		}
	}
}