import React, { Component }                 from "react"
import { connect }                			from 'react-redux'
import _                                    from 'lodash'
import Helmet                               from 'react-helmet';
import SL                                   from 'sl-library'
import Navigation                           from './AdminNavigation.jsx'
import l_v                     				from "store/languages.jsx"
import {SearchVsButton}                     from './component/Search.jsx'
import BtnAddDevice                         from './component/BtnAddDevice.jsx'
import DevicesList                          from './component/DevicesList.jsx'
import devicesApi                           from '../actions/devicesApi.js'
import SeparPermission                      from './component/SeparPermission.jsx'
import ConfirmRemove                        from './ConfirmRemove.jsx'
import TableFooter, { TableFooterMethods }  from "modules/devicePage/TableFooter.jsx"
import Preloader 							from 'modules/preloader.jsx'

class Devices extends TableFooterMethods(Component){
	constructor(props) {
		super(props);
		this.state = {
			devices_list: null,
			deviceId: 	false,//filter param
			'controlEntityName': false,//filter param
			'owner': false,//filter param
			...this.paginationState
		}
		this.sortParams = ['controlEntityName', 'owner', 'deviceId']; //
		this.searchQuery = '';
	}
	onGetContententInfo = (contentInfo, requestDate) => {
		if(this.lastRequestDate > requestDate) return;
		this.setState({
			devices_list: contentInfo.content,
			totalElements: contentInfo.totalElements,
		})
		if(this.mounted) this.preloader.hide();
	}
	componentDidMount() {
		this.mounted = true;
		const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
		this.doRequest();
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	doRequest = () => {
		const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
		this.preloader.show();
		const lastRequestDate = new Date();
		this.lastRequestDate = lastRequestDate;
		devicesApi.getDevices(
			this.createParams({
				page: 			getPage(totalElements, end_index, count_lines_for_view),
				size: 			count_lines_for_view,
				searchParam: 	this.searchQuery,
			}),
			contentInfo => this.onGetContententInfo(contentInfo, lastRequestDate)
		)
	}
	saveValueForSort = (value) =>{
		this.searchQuery = value;
	}
	setSortParam = (sortParameterName) => {
		this.setState({
			[sortParameterName]: !this.state[sortParameterName],
			selectedParam: sortParameterName,
			...this.createListDisabledSort(sortParameterName, !this.state[sortParameterName])
		}, this.doRequest);
	}
	addDevice = (data, trackActivation) => {
		const updateDevicesList = (newDeviceDto) => {
			const {devices_list} = this.state;
			devices_list.unshift(newDeviceDto)
			this.setState({
				devices_list: devices_list
			});
		}
		devicesApi.addDevice(data, trackActivation,  updateDevicesList);
	}
	updateStatusInList = (updatedDeviceInfo) =>{
		const {serialNumber, trash} = updatedDeviceInfo;
		const {devices_list} = this.state;
		let deviceIndexInList = devices_list.findIndex(el =>{
			if(el.serialNumber == serialNumber){
				return el
			}
		})
		devices_list[deviceIndexInList] = updatedDeviceInfo;
		this.setState({
			devices_list: SL.copyOfObject(devices_list)
		});
	}
	delete = () =>{
		  devicesApi.toggleDeviceStatusInTrash(this.state.forDelete, this.updateStatusInList);
			this.refs.delete.popUp_activation({
	          	active: false
	        })
	        this.setState({
				forDelete: null,
			});
	}
	callPopUp = (e) =>{
		this.setState({
			forDelete: e,
		});
		// this.ConfirmTextforRemove = l_v.are_you_sure  + e.serialNumber +'?';
		this.ConfirmTextforRemove = l_v.move_device_to_trash(e.serialNumber);
		this.refs.delete.popUp_activation({
          	active: true
        })
	}
	restoreDevice = (e) => {
		devicesApi.toggleDeviceStatusInTrash(e, this.updateStatusInList);
	}
	render(){
		const { start_index, end_index, count_lines_for_view, totalElements, deviceId, selectedParam } = this.state;
		return(
			<div>
				<Helmet title = { l_v.device_list } />

				<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER'
									type =              'page'
	                        		kindCapability =    'editDevices'/>
				<Navigation />
				<div className='container content'>

					<h1 className="page_name">{ l_v.device_list }</h1>
					<SearchVsButton onChange = {this.saveValueForSort}
									placeholder = {l_v.device_auto_login}
									name = 'imei'
									sendRequest = {this.doRequest}/>
					<BtnAddDevice 	btnname = {l_v.add_device}
										dataFromform = {this.addDevice} />

					<ContentAboutDevice devicesInfo = {this.state.devices_list}
										deviceId = {deviceId}
										sortByOwner = {this.state['owner']}
										sortByName = {this.state['controlEntityName']}
										setSortParam = {this.setSortParam}
										selectedParam = {selectedParam}
										restoreDevice = {this.restoreDevice}
										callPopUp = {this.callPopUp}/>
					<TableFooter 	changePage = {this.changePage}
									show_pagination = {true}
					  				count_of_available_lines = {totalElements}
					  				count_lines_for_view = {count_lines_for_view}
					  				setCountsLineForView = {this.updateCountForView}
					  				start_index = {start_index}
					  				end_index = {end_index} />
				</div>
				<ConfirmRemove 	ref = 			'delete'
								confirmText = 	{this.ConfirmTextforRemove }
								clear = 		{()=>this.setState({
														forDelete: undefined
												})}
								remove = 	{this.delete}/>
				<Preloader ref = { el => this.preloader = el }/>
			</div>
			)
	}
}
export default connect(
      state => ({
        login: state.authorizationR.login,
        token: state.authorizationR.token,
        userPremission: state.authorizationR.user_permission,
      })
)(Devices)

function ContentAboutDevice(props) {
	const {devicesInfo, callPopUp, restoreDevice, setSortParam} = props;
	return 	SL.arrayIsEmpty(devicesInfo) ?
			<DevicesList 	data = {devicesInfo}
							deviceId = {props.deviceId}
							{...props}
							setSortParam = {setSortParam}
							restoreDevice = {restoreDevice}
							delete = {callPopUp}/> :
			<p className = 'no_data' style = {{marginTop: "15px"}}>{ l_v.device_list_empty }</p>
}

export function getPage(totalElements, end_index, count_lines_for_view) {
	return end_index > totalElements && (totalElements - count_lines_for_view < 0) ? 0  : Math.round(end_index/count_lines_for_view) - 1
}