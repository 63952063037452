import React, { Component }             from 'react';
import SL                               from 'sl-library'
import { editImg, deleteImg, closeImg } from '../img.jsx'
import Search                           from 'modules/component/Search.jsx'
import { Link }                         from 'react-router-dom'
import {setText} 					 from "actions/bugReport.jsx"
import l_v from "store/languages.jsx"
export const DevicesContext = React.createContext({});


export default function DevicesForTranslationView(props) {
	const {devicesList} = props;
	return <div className = 'devices_on_translation'>
				<div className = 'sub_header'>
					<h2>{l_v.broadcast_list}</h2> <button className = 'btn_plus' onClick = {props.callFormForAddDevice}>+</button>
					</div>
				<Search placeholder = 	{l_v.search}
						onChange =		{(value)=>props.handleFilterChange(value)}>
				</Search>
				<ListOfDevices 	devicesList = {devicesList}/>
			</div>
}

function ListOfDevices(props) {
	const {devicesList} = props;
	return 	SL.arrayIsEmpty(devicesList) ?
			<div className = 'server_list'>
				<HeadLineOfDevices />
				{
					devicesList.map(oneDevice => 	<OneDeviceView 	{...oneDevice}
					                 								key = {oneDevice.deviceId}/>)
				}
			</div>:
			<div>{l_v.list_is_empty}</div>
}

function OneDeviceView(props) {
	const {
		deviceId,
		deviceImei,
		blocked,
		retranslateServerDtoList,
		deviceOwner
	} = props;
	function getСomma(index) {
		return retranslateServerDtoList.length-1 > index ? ', ' : ''
	}
	function copy() {
		SL.copyStringToClipboard(`№ ${deviceId} , IMEI: ${deviceImei} `);
		setText('message was copyed to clipboard');
		
	}
	return  <DevicesContext.Consumer>
				{ 
					context => 	<div className = 'oneLine one_server' style = {{backgroundColor: blocked ? 'rgb(253, 161, 161)': 'white'}}>
										<div className = 'cell cell_name'><Link to = {'device/' + deviceId}>{deviceId}</Link></div>
										<div className = 'cell cell_name'> <Link to = {`client/${deviceOwner}/edit`}>{deviceOwner}</Link></div>
										<div className = 'cell'> {deviceImei} </div>
										<div className = 'cell'>{ blocked ? l_v.yes : l_v.no }</div>
										<div className = 'cell list_maped_server'>
											<div>
												{
													SL.arrayIsEmpty(retranslateServerDtoList) ?
													retranslateServerDtoList.map((el, index) =>
													                             <p key = {el.id}>{el.serverName} {getСomma(index)}</p> ) :
													null
												}
												{context.FB.retranslateServerDtoList(props)}
											</div>

										</div>
										<div className = "cell_add cell">
											<button 	className = 'copy_btn'
														onClick = 	{copy}>
													<i className = 'top_square'></i>
													<i className = 'bottom_square'></i>
											</button>
										</div>
										<div className = "cell_add cell">
											<button className = 'btn_plus' onClick = {() => context.callMappingForm(props)}>+</button>
										</div>
										<div className = "cell_edit cell">
											{ editImg("edit", () => context.callEditDevice(props) ) }
										</div>
										<div className = "cell_delete cell">
											{ deleteImg("delete", () => context.callConfirmRemove(props)) }

										</div>
								</div>
				}
			</DevicesContext.Consumer>
}



function HeadLineOfDevices(props) {
	return <div className = "oneLine head_line">
			<div className = "cell_name cell">
				{l_v.device_number}
			</div>
			<div className = "cell_desc cell">
				{l_v.account}
			</div>
			<div className = "cell_desc cell">
				IMEI
			</div>
			<div className = "cell_desc cell">
				{l_v.blocked}
			</div>
			<div className = "cell_desc cell">
				{l_v.servers_for_broadcast}
			</div>
			<div className = "cell_add cell">

			</div>
			<div className = "cell_add cell">

			</div>
			<div className = "cell_edit cell">

			</div>
			<div className = "cell_delete cell">

			</div>
		</div>
}

// {
//     "deviceId": 71359,
//     "deviceImei": "80008000",
//     "retranslateServerDtoList": [
//         {
//             "id": 3,
//             "serverHost": "127.0.0.1",
//             "serverPort": 8980,
//             "serverName": "TestName",
//             "retranslatorProtocolType": "TELTONIKA",
//             "comment": "test comment lol"
//         }
//     ],
//     "blocked": true
// }