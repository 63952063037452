import React                     from 'react'
import { connect }               from 'react-redux'
import { ifNoKey, IsJsonString } from '../../actions/ActionCreator.jsx'
import { setCopy }               from '../../actions/small_actions.jsx' //save calibration
import { closeImg }              from '../img.jsx'
import Calibration               from '../../actions/calibrations.js'
import l_v                       from "store/languages.jsx"

class CopyCalibration extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			login: this.props.login,
			kindUser: this.props.kindUser,
			copyData: this.props.copyData,
		}

	}
	componentDidMount() {
		this.checkerStore = setInterval(() => { //for check storage in second tab // may be rewrite later
			const { copyData } = this.state;
			const infoInStorage = localStorage.getItem('copyInfo') ? JSON.parse(localStorage.getItem('copyInfo')) : '';
			if (infoInStorage) {
				setCopy(infoInStorage)
			}
		}, 3000)
	}
	get isVisibleBtnSave() {
		const url = window.location.hash;
		//url.indexOf('calibrations') !== -1
		return url.indexOf('editVehicle') !== -1 || url.indexOf('editGasStation') !== -1
	}
	componentWillUnmount() {
		clearInterval(this.checkerStore)
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		return {
			copyData: IsJsonString(nextProps.copyData) ? JSON.parse(nextProps.copyData) : nextProps.copyData
		}
	}
	setCalibrationToForm = (e) => {
		const url = window.location.hash;
		const { copyData } = this.state;
		this.props.setSavedData(copyData);
		this.clearCalibrationInfo()
	}
	clearCalibrationInfo = () => {
		setCopy(null)
	}
	render() {
		const { copyData } = this.state;
		return copyData ?
			<div className = 'copy_widget' >
				{closeImg('copyClose', this.clearCalibrationInfo, null, l_v.remove)}
				<p>{l_v.calibration_copy(copyData.name || `id - ${copyData.id}`)}</p>
				{
					this.isVisibleBtnSave ?
					<button 	className = 'small_btn_black'
								onClick = 	{this.setCalibrationToForm}
								ref = 		{(btnSave)=>this.btnSave = btnSave}>
						{l_v.save_here}
					</button> :
					null
				}

				</div> :
			null
	}
}
export default connect(
      state => ({
        login: 			state.authorizationR.login,
        kindUser: 		state.authorizationR.kindUser,
        copyData: 		state.devicesR.calibratioForCopy,
      })
)(CopyCalibration)




