	// const domainKey = getDomainKey();
	// if(DEVELOPMENT)console.log("DOMAINKEY", domainKey)
	const domainKey = "domainDEV";

	function getDomainKey() {
		// if(DEVELOPMENT)   return 'domainPROD';
		if(DEV_SERVER)    return 'domainDEV';
		if(MASTER_SERVER) return 'domainMASTER';
		if(PROD_SERVER)   return 'domainPROD';
		if(TEST_SERVER)	return 'domainTEST'
	}

	const domainSettings = {
		domainDEV:    "k8t-api.elsyton.com",//"dev.elsyton.com",
		domainMASTER: "master-api.elsyton.com",
		domainPROD:   "pro-api.elsyton.com",
		domainTEST:   "k8t-api.elsyton.com"
	}

	const telegramBotNames = {
		domainDEV:    "dev_control_test_bot",
		domainMASTER: "globus_control_bot",
		domainPROD:   "dev_control_test_bot",
	}
export function getDomain (accountType) {
	if (accountType == 'TERES') {
		if (DEV_SERVER || DEVELOPMENT) return 'https://dev-teres.elsyton.com';
		if (MASTER_SERVER) return 'http://online.teres.com.ua';
		if (PROD_SERVER) alert('а куда нужно отправлять?') //return 'https://prod-www.skt-globus.com.ua';
		if (TEST_SERVER) return "https://test-teres.elsyton.com"
	} else {
		if (DEV_SERVER || DEVELOPMENT) return 'https://dev-client.skt-globus.com.ua' //'https://dev-client.skt-globus.com.ua';
		if (MASTER_SERVER) return 'https://online.skt-globus.com.ua';
		if (PROD_SERVER) return 'https://pro.skt-globus.com.ua';
		if (TEST_SERVER) return 'https://k8t-web.skt-globus.com.ua'
	}
}

export function getLocalDomain(accountType) {
	if(accountType == 'TERES') {
		return 'http://localhost:8000'
	} else {
		return 'http://localhost:9000'
	}
}

export function getProdTestDomain(accountType) {
	if(accountType == 'TERES') {
		return "https://test-teres.elsyton.com"
	} else {
		return	'https://test-client.skt-globus.com.ua'
	}
}

const initialState = {
	login:            getValue(localStorage.getItem('login')),
	password:         "",
	savedata:         '',
	answerForUser:    '',
	user_permission:  getValue(localStorage.getItem("user_permission")),
	base_url:         "https://" + domainSettings[domainKey], //'http://dev.elsyton.com/', //'http://gl21.elsyton.com',  - для релизов
	admin_prefix: 	  'api/v2/admin/',
	bonus_url_device: 'api/v2/admin/',
	bonus_prefix:     'api/v2/admin/',
	bonus_url_user:   '/api/v2/hydra-auth/', //auth/v1/
	websocket_url:    'wss://' + domainSettings[domainKey] + '/ws/v2',
	port_one:         '', //:8081
	bonus_url_api:    '/api/v2/',
	kindUser:         getValue(localStorage.getItem('kindUser')),
	token:            getValue(localStorage.getItem('sid')),
	oldToken:         getValue(localStorage.getItem('oldSid')), //oldSid,
	base_url_port:    "https://" + domainSettings[domainKey],
	messageStatus:    undefined,
	urlbeforedep:     undefined,
	socket:           undefined,
	accountType:      getValue(localStorage.getItem('accountType')),
	confirm_message:  null,
}

export default function authorizationR(state = initialState, action) { //prodState  devState
	switch (action.type) {
		case 'SET_CONFIRM_MESSAGE':
		return {
			...state,
			confirm_message: action.confirm_message
		};
		case 'FORM_AUTH_LOGIN_UPDATE':
			return {
				...state,
				login: action.login
			};
		case 'FORM_AUTH_PASSWORD_UPDATE':
			return {
				...state,
				password: action.password
			};
		case 'FORM_AUTH_RESET':
			return {
				...state,
				login: "",
				password: ""
			};
		case 'FORM_AUTH_AUTOFILL':
			return {
				...state,
				login: action.login,
				password: action.password,
				savedata: action.savedata
			};
		case 'FORM_AUTH_FAILED':
			return {
				...state,
				answerForUser: action.answerForUser
			};
		case 'USER_PREMISSION':
			return {
				...state,
				user_permission: action.getpremission
			};
		case 'SESSION_TOKEN':
			return {
				...state,
				token: action.savetoken
			};
		case 'OLDSESSION_TOKEN':
			return {
				...state,
				oldToken: action.saveOldToken
			};
		case 'KIND_USER':
			return {
				...state,
				kindUser: action.savekindUser
			};
		case 'ACCOUNT_TYPE':
			return {
				...state,
				accountType: action.setType
			}
		case 'MESSAGE_STATUS':
			return {
				...state,
				messageStatus: action.setstatus
			}
		case 'URL_BEFORE_DEPORTATION':
			return {
				...state,
				urlbeforedep: action.setURL
			}
		case 'WEBSOCKET':
			return {
				...state,
				socket: action.saveSocket
			}

		default:
			return state;
	}
}

export function getValue(data) {
	return tryParse(data) ? JSON.parse(data) : data
}

function tryParse(data) {
	let parsed;
	try {
		JSON.parse(data);
	} catch (e) {
		return false;
	}
	return true
}
