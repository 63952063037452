import store            from 'store/configureStore.jsx'
import axios            from 'axios'
import infoStore 		from "./infoStore.js"
import bugReport        from './bugReport.jsx'
import l_v 				from "store/languages.jsx"
//api http://docs.elsyton.com/pages/viewpage.action?pageId=8650768
const Calibration = {
	addIndividual:               addIndividual,
	getIndividual:               getIndividual,
	addGlobal:                   addGlobal,
	getGlobal:                   getGlobal,
	deleteThis:                  deleteThis,
	update:                      update,
	dispatchConcated:            dispatchConcated,
	updateStoreThisCalibrations: updateStoreThisCalibrations,
	getArchive: 				 getArchive,
}
export default Calibration;

function getGlobal(callBack) {
	let state = store.getState();
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url
	let bonus_prefix = state.authorizationR.bonus_prefix;
	axios({
			baseURL:  base_url,
			url:      bonus_prefix + 'vehicle/global/sensor-type-calibrations',
			dataType: "json",
			method:   'GET',
			headers:  { 'authorization': token },
		})
		.then(function answer(answer) {
			let calibrationsList = answer.data;
			if(callBack) {
				callBack(calibrationsList)
			}
			store.dispatch({
				type: 'CALIBRATION',
				setGlobalCalibration: calibrationsList,
			})
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}


function getArchive(searchValue, callBack) {
	let state = store.getState();
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url
	let bonus_prefix = state.authorizationR.bonus_prefix;
	axios({
			baseURL:  base_url,
			url:      bonus_prefix + 'vehicle/archive-sensor-calibrations',
			dataType: "json",
			method:   'GET',
			headers:  { 'authorization': token },
			params: {searchParam: searchValue}
		})
		.then(function answer(answer) {
			let calibrationsList = answer.data;
			if(callBack) {
				callBack(calibrationsList)
			}
			// store.dispatch({
			// 	type: 'CALIBRATION',
			// 	setGlobalCalibration: calibrationsList,
			// })
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function addGlobal(calibrationInfo, callBack) {
	let state = store.getState();
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url
	let bonus_prefix = state.authorizationR.bonus_prefix;
	let calibrations = state.devicesR.globalCalibraition;
	axios({
			baseURL: base_url,
			url: bonus_prefix + 'vehicle/global/sensor-type-calibration',
			dataType: "json",
			method: 'PUT',
			headers: { 'authorization': token },
			data: calibrationInfo
		})
		.then(function answer(answer) {
			if(callBack) {
				callBack(answer.data)
			}
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function deleteThis(id, type, callBack) {
	let state = store.getState();
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url
	let bonus_prefix = state.authorizationR.bonus_prefix;
	axios({
			baseURL: base_url,
			url: bonus_prefix + 'vehicle/sensor-type-calibration',
			method: 'DELETE',
			headers: { 'authorization': token },
			params: { id: id }
		})
		.then(function answer(answer) {
			if(callBack) callBack(id)
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function update(calibrationInfo, callBack) {
	let state = store.getState();
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url
	let bonus_prefix = state.authorizationR.bonus_prefix;
	axios({
			baseURL: base_url,
			url: bonus_prefix + 'vehicle/sensor-type-calibration',
			dataType: "JSON",
			method: 'POST',
			headers: { 'authorization': token },
			data: calibrationInfo
		})
		.then(function answer(answer) {
			if(callBack) {
				callBack(answer.data)
			}
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function getIndividual(idvehicle, callBack) {
	let state = store.getState();
	let bonus_prefix = state.authorizationR.bonus_prefix;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	axios({
			baseURL: base_url,
			url: bonus_prefix + 'vehicle/' + idvehicle + '/particular/sensor-type-calibrations',
			dataType: "JSON",
			method: 'GET',
			headers: { 'authorization': token },
		})
		.then(function answer(answer) {
			if(!!callBack) {
				callBack(answer.data)
			}
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function addIndividual(vehicleId, calibrationInfo, callBack, onError) {
	let state = store.getState();
	let login = state.authorizationR.login;
	let bonus_url_device = state.authorizationR.bonus_url_device;
	let token = state.authorizationR.token;
	let base_url = state.authorizationR.base_url;
	let url = bonus_url_device + 'vehicle';
	calibrationInfo.vehicleId = vehicleId;
	axios({
			baseURL: base_url,
			url: bonus_url_device + 'vehicle/particular/sensor-type-calibration',
			dataType: "json",
			method: 'PUT',
			headers: { 'authorization': token },
			data: calibrationInfo,
		})
		.then(function answer(answer) {
			let newsensor = answer.data;
			if(callBack) callBack(newsensor)
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
		})
		.catch(function(serveranswer) {
			if(onError) onError();
			bugReport(serveranswer, store)
		});
}

function dispatchConcated(data) {
	store.dispatch({
		type: 'CONCATED_CALIBRATIONS',
		setCalibrations: data
	})
}

function updateStoreThisCalibrations(list) {
	store.dispatch({
		type: 'SET_CALIBRATIONS',
		setForThisVehicle: list
	})
}