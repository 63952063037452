import React            from "react"
import OneClient        from './OneClient.jsx'
import { arrayIsEmpty } from 'sl-library'
import l_v              from "store/languages.jsx"

export default class Clientlist extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			data:                null,
			column_height:       '',
			win_width:           '',
			table_content_width: '',
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			data: nextProps.data,
		})
	}
	scroolTop(){
		let height = this.state.column_height;
		let name = document.querySelector('.table_content');
		name.scrollTop -= height;
	}
	scroolBottom(){
		let height = this.state.column_height;
		let name = document.querySelector('.table_content');
		name.scrollTop += height;
	}
	setwidth = (data) =>{
		let head_line = data.head_line/data.table * 100;
		let win_width = window.innerWidth;
		let table_content = 100 - head_line;
		if (win_width < 720){
			this.setState({
				column_height:       data.column_height+1,
				table_content_width: table_content,
			})
		}
		this.setState({
			win_width: win_width,
		})
	}
	render(){
		const style = {
				 			height: this.state.column_height+'px',
				 			width:  this.state.table_content_width + '%'
						}
		return 	arrayIsEmpty(this.state.data) ?
				<div className = 'clientlist'>
	{/*					<div className='triggerUp visible_on_mobile'
							 onClick={this.scroolTop.bind(this)}
						>▲</div>
						<div className='triggerDown visible_on_mobile'
							onClick={this.scroolBottom.bind(this)}
						>▼</div>*/}
					<TableHeader 	login = {this.props.login}
									setSortParam = {this.props.setSortParam}
									selectedParam = {this.props.selectedParam}
									name = {this.props.name}
									belongsTo = {this.props.belongsTo}/>
					<div 	className = 'table_content'>

						{
							this.state.data.map( key => {
								return <OneClient 	{...key}
													key =         {key.login}
													login =       {key.login}
													belongsTo =   {key.belongsTo}
													expires =     {key.expires}
													blocked =     {key.blocked}
													description = {key.description}
													name = 		  {key.name}
													returnwidth = {this.setwidth}
													deleteuser =  {this.props.deleteuser} />

							})
						}
					</div>
				</div> :
				<div className = 'no_data'>{l_v.no_data}</div>
	}
}



function TableHeader(props) {
	const visibilityI = (nameOfColumn) => nameOfColumn == props.selectedParam ? {display: 'block'} : {display: 'none'}

	return 	<div className = "oneLine head_line">
				<div className = "cell_name cell" onClick = {()=>props.setSortParam('login')}>
					{l_v.login}
					<i className = 'sort_arrow' style = {visibilityI('login')}>{props.login ? '↑' : '↓'}</i>
				</div>
				<div className = "cell_desc cell" onClick = {()=>props.setSortParam('name')}>
					{l_v.title}
					<i className = 'sort_arrow' style = {visibilityI('name')}>{props.name ? '↑' : '↓'}</i>
				</div>
				<div className = "cell_admin cell" onClick = {()=>props.setSortParam('belongsTo')}>
					{l_v.dealer}
					<i className = 'sort_arrow' style = {visibilityI('belongsTo')}>{props.belongsTo ? '↑' : '↓'}</i>
				</div>
				<div className = "cell_expire  cell">
					{l_v.blocking_date}
				</div>
				<div className = "cell_blocked cell">
					{l_v.blocking}
				</div>
				<div className = "cell_edit cell">

				</div>
				<div className = "cell_delete cell">

				</div>
			</div>
}