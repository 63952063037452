import React from "react";
import {connect} from 'react-redux'
import {setDeportaionUrl} 	from '../../actions/small_actions.jsx'
import {ifNoKey} 				from "actions/ActionCreator.jsx"
import {getValue} 				from "reducers/authorization_reducer.jsx"

class SeparPermission extends React.Component{
	constructor(props) {
		super(props);
		const {
			alowed,
			kindUser,
			kindCapability,
			user_permission,
			type
		} = this.props;
		let show = false;
		const capabilities = ifNoKey(user_permission, 'capabilities');

		show = checkAcces({
			alowedUserTypes: alowed,
			currentUserType: kindUser
		},
		{
			userPremission: capabilities,
			requiredPremissions: kindCapability
		}) ? true : false;

		this.state = {
			show: show,
		}
	}
	componentWillMount() {
		const {
			alowed,
			kindUser,
			kindCapability,
			user_permission,
			type
		} = this.props;
		const capabilities = ifNoKey(user_permission, 'capabilities');

		if(type == 'page'){
			checkAcces({
				alowedUserTypes: alowed,
				currentUserType: kindUser
			},
			{
				userPremission: capabilities,
				requiredPremissions: kindCapability
			}) ? null : moveUserToPreviousPage();
		}
	}
	render() {
		const {children} = this.props;
		const {show} = this.state;
		const childElements = () => children ? React.cloneElement(this.props.children, {}) : null ;
		return show ? childElements() : null
	}
}





export default connect(
    state => ({
    	login: 				state.authorizationR.login,
		token: 				state.authorizationR.token,
		kindUser: 			state.authorizationR.kindUser,
		user_permission: 	state.authorizationR.user_permission,
    })
)(SeparPermission)


export function permissionsFoRequst(alowedUserTypes, requiredPremissions, request) {
	const kindUser = localStorage.getItem('kindUser');
	const userProfile = getValue(localStorage.getItem('user_permission'));
	const capabilities = ifNoKey(userProfile, 'capabilities');
	checkAcces({
			alowedUserTypes: alowedUserTypes,
			currentUserType: kindUser
		},
		{
			userPremission: capabilities,
			requiredPremissions: requiredPremissions
		}) ? request() : null;
}


export function byKind(kind, alowed) {
	if(alowed.indexOf(kind) !== -1){
		return true
	}else{
		false
	}
}

function checkAcces(userInfo, permissions) {
	const {alowedUserTypes, currentUserType} = userInfo;
	const {userPremission, requiredPremissions} = permissions;
	let haveAcces = true;
	if(!checkAccesByUserType(alowedUserTypes, currentUserType)){
		haveAcces  = false
	}
	if(!checkAccesByUserPremission(userPremission, requiredPremissions)){
		haveAcces  = false
	}
	return haveAcces
}

function checkAccesByUserPremission(userPremission, requiredPremissions) {
	let allowed = true;
	if(typeof requiredPremissions === 'string'){
		allowed = checkStatusPermission(requiredPremissions)
	}
	if(Array.isArray(requiredPremissions)){
		for(let i in requiredPremissions){
			allowed = checkStatusPermission(requiredPremissions[i]);
			if(!allowed){
				break;
			}
		}
	}
	return allowed

	function checkStatusPermission(requiredPremissions) {
		let statusAllowed = false;
		for(let i in userPremission){
			if(i == requiredPremissions){
				statusAllowed = userPremission[i]
				if(!statusAllowed){
					break;
				}
			}

		}
		return statusAllowed
	}
}

function checkAccesByUserType(allowedUserTypes, currentUserType) {
	const arrAllowedUsersTypes = allowedUserTypes.split(', ')
	return arrAllowedUsersTypes.find(userType => userType == currentUserType)? true : false
	// allowedUserTypes.match() == -1 ? false : true
}

function moveUserToPreviousPage() {
	setDeportaionUrl(undefined)
	window.history.back();
}