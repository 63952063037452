import React, { Fragment }                      from 'react'
import { Link }                                 from 'react-router-dom';
import Navigation                               from '../modules/AdminNavigation.jsx'
import Calendar                                 from './InfiniteCalendar/Calendar.jsx';
import moment                                   from 'moment';
import { connect }                              from 'react-redux'
import Helmet                                   from 'react-helmet';
import * as QRCode                              from 'easyqrcodejs';
import TimeInput                                from "dd-date-time-input"
import l_v                                      from 'store/languages.jsx'
import _                                        from 'lodash'
import Users                                    from '../actions/users.js'
import Transports                               from './Transports.jsx'
import SL, {
	ifNoKey,
	checkPass,
	arrayIsEmpty,
	toLowerCase
}                                               from 'actions/ActionCreator.jsx'
import EnDisUserLogging                         from './component/EnDisUserLogging.jsx'
import SwitchInput                              from './component/SwitchInput.jsx'
import SeparPermission, { permissionsFoRequst } from './component/SeparPermission.jsx'
import Trailers                                 from "./transportPage/Trailers.jsx"
import authInClient                             from './component/authInClient.js'
import CapabilityEditor                         from "./component/CapabilityEditor.jsx"
import { goToClientAppImg, qrIcon }             from './img.jsx'
import ErrorBoundary                            from "./ErrorBoundary.jsx"
import { HintUsers }                            from './transportPage/HintData.jsx'
import { HintsGroups }                          from "modules/component/BtnAdd.jsx"
import CustomReportsMapping                     from 'modules/component/CustomReportsMapping.jsx'
import Pop_up                                   from './component/Pop_up.jsx'
import Preloader                                from 'modules/preloader.jsx'

// import TransportList from './component/TransportList.jsx'

class ClientPage extends  React.Component{
	constructor(props) {
		super(props);
		const {userName} = this.props.match.params;

		this.state = {
			accountType: null,
			userName:    userName,
			aloxa: {//ну да, херня, сам не помню зачем это сделал, но рефакторить мне лень))
						login:        '',
						description:  '',
						capabilities: {},
						belongsTo:    '',
						expire:       '',
						blocked:      '',
						message:      '',
					},
			datablocking: '',
			data:         '',
			ignored_status: false
		}
	}
	componentWillMount() {
		const {accountType, userName} = this.props.match.params;
		Users.getUserData(
			{
				kindUser: 'CLIENT',
				userLogin: userName,
			}
		)

		const getIgnoredStatus = () => Users.getIgnoredUser(userName, (xzArray) => {
			console.log("%c GETIGNOREDUSER xzArray", coCSS, xzArray)
			if(arrayIsEmpty(xzArray)){
				this.idInIgnoredList = xzArray[0].id
			}
			this.setState({
				ignored_status: arrayIsEmpty(xzArray)?true:false
			});
		})
		permissionsFoRequst('SUPERADMIN', null, getIgnoredStatus)
	}
	catchDoubleTap(event){
	}
	componentDidMount() {
		this.lastTapTime = 0;
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	updateIgnoredStatus = (status) => {
		const {accountType, userName} = this.props.match.params;
		this.preloader.show();

		status ?
		Users.addToIgnoredUsers([userName], (xzArray) => {
			this.setState({ignored_status: true})
			this.idInIgnoredList = xzArray[0].id;
			if(this.mounted) this.preloader.hide();
		}) :
		Users.removeFromIgnoredUsers(this.idInIgnoredList, () => {
			this.idInIgnoredList = null
			this.setState({ignored_status: false})
			if(this.mounted) this.preloader.hide();
		});
	}
	componentWillReceiveProps(nextProps) {
		let curstate = this.state.aloxa;
		this.setState({
				data: nextProps.TransportData,
				...nextProps.thisUser,
			})
	}
	setExpire = (data) =>{
		Users.updateCapability({
			login:   ifNoKey(this.props.match, 'userName'),
			marker:  'clientPage',
			adddata: '/block',
			capability: //in the function take this key
						{
							expires: data.forsend,
						}
		})
	}
	updateDesc = (data) =>{
		Users.editValueOfUser({
			login: this.props.match.params.userName,
			marker: 'clientPage',
			newValue:{
				description: data
			}
		});
	}
	updateName = (newName) => {
		Users.editValueOfUser({
			login: this.props.match.params.userName,
			marker: 'clientPage',
			newValue:{
				name: newName
			}
		});
	}
	updateMessage = (data) =>{
		Users.editValueOfUser({
			"login": this.props.match.params.userName,
			"marker": 'clientPage',
			"newValue":{
				message: data
			}
		})
	}
	sendpassword = (data) =>{
		Users.editPassword({
			"login": this.props.match.params.userName,
			"marker": 'clientPage',
			"password":{
				"newPassword": data,
				"previousPassword": null,
			}
		});
	}
	setBlocking = (e) =>{
		let value = e.target.value === 'true' ? false : true;
		let capname = e.target.name;
		this.preloader.show();
		Users.updateCapability({
			login:      ifNoKey(this.props.match, 'userName'),
			marker:     'clientPage',
			adddata:    '/block',
			capability: { [capname]: value }

		}, () => {
				if(this.mounted) this.preloader.hide();
		})
	}
	setUnblocked = () => {
		this.preloader.show();
		Users.updateCapability({
			login:      ifNoKey(this.props.match, 'userName'),
			marker:     'clientPage',
			adddata:    '/block',
			capability: { blocked: false }

		}, () => {
				if(this.mounted) this.preloader.hide();
		})
	}
	changeOwner = (owner) =>{
		const clientName = this.props.match.params.userName;
		Users.belongsTo({
			login:     clientName,
			belongsTo: { belongsTo: owner }
		})
	}
	scroll(){
		let scrolltop = document.querySelector('body').scrolltop;
		let elementpos = document.querySelector('.transports').scrolltop;
		let scrollpos;
		if (scrolltop > elementpos){
			scrollpos = true;
		} else{ scrollpos = false;}
		this.setState({
				scrollpos: true
		})
	}
	updateCapabilityInState = (newCapability) => {
		this.setState({capabilities: newCapability});
	}
	showQR = (login) => {
		console.log("%c login", coCSS, login)
	    const onGetSID = (sid) => {
	    	console.log("%c sid", coCSS, sid)
	        this.qrPopup.open();
	        let text = login + ',' + sid;
	        const qrcode = new QRCode(document.getElementById("qrcode"),  {
	        	text: text,
	        	width: 256,
			    height: 256,
	        	colorDark : "#000000",
				colorLight : "#ffffff",
				correctLevel : QRCode.CorrectLevel.H,
	        });
	        // qrcode.makeCode(text)
	    }
	    Users.getSidForAuthInClient(login, onGetSID)
	}
	handleTouchEnd = () => {//need fix not working
		let currentTime = new Date().getTime();
		let tapDuration = currentTime - this.lastTapTime;

		if (tapDuration < 300 && tapDuration > 0) {
		  	authInClient(this.state.login, this.state.accountType, 'toLocalServerMarker')
		}else{
			setTimeout(() => { 
					if( this.lastTapTime > 1000 ){
						authInClient(this.state.login, this.state.accountType)
					}
				}, 
				1100 )
			
		}

		this.lastTapTime = currentTime;
	}
	render() {
		//<TransportList data = {this.state.data}/>.
		// <Link to="/control" className="btn_black">Устройства клиента</Link>
		// <Link to="/control" className="btn_black">Пользователи клиента</Link>
		const clientName = this.props.match.params.userName;
		const {accountType, name, belongsTo, expires, blocked, message, description, capabilities, kind, ignored_status} = this.state;
	    return (
	      <div className = "page client_page">
		   	<Helmet title = {`${l_v.client} - ${clientName}`} />
	      	<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN, DEALER'
										type =              'page'
                        		kindCapability =    'editClients'/>
	      	<Navigation />
	      	<ErrorBoundary>
		      	<div className='container content'>
		        	<h1 className="page_name_client" style = {{display: 'inline-flex'}}>
			        	{l_v.edit_client} - <span>{clientName}</span>
		        		{

		        			accountType ?
			        		<Fragment>
				        		<button 	onClick = { () => SL.isMobileDevice ? null: authInClient(this.state.login, accountType) }
					        		      onTouchEnd = { this.handleTouchEnd}
				        					ref = { el => this.btnGoToClient = el }
											className = 'goToClient'>
										{goToClientAppImg('goToClientIcon', accountType)}
								</button>
								<button onClick = {()=>this.showQR(this.state.login)}
										className = 'showQR' >{qrIcon('qr_icon')}
								</button>
							</Fragment> :
							null
		        		}

					</h1>

		         	<div className = 'right_of_name'>
			         	<SeparPermission 	alowed = 'ADMIN, SUPERADMIN'  kindCapability = 'isTrusted'>
		         			<Fragment>
		         				<IgnoredStatus updateIgnoredStatus = {this.updateIgnoredStatus}
														ignored_status = {ignored_status}/>
			         		
					         	<FormAllowEnterToAdmin 	login = 		{clientName}
									        						capabilities = 	{capabilities}/>
								</Fragment>
				        	</SeparPermission>
		        	</div>
		        	<div className="form_block">
			        	<div>
			        		<FormChangeName updateName =  {this.updateName}
				        					form_name =   {l_v.username}
				        					placeholder = {`${l_v.current_username}:`}
				        					currentName = {name}/>

							<FormChangeDesc desc =        {this.updateDesc.bind(this)}
											form_name =   {l_v.user_desc}
											placeholder = {`${l_v.current_user_desc}:`}
											currentdesc = {description}/>

							<SeparPermission 	alowed = 'ADMIN, SUPERADMIN'
												kindCapability = {['setBlocking', 'editOther']}>
							<FormChangeOwner 	owner = 				{this.changeOwner}
													currentVal = 		{belongsTo}
													form_name = 		{l_v.managed_by_dealers}
													dealersList = 		{this.props.DealerData}
													user_permission = {this.props.user_permission}
													kindCreator = 		{this.props.kindCreator}/>
							</SeparPermission>
							<SeparPermission 	alowed = 'ADMIN, SUPERADMIN'>
								<CapabilityEditor 	userLogin = 					{clientName}
															kindUser = 						{kind}
															userCapabilities = 			{capabilities}
															updateCapabilityInState = 	{this.updateCapabilityInState}
															belongsTo =  					{belongsTo} />
							</SeparPermission>
						</div>
						<div>
							<FormChangeMessage 	message = {this.updateMessage}
														currentmessage = {message}/>
							<FormChangePass password = 	{this.sendpassword.bind(this)}/>

						</div>

						<SeparPermission 	alowed = 'ADMIN, SUPERADMIN, DEALER'
											kindCapability = 'setBlocking'>
				        	<FormDataBlocking 	expire = 					{this.setExpire}
				        						expire_date_string_utc = 	{expires}
											  	changeblock = 				{this.setBlocking}
												currentblock = 				{blocked}
												deletedate = 				{this.setUnblocked}/>
						</SeparPermission>
		        	</div>
		        	<SeparPermission 	alowed = 			'SUPERADMIN'
											type =              'component'
	                              kindCapability =    'createAdminUsers'>
	                    <div>
			        		{/*<EnDisUserLogging login = {ifNoKey(this.state.aloxa, "login")} />*/}
			        		<Link 	to = {'/info/client/' + clientName + '/logs'}
			        				className = 'link_to_device'>
			        			{l_v.view_client_logs}
			        		</Link>
			        	</div>
				    </SeparPermission>

		        	<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER, CLIENT'
										type =              'component'
	                                	kindCapability =    'editVehicles'>
			        	<div>
			        	{
			        		accountType ?
			        		<Transports login = {clientName}  accountType = {accountType} kindUser = "Client" /> :
			        		null
			        	}
			        	</div>
		        	</SeparPermission>
    	        	<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER'>
    		        	<CustomReportsMapping login = {clientName} accountType = {accountType}/>
    	        	</SeparPermission>
		        	{/*{
		        		accountType == 'GLOBUS' ?
    		        	<SeparPermission 	alowed = 'SUPERADMIN, ADMIN, DEALER'>
    			        	<CustomReportsMapping login = {clientName} accountType = {accountType}/>
    		        	</SeparPermission> :
    		        	null
		        	}*/}
		        	<Pop_up head_text =     {'QR code for auth - ' + this.state.login}
		        	        head_back =     {{backgroundColor:"#00bcd4", color: 'white', fill: 'white'}}
		        	        ref = {(popup) => this.qrPopup = popup}
		        	        popUp_size =    {{maxWidth: '99%', width: '370px', height: '386px'}}>
		        	        <div className = 'qr_code_container'>
		        	        	<div id="qrcode" style={{width:'280px',height:'280px' }}></div>
		        	        </div>

		        	</Pop_up>
		        	{/*<Trailers login = {clientName}/>*/}
		        </div>
		    </ErrorBoundary>
				<Preloader ref = {preloader => this.preloader = preloader} />

	      </div>
	    )
	}
}


export class FormChangeDesc extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error_emty_field: '',
			message: '',
			description: this.props.currentdesc,
		}
	}
	componentWillReceiveProps(nextProps) {
		if(nextProps.currentdesc !== undefined && nextProps.currentdesc !== null && nextProps.currentdesc !== this.props.currentdesc){
			this.setState({
				description: nextProps.currentdesc
			})
		}
	}
	editdata(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	render(){
		return (
				<form >
					<p className="nameForm">{this.props.form_name}</p>
						<p className = 'form_message '>{this.state.message}</p>
						<textarea 	className = {'form_text_area ' + this.state.error_emty_field}
									type = 'text'
									name = 'description'
									value = {this.state.description}
									onChange = {this.editdata.bind(this)}
									placeholder = {this.props.placeholder}></textarea>
						<button
							className = 'btn_black'
							type = 'button'
							name = 'send'
							onClick = {()=>this.props.desc(this.state.description) }>
						{l_v.change_description}
						</button>
				</form>
		)
	}
}

export class FormChangeName extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error_emty_field: '',
			message: '',
			name: this.props.currentName,
		}
	}
	componentWillReceiveProps(nextProps) {
		if(nextProps.currentName !== undefined && nextProps.currentName !== null && nextProps.currentName !== this.props.currentName){
			this.setState({
				name: nextProps.currentName
			})
		}
	}
	editdata(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	render(){
		return (
				<form >
					<p className="nameForm">{this.props.form_name}</p>
						<p className = 'form_message '>{this.state.message}</p>
						<textarea 	className = {'form_text_area ' + this.state.error_emty_field}
									type = 'text'
									name = 'name'
									value = {this.state.name}
									onChange = {this.editdata.bind(this)}
									placeholder = {this.props.placeholder}></textarea>
						<button
							className = 'btn_black'
							type = 'button'
							name = 'send'
							onClick = {()=>this.props.updateName(this.state.name) }>
							{l_v.change_name}
						</button>
				</form>
		)
	}
}

export class FormChangeOwner extends React.Component {
	constructor(props) {
		super(props);
		const {currentVal} = this.props;
		this.state = {
			error_emty_field: '',
			message:          '',
			belongsTo:        currentVal ? currentVal : '',
			dealersList:      null
		}
		// this.filterDealersList = _.debounce(this.getDealerList, 400);
		this.filterDealerGroupsList = _.debounce(this.filterDealerGroupsList, 400);

	}
	componentWillReceiveProps(nextProps) {
		if (!!nextProps.currentVal && nextProps.currentVal !== this.state.currentdesc){
			this.setState({
				belongsTo: nextProps.currentVal ? nextProps.currentVal : ''
			})
		}
	}
	sendData = () => {
		if (!this.state.belongsTo){
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		}
		else{
			this.setState({
					message: '',
					error_emty_field: ''
				});
			this.props.owner(this.state.belongsTo);
		}
	}
	componentDidMount() {
		// this.getDealerList()
		Users.getDealersGroups(dealerGroupsList=> {
			this.setState({dealerGroupsList: dealerGroupsList})
			this.dealerGroupsList = dealerGroupsList;
		})
	}
	filterDealerGroupsList = (value) => {
		if(arrayIsEmpty(this.dealerGroupsList)){
			const filteredList = this.dealerGroupsList.filter(el => {
				const searchQuery = toLowerCase(value);
				const searchValue = el;
				return searchValue.indexOf(searchQuery) !== -1;
			})
			this.setState({
				dealerGroupsList: filteredList
			});
		}
	}
	/*getDealerList = (serchQuerry) =>{
		serchQuerry = serchQuerry ? serchQuerry : '';
		const setDealerListToState = (dealerData) => {
			console.log("%c dealerData", coCSS, dealerData)
			this.setState({
				dealersList: dealerData.content
			});
		}
		Users.getData(	'DEALER',
						{
							page: 0,
							size: 20,
							searchParam: serchQuerry,
						},
						setDealerListToState);
	}*/
	editdata(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	hint_enter_to_state = (value) => {
		this.setState({belongsTo: value});
		this.filterDealerGroupsList(value)
	}
	render(){
		const {dealersList, dealerGroupsList} = this.state;
		return (
				<form className = 'change_owner'>
						<p className="nameForm">{this.props.form_name}</p>
						<p className = 'form_message '>{this.state.message}</p>
						<SeparPermission 	alowed = 'SUPERADMIN, ADMIN'
											kindCapability = 'editClients'
											type =           'component'>
							<HintsGroups 	index = 		'no_index'
											name = 			'belongsTo'
											className = 	{'input belongsTo '}
											dealerGroupsList = 	{dealerGroupsList}
											check = 		{this.state.check}
											value = 		{this.state.belongsTo}
											onChange = 		{this.hint_enter_to_state}
											placeholder = 	{l_v.enter_owner_group}/>

				{/*			<HintUsers 	index = 		'no_index'
										name = 			'belongsTo'
										className = 	{'input belongsTo '}
										usersList = 	{dealersList}
										check = 		{this.state.check}
										value = 		{this.state.belongsTo}
										onChange = 		{this.hint_enter_to_state}
										placeholder = 	{this.props.placeholder}/>*/}

						</SeparPermission>

					{/*<input 	className = {'input ' + this.state.error_emty_field}
								type = 'text'
								name = 'description'
								value = {this.state.description}
								onChange = {this.editdata.bind(this)}
								placeholder = {this.props.placeholder}/>*/}
						<button className = 'btn_black'
								type = 'button'
								name = 'send'
								onClick = {this.sendData}>
								{l_v.change_dealer}
						</button>
				</form>
		)
	}
}

export class FormChangeMessage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error_emty_field: '',
			message: '',
			messageforsend: this.props.currentmessage?this.props.currentmessage:'',
		}
		this.senddata = this.senddata.bind(this);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			messageforsend: nextProps.currentmessage?nextProps.currentmessage:''
		})
	}
	senddata(){

			this.setState({
					message: '',
					error_emty_field: ''
				});
			this.props.message(this.state.messageforsend);

	}
	editdata(e){
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	render(){
		return (
				<form className = 'form_message_for_client'>
						<p className="nameForm">{l_v.client_message}</p>
						<p className = 'form_message '>{this.state.message}</p>
						<textarea
							className = {'form_text_area ' + this.state.error_emty_field}
							type = 'text'
							name = 'messageforsend'
							value = {this.state.messageforsend}
							onChange = {this.editdata.bind(this)}
							placeholder = {`${l_v.enter_message_for_client} *`}
						></textarea>
						<button
							className = 'btn_black'
							type = 'button'
							name = 'send'
							onClick = {this.senddata.bind(this)}
						>
						{l_v.change_description}
						</button>
					</form>
		)
	}
}

export class FormChangePass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			password: '',
			password_confirm: '',
			error_emty_field: '',
			message: ''
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			visibilityform: nextProps.visibilityform
		})
	}
	sendpassword = () =>{
		if (!this.state.password || !this.state.password_confirm){
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		}
		else{
			if (this.state.password !== this.state.password_confirm){
				this.setState({
					message: l_v.pass_not_match,
					password_error: 'formError'
				})
			}
			else{
				if(checkPass(this.state.password)){
					this.setState({
						message: '',
						password_error: ''
					});
					this.props.password(this.state.password);
				}else{
					this.setState({
						message: l_v.pass_too_easy,
						password_error: 'formError'
					});
				}

			}
		}
	}
	editpass = (e) =>{
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	render(){
		const { password_error, message } = this.state;
		return (

					<form className="changePass">
						<p className="nameForm">{l_v.change_pass}</p>
						<p className = 'form_message '>{message}</p>
						<input 	className = { `input ${password_error}` }
								type = 'password'
								name = 'password'
								onChange = {this.editpass}
								placeholder = {`${l_v.enter_password} *`}/>
						<input	className = { `input ${password_error}` }
								type = 'password'
								name = 'password_confirm'
								onChange = {this.editpass}
								placeholder =  {`${l_v.confirm_pass} *`}/>
						<button className = 'btn_black'
								type = 'button'
								name = 'send'
								onClick = {this.sendpassword}>
							{l_v.change_password}
						</button>
					</form>
		)
	}
}

export class FormDataBlocking extends React.Component {
	constructor(props) {
		super(props);
		const { expire_date_string_utc, currentblock } = this.props;
		let dateForUser = (expire_date_string_utc) ? moment(expire_date_string_utc).format('Do MMMM YYYY HH:mm:ss') : '';
		const expireDate = expire_date_string_utc ? moment(expire_date_string_utc) : moment().add('day');
		this.state = {
			error_emty_field: '',
			message: '',
			description:'',
			expires: dateForUser,
			datablocking: {
							date: '__.__.____',
							forsend: ''
							},
			user_block_status: currentblock,
			button_text: '          ',
			expireDate: expireDate

		}
	}
	componentWillReceiveProps(nextProps) {
		const { expire_date_string_utc, currentblock } = nextProps;
		let button_text;
		let dateForUser = (expire_date_string_utc) ? moment(expire_date_string_utc).format('Do MMMM YYYY HH:mm') : '';
		const expireDate = expire_date_string_utc ? moment(expire_date_string_utc) : moment().add('day');

		this.setState({
			user_block_status: currentblock,
			button_text: currentblock ? l_v.unlock : l_v.block_now,
			expires: dateForUser,
			expireDate: expireDate,
		})
	}
	enterToState = e => {
		this.setState({
			[e.target.name]: e.target.value
		})
	}
	saveBlockDate = (e) => {
		e.preventDefault();
		const {expireDate} = this.state;
		expireDate.set('second', 0);
		this.props.expire({
			date: expireDate,
			forsend: moment(expireDate).utc().format('YYYY-MM-DD[T]HH:mm:ss.s[Z]')
		})
	}
	blockRightNow = e => {
		e.preventDefault();

		this.props.changeblock(e)
	}
	deleteDate = e => {
		e.preventDefault();
		this.props.deletedate();
	}
	render(){
		const {expire_date_string_utc} = this.props;
		const {expireDate} = this.state;
		const block_text = new Date(expire_date_string_utc) < new Date() ? l_v.blocked : l_v.block_will_occur;
		return (
				<form className='form_blocking'>
					<p className="nameForm">{l_v.user_block}</p>
	        		<button type =		"button"
							name = 		'blocked'
							className = 'btn_black'
							value = 	{this.state.user_block_status}
							onClick = 	{this.blockRightNow.bind(this)}>
							{this.state.button_text}
					</button>
					

					{/* <Calendar 	giveform = 	{this.transform_to_api.bind(this)}
					 			format = 	'Do MMMM YYYY HH:mm'/>*/}
					{
						expire_date_string_utc ?
						<Fragment>
							<p className = 'form_message '>{this.state.message}</p>
							<div 	className = 'form_message blocking_date'
									style =		{{visibility: expire_date_string_utc ? 'visible ': 'hidden'}}>

									<div>{block_text}: <b>{this.state.expires}</b></div>
									<button className = 'disable_planned_block' onClick = {this.deleteDate} title = ''>
										<svg 	xmlns="http://www.w3.org/2000/svg"
												x="0px" y="0px" viewBox="0 0 16 16"
												className = "deletedate">
												<path d="M13,5H3v9c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V5z"/>
												<polygon points="11,2 10,1 6,1 5,2 2,2 2,4 14,4 14,2 "/>
										</svg>
									</button>
							</div>
						</Fragment>:
						null
					}
					<div className = 'select_blocking_date '>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
										className = 	'select_blocking_input'
										textLabel = 	{`${l_v.new_date}:`}
										name = 			'expireDate'
										value = 		{expireDate}
										onChange = 		{(e)=>this.setState({expireDate: e.value})} />
							<button type =		"button"
									className = 'btn_black last_btn'
									onClick = 	{this.saveBlockDate}>
									{l_v.block} {/*this.state.datablocking.date*/}
							</button>
					</div>

				</form>
		)
	}
}


function IgnoredStatus(props){
	let {updateIgnoredStatus, ignored_status} = props;
	return <div className='one_switch'>
				<p className='name_switch'>{l_v.ignore_while_cleaning}:</p>
				<SwitchInput 	    result = {updateIgnoredStatus}
									isChecked = {ignored_status}
									name='counter_change_oil'
									tooltip={{on: l_v.yes, off: l_v.no}}/>
			</div>
}

class FormAllowEnterToAdmin extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			isAllowedToEnterAdmin: getPremission(this.props.capabilities, 'isAllowedToEnterAdmin'),
		}
	}
	updateState = (capabilities) =>{
		this.setState({ isAllowedToEnterAdmin: getPremission(capabilities, 'isAllowedToEnterAdmin') });
		if(this.mounted) this.preloader.hide();
	}
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	componentWillReceiveProps(nextProps){
		let next = getPremission(nextProps.capabilities, 'isAllowedToEnterAdmin');
		if(next !== this.state.isAllowedToEnterAdmin){
			this.setState({ isAllowedToEnterAdmin: next })
		}
	}
	update = (e) => {
		this.preloader.show();
		Users.updateCapability({
			"login": this.props.login,
			'adddata': '/capabilities',
			"capability": 	[{
								"name": 'isAllowedToEnterAdmin',
								"value": e,
							}]
		}, this.updateState)
	}
	render(){
		return 	<div className = 'one_switch'>
						<span className='name_switch'>{l_v.adm_aviable}</span>
						<SwitchInput 	result = {this.update}
										isChecked = {this.state.isAllowedToEnterAdmin}
										name='isAllowedToEnterAdmin'
										ref="switch"
										tooltip={{on: l_v.disable_enter, off: l_v.allow_enter}}/>
						<Preloader ref = {preloader => this.preloader = preloader} />

					</div>
	}
}

export default connect(
    state => ({
    	login: 				state.authorizationR.login,
		token: 				state.authorizationR.token,
		user_permission: 	state.authorizationR.user_permission,
		thisUser: 			state.clientsR.thisUser,
		VehicleSettings: 	state.devicesR.VehicleSettings,
		DealerData:     	state.clientsR.DealerData,

    })
)(ClientPage)

function getPremission(arr, name){
		let value = false;
		if(arrayIsEmpty(arr)){
			arr.forEach(key=>{
				if(key.name == name){
					value =  key.value
				}
			})
		}
		return value
}