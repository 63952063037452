import React,  { Component }                          from 'react';
import Helmet                                        from 'react-helmet';
import SL                                            from 'sl-library'
import l_v                                           from "store/languages.jsx"
import translationsApi                               from "actions/translationsApi.js"
import Navigation                                    from 'modules/AdminNavigation.jsx'
import ServersView, { ServerContext }                from "modules/TranslationPage/ServersView.jsx"
import DevicesForTranslationView, { DevicesContext } from "modules/TranslationPage/DevicesForTranslationView.jsx"
import Pop_up                                        from 'modules/component/Pop_up.jsx'
import AddServerForm                                 from "modules/TranslationPage/AddServerForm.jsx"
import AddDeviceForm                                 from "modules/TranslationPage/AddDeviceForm.jsx"
import MappingServersToDeviceForm                    from "modules/TranslationPage/MappingServersToDeviceForm.jsx"
import ConfirmRemove                                 from "modules/ConfirmRemove.jsx"
import { FilterButtonInRow }                         from "modules/transportPage/HighLighterOfRows.js"


const TableLinking = BaseComponent => class TableLink extends BaseComponent {
	//Filter Buttons
	BtnComponent = ({ onClickBtn, row_filter_enabled }) => <FilterButtonInRow  toogleFilteringConnectedItems = { onClickBtn  }
																										row_filter_enabled = { this.state.row_filter_enabled}/>
	//names for ServersView
	serverName = (selectedServer) => this.BtnComponent({ onClickBtn: () => this.handleCellClick({ selectedServer, column_name: 'serverName' }) })
	serverHost = (selectedServer) => this.BtnComponent({ onClickBtn: () => this.handleCellClick({ selectedServer, column_name: 'serverHost' }) })
	serverPort = (selectedServer) => this.BtnComponent({ onClickBtn: () => this.handleCellClick({ selectedServer, column_name: 'serverPort' }) })
	retranslatorProtocolType = (selectedServer ) => this.BtnComponent({ onClickBtn: () => this.handleCellClick({ selectedServer, column_name: 'retranslatorProtocolType' }) })
	//names for DevicesForTranslationView
	deviceId = (selectedDevice) => this.BtnComponent({ onClickBtn: () => this.handleCellClick({ selectedDevice, column_name: 'serverPort' }) })
	// deviceImei = ({selectedDevice}) => this.BtnComponent({onClickBtn: this.handleCellClick({selectedDevice, column_name: 'serverPort'})}),
	// blocked:,
	retranslateServerDtoList = ( selectedDevice ) => this.BtnComponent({ onClickBtn: () => this.handleCellClick({ selectedDevice, column_name: 'retranslateServerDtoList' }) })

	filterServersByColumn = ({column_name, column_value, serverIdList}) => {
		const { serversList } = this.state;
		const filteredServers = serversList.filter(server => SL.arrayIsEmpty(serverIdList) ? serverIdList.includes(server.id) : server[column_name] == column_value)
		return filteredServers;
	}
	filterDevicesByColumn = ({column_name, column_value, serverIdList}) => {
		const { devicesList } = this.state;
		const filteredDevices = devicesList.filter(device => {
			const retranslateServers = device.retranslateServerDtoList || [];
			if(column_name == 'serverId') {
				return retranslateServers.some(server => column_value == server.id);
			}
			if(serverIdList){
				return retranslateServers.some(server => serverIdList.includes(server.id));
			}
			else{
				return device[column_name] == column_value;
			}
		});
		return filteredDevices;
	}
	// Обработчик клика по ячейке
	handleCellClick = ({selectedServer, column_name, selectedDevice}) => {
		const {row_filter_enabled, column_name_filter, serversList, devicesList} = this.state;
		if(row_filter_enabled && column_name_filter == column_name){
			this.setState({
				serversListForView: serversList,
				devicesListForView: devicesList,
				row_filter_enabled: false, 
				column_name_filter: null,
			});
		}else{
			let serversListForView, devicesListForView;
			if (column_name == 'retranslateServerDtoList') {//click in devices page
				const serverIdList = Array.from(selectedDevice.retranslateServerDtoList, serverDto => serverDto.id);
				serversListForView = this.filterServersByColumn({ serverIdList});
				devicesListForView = this.filterDevicesByColumn({column_name, serverIdList}); 
			}else{
				serversListForView = this.filterServersByColumn({column_name, column_value: selectedServer[column_name]});
				devicesListForView = this.filterDevicesByColumn({column_name: 'serverId', column_value: selectedServer.id}); 
			}
			this.setState({
				serversListForView: serversListForView,
				devicesListForView: devicesListForView,
				row_filter_enabled: true,
				column_name_filter: column_name
			});
		}
		
	}



	// Функция для фильтрации устройств по выбранному серверу и столбцу
	filterDevicesByServer = ({ selectedServer, column_name }) => {
		const { devicesList } = this.state;
		const filteredDevices = devicesList.filter(device => {
			const retranslateServers = device.retranslateServerDtoList || [];
			if (column_name === 'retranslateServerDtoList') {
				return retranslateServers.some(server => server.id == selectedServer.id);
			} // Добавьте другие условия для других столбцов, если необходимо
			return false;
		});
		this.setState({ devicesListForView: filteredDevices, row_filter_enabled: true });
	};
	// const deviceServersIdList = selectedDevice.retranslateServerDtoList.map(server => server.id);
	//deviceServersIdList.includes(server.id)
	// Функция для фильтрации серверов по выбранному серверу и столбцу
	FB = () => ({
		context: this,
		handleCellClick: this.handleCellClick,
		BtnComponent: this.BtnComponent,
		serverName: this.serverName,
		serverHost: this.serverHost,
		serverPort: this.serverPort,
		retranslatorProtocolType: this.retranslatorProtocolType,
		retranslateServerDtoList: this.retranslateServerDtoList,
	})

};

export default class Translation extends TableLinking(Component) {
	constructor(props){
		super(props);
		this.state = {
			serversList: 	null,
			serversListForView: null,
			serverForEdit: null,
			serverInfoForDelete: null,
			devicesList: null,
			devicesListForView: null,
			deviceInfoForDelete: null,
			deviceForEdit: null,
			deviceForMapping: null,
			row_filter_enabled: null,
			column_name_filter: null,
		}
		this.initialState = this.state;
		window.TP = this;
		const FB = this.FB();
		this.contextForServer = {
	   		callConfirmRemove: 	(serverInfoForDelete) => this.setState({serverInfoForDelete: serverInfoForDelete}, this.removeServerConfirm.open),
	   		callEditServer: 	(serverInfo) => this.setState({serverForEdit: serverInfo}, this.popServerForm.open),
	   		FB: this.FB(),
		};
		this.contextForDevice = {
	   		callConfirmRemove: 	(deviceInfoForDelete) => this.setState({deviceInfoForDelete: deviceInfoForDelete}, this.removeDeviceConfirm.open),
	   		callEditDevice: 	(deviceInfo) => this.setState({deviceForEdit: deviceInfo}, this.popDeviceForm.open),
	   		callMappingForm: 	(deviceInfo) => this.setState({
										   			deviceForMapping: deviceInfo,
										   			deviceIdForMapping: deviceInfo.deviceId
										   		}, this.mappingServerForm.open),
	   		FB: this.FB(),


		};
	}
	componentDidMount() {
		translationsApi.getServersList(serversList => this.setState({
			serversList: serversList,
			serversListForView: SL.copyOfObject(serversList),
		}))
		translationsApi.getDevisesList(devicesList => this.setState({
			devicesList: devicesList,
			devicesListForView: SL.copyOfObject(devicesList),
		}))
	}
	filterServers = (searchQuerry) => {
		const {serversList} = this.state;
		if(SL.arrayIsEmpty(serversList)){
			const serversListForView = serversList.filter(oneServer => {
				const compareQuerry = SL.doLowerCase(oneServer.serverName) + SL.doLowerCase(oneServer.serverHost) + SL.doLowerCase(oneServer.retranslatorProtocolType)
				return compareQuerry.indexOf(SL.doLowerCase(searchQuerry)) !== -1;
			})
			this.setState({
				serversListForView: serversListForView
			});
		}
	}
	filterDevices = (searchQuerry) => {
		const {devicesList} = this.state;
		if(SL.arrayIsEmpty(devicesList)){
			const devicesListForView = devicesList.filter(oneDevice => {
				const compareQuerry = SL.doLowerCase(oneDevice.deviceImei) + SL.doLowerCase(oneDevice.deviceId) + SL.doLowerCase(oneDevice.deviceOwner);
				return compareQuerry.indexOf(SL.doLowerCase(searchQuerry)) !== -1;
			})
			this.setState({
				devicesListForView: devicesListForView
			});
		}
	}
	saveServer = () => {
		if(this.addServerForm.validateForm()){
			const serverInfo = this.addServerForm.state;
			const {serversList, serversListForView, serverForEdit} = this.state;
			const addServerToList = (newServer) => {
				serversList.push(newServer);
				serversListForView.push(newServer);
				this.setState({
					serversList:        	 serversList,
					serversListForView: 	 serversListForView,
				});
				this.setFormAddServerInitial()
			}
			const updateServerInList = (newServerInfo) => {
				const serversListIndex = serversList.findIndex(serverInfo => serverInfo.id == newServerInfo.id);
				const serversListForViewIndex = serversListForView.findIndex(serverInfo => serverInfo.id == newServerInfo.id);
				serversList[serversListIndex] = newServerInfo;
				serversListForView[serversListForViewIndex] = newServerInfo;
				this.setState({
					serversList: 		 SL.copyOfObject(serversList),
					serversListForView:  SL.copyOfObject(serversListForView)
				});
				this.setFormAddServerInitial()
			}
			serverForEdit ?
			translationsApi.updateServer(serverInfo, updateServerInList) :
			translationsApi.addServer(serverInfo, addServerToList);
		}
	}
	setFormAddServerInitial = () => {
		this.setState({serverForEdit: null}, this.popServerForm.close)
	}
	setFormAddDeviceInitial = () => {
		this.setState({deviceForEdit: null}, this.popDeviceForm.close)
	}
	removeServer = () => {
		const {serverInfoForDelete, serversList, serversListForView} = this.state;
		const onDeleteServer = () => {
			this.setState({
				serversList: SL.removeElementFromList(serversList, 'id', serverInfoForDelete.id),
				serversListForView: SL.removeElementFromList(serversListForView, 'id', serverInfoForDelete.id),
				serverInfoForDelete: null
			});
			SL.removeElementFromList(serverInfoForDelete.id, 'id', )
		}
		translationsApi.deleteServer(serverInfoForDelete.id, onDeleteServer)
	}
	saveDevice = () => {
		if(this.addDeviceForm.validateForm()){
			const deviceInfo = this.addDeviceForm.state;
			const {devicesList, devicesListForView, deviceForEdit} = this.state;
			const addDeviceToList = (newDevice) => {
				devicesList.push(newDevice);
				devicesListForView.push(newDevice);
				this.setState({
					devicesList:        	 devicesList,
					devicesListForView: 	 devicesListForView,
				});
				this.setFormAddDeviceInitial()
			}
			const updateDeviceInList = (newDeviceInfo) => {
				const devicesListIndex = devicesList.findIndex(deviceInfo => deviceInfo.deviceId == newDeviceInfo.deviceId);
				const devicesListForViewIndex = devicesListForView.findIndex(deviceInfo => deviceInfo.deviceId == newDeviceInfo.deviceId);
				devicesList[devicesListIndex] = newDeviceInfo;
				devicesListForView[devicesListForViewIndex] = newDeviceInfo;
				this.setState({
					devicesList: 		 SL.copyOfObject(devicesList),
					devicesListForView:  SL.copyOfObject(devicesListForView),
				});
				this.setFormAddDeviceInitial()
			}

				deviceForEdit ?
				translationsApi.updateDevice(deviceForEdit.deviceId, deviceInfo, updateDeviceInList) :
				translationsApi.addDevice(deviceInfo, addDeviceToList);
		}
	}
	updateDeviceServersList = (newDeviceInfo) => {
		const {devicesList, devicesListForView, deviceForEdit} = this.state;
		const devicesListIndex = devicesList.findIndex(deviceInfo => deviceInfo.deviceId == newDeviceInfo.deviceId);
		const devicesListForViewIndex = devicesListForView.findIndex(deviceInfo => deviceInfo.deviceId == newDeviceInfo.deviceId);
		devicesList[devicesListIndex] = newDeviceInfo;
		devicesListForView[devicesListForViewIndex] = newDeviceInfo;
		this.setState({
			devicesList: 		 SL.copyOfObject(devicesList),
			devicesListForView:  SL.copyOfObject(devicesListForView)
		});
	}
	removeDevice = () => {
		const {deviceInfoForDelete, devicesList, devicesListForView} = this.state;
		const onDeleteDevice = () => {
			this.setState({
				devicesList: SL.removeElementFromList(devicesList, 'deviceId', deviceInfoForDelete.deviceId),
				devicesListForView: SL.removeElementFromList(devicesListForView, 'deviceId', deviceInfoForDelete.deviceId),
				deviceInfoForDelete: null
			});
			SL.removeElementFromList(deviceInfoForDelete.deviceId, 'deviceId', )
		}
		translationsApi.deleteDevice(deviceInfoForDelete.deviceId, onDeleteDevice)
	}
	render() {
		const {
			serversListForView,
			serversList,
			serverForEdit,
			devicesListForView,
			deviceForEdit,
			deviceForMapping,
			deviceIdForMapping
		} = this.state;
		return (
			<div className = 'translation_page'>
					<Helmet title = {l_v.broadcasts} />
				  	<Navigation />
				  	<div className='container content'>
					   	<h1 className="page_name">{l_v.broadcasts}</h1>
					   	<ServerContext.Provider value = {this.contextForServer}>
						   	<ServersView handleFilterChange =   { this.filterServers }
							   				 callFormForAddServer = { ()=>this.popServerForm.open() }
							   				 serversList =          { serversListForView }/>
		   				</ServerContext.Provider>

					   	<DevicesContext.Provider value = {this.contextForDevice}>
						   	<DevicesForTranslationView handleFilterChange = 	{ this.filterDevices }
												   				callFormForAddDevice = 	{ ()=>this.popDeviceForm.open() }
												   				devicesList = 			{ devicesListForView }/>
		   				</DevicesContext.Provider>

		   				<Pop_up ref = 			{(popUp)=>this.popServerForm = popUp}
				                head_text= 		{ serverForEdit ? `${l_v.edit_server} - ${serverForEdit.serverName}` : l_v.adding_server }
				                head_back = 	{{backgroundColor:"#708090", color: 'white', fill: 'white'}}
				                popUp_size = 	{{maxWidth: '99%', width: '500px', height: !serverForEdit ? '450px' : '250px'}}
				                popUp = 		{this.popUp_activation}>
			               		<div className ='pop_up_content'>
				               		<AddServerForm 	ref = {form => this.addServerForm = form}
				               						serverForEdit = {serverForEdit}/>
					               	<div className = 'pop_up_footer'>
	   	                        		<button className='secondary_btn' onClick={this.setFormAddServerInitial}>
	   	                        			{l_v.cancel}
	   	                        		</button>
	   	        	                	<button className='primari_btn' onClick={this.saveServer}>{l_v.save}</button>
	   	                        	</div>
   	                        	</div>
		   	            </Pop_up>

		   	            <ConfirmRemove  ref =           {confirm => this.removeServerConfirm = confirm}
		   	                            confirmText =   {l_v.sure_delete_server}
		   	                            clear =         {()=>this.setState({
		   	                                                    serverInfoForDelete: null
		   	                                            })}
		   	                            remove =    	{this.removeServer}/>
		   	           	<ConfirmRemove  ref =           {confirm => this.removeDeviceConfirm = confirm}
		   	                            confirmText =   {l_v.sure_delete_device}
		   	                            clear =         {() => this.setState({
		   	                                                   deviceInfoForDelete: null
		   	                                           })}
		   	                            remove =    {this.removeDevice}/>
		   				<Pop_up ref = 			{ (popUp)=>this.popDeviceForm = popUp }
				                head_text= 		{ deviceForEdit ? `${l_v.device_edit} - ${deviceForEdit.deviceId}` : l_v.add_device }
				                head_back = 	{ {backgroundColor:"#708090", color: 'white', fill: 'white'} }
				                popUp_size = 	{ {maxWidth: '99%', width: '500px', height: !deviceForEdit ? '271px' : '200px'} }
				                popUp = 		{ this.popUp_activation }>
			               		<div className ='pop_up_content'>
				               		<AddDeviceForm 	ref = {form => this.addDeviceForm = form}
				               						deviceForEdit = {deviceForEdit}/>
					               	<div className = 'pop_up_footer'>
	   	                        		<button className='secondary_btn' onClick={this.setFormAddDeviceInitial}>
	   	                        			{l_v.cancel}
	   	                        		</button>
	   	        	                	<button className='primari_btn' onClick={this.saveDevice}>{l_v.save}</button>
	   	                        	</div>
   	                        	</div>
		   	            </Pop_up>
		   	            <Pop_up ref = 			{(popUp)=>this.mappingServerForm = popUp}
				                head_text= 		{ `${l_v.relaying_servers} - ${deviceIdForMapping}`}
				                head_back = 	{{backgroundColor:"#708090", color: 'white', fill: 'white'}}
				                popUp_size = 	{{maxWidth: '99%', width: '1100px', height: '585px'}}
				                popUp = 		{this.popUp_activation}>
			               		<div className ='pop_up_content'>
			               			<MappingServersToDeviceForm deviceForMapping =  {deviceForMapping}
			               										updateDeviceServersList = {this.updateDeviceServersList}
			               										serversList = 		{serversList ? SL.copyOfObject(serversList) : serversList}/>
			               		</div>
	               		</Pop_up>

			   		</div>
			</div>
		);
	}
}