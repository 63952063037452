import React, { Component }                                  from 'react';
import Navigation                                            from './AdminNavigation.jsx'
import OneNotifInfo                                          from './whatIsNewAfterUpdate/OneNotifInfo.jsx'
import FormUpdateNotification                                from './whatIsNewAfterUpdate/FormUpdateNotification.jsx'
import Preloader                                             from './preloader.jsx'
import ConfirmRemove                                         from './ConfirmRemove.jsx'
import Pop_up                                                from './component/Pop_up.jsx'
import { arrayIsEmpty, copyOfObject, removeElementFromList } from 'sl-library'
import UpdatesNotif                                          from 'actions/UpdatesNotif.js'
import Helmet                                                from 'react-helmet';
import l_v                                                   from "store/languages.jsx"

export default class WhatIsNewAfterUpdatePage extends Component {
	constructor(props){
		super(props);
		this.state = {
			updatesList: [],
			editedNotification: null
		}
		this.initialState = this.state;
	}
	componentDidMount() {
		UpdatesNotif.getNotification(notifList=>this.setState({updatesList: notifList}))
	}
	saveNotification = () => {
		const {notificationText} = this.formConent.state;
		const {editedNotification} = this.state;
		const updateListOnCreate = (newUpdatesNotif) => {
			const {updatesList} = this.state;
			updatesList.push(newUpdatesNotif)
			this.setState({
				updatesList: updatesList
			});
			this.notifForm.close()
		}
		const updateListOnUpdate = (newUpdatesNotif) =>{
			const {updatesList} = this.state;
			const indexForUpdate = updatesList.findIndex(key => {
				if(key.id == newUpdatesNotif.id) {
					return key;
				}
			})
			updatesList[indexForUpdate] = newUpdatesNotif;
			this.setState({
				updatesList: copyOfObject(updatesList),
				editedNotification: null
			});
			this.notifForm.close()
		}
		if(!editedNotification){
			UpdatesNotif.createNotification(notificationText, updateListOnCreate)
		}else{
			const {id} = editedNotification;
			UpdatesNotif.updateNotifications(notificationText, id, updateListOnUpdate)
		}
	}
	callRemoveNotif = (id) => {
		this.idForDelete = id;
		this.deleteForm.open(true)
	}
	callUpdateNotif = (notifInfo) => {
		this.setState({
			editedNotification: notifInfo
		});
		this.notifForm.open()
	}

	deleteNotification = () => {
		let afterDeleteNotification = () => {
			let {updatesList} = this.state;
			this.setState({
				updatesList: removeElementFromList(updatesList, 'id', this.idForDelete)
			});
		}
		UpdatesNotif.deleteNotification(this.idForDelete, afterDeleteNotification)
	}
	render() {
		return (
			<div>
				<Helmet title = {l_v.info_updates} />
				<Navigation />
				<div className='container content what_is_new_content'>
            		<h1 className="page_name">{l_v.info_updates}</h1>
            		<button className = "btn_black" onClick={()=>this.notifForm.open()}>{l_v.add_new_notification}</button>
            		<ListOfUpdates 	updatesList = {this.state.updatesList}
            						removeNotif = {this.callRemoveNotif}
            						updateNotif = {this.callUpdateNotif} />
            		<Pop_up head_text = 	{l_v.info_updates}
	        	            head_back = 	{{backgroundColor:"#00bcd4", color: 'white', fill: 'white'}}
	        	            ref = 			{(notifForm) => this.notifForm = notifForm}
	        	           	popUp_size = 	{{maxWidth: '99%', width: '500px', height: '400px'}}>
	        	           	<div className = 'pop_up_content'>
			                   	<FormUpdateNotification 	{...this.state.editedNotification}
			                   								ref = {formConent => this.formConent = formConent}
			                   								updateNotif = 		{this.updateNotif}
					                   						/>
					            <div className = "pop_up_footer ">
					            	<button className = 'btn_black' onClick = {this.saveNotification}>Сохранить</button>
					            </div>
					        </div>
	            	</Pop_up>
	            	<ConfirmRemove 	ref = 			{deleteForm => this.deleteForm = deleteForm}
	            					confirmText = 	'Вы уверены что хотите удалить уведомление?'
	            					clear = 		{()=>this.setState({
	            											forDelete: undefined
	            									})}
	            					remove = 	{this.deleteNotification}/>
				</div>
			</div>
		);
	}
}
function ListOfUpdates(props) {
	let {updatesList, removeNotif, updateNotif} = props;
	if(arrayIsEmpty(updatesList)){
		return updatesList.map(key => <OneNotifInfo {...key} key={key.id} updateNotif = {updateNotif}
													                       removeNotif = {removeNotif}/>);
	}else{
		return null;
	}
}