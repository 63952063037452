import React, { useState }                                                      from "react"
import SeparPermission, { permissionsFoRequst }                                 from './SeparPermission.jsx'
import { connect }                                                              from 'react-redux'
import Users                                                                    from 'actions/users.js'
import { closeImg }                                                             from '../img.jsx'
import _                                                                        from 'lodash'
import { removeProhibitedSymbol, matchPronhibitedSymbol, removeSpaces }         from "actions/ActionCreator.jsx"
import ClickOutside                                                             from 'react-click-outside'
import { toLowerCase, arrayIsEmpty, removeElementFromList, ifNoKey, checkPass } from 'actions/ActionCreator.jsx'
import l_v                                                         from "store/languages.jsx"
import EnterListennerForForm                                                    from "modules/component/EnterListennerForForm.jsx"
class BtnAdd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visibilityform: 	'',
			bonusinput: 		'',
			login: 				'',
			belongsTo: 			emptyOwnerForAdminSuperAdmin((this.props.defaultowner || this.props.defaultgroup), this.props.kindCreator),
			password: 			'',
			password_confirm:  	'',
			description: 		'',
			message: 			'',
			password_error: 	'',
			error_emty_field: 	'',
			permissionForGroup: (this.props.kindUser == 'ADMIN' || this.props.kindUser == 'SUPERADMIN')?true:false,
			kindUser: 			this.props.kindUser,
			capabilities: 		[],
			accountType: 		'GLOBUS',
			dealersList: 		null,
			name: 				''
		}
		this.filterDealerGroupsList = _.debounce(this.filterDealerGroupsList, 400);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			permissionForGroup: nextProps.permissionForGroup,
			kindUser: 			nextProps.kindUser,

		})
		if(!!nextProps.defaultowner){
			this.setState({
				belongsTo: emptyOwnerForAdminSuperAdmin((nextProps.defaultowner || nextProps.defaultgroup), nextProps.kindCreator),
			});
		}
	}
	componentDidMount() {
		const { kindCreator, DealerData } = this.props;
		permissionsFoRequst('SUPERADMIN, ADMIN, DEALER', ['editOther', 'createAdminUsers'], this.getDealersGroups)
	}
	getDealersGroups = () => {
		Users.getDealersGroups(dealerGroupsList => {
			this.setState({ dealerGroupsList: dealerGroupsList })
			this.dealerGroupsList = dealerGroupsList;
		})
	}
	filterDealerGroupsList = (value) => {
		if(arrayIsEmpty(this.dealerGroupsList)) {
			const filteredList = this.dealerGroupsList.filter(el => {
				const searchQuery = toLowerCase(value);
				const searchValue = el;
				return searchValue.indexOf(searchQuery) !== -1;
			})
			this.setState({
				dealerGroupsList: filteredList
			});
		}
	}
	enterToState = (e) => {
		e.preventDefault()
		let value = e.target.value
		if(e.target.name == 'login') {
			if(matchPronhibitedSymbol(value)) {
				this.setState({
					message: "Разрешены только латинские символы и цифры"
				});
				value = removeProhibitedSymbol(value)
			} else {
				this.setState({
					message: null
				});
			}
		}
		if(e.target.name == 'password' || e.target.name == 'login' || e.target.name == 'password_confirm') {
			value = removeSpaces(value);
		}
		this.setState({
			[e.target.name]: value
		})
	}
	addition = () => {
		const { accountType, belongsTo, login, password, password_confirm, capabilities, description, name } = this.state;
		const { user_permission, kindUser, kindCreator } = this.props;
		if(!password || !password_confirm || !login) {
			this.setState({
				message: `${l_v.fill_marked_fields}`,
				error_emty_field: 'formError'
			})
		} else {
			if(password !== password_confirm) {
				this.setState({
					message: l_v.pass_not_match,
					password_error: 'formError'
				})
			} else {
				if(!checkPass(password)) {
					this.setState({
						message: l_v.newPassMustBe,
						password_error: 'formError'
					})
				} else {
					this.setState({
						message: '',
						password_error: ''
					})
					const belongsToForSend = kindCreator == 'DEALER' ? ifNoKey(user_permission, 'belongsTo') : belongsTo;
					const dataForSend = {
						password:      password,
						capabilities:  capabilities,
						belongsTo:     belongsToForSend,
						description:   description,
						name:          name,
					};
					kindUser == 'CLIENT' ? (dataForSend.accountType = accountType) : null;
					this.props.dataFromform({
						kindUser: kindUser,
						login: login,
						dataForSend: dataForSend
					})
					this.toogleFormVisibility();
					this.clearState();
				}
			}
		}
	}
	toogleFormVisibility = () => {
		const { formIsVisible } = this.state;
		this.setState({
			formIsVisible: !formIsVisible,
		})
	}
	clearState = () => {
		this.setState({
			message: '',
			password_error: '',
			login: '',
			password: '',
			password_confirm: '',
		})
	}
	hint_enter_to_state = (value) => {
		this.setState({ belongsTo: value });
		this.filterDealerGroupsList(value)
	}
	render() {
		const {kindUser, accountType, belongsTo, dealersList, dealerGroupsList, formIsVisible} = this.state;
		const display = belongsTo ? 'none' : '';
		return <div className = 'add_info' style={{display: this.props.display}}>
					<span 	className = 'btn_black'
								onClick = 	{this.toogleFormVisibility}>
							{this.props.btnname}
					</span>
					{
						formIsVisible ?
						<EnterListennerForForm onPressEnter = { this.addition }>
							<form className = {'add_info_form show_form'} ref = {el => this.form = el }>
								{closeImg('close', this.toogleFormVisibility)}
								<div className = 'form_message'>
									<p>{this.state.message}</p>
								</div>
								<TubsAccountType 	accountType = {accountType}
													kindUser = {kindUser}
													changeAccountType = {this.enterToState}/>
								{this.props.bonusinput}
								<div className = 'flex_container'>
									<input 	type = 			"text"
											className = 	{'input ' + this.state.error_emty_field }
											name = 			'login'
											value = 		{this.state.login}
											onChange = 		{this.enterToState}
											placeholder = 	{this.props.name_placeholder +' *'}/>
									<SeparPermission 	alowed = 'SUPERADMIN, ADMIN'
														kindCapability = {['editClients', 'editOther']}
														type =           'component'>
										<HintsGroups 	index = 		'no_index'
														name = 			'belongsTo'
														className = 	{'input belongsTo '}
														dealerGroupsList = 	{dealerGroupsList}
														check = 		{this.state.check}
														value = 		{this.state.belongsTo}
														onChange = 		{this.hint_enter_to_state}
														placeholder = 	{l_v.enter_owner_group}/>

									</SeparPermission>
									<textarea 	type = 			"text"
												className = 	"form_text_area"
												name = 			"description"
												value = 		{this.state.description}
												placeholder = 	{l_v.description}
												onChange = 		{this.enterToState}/>
									<textarea 	type = 			"text"
												className = 	"form_text_area"
												name = 			"name"
												value = 		{this.state.name}
												placeholder = 	{l_v.username}
												onChange = 		{this.enterToState}/>
								</div>
								<div>
									<input 	type = 			"text"
											name =			"password"
											className = 	{'input ' + this.state.password_error + ' ' +this.state.error_emty_field }
											placeholder = 	{`${l_v.enter_password} *`}
											value = 		{this.state.password}
											onChange = 		{this.enterToState}/>
									<input 	type = 			"text"
											className = 	{'input ' + this.state.password_error + ' ' +this.state.error_emty_field }
											name = 			'password_confirm'
											value = 		{this.state.password_confirm}
											placeholder = 	{`${l_v.confirm_password}  *`}
											onChange = 		{this.enterToState} />
								</div>
								<button type = 		"button"
										className = 'btn_black'
										onClick = 	{this.addition.bind(this)}>
										{l_v.add}
								</button>
							</form>
						</EnterListennerForForm> :
						null
					}

				</div>
		}
}
export default connect(
	    state => ({
	    	login: 				state.authorizationR.login,
			token: 				state.authorizationR.token,
			kindCreator: 		state.authorizationR.kindUser,
			user_permission: 	state.authorizationR.user_permission,
			DealerData:     	state.clientsR.DealerData,

	    })
)(BtnAdd)

function emptyOwnerForAdminSuperAdmin(ownerGroup, kindUser) {
	if(kindUser == 'ADMIN' || kindUser == 'SUPERADMIN') {
		return ''
	} else {
		return ownerGroup
	}
}

function TubsAccountType(props) {
	const {accountType, kindUser, changeAccountType} = props;
	const activeTeres = {
		backgroundColor: 'rgb(242, 101, 34)',
		color: 'white'
	};
	const activeGlobus  = {
		backgroundColor: '#4179f8',
		color: 'white'
	}
	return 	kindUser == 'CLIENT' ?
			<div className="accountType_tubs">
				<button className="tubs" onClick={changeAccountType} value = 'TERES' name = 'accountType'
						style = { accountType == 'TERES' ? activeTeres : null}>
					{l_v.account} TERES
				</button>
				<button className="tubs" onClick={changeAccountType} value = 'GLOBUS' name = 'accountType'
						style = { accountType == 'GLOBUS' ? activeGlobus : null}>
					{l_v.account} GLOBUS
				</button>
			</div> :
			null
}

export function HintsGroups(props){
	const {name, index, className, dealerGroupsList} = props;
	const [visible, setVisible] = useState(false);
	console.log("%c visible", coCSS, visible)
	function onSelectValue(e) {
		const value = e.target.attributes.getNamedItem('data-value').value;
		setVisible(false)
		props.onChange(value)
	}
	return 	<ClickOutside 	className = 		'hint_container'
							onClickOutside =	{()=>setVisible(false)}>
				<input 	type =  		"text"
						id = 			'input_belongs_to'
						name = 			{name}
						data-index = 	{index}
						className = 	{'input ' + className}
						style = 		{style(props.check, props.value)}
						value =			{props.value}
						placeholder = 	{props.placeholder}
						autoComplete =	"off"
						onChange = 		{(e)=>props.onChange(e.target.value)}
						onFocus = 		{()=>setVisible(true)}/>
				<Hints 	listForHint = {dealerGroupsList}
						visible = {visible}
						selectElement = {onSelectValue} />
			</ClickOutside>
}

function Hints(props) {
	const {listForHint, visible, selectElement} = props;
	return 	arrayIsEmpty(listForHint) && visible ?
				<div className="hint_data" style = {{display: 'block'}}>
					{
						listForHint.map(key => 	<p 	data-value = 	{key}
						                			data-name = 	{key}
													key =			{key}
													onClick =		{selectElement}>
												{key}
												</p> )
					}
				</div>
			:
			null
}

function style(check, value){
	if(!!check && value === ''){
		return {boxShadow: "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset"}
	}else{
		return null
	}
}