import React, { Component } from 'react';
import 'css/oneCheckbox.scss'
export default function OneCheckBox(props){
		const {	checkbox_value, onChangeCheckbox, keySettings, label_text,
				input_value, input_name, updateDelay, name, disabled,
				text_after, text_before, id} =  props;
		return 	<div className="one_checkbox_def">
					<span className='for_custom_checkbox'>
							<input 	type = 'checkbox'
									className = 'checkbox_blue'
									id = {keySettings||name}
									disabled = {disabled}
									onChange = {onChangeCheckbox}
									defaultChecked = {checkbox_value?checkbox_value:false}
									name = {keySettings||name}/>
							<label htmlFor = {keySettings}></label>
						</span>
						<span 	className='psevdo_label'>
								{label_text}
						</span>
				</div>
}
function disabledText(checkbox_value) {
	return checkbox_value?1:0.3
}

function disabled(checkbox_value) {
	return checkbox_value?false:true
}