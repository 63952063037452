import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import reducer from "reducers/combine_reducer.jsx"
const logger = createLogger({
  predicate: (getState, action) => ( //disabled logs instruction
    process.env.NODE_ENV !== 'production' ||
    (true || action.type !== 'NEWSTATE' && action.type !== 'STATUS_REQUEST')
  )
})
// const store = createStore(reducer);
//logger will added only for local host (search DEVELOPMENT value in webpack config)
const store = /*DEVELOPMENT*/false ? createStore(reducer, applyMiddleware(thunk, logger)) : createStore(reducer); //createStore(reducer); //
// store.subscribe((e)=>console.log("%c STORE.SUBSCRIBE e", coCSS, e)); can add some side effect on update store
export default store;