import axios 		from 'axios'
import bugReport 	from './bugReport'
import infoStore 	from './infoStore.js'
import store 		from 'store/configureStore.jsx'
import l_v 				from "store/languages.jsx"

const trailersApi = {
	getTrailersList: getTrailersList,
	getTrailerInfo:  getTrailerInfo,
	addTrailer:      addTrailer,
	updateTrailer:   updateTrailer,
	deleteTrailer:   deleteTrailer,
	getTrailersListPaginated: getTrailersListPaginated
}

export default trailersApi;

function getTrailersListPaginated(params, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'trailer/page',
		dataType: "JSON",
		method:   'GET',
		headers:  { 'authorization': infoStore().token },
		params:   params
	};
	axios(requestData)
	.then(answer => callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer))
}

function getTrailersList(ownerOfTrailers, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'trailer',
		dataType: "JSON",
		method:   'GET',
		headers:  { 'authorization': infoStore().token },
		params:   { owner: ownerOfTrailers }
	};
	axios(requestData)
	.then(answer => callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer))
}

function getTrailerInfo(traileId) {
	const requestData = {
		baseURL:         infoStore().base_url,
		url:             infoStore().bonus_url_api + 'trailer/' + traileId,
		dataType:        "json",
		method:          'GET',
		headers:         { 'authorization': infoStore().token },
		withCredentials: true,
	};
	axios(requestData)
	.then(answer => callback(answer.data))
	.catch(serveranswer => bugReport(serveranswer, store))
}

function addTrailer(trailerInfo, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'trailer',
		dataType: "JSON",
		method:   'PUT',
		headers:  { 'authorization': infoStore().token },
		data:     trailerInfo
	}
	axios(requestData)
	.then(answer => {
		callback(answer.data);
		store.dispatch({
			type:          'STATUS_REQUEST',
			setAnswer:     answer.status,
			settextanswer: l_v.trailer_added
		})
	})
	.catch(serveranswer => bugReport(serveranswer, store))
}

function updateTrailer(trailerInfo, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'trailer/' + trailerInfo.vehicleId,
		dataType: "JSON",
		method:   'POST',
		headers:  { 'authorization': infoStore().token },
		data:     trailerInfo,
	}
	axios(requestData)
	.then(answer => {
		callback(answer.data);
		store.dispatch({
			type:          'STATUS_REQUEST',
			setAnswer:     answer.status,
			settextanswer: l_v.information_was_updated
		})
	})
	.catch(serveranswer => bugReport(serveranswer, store));
}

function deleteTrailer(trailerId, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_api + 'trailer/' + trailerId,
		dataType: "JSON",
		method:   'DELETE',
		headers:  { 'authorization': infoStore().token },
	}
	axios(requestData).then(answer => {
		callback(trailerId)
		store.dispatch({
			type:          'STATUS_REQUEST',
			setAnswer:     	answer.status,
			settextanswer:  l_v.trailer_removed
		})
	})
	.catch(serveranswer => bugReport(serveranswer, store))
}