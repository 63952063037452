import React 			from 'react';
import createReactClass from 'create-react-class';
import axios 			from 'axios'
import bindingToRoad 	from '../utils/bindingToRoad.js'
import bugReport 		from '../../../actions/bugReport.jsx'
let test = 'mwxfHs}{tEYn@yCxGTX^d@`G|HvDpElLtNtBtCdFlGzR|VhWn\\|C~DlAoD|EuNPe@n@x@rBhCzBhCxGpG|DlDhJpI|EnEjVrT|ElErGhFxCfBrObIZu@hAs@nEsCjI}F~HiFdC}BzJiJjBkB\\S`Bq@VFXKpBa@~@JxAZvD~@nEhA~Cv@`B\\h@?^C|@QpHkBlBcA`S}Nvj@sb@vYiTrQaN|N}KzBqA^UtAYf@K`ZgEzEi@dCFhEx@ld@tJzBh@XP`Cf@`FlAnAb@zOrHdTnLhGnDzJrFFd@X|AjFzX`@rA`@t@p@j@z@TjETtAI|@S`GcDbHyD`UhKfUdKrEzApDx@xEp@bDJfDAfEUzGs@|QiCha@oFfw@sKfFk@tDMjDB`DTpEn@jDx@tDpAhEtB|F~DjG~Fjo@dt@x}@zdAfH|H~n@ls@zfAjmAhSdUrFzGjCjEhLpThKbShInQdR|a@zGbOlDnHtBlDrCpDpCjCdD|BfClAvCfAxE`Ax^pE`sAnOpDTlDA|Da@nGyAjf@}LzYaHtfCqi@pWsFx_@qIviAkV~MiC~Fi@zFG|GVhEd@bE|@bFvArW|JnpAtf@r_@zNjLpEjT`JnFtC~CrBpDlCdLnJzr@ll@dXhUr]pYxCrBnFxBbFpAvAPtCBbGY~VqBbFQ~FRlpAxGd`@rBblBjLtz@bFvDL|CGdl@iGbq@sHvCm@|EcB~BqAx]iWz|@wo@fy@cm@x{@_x@nrAamAnj@mg@~FkFjCsBjDeBpDkAbEu@|CKxC@vD^bo@~Ljs@jNx[pG`iAtTrb@jIvx@`PbJlBzEh@xD@dI{@`dAwQjh@aJjPkDbOoDxa@qJnp@uPjn@sPhIoC\\Gz@w@\\_BKcBm@gAo@[c@Ca@e@Y{@_Dc]oD{a@Q}EEmGNiOVgOZ{Ez@_FnE_O~@iD~@gGdEak@fBcZNcNIkHaB_]eIcmAmCqa@Q_EAqDZeFf@wCp@oCxAcEbBqDrAgBxAwAvAeAjB_AvAk@`Fw@|Dw@vCcAnDqBxB_BzDeErCkExBeExAqDxAgFvAqHv@mIPsFQ{OPKT]Hg@C}@FaANo@nBiE~Ni\\vCeHnDaMfAiCbB_BpBkAlH{Cr@e@h@{@d@mBJ}Eh@cHbB_It@aCrA{CxBiDjBkBrAcAx@_@j@Kv@Fh@Nh@^zB|D|@rAt@n@bAb@fAPt@CbAUzAeAx@eAr@}A\\mAf@eCNwBCmESsA[cAw@aAiA_Bm@k@USMUhC_G`@{@nBqEfL_WlNq[zP{_@js@}~Axo@_xAU[]x@yB|E'
export default class DrawRoad extends React.Component{
	constructor(props) {
		super(props);
		this.state={
			points: 			undefined, // wait array like this [[lat, lng],[lat, lng],...]
			snappedPolyline: 	undefined, // it will be set when polilyne will be draw
			map: 				this.props.map,  //map where will drawn polyline
			snaptoroad: 		this.props.snaptoroad,  // boolian (if use this component on route detail, we draw pure coordinat, if on setMarshrut we use api snap to road)
		}
		this.drawroad = this.drawroad.bind(this)
	}
	componentDidMount() {
		// this.drawroad('', test)
	}
	componentWillReceiveProps(nextProps) {
		if (!!nextProps.points && nextProps.points !== this.state.points){
			if(!!this.state.snappedPolyline){//clear previous polyline if it was drawn
					let map = this.state.map,
					snappedPolyline = this.state.snappedPolyline;
					snappedPolyline.setMap(null)
					this.setState({map: map})
			}
			this.setState({
				points: nextProps.points,
				newtask: nextProps.newtask,
			})
			this.drawroad(nextProps.points)
		}else{
			console.log('ELSE', nextProps)
			if(!!this.state.snappedPolyline){
				this.state.snappedPolyline.setMap(null)
				this.setState({
					snappedPolyline: undefined,
					points: undefined
				});
			}
		}
	}
	drawroad(points){

		let map = this.state.map,
			snappedPolyline,
		    path = bindingToRoad.getSrcSnapToRoads(points, this.props.match.params, this.props.options);
		if(!this.state.snaptoroad && !!points){ //use this on draw marshrut on deatailes
			drawSnappedPolyline(processSnapToRoadGlobus(points))//draw line by pure coordinates
		}
		if(!!this.state.snaptoroad && !!path){
			runSnapToRoad(path); //draw line whith help roads api
		}
		if(!!points && points instanceof Array){
			map.setCenter({'lat':points[points.length-1][0],'lng':points[points.length-1][1]})// set map on last points
		}
		function processSnapToRoadGlobus(data) {
		  	let snappedCoordinates = [];
		 	let  placeIdArray = [];
			  for (let i = 0; i < data.length; i++) {
			    let latlng = new google.maps.LatLng(
			       data[i][0],
			       data[i][1])
			    snappedCoordinates.push(latlng);
			  }
		   	return snappedCoordinates
		}



		function runSnapToRoad(){// request to google servers
		 	axios({
				url: path,
				method: 'GET',
			})
			.then(function(answer){
			    			drawSnappedPolyline(processSnapToRoadResponse(answer.data));
				}
			)
		    .catch(function(serveranswer) {
				  bugReport (serveranswer)
			})
		}
		// Store snapped polyline returned by the snap-to-road service.
		function processSnapToRoadResponse(data) {
		  	let snappedCoordinates = [];
		 	let  placeIdArray = [];
			  for (let i = 0; i < data.snappedPoints.length; i++) {
			    let latlng = new google.maps.LatLng(
			        data.snappedPoints[i].location.latitude,
			        data.snappedPoints[i].location.longitude);
			    snappedCoordinates.push(latlng);
			    placeIdArray.push(data.snappedPoints[i].placeId);

			  }
		   	return snappedCoordinates
		}
		// Draws the snapped polyline (after processing snap-to-road response).
		function drawSnappedPolyline(data) {
		  let snappedCoordinates = data;
		  snappedPolyline = new google.maps.Polyline({
		    path: snappedCoordinates,
		    strokeColor: '#4079f8',
		    strokeWeight: 6
		  } );
		  snappedPolyline.setMap(map);
		  // polylines.push(snappedPolyline);

		}
		this.setState({
		  	snappedPolyline: snappedPolyline,
		  	map: map,
		})
		// snappedPolyline.setMap(null)
	}
	componentWillUnmount() {
		if(!!this.state.snappedPolyline){
			this.state.snappedPolyline.setMap(null)
		}
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (nextProps.points !== this.state.points){
			console.log('nextProps.points !== this.state.points', nextProps.points !== this.state.points)
			return true
		} else {return false}
	}
	render(){
		console.log('render drawroad')
		return(null)
	}


}