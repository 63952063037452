import React, {useState, Fragment, useReducer }		from 'react'
import {connect} 						from 'react-redux'
import {HintUsers} 						from './HintData.jsx'
import Users 							from "actions/users.js"
import transportsApi 					from "actions/transportsApi.js"
import {editImg, closeImg} 				from '../img.jsx'
import _ 								from "lodash"
import l_v 				   from 'store/languages.jsx'
import SeparPermission, { permissionsFoRequst } from 'modules/component/SeparPermission.jsx'

const getClientList = _.throttle(requestForGetClientList, 400);

function OwnerOfTransport(props) {
	const {owner, kindCreator, vehicleId} = props;
	const [active, setActive] = useState(true); //from previous variant form, now don't used
	// const thisUserCanChangeOwner  = kindCreator == 'ADMIN' || kindCreator == 'SUPERADMIN' ||  kindCreator == 'DEALER';
	return 	<SeparPermission 	alowed = 'ADMIN, SUPERADMIN'
								kindCapability = {['editOther']}>
				<FormUpdateOwner 	status = {active}
		       						vehicleId = {vehicleId}
		       						setStatus = {setActive}
		       						owner = {owner}/> 
			</SeparPermission>
}


function FormUpdateOwner(props) {
	const {status,  owner, vehicleId, havePremissions, setStatus} = props;
	const initialState = {newOwner: owner, clientsList: null, };
	const [state, dispatch] = useReducer(reducer, initialState);
	const {newOwner, clientsList} = state;
	const validate = newOwner ? false : true;
	function onTypeOwnerName(value) {
		dispatch({type: 'typeName', value: value});
		getClientList(value, (clientsList)=>dispatch({type: 'updateList', value: clientsList}))
	}
	function updateOwner() {
		transportsApi.updateVehicleOwner(vehicleId, newOwner, (vehicleInfo)=>{
			const {owner, name} = vehicleInfo
			window.location = "#/transport/" + owner + "/" + name + "/" + vehicleId + "/editVehicle";
			window.location.reload()
		})
	}
	return 	<div className = 'form_edit_vehicle_owner'>
				<p className = 'nameForm'>{l_v.auto_onwer}</p>
				<p className = 'form_message'>{ newOwner ? null : l_v.name_cannot_be_empty}</p>
				<HintUsers  index = 		'no_index'
							name = 			'newOwner'
							className = 	{'input belongsTo '}
							usersList = 	{clientsList}
							check = 		{validate}
							style = 		{{display: 'block'}}
							value = 		{newOwner}

							returnData = 	{(e)=>onTypeOwnerName(e.value)}
							onChange = 		{(e)=>onTypeOwnerName(e)}
							placeholder = {l_v.enter_owner}/>
				<button name = 'updateOwner'
						className = 'primari_btn'
						disabled = {newOwner?false:true}
						onClick = {updateOwner}>
						{l_v.update_auto_owner}
				</button>
			</div>
}

export default connect(
	    state => ({
	    	login: 				state.authorizationR.login,
			token: 				state.authorizationR.token,
			kindCreator: 		state.authorizationR.kindUser,
			user_permission: 	state.authorizationR.user_permission,
	    })
)(OwnerOfTransport)

function reducer(state, action) {
  switch (action.type) {
    case 'typeName':
    		return{
			...state,
			newOwner: action.value

    		};
    case 'updateList':
      return {
	      	...state,
      		clientsList: action.value
    };
    default:
      throw new Error();
  }
}
function requestForGetClientList(newOwner, callback) {
	Users.getData("CLIENT", getParams(newOwner), serverAnswer => callback(serverAnswer.content));
	function getParams(searchParam) {
		return {
					page: 0,
					size: 20,
					searchParam: searchParam
				}
	}
}



