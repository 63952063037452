import React, { Component, useState } from 'react';
import {toLowerCase, arrayIsEmpty, removeElementFromList} 				from 'actions/ActionCreator.jsx'
import ClickOutside  		from 'react-click-outside'
export default class HintData extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data,
			value: !!this.props.value?this.props.value:'',
			valuefromhint: '',
			data_show: [],
			visible: 'none',
			check: false,
			helpName: ''
		}
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			data: 	nextProps.data,
			check: 	nextProps.check,
			value: 	nextProps.value?nextProps.value:'',
		})
	}
	enterToState = (event) =>{
		let value  = event.target.value;
		this.setState({
			value: event.target.value,
			helpName: '',
		})
		let searchQuery = event.target.value;
		let displayedData = this.state.data.filter(function(el){
		let id = el.id || el.virtualSensorId || el.login;
		let name = el.name;
	    let searchValue = id.toString() + name;
	     	return searchValue.indexOf(searchQuery) !== -1;
	    });
	    this.setState({
			data_show: displayedData,
		})

		if (value.length > 0){
			if (displayedData.length == 0){
				this.setState({
					visible: 'none',
				})
			}
			if (displayedData.length >= 1){
					this.setState({
						visible: 'block',
					})
			}
		}
		if (value.length == 0){
				this.setState({
					visible: false,
				})
		}

		this.returndata(event.target.value)
	}
	showhint =() =>{
		this.setState({
			visible: true,
			data_show: this.state.data
		})
	}
	hidehint = () => {
		this.setState({
			visible: false,
		})
	}
	selectElement = (e) =>{
		let value = e.target.attributes.getNamedItem('data-value').value;
		let helpName = e.target.attributes.getNamedItem('data-name').value;
		this.setState({
			value: value,
			helpName: helpName
		})
		this.returndata(value)
		this.setState({
						visible: false,
					})
	}
	returndata = (valueForRequest) =>{
		this.props.returnData({
			value: 	valueForRequest,
			index: 	this.props.index,
			name: 	this.props.name,
		})
	}
	render(){
		const {visible, value, helpName} = this.state;
		const valueForInput = value ? value +' '+ helpName : '';
		return(
			<ClickOutside className='hint_container' onClickOutside = {this.hidehint}>
				<input 	type =  		"text"
						ref = 			{input => this.input = input}
						name = 			{this.props.name}//Id датчика
						data-index = 	{this.props.index}
						className = 	{'input ' + this.props.className}
						style = 			{style(this.state.check, this.state.value)}
						value =			{valueForInput}
						placeholder = 	{this.props.placeholder}
						autoComplete =	"off"
						onChange = 		{this.enterToState}
						onFocus = 		{this.showhint}/>
				<Hints 	listForHint = {this.state.data_show}
							visible = {this.state.visible}
							selectElement = {this.selectElement} />
			</ClickOutside>
		)
	}
}


export function HintUsers(props) {
	const {name, index, className, usersList} = props;
	const [visible, setVisible] = useState(false);
	function onSelectValue(e) {
		const value = e.target.attributes.getNamedItem('data-value').value;
		setVisible(false)
		props.onChange(value)
	}
	return 	<ClickOutside 	className = 		'hint_container'
							onClickOutside =	{()=>setVisible(false)}>
				<input 	type =  		"text"
						id = 			'input_belongs_to'
						name = 			{name}
						data-index = 	{index}
						className = 	{'input ' + className}
						style = 		{style(props.check, props.value)}
						value =			{props.value}
						placeholder = 	{props.placeholder}
						autoComplete =	"off"
						onChange = 		{(e)=>props.onChange(e.target.value)}
						onFocus = 		{()=>setVisible(true)}/>
				<Hints 	listForHint = {usersList}
						visible = {visible}
						selectElement = {onSelectValue} />
			</ClickOutside>
}

function Hints(props) {
	const {listForHint, visible, selectElement} = props;
	return 	arrayIsEmpty(listForHint) && visible ?
				<div className="hint_data" style = {{display: 'block'}}>
					{
						listForHint.map(key => 	<p data-value = 	{key.id || key.sensorId || key.login || key.virtualSensorId}
						                				data-name = 	{key.title ||key.name || key.sensorType || key.login }
															key =				{key.id ||	key.sensorId || key.login || key.virtualSensorId}
															onClick =		{selectElement}>
															{key.id || key.virtualSensorId} {key.title} {key.name} - {key.sensorType || key.login}
														</p> )
					}
				</div>
			:
			null
}

function style(check, value){
	if(!!check && value === ''){
		return {boxShadow: "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset"}
	}else{
		return null
	}
}

