import React, { Component } from 'react';
import Pop_up from "modules/component/Pop_up.jsx"
import { connect }                                                              from 'react-redux'
import store                from 'store/configureStore.jsx'

export class ConfirmNotification extends Component {
	constructor(props){
		super(props);
		this.state = {
			message: props.message,
		}
		this.initialState = this.state;
	}
	static getDerivedStateFromProps(newProps, prevState){
		return {
			message: newProps.message,
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if(this.state.message) this.popup.open()
	}
	render() {
		const {message} = this.state;
		return (
			<Pop_up ref = 		{ el => this.popup = el }
					onClose = 	{() => setConfirmMessage(null)}
					head_text = { '' }
					head_back = { { backgroundColor: "#708090", color: 'white', fill: 'white' } }
					popUp_size = { { maxWidth: '99%', width: '600px', height: '280px' } } >
					<div className = 'confirm_message_container' style={{height: `${280-49-10}px`}}><p>{message}</p></div>
			</Pop_up>
		);
	}
}

export default connect(
	    state => ({
	    	message: 	state.authorizationR.confirm_message,
	    })
)(ConfirmNotification)

export function setConfirmMessage(messageText) {
	store.dispatch({
		type: 'SET_CONFIRM_MESSAGE',
		confirm_message: messageText,
	})
}