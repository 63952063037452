import React, {Fragment}                                                    from "react"
import { connect }                                                          from 'react-redux'
import moment                                                               from 'moment';
import TimeInput                                                            from "dd-date-time-input"
import SL                                                                   from "sl-library"
import _                                                                    from 'lodash'
import Users                                                                from 'actions/users.js'
import devicesApi                                                           from 'actions/devicesApi.js'
import l_v                                                                  from "store/languages.jsx"
import PhoneInput, {
	isValidPhoneNumber,
}                                                                           from 'react-phone-number-input'
import SeparPermission, { permissionsFoRequst }                             from './SeparPermission.jsx'
import TooltipWrapper                                                       from "./TooltipWrapper.jsx"
import FullCalendar                                                         from '../InfiniteCalendar/FullCalendar.jsx';
import Calendar                                                             from '../InfiniteCalendar/Calendar.jsx';
import { closeImg }                                                         from '../img.jsx'
import { HintUsers }                                                        from '../transportPage/HintData.jsx'
import SimpleSelectList                                                     from './SimpleSelectList.jsx'
import { validateElements, replaceAllIsNotNumber, copyOfObject, checkPass } from 'actions/ActionCreator.jsx'
import CreatePortal                                                         from './CreatePortal.jsx'
import Preloader                                				    from 'modules/preloader.jsx'

import { removeSpaces }                                                     from 'actions/ActionCreator.jsx'
import EnterListennerForForm                                                from "modules/component/EnterListennerForForm.jsx"
import 'react-phone-number-input/style.css'

const listOfDeviceTypes = [
	{ name: 'GLOBUS',    value: 'GLOBUS',    id:        "GLOBUS"    },
	{ name: 'TELTONIKA', value: "TELTONIKA", id:        "TELTONIKA" },
	{ name: 'TESWELL',   value: "TESWELL",   id:        "TESWELL"   },
	{ name: 'NAVITRACK', value: "NAVITRACK", id:        "NAVITRACK" },
	{ name: 'BITREK',    value: "BITREK",    id:        "BITREK"    },
	{ name: 'TAG PRO',   value: "TAG_PRO",   id:        "TAG_PRO"   },
	{ name: 'WIALON_IPS_1_1',   value: "WIALON_IPS_1_1",   id:        "WIALON_IPS_1_1"   },
]
const bitrekProtocolsList =     [
      { name: 'BITREK_DEFAULT',   value: 'BITREK_DEFAULT',   id: "BITREK_DEFAULT"   },
      { name: 'BITREK_520',       value: "BITREK_520",       id: "BITREK_520"       },
      { name: 'BITREK_520_6',     value: "BITREK_520_6",     id: "BITREK_520_6"     },
      { name: 'BITREK_530',       value: "BITREK_530",       id: "BITREK_530"       },
      { name: 'BITREK_530_6',     value: "BITREK_530_6",     id: "BITREK_530_6"     },
      { name: 'BITREK_810',       value: "BITREK_810",       id: "BITREK_810"       },
      { name: 'BITREK_810_6',     value: "BITREK_810_6",     id: "BITREK_810_6"     },
      { name: 'BITREK_820_V2',    value: "BITREK_820_V2",    id: "BITREK_820_V2"    },
      { name: 'BITREK_820_V2_5',  value: "BITREK_820_V2_5", id: "BITREK_820_V2_5" },
      { name: 'BITREK_868',       value: "BITREK_868",       id: "BITREK_868"       },
      { name: 'BITREK_910',       value: "BITREK_910",       id: "BITREK_910"       },
      { name: 'Wialon IPS 1.1',   value: "WIALON_IPS_1_1",       id: "WIALON_IPS_1_1"       },
]
const teltonikaProtocolsList = [
	{ name: 'TELTONIKA_DEFAULT', value: 'TELTONIKA_DEFAULT', id: "TELTONIKA_DEFAULT" },
]
class BtnAddDevice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			bonusinput:         '',
			belongsTo:          !!this.props.defaultgroup ? this.props.defaultgroup : '',
			permissionForGroup: this.props.permissionForGroup,
			number:             '',
			description:        '',
			lastKnownTime:      null,
			number_error:       '',
			imei_error:         '',
			error_emty_field:   '',
			imei:               '',
			comment:            '',
			navitrackDeviceId:  '',
			deviceType:         { name: 'GLOBUS', value: 'GLOBUS', id: "GLOBUS" },
			simNumber:          '',
			trackActivation:    false,
			isVisible:          false,
			devicePassword:     '1111',
		}
		this.initialState = this.state;
		this.filterClientList = _.debounce(this.getClientList, 400);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			permissionForGroup: nextProps.permissionForGroup,
		})
	}
	componentDidMount() {
		this.mounted = true;
		const {kindCreator, DealerData} = this.props;
		permissionsFoRequst('SUPERADMIN, ADMIN, DEALER', null,  this.getClientList)
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	getClientList = (serchQuerry) =>{
		serchQuerry = serchQuerry ? serchQuerry : '';
		const setDealerListToState = (dealerData) => {
			this.setState({
				clientList: dealerData.content
			});
		}
		Users.getData(	'CLIENT',
						{
							page: 0,
							size: 20,
							searchParam: serchQuerry,
						},
						setDealerListToState);
	}
	hideForm = () =>{
		this.setState(this.initialState);
	}
	enterToState = (e) =>{
		let value = e.target.value;
		let name = e.target.name;
		if(name == 'imei' || name == 'number' || name == 'navitrackDeviceId'){
			value = replaceAllIsNotNumber(value)
		}
		if(name == 'devicePassword'){
			value = removeSpaces(value);
		}
		this.setState({
			[name]: value
		})
	}
	setActivation = (e) => {
		this.setState({
			trackActivation: e.target.checked
		});
	}
	hint_enter_to_state = (value) => {
		this.setState({belongsTo: value});
		this.filterClientList(value)
	}
	addition = (event) => {
		let style = 'box-shadow: inset 0 2px 8px 0 rgba(243, 135, 135, 0.33)';
		const input = document.getElementById('phone_number');
		const ownerIput = document.getElementById('input_belongs_to');
		const { belongsTo, number, deviceType, navitrackDeviceId, trackActivation, devicePassword, imei } = this.state;
		const { itsEditingDevice } = this.props;
		//|| !isValidPhoneNumber(simNumber)
		if (deviceType.value == 'NAVITRACK') {
			if (!navitrackDeviceId || !imei) {
				this.setState({
					message: `${l_v.fill_marked_fields}`,
					error_emty_field: 'formError'
				})
				return
			}
		}
		if (deviceType.value == 'WIALON_IPS_1_1') {
			if (!imei) {
				this.setState({
					message: `${l_v.fill_marked_fields}`,
					error_emty_field: 'formError'
				})
				return
			}
		}
		if (deviceType.value == 'TAG_PRO') {
			if (!devicePassword && !itsEditingDevice) {
				this.setState({
					message: `${l_v.fill_marked_fields}`,
					error_emty_field: 'formError'
				})
				return
			}
			if (devicePassword && !checkPass(devicePassword)) {
				this.setState({
					message: l_v.newPassMustBe,
					error_emty_field: 'formError'
				})
				return
			}
		}
		if (!belongsTo || !number) {
			if (!this.state.number) {
				this.setState({
					message: `${l_v.fill_marked_fields}`,
					error_emty_field: 'formError'
				})
			} else {
				this.setState({
					message: 'Такого клиента не существует',
				})
				ownerIput ? ownerIput.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset" : null;
			}
			return
		} else {
			this.setState({
				message: '',
				number_error: '',
				check: false
			})

			style = null;
			ownerIput ? ownerIput.style.boxShadow = 'none' : null;
			const lastKnownTimeForSend = this.state.lastKnownTime ?
				moment(this.state.lastKnownTime).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') :
				moment().utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
			const dataForSend = {
				owner: !!this.state.belongsTo ? this.state.belongsTo : '',
				deviceType: this.state.deviceType.value,
				lastKnownTime: lastKnownTimeForSend,
				comment: this.state.comment,
				simNumber: this.state.simNumber
			};
			if (IMEIPresentInForm(deviceType.value)) {
				dataForSend.imei = imei
			}
			if (deviceType.value == 'NAVITRACK') {
				dataForSend.navitrackDeviceId = this.state.navitrackDeviceId;
			}
			if (deviceType.value == 'TELTONIKA' || deviceType.value == 'BITREK') {
				dataForSend.teltonikaProtocolDeviceVersion = this.state.teltonikaProtocolDeviceVersion.id;
			}

			if (deviceType.value == 'TAG_PRO' || deviceType.value == 'WIALON_IPS_1_1') {
				dataForSend.devicePassword = devicePassword
			}
			this.props.dataFromform({
				number: this.state.number,
				kindDevice: 'Devices',
				dataForSend: dataForSend,
			}, trackActivation)
			this.hideForm();
		}
		input.setAttribute('style', style);
	}
	setPhone = (value) =>{
		this.setState({
			simNumber: value
		});
	}
	setDeviceType = (e) => {
		const {id} = e;
		const newState = {
			deviceType: e,
		}
		if(id == 'TAG_PRO' || id == 'TELTONIKA' || id == 'BITREK' || id == 'NAVITRACK'){
			this.getDeviceId(id)
		}
		if(id == 'BITREK'){
			newState.teltonikaProtocolDeviceVersion = {name: 'BITREK_DEFAULT', value: 'BITREK_DEFAULT', id: "BITREK_DEFAULT" };
		}
		if(id == 'TELTONIKA'){
			newState.teltonikaProtocolDeviceVersion = {name: 'TELTONIKA_DEFAULT', value: 'TELTONIKA_DEFAULT', id: "TELTONIKA_DEFAULT" };
		}
		this.setState({ ...newState })
	}
	getDeviceId = (deviceType) => {
		/*e.preventDefault();
		const {deviceType} = this.state;*/
		this.preloader.show();
		devicesApi.getDeviceId(deviceType, (deviceId)=>this.setState({number: deviceId}, this.hidePreloader))
	}
	hidePreloader = () => {
		if(this.mounted) this.preloader.hide();
	}
	setActive = () => {
		if(this.props.getEditedDevice){
			const dtoEditedDevice = copyOfObject(this.props.getEditedDevice());
			const {deviceType, teltonikaProtocolDeviceVersion, serialNumber} = this.props.getEditedDevice();
			const selectedDeviceType = listOfDeviceTypes.find(el => el.value == deviceType);
			dtoEditedDevice.deviceType = selectedDeviceType;
			dtoEditedDevice.number
			if(deviceType == 'BITREK'){
				const bitrekItem = bitrekProtocolsList.find(el => el.value == teltonikaProtocolDeviceVersion);
				dtoEditedDevice.teltonikaProtocolDeviceVersion = bitrekItem ? bitrekItem : bitrekProtocolsList[0]
			}
			if(deviceType == 'TELTONIKA'){
				const telonikaItem = teltonikaProtocolsList.find(el => el.value == teltonikaProtocolDeviceVersion);
				dtoEditedDevice.teltonikaProtocolDeviceVersion = telonikaItem ? telonikaItem : teltonikaProtocolsList[0]
			}
			console.log(`%c setActive`, "color: blue; font-weight: 400; text-transform:uppercase", deviceType)
			if(deviceType == 'NAVITRACK'){
				
				devicesApi.getNavitrackUnid(
					serialNumber,
					navitrackDTO => {
						const {navitrackDeviceId} = navitrackDTO;
						console.log(`%c navitrackDeviceId`, "color: blue; font-weight: 400; text-transform:uppercase", navitrackDeviceId)
						this.setState({
							navitrackDeviceId
						}, this.hidePreloader)
					},
					this.hidePreloader
				)
			}
			this.setState({
				isVisible: true,
				edit: true,
				number: dtoEditedDevice.serialNumber,
				belongsTo: dtoEditedDevice.owner,
				...dtoEditedDevice
			});
		}else{
			this.setState({isVisible: true})
		}
	}
	render() {
		const {clientList, permissionForGroup, deviceType, trackActivation, lastKnownTime,
				teltonikaProtocolDeviceVersion, isVisible, edit} = this.state;
		const canSetupOwner = this.state.permissionForGroup == 'false' ? false : true;
		const deviceType_value = SL.ifNoKey(deviceType, 'value');
		// const showBtnGetNumber = deviceType.value == 'TAG_PRO' || deviceType.value == 'TELTONIKA' || deviceType.value == 'BITREK';
		const numberIsFixed = deviceType_value == 'TAG_PRO' || deviceType_value == 'TELTONIKA' || deviceType_value == 'BITREK';
		return <div className = 'add_info '>
					<span className = 'btn_black'
							onClick = {this.setActive}>
							{this.props.btnname}
					</span>
					<Preloader ref = { el => this.preloader = el } />

					{
						isVisible ?
						<CreatePortal idOfMountPlace = 'modal-root'>
							<EnterListennerForForm onPressEnter = {this.addition}>
								<form className = {'add_info_form device_form show_form'}>
									{closeImg('close', this.hideForm)}
									<div className='form_message'> <p>{this.state.message}</p></div>
									<SimpleSelectList list = {listOfDeviceTypes}
															listHeight = {216}
															class_name  = 'select_list z_index_4'
															selected_item = {deviceType}
															default_value = {deviceType}
															returnValue   = {this.setDeviceType } />
									{/*{
										showBtnGetNumber ?
										<button className = 'secondary_btn get_tag_pro_device_id'
												onClick = {this.getDeviceId}>
												получить номер
										</button> :
										null
									}*/}
									<TeltonikaProtocolsList onSelect = {(e)=>this.setState({teltonikaProtocolDeviceVersion: e}) }
															selectedItem = {teltonikaProtocolDeviceVersion}
															deviceType = {deviceType.value} />
									<BitrekProtocolsList 	onSelect = {(e)=>this.setState({teltonikaProtocolDeviceVersion: e}) }
															selectedItem = {teltonikaProtocolDeviceVersion}
															deviceType = {deviceType.value} />
									{
										deviceType.value == 'NAVITRACK' ?
										<TooltipWrapper name = {l_v.id_navitrack_contain_numbers}
														className = 'wrap'>
											<input 	type = 			"text"
													key =			"navitrackDeviceId"
													name =			"navitrackDeviceId"
													value = 		{this.state.navitrackDeviceId}
													disabled = 		{ edit }
													className = 	{'input ' + this.state.number_error + ' ' +this.state.error_emty_field }
													placeholder = 	{`${l_v.enter_navitrack_id} *`}
													onChange = 		{this.enterToState} />
										</TooltipWrapper> :
										null
									}
									{
										canSetupOwner ?
										<HintUsers 	index = 		'no_index'
													name = 			'belongsTo'
													className = 	{'input belongsTo '}
													usersList = 	{clientList}
													check = 			{this.state.check}
													value = 			{this.state.belongsTo}
													onChange = 		{this.hint_enter_to_state}
													placeholder = 	{l_v.enter_client}/> :
										null
									}
									<TooltipWrapper name = {
																numberIsFixed ?
																l_v.tracker_number_server :
																l_v.incorrect_serial_number
															}
													className = 'wrap'>
										<input 	type = 			"text"
													key =			"number"
													name =			"number"
													disabled = 		{this.state.edit || numberIsFixed}
													value = 			{this.state.number}
													className = 	{`input ${!this.state.number ? (this.state.number_error + ' ' +this.state.error_emty_field) : ''}` }
													placeholder = 	{`${l_v.enter_number} *`}
													onChange = 		{this.enterToState} />
									</TooltipWrapper>
									<TooltipWrapper name = {l_v.sim_number}
													className = 'wrap'>
										<PhoneInput 	type = 				"tel"
														id = 				'phone_number'
														name = 				{'phone_number'}
														placeholder = 		{`${l_v.enter_sim_number} +380 96 582 49*`}
														value = 			{this.state.simNumber}
														showCountrySelect = {false}
														onChange = 			{this.setPhone}/>
									</TooltipWrapper>
									<ImeiInput 	imei = {this.state.imei}
											imei_error = {this.state.imei_error}
											error_emty_field = {this.state.error_emty_field}
											deviceType = {this.state.deviceType}
											enterToState = {this.enterToState}/>
									<PasswordInput 	devicePassword = {this.state.devicePassword}
													error_emty_field = {this.state.error_emty_field}
													password_error = 	{this.state.password_error}
													enterToState = {this.enterToState}
													deviceType = {deviceType.value}/>
									<TooltipWrapper name = {l_v.date_from_which}
													className = 'wrap'>
										<TimeInput 	placeholder = 	'25.08.1988 9:45'
												name = 		'lastKnownTime'
												value = 		{lastKnownTime}
												onChange = 		{(e)=>this.setState({lastKnownTime: e.value})} />
									</TooltipWrapper>
									<div className = 'calendar_container' key = 'calendar_container'>
									</div>
									<SeparPermission 	alowed = 			'SUPERADMIN, ADMIN'>
										<label htmlFor="trackActivation" className = 'track_activation_label'>
												<input  type = 		"checkbox"
														name = 		"trackActivation"
														className = 'checkbox_input'
														id = 		"trackActivation"
														onChange = 	{this.setActivation}
														checked = 	{trackActivation}/>
												Отслеживать активацию
											</label>
									</SeparPermission>
									<textarea 	className = 	"form_text_area "
											type = 			'text'
											name = 			'comment'
											onChange = 		{this.enterToState}
											value = 		{this.state.comment}
											placeholder = 	{l_v.comment_to_device}></textarea>
									<button type =		"button"
											key = 		'btnAdd'
											className = 'btn_black'
											onClick = 	{this.addition}>
										{l_v.add}
									</button>
								</form>
							</EnterListennerForForm>
						</CreatePortal> :
						null
					}

				</div>
	}

}
export default connect(
	    state => ({
	    	login: state.authorizationR.login,
			token: state.authorizationR.token,
			ClientsData: state.clientsR.ClientData,
	    })
)(BtnAddDevice)

export class EditIgnitionNavitrackForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			is_visible:           false,
			navitrackDeviceId:    '',
			ignitionSensorNumber: '',
			minimalSensorValue:   '',
			scale:                '',
			message:              '',
		}
		this.initialState = this.state;
	}
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	showForm = () => {
		const {device_id} = this.props;
		this.preloader.show();
		devicesApi.getNavitrackUnid(device_id, (navitrackDTO) => this.setState(navitrackDTO, this.hidePreloader), this.hidePreloader);
		this.setState({ is_visible: true });
	}
	hidePreloader = () => {
		if(this.mounted) this.preloader.hide();
	}
	enterToState = (e) =>{
		let value = e.target.value;
		let name = e.target.name;
		value = removeSpaces(value)
		if(name == 'minimalSensorValue'){
			value = SL.leaveNumberWithDot(value)
		}else{
			value = replaceAllIsNotNumber(value)
		}
		this.setState({
			[name]: value
		})
	}
	save = () => {
		const {is_visible, navitrackDeviceId, ignitionSensorNumber, minimalSensorValue, scale} = this.state;
		const {device_id} = this.props;
		const validate = () => {
			let valid = true;
			const required = ['navitrackDeviceId', 'ignitionSensorNumber','minimalSensorValue','scale'];
			required.forEach((el) => {
				if(this.state[el] == ''){
					valid = false
				}
			})
			return valid
		}

		if(validate()){
			devicesApi.saveNavitrackIgnition({
				requestInfo: {
					serialNumber:         Number(device_id),
					ignitionSensorNumber: Number(ignitionSensorNumber),
					minimalSensorValue:   Number(minimalSensorValue),
					scale: 					 Number(scale),
				},
				onSuccess: () => { this.setState(this.initialState) },
				onError: (error) => {}
			});
		}else{
			this.setState({
				message: `${l_v.fill_marked_fields}`
			});
		}
	}
	cancel = () => {
		this.setState( this.initialState );
	}
	clear = () => {
		const {device_id} = this.props;
		devicesApi.deleteNavitrackIgnition(device_id, () => this.setState(this.initialState) )
	}
	render(){
		const {is_visible, navitrackDeviceId, ignitionSensorNumber, minimalSensorValue, scale} = this.state;
		return   <Fragment>
						<button className = 'btn_black addsensor' onClick = { this.showForm  } name = 'ignition_settings_btn'>
							Настройки зажигания
						</button> 
						<Preloader ref = { el => this.preloader = el }/>
						{
							is_visible ?
							<CreatePortal idOfMountPlace = 'modal-root'>
								<EnterListennerForForm onPressEnter = {this.addition}>
									<form className = {'add_info_form ignition_settings_form show_form'}>
										<p className = 'nameForm'>Настройки зажигания</p>
										<div className='form_message'> <p>{this.state.message}</p></div>
										{ closeImg('close', this.cancel ) }
										{/*<TooltipWrapper 	name = {l_v.id_navitrack_contain_numbers}
																className = 'wrap'>
											<input 	type = 		"text"
													key =			"navitrackDeviceId"
													name =			"navitrackDeviceId"
													value = 			{this.state.navitrackDeviceId}
													disabled = 		{ true }
													className = 	{'input ' + this.state.number_error + ' ' +this.state.error_emty_field }
													placeholder = 	{`${l_v.enter_navitrack_id} *`}
													onChange = 		{this.enterToState} />
										</TooltipWrapper>*/}
										<TooltipWrapper 	name = {l_v.ignition_sensor}
																className = 'wrap'>
												<input 	type = 			"text"
															key =				"ignitionSensorNumber"//датчик для определения зажигания
															name =			"ignitionSensorNumber"
															value = 			{ignitionSensorNumber}
															className = 	{'input ' + this.state.number_error + ' ' + this.state.error_emty_field }
															placeholder = 	{`${l_v.ignition_sensor} *`}
															onChange = 		{this.enterToState} />
										</TooltipWrapper>
										<TooltipWrapper name = {l_v.minimal_sensor_value_for_ignition}
															className = 'wrap'>
												<input 	type = 			"text"
													key =				"minimalSensorValue"//
													name =			"minimalSensorValue"
													value = 			{minimalSensorValue}
													className = 	{'input ' + this.state.number_error + ' ' +this.state.error_emty_field }
													placeholder = 	{`${l_v.minimal_sensor_value_for_ignition} *`}
													onChange = 		{this.enterToState} />
										</TooltipWrapper>
										<TooltipWrapper 	name = 		{l_v.coefficient}//коефициент
																className = 'wrap'>
											<input 	type = 			"text"
														key =				"scale"
														name =			"scale"
														value = 			{scale}
														className = 	{'input ' + this.state.number_error + ' ' + this.state.error_emty_field }
														placeholder = 	{`${l_v.coefficient} *`}
														onChange = 		{this.enterToState} />
										</TooltipWrapper>
										<div className = 'footer_form'>
											<TooltipWrapper 	name = 		{l_v.If_there_are_no_values_ignition_is_determined_by_speed}
																	className = 'wrap'>
												<button 	type =		"button"
															key = 		'btnSave'
															className = 'btn_black'
															onClick = 	{this.clear}>
													{l_v.clear}
												</button>
											</TooltipWrapper>
											<button 	type =		"button"
														key = 		'btnSave'
														className = 'btn_black'
														onClick = 	{this.save}>
												{l_v.save}
											</button>
										</div>
									</form>
								</EnterListennerForForm>
							</CreatePortal> :
							null
						}
					</Fragment>
	}
}


function IMEIPresentInForm(deviceType_value) {
	return 	deviceType_value == 'TELTONIKA' || deviceType_value == 'GLOBUS' || 
				deviceType_value == 'BITREK' || deviceType_value == 'NAVITRACK' || deviceType_value == 'WIALON_IPS_1_1'
}

function BitrekProtocolsList(props) {
	return 	props.deviceType == 'BITREK' ?
			<SimpleSelectList 	list = {bitrekProtocolsList}
								listHeight = {216}
								class_name  = 'select_list'
								selected_item = {props.selectedItem}
								default_value = {props.selectedItem}
								returnValue   = {props.onSelect} /> :
			null
}

function TeltonikaProtocolsList(props) {
	return 	props.deviceType == 'TELTONIKA' ?
			<SimpleSelectList 	list = {teltonikaProtocolsList}
								listHeight = {216}
								class_name  = 'select_list'
								selected_item = {props.selectedItem}
								default_value = {props.selectedItem}
								returnValue   = {props.onSelect} /> :
			null
}

function PasswordInput(props) {
	const {deviceType, devicePassword, error_emty_field, password_error, enterToState } = props;
	return 	props.deviceType == 'TAG_PRO' || props.deviceType == 'WIALON_IPS_1_1' ?
				<TooltipWrapper name = {l_v.device_password}
								className = 'wrap'>
						<input 	type = 			"text"
								key =			"devicePassword"
								name =			"devicePassword"
								value = 		{devicePassword}
								className = 	{'input ' + password_error + ' ' + error_emty_field }
								placeholder = 	{`${l_v.enter_deice_pass} *`}
								onChange = 		{enterToState} />
				</TooltipWrapper> :
				null
}

function ImeiInput(props) {
	const {deviceType, imei, imei_error, error_emty_field, enterToState} = props;
	const isRequired = true;//deviceType.value !== 'NAVITRACK';
	return 	IMEIPresentInForm(deviceType.value)?
				<TooltipWrapper name =  {l_v.imei_only_numbers}
								className = 'wrap'>
					<input 	type = 		"text"
							key =			"imei"
							name =		"imei"
							value = 		{imei}
							className = 	{'input ' + imei_error + ' ' +error_emty_field }
							placeholder = 	{`${l_v.enter_imei} ${isRequired ? '*' : ''}`}
							onChange = 		{enterToState} />
				</TooltipWrapper> :
				null
}
