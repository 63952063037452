import React, { Component }                                 from "react"
import { Provider, connect }                                from 'react-redux'
import { bindActionCreators, applyMiddleware }              from 'redux'
import Navigation                                           from './AdminNavigation.jsx'
import l_v                                     				from "store/languages.jsx"
import Search, {SearchVsButton}                             from './component/Search.jsx'
import BtnAdd                                               from './component/BtnAdd.jsx'
import ClientsList                                          from './component/ClientsList.jsx'
import ActionCreator                                        from '../actions/ActionCreator.jsx'
import Users                                                from '../actions/users.js'
import SeparPermission                                      from './component/SeparPermission.jsx'
import ConfirmRemove                                        from './ConfirmRemove.jsx'
import _                                                    from 'lodash'
import { toLowerCase, arrayIsEmpty, removeElementFromList, ifNoKey } from 'actions/ActionCreator.jsx'
import { getPage }                                          from "./Devices.jsx"
import TableFooter, { TableFooterMethods }                  from "modules/devicePage/TableFooter.jsx"
import Helmet                                               from 'react-helmet';
import * as QRCode 											from 'easyqrcodejs';
import Pop_up                                  				from './component/Pop_up.jsx'
import {QrCodeContext}                         				from './component/OneClient.jsx'
import Preloader 									  		from 'modules/preloader.jsx'


class Clients extends TableFooterMethods(Component) {
	constructor(props) {
		super(props);
		this.state = {
			clientsList:           null,
			user_login:            '',
			user_permission:       '',
			userbelongsTo:         '',
			permission_edit_group: '',
			login:                 false,
			name:                  false,
			belongsTo:             false,
			...this.paginationState
		}
	    this.sortParams = ['name', 'belongsTo', 'login' ];
		// this.handleFilterChange = _.debounce(this.handleFilterChange, 800);
		this.searchQuery = '';
		QrCodeContext.showQR = this.showQR;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	componentDidMount() {
		this.mounted = true;
		const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
		this.preloader.show()
		Users.getData("CLIENT", {
			page:                  0,
			size:                  count_lines_for_view,

		}, contentInfo => {
			if(this.mounted) this.preloader.hide();
			if(this.mounted) this.setState({
									clientsList:           contentInfo.content,
									totalElements:         contentInfo.totalElements
								})	
					
		})
	}
	
	componentWillReceiveProps(nextProps, prevProps) {
		this.setState({
			permission_edit_group: getPermission_edit_group(ifNoKey(nextProps.user_permission, 'capabilities')),
			userbelongsTo:         ifNoKey(nextProps.user_permission, 'belongsTo'),
		})
	}
	showQR = (login) => {
	    const onGetSID = (sid) => {
	        this.qrPopup.open();
	        let text = login + ',' + sid;
	        const qrcode = new QRCode(document.getElementById("qrcode"),  {
	        	text: text,
	        	width: 256,
			    height: 256,
	        	colorDark : "#000000",
				colorLight : "#ffffff",
				correctLevel : QRCode.CorrectLevel.H,
	        });
	        // qrcode.makeCode(text)
	    }
	    this.setState({loginForQrCode: login});
	    Users.getSidForAuthInClient(login, onGetSID)
	}
	doRequest = () => {
		const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
		this.tooglePreloader(true);
		Users.getData(
		    "CLIENT",
		    this.createParams({
    			page: getPage(totalElements, end_index, count_lines_for_view),
    			size: count_lines_for_view,
    			searchParam: this.searchQuery,
    		}),
		    contentInfo => this.setState({
				clientsList:   contentInfo.content,
				totalElements: contentInfo.totalElements
			}, () => this.tooglePreloader(false))
		)
	}
	tooglePreloader = (status) => {
		if(this.mounted) status ? this.preloader.show() : this.preloader.hide()
	}
	saveValueForSort = (value) =>{
		this.searchQuery = value;
	}
	getClientList = (e) =>{
		if(this.searchQuery !== undefined){
			this.tooglePreloader(true);
			const {count_lines_for_view} = this.state;
			Users.getData(
			    "CLIENT",
			    this.createParams({
	    			page: 0,
	    			size: count_lines_for_view,
	    			searchParam: this.searchQuery,
	    		}),
	    		contentInfo => this.setState({
					start_index:   0,
					end_index:     count_lines_for_view,
					clientsList:   contentInfo.content,
					totalElements: contentInfo.totalElements

				}, () => this.tooglePreloader(false))
			)
		}
	}
	addClient = (dataForRequst) =>{
		const addUserToList = (newUser) => {
			const {clientsList} = this.state;
			clientsList.unshift(newUser)
			this.setState({
				clientsList: clientsList
			});
		}
		Users.addUser(dataForRequst, addUserToList);
	}
	deleteuser = () =>{
		const removeUserFromList = (userLogin) =>{
			const {clientsList} = this.state;
			this.setState({
				clientsList: removeElementFromList(clientsList, 'login', userLogin)
			});
		}
		Users.deleteUser(this.state.forDelete, removeUserFromList);

		this.refs.delete.popUp_activation({
          	active: false
        })
        this.setState({
			forDelete: null,
		});
	}
	callPopUp = (e) =>{
		this.setState({
			forDelete: e,
		});
		this.ConfirmTextforRemove = l_v.are_you_sure  + e.userlogin +'?';
		this.refs.delete.popUp_activation({
          	active: true
        })
	}
	setSortParam = (sortParameterName) => {
		this.setState({
			[sortParameterName]: !this.state[sortParameterName],
			selectedParam: sortParameterName,
			...this.createListDisabledSort(sortParameterName, !this.state[sortParameterName])
		}, this.doRequest);
	}
	render(){
		const {
			start_index,
			end_index,
			count_lines_for_view,
			totalElements,
			userbelongsTo,
			loginForQrCode,
			login,
			name,
			belongsTo,
			selectedParam,
		} = this.state;
		return(
			<div>
		       <Helmet title = {l_v.clients} />
				<Navigation />
				<div className='container content'>
					<h1 className="page_name">{l_v.clients_list}</h1>
					<SearchVsButton onChange = 	{this.saveValueForSort}
									placeholder = 	{l_v.name_dealer_or_desc}
									name = 			{'login'}
									sendRequest = {this.getClientList}/>
					<SeparPermission 	alowed =         'SUPERADMIN, ADMIN, DEALER'
										type =           'component'
										kindCapability = 'editClients'>
						<BtnAdd permissionForGroup =  {this.state.permission_edit_group}
								defaultgroup =        {userbelongsTo}
								pasword_placeholder = {l_v.enter_password}
								name_placeholder =    {l_v.enter_login}
								btnname =             {l_v.add_client}
								dataFromform =        {this.addClient}
								kindUser =            {'CLIENT'}>
						</BtnAdd>
					</SeparPermission>
					<ClientsList  	data = {this.state.clientsList}
									setSortParam = {this.setSortParam}
									selectedParam = {selectedParam}
									login = {login}
									name = {name}
									belongsTo = {belongsTo}
									deleteuser = {this.callPopUp}/>
					<TableFooter 	changePage =               {this.changePage}
									show_pagination =          {totalElements?true:false}
									count_of_available_lines = {totalElements}
									count_lines_for_view =     {count_lines_for_view}
									setCountsLineForView =     {this.updateCountForView}
									start_index =              {start_index}
									end_index =                {end_index} />
					<ConfirmRemove 	ref = 			'delete'
									confirmText = 	{this.ConfirmTextforRemove }
									clear = 		{()=>this.setState({ forDelete: undefined })}
									remove = 		{this.deleteuser}/>
					<Preloader ref = { el => this.preloader = el }/>
					<Pop_up head_text =     {'QR code for auth - ' + loginForQrCode}
					        head_back =     {{backgroundColor:"#00bcd4", color: 'white', fill: 'white'}}
					        ref = {(popup) => this.qrPopup = popup}
					        popUp_size =    {{maxWidth: '99%', width: '370px', height: '386px'}}>
					        <div className = 'qr_code_container'>
					        	<div id="qrcode" style={{width:'280px',height:'280px' }}></div>
					        </div>
					</Pop_up>
				</div>
			</div>
			)
	}
}

export default connect(
	    state => ({
			login:           state.authorizationR.login,
			token:           state.authorizationR.token,
			user_permission: state.authorizationR.user_permission,
	    }),
		dispatch => bindActionCreators(ActionCreator, dispatch)
)(Clients)

export function filterClients(clientsList, filterValue) {
	let searchQuery = toLowerCase(filterValue);
	let displayedUsers;
	if(arrayIsEmpty(clientsList)) {
		displayedUsers = clientsList.filter(function(el) {
			let login = toLowerCase(el.login),
				owner = toLowerCase(el.belongsTo),
				description = toLowerCase(el.description);
			let searchValue = login + owner + description;
			return searchValue.indexOf(searchQuery) !== -1;
		});
	}
	return displayedUsers
}

function getPermission_edit_group(mas) {
	let getPermission_edit_group = false;
	for(var i in mas){
		if (i == 'editOther'){
			let value = mas[i];
			getPermission_edit_group = value
		}
	}
	return getPermission_edit_group
}