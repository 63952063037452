import React, { useState, Fragment, Component }  from 'react'
import TimeInput    from 'dd-date-time-input'
import moment       from "moment"
import ConfirmRemove from "modules/ConfirmRemove.jsx"
import l_v         from 'store/languages.jsx'

export class IgnitionCorrectionForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            to:     null,
            from:   null,
            ignitionOn: false
        }
        this.initialState = this.state;
    }
    sendRequest = () => {
        const {to, from, ignitionOn} = this.state;
        this.props.correctIgnition({
            to: moment(to).format(),//.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            from: moment(from).format(),//.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            ignitionOn: ignitionOn,
        })
    }
    render() {
        const {ignitionOn, to, from} = this.state;
        const period = `<p>${moment(from).format('DD.MM.YYYY HH:mm:ss')} - ${moment(to).format('DD.MM.YYYY HH:mm:ss')}</p>`;
        const confirmText = `<div>${l_v.are_you_sure_adjustment}: ${period}</div>` ;
        return (

            <div className = 'ignition_correction_form'>
                <ConfirmRemove  ref =           {el => this.confirm = el}
                                confirmText =   {confirmText}
                                confirmTextYes = {l_v.yes}
                                confirm_header_text = {l_v.ignition_adjustment}
                                height =        '222px'
                                remove =        {this.sendRequest}/>
                <p className = 'nameForm'>{l_v.ignition_adjustment}</p>
                <TimeInput  disabledDate =          {false}
                            id = 'ignition_correction_form-from'
                            onChange =              {(e) => this.setState({[e.name]: e.value})}
                            value =                 {from}
                            hiddenTime =            {false}
                            textLabel =             {l_v.period_start}
                            maxDate =               {to ? to : null}
                            withSeconds =           {true}
                            name =                  'from'/>
                <TimeInput  disabledDate =          {false}
                            onChange =              {(e) => this.setState({[e.name]: e.value})}
                            id = 'ignition_correction_form-to'
                            value =                 {to}
                            textLabel =             {l_v.end_period}
                            hiddenTime =            {false}
                            withSeconds =           {true}
                            minDate =               {from}
                            name =                  'to'/>

                <label className = 'select_label'>
                    <input  type =      'checkbox'
                            name =      'ignitionOn'
                            className = 'select_checkbox'
                            checked =   {ignitionOn}
                            onChange =  {()=>this.setState({ignitionOn: !ignitionOn}) }/>
                    {l_v.ignition}
                </label>
                <div className = 'clear'></div>
                <button className = 'primari_btn'
                        disabled = {!to || !from}
                        onClick = {()=>this.confirm.open()}>{l_v.apply}</button>

            </div>
        );
    }
}



export default IgnitionCorrectionForm;


// https://pro-api.elsyton.com/api/v2/admin/device/gps-data?deviceId=150023&ignitionOn=true&from=2021-06-01T03:00:00%2B03:00&to=2021-06-16T23:59:00%2B03:00