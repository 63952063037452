import store                             from 'store/configureStore.jsx'
import axios                             from 'axios'
import bugReport, { setText }            from './bugReport.jsx'
import infoStore                     	from './infoStore.js'
import l_v                  			from "store/languages.jsx"
import { ifNoKey, arrayIsEmpty, doCopy, toUpperCase } from './ActionCreator.jsx'
import devicesApi                         from "./devicesApi.js"

function getVehicleSettings(vehicleId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + `vehicle/${vehicleId}/settings`,
		dataType: "JSON",
		method: 'GET',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: { locale: infoStore().langUppercase },
	}
	axios(dataForRequest).then(answer => callback(answer.data))
		.catch((answer) => bugReport(answer, { vehicleId: vehicleId }, 'getVehicleSettings'))
}

function updateVehicleOwner(vehicleId, newOwner, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/' + vehicleId,
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: { owner: newOwner },
	}
	axios(dataForRequest).then(answer => callback(answer.data))
		.catch((answer) => bugReport(answer, { vehicleId: vehicleId, newOwner: newOwner }, 'updateVehicleOwner'))
}

function createGroup(groupName, userLogin, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/group',
		dataType: "JSON",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: groupName,
		params: { userName: userLogin }
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'createGroups'))
}

function getGroupsList(userLogin, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/group',
		dataType: "JSON",
		method: 'GET',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: { userName: userLogin }
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'getGroupsList'))
}

function updateGroupName(groupId, newGroupName, userLogin, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/group/' + groupId,
		dataType: "JSON",
		method: 'POST',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: newGroupName,
		params: { userName: userLogin }
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateGroupName'))
}

function deleteGroup(groupId, userLogin, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/group/' + groupId,
		dataType: "JSON",
		method: 'DELETE',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		params: { userName: userLogin }
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'deleteGroup'))
}

function addVehiclesToGroup(groupId, vehicleList, userLogin, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/group/mapping/' + groupId,
		dataType: "JSON",
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: vehicleList,
		params: { userName: userLogin }
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'addVehiclesToGroup'))
}

function removeVehiclesFromGroup(groupId, vehicleList, userLogin, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/group/mapping/' + groupId,
		dataType: "JSON",
		method: 'DELETE',
		withCredentials: true,
		headers: { 'authorization': infoStore().token },
		data: vehicleList,
		params: { userName: userLogin }
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'removeVehiclesFromGroup'))
}

function addTransport(vehicleInfo, callback) {
	const requestData = {
		baseURL:  infoStore().base_url,
		url:      infoStore().bonus_url_device + 'vehicle',
		dataType: "json",
		method:   'PUT',
		headers:  { 'authorization': infoStore().token },
		data:     vehicleInfo
	}
	axios(requestData)
		.then(answer => {
			callback(answer.data)
			store.dispatch({
				type:          'STATUS_REQUEST',
				setAnswer:     answer.status,
				settextanswer: l_v.auto_added
			})
		})
		.catch(serveranswer => bugReport(serveranswer, store))
}

function getTransportInfo(vehicleId, callback) {
	const requestData = {
		baseURL:         infoStore().base_url,
		url:             infoStore('authorizationR.bonus_url_api') + 'vehicle/' + vehicleId,
		dataType:        "json",
		method:          'GET',
		headers:         { 'authorization': infoStore().token },
		withCredentials: true,
	};
	axios(requestData)
		.then(function answer(answer) {
			const vehicleDto = answer.data;
			if(callback) callback(answer.data);
			const currentDeviceMapping = vehicleDto.currentDeviceMapping;
			devicesApi.setDeviceSettingsDTO(ifNoKey(currentDeviceMapping, 'deviceSettingsDto'))
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		})
}

function clearThisTransport(argument) {
	store.dispatch({
		type: 'GET_one_transport',
		setThisTransport: null
	})
	store.dispatch({
		type: 'GET_deviceVStransport_connect',
		setDeviceVStransport_connect: null
	})
}

function getDataFromPeriod(deviceId, date, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/device/' + deviceId + '/commands/upload-data',
			dataType: "json",
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			withCredentials: true,
			params: { from: date }
		})
		.then(function answer(answer) {
			if(!!callback) {
				callback()
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		})
}

function getLastTrackPoint(data) { //early it's was current transport state{
	axios({
			baseURL: infoStore().base_url,
			url: 'device/last-track-point',
			dataType: "json",
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			params: { id: data },
			withCredentials: true,
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'CURRENT_INFO',
				setCurrentInfo: answer.data
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		})
}

function currentTransportState(deviceSerial, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'device/last-state-data/' + deviceSerial,
			dataType: "json",
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			withCredentials: true,
		})
		.then( answer => {
			let state = answer.data;
			devicesApi.upateStateFromSocket(state) //yes, it's fucking crutch)
			// devicesApi.setDeviceSettingsDTO(ifNoKey(state, 'deviceSettingsDto'))
			if(!!callback) {
				callback(state)
			}
		})
		.catch( answer => {
			if(answer.response.status == 424) {
				setText(l_v.device_state_not_available, answer.response.status);
				if(!!callback) callback(424)
			} else {
				bugReport(answer, store)
			}
		})
}

function getAllTransportByUser(owner, callback) {
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore('authorizationR.bonus_url_api') + 'vehicles',
		dataType: "json",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		params: {login: owner}
	}
	axios(requestData)
	.then(answer => callback(answer.data, `${l_v.transports} - ${owner}`) )
	.catch(serveranswer => bugReport(serveranswer, store))
}

function download(fileInfo, filename) {
	var file = new Blob([fileInfo], { type: 'text/csv;charset=utf-8;' });
	console.log("%c file", 'color: green; font-weight: bold;', file)
	if(window.navigator.msSaveOrOpenBlob) // IE10+
		window.navigator.msSaveOrOpenBlob(file);
	else { // Others
		var a = document.createElement("a"),
			url = URL.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			try{
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
			}catch(e){
				console.error(e)
				alert('Что-то пошло не так')
			}

		}, 0);
	}
}

function getCSVFileByUser(owner, callback) {
	const requestData = {
			baseURL: infoStore().base_url,
			url: `${infoStore('authorizationR.bonus_url_api')}vehicle/state/csv`,
			dataType: "json",
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			params: {login: owner}
		}
		axios(requestData)
		.then(answer => download(answer.data, `${owner} - транспорт`))
		.catch(serveranswer => bugReport(serveranswer, store))
}

function getTransports(params, callback) {
	// page: 1,  //example
	// size: 40,
	// owner: login,
	// groupId: 4,
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore('authorizationR.bonus_url_api') + 'vehicles/page',
		dataType: "json",
		method: 'GET',
		headers: { 'authorization': infoStore().token },
		params: params
	}
	axios(requestData)
		.then(answer => callback(answer.data))
		.catch(serveranswer => bugReport(serveranswer, store))
}

function updateTransportInfo(id, requestBody, callback) {
	axios({
			baseURL:  infoStore().base_url,
			url:      `${infoStore().bonus_url_device}vehicle/${id}`,
			dataType: "JSON",
			method:   'POST',
			headers:  { 'authorization': infoStore().token },
			data:     requestBody,
		})
		.then(answer => {
			const newTransportInfo = answer.data;
			store.dispatch({
				type: 'GET_one_transport',
				setThisTransport: newTransportInfo
			})
			devicesApi.upateStateFromSocket(newTransportInfo)
			store.dispatch({
				type: 'STATUS_REQUEST',
				setAnswer: answer.status,
				settextanswer: l_v.information_was_updated
			})
			if(callback) callback(newTransportInfo);
		})
		.catch(serveranswer => bugReport(serveranswer, store));
}

function updateTransmission(data) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/' + data.id + '/transmission',
			dataType: "json",
			method: 'POST',
			headers: { 'authorization': infoStore().token },
			data: data.data,
		})
		.then(function answer(answer) {
			store.dispatch({
				type: 'GET_one_transport',
				setThisTransport: answer.data
			})
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		});
}

function updateVehicleSettings(vehicleId, data, callback) {
	axios({
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + "vehicle/" + vehicleId + "/settings",
		dataType: "json",
		method: 'PUT',
		headers: {
			"Content-Type": "application/json",
			'authorization': infoStore().token
		},
		data: data,
	}).then((answer) => {
		answer = answer.data;
		if(!!callback) {
			callback(answer.settings)
		}
		let info = infoStore('devicesR.ThisTransport');
		info = answer;
		store.dispatch({ //state.devicesR.ThisTransport
			type: 'GET_one_transport',
			setThisTransport: answer,
		})
	}).catch(function(serveranswer) {
		bugReport(serveranswer, store)
	});
}

function deleteVehicle(vehicleId, callback) {
	const requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'vehicle/' + vehicleId,
		dataType: "json",
		method: 'DELETE',
		headers: { 'authorization': infoStore().token },
	}
	axios(requestData).then(answer => {
		callback(vehicleId)
		store.dispatch({
			type: 'STATUS_REQUEST',
			setAnswer: answer.status,
			settextanswer: l_v.vehicle_deleted
		})
	})
	.catch(serveranswer => bugReport(serveranswer, store))
}

function clearInfo(argument) {
	store.dispatch({
		type: 'CURRENT_INFO',
		setCurrentInfo: undefined
	})
}

function updateSignalization(deviceSerial, status, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/device/' + deviceSerial + '/commands/set-lock-state',
			dataType: "json",
			method: 'POST',
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
			params: { lockState: status },
		})
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data)
			} else {
				console.log("%c else not defined, updateSignalization - answer", 'color: orange; font-weight: bold;', answer)
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, {
				baseURL: infoStore().base_url,
				url: infoStore().bonus_url_api + 'admin/vehicle/device/' + deviceSerial + '/commands/set-lock-state',
				dataType: "json",
				method: 'POST',
				withCredentials: true,
				headers: { 'authorization': infoStore().token },
				params: { lockState: status },
			}, "updateSignalization")
		});
}

function correctIgnitionRequest(params, callback, onError) {
	const requestData = {
				baseURL: infoStore().base_url,
				url: infoStore().bonus_url_device + 'device/gps-data',
				dataType: "json",
				method: 'POST',
				withCredentials: true,
				headers: { 'authorization': infoStore().token },
				params: params,
			}
	axios(requestData)
	.then( answer => {
		if(!!callback) callback(answer.data)
	})
	.catch(serveranswer => {
		onError();
		bugReport(serveranswer, requestData, "correctIgnitionRequest")
	});
}

function getActualSignalization(deviceSerial, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_device + 'vehicle/device/' + deviceSerial + '/commands/actual-lock-state',
			dataType: "json",
			method: 'GET',
			withCredentials: true,
			headers: { 'authorization': infoStore().token },
			// params: 	{lockState: status},
		})
		.then(function answer(answer) {
			if(!!callback) {
				callback(answer.data)
			} else {
				console.log("%c else not defined, updateSignalization - answer", 'color: orange; font-weight: bold;', answer)
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, {
				baseURL: infoStore().base_url,
				url: infoStore().bonus_url_api + 'admin/vehicle/device/' + deviceSerial + '/commands/set-lock-state',
				dataType: "json",
				method: 'POST',
				withCredentials: true,
				headers: { 'authorization': infoStore().token },
				params: { lockState: status },
			}, "updateSignalization")
		});
}

function paramsAdaptation(arr, str) {
	arr.forEach((key, index) => {
		let symbol = !index ? '?' : '&';
		str += symbol + 'vehicleId=' + key
	})
	return str
}

function permitVehicleForUser(userLogin, listVehicles, callback) {
	if(Array.isArray(listVehicles)) {
		//by def Axios do params like vehicleId[]= 1&vehiclId[] ..
		userLogin = paramsAdaptation(listVehicles, userLogin)
		listVehicles = null;
	}
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'user/vehicles/' + userLogin,
		dataType: "json",
		method: 'PUT',
		headers: { 'authorization': infoStore().token },
		withCredentials: true,
		params: { vehicleId: listVehicles }
	}
	axios(requestData)
		.then(answer => callback())
		.catch(serveranswer => bugReport(serveranswer, store))
}

function removeFromPermitedVehicleForUser(userLogin, listVehicles, callback) {
	if(Array.isArray(listVehicles)) {
		//by def Axios do params like vehicleId[]= 1&vehiclId[] ..
		userLogin = paramsAdaptation(listVehicles, userLogin)
		listVehicles = null;
	}
	let requestData = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'user/vehicles/' + userLogin,
		dataType: "json",
		method: 'DELETE',
		headers: { 'authorization': infoStore().token },
		withCredentials: true,
		params: { vehicleId: listVehicles }
	};
	axios(requestData)
		.then(answer => callback())
		.catch(serveranswer => bugReport(serveranswer, store))
}

function getMappedVehicles(userLogin, callback) {
	axios({
			baseURL: infoStore().base_url,
			url: infoStore().bonus_url_api + 'user/vehicles/mappings',
			dataType: "json",
			method: 'GET',
			headers: { 'authorization': infoStore().token },
			withCredentials: true,
			params: { mappedUser: userLogin }
		})
		.then((answer) => {
			let state = answer.data;
			if(!!callback) {
				callback(state)
			}
		})
		.catch(function(serveranswer) {
			bugReport(serveranswer, store)
		})
}

function updateOnAddGroupInVehicleList(listOfVehiclesInGroup, newGroup) {
	console.log("%c listOfVehiclesInGroup", 'color: green; font-weight: bold;', listOfVehiclesInGroup)
	let transport_list = infoStore('devicesR.TransportData');
	if(arrayIsEmpty(transport_list) && arrayIsEmpty(listOfVehiclesInGroup)) {
		listOfVehiclesInGroup.forEach(group => {
			let indexOfVehicle = findVehicleIndex(transport_list, group)
			if(isFinite(indexOfVehicle) && indexOfVehicle !== -1) {
				transport_list[indexOfVehicle].groups.push(newGroup)
			}
		})
	}
}

function updateOnRemoveGroupInVehicleList(listOfVehiclesForRemove) {
	let transport_list = infoStore('devicesR.TransportData');
	console.log("%c transport_list", 'color: green; font-weight: bold;', transport_list)
	if(arrayIsEmpty(transport_list) && arrayIsEmpty(listOfVehiclesForRemove)) {
		let groupId = listOfVehiclesForRemove[0].groupId;
		listOfVehiclesForRemove.forEach(group => {
			let indexOfVehicle = findVehicleIndex(transport_list, group);
			if(isFinite(indexOfVehicle) && indexOfVehicle !== -1) {
				let indexGroupForRemove = transport_list[indexOfVehicle].groups.findIndex(vehicleGroup => {
					if(vehicleGroup.id == groupId) {
						return vehicleGroup
					}
				})
				if(indexGroupForRemove !== -1) {
					transport_list[indexOfVehicle].groups.splice(indexGroupForRemove, 1)
				}
			}
		})
	}
}

function findVehicleIndex(transport_list, groupInfo) {
	return transport_list.findIndex(oneVehicle => {
		if(oneVehicle.id == groupInfo.vehicleId) {
			return oneVehicle
		}
	})
}



const transportsApi = {
	getCSVFileByUser: 				  getCSVFileByUser,
	getLastTrackPoint:                getLastTrackPoint,
	getTransportInfo:                 getTransportInfo,
	getDataFromPeriod:                getDataFromPeriod,
	currentTransportState:            currentTransportState,
	addTransport:                     addTransport,
	clearInfo:                        clearInfo,
	getTransports:                    getTransports,
	updateTransportInfo:              updateTransportInfo,
	updateTransmission:               updateTransmission,
	updateVehicleSettings:            updateVehicleSettings,
	deleteVehicle:                    deleteVehicle,
	getActualSignalization:           getActualSignalization,
	updateSignalization:              updateSignalization,
	clearThisTransport:               clearThisTransport,
	permitVehicleForUser:             permitVehicleForUser,
	removeFromPermitedVehicleForUser: removeFromPermitedVehicleForUser,
	getMappedVehicles:                getMappedVehicles,
	updateOnAddGroupInVehicleList:    updateOnAddGroupInVehicleList,
	updateOnRemoveGroupInVehicleList: updateOnRemoveGroupInVehicleList,
	createGroup:                      createGroup,
	getGroupsList:                    getGroupsList,
	updateGroupName:                  updateGroupName,
	deleteGroup:                      deleteGroup,
	addVehiclesToGroup:               addVehiclesToGroup,
	removeVehiclesFromGroup:          removeVehiclesFromGroup,
	updateVehicleOwner:               updateVehicleOwner,
	getAllTransportByUser: 			  getAllTransportByUser,
	correctIgnitionRequest: 		  correctIgnitionRequest,
	getVehicleSettings: 			  getVehicleSettings
};
export default transportsApi;