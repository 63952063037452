import SL        from 'sl-library'
// import deviceApi from "actions/deviceApi.js"

const initialState = {
	DevicesData:                  undefined,
	TransportData:                undefined,
	ThisTransport:                '',
	thisCalibrations:             undefined,
	DeviceVStransport_connect:    [],
	commands_history:             [],
	lastComm:                     undefined,
	currentInfo:                  undefined,
	vehicleSettings:              undefined,
	globalCalibraition:           undefined,
	concatedCalibrations:         undefined,
	answer:                       undefined,
	deviceInfo:                   undefined,
	test:                         undefined,
	lastCommand:                  undefined,
	deviceAnswer:                 {},
	lockState:                    undefined,
	stateFromSocket:              undefined,
	current_deviceSettingsDto:    null,
	newDeviceConnectedStatus:     null,
	newSW_and_HW_versions:        null,
	newAuthCode:                  null,
	serial_of_mounted_DevicePage: null,
	calibratioForCopy:            localStorage.getItem('copyInfo'),
};
export default function devicesR(state = initialState, action) {
	switch (action.type) {
		case "CURRENT_DEVICE_SERIAL":
			return {
				...state,
				serial_of_mounted_DevicePage: action.saveSerial,
			}
		case 'SET_NEW_AUTH_CODE':
			return {
				...state,
				newAuthCode: action.setNewCode
			}
		case 'SET_NEW_HW_SW_VERSIONS':
			return {
				...state,
				newSW_and_HW_versions: action.setNew
			}
		case 'SET_NEW_CONNECTED_STATUS':
			return {
				...state,
				newDeviceConnectedStatus: action.setNewStatus
			}
		case 'SET_DEVICESETTINGSDTO':
			return {
				...state,
				current_deviceSettingsDto: action.setDeviceSettingsDTO
			}
		case 'NEWSTATE':
			return {
				...state,
				stateFromSocket: action.updateNewState,
			}
		case 'LOCKSTATE':
			return {
				...state,
				lockState: action.setLockState,
			}
		case 'SETCOPY':
			return {
				...state,
				calibratioForCopy: action.setCalibratioForCopy
			};
		case 'CALIBRATION':
			return {
				...state,
				globalCalibraition: action.setGlobalCalibration,
				test: () => console.log("%c test this", 'color: orange; font-weight: bold;', this)
			};
		case 'CONCATED_CALIBRATIONS':
			return {
				...state,
				concatedCalibrations: action.setCalibrations,
			};
		case 'SETUP':
			return {
				...state,
				vehicleSettings: action.setVehicleSettings,
			};
		case 'GET_devices':
			return {
				...state,
				DevicesData: action.setDevicesData,
			};
		case 'GET_transport':
			return {
				...state,
				TransportData: action.setTransportData
			};
		case 'GET_one_transport':
			return {
				...state,
				ThisTransport: action.setThisTransport
			};
		case 'SET_CALIBRATIONS':
			return {
				...state,
				thisCalibrations: action.setForThisVehicle
			};
		case 'GET_deviceVStransport_connect':
			return {
				...state,
				DeviceVStransport_connect: action.setDeviceVStransport_connect
			};
		case "CURRENT_INFO":
			return {
				...state,
				currentInfo: action.setCurrentInfo
			};
		case "SET_DEVICE_INFO":
			return {
				...state,
				deviceInfo: action.setInfo
			};
		case 'COMMAND_HISTORY':
			return {
				...state,
				commands_history: action.setHistory
			};
		case 'LAST_COMMANDS':
			const commands_history_on_answer_last = action.setLastComm ?
				createNewListIfLastCommand( {
					commands_history:    [ ...state.commands_history ],
					currentSerialNumber: state.serial_of_mounted_DevicePage,
					lastCommand:         action.setLastComm,
				} ) : state.commands_history;
			return {
				...state,
				lastCommand: action.setLastComm,
				commands_history:  commands_history_on_answer_last
			};
		case 'ANSWER_DEVICE':
			const commands_history_on_answer_device = action.setAnswer ?
				createNewListIfDeviceAnswer( {
					commands_history:    [ ...state.commands_history ],
					currentSerialNumber: state.serial_of_mounted_DevicePage,
					deviceAnswer:        action.setAnswer,
				} ) : state.commands_history;

				// const {deviceId, name} = action.setAnswer;
			 	// if(name == "Device_Connected" && deviceId == currentSerialNumber){
			 	// 	deviceApi.getCommandsHistory(deviceId)//get new commands history may be device was on other server
			 	// }

			return {
				...state,
				deviceAnswer: action.setAnswer,
				commands_history: commands_history_on_answer_device
			};
		default:
			return state;
	}

}


function createNewListIfDeviceAnswer ( { commands_history, deviceAnswer, currentSerialNumber } ) {
		const {regId, name, messageId, answer, responseTimestamp} = deviceAnswer;
		const itsDeviceAnswerForThisDevice = deviceAnswer && currentSerialNumber == regId && name == 'deviceAnswer';
	
		const commandResponse = {
			responseText: answer,
			responseTimestamp: responseTimestamp ? responseTimestamp : null
		};
		if ( itsDeviceAnswerForThisDevice && SL.arrayIsEmpty( commands_history ) ) {
			const compareCondition = el => messageId == el.id || messageId == el.messageId; //Don't ask
			const index_command = messageId === 0 ? 'its_crutch' : commands_history.findIndex( compareCondition )

			if ( index_command == 'its_crutch' ) {
				commands_history.unshift( transformCommandDTO( SL.copyOfObject( { commandResponse: commandResponse } ) ) );
			}
			if ( index_command !== -1 && index_command !== 'its_crutch' ) {
				const el = commands_history[index_command];
				el.commandResponse = commandResponse;
				commands_history[index_command] = el;
				return commands_history
			}
		} else {
			if(!messageId && !regId && !answer){//then it is not a response to the command and return current history
				return commands_history
			}else{
				return new Array( commandResponse )
			}
		};
		return commands_history
	};

	function createNewListIfLastCommand ( { commands_history, lastCommand, currentSerialNumber } ) {
		const itsCommandForThisDevice = lastCommand && currentSerialNumber == lastCommand.regId && lastCommand.name == 'receivedCommand';

		lastCommand.commandText = lastCommand.commandString; 

		if ( !lastCommand.receivedTimestamp ) { //can be empty
			lastCommand.receivedTimestamp = new Date();
		}
		if ( itsCommandForThisDevice && SL.arrayIsEmpty( commands_history ) ) {
			const indexOfCommandElInArr = commands_history.findIndex( el => el.messageId == lastCommand.messageId )
			if ( indexOfCommandElInArr == -1 ) {
				commands_history.unshift( lastCommand )
			} else {
				commands_history[ indexOfCommandElInArr ] = transformCommandDTO( lastCommand );
			}
		} else {
			return  [ transformCommandDTO( lastCommand ) ]
		}
		return commands_history

		function transformCommandDTO ( lastCommand ) {
			return {
				...lastCommand,
				commandText: lastCommand.commandString
			}
		};
	};


function addLastCommandToHistory ( obj ) {
	/*console.group('On get obj in addLastCommandToHistory');
	
	console.log(`%c ADDLASTCOMMANDTOHISTORY `, "color: blue; font-weight: 400; text-transform:uppercase", SL.copyOfObject(obj), obj)*/

	const { commands_history, lastCommand, deviceAnswer, currentSerialNumber } = obj;

	const itsCommandForThisDevice = lastCommand && currentSerialNumber == lastCommand.regId && lastCommand.name == 'receivedCommand';
	const itsDeviceAnswerForThisDevice = deviceAnswer && currentSerialNumber == deviceAnswer.regId && deviceAnswer.name == 'deviceAnswer';

	if ( itsCommandForThisDevice ) return createNewListIfLastCommand( { commands_history, lastCommand } );
	if ( itsDeviceAnswerForThisDevice ) return createNewListIfDeviceAnswer( { commands_history, deviceAnswer } );



	

	

}

function modifyHistoryOnUpdate(newMessage) { //not used more?
	const { lastCommand, deviceAnswer, commands_history } = newMessage;
	return {
		commands_history: addLastCommandToHistory(commands_history, lastCommand, deviceAnswer, currentSerialNumber),
		lastCommand: lastCommand ? lastCommand : null,
		deviceAnswer: deviceAnswer ? deviceAnswer : null
	}
}

/*examples
{
	answer: 'то ничё?',
	responseTimestamp: '2023-07-20T14:37:54.953+00:00',
	messageId: 342,
	regId: 71359,
	name: 'deviceAnswer'
}

{"deviceId":71359,"name":"Device_Disconnect"}

{"deviceId":71359,"name":"Device_Connected"}

{"deviceId":71359,"hardwareVersion":"2","softwareVersion":"2.0.184","name":"SwAndHwMessage"}

{"deviceId":71359,"authCode":1710609238,"name":"SaveAuthCode"}

{"serialNumber":71359,"timestamp":"2023-08-30T11:40:04Z","longitude":28.54790333333333,"latitude":49.20051,"speed":0.0,"logicalInputs":"00010000","analogSensors":[{"sensorNumber":0,"value":11978,"timestamp":"2023-08-30T11:40:04Z"},{"sensorNumber":4,"value":2687031,"timestamp":"2023-08-30T11:40:04Z"},{"sensorNumber":5,"value":9,"timestamp":"2023-08-30T11:40:04Z"}],"oneWireSensors":[],"trailerActionEvent":{"idSerial":5928342},"connectedRfidCard":null,"ignitionOn":false}

{"serialNumber":71359,"timestamp":"2023-08-30T11:35:04Z","longitude":28.54790333333333,"latitude":49.20051,"speed":0.0,"logicalInputs":"00010000","analogSensors":[{"sensorNumber":0,"value":11999,"timestamp":"2023-08-30T11:35:04Z"},{"sensorNumber":4,"value":2687031,"timestamp":"2023-08-30T11:35:04Z"},{"sensorNumber":5,"value":10,"timestamp":"2023-08-30T11:35:04Z"}],"oneWireSensors":[],"trailerActionEvent":{"idSerial":5928342},"connectedRfidCard":null,"ignitionOn":false}
aa
	{
		action: "executeStringCommand",
		answer: "+MYTEL=+380672400586",
		messageId: 345,
		name: "deviceAnswer",
		regId: 71359,
		responseTimestamp: "2023-07-20T15:39:14.199Z"
	} 
	*/

// receive messages when cleared history
// {"deviceId":71359,"name":"Device_Connected"}	44	
// 09:09:46.495
// {"deviceId":71359,"hardwareVersion":"2","softwareVersion":"2.0.184","name":"SwAndHwMessage"}	92	
// 09:09:46.582
// {"deviceId":71359,"authCode":1710609238,"name":"SaveAuthCode"}	62	
// 09:09:46.627
// {"deviceId":71359,"lockStateTime":"2023-08-30T06:09:47.076Z","lockState":true,"name":"LockStateForDevice"}
// 
// { "serialNumber": 71359, "timestamp": "2023-08-11T13:52:00Z", "longitude": 28.547925000000003, "latitude": 49.20039166666667, "speed": 0.0, "logicalInputs": "00010000", "analogSensors": [{ "sensorNumber": 0, "value": 12020, "timestamp": "2023-08-11T13:52:00Z" }, { "sensorNumber": 4, "value": 2687031, "timestamp": "2023-08-11T13:52:00Z" }, { "sensorNumber": 5, "value": 6, "timestamp": "2023-08-11T13:52:00Z" }], "oneWireSensors": [], "trailerActionEvent": { "idSerial": 5928342 }, "connectedRfidCard": null, "ignitionOn": false }

