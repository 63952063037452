import infoStore from './infoStore.js'
import axios 			from 'axios'
import bugReport from './bugReport.jsx'
const UpdatesNotif = {
	createNotification: createNotification,
	updateNotifications: updateNotifications,
	deleteNotification: deleteNotification,
	getNotification: getNotification,

}
export default UpdatesNotif;
window.createNotification = createNotification;
function createNotification(notifText, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'updating_notification',
		method: 'PUT',
		withCredentials: true,
		headers: { 'authorization': infoStore().token, 'Content-Type': 'text/html; charset=utf-8'},
		data: notifText
	}
	axios(dataForRequest).then( answer => callback(answer.data) )
		.catch((answer) => bugReport(answer, dataForRequest, 'createNotification'))
}
function updateNotifications(notifText, notifId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'updating_notification/'+ notifId,
		headers: { 'authorization': infoStore().token, 'Content-Type': 'text/html; charset=utf-8' },
		method: 'POST',
		withCredentials: true,
		data: notifText,
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateNotifications'))
}
function deleteNotification(notifId, callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_device + 'updating_notification/'+ notifId,
		headers: { 'authorization': infoStore().token, 'Content-Type': 'application/json' },
		method: 'DELETE',
		withCredentials: true,
	}
	axios(dataForRequest).then((answer) => {
		callback(answer)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateNotifications'))
}
function getNotification(callback) {
	let dataForRequest = {
		baseURL: infoStore().base_url,
		url: infoStore().bonus_url_api + 'updating_notification',
		headers: { 'authorization': infoStore().token, 'Content-Type': 'application/json' },
		method: 'GET',
		withCredentials: true,
	}
	axios(dataForRequest).then((answer) => {
		callback(answer.data)
	}).catch((answer) => bugReport(answer, dataForRequest, 'updateNotifications'))
}