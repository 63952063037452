import React 			from 'react'
import Required 		from '../component/Required.jsx'
import {closeImg}   	from '../img.jsx'
import TooltipWrapper 	from '../component/TooltipWrapper.jsx'
import CalibrationForm 	from './CalibrationForm.jsx'
import l_v                                  			 from "store/languages.jsx"

export default class CreateSensorType extends React.Component {
	constructor(props){
		super(props);
	}
	render(){
		const {globalCalibrations} = this.props;
		return 	<div className = 'sensor_types' id = 'sensor_types'>
								<ButtonForAddCalibration 	globalCalibrations = {globalCalibrations}
															callForm = {() => this.calibrationForm.activation()}/>
								<CalibrationForm 	createCalibration = {this.props.createCalibration}
													itsAdd = 	{true}
													ref = {calibrationForm => this.calibrationForm = calibrationForm}/>
						</div>
	}
}

function ButtonForAddCalibration(props) {
	const {callForm, globalCalibrations} = props;
	return 	globalCalibrations ?
			<button 	className = 'primari_btn'
						type = 		'button'
						name = 		'CreateSensorType'
						onClick = 	{callForm}>
					{l_v.add_calibration}
			</button>:
			<button 	className = 'btn_plus'
						type = 		'button'
						name = 		'CreateSensorType'
						onClick = 	{callForm}>
					+
			</button>

}