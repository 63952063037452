import React                    from 'react'
import moment                   from 'moment'
import TimeInput                from "dd-date-time-input"
import SL 							  from 'sl-library'
import TooltipWrapper           from '../component/TooltipWrapper.jsx'
import l_v                      from "store/languages.jsx"

export default class ChangePeriodRealSensor extends React.Component{
	constructor(props){
		super(props);
		const {sensorInfo, defaultPeriod} = this.props;
		this.state = {
			realSensorId: 	SL.ifNoKey(sensorInfo, 'realSensorId'),
			dateFrom: 		SL.ifNoKey(defaultPeriod, 'from') ? moment(defaultPeriod.from).format('YYYY-MM-DDTHH:mm:ss') : '',
			dateTo: 			SL.ifNoKey(defaultPeriod, 'to') ? moment(defaultPeriod.to).format('YYYY-MM-DDTHH:mm:ss') : '',
			message: 		''
		}
	}
	enterToState = e => {
		const name =  e.name  || e.target.name;
		const value = e.value || e.target.value;
		this.setState({
			[name]: value
		});
	}
	render(){
		return 	<div className = 'change_period_form' onSubmit = {this.validate}>
                  <p className = 'detail_socket_info_tub_header'>{l_v.real_sensor_period_update}</p>
						<div className = 'message'>{this.state.message}</div>
						<TooltipWrapper 	name = 		{l_v.id_of_the_sensor_to_be_changed}
												position = 	'right'
												className = 'wrap'>
							<input 	type = 			'text'
										ref = 			{realSensorId =>this.realSensorId = realSensorId}
										className = 	{'input ' + this.state.sensorNumber_empty}
										name = 			'realSensorId'
										value = 			{this.state.realSensorId}
										onChange = 		{this.enterToState}
										placeholder = 	{`${l_v.previous_sensor_id} *`}/>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<TooltipWrapper 	name = 		{l_v.date_from_which_to_replace}
												position = 	{'right'}
												className = 'wrap'>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
											name = 			'dateFrom'
											maxDate = 		{this.state.dateTo}
											value = 			{this.state.dateFrom}
											class = 			'input'
											onChange = 		{ e => this.setState({dateFrom: e.value})}
											labelHide = 	{true}/>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<TooltipWrapper 	name = 		{l_v.date_from_which_to_replace}
												position = 	{'right'}
												className = 'wrap'>
							<TimeInput 	placeholder = 	'25.08.1988 9:45'
											name = 			'dateTo'
											minDate = 		{this.state.dateFrom}
											value = 		{this.state.dateTo}
											class = 		'input'
											onChange = 		{ e => this.setState({dateTo: e.value})}
											maxDis = 		{true}
											labelHide = 	{true}/>
						</TooltipWrapper>
						<div className = 'clear'></div>
						<button 	className  = 	'primari_btn'
									style = 			{{display: 'block'}}
									onClick = 		{this.validate}>
							{l_v.update_period}
						</button>
				</div>
	}
	validate = e => {
		e.preventDefault();
		let {realSensorId, dateFrom, dateTo} = this.state;
		if(!!dateFrom && SL.isValidDate(new Date(dateFrom))){
			dateFrom =  moment(dateFrom).toISOString();
			console.log("%c dateFrom", 'color: green; font-weight: bold;',  dateFrom)
		}else{
			// this.dateFrom.element.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
			dateFrom = null;
			this.setState({
				message: l_v.fill_marked_fields
			});
		}
		if(!!dateTo && SL.isValidDate(new Date(dateTo))){
			dateTo =  moment(dateTo).toISOString();
			// dateTo = moment(dateTo).format('YYYY-MM-DDTHH:mm:ss[Z]');
		}else{
			if(!!dateTo){
				// this.dateTo.element.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
				dateTo = null;
				this.setState({
					message: l_v.date_end_are_wrong
				});
			}
		}
		if(!realSensorId){
			this.realSensorId.style.boxShadow = "rgba(240, 36, 36, 0.88) 0px 0px 17px -5px inset";
			this.setState({
				message: l_v.fill_marked_fields
			});
		}
		if(!!dateFrom && !!realSensorId){
			this.props.changeSensorPeriod(realSensorId, dateFrom, dateTo)
		}
	}
}