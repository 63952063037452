import React, { Component } from 'react';
import TooltipWrapper 		from '../component/TooltipWrapper.jsx'
import PermittedList 		from "../component/PermittedList.jsx"
import translationsApi      from "actions/translationsApi.js"
import {
	ifNoKey,
	checkPass,
	arrayIsEmpty,
	toLowerCase,
	copyOfObject,
	removeElementFromList
}                                                    from 'actions/ActionCreator.jsx'
import SL 				from "sl-library"
import l_v 		from 'store/languages.jsx'

export default class MappingServersToDeviceForm extends Component {
	constructor(props){
		super(props);
		this.state = {
			deviceForMapping: 	this.props.deviceForMapping,
		}
		this.initialState = this.state;
	}
	updateMappedList = (selectedServerList) =>{
		const {retranslateServerDtoList, deviceId} = this.state.deviceForMapping;
		const listForMapping = getServerForMapping(retranslateServerDtoList, selectedServerList);
		const listForUnpaming = getServerForUnmap(retranslateServerDtoList, selectedServerList);
		const updateDeviceServersList = (newDeviceInfo) => {
			this.setState({deviceForMapping: newDeviceInfo});
			this.props.updateDeviceServersList(newDeviceInfo)
		}
		listForMapping.forEach(serverInfo => {
			translationsApi.addServerToDevice(deviceId, serverInfo.id, updateDeviceServersList)
		})
		listForUnpaming.forEach(serverInfo => {
			translationsApi.removeServerFromDevice(deviceId, serverInfo.id, updateDeviceServersList)
		})
	}
	render() {
		const {serversList} = this.props;
		const {deviceForMapping} = this.state;
		return (
			<div>
				<PermittedList 	namePermittedBlock = 	{l_v.mapped_servers}
								namePronhibitedBlock =  {l_v.available_servers}
								allAvailableElements = 	{serversList}
								setPermittedList = 		{this.updateMappedList}
								permittedElements = 	{copyOfObject(deviceForMapping.retranslateServerDtoList)}/>
			</div>
		);
	}
}


function getServerForUnmap(allreadyMappedServers, listForMapping) {
	const getOnlyAbsentServers = (serverForMapping) => {
			return listForMapping.findIndex(mappedServer => mappedServer.id == serverForMapping.id) == -1
		}
	console.log(`%c getServerForUnmap - allreadyMappedServers`, "color: blue; font-weight: 400; text-transform:uppercase", allreadyMappedServers)
	return   SL.arrayIsEmpty(allreadyMappedServers) ? allreadyMappedServers.filter(getOnlyAbsentServers) : []
}



function getServerForMapping(allreadyMappedServers, listForMapping) {
	const getOnlyAbsentServers = (serverForMapping) => {
		return allreadyMappedServers.findIndex(mappedServer => mappedServer.id == serverForMapping.id) == -1
	}
	return !arrayIsEmpty(allreadyMappedServers) ? listForMapping : listForMapping.filter(getOnlyAbsentServers)
}

