import React                            from 'react'
import moment                           from 'moment';
import ClickOutside                     from 'react-click-outside'
import TimeInput                        from "dd-date-time-input"
import l_v                              from 'store/languages.jsx'
import { ifNoKey }                      from 'actions/ActionCreator.jsx'
import devicesApi                       from 'actions/devicesApi.js'
import deviceOnTransportApi             from 'actions/deviceOnTransportApi.js'
import Calendar                         from '../../modules/InfiniteCalendar/Calendar.jsx';
import RealSensors                      from '../socketInfo/RealSensors.jsx'
import ConfirmRemove                    from '../ConfirmRemove.jsx'
import SeparPermission                  from "modules/component/SeparPermission.jsx"
import { deleteImg, editImg, closeImg } from '../img.jsx'
import { arrows }                       from "images/simple_svg_icons.jsx"
import Preloader                        from 'modules/preloader.jsx'

export default class EditMountedDeviceForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			deviceData:  		this.props.deviceData,
			message:  			'',
			set_up_date: 		moment(ifNoKey(this.props.deviceData, "from")),
			set_up_dateEmty: 	'',
			remove_date: 		null,
			remove_date_dateEmty: '',
			showMore: 			false,
			softwareVersion: 	undefined,
			IMEI: 				undefined,
			deviceType: 		undefined,
			hardwareVersion: 	undefined,
		}
		this.ConfirmTextforRemove = l_v.ask_remove_period(this.props.periodId);
	}
	componentWillReceiveProps(nextProps) {
		this.setState({
			sensors: 		nextProps.sensors,
			deviceData: 	nextProps.deviceData,
			set_up_date: 	moment(ifNoKey(nextProps.deviceData, "from")),
		})
	}
	componentWillMount() {

		deviceOnTransportApi.getDeviceInfo(this.props.deviceData.deviceSerial, this.setDeviceInfo)
	}
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	setDeviceInfo = (data) =>{
		this.setState({
			softwareVersion: 		data.softwareVersion,
			IMEI: 					data.imei,
			deviceType: 			data.deviceType,
			hardwareVersion: 		data.hardwareVersion,
		});
	}
	removeSensor_from_Vehicle = () =>{
		const {isGasStation} = this.props;
		const requestInfo = {
			idvehicle: this.props.transportId,
			timeId: this.state.deviceData.id,
		};
		deviceOnTransportApi.unmountDevice({
			requestInfo,
			isGasStation,
			callbackOnOk: () => this.mounted ? this.preloader.hide() : null,
			callbackOnError: () => this.mounted ? this.preloader.hide() : null,
		})
	}
	checkDatesForConflict(dateFrom, dateTo){
		if(!!dateFrom && !!dateTo){
			if(new Date(dateFrom)>new Date(dateTo)){
				return false
			}else return true
		}else{
			if(!!dateFrom){
				return true
			}else false
		}
	}
	updateDeviceInterval = () =>{
		let set_up_date = this.state.set_up_date ? this.state.set_up_date : ifNoKey(this.state.deviceData, "from");
		let remove_date = this.state.remove_date;
		const {isGasStation} = this.props;
		if(set_up_date){
			if(!this.checkDatesForConflict(set_up_date, remove_date)){
				this.setState({
						remove_date_dateEmty: 'formError_calendar',
				})
			}else{
				const requestInfo = {
						idvehicle: this.props.transportId,
						timeId: this.state.deviceData.id,
						forsend: {
							from: moment(set_up_date).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
							to: remove_date ? moment(remove_date).utc().format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') : null,
						}
				};
				deviceOnTransportApi.updateDevicePeriod({
					requestInfo,
					isGasStation,
					callbackOnOk: () => this.mounted ? this.preloader.hide() : null,
					callbackOnError: () => this.mounted ? this.preloader.hide() : null,
				})
				this.setState({
					set_up_dateEmty: '',
					remove_date_dateEmty: '',
				})
			}
		}else{
			if(!set_up_date){
				if(!!remove_date){
					this.setState({
						remove_date_dateEmty: '',
					})
				}
			}
			if(!!remove_date){
				this.setState({
					remove_date_dateEmty: 'formError_calendar',
				})
				if(!!set_up_date){
					this.setState({
						set_up_dateEmty: '',
					})
				}
			}
		}
	}
	editSensor(){
		alert("тут должна быть форма, но, у меня есть вариант удалить и создать новый датчик, но у него будет уже другой id, это ничего?)")
	}
	callDeleteDeviceInterval = () =>{
		this.remove.popUp_activation({
                active: true
        })
	}
	deleteDeviceInterval = () => {
		const requestInfo = {
			vehicleId: this.props.transportId,
			timeId: this.props.periodId
		};
		const {isGasStation} = this.props;
		deviceOnTransportApi.removeIntervalFromVehicle({
			requestInfo,
			isGasStation,
			callbackOnOk: () => this.mounted ? this.preloader.hide() : null,
			callbackOnError: () => this.mounted ? this.preloader.hide() : null,
		})
	}
	render(){
		const {	deviceData,
				set_up_dateEmty,
				remove_date_dateEmty,
				calibrations,
				sensors,
				hardwareVersion,
				IMEI,
				set_up_date,
				showMore,
				softwareVersion,
				remove_date,
				deviceType
			} = this.state;
		const styles = {
								maxHeight: !!checkStatus(ifNoKey(deviceData, "to")) ? "4500px" : "115px", 
								overflow: "hidden"
							}
		return	<div 	className =	'mount_form edit_device_on_transport'
							style = 	{styles}
							ref= 		{ editDevice => this.editDevice = editDevice }
							key = 	{ this.props.transportId }>
							<p className = 'form_message '>{this.state.message}</p>
							<DeviceInfo deviceData = {deviceData}
										callDeleteDeviceInterval = {this.callDeleteDeviceInterval}
										hardwareVersion = {hardwareVersion}
										IMEI = {IMEI}
										showMoreUpdate = {this.showMore}
										showMoreStatus = {showMore}
										softwareVersion = {softwareVersion}
										deviceType = {deviceType}/>
							<div 	className = "date_block" >
								<p 	className = "nameParagraph small_height">
									<span>{l_v.montage_date}:</span> {!ifNoKey(deviceData, "from") ? l_v.not_mounted : moment(ifNoKey(deviceData, "from")).format('DD-MM-YYYY HH:mm')}
								</p>
								<p 	className = "nameParagraph margin_bottom">
									<span>{l_v.demontage_date}:</span> {(!ifNoKey(deviceData, "to")) ? l_v.not_mounted: moment(ifNoKey(deviceData, "to")).format('DD-MM-YYYY HH:mm')}
								</p>
								<div>
									<TimeInput 	placeholder = 	'25.08.1988 9:45'
													name = 			'set_up_date'
													value = 			{set_up_date}
													class = 			{'input ' + set_up_dateEmty}
													onChange = 		{(e)=> this.setState({set_up_date: e.value})}
													textLabel = 	{`${l_v.mount_date} *`}/>

									<TimeInput 	placeholder = 	'25.08.1988 9:45'
													name = 			'remove_date'
													value = 			{remove_date}
													class = 			{'input ' + remove_date_dateEmty}
													onChange = 		{(e) => this.setState({remove_date: e.value})}
													textLabel = 	{l_v.device_unmount_date}/>
								</div>
								<button type = 		"button"
										className =	"btn_black addsensor"
										onClick = 	{this.updateDeviceInterval}>
										{l_v.update_timeslot}
								</button>
							</div>

							<ConfirmRemove  	ref =           { (remove) => this.remove = remove }
					                        confirmText =   { this.ConfirmTextforRemove }
					                        clear =         { () => this.setState({ forDelete: undefined }) }
					                        remove =    	 { this.deleteDeviceInterval }/>
							<Preloader ref = {preloader => this.preloader = preloader} />				      

				</div>
	}
	showMore = () => {
		const { showMore } = this.state;
		this.editDevice.style.maxHeight = showMore ? '115px' : "1500px";
		this.setState({ showMore: !showMore });
	}



}
function checkStatus(dateTo){
		if(!!dateTo){
			if(new Date() > new Date(dateTo)){
				return false
			}else{
				return true
			}
		}else{
			return true
		}
	}

function DeviceInfo(props){
	let {deviceData, callDeleteDeviceInterval, hardwareVersion, IMEI, showMoreStatus, showMoreUpdate, softwareVersion, deviceType} = props;
	return 	<div>
				<p className = "nameParagraph" style = {{display: 'flex', alignItems: 'center'}}>
					<span>{l_v.serial_number}:</span> {ifNoKey(deviceData, "deviceSerial")}
					<SeparPermission 	alowed =         'SUPERADMIN'
										type =           'component'
										kindCapability = ''>
						<button type = 		"button"
								className =	"primari_btn"
								style = {{marginLeft: '160px'}}
								onClick = 	{callDeleteDeviceInterval}>
								{l_v.remove_period}
						</button>
					</SeparPermission>
				</p>
				<p className = "nameParagraph">
					<span>{l_v.id_of_period}:</span> {ifNoKey(deviceData,"id")}
				</p>
				<p className = "nameParagraph">
					<span>{l_v.device_type}:</span> {deviceType ? deviceType : l_v.there_is_no}
				</p>
				<p className="nameParagraph">
					<span>HW:</span> {hardwareVersion}

					<button 	onClick = 	{showMoreUpdate}
								className = 'toogle_show_more_in_mounted_device'
								style = { {
										marginLeft: "15px"
									} } >
								{ showMoreStatus ? l_v.hide: l_v.more }
								{ arrows.bothArrows('vertical_arrows') }
					</button>
				</p>
				{IMEI?<p className = "nameParagraph">
					<span>IMEI:</span> {IMEI}
				</p>:null}

				<p className = "nameParagraph">
					<span>SW:</span> {softwareVersion}
				</p>
			</div>
}