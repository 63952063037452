import React, { useState, Fragment, Component }  from 'react'
import TimeInput    from 'dd-date-time-input'
import moment from "moment"
import ConfirmRemove from "modules/ConfirmRemove.jsx"
import l_v         from 'store/languages.jsx'

class ClearDataByDeviceForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            to:     null,
            from:   null,
        }
        this.initialState = this.state;
    }
    sendRequest = () => {
        const {to, from, ignitionOn} = this.state;
        this.props.removeDataFromСassandra({
            to: moment(to).format(),//.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
            from: moment(from).format(),//.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
        })
    }
    callConfirm = () => this.confirm.open()
    render() {
        const {ignitionOn, to, from} = this.state;
        let confirmText = `${l_v.are_you_sure_want_to_delete_data} `;
        confirmText += `${moment(from).format('DD.MM.YYYY - HH:mm:ss')} ${l_v.to} ${moment(to).format('DD.MM.YYYY - HH:mm:ss')}`;
        return (
            <div className = 'ignition_correction_form'>
                <p className = 'nameForm'>{l_v.data_range_remove}</p>
                <TimeInput  disabledDate =          {false}
                            id =                    'clear_data_from_server_form-from'
                            onChange =              {(e) => this.setState({[e.name]: e.value})}
                            value =                 {from}
                            hiddenTime =            {false}
                            textLabel =             {l_v.period_start}
                            maxDate =               {to ? to : null}
                            withSeconds =           {true}
                            name =                  'from'/>
                <TimeInput  disabledDate =          {false}
                            id =                    'clear_data_from_serverform-to'
                            onChange =              {(e) => this.setState({[e.name]: e.value})}
                            value =                 {to}
                            textLabel =             {l_v.end_period}
                            hiddenTime =            {false}
                            minDate =               {from}
                            withSeconds =           {true}
                            name =                  'to'/>

            
                <div className = 'clear'></div>
                <button className = 'primari_btn'
                        disabled = {!to || !from}
                        onClick = {this.callConfirm}>
                        {l_v.remove_data}
                </button>
                <ConfirmRemove  confirm_header_text = {l_v.remove_data_cassandra}
                                height =        '210px'
                                ref =           {el => this.confirm = el}
                                remove =        {this.sendRequest}
                                confirmText =   {confirmText}/>
            </div>
        );
    }
}
export default ClearDataByDeviceForm