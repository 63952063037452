import React 					from "react"
import OneAdmin 				from './OneAdministrator.jsx'
import SL 						from 	"sl-library"
import l_v 						from "store/languages.jsx"

export default class AdminList extends React.Component {
	constructor(props) {
		super(props);
		const { userPremission, kindUser } = this.props;
		const premissions = SL.copyOfObject(userPremission);

		if(kindUser == 'DEALER' && premissions) {
			//this capabilities not available for dealers (there was a backend bug )
			delete premissions.capabilities.createAdminUsers
			delete premissions.capabilities.isTrusted
		}

		this.state = {
			data:              [],
			userPremissions:   premissions,
			belongsTo:         'none',
			editOther:         'none',
			editDevices:       'none',
			editSensors:       'none',
			deleteSensorTypes: 'none',
			seeStats:          'none',
			recalcSensors:     'none',
			createAdminUsers:  'none',
			setBlocking:       'none',
			editClients:       'none',
			editVehicles:      'none',
			isTrusted: 		   'none',
			premissionsWidth:  '',
			column_height:     '',
			win_width:         '',
			after:             0,
			before:            '',
			scrollpos:         '',
		}
		this.setwidth = this.setwidth.bind(this)
	}

	componentWillMount() {
		// check user capabilities and show, that contain  true
		if(this.state.userPremission !== undefined) {
			this.setColumnVisibility(this.state.userPremission)
		}
		if(this.props.userPremission.belongsTo !== null && this.props.userPremission.belongsTo !== undefined) { //if user has group, he can't set up other group for child
			this.setState({
				belongsTo: 'none'
			})
		} else {
			this.setState({
				belongsTo: ''
			})
		}
	}
	setColumnVisibility = (premissions) => {
		// check user capabilities and show, that contain  true
		let mas = premissions.capabilities;
		for(let i in mas) {
			if(mas[i] == true) {
				if(i == 'editOthers' && this.props.kindUser == 'ADMIN' ){
					this.setState({
						isTrusted: "",
					})
				}
				if(i == 'isTrusted' && this.props.kindUser == 'DEALER'){
					//do nothing
				}else{
					this.setState({
						[i]: "",
					})
				}
			}
		}
	}
	componentWillReceiveProps(nextProps) {
		let premissions = Object.assign({}, nextProps.userPremission);
		premissions.capabilities = Object.assign({}, SL.ifNoKey(nextProps.userPremission, "capabilities"))
		if(this.props.kindUser == 'DEALER') {
			delete premissions.capabilities.createAdminUsers
		}
		this.setState({
			data: nextProps.data,
			userPremissions: premissions,
		})
		this.setColumnVisibility(premissions);
		//if user has group, he can't set up other group for child
		if(SL.ifNoKey(nextProps.userPremission, 'belongsTo') !== null && SL.ifNoKey(nextProps.userPremission, 'belongsTo') !== undefined) { 
			this.setState({
				belongsTo: 'none'
			})
		} else {
			this.setState({
				belongsTo: ''
			})
		}
	}
	goUp = (data) => {
		this.props.filterGroupByThis(data);
	}
	setwidth(data) {
		//do  nothing
		// let premissions = data.premissions / data.table * 100;
		// let cell_login = data.cell_login / data.table * 100;
		// let premissionsWidth = premissions + cell_login + '%';
		// let win_width = window.innerWidth;
		// if(win_width > 1000) {
		// 	if(this.state.belongsTo == 'none') {
		// 		console.log('if', premissionsWidth, 'state', this.state.premissionsWidth)
		// 		this.setState({
		// 			premissionsWidth: premissionsWidth,
		// 		})
		// 	}
		// }
		// if(win_width < 1000) {
		// 	this.setState({
		// 		column_height: data.column_height + 1,
		// 	})
		// }
		// this.setState({
		// 	win_width: win_width,
		// })
	}
	scroolTop = () => {
		let height = this.state.column_height;
		let name = document.querySelector('.table_content');
		name.scrollTop -= height;
	}
	scroolBottom = () => {
		let height = this.state.column_height;
		let name = document.querySelector('.table_content');
		name.scrollTop += height;
	}
	componentDidMount() {
		let mas = this.refs;
		let length = 0;
		let kol = Object.assign({}, SL.ifNoKey(this.props.userPremission, "capabilities"));
		if(this.props.kindUser == 'DEALER') {
			delete kol.createAdminUsers
			delete kol.isTrusted
		}
		let win_width = window.innerWidth;
		if(win_width > 1000) {
			for(let i in kol) {
				if(kol[i] !== 'false') {
					length = length + 1;
				}
			}
			for(let i in mas) {
				let cellname = i;
				// console.log(cellname,length)
				let width = 100 / length;
				// let width = this.refs[cellname].offsetWidth;
				this.setState({
					[cellname]: width + '%',
				})
			}
		}
	}
	render(){
		let setwidth = this.setwidth;
		let {belongsTo, scrollpos, editOther, editDevices, editSensor, deleteSensorTypes, seeStats, recalcSensors,
			createAdminUsers, setBlocking, premissionsWidth, editClients, editVehicles, editClients_width, editOther_width,
			editDevices_width, editVehicles_width, editSensor_width, deleteSensorTypes_width, seeStats_width, recalcSensors_width,
			createAdminUsers_width, setBlocking_width, blocking_width, isTrusted}  = this.state;
		if (!SL.arrayIsEmpty(this.state.data)){
			return  <p className='nodata'>{l_v.nodata}</p>
		}
		else {
			return(
				<div className = 'administratorList table'>
					{/*<div className='triggerUp visible_on_mobile'
						 onClick={this.scroolTop}
					>▲</div>
					<div className='triggerDown visible_on_mobile'
						onClick={this.scroolBottom}
					>▼</div>*/}
					<div className = "oneLine head_line">
						<div className = "cell_login cell">
							{l_v.login}
						</div>
						<div 	className = "cell_desc cell"
								style =  {{display: belongsTo}}>
							{l_v.description}
						</div>
						<div 	className = "cell_byWhom cell"
								style =  {{display: belongsTo}}>
							{l_v.group}
						</div>
						<div 	className = 'premissions'
								style= 		{{width: this.state.premissionsWidth}}>
								<div 	className = "cell_editClients cell"
										style = 	{{display: this.state.editClients, width: editClients_width}}
										ref = 		{'editClients_width'}>
										{l_v.edit_clients}
								</div>
								<div  	className = "cell_editOther cell "
										style = 	{{display: this.state.editOther, width: editOther_width}}
										ref = 		'editOther_width'>
										{l_v.edit_others}
								</div>
								<div 	className = "cell_editDevice cell "
										style = 	{{display: this.state.editDevices, width: editDevices_width }}
										ref = 		'editDevices_width'>
										{l_v.edit_devices}
								</div>
								<div  	className = "cell_editSensor cell "
										style = {{display: this.state.editSensor, width: editSensor_width}}
										ref='editSensor_width'>
										{l_v.edit_sensors}
								</div>
								<div 	className = 'cell_editVehicles cell '
										style = {{display: this.state.editVehicles, width: editVehicles_width}}
										ref='editVehicles_width'>
										Редактировать транспорт
								</div>
								<div  	className = "cell_deleteSensor cell "
										style = {{display: this.state.deleteSensorTypes, width: deleteSensorTypes_width}}
										ref='deleteSensorTypes_width'>
										{l_v.cailbrations_remove}
								</div>
								<div 	className = "cell_seeStatistic cell "
										style = {{display: this.state.seeStats, width: seeStats_width}}
										ref='seeStats_width'>
										{l_v.see_statistics}
								</div>
								<div 	className = "cell_recalculateDataSensor cell recalcSensors"
										style = {{display: this.state.recalcSensors, width: recalcSensors_width}}
										ref='recalcSensors_width'>
										{l_v.sensor_setup}
								</div>
								<div 	className = "cell_createAdmin cell "
										style = {{display: this.state.createAdminUsers, width: createAdminUsers_width}}
										ref='createAdminUsers_width'>
										{l_v.create_admins}
								</div>
								<div 	className = "cell_setUpBlocking cell "
										style = {{display: this.state.setBlocking, width: setBlocking_width}}
										ref='setBlocking_width'>
										{l_v.set_blocks}
								</div>
							{/*	<div 	className = 'cell_blocking cell'
										style = {{display: this.state.setBlocking, width: blocking_width}}
										ref='blocking_width'>
										{l_v.blocked}?
								</div>*/}
								<div 	className = 'cell_is_trusted cell'
										style = {{display: this.state.isTrusted, width: blocking_width}}
										ref='blocking_width'>
										{l_v.trusted_admin}
								</div>
						</div>
						<div className = "cell_edit cell">

						</div>
						{/*<div className = "cell_delete cell">

						</div>*/}
					</div>

					<div className = 	'table_content'
						 style = 		{{height: this.state.column_height+'px'}}>
					{
							this.state.data.map( (key) => {
								return <OneAdmin 	key = 						{key.login}
													login = 					{key.login}
													kindUser = 					{this.props.kindUser}
													capabilities = 				{key.capabilities}
													belongsTo = 				{key.belongsTo}
													blocked = 					{key.blocked}
													description = 				{key.description}
													visible_belongsTo = 		{belongsTo}
													visible_editOther = 		{editOther}
													visible_editDevices = 		{editDevices}
													visible_editSensor = 		{editSensor}
													visible_deleteSensorTypes = {deleteSensorTypes}
													visible_seeStats = 			{seeStats}
													visible_recalcSensors = 	{recalcSensors}
													visible_createAdminUsers = 	{createAdminUsers}
													visible_setBlocking = 		{setBlocking}
													visible_isTrusted = 		{isTrusted}
													visible_editClients = 		{editClients}
													visible_editVehicles = 		{editVehicles}
													editClients_width = 		{editClients_width}
													editOther_width = 			{editOther_width}
													editVehicles_width = 		{editVehicles_width}
													editDevices_width = 		{editDevices_width}
													editSensor_width = 			{editSensor_width}
													deleteSensorTypes_width = 	{deleteSensorTypes_width}
													seeStats_width = 			{seeStats_width}
													recalcSensors_width = 		{recalcSensors_width}
													createAdminUsers_width = 	{createAdminUsers_width}
													setBlocking_width = 		{setBlocking_width}
													blocking_width = 			{blocking_width}
													premissionsWidth = 			{premissionsWidth}
													filterbyGroup = 			{this.goUp}
													delete = 					{this.props.delete}
													returnwidth = 				{setwidth}
													scrollpos = 				{scrollpos}/>
							})
					}
					</div>
				</div>
			)
		}
	}
}

