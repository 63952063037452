import React, { Fragment } from 'react';
import SL from 'sl-library';
import SwitchInput from '../component/SwitchInput.jsx';
import CustomSelect from '../component/CustomSelect.jsx';
import EditorValue from './EditorValue.jsx';

class TransportParameters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsArray: this.props.settingsArray,
      options: [
        language_variant.standart_haversin,
        language_variant.haversin,
      ],
      selectedValue: ''
    };
  }

  enterToStateInput = (value, name) => {
    this.setState({
      [name]: value
    }, () => {
      this.props.sendToServer(value, name);
    });
  }

  handleOptionSelect = (option, name) => {
    this.setState({
      selectedValue: option
    }, () => {
      if (option === language_variant.haversin) {
        this.enterToStateInput("HAVERSINE", name);
      }
      if (option === language_variant.standart_haversin) {
        this.enterToStateInput("DEFAULT", name);
      }
    });
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ settingsArray: nextProps.settingsArray });
  }

  render(ReactElement, DOMElement, callback) {
    const { settingsArray } = this.state;
    console.log('this.state', this.state);

    const viewElement = key => key.typeValue === 'DOUBLE' || key.typeValue === 'INTEGER' ?
      <EditorValue editable={true}
                   key={key.name}
                   disabled={false}
                   text={key.localeTitle}
                   units={key.unit}
                   value={key.value}
                   name={key.name}
                   returned={this.enterToStateInput} /> :
      <div className='editor_container_switch' key={key.name}>
        <p className='text_info'>{key.localeTitle}</p>
        {key.name !== 'mileage_algorithm_type' &&
          <SwitchInput
            result={this.enterToStateInput}
            isChecked={SL.stringToBoolean(this.state[key.name])}
            name={key.name} />
        }
        {key.name === 'mileage_algorithm_type' &&
          <>
            <CustomSelect
              options={this.state.options}
              onOptionSelect={(option) => this.handleOptionSelect(option, key.name)}
              defaultValue={key.value === 'DEFAULT' ? language_variant.standart_haversin : language_variant.haversin}
            />
          </>
        }
      </div>;

    return <div className='profile_container'>
      {
        SL.arrayIsEmpty(settingsArray) ?
          settingsArray.map(key => viewElement(key)) :
          null
      }
    </div>;
  }
}

export default TransportParameters;
