import React from "react"
export default class RequireWrapper extends React.Component {
	constructor(props) {
		super(props)
		this.state = {

		}
		this.child = this.props.children;
	}
	componentDidMount() {
		if(!this.child.props.defaultValue){
			this.props.checkRequire(this.child.props.name, false)
		}else{
			this.props.checkRequire(this.child.props.name, true)
		}
	}
	componentWillReceiveProps(nextProps) {
		this.child = nextProps.children;
	}
	children = () => {
		if(!!this.child){
			if(!this.child.props.value && this.child.props.value !== 0){
				this.props.checkRequire(this.child.props.name, false)
			}else{
				this.props.checkRequire(this.child.props.name, true)
			}
			if(!!this.props.checkIt){
					return React.cloneElement(this.props.children, {
				     	style: this.style()
				    })
			}else{
				return this.props.children
			}
		}else{
			return null
		}

	}
	render() {
		return (
			this.children()
		);
	}
	style = () =>{
		if(!this.child.props.value && this.child.props.value !== 0){
			return  {boxShadow: "inset 0px 0px 17px -5px rgba(240, 36, 36, 0.88)"}
		}
		else{
			return undefined
		}
	}
	// methods
}


// in parent component
// 	checkRequire = (data, event) =>{
// 		this.setState({
// 			check: true,
// 		});
// 		let validate = true;
// 		let name;
// 		Object.keys(this.requires).map(key=>{
// 			if(this.requires[key] == false){
// 				validate = false;
// 				name = key;
// 			}
// 		})
// 		if(!!validate){
// 			this.setState({
// 					message: 	'',
// 					check: 		false,
// 			})
// 			this.addition()
// 		}else{
// 			this.setState({
// 				message: `${l_v.fill_marked_fields}`,
// 			})
// 		}
// 	}
// 	requireElem = (name, value) =>{
// 		this.requires[name] = value;
// 	}