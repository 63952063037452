
const all_translates = {
    "edit": {
        "ru": "Редактирование",
        "eng": "Edit",
        "ukr": "Редагування"
    },

    request_limit_exceeded: {
        "ru": "Превышен лимит запросов, попробуйте через минуту",
        "eng": "Request limit exceeded, please try again in a minute",
        "ukr": "Перевищен ліміт на запити, спробуйте за хвилину"
    },

    minimal_sensor_value_for_ignition: {
        "ru": "минимальное значение для зажигания",
        "eng": "minimum value for ignition",
        "ukr": "мінімальне значення для зажигання"
    },
    ignition_sensor: {
        "ru": 'датчик для определения зажигания',
        "eng": "ignition sensor",
        "ukr": 'датчик для визначення зажигання'
    },
    If_there_are_no_values_ignition_is_determined_by_speed: {
        "ru": 'При отсутствии значений зажигание определяется по скорости',
        "eng": 'If there are no values, ignition is determined by speed',
        "ukr": 'За відсутності значень запалювання визначається за швидкістю'
    },

    sensors: {
        "ru": "Датчики",
        "eng": "Sensors",
        "ukr": "Датчики"

    },
    clear: {
        "ru": "Очистить",
        "eng": "Clear",
        "ukr": "Очистити"
    },
    back: {
        "ru": "назад",
        "eng": "back",
        "ukr": "назад"
    },
    updated: {
        "ru": "обновлено",
        "eng": "updated",
        "ukr": "оновлено"
    },
    socket: {
        "ru": "сокет",
        "eng": "socket",
        "ukr": "сокет"
    },
    more: {
        "ru": "Еще",
        "eng": "More",
        "ukr": "Ще"
    },
    sec: {
        "ru": "сек",
        "eng": "sec",
        "ukr": "сек"
    },
    to: {
        "ru": "по",
        "eng": "to",
        "ukr": "по"
    },
    user: {
        "ru": 'Пользователь',
        "eng": "User",
        "ukr": "Користувач"
    },
    information_was_updated: {
        "ru": "Информация обновлена",
        "eng": "Information updated",
        "ukr": "Інформація оновлена"
    },
    connection_restored: {
        "ru": 'Соединение восстановлено',
        "eng": "Connection restored",
        "ukr": "З'єднання відновлено"
    },
    add_calibration: {
        "ru": 'Добавить тарировку',
        "eng": 'Add calibration',
        "ukr": 'Додати таріровку'
    },
    table_of_values: {
        "ru": 'Таблица значений',
        "eng": 'Table of values',
        "ukr": 'Таблиця значень'
    },
    gas_station_settings: {
        "eng": "Gas station settings",
        "ukr": "Налаштування АЗС",
        "ru": "Настройки АЗС",
    },
    gas_station_params: {
        "ru": 'Параметры АЗС',
        "eng": "Filling station parameters",
        "ukr": "Параметры АЗС"
    },
    test: {
        "ru": (value) => `some text ${value}`,
        "eng": "Yes",
        "ukr": "Так"
    },
    new_date: {
        "ru": "Новая дата",
        "eng": "New date",
        "ukr": "Нова дата"
    },
    "yes": {
        "ru": "Да",
        "eng": "Yes",
        "ukr": "Так"
    },
    "no": {
        "ru": "Не",
        "eng": "No",
        "ukr": "ні"
    },
    "there_is_no": {
        "ru": "Нету",
        "eng": "There is no",
        "ukr": "Немає"
    },
    "login_placeholder": {
        "ru": "Логин",
        "eng": "Login",
        "ukr": "Логін"
    },
    "name": {
        "ru": "Название",
        "eng": "Name",
        "ukr": "Назва"
    },
    "block_date": {
        "ru": "Дата блокировки",
        "eng": "Block date",
        "ukr": "Дата блокування"
    },
    "block": {
        "ru": "Блокировка",
        "eng": "Block",
        "ukr": "Блокування"
    },
    "number_of_lines": {
        "ru": "Количество строк",
        "eng": "Number of lines",
        "ukr": "Кількість рядків"
    },
    "description": {
        "ru": "Описание",
        "eng": "Description",
        "ukr": "Опис"
    },
    "add": {
        "ru": "Добавить",
        "eng": "Add",
        "ukr": "Додати"
    },
    "close": {
        "ru": "Закрыть",
        "eng": "close",
        "ukr": "Закрити"
    },
    "add_client": {
        "ru": "Добавить клиента",
        "eng": "Add client",
        "ukr": "Додати клієнта"
    },
    "logout": {
        "ru": "Выход",
        "eng": "Logout",
        "ukr": "Вихід"
    },
    "client_customer": {
        "ru": "Пользователи клиентов",
        "eng": "Client customers",
        "ukr": "Користувачі клієнтів"
    },
    "password_placeholder": {
        "ru": "Пароль",
        "eng": "Password",
        "ukr": "Пароль"
    },
    "profile": {
        "ru": "Профиль",
        "eng": "Profile",
        "ukr": "Профіль"
    },
    "button_send": {
        "ru": "Войти в систему",
        "eng": "Login to system",
        "ukr": "Увійти до системи"
    },
    "tasks": {
        "ru": "Задания",
        "eng": "Tasks",
        "ukr": "Завдання"
    },
    "report": {
        "ru": "Отчёт",
        "eng": "Report",
        "ukr": "Звіт"
    },
    "personal": {
        "ru": "Сотрудники",
        "eng": "Personal",
        "ukr": "Персонал"
    },
    "help": {
        "ru": "Помощь",
        "eng": "Help",
        "ukr": "Допомога"
    },
    "placeholder_search": {
        "ru": "Поиск ...",
        "eng": "Search ...",
        "ukr": "Пошук ..."
    },
    "search": {
        "ru": "Поиск",
        "eng": "Search",
        "ukr": "Пошук"
    },
    "today": {
        "ru": "Сегодня",
        "eng": "Today",
        "ukr": "Сьогодні"
    },
    "show": {
        "ru": "Отображать",
        "eng": "Show",
        "ukr": "Показувати"
    },
    "group": {
        "ru": "Группа",
        "eng": "Group",
        "ukr": "Група"
    },
    "groups": {
        "ru": "Группы",
        "eng": "Groups",
        "ukr": "Групи"
    },
    "allTransport": {
        "ru": "Весь транспорт",
        "eng": "All transport",
        "ukr": "Весь транспорт"
    },
    orig_value: {
        "ru": "Ориг. знач",
        "eng": "Orig. value",
        "ukr": "Оріг. знач"
    },
    result_value: {
        "ru": "Результат. знач",
        "eng": "Result. value",
        "ukr": "Результат. знач"
    },
    literage: {
        "ru": "Литраж",
        "eng": "Literage",
        "ukr": "Літраж"
    },
    frequency: {
        "ru": "Частота",
        "eng": "Frequency",
        "ukr": "Частота"
    },
    min_val:{
        "ru": "Мин. знач",
        "eng": "Min. value",
        "ukr": "Мін. знач"
    },
    max_val:{
        "ru": "Макс. знач",
        "eng": "max. value",
        "ukr": "Макс. знач"
    },
    "save_calibrations": {
        "ru": "Сохранить",
        "eng": "Save",
        "ukr": "Зберегти"
    },
    page_not_found: {
        'ru': "Страница не найдена",
        "eng": "Page not found",
        "ukr": "Сторінка не знайдена"
    },
    global_calibration: {
        'ru': "Глобальные тарировки",
        "eng": "Global calibrations",
        "ukr": "Глобальні тарування"
    },
    calibrations_placeholder: {
        'ru': "Введите название или id тарировки",
        "eng": 'Enter the name or id of the calibration',
        "ukr": 'Введіть назву або id тарування'
    },
    "update_calibration": {
        "ru": "Обновить калибровку",
        "eng": "Update calibration",
        "ukr": "Оновити калібрування"
    },

    "transport_search_placeholder": {
        "ru": "Номер или марка транспорта",
        "eng": "Number or transportation brand",
        "ukr": "Номер або марка транспорту"
    },
    "errorconnection": {
        "ru": "Проблемы подключения к серверу, проверьте ваше соединение...",
        "eng": "Connection problem, check your internet connection...",
        "ukr": "Проблеми з'єднання з сервером, перевірте ваше підключення... "
    },
    "errorauthorization": {
        "ru": "Неправильный логин или пароль",
        "eng": "Incorrect login or password",
        "ukr": "Неправильний логін або пароль"
    },
    "enterToSystem": {
        "ru": "Вход в систему",
        "eng": "Enter to system",
        "ukr": "Увійти до системи"
    },
    "interfaceLanguage": {
        "ru": "Язык интерфейса",
        "eng": "Interface language",
        "ukr": "Мова інтерфейсу"
    },
    "status_inmidcourse": {
        "ru": "В пути",
        "eng": "In mid course",
        "ukr": "В дорозі"
    },
    "parking": {
        "ru": "Стоит",
        "eng": "Parking",
        "ukr": "Стоїть"
    },
    "on": {
        "ru": "Вкл",
        "eng": "On",
        "ukr": "Увімкн"
    },
    "off": {
        "ru": "Выкл",
        "eng": "Off",
        "ukr": "Вимкн"
    },
    "kmH": {
        "ru": "Км/ч",
        "eng": "Km/h",
        "ukr": "Км/г"
    },
    "min": {
        "ru": "мин",
        "eng": "min",
        "ukr": "мін"
    },
    "engine": {
        "ru": "Зажигание",
        "eng": "Ignition",
        "ukr": "Запалювання"
    },
    "speed": {
        "ru": "Скорость",
        "eng": "Speed",
        "ukr": "Швидкість"
    },
    "showroute": {
        "ru": "Показать маршрут",
        "eng": "Show route",
        "ukr": "Показати маршрут"
    },
    "give_task": {
        "ru": "Дать задание",
        "eng": "Give task",
        "ukr": "Дати завдання"
    },
    "list": {
        "ru": "Список",
        "eng": "List",
        "ukr": "Список"
    },
    "transports": {
        "ru": "Транспорт",
        "eng": "Transports",
        "ukr": "Транспорт"
    },
    "nodata": {
        "ru": "Нет данных",
        "eng": "No data",
        "ukr": "Немає даних"
    },
    "name_group": {
        "ru": "Название группы",
        "eng": "Group name",
        "ukr": "Назва групи"
    },
    "group_Edit": {
        "ru": "Редактирование группы",
        "eng": "Group edit",
        "ukr": "Редагування групи"
    },
    "create_Group": {
        "ru": "Создать группу",
        "eng": "Create group",
        "ukr": "Створити групу"
    },
    "cancel": {
        "ru": "Отмена",
        "eng": "Cancel",
        "ukr": "Відмінити"
    },
    "continue": {
        "ru": "Продолжить",
        "eng": "Continue",
        "ukr": "Продовжити"
    },
    "remove_group": {
        "ru": "Удалить группу",
        "eng": "Remove group",
        "ukr": "Видалити групу"
    },
    "save": {
        "ru": "Сохранить",
        "eng": "Save",
        "ukr": "Зберегти"
    },
    "selectVehicles": {
        "ru": "Выберите транспортные средства",
        "eng": "Select vehicles",
        "ukr": "Оберіть транспортні засоби"
    },
    "selectVehicle": {
        "ru": "Выберите транспортное средство",
        "eng": "Select vehicle",
        "ukr": "Оберіть транспортний засіб"
    },
    "giveTask": {
        "ru": "Дать задание",
        "eng": "Give task",
        "ukr": "Дати завдання"
    },
    "active": {
        "ru": "Активные",
        "eng": "Active",
        "ukr": "Активні"
    },
    "planned": {
        "ru": "Запланированые",
        "eng": "Planned",
        "ukr": "Заплановані"
    },
    "completed": {
        "ru": "Завершенные",
        "eng": "Completed",
        "ukr": "Закінчені"
    },
    "draft": {
        "ru": "Черновики",
        "eng": "Draft",
        "ukr": "Чорновики"
    },
    "templates": {
        "ru": "Шаблоны",
        "eng": "Templates",
        "ukr": "Шаблони"
    },
    "geozones_and_addresses": {
        "ru": "Адреса и геозоны",
        "eng": "Adresses and geozones",
        "ukr": "Адреси та геозони"
    },
    "apply": {
        "ru": "Применить",
        "eng": "Apply",
        "ukr": "Застосувати"
    },
    "anytime": {
        "ru": "Любое время",
        "eng": "Anytime",
        "ukr": "У будь-який час"
    },
    "task_type": {
        "ru": "Тип задачи",
        "eng": "Task type",
        "ukr": "Тип задачі"
    },
    "route": {
        "ru": "Маршрут",
        "eng": "Route",
        "ukr": "Маршрут"
    },
    "date_and_time": {
        "ru": "Дата и время",
        "eng": "Date and time",
        "ukr": "Дата та час"
    },
    "beginning_way": {
        "ru": "Начало пути",
        "eng": "Beginning of way",
        "ukr": "Початок шляху"
    },
    "no_data": {
        "ru": "Нет данных",
        "eng": "No data",
        "ukr": "Немає даних"
    },
    "may_be_trouble_internet": {
        "ru": "Возможно у вас проблемы с интернет соединением",
        "eng": "Maybe you have connection problems",
        "ukr": "Можливо у вас проблеми з пілключенням до інтернету"
    },
    "choose_vehicle": {
        "ru": "Выберите ТС",
        "eng": "Choose vehicle",
        "ukr": "Виберіть ТЗ"
    },
    "one_time_assignment": {
        "ru": "Одноразовое задание",
        "eng": "One-time assignment",
        "ukr": "Одноразове завдання"
    },
    "addPoint": {
        "ru": "Добавить точку",
        "eng": "Add point",
        "ukr": "Додати точку"
    },
    "enter_adress_or_geofence": {
        "ru": "Добавить адрес или геозону",
        "eng": "Add adress or geozone",
        "ukr": "Додати абресу або геозону"
    },
    "yesterday": {
        "ru": "Вчера",
        "eng": "Yesterday",
        "ukr": "Учора"
    },
    "last_seven_day": {
        "ru": "Последние семь дней",
        "eng": "Last seven days",
        "ukr": "Останні 7 днів"
    },
    "set_own": {
        "ru": "Задать свой...",
        "eng": "Set your...",
        "ukr": "Задати свій..."
    },
    "fuel": {
        "ru": "Топливо",
        "eng": "Fuel",
        "ukr": "Паливо"
    },
    "voltage": {
        "ru": "Вольтаж",
        "eng": "Voltage",
        "ukr": "Вольтаж"
    },
    "input": {
        "ru": "Ввод",
        "eng": "Input",
        "ukr": "Введення"
    },
    "support_contacts": {
        "ru": "Есть вопрос? Служба поддержки",
        "eng": "Have a question? Support service",
        "ukr": "Є питання? Служба підтримки"
    },
    "stay_in_course": {
        "ru": "Оставайтесь в курсе событий, происходящих с вами",
        "eng": "Stay up to date with what's happening to you",
        "ukr": "Залишайтесь в курсі подій, що відбуваються з вами"
    },
    "notifications": {
        "ru": "Уведомления",
        "eng": "Notifications",
        "ukr": "Повідомлення"
    },
    "password": {
        "ru": "Пароль",
        "eng": "Password",
        "ukr": "Пароль"
    },
    "recieved_sms_notif": {
        "ru": "Получать СМС уведомления",
        "eng": "Reciene SMS notifies",
        "ukr": "Отримувати СМС повідомлення"
    },
    "recieved_email_notif": {
        "ru": "Получать уведомления на почту",
        "eng": "Recieve email notifies",
        "ukr": "Отримувати повідомлення на пошту"
    },
    "recieced_telegram_notif": {
        "ru": "Получать уведомления в телеграм",
        "eng": "Recieve telegram notifies",
        "ukr": "Отримувати поваідомлення в телеграм"
    },
    "adress": {
        "ru": "Адрес",
        "eng": "Adress",
        "ukr": "Адреса"
    },
    "setting": {
        "ru": "Настройка",
        "eng": "Setting",
        "ukr": "Налаштування"
    },
    "mobile_phone_number": {
        "ru": "Номер мобильного телефона",
        "eng": "Mobile phone number",
        "ukr": "Номер мобільного телефону"
    },
    "user_name": {
        "ru": "Имя пользователя",
        "eng": "Username",
        "ukr": "Ім'я користувача"
    },
    "changing_password_title": {
        "ru": "Cмена пароля для",
        "eng": "Changing password for",
        "ukr": "Зміна пароля для"
    },
    "current_pass": {
        "ru": "Текущий пароль",
        "eng": "Current password",
        "ukr": "Поточний пароль"
    },
    "new_pass": {
        "ru": "Новый пароль",
        "eng": "New password",
        "ukr": "Новий пароль"
    },
    "change": {
        "ru": "Изменить",
        "eng": "Change",
        "ukr": "Змінити"
    },
    "current": {
        "ru": "текущий",
        "eng": "current",
        "ukr": "поточний"
    },
    "open_date": {
        "ru": "открытая дата",
        "eng": "open date",
        "ukr": "відкрита дата"
    },
    "here_you_can_change_pass": {
        "ru": "Здесь вы мождете сменить ваш пароль для входа в систему",
        "eng": "Here you can change password for enter to system",
        "ukr": "Тут ви можете змінити ваш пароль для входу у систему"
    },
    "in-system": {
        "ru": "Внутри системы",
        "eng": "In system",
        "ukr": "У системі"
    },
    "on_email": {
        "ru": "На почту",
        "eng": "On email",
        "ukr": "На пошту"
    },
    "sms_notif": {
        "ru": "СМС уведомлдения",
        "eng": "SMS notifies",
        "ukr": "СМС повідомлення"
    },
    "telegram_notif": {
        "ru": "Telegram-уведомления",
        "eng": "Telegram-notifications",
        "ukr": "Telegram-повідомлення"
    },
    "signal": {
        "ru": "Сигнал",
        "eng": "Signal",
        "ukr": "Сигнал"
    },
    "more": {
        "ru": "Более",
        "eng": "More",
        "ukr": "Більше"
    },
    "fueling": {
        "ru": "Заправки",
        "eng": "Fueling",
        "ukr": "Заправки"
    },
    "maybe_discharge": {
        "ru": "Возможные сливы топлива",
        "eng": "Possibly discharge fuel",
        "ukr": "Можливі зливи палива"
    },
    "consumption_average": {
        "ru": "Расход топлива на 100км выше среднего",
        "eng": "Fuel consumption at 100 km above average",
        "ukr": "Витрати палива на 100км вище середнього"
    },
    "lack_signal": {
        "ru": "Отсутствие сигнала",
        "eng": "Lack of signal",
        "ukr": "Відсутність сигналу"
    },
    "event_in_way": {
        "ru": "События в пути",
        "eng": "Events in way",
        "ukr": "Події під час шляху"
    },
    "stop_vs_engine_on": {
        "ru": "Стоянки с включённым двигателем",
        "eng": "Stopped with engine in-gear",
        "ukr": "Стоянки з увімкненим двигуном"
    },
    "over_speed": {
        "ru": "Превышение скорости",
        "eng": "Overspeed",
        "ukr": "Перевищення швидкості"
    },
    "dangerous_temper": {
        "ru": "Опасное превышение температуры двигателя",
        "eng": "Dangerous increase in engine temperature",
        "ukr": "Небезпечне перевищення температури двигуна"
    },
    "dangerous_voltage": {
        "ru": "Опсаное повышение напряжения бортсети",
        "eng": "Dangerous increase in bortnetwork voltage",
        "ukr": "Небезпечне перевищення напруги бортсіті"
    },
    "start_task": {
        "ru": "Начало задания",
        "eng": "Start of task",
        "ukr": "Початок завдання"
    },
    "end_task": {
        "ru": "Конец задания",
        "eng": "End of task",
        "ukr": "Кінець завдання"
    },
    "deviation_route": {
        "ru": "Отклонение от маршрута",
        "eng": "Deviation from the route",
        "ukr": "Відхилення від маршруту"
    },
    "choose_events_what_you_want_receive_on_email": {
        "ru": "Выберите события, если хотите получать уведомления о них на свой электронный адрес.",
        "eng": "Choose events if you want recieve emails with notifies",
        "ukr": "Виберіть події, якщо хочете отримувати повідомлення про них на свою електронну пошту"
    },
    "to reciece": {
        "ru": "Чтобы получать уведомления на ваш номер телефона, пожалуйста,",
        "eng": "To receive notifications on your phone number, please,",
        "ukr": "Щоб отримувати повідомлення на ваш номер телефону, будь-ласка, "
    },
    "indicate_your_number": {
        "ru": "укажите свой номер",
        "eng": "indicate your number",
        "ukr": "вкажіть свій номер"
    },
    "and allow": {
        "ru": "и разрешите СМС уведомления",
        "eng": "and allow SMS notifies",
        "ukr": "та дозвольте СМС повідомлення"
    },
    "settings": {
        "ru": "Настройки",
        "eng": "Settings",
        "ukr": "Налаштування"
    },
    "show_all": {
        "ru": "Показать всё",
        "eng": "Show all",
        "ukr": "Показати все"
    },
    "tuning_notif": {
        "ru": "Настройка уведомлений",
        "eng": "Tuning notifications",
        "ukr": "Налаштування повідомлень"
    },
    "add_photo": {
        "ru": "Добавить фото",
        "eng": "Add photo",
        "ukr": "Додати фото"
    },
    "change_photo": {
        "ru": "Изменить фото",
        "eng": "Change photo",
        "ukr": "Змінити фото"
    },
    "profile_vehicles": {
        "ru": "Профиль транспортного средства",
        "eng": "Vehicle profile",
        "ukr": "Профіль траспортного засобу"
    },
    "parameters": {
        "ru": "Параметры",
        "eng": "Parametrs",
        "ukr": "Параметри"
    },
    "counters": {
        "ru": "Счётчики",
        "eng": "Counters",
        "ukr": "Лічильники"
    },
    "current_level_fuel": {
        "ru": "Текущий уровень топлива",
        "eng": "Current fuel level",
        "ukr": "Поточний рівень палива"
    },
    "rate_of_fuel": {
        "ru": "Норма расхода топлива (на 100 км)",
        "eng": "The rate of fuel consumption (per 100 km)",
        "ukr": "Норма витрат палива (на 100 км)"
    },
    "l": {
        "ru": "л",
        "eng": "l",
        "ukr": "л"
    },
    "L": {
        "ru": "Л",
        "eng": "L",
        "ukr": "Л"
    },
    "voltage_board_network": {
        "ru": "Напряжение бортсети",
        "eng": "Voltage board network",
        "ukr": "Напруга бортсіті"
    },
    "current_voltage": {
        "ru": "Текущее напряжение",
        "eng": "Current voltage",
        "ukr": "Поточна напруга"
    },
    "normal_voltage": {
        "ru": "Нормальное напряжение",
        "eng": "Normal voltage",
        "ukr": "Нормальна напруга"
    },
    "v": {
        "ru": "В",
        "eng": "V",
        "ukr": "В"
    },
    "motor_temperature": {
        "ru": "Температура двигателя",
        "eng": "Engine temperature",
        "ukr": "Температура двигуна"
    },
    "current_temperature": {
        "ru": "Текущая температура",
        "eng": "Current temperature",
        "ukr": "Поточна температура"
    },
    "normal_temperature": {
        "ru": "Нормальная температура",
        "eng": "Normal temperature",
        "ukr": "Нормальна температура"
    },
    "other_parametrs": {
        "ru": "Другие параметры",
        "eng": "Other paramets",
        "ukr": "Інші параметри"
    },
    "mileage": {
        "ru": "Пробег",
        "eng": "Mileage",
        "ukr": "Пробіг"
    },
    "km": {
        "ru": "км",
        "eng": "km",
        "ukr": "км"
    },
    "carrying": {
        "ru": "Грузоподъёмность",
        "eng": "Carrying",
        "ukr": "Вантажопідйомність"
    },
    "tons": {
        "ru": "тонн",
        "eng": "tons",
        "ukr": "тон"
    },
    "fuel_tank_volume": {
        "ru": "Объём бака",
        "eng": "Fuel tank volume",
        "ukr": "Об'єм бака"
    },
    "change_of_oil": {
        "ru": "Замена масла",
        "eng": "Oil change",
        "ukr": "Заміна масла"
    },
    "disable_counter": {
        "ru": "Отключить счётчик",
        "eng": "Disable counter",
        "ukr": "Відключити лічильник"
    },
    "enable_counter": {
        "ru": "Включить счётчик",
        "eng": "Enable counter",
        "ukr": "Увімкнути лічильник"
    },
    "reset_counter": {
        "ru": "Сбросить счётчик",
        "eng": "Reset counter",
        "ukr": "Скинути лічильник"
    },
    "next_maintence": {
        "ru": "Следующее ТО",
        "eng": "Next maintence",
        "ukr": "Наступне ТО"
    },
    "change_rubber": {
        "ru": "Замена резины",
        "eng": "Change rubber",
        "ukr": "Заміна резини"
    },
    "here_will_bee_employee": {
        "ru": "Здесь будет отображаться профиль сотрудника.",
        "eng": "Here will be displayed employee profile.",
        "ukr": "Тут розташований профіль співробітника"
    },
    "please_select_employee": {
        "ru": "Пожалуйста, выберите сотрудника из списка слева или",
        "eng": "Please select an employee from the list on the left or",
        "ukr": "Будь-ласка, віберіть співробітника із списку зліва або"
    },
    "add_new_one": {
        "ru": "добавьте нового",
        "eng": "add a new one",
        "ukr": "додайте нового"
    },
    "tacts": {
        "ru": "Контакты",
        "eng": "Tacts",
        "ukr": "Контакти"
    },
    "work_time": {
        "ru": "Рабочее время",
        "eng": "Work time",
        "ukr": "Робочий час"
    },
    "vehicles": {
        "ru": "Транспортные средства",
        "eng": "Vehicles",
        "ukr": "Транспортні засоби"
    },
    "gas_stations": {
        "ru": "Заправочные станции",
        "eng": "Gas stations",
        "ukr": "Заправочные станции"
    },
    "violations": {
        "ru": "Нарушения",
        "eng": "Violations",
        "ukr": "Порушення"
    },
    "h": {
        "ru": "ч",
        "eng": "h",
        "ukr": "г"
    },
    "date": {
        "ru": "дата",
        "eng": "date",
        "ukr": "дата"
    },
    "timestamp": {
        "ru": "дата",
        "eng": "timestamp",
        "ukr": "дата"
    },
    "location": {
        "ru": "Местоположение",
        "eng": "Location",
        "ukr": "Місцезнаходження"
    },
    "new_employee": {
        "ru": "Новый сторудник",
        "eng": "New employee",
        "ukr": "Новий співробітник"
    },
    "photo_helps": {
        "ru": "Фото поможет Вам быстро отыскать нужного человека",
        "eng": "Photo helps you quickly find the right person",
        "ukr": "Фото допоможе Вам швидко знайти потрібну людину"
    },
    "enter_first_name": {
        "ru": "Введите имя пользователя",
        "eng": "Enter user's first name",
        "ukr": "Введіть ім'я користувача"
    },
    "enter_last_name": {
        "ru": "Введите фамилию пользователя",
        "eng": "Entar user's last name",
        "ukr": "Введіть прізвище користувача"
    },
    "first_name": {
        "ru": "Имя",
        "eng": "First name",
        "ukr": "Ім'я"
    },
    "last_name": {
        "ru": "Фамилия",
        "eng": "Last name",
        "ukr": "Прізвище"
    },
    "add_another_phone": {
        "ru": "Добавить ещё один номер",
        "eng": "Add another phone",
        "ukr": "Додати ще один номер"
    },
    "create_employee": {
        "ru": "Создать сотрудника",
        "eng": "Create employee",
        "ukr": "Створити співробітника"
    },
    "add_employee": {
        "ru": "Добавить сотрудника",
        "eng": "Add employee",
        "ukr": "Додати співробітника"
    },
    "are_you_sure": {
        "ru": "Вы уверены, что хотите удалить ",
        "eng": "Are you sure you want to delete ",
        "ukr": "Ви впевненні, що хочете видалити "
    },
    "this employee": {
        "ru": "этого работника",
        "eng": "this employee",
        "ukr": "цього робітника"
    },
    "remove": {
        "ru": "Удалить",
        "eng": "Remove",
        "ukr": "Видалити"
    },
    "deleting": {
        "ru": "Удалдение",
        "eng": "Deleting",
        "ukr": "Видалення"
    },
    "edit_employee_profile": {
        "ru": "Редактирование профиля сотрудника",
        "eng": "Edit employee profile",
        "ukr": "Редагування профіля співробітника"
    },
    "update_data": {
        "ru": "Обновить данные",
        "eng": "Update data",
        "ukr": "Оновити дані"
    },
    "report_type": {
        "ru": "Тип отчёта",
        "eng": "Report type",
        "ukr": "Тип звіту"
    },
    "here_will_be_report": {
        "ru": "Здесь будет отображаться ваш отчёт",
        "eng": "Here will be displayed your report",
        "ukr": "Тут буде відображено ваш звіт"
    },
    "create_report_on_sidebar": {
        "ru": "Создайте отчёт, используя меню слева. В процессе работы вы сможете обновлять параметры отчёта в реальном времени.",
        "eng": "Create report using menu on left side. In process work you will can update  report configuration in real time",
        "ukr": "Створіть звіт, використовуючи меню зліва. В процессі роботи ви зможете оновлювати параметри звіту в реальному часу."
    },
    "create_own_report": {
        "ru": "Создать свой отчёт",
        "eng": "Create own report",
        "ukr": "Створити свій звіт"
    },
    confirm_text_remove_device: {
        "ru": 'Устройство и все его данные будут удалены с сервера, удалить?',
        "ukr": 'Пристрій та всі його дані будуть видалені з сервера, видалити?',
        "eng": 'The device and all its data will be removed from the server, delete?'
    },
    device_added_text: {
        "ru": number => `Устройство ${number} добавлено`,
        "ukr": number => `Пристрій ${number} додано`,
        "eng":  number => `Device ${number} added`
    },
    command_sent: {
        "ru": command => `команда ${command} отправлена`,
        "ukr": command => `команда ${command} відправлена`,
        "eng":  command => `command ${command} sent`
    },
    auto_added: {
        "ru": 'Авто добавлено',
        "ukr": 'Авто додано',
        "eng":  'Auto added'
    },
    'no_connection': {
        "ru": 'отсутствует соединение',
        "ukr": `відсутнє з'єднання`,
        "eng":  'no connection'
    },
    select_group: {
        "ru": 'Выберите группу',
        "ukr": 'Оберіть групу',
        "eng":  'Select group'
    },
    create_a_new_group: {
        "ru": 'Создание новой группы',
        "ukr": 'Створення нової групи',
        "eng":  'Create a new group'
    },
    groups_in_which_the_car_is: {
        "ru": 'Нету групп в которых состоит авто',
        "ukr": 'Немає груп до яких включено авто',
        "eng":  'There are no groups in which the car is'
    },
    "name_licenceplate_or_group": {
        "ru": "Название, группа авто или ТС",
        "eng": "Name, licence plate vehicle or group",
        "ukr": "Назва, група авто або ТЗ"
    },
    "update_report": {
        "ru": "Обновить отчёт",
        "eng": "Update report",
        "ukr": "Оновити звіт"
    },
    "start_date_must_be_early": {
        "ru": "Дата начала должна быть раньше даты окончания",
        "eng": "Start date must be before end date",
        "ukr": "Дата початку повинна бути раніше ніж дата закінчення"
    },
    "report_parametrs": {
        "ru": "Параметры отчёта",
        "eng": "Report parametrs",
        "ukr": "Параметри звіту"
    },
    "display_order": {
        "ru": "Порядок отображения",
        "eng": "Display order",
        "ukr": "Порядок відображення"
    },
    "choose_enter_parameters": {
        "ru": "Введите название и выберите параметры, которые вы хотите включить в ваш отчет",
        "eng": "Enter a name and select the options that you want to include in your report",
        "ukr": "Введіть назву та виберіть параметри, які ви хочете включити у ваш звіт"
    },
    "report_name": {
        "ru": "Название отчёта",
        "eng": "Report name",
        "ukr": "Назва звіту"
    },
    "start_motion": {
        "ru": "Начало движения",
        "eng": "Start motion",
        "ukr": "Початок руху"
    },
    "end_motion": {
        "ru": "Конец движения",
        "eng": "End motion",
        "ukr": "Кінець руху"
    },
    "time_in_way": {
        "ru": "Время в пути",
        "eng": "Time in way",
        "ukr": "Час в дорозі"
    },
    "max_speed": {
        "ru": "Максимальная скорость",
        "eng": "Maximum speed",
        "ukr": "Максимальна швидкість"
    },
    "average_speed": {
        "ru": "Средняя скорость",
        "eng": "Average speed",
        "ukr": "Середня швидкість"
    },
    "spent_fuel": {
        "ru": "Потрачено топлива",
        "eng": "Spent fuel",
        "ukr": "Витрати палива"
    },
    "average_spent_fuel": {
        "ru": "Средний расход топлива",
        "eng": "Average spent fuel",
        "ukr": "Середні витрати палива"
    },
    "time_parking": {
        "ru": "Время остановки",
        "eng": "Time parking",
        "ukr": "Час зупинки"
    },
    "time_parking_ignition_on": {
        "ru": "Время стоянки с включённым зажиганием",
        "eng": "Time parking with ignition on",
        "ukr": "Час стоянки з увімкненим запалюванням"
    },
    "time_parking_ignition_off": {
        "ru": "Время стоянки с выключенным зажиганием",
        "eng": "Time parking with ignition off",
        "ukr": "Час стоянки з вимкненим запалюванням"
    },
    "time_stop_in_geo": {
        "ru": "Время стоянки в геозоне",
        "eng": "Time stop in geo",
        "ukr": "Час стоянки в геозоні"
    },
    "time_of_entry_in_geo": {
        "ru": "Время въезда в геозону",
        "eng": "Time of entry in geo",
        "ukr": "Час заїзду в геозону"
    },
    "time_from_exit_in_geo": {
        "ru": "Время выезда из геозоны",
        "eng": "Time of exit in geo",
        "ukr": "Час виїзду з геозони"
    },
    "spent_fuel_in_geo": {
        "ru": "Потрачено топлива в геозоне",
        "eng": "Fuel spent in geo",
        "ukr": "Витрачено палива в геозоні"
    },
    "fuel_consumption_in_geo": {
        "ru": "Расход топлива в геозоне",
        "eng": "Fuel consumption im geo",
        "ukr": "Витрати палива в геозоні"
    },
    "distance_about_prev_entering": {
        "ru": "Расстояние от предыдущего въезда",
        "eng": "Distance about previous entering",
        "ukr": "Дистанція від попереднього в'їзду"
    },
    "distance_traveled": {
        "ru": "Пройденный путь",
        "eng": "Traveled distance",
        "ukr": "Пройдений шлях"
    },
    "geofence": {
        "ru": "Геозоны",
        "eng": "Geofence",
        "ukr": "Геозони"
    },
    "stop_parking": {
        "ru": "Остановки/Стоянки",
        "eng": "Stops/Parkings",
        "ukr": "Зупинки/Стоянки"
    },
    "failed_delete_real_sensor": {
        "ru": 'Не удалось удалить реальный сенсор',
        "eng": 'Failed to delete real sensor',
        "ukr": 'Не вдалося видалити реальний сенсор'
    },
    "check_order_display": {
        "ru": "Проверьте порядок отображения символов в таблице и поменяйте колонки местами, если нужно.",
        "eng": "Check the order of the parameters in the table and change the columns in places, if necessary.",
        "ukr": "Перевiрте порядок вiдображення параметрiв в табличцi и помiняйте колонки мicцями, якщо потрiбно."
    },
    "over_cursor_of_interest": {
        "ru": "Наведите курсор на интересуюшую вас колонку и перетяните ее в другое место.",
        "eng": "Hover over the column you are interested in and move it to a different location.",
        "ukr": "Наведiть курсор на цiкавлячу вас колонку та перетягнiть її в iнше мiсце"
    },
    "create_report": {
        "ru": "Создать отчёт",
        "eng": "Create report",
        "ukr": "Створити звіт"
    },
    "pull_to_move": {
        "ru": "Тяните для перемещения",
        "eng": "Pull to move",
        "ukr": "Тягніть для переміщення"
    },
    "your_report_was_created": {
        "ru": "Ваш отчёт успешно создан!",
        "eng": "Your report was successfully created!",
        "ukr": "Ваш звіт успішно створений!"
    },
    "now_you_can_choose": {
        "ru": "Теперь вы можете выбрать его в поле ",
        "eng": "Now you can choose him in field ",
        "ukr": "Тепер ви можете вибрати його в полі "
    },
    "type_of_report": {
        "ru": "Тип отчёта",
        "eng": "Type of report",
        "ukr": "Тип звіту"
    },
    "save_pdf": {
        "ru": "Сохранить в PDF",
        "eng": "Save as PDF",
        "ukr": "Зберегти як PDF"
    },
    "print": {
        "ru": "Печать",
        "eng": "Print",
        "ukr": "Печатка"
    },
    "save_xlsx": {
        "ru": "Сохранить в XLSX",
        "eng": "Save as XLSX",
        "ukr": "Зберегти як XLSX"
    },
    "save_csv": {
        "ru": "Сохранить в CSV",
        "eng": "Save as CSV",
        "ukr": "Зберегти як CSV"
    },
    "no_data_for_period": {
        "ru": "Нет данных за выбранный период",
        "eng": "No data for the selected period",
        "ukr": "Немає даних за обраний період"
    },
    "try_other_period": {
        "ru": "Попробуйте выбрать другие даты.",
        "eng": "Try to choose other dates.",
        "ukr": "Спробуйте вибрати інші дати"
    },
    "whole_period": {
        "ru": "За весь период",
        "eng": "For thw whole period",
        "ukr": "За весь період"
    },
    "period": {
        "ru": "Период",
        "eng": "Period",
        "ukr": "Період"
    },
    "time_in_point": {
        "ru": "Время в точках",
        "eng": "Time in points",
        "ukr": "Час в точках"
    },
    "optional": {
        "ru": "опционально",
        "eng": "optional",
        "ukr": "опціонально"
    },
    "repeatings": {
        "ru": "Повторяющиеся",
        "eng": "Repeatings",
        "ukr": "Повторювані"
    },
    "date_of_statrTask": {
        "ru": "Дата начала задания",
        "eng": "Date of start task",
        "ukr": "Дата початку завдання"
    },
    "no": {
        "ru": "нет",
        "eng": "no",
        "ukr": "ні"
    },
    "before": {
        "ru": "до",
        "eng": "before",
        "ukr": "до"
    },
    "task_created_succsessful": {
        "ru": "Задание успешно создано!",
        "eng": "Task succsessfuly created!",
        "ukr": "Завдання успішно створене!"
    },
    "orienting_resourse_consumption": {
        "ru": "Ориентировочный расход ресурсов",
        "eng": "Orienting resource consumption",
        "ukr": "Орієнтовані витрати ресурсів"
    },
    "without_taking_in_geo": {
        "ru": "без учёта работы в геозонах и стоянки на точках",
        "eng": "without taking into account the work in the geofence and parking in points",
        "ukr": "без врахування роботи в геозонах та стоянки на точках"
    },
    "duration_of_route": {
        "ru": "Протяжённость маршрута",
        "eng": "Duration of route",
        "ukr": "Протяжність маршруту"
    },
    "fuel_consumption": {
        "ru": "Расход топлива",
        "eng": "Fuel consumption",
        "ukr": "Витрати палива"
    },
    "duration": {
        "ru": "Продолжительность",
        "eng": "Duration",
        "ukr": "Протяжність"
    },
    "edit": {
        "ru": "Редактировать",
        "eng": "Edit",
        "ukr": "Редагувати"
    },
    "adresses": {
        "ru": "Адреса",
        "eng": "Adresses",
        "ukr": "Адреси"
    },
    "geofences": {
        "ru": "Геозоны",
        "eng": "Geofences",
        "ukr": "Геозони"
    },
    "search_by_addresses": {
        "ru": "Поиск по адресам",
        "eng": "Search by address",
        "ukr": "Пошук по адресам"
    },
    "search_by_geofences": {
        "ru": "Поиск по геозонам",
        "eng": "Search by geofences",
        "ukr": "Пошук за геозонами"
    },
    "you_dont_have_saved_addresses": {
        "ru": "У вас пока нет сохранённых геозон",
        "eng": "You don't have saved addresses yet",
        "ukr": "У вас поки що немає збережених адрес"
    },
    "add_ofted_used_addres": {
        "ru": "Добавляйте часто используемы адреса, используя меню справа.",
        "eng": "Add addresses what you use often, using menu of right",
        "ukr": "Додавайте часто використовувані адреси за допомогою меню праворуч"
    },
    "you_dont_have_saved_geofences": {
        "ru": "У вас пока нет сохранённых геозон.",
        "eng": "You don't have saved geofences yet.",
        "ukr": "У вас поки що немає збережених геозон."
    },
    "add_geofences_by": {
        "ru": "Добавляйте геозоны, используя меню справа.",
        "eng": "Add geofences using menu on the right",
        "ukr": "Додавайте геозони, використовуючи меню праворуч"
    },
    "add_address": {
        "ru": "Добавить адрес",
        "eng": "Add address",
        "ukr": "Додати адресу"
    },
    "add_geofence": {
        "ru": "Добавить геозону",
        "eng": "Add geofence",
        "ukr": "Додати геозону"
    },
    "new_address": {
        "ru": "Новый адрес",
        "eng": "New address",
        "ukr": "Нова адреса"
    },
    "new_geo": {
        "ru": "Новая геозона",
        "eng": "New geofence",
        "ukr": "Нова геозона"
    },
    "enter-address": {
        "ru": "Введите адрес",
        "eng": "Enter address",
        "ukr": "Введіть адресу"
    },
    "denotation": {
        "ru": "Название",
        "eng": "Denotation",
        "ukr": "Название"
    },
    "this-address": {
        "ru": "этот адрес",
        "eng": "this address",
        "ukr": "ця адреса"
    },
    "this_geofence": {
        "ru": "эту геозону",
        "eng": "this geofence",
        "ukr": "цю геозону"
    },
    "address_or_object": {
        "ru": "Адрес или объект",
        "eng": "Address or onject",
        "ukr": "Адреса або об'єкт"
    },
    "circle": {
        "ru": "Окружность",
        "eng": "Circle",
        "ukr": "Коло"
    },
    "polygon": {
        "ru": "Многоугольник",
        "eng": "Polyhon",
        "ukr": "Багатокутник"
    },
    "color": {
        "ru": "Цвет",
        "eng": "Color",
        "ukr": "Колір"
    },
    "default": {
        "ru": "Обычная",
        "eng": "Default",
        "ukr": "Звичайна"
    },
    "city": {
        "ru": "Город",
        "eng": "City",
        "ukr": "Місто"
    },
    "cornfield": {
        "ru": "Поле",
        "eng": "Cornfield",
        "ukr": "Поле"
    },
    "type_geofence": {
        "ru": "Тип геозоны",
        "eng": "Type of geofence",
        "ukr": "Тип геозони"
    },
    "search_by_templates": {
        "ru": "Поиск по шаблонам",
        "eng": "Search by templates",
        "ukr": "Пошук по шаблонам"
    },
    "you_dont_have_saved_templates": {
        "ru": "У вас нет сохранённых шаблонов",
        "eng": "You don't have saved templates",
        "ukr": "У вас немає збережених шаблонів"
    },
    "add_templates": {
        "ru": "Добавить шаблоны",
        "eng": "Add templates",
        "ukr": "Додати шаблони"
    },
    "hide": {//need change key
        "ru": "Скрыть",
        "eng": "Hide",
        "ukr": "Сховати"
    },
    "hide": {//need change key
        "ru": "Скрывать",
        "eng": "Hide",
        "ukr": "Приховувати"
    },
    "points": {
        "one": {
            "ru": "точку",
            "eng": "point",
            "ukr": "точку"
        },
        "i": {
            "ru": "точки",
            "eng": "points",
            "ukr": "точки"
        },
        "ek": {
            "ru": "точек",
            "eng": "points",
            "ukr": "точок"
        }
    },
    "decline_min": {
        "one": {
            "ru": "мин.",
            "eng": "min.",
            "ukr": "хв."
        },
        "i": {
            "ru": "мин.",
            "eng": "min.",
            "ukr": "хв."
        },
        "ek": {
            "ru": "мин.",
            "eng": "min.",
            "ukr": "хв."
        }
    },
    "decline_hour": {
        "one": {
            "ru": "час",
            "eng": "hour",
            "ukr": "година"
        },
        "i": {
            "ru": "часа",
            "eng": "hours",
            "ukr": "години"
        },
        "ek": {
            "ru": "часов",
            "eng": "hours",
            "ukr": "годин"
        }
    },
    "enter_dates": {
        "ru": "Ввод дат",
        "eng": "Enter dates",
        "ukr": "Введення дат"
    },
    "copying": {
        "ru": "Копирование",
        "eng": "Copying",
        "ukr": "Копіювання"
    },
    "sure_copy_template": {
        "ru": "Вы действительно хотите скопировать этот шаблон?",
        "eng": "Are you sure want copy template?",
        "ukr": "Ви дійсно хочете скопіювати цей шаблон?"
    },
    "copy": {
        "ru": "Копировать",
        "eng": "Copy",
        "ukr": "Скопіювати"
    },
    "do_not_copy": {
        "ru": "Не копировать",
        "eng": "Do not copy",
        "ukr": "Не копіювати"
    },
    "repeat": {
        "ru": "Повторять",
        "eng": "Repeat",
        "ukr": "Повторювати"
    },
    "every_day": {
        "ru": "Каждый день",
        "eng": "Every day",
        "ukr": "Кожен день"
    },
    "every_week": {
        "ru": "Каждую неделю",
        "eng": "Every week",
        "ukr": "Кожен тиждень"
    },
    "every_month": {
        "ru": "Каждый месяц",
        "eng": "Every month",
        "ukr": "Кожен місяць"
    },
    "on_weekdays": {
        "ru": "По будням",
        "eng": "On weekdays",
        "ukr": "По буднях"
    },
    "update_name": {
        "ru": "Обновить название",
        "eng": "Update name",
        "ukr": "Оновити назву"
    },
    "no_active_task": {
        "ru": "Нет активного задания",
        "eng": "No active task",
        "ukr": "Немає активного завдання"
    },
    "no_planned_task": {
        "ru": "Нет запланированных заданий",
        "eng": "No planned tasks",
        "ukr": "Немає запланованих завдань"
    },
    "no_compete_tasks": {
        "ru": "Нет завершенных заданий",
        "eng": "No completed tasks",
        "ukr": "Немає завершених завдань"
    },
    "create_task_for_vehicles": {
        "ru": "Создавайте задания для удобного контроля своих транспортных средств.",
        "eng": "Create the tasks for comfortable monitoring self vehicles.",
        "ukr": "Створюйте завдання для зручного контролю своїх транспортних засобiв."
    },
    "template_was_created": {
        "ru": "Шаблон успешно создан!",
        "eng": "Template succsessfuly created!",
        "ukr": "Шаблон успішно створено!"
    },
    "template_was_edited": {
        "ru": "Шаблон успешно отредактирован!",
        "eng": "Template edited succesfull!",
        "ukr": "Шаблон успiшно вiдредаговано!"
    },
    "column_is_required": {
        "ru": "Колонка является обязательной и не может быть удалена или пемещена",
        "eng": "The column is required and cannot be removed or dragged",
        "ukr": "Стовбець є обов'язковим i не може бути видаленим чi перемiщеним"
    },
    "transport_search": {
        "ru": "поиск транспорта",
        "eng": "transport search",
        "ukr": "пошук транспорту"
    },
    "for_your_request_nodata": {
        "ru": "По вашему запросу нет данных",
        "eng": "No data by your request",
        "ukr": "По вашому запиту немає даних"
    },
    "choose_geofence_or": {
        "ru": "Выберите геозону или",
        "eng": "Choose geofence or",
        "ukr": "Виберіть геозону або"
    },
    "create_new": {
        "ru": "создайте новую",
        "eng": "create new",
        "ukr": "створіть нову"
    },
    "forgot_pass": {
        "ru": "Забыли пароль?",
        "eng": "Forgot your password?",
        "ukr": "Забули пароль?"
    },
    "nothing_choosed": {
        "ru": "Ничего не выбрано",
        "eng": "Nothing choosed",
        "ukr": "Нічого не вибрано"
    },
    "date_of_violation": {
        "ru": "Дата нарушения",
        "eng": "Date of violation",
        "ukr": "Дата порушення"
    },
    "enter_address": {
        "ru": "Введите адрес",
        "eng": "Enter address",
        "ukr": "Введіть адресу"
    },
    "description_of_violation": {
        "ru": "Описание нарушения",
        "eng": "Description of violation",
        "ukr": "Опис порушення"
    },
    "save_violation": {
        "ru": "Сохранить нарушение",
        "eng": "Save violation",
        "ukr": "Зберегти порушення"
    },
    "adding_violation": {
        "ru": "Добавление нарушения",
        "eng": "Add violation",
        "ukr": "Додання порушення"
    },
    "been_blocked": {
        "ru": "Ваш аккаунт заблокирован",
        "eng": "Your account has beed blocked",
        "ukr": "Ваш аккаунт був заблокований"
    },
    "find_reason": {
        "ru": "Для выяснения причины обратитесь по телофонам",
        "eng": "To find out the reason, contact telephones",
        "ukr": "Для з'ясування причини звернiться за телофонами"
    },
    "or_email": {
        "ru": "или по электронной почте",
        "eng": "or by email",
        "ukr": "або по електронній пошті"
    },
    "introduced": {
        "ru": "Ознакомлен",
        "eng": "Introduced",
        "ukr": "Ознайомлений"
    },
    "noGpsPeriod": {
        "ru": "нет gps данных",
        "eng": "no gps data",
        "ukr": "немає gps даних"
    },
    "noPowerPeriod": {
        "ru": "нет питания",
        "eng": "no power",
        "ukr": "немає живлення"
    },
    "daily_traffic": {
        "ru": "Движение по дням",
        "eng": "Daily traffic",
        "ukr": "Рух по дням"
    },
    "logical_inputs": {
        "ru": "Логические выводы",
        "eng": "Logical inputs",
        "ukr": "Логічні виводи"
    },
    "stops_report": {
        "ru": "Отчёт по остановкам",
        "eng": "Report of stops",
        "ukr": "Звіт по зупинкам"
    },
    "save_and_continue": {
        "ru": "Сохранить и продолжить",
        "eng": "Save and continue",
        "ukr": "Зберегти та продовжити"
    },
    "alloted_show_place": {
        "ru": "Выделенная область будет показыватся на месте аватарки",
        "eng": "Allotted zone will be show on avatar place",
        "ukr": "Виділена зона буде показано на місці аватару"
    },
    "daily_fuel_level": {
        "ru": "Уровень топлива по дняи",
        "eng": "Daily fuel level",
        "ukr": "Рівень палива до дням"
    },
    "daily_consumption_hundred_km": {
        "ru": "Дневные траты топлива на 100км",
        "eng": "Daily consumption on 100km",
        "ukr": "Щоденні витрати палива на 100км"
    },
    "daily_fuel_consumption": {
        "ru": "Суточный расход топлива",
        "eng": "Daily fuel consumption",
        "ukr": "Добові витрати палива"
    },
    "fueled_at_day": {
        "ru": "Всего заправлено",
        "eng": "Fueled at day",
        "ukr": "Усього заправлено"
    },
    "de_fueled_at_day": {
        "ru": "Всего возможно слито",
        "eng": "Maybe fuel drained",
        "ukr": "Усього можливо злито"
    },
    "fuel_level_srart": {
        "ru": "Уровень топлива вначале суток",
        "eng": "Fuel level on start of day",
        "ukr": "Рівень палива на початку доби"
    },
    "fuel_level_end": {
        "ru": "Уровень топлива в конце суток",
        "eng": "Fuel level on end of day",
        "ukr": "Рівень палива в кінці доби"
    },
    "daily_ignition_on_duration": {
        "ru": "Время с включённым зажиганием",
        "eng": "Time with working ignition",
        "ukr": "Час з увімкненим запалюванням"
    },
    "daily_consumption_hour": {
        "ru": "Расход топлива за час",
        "eng": "Fuel consume on hour",
        "ukr": "Витрати палива за годину"
    },
    "mileage_at_day": {
        "ru": "Расстояние за сутки",
        "eng": "Mileage at day",
        "ukr": "Відстань за добу"
    },
    "summary_de_fueled": {
        "ru": "Вероятное количество слитого топлива",
        "eng": "Maybe fuel drained",
        "ukr": "Можливо злито палива"
    },
    "summary_fuel_spent": {
        "ru": "Всего израсходовано топлива",
        "eng": "Scope fuel consumed",
        "ukr": "Всього витрачено палива"
    },
    "summary_fuelled": {
        "ru": "Всего заправлено топлива",
        "eng": "Scope fuel fuelled",
        "ukr": "Усього заправлено палива"
    },
    "summary_mileage": {
        "ru": "Общее пройденное расстояние",
        "eng": "Summary mileage",
        "ukr": "Загальна пройдена відстань"
    },
    "ignition_on_duration": {
        "ru": "Общее время с включённым зажиганием",
        "eng": "Summary time with working ignition",
        "ukr": "Загальний час з працюючис запалюванням"
    },
    "delta": {
        "ru": "длительность",
        "eng": "duration",
        "ukr": "тривалість"
    },
    "dist": {
        "ru": "дистанция от предыдущей остановки",
        "eng": "distance from previous stop",
        "ukr": "дистанція від попередньої зупинки"
    },
    "time_begin": {
        "ru": "начало",
        "eng": "start",
        "ukr": "початок"
    },
    "time_end": {
        "ru": "конец",
        "eng": "end",
        "ukr": "кінець"
    },
    "arrival_time": {
        "ru": "Время прибытия",
        "eng": "Arrival time",
        "ukr": "Час прибуття"
    },
    "departure_time": {
        "ru": "Время отбытия",
        "eng": "Departure time",
        "ukr": "Час відбуття"
    },
    "complete_prev-step": {
        "ru": "Заполните предыдущий этап",
        "eng": "Complete the previous step",
        "ukr": "Заповніть попередній крок"
    },
    "maximum_speed": {
        "ru": "Максимальная скорость движения при определении сливов и заправок",
        "eng": "Maximum speed when calculating defueling and refuelling",
        "ukr": "Максимальна швидкість руху при визначенні зливів та заправок"
    },
    "min_volume": {
        "ru": "Минимальный объём заправки и слива",
        "eng": "Minimum fill and drain volume",
        "ukr": "Мінімальний об'єм заправки та зливу"
    },
    "maximum_duration": {
        "ru": "Пропускать скачки менее чем секунд",
        "eng": "Skip jumps that are below a second",
        "ukr": "Пропускати стрибки меньші ніж секунду"
    },
    "tank_volume": {
        "ru": "Объём бака",
        "eng": "Tank volume",
        "ukr": "Об'єм баку"
    },
    "fuel_allowable_fluctuation": {
        "ru": "Допустимые колебания топлива",
        "eng": "Allowable fuel fluctuation",
        "ukr": "Припустимі коливання палива"
    },
    "minimal_mileage": {
        "ru": "Минимальный пробег, для которого нужно считать средний расход топлива",
        "eng": "Minimum mileage for which the average fuel consumption should be calculated",
        "ukr": "Мінімальний пробіг, для якого потрібно рахувати середні витрати палива"
    },
    "minimal_stop_time": {
        "ru": "Минимальное время остановки (при котором это не будет считаться собитыем стоянки)",
        "eng": "Minimum stopping time (at which this will not be considered a parking event)",
        "ukr": "Мінімальний час зупинки (при якому це не буде вважатися подією стоянки)"
    },
    "average_fuel_consumption": {
        "ru": "Среднее потребление топлива",
        "eng": "Average fuel consumption",
        "ukr": "Середні витрати палива"
    },
    "calinrations": {
        "ru": "Тарировки",
        "eng": "Calibrations",
        "ukr": "Тарування"
    },
    сalibration_mapping_required_field: {
        "ru": "Маппинг тарировки обязательное поле",
        "ukr": "Мапінг тарування обов'язкове поле",
        "eng": "Calibration mapping required field",
    },
    calibration_id_and_start_date_are_required: {
        "ru": "Id тарировки и дата начала, обязательны для заполнения",
        "ukr": "Id таріювання та дата початку, обов'язкові для заповнення",
        "eng": "Calibration ID and start date are required",
    },
    "sound_signal": {
        "ru": "Звук сигнала",
        "eng": "Sound of signal",
        "ukr": "Звук сигналу"
    },
    "didnt_have_vehicle": {
        "ru": "У вас нет транспортных средств",
        "eng": "You don't have vehicles",
        "ukr": "У ва немає транспортних засобів"
    },
    "youDidntSetOldPass": {
        "ru": "Вы не указали старый пароль",
        "eng": "You didn't set old password",
        "ukr": "Ви не ввказали старий пароль"
    },
    "newPassMustBe": {
        "ru": "Новый пароль должен содержать минимум 8 символов и три цифры",
        "eng": "New pass must contain minimum 8 character and must have 3 digit",
        "ukr": "Новий пароль повинен мати мінімум 8 символів та 3 цифри"
    },
    haversin: {
        "ru": "Haversin",
        "eng": "Haversin",
        "ukr": "Haversin"
    },
    standart_haversin: {
        "ru": "Стандартный",
        "eng": "Standart",
        "ukr": "Стандартний"
    },
    "yourPassWasUpdated": {
        "ru": "Ваш пароль успешно обновлён",
        "eng": "Your password successfuly updated",
        "ukr": "Ваш пароль успішно змінено"
    },
    "checkYourOldPass": {
        "ru": "Проверьте старый пароль",
        "eng": "Check your old password",
        "ukr": "Перевірте старий пароль"
    },
    "orientationLocked": {
        "ru": "Ориентация заблокирована",
        "eng": "Orientation locked",
        "ukr": "Орієнтація заблокована"
    },
    "maximum_amount": {
        "ru": "Разница в объеме толпива, которая говорит про слив или заправку",
        "eng": "The difference in the volume of the fuel, which indicates a defueling or refueling",
        "ukr": "Різниця в об'ємі палива, яка свідотствує про злив або заправку"
    },
    logs_search_placeholder: {
        "ru": "Имя, id или серийный номер",
        "eng": "Name, id or serial number",
        "ukr": "Ім'я, ID або серійний номер"
    },
    rfid_cards: {
        "ru": "RFID карты",
        "eng": "RFID cards",
        "ukr": "RFID карти"
    },
    client: {
        "ru":  "Клиент",
        "eng": "Client",
        "ukr": "Клієнт"
    },
    admin: {
        "ru":  "Админ",
        "eng": "Аdmin",
        "ukr": "Адмін"
    },
    //Шапка админки
    "clients": {
        "ru": "Клиенты",
        "eng": "Clients",
        "ukr": "Клієнти"
    },
    "users": {
        "ru": "Пользователи",
        "eng": "Users",
        "ukr": "Користувачі"
    },
    "devices": {
        "ru": "Устройства",
        "eng": "Devices",
        "ukr": "Пристрої"
    },
    "dealers": {
        "ru": "Дилеры",
        "eng": "Dealers",
        "ukr": "Дилери"
    },
    "admins": {
        "ru": "Администраторы",
        "eng": "Admins",
        "ukr": "Адміністратори"
    },
    "calibrations": {
        "ru": "Тарировки",
        "eng": "Calibration",
        "ukr": "Тарування"
    },
    "control": {
        "ru": "Управление",
        "eng": "Control",
        "ukr": "Керування"
    },
    "statistics": {
        "ru": "Статистика",
        "eng": "Statistics",
        "ukr": "Статистика"
    },
    "log": {
        "ru": "Логи",
        "eng": "Logs",
        "ukr": "Логи"
    },
    "updates": {
        "ru": "Обновления",
        "eng": "Updates",
        "ukr": "Оновлення"
    },
    "broadcasts": {
        "ru": "Трансляции",
        "eng": "Broadcasts",
        "ukr": "Трансляції"
    },
    "ignored_devices": {
        "ru": "Игнорируемые устройства",
        "eng": "Ignored devices",
        "ukr": "Ігноровані пристрої"
    },
    "ignored_users": {
        "ru": "Игнорируемые пользователи",
        "eng": "Ignored users",
        "ukr": "Ігноровані користувачі"
    },
    "token_session_statistics": {
        "ru": "Статистика токенов и сессий",
        "eng": "Tokens and sessions statistics",
        "ukr": "Статистика токенів та сесій"
    },
    //Клиенты
    "clients_list": {
        "ru": "Список клиентов",
        "eng": "Clients list",
        "ukr": "Перелік клієнтів"
    },
    "login": {
        "ru": "Логин",
        "eng": "Login",
        "ukr": "Логін"
    },
    "title": {
        "ru": "Название",
        "eng": "Title",
        "ukr": "Назва"
    },
    "dealer": {
        "ru": "Дилер",
        "eng": "Dealer",
        "ukr": "Дилер"
    },
    "blocking_date": {
        "ru": "Дата блокировки",
        "eng": "Block date",
        "ukr": "Дата блокування"
    },
    "blocking": {
        "ru": "Блокировка",
        "eng": "Block",
        "ukr": "Блокування"
    },
    "number_of_strings": {
        "ru": "Количество строк",
        "eng": "Number of strings",
        "ukr": "Кількість рядків"
    },
    "exit": {
        "ru": "Выход",
        "eng": "Exit",
        "ukr": "Вийти"
    },
    "edit_client": {
        "ru": "Редактирование клиента",
        "eng": "Edit client",
        "ukr": "Редагування клієнта"
    },
    "username": {
        "ru": "Название пользователя",
        "eng": "Username",
        "ukr": "Ім'я користувача"
    },
    "change_name": {
        "ru": "Изменить имя",
        "eng": "Change name",
        "ukr": "Змінити ім'я"
    },
    "user_desc": {
        "ru": "Описание пользователя",
        "eng": "User description",
        "ukr": "Опис користувача"
    },
    "current_user_desc": {
        "ru": "Текущее описание пользователя",
        "eng": "Current user desciription",
        "ukr": "Поточний опис користувача"
    },
    "change_description": {
        "ru": "Изменить описание",
        "eng": "Change desciription",
        "ukr": "Змінити опис"
    },
    "user_rights": {
        "ru": "Права пользователя",
        "eng": "User rights",
        "ukr": "Права користувача"
    },
    "change_user_rights": {
        "ru": "Изменить права пользователя",
        "eng": "Change user rights",
        "ukr": "Змінити права користувача"
    },
    "adm_aviable": {
        "ru": "Доступен вход в админку",
        "eng": "Admin panel aviable",
        "ukr": "Дозволен вхід до адмінки"
    },
    "client_message": {
        "ru": "Сообщение клиенту",
        "eng": "Message to client",
        "ukr": "Повідомлення клієнту"
    },
    "change_password": {
        "ru": "Изменить пароль",
        "eng": "Change password",
        "ukr": "Змінити пароль"
    },
    "enter_password": {
        "ru": "Введите пароль",
        "eng": "Enter password",
        "ukr": "Введіть пароль"
    },
    "confirm_password": {
        "ru": "Подтвердите пароль",
        "eng": "Confirm password",
        "ukr": "Підтвердіть пароль"
    },
    "vehicles_list": {
        "ru": "Список транспортных средств",
        "eng": "Vehicles list",
        "ukr": "Перелік транспортних засобів"
    },
    "all": {
        "ru": "Всего",
        "eng": "All",
        "ukr": "Усього"
    },
    "online_on_page": {
        "ru": "На связи со страницы",
        "eng": "Online from page",
        "ukr": "На зв'язку зі сторінки"
    },
    "add_vehicle": {
        "ru": "Добавить транспорт",
        "eng": "Add vehicle",
        "ukr": "Додати транспорт"
    },
    "export": {
        "ru": "Экспорт",
        "eng": "Export",
        "ukr": "Експорт"
    },
    list_of_gas_stations: {
        "ru": "Список заправок",
        "eng": 'List of gas stations',
        "ukr": 'Список заправок'
    },
    "plate_number": {
        "ru": "Номерной знак",
        "eng": "Plate number",
        "ukr": "Номерний знак"
    },
    "transfer_period": {
        "ru": "Период передачи",
        "eng": "Transfer period",
        "ukr": "Період передачі"
    },
    "trailer_control": {
        "ru": "Управление прицепами",
        "eng": "Trailer control",
        "ukr": "Керування причепами"
    },
    "serial_id": {
        "ru": "Serial ID",
        "eng": "Serial ID",
        "ukr": "Serial ID"
    },
    "trailer_type": {
        "ru": "Тип прицепа",
        "eng": "Trailer type",
        "ukr": "Тип причепу"
    },
    "width": {
        "ru": "Ширина",
        "eng": "Width",
        "ukr": "Ширина"
    },
    "add_trailer": {
        "ru": "Добавить прицеп",
        "eng": "Add trailer",
        "ukr": "Додати причеп"
    },
    //Страничка авто
    "type_of_device": {
        "ru": "Тип оборудования",
        "eng": "Device type",
        "ukr": "Тип обладнання"
    },
    "show_new_data": {
        "ru": "Показывать только свежие данные",
        "eng": "Show only new data",
        "ukr": "Показувати тільки нові дані"
    },
    "data_reception_on": {
        "ru": "Приём данных на сервер ",
        "eng": "Data reception on the server is ",
        "ukr": "Прийом даних на сервер "
    },
    "data_reception_off": {
        "ru": "Приём данных на сервер ",
        "eng": "Data reception on the server is ",
        "ukr": "Прийом даних на сервер "
    },
    "data_on_server": {
        "ru": "Последние данные",
        "eng": "Latest data",
        "ukr": "Останні дані"
    },
    "device_status_on_server": {
        "ru": "Статус устройства на сервере",
        "eng": "Device status on server",
        "ukr": "Статус пристрою на сервері"
    },
    "in_touch": {
        "ru": "На связи",
        "eng": "In touch",
        "ukr": "На зв'язку"
    },
    "out_of_touch": {
        "ru": "Не на связи",
        "eng": "Out of touch",
        "ukr": "Не на зв'язку"
    },
    "longtitude": {
        "ru": "Долгота",
        "eng": "Longtitude",
        "ukr": "Довгота"
    },
    "latitude": {
        "ru": "Широта",
        "eng": "Latitude",
        "ukr": "Широта"
    },
    "ignition": {
        "ru": "Зажигание",
        "eng": "Ignition",
        "ukr": "Запалювання"
    },
    "digital_sensors": {
        "ru": "Цифровые датчики",
        "eng": "Digital sensors",
        "ukr": "Ціфрові датчики"
    },
    "analog_sensors": {
        "ru": "Аналоговые датчики",
        "eng": "Analog sensors",
        "ukr": "Аналогові датчики"
    },
    "1_wire_sensors": {
        "ru": "1Wire датчики",
        "eng": "1Wire sensors",
        "ukr": "1Wire датчики"
    },
    "signaling": {
        "ru": "Сигнализация",
        "eng": "Signaling",
        "ukr": "Сигналізація"
    },
    "rfid_card": {
        "ru": "RFID карта",
        "eng": "RFID card",
        "ukr": "RFID картка"
    },
    "re_request_data": {
        "ru": "Перезапрос данных с устройства",
        "eng": "Re-request data from device",
        "ukr": "Перезапит даних з пристрою"
    },
    "reset_authorization": {
        "ru": "Сбросить авторизацию",
        "eng": "Reset authorisation",
        "ukr": "Скинути авторизацію"
    },
    "auto_onwer": {
        "ru": "Владелец авто",
        "eng": "Vehicle owner",
        "ukr": "Власник авто"
    },
    "update_auto_owner": {
        "ru": "Обновить владельца",
        "eng": "Update auto owner",
        "ukr": "Оновити власника авто"
    },
    "data_transfer_period": {
        "ru": "Период передачи данных",
        "eng": "Data transfer period",
        "ukr": "Період передачі даних"
    },
    "current": {
        "ru": "Текущие",
        "eng": "Current",
        "ukr": "Поточні"
    },
    "when_ignition_on": {
        "ru": "При включённом зажигании",
        "eng": "When ignition on",
        "ukr": "При увімкненому запалюванні"
    },
    "when_parking": {
        "ru": "При стоянке",
        "eng": "When parking",
        "ukr": "При стоянці"
    },
    "minimal": {
        "ru": "Минимальные",
        "eng": "Minimal",
        "ukr": "Мінімальні"
    },
    "minimal_ignition_on": {
        "ru": "Минимальные при включённом зажигании",
        "eng": "Minimal when ignition on",
        "ukr": "Мінімальні при увікненому запалюванні"
    },
    "minimal_when_stopped": {
        "ru": "Мнимальные при стоянке",
        "eng": "Minimal ehwn stopped",
        "ukr": "Мінімальні при стоянці"
    },
    "reset_at_midnight": {
        "ru": "Сбросить в полночь до минимальных",
        "eng": "Reset to minimal at midnight",
        "ukr": "Скинути опівночі до мінімальних"
    },
    "change_period": {
        "ru": "Изменить период",
        "eng": "Change period",
        "ukr": "Змінити період"
    },
    "vehicle_number": {
        "ru": "Номер траспортного средства",
        "eng": "Vehicle number",
        "ukr": "Номер транспортного засобу"
    },
    "change_number": {
        "ru": "Изменить номер",
        "eng": "Change number",
        "ukr": "Змінити номер"
    },
    "width_of_vehicle": {
        "ru": "Ширина транспортного средства",
        "eng": "Width of vehicle",
        "ukr": "Ширина траспортного засобу"
    },
    "update_width": {
        "ru": "Обновить ширину",
        "eng": "Update width",
        "ukr": "Оновити ширину"
    },
    "vehicle_groups": {
        "ru": "Группы транспортного средства",
        "eng": "Vehicle groups",
        "ukr": "Групи транспортного засобу"
    },
    "add_to_group": {
        "ru": "Добавить в группу",
        "eng": "Add to group",
        "ukr": "Додати в групу"
    },
    "create_new_group": {
        "ru": "Создать новую группу",
        "eng": "Create new group",
        "ukr": "Створити нову групу"
    },
    "attached_trackers": {
        "ru": "Прикреплённые трекеры",
        "eng": "Attached trackers",
        "ukr": "Під'єднані трекери"
    },
    "commands_to_device": {
        "ru": "Команды активному устройству, сейчас это №",
        "eng": "Commands to active device, now it's №",
        "ukr": "Команди активному пристрою, зараз це №"
    },
    "id_of_period": {
        "ru": "ID периода",
        "eng": "ID o period",
        "ukr": "ID періоду"
    },
    "montage_date": {
        "ru": "Дата установки",
        "eng": "Montage date",
        "ukr": "Дата установки"
    },
    "demontage_date": {
        "ru": "Дата демонтажа",
        "eng": "Demontage date",
        "ukr": "Дата демонтажу"
    },
    "update_timeslot": {
        "ru": "Обновить временной интервал",
        "eng": "Update timeslot",
        "ukr": "Оновити часовий інтервал"
    },
    "add_new_tracker": {
        "ru": "Добавить новый трекер",
        "eng": "Add new tracker",
        "ukr": "Додати новий трекер"
    },
    "virtyal_types_tracker": {
        "ru": "Виртуальные типы датчиков",
        "eng": "Virtyal types of trackers",
        "ukr": "Віртуальні типи датчиків"
    },
    "real_period_sensors": {
        "ru": "Реальные датчики периода",
        "eng": "Real period sensors",
        "ukr": "Реальні датчики періоду"
    },
    "multisensors": {
        "ru": "Мультисенсоры",
        "eng": "Multisensors",
        "ukr": "Мультісенсори"
    },
    "sensor_templates": {
        "ru": "Шаблоны датчиков",
        "eng": "Sensors templates",
        "ukr": "Шаблони датчиків"
    },
    "template_desc": {
        "ru": "Нажмите на нужный, чтобы использовать на активном устройстве",
        "eng": "Click on the one you want to use on the active device",
        "ukr": "Натисніть на потрібний, щоб використати на активному пристрої"
    },
    "teres_standart": {
        "ru": "Терес стандарт",
        "eng": "Teres standart",
        "ukr": "Терес стандарт"
    },
    "fls_f3": {
        "ru": "ДУТ F3",
        "eng": "FLS F3",
        "ukr": "ДРП F3"
    },
    "fls_f3_72": {
        "ru": "ДУТ F3:датчик 72",
        "eng": "FLS F3:sensor 72",
        "ukr": "ДРП F3:датчик 72"
    },
    "fls_f3_32+33": {
        "ru": "ДУТ F3 32+33",
        "eng": "FLS F3 32+33",
        "ukr": "ДРП F3 32+33"
    },
    "g7": {
        "ru": "G7",
        "eng": "G7",
        "ukr": "G7"
    },
    "can_cargo": {
        "ru": "CAN Грузовой",
        "eng": "CAN Cargo",
        "ukr": "СAN Грузовий"
    },
    "g4/g6": {
        "ru": "G4/G6+27-28",
        "eng": "G4/G6+27-28",
        "ukr": "G4/G6+27-28"
    },
    "can_light": {
        "ru": "CAN Легковой",
        "eng": "CAN Light",
        "ukr": "CAN Легковий"
    },
    "g5": {
        "ru": "G5",
        "eng": "G5",
        "ukr": "G5"
    },
    "bitrek": {
        "ru": "Bitrek: 0,66,67",
        "eng": "Bitrek: 0,66,67",
        "ukr": "Bitrek: 0,66,67"
    },
    "bitrek_fls1": {
        "ru": "Bitrek ДУТ1:112",
        "eng": "Bitrek FLS1:112",
        "ukr": "Bitrek ДРП1:112"
    },
    "bitrek_fls1+2": {
        "ru": "ДУТ1+ДУТ2:112,113",
        "eng": "FLS1+FLS2:112,113",
        "ukr": "ДРП1+ДРП2:112,113"
    },
    "transport_settings": {
        "ru": "Настройки транспорта",
        "eng": "Transport settings",
        "ukr": "Налаштування транспорту"
    },
    "max_speed_fuelling": {
        "ru": "Максимльная скорость для определения заправок/сливов",
        "eng": "Maximum speed for filling/draining detection",
        "ukr": "Максимальна швидкість для визначення заправок/зливів"
    },
    "minimum_filling": {
        "ru": "Минимаьный объём заправки",
        "eng": "Minimum filling volume",
        "ukr": "Мінімальний об'єм заправки"
    },
    "minimum_drain": {
        "ru": "Минимальный объём слива",
        "eng": "Minumum drain volume",
        "ukr": "Мінімальний об'єм зливу"
    },
    "maximum_fuel_return": {
        "ru": "Максимальный время возврата топлива при скачках",
        "eng": "Maximum fuel return time for surges",
        "ukr": "Максимальний час повернення палива при стрибках"
    },
    "maximum_jump_time": {
        "ru": "Минимальный время прыжка топлива кратковременной остановки",
        "eng": "Minimum jump time fuel short stop",
        "ukr": "Мінімальний час стрибка палива при короткочасній зупинці"
    },
    "average_fuel_consumption_100km": {
        "ru": "Среднее потребление топлива на 100км",
        "eng": "Average fuel consumption per 100 km",
        "ukr": "Середні витрати палива на 100км"
    },
    "average_fuel_consumption_1h": {
        "ru": "Среднее потребление топлива за 1 час",
        "eng": "Average fuel consumption per 1 hour",
        "ukr": "Середні витрати палива за 1 годину"
    },
    "do_not_count": {
        "ru": "Не считать расход на 100 км при пробеге менее",
        "eng": "Do not count consumption per 100 km with a mileage of less than",
        "ukr": "Не рахувати витрати на 100км при пробігу меньше ніж"
    },
    "don't_deduct_plumps": {
        "ru": "Не вычитать сливы из потраченого топлива",
        "eng": "Don't deduct plums from spent fuel",
        "ukr": "Не віднімати сливи з витраченого палива"
    },
    "max_allowed_speed": {
        "ru": "Максимально разрешённая скорость",
        "eng": "Maximum permitted speed",
        "ukr": "Максимально дозволена швідкість"
    },
    "parking_with_ignition_on": {
        "ru": "Парковка с включённым зажиганием через",
        "eng": "Parking with the ignition on, after",
        "ukr": "Паркування з увімкненим запалюванням через"
    },
    "connect_fuelling": {
        "ru": "Склеивать заправки менее",
        "eng": " Connect fueling less then",
        "ukr": "Склеювати заправки меньше ніж"
    },
    "crankshaft_rotations": {
        "ru": "Количество вращений коленвала",
        "eng": "Number of crankshaft rotations",
        "ukr": "Кількість обертань колінвалу"
    },
    "fuel_consuption_filter": {
        "ru": "Расход топлива за 1 час для топливного фильтра",
        "eng": "Fuel consumption per hour for fuel filter",
        "ukr": "Витрати палива за 1 годину для паливного фільтра"
    },
    "device_settings": {
        "ru": "Настройки устройства",
        "eng": "Device settings",
        "ukr": "Налаштування пристрою"
    },
    "data_reception_enabled": {
        "ru": "Приём данных включён",
        "eng": "Data reception enabled",
        "ukr": "Прийом даних включений"
    },
    "always_on": {
        "ru": "всегда включено",
        "eng": "always on",
        "ukr": "завжди увімкнено"
    },
    "ugnition_always_on": {
        "ru": "Зажигание всегда включено",
        "eng": "Ignition always on",
        "ukr": "Запалювання завжди увімкнено"
    },
    //Пользователи
    "client": {
        "ru": "Клиент",
        "eng": "Client",
        "ukr": "Клієнт"
    },
    //Список устройств
    "serial_number": {
        "ru": "Серийный номер",
        "eng": "Serial nimber",
        "ukr": "Серійний номер"
    },
    "machine": {
        "ru": "Машина",
        "eng": "Machine",
        "ukr": "Машина"
    },
    "account": {
        "ru": "Аккаунт",
        "eng": "Account",
        "ukr": "Акаунт"
    },
    "comment": {
        "ru": "Комментарий",
        "eng": "Comment",
        "ukr": "Коментар"
    },
    "last_data": {
        "ru": "Последние данные",
        "eng": "Last data",
        "ukr": "Останні дані"
    },
    "status": {
        "ru": "Статус",
        "eng": "Status",
        "ukr": "Статус"
    },
    "add_device": {
        "ru": "Добавить устройство",
        "eng": "Add device",
        "ukr": "Додати пристрій"
    },
    "device": {
        "ru": "Устройство",
        "eng": "Device",
        "ukr": "Пристрій"
    },
    "owner": {
        "ru": "Владелец",
        "eng": "Owner",
        "ukr": "Власник"
    },
    "not_specified": {
        "ru": "не указан",
        "eng": "not specified",
        "ukr": "не вказано"
    },
    "update_date": {
        "ru": "Дата обновления",
        "eng": "Update date",
        "ukr": "Дата оновлення"
    },
    "device_type": {
        "ru": "Тип устройства",
    "eng": "Device type",
        "ukr": "Тип пристрою"
    },
    "authorisation_code": {
        "ru": "Код авторизации устройства",
        "eng": "Device authorisation code",
        "ukr": "Код авторизації пристрою"
    },
    device_is_ON: {
        "ru": "Включено",
        "eng": "Turned on",
        "ukr": "Увімкнено"
    },
    device_is_OFF: {
        "ru": "Выключено",
        "eng": "Turned off",
        "ukr": "Вимкнено"
    },
    "device_status": {
        "ru": "Статус устройства на сервере",
        "eng": "Device status on server",
        "ukr": "Статус пристрою на сервері"
    },
    "sim_number": {
        "ru": "Номер SIM",
        "eng": "SIM Number",
        "ukr": "Номер SIM"
    },
    "sim_number_updated": {
        "ru": "Номер SIM обновлён",
        "eng": "SIM Number updated",
        "ukr": "Номер SIM оновлено"
    },
    "clients_sim": {
        "ru": "SIM Клиента",
        "eng": "Client's SIM",
        "ukr": "SIM клієнта"
    },
    "disconnect": {
        "ru": "Разорвать соединение",
        "eng": "Disconnect",
        "ukr": "Розірвати підключення"
    },
    "enter_command": {
        "ru": "Введите команду",
        "eng": "Enter command",
        "ukr": "Введіть команду"
    },
    "send": {
        "ru": "Отправить",
        "eng": "Send",
        "ukr": "Відправити"
    },
    "get_command_list": {
        "ru": "Получить список команд",
        "eng": "Get commands list",
        "ukr": "Отримати список команд"
    },
    "command_history": {
        "ru": "История команд",
        "eng": "Command history",
        "ukr": "Історія команд"
    },
    "request_date": {
        "ru": "Дата, с которой запросить",
        "eng": "Date from which to request",
        "ukr": "Дата, з якої здйснити запит"
    },
    "ok": {
        "ru": "OK",
        "eng": "OK",
        "ukr": "OK"
    },
    "check_logs": {
        "ru": "Проверить логи по устройству",
        "eng": "Check device's logs",
        "ukr": "Перевырити логи по пристрою"
    },
    "mapping_history": {
        "ru": "История примапливания",
        "eng": "Mapping history",
        "ukr": "Історія примаплювання"
    },
    //Дилеры
    "dealers_list": {
        "ru": "Список дилеров",
        "eng": "Dealers list",
        "ukr": "Перелік дилерів"
    },
    "add_dealer": {
        "ru": "Добавить дилера",
        "eng": "Add dealer",
        "ukr": "Додати дилера"
    },
    "change_dealer": {
        "ru": "Изменить дилера",
        "eng": "Change dealer",
        "ukr": "Змінити дилера"
    },
    "clients_edit": {
        "ru": "Редактирование клиентов",
        "eng": "Editing clients",
        "ukr": "Редагування клієнтів"
    },
    "others_edit": {
        "ru": "Редактирование других",
        "eng": "Editing others",
        "ukr": "Редагування інших"
    },
    "device_edit": {
        "ru": "Редактирование устройства",
        "eng": "Device editing",
        "ukr": "Редагування пристрою"
    },
    "sensors_edit": {
        "ru": "Редактирование датчиков",
        "eng": "Sensors edit",
        "ukr": "Редагування датчиків"
    },
    "vehicles_edit": {
        "ru": "Редактирование транспорта",
        "eng": "Vehicles edit",
        "ukr": "Редагування транспорту"
    },
    "cailbrations_remove": {
        "ru": "Удалять тарировки",
        "eng": "Remove calibrations",
        "ukr": "Видаляти тарування"
    },
    "see_statistics": {
        "ru": "Видеть статистику",
        "eng": "See statistics",
        "ukr": "Бачити статистику"
    },
    "sensor_setup": {
        "ru": "Настройка датчиков",
        "eng": "Sensors setup",
        "ukr": "Налаштування сенсорів"
    },
    "set_blocks": {
        "ru": "Устанавливать блокировки",
        "eng": "Set blocks",
        "ukr": "Встановлювати блокування"
    },
    "blocked": {
        "ru": "Заблокирован",
        "eng": "Blocked",
        "ukr": "Заблокований"
    },
    //Администраторы
    "admins_list": {
        "ru": "Список администраторов",
        "eng": "Admins list",
        "ukr": "Список адміністраторів"
    },
    "add_admin": {
        "ru": "Добавить администратора",
        "eng": "Add admin",
        "ukr": "Додати адміністратора"
    },
    "create_admins": {
        "ru": "Создавать админов",
        "eng": "Create admins",
        "ukr": "Створювати адмінів"
    },
    "trusted_admin": {
        "ru": "Доверенный администратор",
        "eng": "Trusted admin",
        "ukr": "Довірений адміністратор"
    },
    //Тарировки
    "archive_calibration": {
        "ru": "Архивные тарировки",
        "eng": "Archieve calibrations",
        "ukr": "Архівне тарування"
    },
    "device_number": {
        "ru": "Номер устройства",
        "eng": "Device number",
        "ukr": "Номер пристрою"
    },
    "removed": {
        "ru": "Удалено",
        "eng": "Removed",
        "ukr": "Видалено"
    },
    "sensor_number": {
        "ru": "Номер датчика",
        "eng": "Sensor number",
        "ukr": "Номер датчика"
    },
    "auto_id": {
        "ru": "ID авто",
        "eng": "Auto ID",
        "ukr": " ID Авто"
    },
    "coefficient": {
        "ru": "Коэффициент",
        "eng": "Coefficient",
        "ukr": "Коефіцієнт"
    },
    "bias": {
        "ru": "Смещение",
        "eng": "Bias",
        "ukr": "Зміщення"
    },
    "minimum_value": {
        "ru": "Минимальное значение",
        "eng": "Minumum value",
        "ukr": "Мінімальне значення"
    },
    "maximum_value": {
        "ru": "Максимальное значение",
        "eng": "Maximum value",
        "ukr": "Максимальне значення"
    },
    "calibration": {
        "ru": "Тарировка",
        "eng": "Calibration",
        "ukr": "Тарування"
    },
    "calibrations": {
        "ru": "Тарировки",
        "eng": "Calibrations",
        "ukr": "Тарування"
    },
    //Статистика
    "client_login": {
        "ru": "Логин клиента",
        "eng": "Client's login",
        "ukr": "Логін клієнта"
    },
    "client_desc": {
        "ru": "Описание клиента",
        "eng": "Client description",
        "ukr": "Опис клієнта"
    },
    "client_status": {
        "ru": "Стус клиента",
        "eng": "Client status",
        "ukr": "Статус клієнта"
    },
    "all_devices": {
        "ru": "Всего устройств",
        "eng": "All devices",
        "ukr": "Усього пристроїв"
    },
    "were_in_touch": {
        "ru": "Были на связи сегодня",
        "eng": "Were in touch today",
        "ukr": "Були на зв'язку сьогодні"
    },
    "data_reception_disabled": {
        "ru": "Приём данных выключен",
        "eng": "Data reception disabled",
        "ukr": "Прийом даних вимкнено"
    },
    "download_statistics": {
        "ru": "Скачать статистику по актуальным устройствам",
        "eng": "Download statistics for current devices",
        "ukr": "Завантажити статистику по актуальним пристроям"
    },
    //Логи
    "user_actions": {
        "ru": "Действия какого пользователя",
        "eng": "Which user actions",
        "ukr": "Дії якого користувача"
    },
    "log item": {
        "ru": "Предмет логов",
        "eng": "Log item",
        "ukr": "Предмет логів"
    },
    "period_start": {
        "ru": "Начало периода",
        "eng": "Start of period",
        "ukr": "Початок періоду"
    },
    "end_period": {
        "ru": "Конец периода",
        "eng": "End of period",
        "ukr": "Кінець періоду"
    },
    "time": {
        "ru": "Время",
        "eng": "Time",
        "ukr": "Час"
    },
    "operation": {
        "ru": "Операция",
        "eng": "Operation",
        "ukr": "Операція"
    },
    "perfomer": {
        "ru": "Исполнитель",
        "eng": "Performer",
        "ukr": "Виконавець"
    },
    "subject": {
        "ru": "Субъект",
        "eng": "Subject",
        "ukr": "Суб'єкт"
    },
    "subject_id": {
        "ru": "Id субъекта",
        "eng": "Subject Id",
        "ukr": "Id суб'єкта"
    },
    "ip": {
        "ru": "IP",
        "eng": "IP",
        "ukr": "IP"
    },
    //Обновления
    "info_updates": {
        "ru": "Информация про системные обновления",
        "eng": "Information about system updates",
        "ukr": "Інформація про системні оновлення"
    },
    "add_new_notification": {
        "ru": "Добавить новое уведомление",
        "eng": "Add new notification",
        "ukr": "Додати нове повідомлення"
    },
    //Трансляции
    "available_servers": {
        "ru": "Доступные серверы",
        "eng": "Available servers",
        "ukr": "Доступні сервери"
    },
    server_port: {
        "ru": "Порт сервера",
        "eng": "Server Port",
        "ukr": "Порт сервера"
    },
    relay_protocol: {
        "ru": "Протокол ретрансляции",
        "eng": "Relay protocol",
        "ukr": "Протокол ретрансляції"
    },
    "mapped_servers": {
        "ru": "Примапленные серверы",
        "eng": "Mapped servers",
        "ukr": "Примаплені сервери"
    },
    "servers_list": {
        "ru": "Список серверов",
        "eng": "Servers list",
        "ukr": "Перелік серверів"
    },
    "server_name": {
        "ru": "Имя сервера",
        "eng": "Server name",
        "ukr": "Ім'я серверу"
    },
    "host": {
        "ru": "Хост",
        "eng": "Host",
        "ukr": "Хост"
    },
    "port": {
        "ru": "Порт",
        "eng": "Port",
        "ukr": "Порт"
    },
    "protocol": {
        "ru": "Протокол",
        "eng": "Protocol",
        "ukr": "Протокол"
    },
    "broadcast_list": {
        "ru": "Список устройств для ретрансляции",
        "eng": "List of devices for broadcast",
        "ukr": "Перелік пристроїв для ретрасляції"
    },
    "imei": {
        "ru": "imei",
        "eng": "imei",
        "ukr": "imei"
    },
    "servers_for_broadcast": {
        "ru": "Список серверов для трансляции",
        "eng": "List of servers for broadcast",
        "ukr": "Перелік серверів для трансляції"
    },
    //Игнорируемые устройства
    "ignored_devices_list": {
        "ru": "Список игнорируемых устройств",
        "eng": "Ignored devices list",
        "ukr": "Перелік ігнорованих пристроїв"
    },
    "id_in_list": {
        "ru": "ID в списке",
        "eng": "ID in list",
        "ukr": "ID в переліку"
    },
    "date_added": {
        "ru": "Дата добавления",
        "eng": "Date added",
        "ukr": "Дата додання"
    },
    //Игнорируемые пользователи
    "ignored_users_list": {
        "ru": "Список игнорируемых пользователей",
        "eng": "Ignored users list",
        "ukr": "Перелік ігнорованих користувачів"
    },
    //Статистика по токенам и сессиям
    "tokens_session_statistic": {
        "ru": "Статистики по токенам и сессиям",
        "eng": "Tokens and sessions statistics",
        "ukr": "Статистики по токенам та сесіям"
    },
    "number_of_sessions": {
        "ru": "Количество сессий",
        "eng": "Amount of sessions",
        "ukr": "Кількість сесій"
    },
    "number_of_tokens": {
        "ru": "Количество токенов",
        "eng": "Amount of tokens",
        "ukr": "Кількість токенів"
    },
    "login_amount_token": {
        "ru": "Логин - количество токенов",
        "eng": "Login - amount of tokens",
        "ukr": "Логін - кількість токенів"
    },
    "login_amount_sessions": {
        "ru": "Логин - количество сессий",
        "eng": "Login - amount of sessions",
        "ukr": "Логін - кількість сесій"
    },
    "name_dealer_or_desc": {
        "ru": "Имя, дилер или описание клиента",
        "eng": "Name, dealer or description of client",
        "ukr": "Ім'я, дилер або опис клієнта"
    },
    "enter_name": {
        "ru": "Введите имя",
        "eng": "Enter name",
        "ukr": "Введіть ім'я"
    },
    "current_username": {
        "ru": "Текущее имя пользователя",
        "eng": "Current username",
        "ukr": "Поточне ім'я користувача"
    },
    "managed_by_dealers": {
        "ru": "Управляет группа дилеров",
        "eng": "Managed by a group of dealers",
        "ukr": "Керується групою дилерів"
    },
    "view_client_logs": {
        "ru": "Посмотреть логи по клиенту",
        "eng": "View client logs",
        "ukr": "Переглянути логи клінєнта"
    },
    "change_name": {
        "ru": "Изменить имя",
        "eng": "Change name",
        "ukr": "Змінити ім'я"
    },
    "fill_marked_fields": {
        "ru": "Заполните все выделенные поля",
        "eng": "Fill all marked fields",
        "ukr": "Заповніть всі відмічені поля"
    },
    "enter_message_for_client": {
        "ru": "Введите сообщение для клиента",
        "eng": "Enter message to client",
        "ukr": "Введіть повідомлення клієнту"
    },
    "pass_not_match": {
        "ru": "Пароли не совпадают",
        "eng": "Passwords do not match",
        "ukr": "Паролі не збігаються"
    },
    "pass_too_easy": {
        "ru": "Пароль слишком простой (минимум 3 цифры и 5 других символов)",
        "eng": "Password is too easy (minimum 3 numbers and 5 other symbols)",
        "ukr": "Пароль дуже простий (мінімум 3 числа та 5 інших символів)"
    },
    "change_pass": {
        "ru": "Изменение пароля",
        "eng": "Change password",
        "ukr": "Зміна пароля"
    },
    "confirm_pass": {
        "ru": "Подтвердите пароль",
        "eng": "Confirm password",
        "ukr": "Підтвердіть пароль"
    },
    "unlock": {
        "ru": "Разблокировать",
        "eng": "Unlock",
        "ukr": "Розблокувати"
    },
    "block_now": {
        "ru": "Заблокировать сейчас",
        "eng": "Block now",
        "ukr": "Заблокувати зараз"
    },
    "choose_block_date": {
        "ru": "Выберите дату блокировки",
        "eng": "Choose block date",
        "ukr": "Виберіть дату блокування"
    },
    "user_block": {
        "ru": "Блокировка пользователя",
        "eng": "Block user",
        "ukr": "Блокування користувача"
    },
    "block": {
        "ru": "Заблокировать",
        "eng": "Block",
        "ukr": "Заблокувати"
    },
    "block_will_occur": {
        "ru": "Блокировка произойдёт",
        "eng": "Blocking will occur",
        "ukr": "Блокування буде здійснено"
    },
    "ignore_while_cleaning": {
        "ru": "Игнорировать при чистке",
        "eng": "Ignore while cleaning",
        "ukr": "Ігнорувати під час очищення"
    },
    "disable_enter": {
        "ru": "Отключить вход",
        "eng": "Disable enter",
        "ukr": "Вимкнути вхід"
    },
    "allow_enter": {
        "ru": "Разрешить вход",
        "eng": "Allow enter",
        "ukr": "Дозволити вхід"
    },
    "enter_auto_name_or_number": {
        "ru": "Введите имя или серийный номер транспорта",
        "eng": "Enter auto name or serial number",
        "ukr": "Введіть ім'я або серійний номер транспорту"
    },
    "enter_device_number": {
        "ru": "Введите номер устройства",
        "eng": "Enter device number",
        "ukr": "Введіть номер пристрою"
    },
    "add_gas_station": {
        "ru": "Добавить заправку",
        "eng": "Add gas station",
        "ukr": "Додати заправку"
    },
    "ignition_request_accept": {
        "ru": "Запрос на корректировку зажигания принят в обработку",
        "eng": "Request for ignition adjustment has been accepted for processing",
        "ukr": "Запит на корегування запалювання прийнятий в обробку"
    },
    "ignition_request_decline": {
        "ru": "Запрос на корректировку зажигания отклонён",
        "eng": "Request for ignition adjustment has been declined",
        "ukr": "Запит на корегування запалювання відхилено"
    },
    "no_active_device": {
        "ru": "На авто нет активного устройства",
        "eng": "Vehicle don't have active device",
        "ukr": "На авто немає активного пристрою"
    },
    device_data_remove_accept: {
        ru: activeSerial => `Запрос на удаление данных по устройству №${activeSerial} принят в обработку`,
        eng: activeSerial => `Request to delete data on the device №${activeSerial} accepted for processing`,
        ukr: activeSerial => `Запит на видалення даних з пристрою №${activeSerial} прийнятий в обробку`
    },
    "device_data_remove_decline": {
        "ru": "Запрос на удаление данных по устройству отклонён",
        "eng": "Request to delete data on the device declined",
        "ukr": "Запит на видалення даних з пристрою відхилено"
    },
    sensor_data_remove_accept: {
        ru: (variable) => `Запрос на удаление данных по сенсору №${variable} принят в обработку`,
        eng: (variable) => `Request to delete data on the sensor №${variable} accepted for processing`,
        ukr: (variable) => `Запит на видалення даних з сенсора №${variable} прийнято в оброку`
    },
    sensor_data_remove_decline: {
        ru: (variable) => `Запрос на удаление данных по сенсору №${variable} отклонен`,
        eng: (variable) => `Request to delete data on the sensor №${variable} declined`,
        ukr: (variable) => `Запит на видалення даних з сенсора №${variable} відхилено`
    },
    update_speed_request_decline: {
        ru: (variable) => `Запрос на обновление данных по скорости для устройства №${variable} отклонен`,
        eng: (variable) => `Request to delete data on the sensor №${variable} declined`,
        ukr: (variable) => `Запит на оновлення даних швидкості для пристрою №${variable} відхилений`
    },
    update_speed_request_accept: {
        ru: (variable) => `Запрос на обновление данных по скорости для устройства №${variable} принят в обработку`,
        eng: (variable) => `Request to update speed data for device #${variable} accepted for processing`,
        ukr: (variable) => `Запит на оновлення даних швидкості для пристрою №${variable} прийнятий в обробку`
    },
    "gas_station": {
        "ru": "Заправка",
        "eng": "Gas station",
        "ukr": "Заправка"
    },
    "auto": {
        "ru": "Авто",
        "eng": "Vehicle",
        "ukr": "Авто"
    },
    "mounted_trackers": {
        "ru": "Прикреплённые трекеры",
        "eng": "Mounted trackers",
        "ukr": "Встановлені трекери"
    },
    "auto_name": {
        "ru": "Имя авто",
        "eng": "Vehicle name",
        "ukr": "Ім'я авто"
    },
    "name_cannot_be_empty": {
        "ru": "Имя не может быть пустым",
        "eng": "Name cannot be empty",
        "ukr": "Ім'я не може бути порожнім"
    },
    "new_name": {
        "ru": "Новое имя",
        "eng": "New name",
        "ukr": "Нове ім'я"
    },
    "change_name": {
        "ru": "Изменить имя",
        "eng": "Change_name",
        "ukr": "Змінити ім'я"
    },
    "current_desc": {
        "ru": "Текущее описание",
        "eng": "Current description",
        "ukr": "Поточний опис"
    },
    "vehicle_number": {
        "ru": "Номер транспортного средства",
        "eng": "Vehicle number",
        "ukr": "Номер транспортного засобу"
    },
    "enter_vehicle_number": {
        "ru": "Введите номер транспортного средства",
        "eng": "Enter vehicle number",
        "ukr": "Введіть номер транспортного засобу"
    },
    "choose_real_sensors": {
        "ru": "Выберите реальные сенсоры",
        "eng": "Choose real sensors",
        "ukr": 'Виберіть реальні сенсори'
    },
    "fuel_level": {
        "ru": "Уровень топлива",
        "eng": "Fuel level",
        "ukr": "Рівень палива"
    },
    "multisensor_add": {
        "ru": "Добавление мультисенсора",
        "eng": "Add multisensor",
        "ukr": "Додати мультисенсор"
    },
    "enter_virtual_sensor_id": {
        "ru": "Введите id виртуального датчика",
        "eng": "Enter virtual sensor id",
        "ukr": "Введіть id віртуального датчика"
    },
    virtual_sensor_id: {
        "ru": "id виртуального датчика",
        "eng": "virtual sensor id",
        "ukr": "id віртуального датчика"
    },
    "multisensor_name": {
        "ru": "Название мультисенсора",
        "eng": "Multisensor name",
        "ukr": "Назва мультисенсора"
    },
    "operations_sensors_data": {
        "ru": "Операции  с данными от датчиков",
        "eng": "Operations with data from sensors",
        "ukr": "Операції з даними від датчиків"
    },
    "summ": {
        "ru": "Суммировать",
        "eng": "Summarize",
        "ukr": "Підсумувати"
    },
    "subtract": {
        "ru": "Получить разницу",
        "eng": "Subtract",
        "ukr": "Відняти"
    },
    "average": {
        "ru": "Получить среднее",
        "eng": "Get average",
        "ukr": "Отримати середнє"
    },
    "save_multisensor": {
        "ru": "Сохранить",
        "eng": "Save",
        "ukr": "Зберегти"
    },
    "choose_sensor_type": {
        "ru": "Укажите тип датчика",
        "eng": "Choose sensor type",
        "ukr": "Вкажіть тип датчика"
    },
    "are_you_sure_want_to_delete_data": {
        "ru": "Вы уверены что хотите удалить данные с",
        "eng": "Are you sure want to delete data from",
        "ukr": "Ви дійсно хочете видалити дані з"
    },
    "data_range_remove": {
        "ru": "Удаление диапазона данных",
        "eng": "Remove data range",
        "ukr": "Видалення діапазону даних"
    },
    "remove_data": {
        "ru": "Удалить данные",
        "eng": "Remove data",
        "ukr": "Видалити дані"
    },
    "remove_data_cassandra": {
        "ru": "Удаление данных в Кассандре",
        "eng": "Remove data from Cassandra",
        "ukr": "Видалити дані з Касандри"
    },
    "remove_data_from_sensor": {
        "ru": "Удаление данных по датчику",
        "eng": "Remove data from sensor",
        "ukr": "Видалити дані по датчику"
    },
    "sensor_number": {
        "ru": "Номер датчика",
        "eng": "Sensor number",
        "ukr": "Номер датчика"
    },
    "remove_data_from_sensor": {
        "ru": "Удаление данных по датчику",
        "eng": "Remove data from sensor",
        "ukr": "Видалення даних по датчику"
    },
    "tracker_number": {
        "ru": "Номер трекера",
        "eng": "Tracker number",
        "ukr": "Номер трекера"
    },
    "show": {
        "ru": "Показывать",
        "eng": "Show",
        "ukr": "Показувати"
    },

    "device_added_not_connected": {
        "ru": "Устройство добавлено но ещё не подключалось к серверу",
        "eng": "Device added but not yet connected to the server",
        "ukr": "Пристрій додано, але він ще не підключався до серверу"
    },
    "no_data_current_state": {
        "ru": "Нет данных по текущему состоянию устройства",
        "eng": "No data from current device state",
        "ukr": "Немає даних по поточному стану пристрою"
    },
    "updated": {
        "ru": "Обновлён",
        "eng": "Updated",
        "ukr": "Оновлений"
    },
    "recieving_data_server": {
        "ru": "Приём данных на сервер",
        "eng": "Recieving data to the server",
        "ukr": "Прийом даних на сервер"
    },
    "missing": {
        "ru": "Отсутствуют",
        "eng": "Missing",
        "ukr": "Відсутні"
    },
    "transfer_periods": {
        "ru": "Периоды передачи",
        "eng": "Transfer periods",
        "ukr": "Періоди передачі"
    },
    "authorization_codes": {
        "ru": "Коды авторизации",
        "eng": "Authorization codes",
        "ukr": "Коди авторизації"
    },
    "on": {
        "ru": "Включена",
        "eng": "On",
        "ukr": "Увімкнена"
    },
    "off": {
        "ru": "Выключена",
        "eng": "Off",
        "ukr": "Вимкнена"
    },
    "always_on": {
        "ru": "Всегда включено",
        "eng": "Always on",
        "ukr": "Завжди увімкнено"
    },
    "from_sensor": {
        "ru": "От датчика",
        "eng": "From sensor",
        "ukr": "Від датчика"
    },
    "trailer": {
        "ru": "Прицеп",
        "eng": "Trailer",
        "ukr": "Причіп"
    },
    "no_connection": {
        "ru": "Нет связи",
        "eng": "No connection",
        "ukr": "Немає з'єднання"
    },
    "data_reception_on": {
        "ru": "Приём данных включён",
        "eng": "Data reception on",
        "ukr": "Прийом даних увімкнено"
    },
    "yes": {
        "ru": "Да",
        "eng": "Yes",
        "ukr": "Так"
    },
    "no": {
        "ru": "Нет",
        "eng": "No",
        "ukr": "Ні"
    },
    ask_remove_period: {
        ru: (variable) => `Удалить период № ${variable} c этого авто?`,
        eng: (variable) => `Remove period № ${variable} from this vehicle?`,
        ukr: (variable) => `Видалити період № ${variable} з цього авто?`
    },
    "not_mounted": {
        "ru": "Не установлено",
        "eng": "Not mounted",
        "ukr": "Не встановлено"
    },
    "device_mount_date": {
        "ru": "Дата установки устройства",
        "eng": "Device mount date",
        "ukr": "Дата встановлення пристрою"
    },
    "device_unmount_date": {
        "ru": "Дата снятия устройства",
        "eng": "Device unmount date",
        "ukr": "Дата зняття пристрою"
    },
    "remove_period": {
        "ru": "Удалить период",
        "eng": "Remove period",
        "ukr": "Видалити період"
    },
    "more": {
        "ru": "Ещё",
        "eng": "More",
        "ukr": "Ще"
    },
    "edit_sensor_type": {
        "ru": "Редактирование типа датчика",
        "eng": "Edit sensor type",
        "ukr": "Редагування типу датчика"
    },

    delete_sensor_type: {
        ru: (variable) => `Вы уверены, что хотите удалить тип датчика c id ${variable}?`,
        eng: (variable) => `Are you sure want to delete sensor type with id  ${variable}?`,
        ukr: (variable) => `Ви впевнені, що хочете видалити тип датчика з id  ${variable}?`
    },
    "type": {
        "ru": "Тип",
        "eng": "Type",
        "ukr": "Тип"
    },
    "numbers_after_comma": {
        "ru": "Чисел после запятой",
        "eng": "Numbers after comma",
        "ukr": "Чисел після коми"
    },
    "units": {
        "ru": "Единицы измерения",
        "eng": "Units",
        "ukr": "Одиниці вимірювання"
    },
    "visible": {
        "ru": "Видимый",
        "eng": "Visible",
        "ukr": "Видимий"
    },
    "growing": {
        "ru": "Нарастающий",
        "eng": "Growing",
        "ukr": "Наростаючий"
    },
    "name_cannot_be_empty": {
        "ru": "имя не может быть пустым",
        "eng": "name cannot be empty",
        "ukr": "ім'я не може бути порожнім"
    },
    "enter_owner": {
        "ru": "Введите владельца",
        "eng": "Enter owner",
        "ukr": "Введіть власника"
    },
    "connect_user_to_transport": {
        "ru": "Подключить пользователя к управлению транспортом",
        "eng": "Connect user to transport management",
        "ukr": "Підключити користувача до керування транспортом"
    },
    "enter_user_login": {
        "ru": "Введите логин пользователя",
        "eng": "Enter user login",
        "ukr": "Введіть логін користувача"
    },
    "volume": {
        "ru": "Объём",
        "eng": "Volume",
        "ukr": "Об'єм"
    },
    "level_at_the_time": {
        "ru": "Уровень во время установки системы",
        "eng": "Level at the time of system installation",
        "ukr": "Рівень під час встановлення системи"
    },
    "take_into_account_fuel": {
        "ru": "Учитывать расходы по датчику топлива",
        "eng": "Take into account fuel sensor costs",
        "ukr": "Враховувати витрати за датчиком палива"
    },
    "clear_cache_period_azs": {
        "ru": "Очистить кэш за выбранный период для АЗС",
        "eng": "Clear cache from the selected period for gas stations",
        "ukr": "Очистити кеш за вибраний період для АЗС"
    },
    "clear_cache_date": {
        "ru": "Дата с которой очистить",
        "eng": "Date from which to clear",
        "ukr": "Дата з якої очистити"
    },
    "clear_cache_period": {
        "ru": "Очистить кэш за период",
        "eng": "Clear cache for period",
        "ukr": "Очистити кеш за період"
    },
    "clear_cache_month": {
        "ru": "Очистить кэш за текущий месяц",
        "eng": "Clear cache for current month",
        "ukr": "Очистити кеш за поточний місяць"
    },
    "global": {
        "ru": "Глобальная",
        "eng": "Global",
        "ukr": "Глобальна"
    },
    "error": {
        "ru": "Погрешность",
        "eng": "Error",
        "ukr": "Відхилення"
    },
    "scale": {
        "ru": "Масштаб",
        "eng": "Scale",
        "ukr": "Масштаб"
    },
    "created": {
        "ru": "Cоздано",
        "eng": "Сreated",
        "ukr": "Cтворено"
    },
    "ignition_adjustment": {
        "ru": "Корректировка зажигания",
        "eng": "Ignition adjustment",
        "ukr": "Коригування запалювання"
    },
    "are_you_sure_adjustment": {
        "ru": "Вы уверены, что хотите скорректировать период",
        "eng": "Are you sure you want to adjust the period",
        "ukr": "Ви впевнені що хочете скорегувати період"
    },
    "mount_unmount_date": {
        "ru": "Дата монтажа не может быть раньше даты демонтажа",
        "eng": "The installation date cannot be earlier than the dismantling date",
        "ukr": "Дата монтажу не може бути раніше дати демонтажу"
    },
    "tracker_number": {
        "ru": "Номер трекера",
        "eng": "Tracker number",
        "ukr": "Номер трекеру"
    },
    "mount_date": {
        "ru": "Дата установки устройства",
        "eng": "Device mount date",
        "ukr": "Дата встановлення пристрою"
    },
    "unmount_date": {
        "ru": "Дата снятия устройства",
        "eng": "Device unmount date",
        "ukr": "Дата зняття пристрою"
    },
    "copy_sensors_from_previous": {
        "ru": "Копирование датчиков с предыдущего устройства",
        "eng": "Copy sensors from previous device",
        "ukr": "Копіювання датчиків з попереднього пристрою"
    },
    "save_settings": {
        "ru": "Сохранить настройки",
        "eng": "Save settings",
        "ukr": "Зберегти налаштування"
    },
    "enter_name_or_id": {
        "ru": "Введите имя или id сокета реального сенсора",
        "eng": "Enter name or id of real sensor socket",
        "ukr": "Введіть ім'я або id сокету реального сенсора"
    },
    "device_list": {
        "ru": "Список устройств",
        "eng": "Device list",
        "ukr": "Перелік пристроїв"
    },
    "device_list_empty": {
        "ru": "Список устройств пуст",
        "eng": "Device list is empty",
        "ukr": "Перелік пристроїв порожній"
    },
    "eqipment_binding": {
        "ru": "Привязка оборудования",
        "eng": "Equipment binding",
        "ukr": "Прив'язка обладнання"
    },
    "adding_calibration": {
        "ru": "Добавление тарировки",
        "eng": "Adding a calibration",
        "ukr": "Додавання тарування"
    },
    "update_calibration": {
        "ru": "Обновление - название",
        "eng": "Update - title",
        "ukr": "Оновлення - назва"

    },
    update: {
        "ru": "Обновление",
        "eng": "Update",
        "ukr": "Оновлення"

    },
    invert_values: {
        "ru": "Инвертировать значения",
        "eng": "Invert values",
        "ukr": "Інвертувати значення"
    },
    absent: {
        "ru": 'Отсутствуют',
        "eng": "absent",
        "ukr": 'Відсутні'
    },
    "completed_calibration_save": {
        "ru": "Завершено сохранения тарировки",
        "eng": "Completed calibration save",
        "ukr": "Завершено збереження тарування"
    },
    "name_and_sensor": {
        "ru": "Название и датчик",
        "eng": "Name and sensor",
        "ukr": "Назва та датчик"
    },
    "enter_coefficient": {
        "ru": "Введите коэффициент",
        "eng": "Enter coefficient",
        "ukr": "Введіть коефіцієнт"
    },
    enter_offset: {
        "ru": "Введите cмещение",
        "eng": "Enter offset",
        "ukr": "Введіть зміщення"
    },
    enter_minimum_value: {
        "ru": "Введите минимальное значение",
        "eng": "Enter the minimum value",
        "ukr": "Введіть мінімальне значення"
    },
    enter_maximum_value: {
        "ru": "Введите максимальное значение",
        "eng": "Enter the maximum value",
        "ukr": "Введіть максимальне значення"
    },
    model_fuel_sensor: {
        "ru": "Модель топливного датчика",
        "eng": "Fuel gauge model",
        "ukr": "Модель паливного датчика"
    },
    unknown: {
        "ru": "Неизвестно",
        "eng": "unknown",
        "ukr": "Невідомо"
    },
    model_dut: {
        "ru": "Модель ДУТ",
        "eng": "FLS model",
        "ukr": "Модель ДУТ"

    },
    arm: {
        "ru": "Стрела",
        "eng": "Arm",
        "ukr": "Стріла"

    },
    offset_factor_or_table: {
        "ru": "Коэффициент с оффсетом или таблица",
        "eng": "Offset factor or table",
        "ukr": "Коефіцієнт з офсетом чи таблиця"
    },
    enter_the_volume_min_:{
        "ru": "Введите объем (минимум два значения, каждая пара значена с новой строки)",
        "eng": "Enter the volume (at least two values, each pair of values with a new line)",
        "ukr": "Введіть обсяг (щонайменше два значення, кожна пара значень з нового рядка)"
    },
    inverted: {
        "ru": "Инвертировано",
        "eng": "Inverted",
        "ukr": "Інвертовано"
    },
    not_inverted: {
        "ru": "Не инвертировано",
        "eng": "Not inverted",
        "ukr": "Не інвертовано"
    },
    IMEI_device: {
        "ru": 'IMEI устройства',
        "eng": 'IMEI device',
        "ukr": 'IMEI пристрою'
    },
    id_real_and_date_are_required: {
        "ru": "ID реального сенсора и дата начала, обязательны для заполнения",
        "eng": "Real sensor ID and start date, required",
        "ukr": "Id реального сенсора та дата початку, обов'язкові для заповнення"
    },
    calibration_at_the_end_or_current_period: {
        "ru": 'Добавление калибровки в конец прошлого или текущего периода',
        "eng": 'Add calibration at the end of the past or current period',
        "ukr": 'Додавання калібрування до кінця минулого або поточного періоду'
    },
    date_end_are_wrong: {
        "ru": "Вы неверно заполнили дату окончания",
        "eng": "You entered an incorrect end date",
        "ukr": "Ви невірно заповнили дату закінчення"
    },
    are_you_sure_remove_multi_sensor: {
        "ru": multiSensorId => `Вы уверены что хотите удалить мультисенсор - ${multiSensorId}?`,
        "eng": multiSensorId => `Are you sure you want to remove the multisensor - ${multiSensorId}?`,
        "ukr": multiSensorId => `Ви впевнені, що хочете видалити мультисенсор - ${multiSensorId}?`
    },
    are_you_sure_remove_real_sensor: {
        "ru": sensorNum => `Вы уверены что хотите удалить реальный сенсор - ${sensorNum} из мапинга?`,
        "eng": sensorNum=> `Ви впевнені, що хочете видалити реальний сенсор - ${sensorNum} з мапінгу?`,
        "ukr": sensorNum => `Are you sure you want to remove the real sensor - ${sensorNum} from the mapping?`
    },

    "completed_realsensor_save": {
        "ru": "Завершено сохранение реального сенсора-сокета",
        "eng": "Completed real sensor-socker save",
        "ukr": "Завершено збереження реального сенсора-сокета"
    },
    "completed_virtualsensor_save": {
        "ru": "Завершено сохранение вирутального сенсора",
        "eng": "Completed virtual sensor save",
        "ukr": "Завершено збереження віртуального сенсору"
    },
    "install_pattern": {
        "ru": "Установить шаблон",
        "eng": "Install pattern",
        "ukr": "Встановити шаблон"
    },
    "install_standart_config": {
        "ru": "Установка стандартной конфигурации",
        "eng": "Install standart config",
        "ukr": "Встановити стандартну конфігурацію"
    },
    "install": {
        "ru": "Установить",
        "eng": "Install",
        "ukr": "Встановити"
    },
    "install_pattern_complete": {
        "ru": "Установка шаблона завершена",
        "eng": "Pattern install competed",
        "ukr": "Встановлення шаблону завершено"
    },
    "installing_patern": {
        "ru": "Идёт установка шаблона",
        "eng": "Installing pattern",
        "ukr": "Встановлюється шаблон"
    },
    "success": {
        "ru": "Успешно",
        "eng": "Success",
        "ukr": "Успішно"
    },
    "failure": {
        "ru": "Неудача",
        "eng": "Failure",
        "ukr": "Невдача"
    },
    "are_you_sure_want_delete_trailer": {
        "ru": "Вы уверены, что хотите удалить прицеп",
        "eng": "Are you sure want to delete trailer",
        "ukr": "Ви впевнені що хочете видалити причіп"
    },
    "enter_trailer_id": {
        "ru": "Введите имя прицепа, серийный номер или тип",
        "eng": "Enter trailer name, serial number or type",
        "ukr": "Введіть ім'я причіпу, серійний номер або тип"
    },
    "add_trailer": {
        "ru": "Добавление прицепа",
        "eng": "Add trailer",
        "ukr": "Додати причіп"
    },
    "trailer_name": {
        "ru": "Название прицепа",
        "eng": "Trailer name",
        "ukr": "Назва причіпу"
    },
    "trailer_serial_number": {
        "ru": "Серийный номер прийцепа",
        "eng": "Trailer serial number",
        "ukr": "Серійний номер причіпу"
    },
    "trailer_width_meters": {
        "ru": "Ширина прицепа в метрах",
        "eng": "Trailer width in meters",
        "ukr": "Ширина причіпу в метрах"
    },
    "transmission_period_day": {
        "ru": "Период передачи дневной в C",
        "eng": "Transmission period daytime in s",
        "ukr": "Період передачі денний в С"
    },
    "transmission_period_night": {
        "ru": "Период передачи ночной в С",
        "eng": "Transmission period nighttime in S"
    },
    "sensor_id": {
        "ru": "Id датчика",
        "eng": "Sensor id",
        "ukr": "Id датчика"
    },
    device_id: {
        "ru": "ID устройства",
        "eng": "Device ID",
        "ukr": "ID пристрою"
    },
    change_sensor: {
        "ru": 'Изменить датчик',
        "eng": 'Change sensor',
        "ukr": 'Змінити датчик'
    },
    "enter_device_id": {
        "ru": "Введите id устройства",
        "eng": "Enter device id",
        "ukr": "Введіть id пристрою"
    },
    "sensor_name": {
        "ru": "Название датчика",
        "eng": "Sensor name",
        "ukr": "Назва датчика"
    },
    "enter_sensor_name": {
        "ru": "Введите название датчика",
        "eng": "Enter sensor name",
        "ukr": "Введіть назву датчика"
    },
    "fuel_consumption_tanker_": {
        "ru": "Расход топлива(заправщик)",
        "eng": "Fuel consumption(tanker)",
        "ukr": "Витрати палива(заправник)"
    },
    "voltage": {
        "ru": "Напряжение",
        "eng": "Voltage",
        "ukr": "Напруга"
    },
    "thermal_sensor": {
        "ru": "Термодатчик",
        "eng": "Thermal sensor",
        "ukr": "Термодатчик"
    },
    "tachometer": {
        "ru": "Тахометр",
        "eng": "Tachometer",
        "ukr": "Тахометр"
    },
    "raw_sensor": {
        "ru": "RAW(простой датчик)",
        "eng": "RAW(simple sensor)",
        "ukr": "RAW(простий датчик)"
    },
    "flow_meter": {
        "ru": "Расходомер",
        "eng": "Flow meter",
        "ukr": "Витратомір"
    },
    "sos_button": {
        "ru": "Кнопка SOS",
        "eng": "SOS button",
        "ukr": "Кнопка SOS"
    },
    "gyroscope": {
        "ru": "Гироскоп",
        "eng": "Gyroscope",
        "ukr": "Гіроскоп"
    },
    "y_axis": {
        "ru": "Ось Y гироскопа",
        "eng": "Y-axis of the gyroscope",
        "ukr": "Вісь y гіроскопа"
    },
    "x_axis": {
        "ru": "Ось X гироскопа",
        "eng": "X-axis of the gyroscope",
        "ukr": "Вісь X гіроскопа"
    },
    "grain_counter": {
        "ru": "Счётчик зерна",
        "eng": "Grain counter",
        "ukr": "Лічильник зерна"
    },
    "hopper_auger": {
        "ru": "Шнек бункера",
        "eng": "Hopper auger",
        "ukr": "Шнек бункера"
    },
    "ammeter": {
        "ru": "Амперметр",
        "eng": "Ammeter",
        "ukr": "Амперметр"
    },
    "voltmeter": {
        "ru": "Вольтметр",
        "eng": "Voltmeter",
        "ukr": "Вольтметр"
    },
    "positioner": {
        "ru": "Позиционер",
        "eng": "Positioner",
        "ukr": "Позиціонер"
    },
    "oil_temperature": {
        "ru": "Температура масла",
        "eng": "Oil temperature",
        "ukr": "Температура масла"
    },
    "water_temperature": {
        "ru": "Температура воды",
        "eng": "Water temperature",
        "ukr": "Температура води"
    },
    "motohours_can": {
        "ru": "Моточасы CAN",
        "eng": "Motohours CAN",
        "ukr": "Мотогодини CAN"
    },
    "reaper": {
        "ru": "Жатка",
        "eng": "Reaper",
        "ukr": "Жниварка"
    },
    "update_info": {
        "ru": "Обновить информацию",
        "eng": "Update information",
        "ukr": "Оновити інформацію"
    },
    "save_data": {
        "ru": "Сохранить данные",
        "eng": "Save data",
        "ukr": "Зберегти дані"
    },
    "add_sensor_type": {
        "ru": "Добавление типа датчика",
        "eng": "Add sensor type",
        "ukr": "Додати тип датчика"
    },
    "allowed_vehicle": {
        "ru": "Разрешенный транспорт клиента",
        "eng": "Allowed client vehicle",
        "ukr": "Дозволений транспорт клієнта"
    },
    "forbidden_vehicle": {
        "ru": "Запрещенный транспорт клиента",
        "eng": "Forbidden client vehicle",
        "ukr": "Заборонений транспорт клієнта"
    },
    "recieving_data": {
        "ru": "Приём данных на сервер",
        "eng": "Recieving data to the server",
        "ukr": "Прийом даних на сервер"
    },
    "enabled": {
        "ru": "Включён",
        "eng": "Enabled",
        "ukr": "Увімкнений"
    },
    "disabled": {
        "ru": "Выключен",
        "eng": "Disabled",
        "ukr": "Вимкнений"
    },
    "from_sensor": {
        "ru": "От датчика",
        "eng": "From sensor",
        "ukr": "Від датчика"
    },
    "notification_text": {
        "ru": "Текст уведомления",
        "eng": "Notification text",
        "ukr": "Текст повідомлення"
    },
    "enter_notifi_text": {
        "ru": "Введите текст уведомления",
        "eng": "Enter notification text",
        "ukr": "Введіть текст поведомлення"
    },
    "enter_admin_name": {
        "ru": "Введите имя администратора",
        "eng": "Enter administrator name",
        "ukr": "Введіть ім'я адміністратора"
    },
    "enter_dealer_name": {
        "ru": "Введите имя дилера",
        "eng": "Enter dealer name",
        "ukr": "Введіть ім'я дилера"
    },
    "enter_login": {
        "ru": "Введите логин",
        "eng": "Enter login",
        "ukr": "Введіть логін"
    },
    "enter_device_number": {
        "ru": "Введите номер устройства",
        "eng": "Enter device number",
        "ukr": "Введіть номер пристрою"
    },
    "incorrect_link": {
        "ru": "Со ссылкой что-то не так, проверьте ссылку или вернитесь обратно",
        "eng": "Link is incorrect, check the link or go back",
        "ukr": "Посилання помилкове, перевірте його або поверніться назад"
    },
    "back_to_control_panel": {
        "ru": "Вернуться в панель управления",
        "eng": "Return to control panel",
        "ukr": "Повернутися до панелі керування"
    },
    "authorize_after_link": {
        "ru": "Вы будете авторизированы после того как введете ссылку",
        "eng": "You will be logged in after you enter the link",
        "ukr": "Ви будете авторизовані після того як введете посилання"
    },
    "insert_link": {
        "ru": "Вставьте сюда ссылку которую нужно открыть, CTRL+V",
        "eng": "Paste here the link you want to open, CTRL+V",
        "ukr": "Вставте сюди посилання, яке потрібно відкрити, CTRL+V"
    },
    "authorization_in_progress": {
        "ru": "Выполняется авторизация...",
        "eng": "Authorization in progress...",
        "ukr": "Виконується авторизація..."
    },
    "current_username": {
        "ru": "Текущее имя пользователя",
        "eng": "Current username",
        "ukr": "Поточне ім'я користувача"
    },
    "managed_by_a_group_of_dealers": {
        "ru": "Управляет группа дилеров",
        "eng": "Managed by a group of dealers",
        "ukr": "Керується групою дилерів"
    },
    "enter_owner_group": {
        "ru": "Введите группу владельца",
        "eng": "Enter owner's group",
        "ukr": "Введіть групу власника"
    },
    "enter_message_for_client": {
        "ru": "Введите сообщения для клиента",
        "eng": "Enter message to client",
        "ukr": "Введіть повідомлення клієнту"
    },
    "pass_not_match": {
        "ru": "Пароли не совпадают",
        "eng": "Passwords not matching",
        "ukr": "Паролі не збігаються"
    },
    "name_dealer_desc": {
        "ru": "Имя, дилер или описание клиента",
        "eng": "Name, dealer or description of client",
        "ukr": "Ім'я, дилер або опис клієнта"
    },
    "go_back": {
        "ru": "вернуться назад",
        "eng": "go back",
        "ukr": "вернуться назад"
    },
    "delete_device_from_server": {
        "ru": "Удалить устройство с сервера",
        "eng": "Delete device from server",
        "ukr": "Видалити пристрій з серверу"
    },
    "come_back": {
        "ru": "вернутся назад",
        "eng": "come back",
        "ukr": "повернутися назад"
    },
    before_remove_sensor:{
        "ru": 'Сначала удалите датчик с устройства',
        "eng": 'Remove the sensor from the device first',
        "ukr": 'Спочатку видаліть датчик із пристрою'
    },

    failed_add_virtual_senspr:{
        "ru": 'не удалось добавить виртуальный датчик, этот тип уже есть',
        "eng": 'failed to add virtual sensor, this type already exists',
        "ukr": 'не вдалося додати віртуальний датчик, цей тип вже є'
    },
    calibration_removed_from_mapping: {
        "ru": 'Калибровка удалена из маппинга',
        "eng": 'Calibration removed from mapping',
        "ukr": 'Калібрування видалено з мапінгу'

    },
    failed_update_calibration: {
        "ru": 'Не удалось обновить тарировку',
        "eng": 'Failed to update calibration',
        "ukr": 'Не вдалося оновити тарування'
    },
    failed_to_update_real_socket: {
        "ru": "не удалось удалось обновить реальный сокет",
        "eng": "failed to update real socket",
        "ukr": "не вдалося оновити реальний сокет"

    },
    failed_update_real_sensor_for_period: {
        "ru": (sensorId, from)  => `Не удалось удалось заменить реальный сенсор ${sensorId} за период ${from}`,
        "eng": (sensorId, from)  => `Failed to replace real sensor ${sensorId} in period ${from}`,
        "ukr": (sensorId, from)  => `Не вдалося замінити реальний сенсор ${sensorId} за ${from}`

    },
    real_sensor_for_virtual_received: {
        "ru": 'реальные датчики для виртуального получены',
        "eng": 'real sensors for virtual received',
        "ukr": 'реальні датчики для віртуального отримані'
    },
    move_device_to_trash: {
        "ru": serialNumber => `Переместить устройство ${serialNumber} в корзину?`,
        "eng": serialNumber => `Move device ${serialNumber} to trash?`,
        "ukr": serialNumber => `Перемістити пристрій ${serialNumber} до кошика?`
    },
    failed_get_real_sensors_for_virtual: {
        "ru": (virtualSensorsId, periodId) => `не удалось получить список реальных сенсоров для виртуальный сенсора ${virtualSensorsId} за период ${periodId}`,
        "eng": (virtualSensorsId, periodId) => `failed to get list of real sensors for virtual sensor ${virtualSensorsId} for period ${periodId}`,
        "ukr": (virtualSensorsId, periodId) => `не вдалося отримати список реальних сенсорів для віртуального сенсора ${virtualSensorsId} за період ${periodId}`
    },
    calibration_period_updated: {
        "ru": 'Период калибровки обновлен',
        "eng": 'Calibration period updated',
        "ukr": 'Період калібрування оновлено'
    },
    calibration_added: {
        "ru": 'Калибровка добавлена',
        "eng": 'Calibration added',
        "ukr": 'Калібрування додано'
    },
    failed_calibration_adding: {
        "ru": 'не удалось добавить калибровку к сокету',
        "eng": 'failed to add calibration to socket',
        "ukr": 'не вдалося додати калібрування до сокету'
    },
    calibration_inserted: {
        "ru": 'Калибровка вставлена',
        "eng": 'Calibration inserted',
        "ukr": 'Калібрування вставлено'
    },
    failed_to_add_calibration_to_socket: {
        "ru": 'не удалось добавить калибровку к сокету',
        "eng": 'failed to add calibration to socket',
        "ukr": 'не вдалося додати калібрування до сокету'
    },
    failed_create_multisensor: {
        "ru": 'не удалось создать мультисенсор, этот виртуальный сенсор уже используется',
        "eng": 'failed to create multisensor, this virtual sensor is already in use',
        "ukr": 'не вдалося створити мультисенсор, цей віртуальний сенсор вже використовується'
    },
    failed_to_connect: {
        "ru": 'не удалось удалось установить связь',
        "eng": 'failed to connect',
        "ukr": "не вдалося встановити зв'язок"
    },
    multisensor_connection_removed: {
        "ru": 'Связь мультисенсора удалена',
        "eng": 'Multisensor connection removed',
        "ukr": `Зв'язок мультисенсора видалена`
    },
    failed_failed_to_remove_link: {
        "ru": 'не удалось удалось удалить связь',
        "eng": 'failed failed to remove link',
        "ukr": "не вдалося видалити зв'язок"
    },
    vehicle_deleted: {
        "ru": 'Авто удалено',
        "eng": 'Vehicle removed',
        "ukr": 'Авто видалено'
    },
    trailer_added: {
        "ru": 'Прицеп добавлен',
        "eng": 'Trailer added',
        "ukr": 'Причіп доданий'
    },
    trailer_removed: {
        "ru": 'Прицеп удален',
        "eng": 'Trailer removed',
        "ukr": 'Причіп видалено'
    },
    tokens_deleted_successfully: {
        "ru": 'Токены успешно удалены',
        "eng": 'Tokens deleted successfully',
        "ukr": 'Токени успішно видалені'
    },

    device_state_not_available: {
        "ru": 'Состояние устройства недоступно',
        "eng": 'Device status unavailable',
        "ukr": 'Стан пристрою недоступний'

    },
    connection_reset: {
        "ru": 'Соединение сброшено',
        "eng": 'Connection reset',
        "ukr": `З'єднання скинуто`

    },
    list_updated: {
        "ru": 'Список обновлен',
        "eng": 'List updated',
        "ukr": 'Список оновлено'

    },
    device_removed_from_transport: {
        "ru": 'Оборудование удалено с транспорта',
        "eng": 'Equipment removed from vehicle',
        "ukr": 'Обладнання видалено з транспорту'

    },
    added_to_device: {
        "ru": 'Добавлен на устройство',
        "eng": 'Added to device',
        "ukr": 'Доданий на пристрій'

    },
    failed_to_add_a_sensor_to_the_device: {
        "ru": 'Не получилось добавить на устройство датчик',
        "eng": 'Failed to add a sensor to the device',
        "ukr": 'Не вдалося додати на пристрій датчик'

    },
    time_period_updated: {
        "ru": 'Временной период обновлен',
        "eng": 'Time period updated',
        "ukr": 'Часовий період оновлено'

    },
    vehicle_owner_can_be_only_client: {
        "ru": 'Владельцем транспорта может быть только клиент, укажите корректный логин клиента',
        "eng": 'The owner of the transport can only be a client, please specify a valid client login',
        "ukr": 'Власником транспорту може бути тільки клієнт, вкажіть коректний логін клієнта'

    },
    your_premission_is_not_sufficient: {
        "ru": 'У вас недостаточно прав для совершения этой операции, обратитесь к администратору',
        "eng": 'You do not have sufficient permissions to perform this operation, please contact your administrator',
        "ukr": 'У вас недостатньо прав для здійснення цієї операції, зверніться до адміністратора'

    },
    userVS_this_login_exist: {
        "ru": 'Пользователь с таким логином существует',
        "eng": 'User with this login exists',
        "ukr": 'Користувач з таким логіном існує'

    },
    period_conflict: {
        "ru": 'Конфликт периодов, проверьте существующие периоды',
        "eng": 'Period conflict, check existing periods',
        "ukr": 'Конфлікт періодів, перевірте існуючі періоди'

    },
    current_real_sensor_oldready_added: {
        "ru": 'Такой реальный сенсор уже добавлен',
        "eng": 'Such a real sensor has already been added',
        "ukr": 'Такий реальний сенсор вже додано'

    },
    period_conflict_text: {
        "ru": 'Конфликт периодов устройства, возможно устройство где то уже используется или в этот период на авто указано другое устройство',
        "eng": 'Device period conflict, perhaps the device is already in use somewhere or another device is indicated on the auto during this period',
        "ukr": 'Конфлікт періодів пристрою, можливо пристрій десь уже використовується або в цей період на авто вказано інший пристрій'

    },
    real_sensor_type_wrong: {
        "ru": 'Тип реальных датчиков не соответствует типу мультисенсора',
        "eng": 'The type of real sensors does not match the type of multisensor',
        "ukr": 'Тип реальних датчиків не відповідає типу мультисенсора'

    },
    real_sensor_used_in_another_period: {
        "ru": 'Датчик используется в другом периоде',
        "eng": 'Sensor is used in another period',
        "ukr": 'Датчик використовується в іншому періоді'

    },
    sensor_with_this_id_olready_used: {
        "ru": 'Датчик с таким id уже используется',
        "eng": 'Sensor with this id is already in use',
        "ukr": 'Датчик з таким ID вже використовується'

    },
    sensor_with_this_type_present_on_car: {
        "ru": 'Датчик этого типа уже представлен на авто',
        "eng": 'Sensor of this type is already present on the car',
        "ukr": 'Датчик цього типу вже представлений на авто'

    },
    do_not_match_the_virtual_sensor_type: {
        "ru": 'Один или несколько сокетов не соответствуют типу виртуального сенсора',
        "eng": 'One or more sockets do not match the virtual sensor type',
        "ukr": 'Один або кілька сокетів не відповідають типу віртуального сенсора'

    },
    virtual_sensor_typ_already_on_the_car: {
        "ru": 'Данный тип виртуального сенсора уже есть на авто',
        "eng": 'This type of virtual sensor is already on the car',
        "ukr": 'Цей тип віртуального сенсора вже є на авто'

    },
    attempting_to_reconnect: {
        "ru": 'Попытка восстановить соединение',
        "eng": 'Attempting to reconnect',
        "ukr": `Спроба відновити з'єднання`

    },
    trying_to_reconnect: {
        "ru": 'Пробуем восстановить соединение',
        "eng": 'Trying to reconnect',
        "ukr": `Пробуємо відновити з'єднання`

    },
    trailer_editing: {
        "ru": 'Редактирование прицепа',
        "eng": 'Trailer Editing',
        "ukr": 'Редагування причепа'

    },

    block_status_updated: {
        "ru": "Статус блокировки обновлен",
        "eng": "Lock status updated",
        "ukr": "Статус блокування оновлено"
    },
    device_status_updated: {
        "ru": 'Статус устройства на сервере изменен',
        "eng": 'Device status on server changed',
        "ukr": 'Статус пристрою на сервері змінено'
    },
    status_for_ignition_always_on_changed: {
        "ru": 'Статус для "зажигание включено всегда" изменен',
        "eng": 'Status for "ignition always on" changed',
        "ukr": 'Статус для "запалювання завжди включено" змінено'
    },
    calibration_updated: {
        "ru": 'Тарировка обновлена',
        "eng": 'Calibration updated',
        "ukr": 'Тарування оновлено'

    },
    group_updated: {
        "ru": "Группа обновлена",
        "eng": "Group updated",
        "ukr": "Група оновлена"
    },
    password_updated: {
        "ru": "пароль обновлен",
        "eng": "password updated",
        "ukr": "пароль оновлено"
    },
    message_updated: {
        "ru": "Сообщение обновлено",
        "eng": "Post updated",
        "ukr": "Повідомлення оновлено"
    },
    description_updated: {
        "ru": "Описание обновлено",
        "eng": "Description updated",
        "ukr": "Опис оновлено"
    },
    user_added: {
        "ru": "пользователь добавлен",
        "eng": "user added",
        "ukr": "користувач доданий"

    },
    you_will_be_authorized: {
        "ru": "Вы будете авторизированы после того как введете ссылку",
        "eng": "You will be logged in after you enter the link",
        "ukr": "Ви будете авторизовані після того, як введете посилання"

    },
    you_will_be_authorized_placeholder: {
        "ru": "Вставьте сюда ссылку которую нужно открыть, CTRL+V",
        "eng": "Paste here the link you want to open, CTRL+V",
        "ukr": "Вставте сюди посилання, яке потрібно відкрити, CTRL+V"

    },
    link_is_bad: {
        "ru": "Со ссылкой что-то не так, проверьте ссылку или вернитесь обратно",
        "eng": "There is something wrong with the link, check the link or go back",
        "ukr": "З посиланням щось не так, перевірте посилання або поверніться назад"
    },

    "delete_device_you_sure": {
        "ru": "Устройство и все его данные будут удалены с сервера, удалить?",
        "eng": "Device and all of it's data will be removed from server, proceed?",
        "ukr": "Пристрій та всі його дані будуть видалені з сервера, продовжити?"
    },
    "client_sim": {
        "ru": "SIM клиента",
        "eng": "client SIM",
        "ukr": "SIM клієнта"
    },
    "not_mounted_on_vehicle": {
        "ru": "Не установлено на транспорт",
        "eng": "Not mounted on vehicle",
        "ukr": "Не встановлено на транспорт"
    },
    "unknown_account": {
        "ru": "Аккаунт не указан",
        "eng": "Account not selected",
        "ukr": "Акаунт не вказано"
    },
    "ignore_while_cleaning": {
        "ru": "Игнорировать при чистке",
        "eng": "Ignore while cleaning",
        "ukr": "Ігнорувати при очищені"
    },
    "recieved": {
        "ru": "Получено",
        "eng": "Recieved",
        "ukr": "Отримано"
    },
    "answer_time": {
        "ru": "Время отклика",
        "eng": "Время ответа",
        "ukr": "Час відповіді"
    },
    "answer": {
        "ru": "Ответ",
        "eng": "Answer",
        "ukr": "Відповідь"
    },
    "empty_history": {
        "ru": "История пустая",
        "eng": "History is empty",
        "ukr": "Історія порожня"
    },
    "was_never_connected": {
        "ru": "Никогда не подключалось",
        "eng": "Was never connected",
        "ukr": "Ніколи не підключалося"
    },
    "in_touch": {
        "ru": "На связи",
        "eng": "In touch",
        "ukr": "На зв'язку"
    },
    "out_of_touch": {
        "ru": "Не на связи",
        "eng": "Out of touch",
        "ukr": "Не на зв'язку"
    },
    "in_the_trash_can": {
        "ru": "В корзине",
        "eng": "In the trash can",
        "ukr": "В корзині"
    },
    "device_auto_login": {
        "ru": "Устройство, авто, логин, IMEI",
        "eng": "Device, auto, login, IMEI",
        "ukr": "Пристрій, авто, логін, IMEI"
    },
    nothing_found: {
        "ru": "Ничего не найдено",
        "eng": "Nothing found",
        "ukr": "Нічого не знайдено"
    },

    "device_list_empty": {
        "ru": "Список устройств пуст",
        "eng": "Device list is empty",
        "ukr": "Перелік пристроїв порожній"
    },
    "token_session_statistics": {
        "ru": "Статистики по токенам и сессиям",
        "eng": "Token and sessions statistics",
        "ukr": "Статистики по токенам та сесіям"
    },
    "remove_tokens": {
        "ru": "Удалить все выданные токены",
        "eng": "Remove all provided tokens",
        "ukr": "Видалити всі видані токени"
    },
    adding_server: {
        'ru': 'Добавление сервера',
        "eng": "Adding a server",
        "ukr": "Додавання сервера"
    },
    too_many_login_attempts: {
        'ru': 'Слишком много попыток входа',
        "eng": 'Too many login attempts',
        "ukr": 'Занадто багато спроб входу'
    },
    server_not_available: {
        'ru': 'Сервер не отвечает, проверьте интернет соединение\n',
        "eng": 'Server not responding, please check internet connection\n',
        "ukr": `Сервер не відповідає, перевірте інтернет з'єднання\n`
    },
    "edit_server": {
        "ru": "Редактирование сервера",
        "eng": "Server editing",
        "ukr": "Редагування сервера"
    },
    "sure_delete_server": {
        "ru": "Вы уверенны, что хотите удалить сервер?",
        "eng": "Are you sure want to delete server?",
        "ukr": "Ви впевнені що хочете видалити сервер?"
    },
    "sure_delete_device": {
        "ru": "Вы уверенны, что хотите удалить устройство?",
        "eng": "Are you sure want to delete device?",
        "ukr": "Ви впевнені що хочете видалити пристрій?"
    },
    "relaying_servers": {
        "ru": "Сервера ретрансляции для устройств",
        "eng": "Relaying servers for devices",
        "ukr": "Сервера ретрансляції для пристроїв"
    },
    "add_device": {
        "ru": "Добавление устройства",
        "eng": "Add device",
        "ukr": "Додання пристрою"
    },
    "no_rights_user": {
        "ru": "Нет данных разрешённых прав для пользователя",
        "eng": "No rights for user",
        "ukr": "Немає дозволених прав для користувача"
    },
    "save_users_rights": {
        "ru": "Сохранить права пользователей",
        "eng": "Save users rights",
        "ukr": "Зберегти права користувачів"
    },
    "users_list": {
        "ru": "Список пользователей",
        "eng": "Users list",
        "ukr": "Перелік користувачів"
    },
    "add_user": {
        "ru": "Добавить пользователя",
        "eng": "Add user",
        "ukr": "Додати користувача"
    },
    "client": {
        "ru": "Клиент",
        "eng": "Client",
        "ukr": "Клієнт"
    },
    "information_about_update": {
        "ru": "Информация об обновлении",
        "eng": "Information about update",
        "ukr": "Інформація про оновлення"
    },
    "sure_delete_message": {
        "ru": "Вы уверенны что хотите удалить уведомление?",
        "eng": "Are you sure want to delete message?",
        "ukr": "Ви впевнені що хочете видалити повідомлення?"
    },
    "enter_command": {
        "ru": "Введите команду",
        "eng": "Enter command",
        "ukr": "Введіть команду"
    },
    "get_command_list": {
        "ru": "Получить список команд",
        "eng": "Get commands list",
        "ukr": "Отримати перелік команд"
    },
    "edit_clients": {
        "ru": "Редактировать клиентов",
        "eng": "Edit clients",
        "ukr": "Редагувати клієнтів"
    },
    "edit_others": {
        "ru": "Редактировать других",
        "eng": "Edit others",
        "ukr": "Редагувати інших"
    },
    "edit_devices": {
        "ru": "Редактировать устройства",
        "eng": "Edit devices",
        "ukr": "Редагувати пристрої"
    },
    "delete_sensor_types": {
        "ru": "Удалять типы датчиков",
        "eng": "Delete sensor types",
        "ukr": "Видаляти типи датчиків"
    },
    "edit_sensors": {
        "ru": "Редактировать датчики",
        "eng": "Edit sensors",
        "ukr": "Редагувати датчики"
    },
    "edit_vehicle": {
        "ru": "Редактировать транспорт",
        "eng": "Edit vehicle",
        "ukr": "Редагувати транспорт"
    },
    "delete_calibrations": {
        "ru": "Удалить калибровки",
        "eng": "Delete calibrations",
        "ukr": "Видалити калібрування"
    },
    "see_statistics": {
        "ru": "Видеть статистику",
        "eng": "See statistics",
        "ukr": "Бачити статистику"
    },
    "sensors_config": {
        "ru": "Настройка датчиков",
        "eng": "Sensors config",
        "ukr": "Налаштування сенсорів"
    },
    "creating_admins": {
        "ru": "Создавать админов",
        "eng": "Creating admins",
        "ukr": "Створювати адмінів"
    },
    "do_blocking": {
        "ru": "Устанавливать блокировки",
        "eng": "Create blockings",
        "ukr": "Встановлювати блокування"
    },
    "blocked": {
        "ru": "Заблокирован",
        "eng": "Blocked",
        "ukr": "Заблокований"
    },
    "trusted_admin": {
        "ru": "Доверенный админ",
        "eng": "Trusted admin",
        "ukr": "Довірений адмін"
    },
    "enter_owner_group": {
        "ru": "Введите группу владельца",
        "eng": "Enter owner's group",
        "ukr": "Введіть групу власника"
    },
    "select_group": {
        "ru": "Укажите группу",
        "eng": "Select group",
        "ukr": "Виберіть групу"
    },
    "id_navutrack_numbers": {
        "ru": "Id navitrack может состоять только из цифр",
        "eng": "Id navitrack can contain only numbers",
        "ukr": "Id navitrack може складатися тільки з чисел"
    },
    "enter_navitrack_id": {
        "ru": "Navitrack UNID",
        "eng": "Navitrack UNID",
        "ukr": "Navitrack UNID"
    },
    "enter_client": {
        "ru": "Клиент",
        "eng": "Client",
        "ukr": "Клієнт"
    },
    "tracker_number_server": {
        "ru": "Номер трекера выдаёт сервер",
        "eng": "Tracker number is given by server",
        "ukr": "Номер трекера видається сервером"
    },
    'id_navitrack_contain_numbers': {
        "ru": 'Id navitrack может состоять только из цифр',
        "eng": "Tracker number is given by server",
        "ukr": 'Id navitrack може складатися тільки з цифр'
    },
    "incorrect_serial_number": {
        "ru": "Серийный номер может состоять только из цифр",
        "eng": "Serial number can contain only numbers",
        "ukr": "Серійний номер може вміщати лише числа"
    },
    "enter_number": {
        "ru": "Серийный номер",
        "eng": "Serial number",
        "ukr": "Серійний номер"
    },
    "enter_sim_number": {
        "ru": "Номер SIM",
        "eng": "SIM number",
        "ukr": "Номер SIM"
    },
    "date_from_which": {
        "ru": "Дата с которой получить данные",
        "eng": "Date from which to recieve data",
        "ukr": "Дата з якої отримати дані"
    },
    "track_activation": {
        "ru": "Отслеживать активацию",
        "eng": "Track activation",
        "ukr": "Відстежувати активацію"
    },
    "comment_to_device": {
        "ru": "Комментарий к устройству",
        "eng": "Comment to device",
        "ukr": "Коментар до пристрою"
    },
    "enter_deice_pass": {
        "ru": "Пароль от устройства",
        "eng": "Device password",
        "ukr": "Пароль від пристрою"
    },
    "device_password": {
        "ru": "Пароль от устройства",
        "eng": "Device password",
        "ukr": "Пароль від пристрою"
    },
    "enter_imei": {
        "ru": "IMEI",
        "eng": "IMEI",
        "ukr": "IMEI"
    },
    "imei_only_numbers": {
        "ru": "IMEI может содержать только числа",
        "eng": "IMEI can contain only numbers",
        "ukr": "IMEI може містити тільки числа"
    },
    "enter_description": {
        "ru": "Описание",
        "eng": "Description",
        "ukr": "Опис"
    },
    "vehicle_name": {
        "ru": "Имя транспорта",
        "eng": "Vehicle name",
        "ukr": "Ім'я транспорту"
    },
    "min_impulse_period": {
        "ru": "Минимальный период импульса",
        "eng": "Minimal impulse period",
        "ukr": "Мінімальний період імпульсу"
    },
    "filling_without_connection": {
        "ru": 'Заправка без соединения',
        "eng": 'Filling without connection',
        "ukr": `Заправка без з'єднання`
    },
    "add_gas_station": {
        "ru": "Добавить заправку",
        "eng": "Add gas station",
        "ukr": "Додати заправку"
    },
    "calibration_copy": {
        "ru": name => `Тарировка ${name} скопирована`,
        "eng": name => `Calibration ${name} copied`,
        "ukr": name => `Тарування ${name} скопійовано`
    },
    "save_here": {
        "ru": "Сохранить сюда",
        "eng": "Save here",
        "ukr": "Зберегти сюди"
    },
    "additional_reports": {
        "ru": "Дополнительные отчёты",
        "eng": "Additional reports",
        "ukr": "Додаткові звіти"
    },
    "enable_logging": {
        "ru": "Включить логирование",
        "eng": "Enable logging",
        "ukr": "Увімкнути логування"
    },
    "disable_logging": {
        "ru": "Выключить логирование",
        "eng": "Disable logging",
        "ukr": "Вимкнути логування"
    },
    "enter_group_name": {
        "ru": "Введите имя группы",
        "eng": "Enter group name",
        "ukr": "Введіть ім'я групи"
    },
    "edit_group": {
        "ru": "Изменить группу",
        "eng": "Edit group",
        "ukr": "Змінити групу"
    },
    "enter_user_desc": {
        "ru": "Введите описание для пользователя",
        "eng": "Enter user description",
        "ukr": "Введіть опис для користувача"
    },
    "data_was_rerequested": {
        "ru": "Данные были перезапрошены",
        "eng": "Data was re-requested",
        "ukr": "Дані були перезапрошені"
    },
    "data_from_which_request": {
        "ru": "Дата с которой запросить",
        "eng": "Date from which request",
        "ukr": "Дата з якої запросити"
    },
    "check_device_logs": {
        "ru": "Просмотреть логи по устройству",
        "eng": "Check device logs",
        "ukr": "Перевірити логи по пристрою"
    },
    "add_calibration_period": {
        "ru": "Добавить калибровку в конец прошлого или текущего периода",
        "eng": "Add calibration to end of past or new period",
        "ukr": "Додати калібруваня в кінець колишнього або поточного періоду"
    },
    "socket_id": {
        "ru": "Id сокета который меняем",
        "eng": "Socket id which we changing",
        "ukr": "Id сокету який ми змінюємо"
    },
    "calibration_id": {
        "ru": "Id калибровки",
        "eng": "Calibration id",
        "ukr": "Id калібрування"
    },
    "date_from_which_change": {
        "ru": "Дата с которой заменить",
        "eng": "Date from which change",
        "ukr": "Дата з якої замінити"
    },
    "date_of_period_end": {
        "ru": "Дата конца периода",
        "eng": "Date of period end",
        "ukr": "Дата кінця періоду"
    },
    "date_of_period_end_to": {
        "ru": "Дата по которую очистить",
        "eng": 'Date to clear',
        "ukr": 'Дата, по яку очистити'
    },
    clear_cache_for_month: {
        "ru": 'Очистить кеш за текущий месяц',
        "eng": 'Clear cache for the current month',
        "ukr": 'Очистити кеш за поточний місяць'
    },

    "add_calibration": {
        "ru": "Добавить калибровку",
        "eng": "Add calibration",
        "ukr": "Додати калібрування"
    },
    "sensor_number": {
        "ru": "Номер сенсора",
        "eng": "Sensor number",
        "ukr": "Номер сенсора"
    },
    "virtual_sensor_id": {
        "ru": "Id виртуального сенсора",
        "eng": "Virtual sensor id",
        "ukr": "Id віртуального сенсора"
    },
    "sensor_name": {
        "ru": "Название сенсора",
        "eng": "Sensor name",
        "ukr": "Назва сенсора"
    },
    "data_revers": {
        "ru": "Реверс данных",
        "eng": "Data reverse",
        "ukr": "Реверс даних"
    },
    "start_from_zero": {
        "ru": "Начинать с нуля",
        "eng": "Start from zero",
        "ukr": "Починати з нуля"
    },
    "save_data": {
        "ru": "Сохранить данные",
        "eng": "Save data",
        "ukr": "Зберегти дані"
    },
    restore_device_from_trash: {
        "ru": "Восстановить устройство из корзины",
        "eng": "Restore device from trash",
        "ukr": "Восстановить пристрій із корзини"
    },
    go_to_application_under_client: {
        "ru": "Перейти в приложение под этим клиентом",
        "eng": "Go to the application under this client",
        "ukr": "Перейти в додаток під цим клієнтом"

    },
    create_super_user: {
        "ru": "Создать супер пользователя",
        "eng": "Create super user",
        "ukr": "Створити супер користувача"
    },
    "request_data": {
        "ru": "Запросить данные",
        "eng": "Request data",
        "ukr": "Запитати дані"
    },
    "attention": {
        "ru": "Внимание",
        "eng": "Attention",
        "ukr": "Увага"
    },
    "fill_marked_fields": {
        "ru": "Заполните поля помеченные *",
        "eng": "Fill in the fields marked with *",
        "ukr": "Заповніть поля помічені *"
    },
    "no_calibration": {
        "ru": "Вы не ввели тарировку. Хотите продолжить?",
        "eng": "You have not entered a calibration. Do you want to continue?",
        "ukr": "Ви не ввели тарування. Хочете продовжити?"
    },
    "no_real_sensor_selected": {
        "ru": "Вы не ввели тарировку. Хотите продолжить?",
        "eng": "You have not entered a calibration. Do you want to continue?",
        "ukr": "Ви не ввели тарування. Хочете продовжити?"
    },
    "add_sensors_to_mapping": {
        "ru": "Добавить датчики в маппинг",
        "eng": "Add sensors to mapping",
        "ukr": "Додати датчики до мапінгу?"
    },
    "enter_num": {
        "ru": "Введите num реальных сенсоров через зяпатую, например: 1,2,3,4",
        "eng": "Enter num real sensors separated by commas. like this: 1,2,3,4",
        "ukr": "Введіть num реальних сенсорів через кому, наприклад 1,2,3,4"
    },
    "add_mapping": {
        "ru": "Добавить маппинг",
        "eng": "Add mapping",
        "ukr": "Додати мапінг"
    },
    "update_calibration_period": {
        "ru": "Обновление периода калибровки",
        "eng": "Update of calibration period",
        "ukr": "Оновлення періоду калібрування"
    },
    real_sensor_period_update: {
        "ru": 'Обновление периода реального сенсора',
        "eng": 'Real sensor period update',
        "ukr": 'Оновлення періоду реального сенсора'
    },
    id_of_the_sensor_to_be_changed: {
        "ru": 'Id сенсора который меняем',
        "eng": 'Id of the sensor to be changed',
        "ukr": 'Id сенсора що міняємо'
    },
    previous_sensor_id: {
        "ru": 'Id предыдущего сенсора',
        "eng": 'Previous sensor id',
        "ukr": 'Id попереднього сенсора'
    },
    "id_mapping_calibration": {
        "ru": "id маппинга калибровки",
        "eng": "id mapping calibration",
        "ukr": "id мапінга калібрування"
    },
    "change_mapping_period": {
        "ru": "Изменить период маппинга",
        "eng": "Change mapping period",
        "ukr": "Змінити період мапінга"
    },
    "remove_mapping_calibration": {
        "ru": "Удалить маппинг калибровки",
        "eng": "Remove mapping calibration",
        "ukr": "Видалити мапінг калібрування"
    },
    "used": {
        "ru": "Используется",
        "eng": "Used",
        "ukr": "Використовується"
    },
    "id_mapping_calibration": {
        "ru": "id маппинга калибровки",
        "eng": "id mapping calibration",
        "ukr": "id мапінга калібрування"
    },
    "change_mapping_period": {
        "ru": "Изменить период маппинга",
        "eng": "Change mapping period",
        "ukr": "Змінити період мапінга"
    },
    "real_sensor": {
        "ru": "Реальный сенсор",
        "eng": "Real sensor",
        "ukr": "Реальний сенсор"
    },
    "change_real_sensor": {
        "ru": "Замена реального сенсора",
        "eng": "Change real sensor",
        "ukr": "Заміна реального сенсора"
    },
    "update_real_sensor_period": {
        "ru": "Обновить период реального сенсора",
        "eng": "Update reak sensor period",
        "ukr": "Оновити період реального сенсора"
    },
    "update_period": {
        "ru": "Обновить период",
        "eng": "Update period",
        "ukr": "Оновити період"
    },
    "add_calibration": {
        "ru": "Добавить калибровку",
        "eng": "Add calibration",
        "ukr": "Додати калібрування"
    },
    "insert_calibration": {
        "ru": "Вставить калибровку",
        "eng": "Insert calibration",
        "ukr": "Вставити калібрування"
    },
    "no_data_real_sensor": {
        "ru": "Нет данных по реальному сенсору",
        "eng": "No data about real sensor",
        "ukr": "Немає даних по реальному сенсору"
    },
    no_real_sensors_are_selected: {
        "ru": "Реальных сенсоров не выбрано",
        "eng": "No real sensors are selected",
        "ukr": "Реальних сенсорів не вибрано"
    },
    "set_new_calibration": {
        "ru": "Установить новю калибровку для сенсора с id",
        "eng": "Set new calibration for sensor with id",
        "ukr": "Встановити нове калібрування для сенсора з id"
    },
    "save_sensor": {
        "ru": "Сохранить",
        "eng": "Save",
        "ukr": "Зберегти"
    },
    "socket_id": {
        "ru": "ID сокета",
        "eng": "socket ID",
        "ukr": "ID сокета"
    },
    "info_about_multisensor": {
        "ru": "Информация по мультисенсору",
        "eng": "Information about multisensor",
        "ukr": "Інформація про мультисенсор"
    },
    "socket_name": {
        "ru": "Имя сокета",
        "eng": "Socket name",
        "ukr": "Ім'я сокета"
    },
    "id_active_calibration": {
        "ru": "ID активной калибровки",
        "eng": "ID of active calibration",
        "ukr": "ID активної калібровки"
    },
    "actions_with_data": {
        "ru": "Действия с данными",
        "eng": "Actions with data",
        "ukr": "Дії з даними"
    },
    "multisensor_without_sensors": {
        "ru": "У мультисенсора нет датчиков",
        "eng": "Multisensor don't have sensors",
        "ukr": "У мультисенсора немає датчиків"
    },
    "multisensors_list": {
        "ru": "Список мультисенсоров",
        "eng": "Multisensors list",
        "ukr": "Перелік мультисенсорів"
    },
    "insert_calibnrations_period": {
        "ru": "Вставка калибровки в существующий период (период в котором уже есть калибровка)",
        "eng": "Inserting a calibration into an existing period (a period that already has a calibration)",
        "ukr": "Вставка калібрування в існуючий період (період у якому вже є калібрування)"
    },
    "id_of_socket_change": {
        "ru": "Id сокета сенсора который меняем",
        "eng": "Id of the sensor socket that we are changing",
        "ukr": "Id сокета сенсора який змінюємо"
    },
    "id_of_socket_adding": {
        "ru": "Id сокета для которого добавляем",
        "eng": "Id of the socket for which we are adding",
        "ukr": "Id сокету для якого додаємо"
    },
    "id_of_calibration": {
        "ru": "Id калибровки",
        "eng": "Id of calibration",
        "ukr": "Id калібрування"
    },
    "date_from_which_to_replace": {
        "ru": "Дата с которой заменить",
        "eng": "Date from which to replace",
        "ukr": "Дата з якої замінити"
    },
    "date_of_period_end": {
        "ru": "Дата конца периода",
        "eng": "Date of period end",
        "ukr": "Дата кінця періоду"
    },
    "add_calibration": {
        "ru": "Добавить калибровку",
        "eng": "Add calibration",
        "ukr": "Додати калібрування"
    },
    are_you_sure_remove_mapping: {
        ru: (variable) => `Вы уверены, что хотите удалить маппинг калибровки ${variable}?`,
        eng: (variable) => `Are you sure you want to delete the calibration mapping ${variable}?`,
        ukr: (variable) => `Ви впевнені, що хочете видалити мапінг калібрування ${variable}?`
    },
    are_you_sure_remove_sensor: {
        ru: (variable) => `Вы уверены, что хотите удалить сенсор ${variable}?`,
        eng: (variable) => `Are you sure you want to delete the sensor ${variable}?`,
        ukr: (variable) => `Ви впевнені, що хочете видалити сенсор ${variable}?`
    },
    "real_sensor_period": {
        "ru": "Реальные сенсоры периода",
        "eng": "Real sensors of the period",
        "ukr": "Реальні сенсори періоду"
    },
    "add_real_sensor": {
        "ru": "Добавление реального датчика",
        "eng": "Add real sensor",
        "ukr": "Додання реального датчика"
    },
    "detailed_information_socket": {
        "ru": "Детальная информация по сокету",
        "eng": "Detailed information about socket",
        "ukr": "Детальна інформація по сокету"
    },
    "update_real_sensor": {
        "ru": "Обновление реального датчика",
        "eng": "Update real sensor",
        "ukr": "Оновлення реального датчика"
    },
    "no_sensors_yet": {
        "ru": "Вы еще не добавили реальных сенсоров",
        "eng": "You haven't added real sensors yet",
        "ukr": "Ви ще не додали реальних сенсорів"
    },
    "show_info": {
        "ru": "Показать информацию",
        "eng": "Show information",
        "ukr": "Показати інформацію"
    },
    "list_is_empty": {
        "ru": "Список пуст",
        "eng": "List is empty",
        "ukr": "Перелік порожній"
    },
    "server_name": {
        "ru": "Имя сервера",
        "eng": "Server name",
        "ukr": "Ім'я сервера"
    },
    "host": {
        "ru": "Хост",
        "eng": "Host",
        "ukr": "Хост"
    },
    "port": {
        "ru": "Порт",
        "eng": "Port",
        "ukr": "Порт"
    },
    "protocol": {
        "ru": "Протокол",
        "eng": "Protocol",
        "ukr": "Протокол"
    }
};

const categoriesOfVirtualSensors = {
    all_sensor_types: {
        ru: "Все типы",
        eng: "All types",
        ukr: "Всі типи",
    },
    all: {
        "ru": "Общие",
        "eng": "General",
        "ukr": "Загальні"
    },
    gas_stations: all_translates.gas_station,
    CAN: {
        "ru": 'CAN',
        "eng": 'CAN',
        "ukr": 'CAN'
    },
    fueling: all_translates.fueling,
    security: {
        "ru": 'Безопасность',
        "eng": 'Security',
        "ukr": 'Безпека'
    },
    signaling: all_translates.signaling,
    agricultural_equipment: {
        "ru": 'Сельхозтехника',
        "eng": 'Agricultural equipment',
        "ukr": 'Сільгосптехніка'
    },
    locomotive: {
        "ru": 'Тепловоз',
        "eng": 'Locomotive',
        "ukr": 'Тепловоз'
    },
    LOCO_POWER: {
        "ru": 'Мощность',
        "eng": 'Power',
        "ukr": 'Потужність'
    }


};

const userCapabilities = {
    "rename": {
        "ru": "Переименовать транспортные средства",
        "eng": "Rename vehicles",
        "ukr": "Перейменувати транспортні засоби"
    },
    "changePeriods": {
        "ru": "Изменить периоды отчетов",
        "eng": "Change report periods",
        "ukr": "Змінити періоди звітів"
    },
    "editZones": {
        "ru": "Редактировать зоны",
        "eng": "Edit zones",
        "ukr": "Редагувати зони"
    },
    "setAlarmDone": {
        "ru": "Сбросить флаг тревоги",
        "eng": "Unset alarm flag",
        "ukr": "Скинути прапорець тривоги"
    },
    "setLock": {
        "ru": "Установить блокировку",
        "eng": "Set lock mode",
        "ukr": "Встановити блокування"
    },
    "getSmsAuthCode": {
        "ru": "Получить SMS-код авторизации",
        "eng": "Get SMS authorisation code",
        "ukr": "Отримати SMS-код авторизації"
    },
    "setOuts": {
        "ru": "Переключить состояние выходов устройства",
        "eng": "Switch device outputs state",
        "ukr": "Перемкнути стан виходів пристрою"
    },
    "editDriverNames": {
        "ru": "Редактировать имена водителей",
        "eng": "Edit driver names",
        "ukr": "Редагувати імена водіїв"
    },
    "receivePos": {
        "ru": "Получить текущее местоположение транспортного средства",
        "eng": "Get current vehicle location",
        "ukr": "Отримати поточне місцезнаходження транспортного засобу"
    },
    "seeFuel": {
        "ru": "Получить данные о топливе",
        "eng": "Get fuel data",
        "ukr": "Отримати дані про пальне"
    },
    "seeRoutes": {
        "ru": "Получить данные о маршрутах транспортного средства",
        "eng": "Get vehicle tracks",
        "ukr": "Отримати дані про маршрути транспортного засобу"
    },
    "seeReports": {
        "ru": "Получить отчеты",
        "eng": "Get reports",
        "ukr": "Отримати звіти"
    },
    "seeCharts": {
        "ru": "Получить графики",
        "eng": "Get charts",
        "ukr": "Отримати графіки"
    },
    "seeTasks": {
        "ru": "Просмотреть задачи управления",
        "eng": "See control task",
        "ukr": "Переглянути задачі керування"
    }
}

export default {
    ...all_translates,
    ...categoriesOfVirtualSensors,
    ...userCapabilities
}
