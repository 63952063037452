import React from "react";
import ReactTooltip from 'react-tooltip'
class TooltipWrapper extends React.Component {
	render(){
		const {position} = this.props;
		return <div    	className =     {this.props.className} 
	                    data-class =    'my_tooltip' 
	                    data-tip =      {true} 
	                    data-for =      {this.props.name}
	                    data-place =    {position ? position : 'top'} 
	                    data-effect =   'solid'>
					{this.props.children}
					<ReactTooltip id={this.props.name} getContent={[() => {return this.props.name}, 500]} />
                 </div>
	}
}
export default TooltipWrapper;