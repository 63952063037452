import React 							from 'react'
import {deleteImg, editImg, closeImg} 	from '../img.jsx'
import {ifNoKey, arrayIsEmpty} 			from '../../actions/ActionCreator.jsx'
import sensorApi 						from '../../actions/updateSensor.jsx'
import ConfirmRemove 					from '../ConfirmRemove.jsx'
import AddRealSensorToMapping 			from './AddRealSensorToMapping.jsx'
import HintCalibrationInfo 				from '../transportPage/HintCalibrationInfo.jsx'
import SeparPermission                	from 'modules/component/SeparPermission.jsx'
import l_v 								from "store/languages.jsx"
export default class ExistingMultisensors extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			forDelete: 			undefined,
			selectedMulti: 		undefined,
			deleteFromMap: 		undefined,
		}
	}
	componentWillMount() {
		if(arrayIsEmpty(this.props.data)){
			this.setState({selectedMulti: this.props.data[0]});
		}
	}
	selectMultiSensor = (e) =>{
		let multiSensorId  = e.target.name;
		let data  = this.props.data;
		let selectedMulti = data.find(key=>{
			if(key.id == multiSensorId){
				return key
			}
		})
		this.setState({
			selectedMulti: selectedMulti
		});
	}
	callDeleteMultiSensor = (multiSensorId) =>{
		this.setState({forDelete: multiSensorId});
		this.ConfirmTextforRemove = l_v.are_you_sure_remove_multi_sensor(multiSensorId)
		this.deleteMulti.popUp_activation({active: true})
	}
	callDeleteFromMapping = (sensorNum) => {
		this.setState({deleteFromMap: virtualSensorId});
		this.ConfirmTextforRemoveMapping = l_v.are_you_sure_remove_real_sensor(sensorNum)
		this.deleteFromMap.popUp_activation({active: true})
	}
	removeMultiSensor = () =>{
		let callBack = (multiSensorId) =>{
			let listOfMultiSensor = this.props.data;

			let index = listOfMultiSensor.findIndex((el)=>{
				if(el.id == multiSensorId) return el
			})
			listOfMultiSensor.splice(index, 1)
			this.forceUpdate()
		}
		sensorApi.removeMultiSensor(this.state.forDelete, callBack)
	}
	removeFromMap  = () =>{
		sensorApi.deleteConnectRealToMultisensor(this.state.selectedMulti.id, this.state.deleteFromMap)
	}
	nameDevicesOnSensor(id, sensorsInfo){
			if(Array.isArray(sensorsInfo) && sensorsInfo.length > 0){
				return <div className = 'cell'>
							{
								sensorsInfo.map((name, index) =>{
									if(id == name.id){
										return <p key = {index} >{name.title}</p>
									}
								})
							}
						</div>
			}else{
				return 	<div className = 'cell'>
							{l_v.no_data}
						</div>
			}
	}
	showCalibrationInfo = (e) =>{//used in prev variant
		this.hintInfo.showCalibrationInfo(e)
	}
	hideCalibrationInfo = (e) =>{
		this.hintInfo.hideCalibrationInfo()
	}
	listOfMultiSensor = (data) =>{
		return data.map((key, index)=>{
			return 	<div key = 	{index + "_" + "listOfMultiSensor"} className = 'listOfVirtualSensor'>
							<button  	name = 		{key.id}
										className = {'btn_black'}
										style = 	{this.styleBtn(key.id)}
										onClick = 	{this.selectMultiSensor}>
									id - {key.id} {key.title}
							</button>
							<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
							      				kindCapability = 'editSensors'>
								{ deleteImg('delete', ()=>this.callDeleteMultiSensor(key.id))}
							</SeparPermission>
					</div>

		})
	}
	listOfMappedSensor = (data, aggregate) =>{
		let setwidth = this.setwidth.bind(this);
		if(arrayIsEmpty(data)){
			return 	<div className = "devicelist">
						<p>{l_v.info_about_multisensor}:</p>
						<div 	className =	'triggerUp visible_on_mobile'
							 	onClick =	{this.scroolTop.bind(this)}>
							 	▲
						</div>
						<div 	className =	'triggerDown visible_on_mobile'
								onClick =	{this.scroolBottom.bind(this)}>
								▼
						</div>
			 			<div className = "head_line oneLine">
										<div className = 'cell'> {l_v.socket_id}</div>
										<div className = 'cell'> {l_v.socket_name}</div>
										<div className = 'cell'> {l_v.id_active_calibration}</div>
										<div className = 'cell'> {l_v.actions_with_data}</div>
										<div className = 'cell_delete cell'> </div>
						</div>
						<div className = 'table_content' ref = {(table_content)=>this.table_content = table_content}
							 style ={{
							 			height: this.state.column_height+'px',
							 			width: this.state.table_content_width + '%'
									}} >
							{data.map((key, index)=>{
								let calibrationId = getActiveCalibration(key.sensorCalibrationMappings);
								return 	<div className = "oneLine" key = {index}>
											<div className = 'cell'> {key.id}</div>
											<div className = 'cell'> {key.name}</div>
											<div 	className = 'cell'
													onMouseEnter = 	{this.showCalibrationInfo}
													onMouseLeave = 	{this.hideCalibrationInfo}
													data-value = {calibrationId}>
													{calibrationId}
											</div>
											<div className = 'cell'> {aggregate}</div>
											<div className = 'cell_delete cell'>
												<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
								      								kindCapability = 'editSensors'>
													{ deleteImg('delete', ()=>this.callDeleteFromMapping(key.id))}
												</SeparPermission>
											</div>
										</div>
							})}
						</div>
						<HintCalibrationInfo 	ref = {hintInfo => this.hintInfo =hintInfo}/>
						<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
		      								kindCapability = 'editSensors'>
							<AddRealSensorToMapping multiSensorId = {ifNoKey(this.state.selectedMulti, "id")}/>
						</SeparPermission>
					</div>
		}else{
			if(!!this.state.selectedMulti){
				return 	<div>
							<div className = 'nodata'>{l_v.multisensor_without_sensors}</div>
							<SeparPermission 	alowed = 'ADMIN, DEALER, CLIENT, SUPERADMIN'
			      								kindCapability = 'editSensors'>
								<AddRealSensorToMapping multiSensorId = {ifNoKey(this.state.selectedMulti, "id")}/>
							</SeparPermission>
						</div>
			}
		}
	}
	render(){
		if(arrayIsEmpty(this.props.data)){
			return 	<div className = 'SensorsInfoOnDevice'>
						<p className = 'nameOfList_multisensors'>{l_v.multisensors_list}</p>
						{this.listOfMultiSensor(this.props.data)}
						{this.listOfMappedSensor(ifNoKey(this.state.selectedMulti, "realSensorSockets"), ifNoKey(this.state.selectedMulti, "aggregate"))}
						<ConfirmRemove  ref =           {(deleteMulti) => this.deleteMulti = deleteMulti}
				                        confirmText =   {this.ConfirmTextforRemove }
				                        clear =         {()=>this.setState({
				                                                forDelete: undefined
				                                        })}
				                        remove =    {this.removeMultiSensor}/>
				        <ConfirmRemove  ref =           {(deleteFromMap) => this.deleteFromMap = deleteFromMap}
				                        confirmText =   {this.ConfirmTextforRemoveMapping}
				                        clear =         {()=>this.setState({
				                                                deleteFromMap: undefined
				                                        })}
				                        remove =    {this.removeFromMap}/>
					</div>
		}else{
			return <div className = 'nodata'>{l_v.absent}</div>
		}
	}
	styleBtn = (id) =>{
		if(!!this.state.selectedMulti){
			if(id == this.state.selectedMulti.virtualSensorId){
				return {
							opacity: 0.87,
						    color: "white",
						    backgroundColor: "#4179f8",
						}
			}
		}

	}
	setwidth(data){
		let head_line = data.head_line/data.table * 100;
		let win_width = window.innerWidth;
		let table_content = 100 - head_line;
		if (win_width < 720){
			this.setState({
				column_height: data.column_height+1,
				table_content_width: table_content,
			})
		}
		this.setState({
			win_width: win_width,
		})
	}
	scroolTop(){
		let height = this.state.column_height;
		let name = this.table_content;
		name.scrollTop -= height;
	}
	scroolBottom(){
		let height = this.state.column_height;
		let name = this.table_content;
		name.scrollTop += height;
	}
}
function getActiveCalibration(list) {
	let activeId;
	if(arrayIsEmpty(list)){
		list.forEach(key=>{
			if(!!key.current){
				activeId = key.sensorCalibrationId
			}
		})
	}
	return activeId
}