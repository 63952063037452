import React, { Component } from 'react';
import devicesApi           from "actions/devicesApi.js"
import Navigation           from './AdminNavigation.jsx'
import SeparPermission      from './component/SeparPermission.jsx'
import Helmet               from 'react-helmet';
import { deleteImg }        from './img.jsx'
import {removeElementFromList} from 'sl-library'
import l_v                     from "store/languages.jsx"

export default class IgnoredDevicesPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            ignoredDevices:     null,
        }
        this.initialState = this.state;
    }
    componentDidMount() {
        devicesApi.getIgnoredDevices(ignoredDevices => this.setState({ignoredDevices: ignoredDevices}) )
    }
    removeDeviceFromList = (id) => {
        const onRequestComplete = ()=>{
            const {ignoredDevices} = this.state;
            this.setState({
                ignoredDevices: removeElementFromList(ignoredDevices, id, 'id')
            });
        }
        devicesApi.removeFromIgnoredDevices(id, onRequestComplete)
    }
    render() {
        const {ignoredDevices} = this.state;
        return (
            <div>
                <Helmet title = { l_v.ignored_devices_list } />
                <SeparPermission    alowed = 'SUPERADMIN'
                                    type =              'page'/>
                <Navigation />
                <div className='container content'>
                    <h1 className="page_name">{ l_v.ignored_devices_list }</h1>
                    <div className = 'devicelist i'>
                        <div className = 'table_content'>
                            <HeadLine />
                            {
                                ignoredDevices ?
                                ignoredDevices.map(key => <OneDevice    key = {key.id}
                                                                        removeDeviceFromList = {this.removeDeviceFromList}
                                                                        {...key}/>) :
                                null
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}

function HeadLine(props) {
    return  <div className = "oneLine head_line">
                <div className = "cell_id cell">
                    {l_v.id_in_list}
                </div>
                <div className = "cell_deviceSerial cell">
                    {l_v.serial_number}
                </div>
                <div className = "cell_date cell">
                    {l_v.date_added}
                </div>
                <div className = "cell_delete cell">

                </div>
            </div>
}

function OneDevice(props) {
    const {deviceId, createdAt, id, removeDeviceFromList} = props;
    return(
            <div className = 'oneLine'>
                <div className = "cell_id cell">
                    {id}
                </div>

                <div className = "cell_deviceSerial cell">
                    {deviceId}
                </div>
                <div className = "cell_createdAt cell">
                    {moment(createdAt).format('DD.MM.YYYY - hh:mm')}
                </div>
                <div className = "cell_delete cell" style = {{
                                                                marginRight: '0px',
                                                                marginLeft: 'auto'
                                                            }}>
                    {
                        deleteImg("delete", ()=>removeDeviceFromList(id))
                    }
                </div>
            </div>
          )
}