import React 							from 'react'
import moment 							from 'moment'
import {ifNoKey, arrayIsEmpty} 			from '../../actions/ActionCreator.jsx'
import {connect} 						from 'react-redux'
import l_v 		from 'store/languages.jsx'

class HintCalibrationInfo extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			calibrationInfo: 	undefined,
		}
	}
	showCalibrationInfo = (e) => { //used in prev variant
		let elem = e.target;
		let id = !!elem ? elem.getAttribute('data-value') : null;
		let listCalibr = this.props.calibrationInfo;
		if(arrayIsEmpty(listCalibr) && id !== null) {
			let currentCal = listCalibr.find(key => {
				if(id == key.id) {
					return key
				}
			})
			if(!!currentCal) {
				this.setState({
					calibrationInfo: {
						x: e.clientX + 15,
						y: e.clientY - 50,
						data: currentCal,
					}
				});
			}
		} else {
			console.log("%c list of calibration is empty, nothing show", 'color: orange; font-weight: bold;', listCalibr)
		}
	}
	hideCalibrationInfo = (e) =>{
		this.setState({calibrationInfo: null});
	}
	render(){
		let data = this.state.calibrationInfo;
		if(!!data){
			let info = data.data;
			return 	<div 	style = 	{{left: data.x+"px", top: data.y + 'px'}}
							className = 'calibrationInfo'>
						<p className='key'>{l_v.global}: <span>{(!!info.general)?l_v.yes:l_v.no}</span></p>
						<p className='key'>{l_v.created}: <span>{moment(info.created).format('DD-MM-YYYY HH:mm')}</span></p>
						<p className='key'>{l_v.first_name}: <span>{info.name}</span></p>
						<p className='key'>{l_v.maximum_value}: <span>{info.max_value}</span></p>
						<p className='key'>{l_v.minimum_value}: <span>{info.min_value}</span></p>
						<p className='key'>{l_v.error}: <span>{info.offset}</span></p>
						<p className='key'>{l_v.scale}: <span>{info.scale}</span></p>
						<p className='key'><i>{l_v.calibration}:</i> {(arrayIsEmpty(info.calibrationTable))?
														<textarea 	className =		"form_text_area "
																	type= 			"text"
																	className = 	"description"
																	value = 		{stringView(info.calibrationTable)}
																	placeholder = 	{l_v.current_desc}
																	readOnly>
														</textarea>:
														<span>{l_v.no_data}</span>}
						</p>
				</div>

		}else {
			return	null
		}
	}
}
export default connect(
	    state => ({
	    	calibrationInfo:  state.devicesR.concatedCalibrations,

	   }), null, null, { forwardRef: true })(HintCalibrationInfo)

function stringView(calibrationTable){
			let view;
			view =	calibrationTable.map((key, index)=>{
					return key.orig_value + '\t' + key.result_value + '\n';
				})
			view = view.join('');
			return view
		}