import React 								from 'react'
import language_variant 					from '../../store/languages.jsx'
import ClickOutside  						from 'react-click-outside'
import OneTransport 						from './OneTransport.jsx'
import {TransportList, filterTransportByNameVSLiscensePlate} from './CreateGroup.jsx'
import {closeIcon} 							from 'images/simple_svg_icons.jsx'
import {arrayIsEmpty, toLowerCase, copyOfObject} 			from '../../actions/ActionCreator.jsx'
export default class EditGroup extends React.Component{
	constructor(props) {
		super(props);
		this.state = {
			active: 	false,
			groupName: 	'',
			transportList: 		this.props.transportList,
			vehiclesInGroup: 	[],
			groupId: null,
		}
	}
	hideForm = () => {
		this.setState({
			active: false,
		})
	}
	enterToState = (e) =>{
		this.setState({[e.target.name]:e.target.value});
	}
	showForm = (groupInfo) => {
		let vehiclesInGroup = [];
		let {transportList} = this.props;
		if(arrayIsEmpty(transportList)){
			transportList.forEach(key=>{
				let groups = key.groups;
				groups.find(group=>{
					if(group.id == groupInfo.id){
						vehiclesInGroup.push(key.id)
					}
				})
			})
		}
		this.setState({
			active: true,
			groupName: groupInfo.name,
			groupId: groupInfo.id,
			vehiclesInGroup: vehiclesInGroup,
			vehiclesInGroupOnStartEdit: copyOfObject(vehiclesInGroup),
			groupNameOnStart: groupInfo.name,
			transportList: 	transportList
		})
	}
	handleChange = (e) => {
		this.setState({
			groupName: e.target.value
		})
	}
	handleSearch = (e) => {
		this.setState({
			transportList: filterTransportByNameVSLiscensePlate(this.props.transportList, e.target.value)
		});
	}
	addThis = (vehicleId) => {
		let vehiclesInGroup = this.state.vehiclesInGroup;
		vehiclesInGroup.push(vehicleId)
		this.setState({
			vehiclesInGroup: vehiclesInGroup
		})
	}
	removeThis = (vehicleId) => {
		let vehiclesInGroup = this.state.vehiclesInGroup;
		let index = vehiclesInGroup.indexOf(vehicleId);
		if(index !== -1) {
			vehiclesInGroup.splice(index, 1)
			this.setState({
				vehiclesInGroup: vehiclesInGroup
			})
		}
	}
	save = () => {
		let {vehiclesInGroupOnStartEdit, vehiclesInGroup, groupName, groupNameOnStart, groupId} = this.state;
		let vehiclesForRemoveGroup = [];
		let vehicleForAddToGroup = [];
		let newName;
		vehiclesInGroupOnStartEdit.forEach(key=>{
			if(vehiclesInGroup.indexOf(key) == -1){
				vehiclesForRemoveGroup.push(key)
			}
		})
		vehiclesInGroup.forEach(key=>{
			if(vehiclesInGroupOnStartEdit.indexOf(key) == -1){
				vehicleForAddToGroup.push(key)
			}
		})
		if(groupNameOnStart !== groupName){
			newName = groupName
		}
		this.props.updateGroup(groupId, vehicleForAddToGroup, vehiclesForRemoveGroup, newName)
	}

	render(){
		if (this.state.active!==true){
			return null
		}else{
			return (
				<ClickOutside className='edit_group' onClickOutside = {this.hideForm}>
						<p className="head_edit_group">
							<span>{language_variant.groupEdit}</span>
							{closeIcon('close', this.hideForm)}
						</p>
						<input  type = 			"text"
				                className =		"group_name_input"
				                placeholder = 	{language_variant.name_group}
				                onChange = 		{this.enterToState}
			                  	name = 			'groupName'
				                value = 		{this.state.groupName} />
		                <div className = 'help_div_edit_group'>
			                <ul className = 'list_edit_group'>
			                <TransportList 	transportList = {this.state.transportList}
			               	 				addThis = {this.addThis}
			               	 				vehiclesInGroup = {this.state.vehiclesInGroup}
											removeThis = {this.removeThis}/>
			                </ul>
			            </div>
			            <div className="filter_list">
				            <input  type = 			"text"
				                  	className = 	"transport_search"
				                  	placeholder = 	{language_variant.transport_search_placeholder}
				                  	onChange = 		{this.handleSearch}
				            />
				            <span> {language_variant.transport_search}</span>
			            </div>
			            <div className = "footer_edit_group">

				            <button className = "remove_group delete_btn"
				            		onClick = {()=>this.props.removeGroup(this.state.groupId)}>
				            	{language_variant.remove_group}
				            </button>

				            <span>
					            <button className = "cancel no_style_btn"
					            		onClick = {this.hideForm}>
					            	{language_variant.cancel}
					            </button>
					            <button className = "primari_btn"
				                 		onClick = 	{this.save}>
				                 	{language_variant.save}
				                </button>
			                </span>
			            </div>
				</ClickOutside>
			)
		}
	}

}
