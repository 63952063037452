import React 				from 'react'
import ClickOutside  		from 'react-click-outside'
import Search               from '../component/Search.jsx'
import {arrayIsEmpty, ifNoKey} 		from '../../actions/ActionCreator.jsx'
import {deleteImg, editImg, closeImg} 	from '../img.jsx'
import l_v 								from "store/languages.jsx"

export default class SelectListOfRealSensor extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			active: 		false,
			selectedItems: 	[],
			list: 			this.props.list,
			...initialStateCheckbox(this.props.list)
		}
	}
	handleSearch = (value) =>{
		if(!this.state.disabled){
		let searchQuery, searchValue, list, group_list, name, licensePlate;
		searchQuery = to_LowerCase(value);
		// if(!!e.target.value){
			list = this.props.list.filter(el=>{
				searchValue = to_LowerCase(el.name) + to_LowerCase(el.id);
				if(!!searchValue){
					return searchValue.indexOf(searchQuery) !== -1;
				}
			})
		    this.setState({
		    	 list: 		 list,
		    	 value: 	 value,
		    })
		// }
			function to_LowerCase(value){
				if(!!value && typeof value == "string"){
					return value.toLowerCase()
				}else return value
			}
		}
	}

	removeThis = (e) =>{
		let id = e.currentTarget.value || e.target.value;
		e.stopPropagation();
		let selectedItems = this.state.selectedItems;
		if(arrayIsEmpty(selectedItems)){
					selectedItems.find((el, index) =>{
						if(el.id == id){
							selectedItems.splice(index, 1)
						}
					})
		}
		this.setState({
			[id]: 			false,
			selectedItems: 	selectedItems,
		}, this.returnToParent());
	}
	enterToStateCheckBox = (e) =>{
		let id = e.target.id;
		let checked = e.target.checked;
		let list = this.props.list;
		let selectedItems = this.state.selectedItems;
		if(arrayIsEmpty(list)){
			if(!!checked){
				list.find(key=>{
				if(key.id == id){
						selectedItems.push(key)
					}
				})
			}else{
				if(arrayIsEmpty(selectedItems)){
					selectedItems.find((el, index) =>{
						if(el.id == id){
							selectedItems.splice(index, 1)
						}
					})
				}
			}
		}
		this.setState({
			selectedItems: 	selectedItems,
			[id]: 			checked,
		}, this.returnToParent());
	}
	returnToParent = () =>{
		this.props.returnSelectedItem(this.state.selectedItems)
	}
	listForSelect(list){
		if(arrayIsEmpty(list)){
			return 	list.map((key, index) =>{
				return <OneCheckBox name = 				{key.id}
									key = 				{key.id + '_' + index}
									checkbox_value = 	{this.state[key.id]}
									onChangeCheckbox = 	{this.enterToStateCheckBox}
									label_text = 		{'№' + ifNoKey(key, 'sensorNumber') +' (id-' + key.id + ') ' + key.name} />
			})
		}
		else{
			return <div >{l_v.nothing_found}</div>
		}
	}
	selectedItem(list){
		if(arrayIsEmpty(list)){
			return list.map(key =>{
				return <div className = 'selected_item' key = {key.id + ' ' + key.name}>
							{key.id + ' ' + key.name}
							<button className = "remove"
									value = 	{key.id}
									onClick = 	{this.removeThis}>
									{closeImg('remove_icon')}
							</button>
						</div>

			})
		}else{
			return <div className = 'no_data'>{l_v.no_real_sensors_are_selected}</div>
		}
	}
	render() {
		return 	<div className = 'realSensors_selectList'>
					<div 	className = 'selected_list'
							id = 'realSensorList'
							onClick = {()=>this.setState({active:true})}>
							{this.selectedItem(this.state.selectedItems)}
					</div>
					<ClickOutside  	onClickOutside = {()=>this.setState({active: false})}
									style = {this.elementHeight(this.state.active)}
									className = 'wrapper_of_list'>
						<Search     placeholder = {l_v.search}
	                        		onChange = {this.handleSearch}>

	            		</Search>
	            		<div className = 'listForSelect'>
	            			{this.listForSelect(this.state.list)}
	            		</div>
					</ClickOutside>
				</div>

		;
	}
	elementHeight(active){
		if(!active){
			return {
				maxHeight: "0px"
			}
		}
	}
}

export class OneCheckBox extends React.Component{
	render(ReactElement, DOMElement, callback){
		return 	<div className="one_checkbox">
					<div>
						<span className='for_custom_checkbox'>
							<input 	type = 		'checkbox'
									className =	'checkbox_blue'
									id = 		{this.props.name}
									onChange = 	{this.props.onChangeCheckbox}
									checked = {(!!this.props.checkbox_value)?this.props.checkbox_value:false}
									name = 		{this.props.name}/>
							<label htmlFor = {this.props.name}></label>
						</span>
						<span 	className = 'psevdo_label'
								style = {{fontWeight: !!this.props.checkbox_value?'600':'400'}}>
								{this.props.label_text}
						</span>
					</div>
				</div>
	}
	disabled=()=>{
		if(!this.props.checkbox_value){
			return true
		}else return false
	}
	disabledText = () => {
		if(!this.props.checkbox_value){
			return 0.3
		}else return 1
	}
}
function initialStateCheckbox(socketsList){
	let initialState = {};
	if(arrayIsEmpty(socketsList)){
		socketsList.forEach(key=>{
			initialState[key.id] = false;
		})
	}
	return initialState
}