import React, { Component } from 'react';
import SL                     from 'sl-library'
import {
	editImg,
	deleteImg,
	closeImg
}                           from '../img.jsx'
import Search               from 'modules/component/Search.jsx'
import l_v                  from "store/languages.jsx"
import { Link }             from 'react-router-dom'

export const ServerContext = React.createContext({});

export default function ServersView(props) {
	const {serversList} = props;
	return <div className = 'servers_on_translation'>
				<div className = 'sub_header'>
					<h2>{ l_v.servers_list }</h2> <button className = 'btn_plus' onClick = {props.callFormForAddServer}>+</button>
				</div>
				<Search placeholder = 	{ l_v.placeholder_search }
						onChange =		{(value)=>props.handleFilterChange(value)}>
				</Search>
				<ListOfServers 	serversList = {serversList}/>
			</div>
}
/*callConfirmRemove = {props.callConfirmRemove}
								callEdit = {props.callEdit}*/
function ListOfServers(props) {
	const {serversList} = props;
	return 	SL.arrayIsEmpty(serversList) ?
			<div className = 'server_list'>
				<HeadLineOfServers />
				{
					serversList.map(oneServer => 	<OneServerView 	{...oneServer}
					                 								key = {oneServer.id}/>)
				}
			</div>:
			<div>{l_v.list_is_empty}</div>
}

function OneServerView(props) {
	const {
		serverHost,
		serverPort,
		serverName,
		retranslatorProtocolType,
		comment,
		id
	} = props;
	return  <ServerContext.Consumer>
				{
					context => 	<div className = 'oneLine one_server'>
										{console.log(`%c OneServerView context-`, "color: green; font-weight: 400; text-transform:uppercase", context, props)}	
										<div className = 'cell cell_id'>{ id } </div>
										<div className = 'cell cell_server_name'> <Link to = {`/retranslation_server/${id}`}>{ serverName }</Link>  { context.FB.serverName(props) }</div>
										<div className = 'cell'>{ serverHost } { context.FB.serverHost(props) }</div>
										<div className = 'cell'>{ serverPort } { context.FB.serverPort(props) }</div>
										<div className = 'cell'>{ retranslatorProtocolType } { context.FB.retranslatorProtocolType(props) } </div>
										<div className = 'cell'>{comment}</div>
										<div className = "cell_edit cell">
											{ editImg("edit", () => context.callEditServer(props) ) }
										</div>
										<div className = "cell_delete cell">
											{ deleteImg("delete", () => context.callConfirmRemove(props)) }

										</div>
									</div>
				}
			</ServerContext.Consumer>
}

function HeadLineOfServers(props) {
	return <div className = "oneLine head_line">
			<div className = "cell_id cell">
				Id
			</div>
			<div className = "cell_name cell">
				{l_v.server_name}
			</div>
			<div className = "cell_desc cell">
				{l_v.host}
			</div>
			<div className = "cell_desc cell">
				{l_v.port}
			</div>
			<div className = "cell_desc cell">
				{l_v.protocol}
			</div>
			<div className = "cell_date cell">
				{ l_v.comment }
			</div>
			<div className = "cell_edit cell">

			</div>
			<div className = "cell_delete cell">

			</div>
		</div>
}

// {
	  // "id": 3,
//     "serverHost":"127.0.0.1",
//     "serverPort": 8980 ,
//     "serverName": "TestName",
//     "retranslatorProtocolType": "TELTONIKA",
//     "comment":"test comment lol"
// }