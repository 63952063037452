import React, { Component, Fragment } from 'react';
import TimeInput                      from 'dd-date-time-input'
import moment                         from "moment"
import ConfirmRemove                  from "modules/ConfirmRemove.jsx"
import {replaceAllIsNotNumber}        from 'actions/ActionCreator.jsx'
import l_v                            from 'store/languages.jsx'
import SL                             from "sl-library"


 
const debugSettings = {
    to: moment().add(-25, 'days').format(),//.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
    from: moment().add(-24, 'days').format(),//.format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]'),
    sensorNums: 1
}


const CorrectionForm = (BaseClass) => class extends BaseClass {
    baseState = {to: null, from: null}    
    get utcFormat(){
        const {to, from} = this.state;
        return {
            to:     moment(to).format(),//.format('YYYY-MM-DD[T]HH'),
            from:   moment(from).format()
        }
    }
    setDatesToState = (e) => {
        const {name, value} = e;
        const {to, from} = this.state;

        if(name == 'from'){//.add(1, 'minutes')
            const valueTo = moment(value);
            this.setState({
                [name]: value,
                to: valueTo
            });
        }else{
             this.setState({[name]: value })
        }
       
        // this.setState({
        //     [name]: value
        // });
    }
    callConfirm = () => this.confirm.open()
    enterToState  = e => {
        const {value, name} = e.target;
         this.setState({[name]: value })
        
    }
    defFormView = ({form_name, required_value, name_request_btn, InputEl}) => {
        const {to, from} = this.state;
        const request_not_allowed = !SL.isValidDate(to) || !SL.isValidDate(from) || !SL.isNumeral(required_value)
        return (
            <div className = 'remove_data_for_sensor_by_period'>
                <p className = 'nameForm'>{this.form_name}</p>
                <TimeInput  disabledDate =          {false}
                            onChange =              {this.setDatesToState}
                            value =                 {from}
                            hiddenTime =            {false}
                            textLabel =             {l_v.period_start}
                            maxDate =               {to ? to : null}
                            withSeconds =           {true}
                            name =                  'from'/>
                <TimeInput  disabledDate =          {false}
                            onChange =              {this.setDatesToState}
                            value =                 {to}
                            textLabel =             {l_v.end_period}
                            hiddenTime =            {false}
                            minDate =               {from}
                            withSeconds =           {true}
                            name =                  'to'/>
                { InputEl }
                <div className = 'clear'></div>
                <button className = 'primari_btn'
                        disabled =  {request_not_allowed}
                        onClick =   {this.callConfirm}>
                        {name_request_btn}
                </button>
                <ConfirmRemove  confirm_header_text =   { this.form_name }
                                height =        '210px'
                                ref =           { el => this.confirm = el}
                                remove =        { this.sendRequest }
                                confirmTextYes = { this.confirmTextYes }
                                confirmText =   { this.confirm_text }/>
            </div>)
    }
}

export  const SpeedCorrectionForm = class SpeedCorrectionForm extends CorrectionForm(Component) {
    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            speed: 0,
        }
        this.initialState = this.state;
        this.form_name = 'Корекція швидкості';
        this.confirmTextYes = l_v.save;
        // this.confirm_text = `Оновлення швидкості`;
    }
    sendRequest = () => {
        const { speed } = this.state;
        this.props.upateSpeedByPeriod({
           ...this.utcFormat,
            speed: speed
        })
    }
    get confirm_text() {
        return `${l_v.period}: ${moment(this.state.from).format('DD.MM.YYYY - HH:mm:ss')} ${l_v.to} ${moment(this.state.to).format('DD.MM.YYYY - HH:mm:ss')}\n  значение: ${this.state.speed}`;
    }
    // get confirm_text_header() {
    //     return `Обновление скорости`
    // }
    render() {
        const { speed } = this.state;
        const InputEl = <input  className =     'input'
                                type =          'text'
                                name =          'speed'
                                value =         {speed}
                                placeholder =   {l_v.sensor_number}
                                onChange =      {this.enterToState}/>;

        return this.defFormView({
            InputEl,
            required_value: speed,
            name_request_btn: l_v.update_data,
        })
    }
}


export class ClearDataBySensorForm extends CorrectionForm(Component) {
    constructor(props) {
        super(props);
        this.state = {
            ...this.baseState,
            sensorNums: '',
        }
        this.initialState = this.state;
        this.form_name = l_v.remove_data_from_sensor;
    }
    get confirm_text() {
        return `${l_v.are_you_sure_want_to_delete_data}  ${moment(this.state.from).format('DD.MM.YYYY - HH:mm:ss')} ${l_v.to} ${moment(this.state.to).format('DD.MM.YYYY - HH:mm:ss')}`;
    }
    get confirm_text_header() {
        return `${l_v.remove_data_from_sensor} ${this.state.sensorNums}`;
    }
    sendRequest = () => {
        const { sensorNums } = this.state;
        this.props.removeDataBySensor({
            ...this.utcFormat,
            sensorNums
        })
    }
   
    render() {
        const { sensorNums } = this.state;
        const InputEl = <input  className =     'input'
                                type =          'text'
                                name =          'sensorNums'
                                value =         {sensorNums}
                                placeholder =   {l_v.sensor_number}
                                onChange =      {this.enterToState}/>;

        return this.defFormView({
            InputEl,
            required_value: sensorNums,
            name_request_btn: l_v.remove_data,
        })
    }
}
const Forms =  {
    SpeedCorrectionForm: SpeedCorrectionForm,
    ClearDataBySensorForm: ClearDataBySensorForm,
}

export default Forms


