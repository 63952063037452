import React, { Component }                    from "react"
import { Provider, connect }                   from 'react-redux'
import Helmet                                  from 'react-helmet';
import { bindActionCreators, applyMiddleware } from 'redux'
import _                                       from 'lodash'
import * as QRCode                             from 'easyqrcodejs';
import {
    copyOfObject,
    removeElementFromList,
    doLowerCase,
    arrayIsEmpty
}                                              from "sl-library"
import Users                                   from 'actions/users.js'
import Navigation                              from './AdminNavigation.jsx'
import l_v                                     from "store/languages.jsx"
import ConfirmRemove                           from './ConfirmRemove.jsx'
import { getPage }                             from "./Devices.jsx"
import TableFooter, { TableFooterMethods }     from "modules/devicePage/TableFooter.jsx"
import Search                                  from './component/Search.jsx'
import BtnAdd                                  from './component/BtnAdd.jsx'
import OneUser                                 from './component/OneUser.jsx'
import {QrCodeContext}                         from './component/OneUser.jsx'
import Pop_up                                  from './component/Pop_up.jsx'

class UsersPage extends TableFooterMethods(Component) {
    constructor(props) {
        super(props);
        const count_lines_for_view = 20;

        this.state = {
            usersList: null,
            display: this.props.kindUser !== 'CLIENT'?'none':null,
            ...this.paginationState
        }
        this.handleFilterChange = _.debounce(this.handleFilterChange, 800);
        QrCodeContext.showQR = this.showQR;

    }
    showQR = (login) => {
        const onGetSID = (sid) => {
            this.qrPopup.open();
            let text = login + ',' + sid;
            const qrcode = new QRCode(document.getElementById("qrcode"),  {
                text: text,
                width: 256,
                height: 256,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.H,
            });
            // qrcode.makeCode(text)
        }
        this.setState({loginForQrCode: login});
        Users.getSidForAuthInClient(login, onGetSID)
    }
    componentDidMount() {
        this.doRequest()
    }
    doRequest = () => {
        const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
        Users.getData("USER", {
            page: getPage(totalElements, end_index, count_lines_for_view),
            size: count_lines_for_view,
        }, contentInfo => this.setState({
            usersList: contentInfo.content,
            totalElements: contentInfo.totalElements

        }))
    }
    valueForSort = (value) =>{
        this.value = value;
        this.handleFilterChange();
    }
    handleFilterChange = (e) =>{
        console.log("%c this.value", coCSS, this.value)
        if(this.value !== undefined){
            // let searchQuery = this.value.toLowerCase();
            let searchQuery = this.value;
            let {count_lines_for_view} = this.state;
            Users.getData("USER", {
                page: 0,
                size: count_lines_for_view,
                searchParam: searchQuery,
            }, contentInfo => this.setState({
                start_index: 0,
                end_index: count_lines_for_view,
                usersList: contentInfo.content,
                totalElements: contentInfo.totalElements

            }))
        }
    }
    addUser = (data) => {
        const addUserToList = (newUserData) => {
            let {usersList} = this.state;
            usersList.unshift(newUserData)
            this.setState({
                usersList: usersList
            });
        }
        Users.addUser(data, addUserToList)
    }
    deleteuser = () =>{
        console.log(this.state.login)
        const removeUserFromList = (userLogin) =>{
            const {usersList} = this.state;
            this.setState({
                usersList: removeElementFromList(usersList, 'login', userLogin)
            });
        }
        Users.deleteUser(this.state.forDelete, removeUserFromList);
        this.refs.delete.popUp_activation({
            active: false
        })
        this.setState({
            forDelete: null,
        });
    }
    callPopUp = (e) =>{
        this.setState({
            forDelete: e,
        });
        this.ConfirmTextforRemove = l_v.are_you_sure  + e.userlogin +'?';
        this.refs.delete.popUp_activation({
            active: true
        })
    }
    render(){
        const { start_index, end_index, count_lines_for_view, totalElements, loginForQrCode } = this.state;

        return(
            <div>
                <Helmet title =  {l_v.client_customer} />

                <Navigation />
                <div className='container content'>
                  <h1 className="page_name">{l_v.client_customer}</h1>
                  <Search   placeholder = {l_v.search}
                            name = 'login'
                            onChange = {this.valueForSort}/>
                    <BtnAdd pasword_placeholder = {l_v.enter_password}
                            name_placeholder = {l_v.enter_first_name}
                            btnname = {l_v.add_user}
                            dataFromform = {this.addUser}
                            kindUser = {'USER'}
                            defaultgroup = {this.props.login}
                            display = {this.state.display}>
                    </BtnAdd>

                  <UserList data = {this.state.usersList} display = {this.state.display} delete = {this.callPopUp}/>
                  <TableFooter  changePage = {this.changePage}
                                show_pagination = {totalElements?true:false}
                                count_of_available_lines = {totalElements}
                                count_lines_for_view = {count_lines_for_view}
                                setCountsLineForView = {this.updateCountForView}
                                start_index = {start_index}
                                end_index = {end_index} />
                </div>
                <ConfirmRemove  ref =           'delete'
                                    confirmText =   {this.ConfirmTextforRemove }
                                    clear =         {()=>this.setState({
                                                            forDelete: undefined
                                                    })}
                                    remove =    {this.deleteuser}/>
                <Pop_up head_text =     {'QR code for auth - ' + loginForQrCode}
                        head_back =     {{backgroundColor:"#00bcd4", color: 'white', fill: 'white'}}
                        ref = {(popup) => this.qrPopup = popup}
                        popUp_size =    {{maxWidth: '99%', width: '370px', height: '386px'}}>
                        <div className = 'qr_code_container'>
                            <div id="qrcode" style={{width:'280px',height:'280px' }}></div>
                        </div>

                </Pop_up>
            </div>
          )
    }
}


export default connect(
      state => ({
        login: state.authorizationR.login,
        token: state.authorizationR.token,
        data: state.clientsR.UserData,
        kindUser: state.authorizationR.kindUser,
      })
)(UsersPage)


class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                        data: [],
                        column_height: '',
                        table_content_width: '',
                        win_width: '',
                    }

    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.data,
        })
    }
    setwidth = (data) =>{
        let head_line = data.head_line/data.table * 100;
        let win_width = window.innerWidth;
        let table_content = 100 - head_line;
        if (win_width < 720){
            this.setState({
                column_height: data.column_height+1,
                table_content_width: table_content,
            })
        }
        this.setState({
            win_width: win_width,
        })
    }
    scroolTop = () =>{
        let height = this.state.column_height;
        let name = document.querySelector('.table_content');
        name.scrollTop -= height;
    }
    scroolBottom = () =>{
        let height = this.state.column_height;
        let name = document.querySelector('.table_content');
        name.scrollTop += height;
    }
    render(){
        const { belongsTo, blocked, descritption, login,
                column_height, table_content_width, data} = this.state;
        const { display } = this.props;
        //style ={tableStyles(column_height, table_content_width)}
        return  !arrayIsEmpty(data) ?
                <p className='nodata'>{l_v.no_data}</p> :
                <div className = 'userlist'>
                    <TableHeader display = {display}/>
                    <div className = 'table_content'
                          >
                        <div className='triggerUp visible_on_mobile'
                             onClick={this.scroolTop}
                        >▲</div>
                        <div className='triggerDown visible_on_mobile'
                            onClick={this.scroolBottom}
                        >▼</div>
                        <TableContent   funcForDelete = {this.props.delete}
                                        updateTableSize = {this.setwidth}
                                        userlist = {data}
                                        display = {display}/>
                </div>
            </div>
    }
}

function TableContent(props) {
    const {userlist} = props;
    return userlist.map( key =>  <OneUser   key =           {key.login}
                                            {...key}
                                            returnwidth =   {props.updateTableSize}
                                            delete =        {props.funcForDelete}
                                            display =       {props.display}/> )
}

function TableHeader(props) {
    return  <div className = "oneLine head_line">
                <div className = "cell_login cell">
                    {l_v.login}
                </div>
                <div className = "cell_byWhom cell">
                    {l_v.account}
                </div>
                <div className = "cell_desc cell">
                    {l_v.description}
                </div>
                <div className = "cell_created cell">
                    {l_v.created}
                </div>
                <div className = "cell_blocked cell">
                    {l_v.blocking}
                </div>
{/*                <div className = "cell_edit cell" style={{display: props.display}}>

                </div>
                <div className = "cell_delete cell" style={{display: props.display}}>

                </div>*/}
            </div>
}

function tableStyles(column_height, table_content_width) {
    return  {
                height: column_height+'px',
                width: table_content_width + '%'
            }
}