import React, { Component, Fragment }      from 'react';
import TableFooter, { TableFooterMethods } from "modules/devicePage/TableFooter.jsx"
import trailersApi                         from "actions/trailersApi.js"
import Pop_up                              from "modules/component/Pop_up.jsx"
import Preloader                           from "modules/preloader.jsx"
import {
	arrayIsEmpty,
	removeElementFromList,
	copyOfObject
}                                          from "actions/ActionCreator.jsx"
import ConfirmRemove                       from '../ConfirmRemove.jsx'
import { editImg, deleteImg, closeImg }    from '../img.jsx'
import _                                   from "lodash"
import Search                              from 'modules/component/Search.jsx'
import { getPage }                         from "../Devices.jsx"
import l_v 				   from 'store/languages.jsx'

export default class Trailers extends TableFooterMethods(Component) {
	constructor(props){
		super(props);
		this.state = {
			trailersList: null,
			id:           false,
			idSerial:     false,
			name:         false,
			trailerType:  false,
			...this.paginationState
		}
		this.initialState = this.state;
		this.props.login;
		this.handleFilterChange = _.debounce(this.handleFilterChange, 400);
		this.sortParams = ['trailerType', 'name', 'idSerial', 'id'];
		this.searchQuery = '';
	}
	componentDidMount() {
		this.doRequest()
		// trailersApi.getTrailersListPaginated(this.props.login, trailersList => this.setState({trailersList: trailersList}))
	}
	getParams = () =>{
		const {count_lines_for_view, start_index, end_index, totalElements} = this.state;
		return this.createParams({
			page: getPage(totalElements, end_index, count_lines_for_view),
			size: count_lines_for_view,
			owner: this.props.login,
			// groupId: this.groupIdForFilter,
			searchParam: this.searchQuery
		})
	}
	doRequest = () => {
		const accountType = this.props.accountType || this.props.accountTypeOfUser;
		const setTrailers = (contentInfo) => this.setState({
			trailersList: contentInfo.content,
			totalElements: contentInfo.totalElements
		});
		trailersApi.getTrailersListPaginated(this.getParams(), setTrailers)

	}
	handleFilterChange = (searchQuery) => {
		this.searchQuery = searchQuery;
		this.doRequest();
	}
	addNewTrailerToList = (trailerInfo) => {
		const {trailersList} = this.state;
		trailersList.push(trailerInfo)
		this.setState({
			trailersList: trailersList
		});
	}
	updateTrailerInList = (newTrailerInfo) => {
		const {trailersList} = this.state;
		const indexInList = trailersList.findIndex(el => el.vehicleId == newTrailerInfo.vehicleId);
		trailersList[indexInList] = newTrailerInfo;
		this.setState({
			trailersList: copyOfObject(trailersList),
			trailerForEdit: null

		});
	}
	callDeleteTrailer = (trailerInfo) =>{
		const {vehicleId, name} = trailerInfo;
		this.ConfirmTextforRemove = `${l_v.are_you_sure_want_delete_trailer} - ${name}?`
		this.setState({
			tralerForDelete: trailerInfo
		});
		this.confirmDelete.open()
	}
	deleteTrailer = () => {
		const {trailersList, tralerForDelete} = this.state;
		const afterDeleteFromServer = () => {
			this.setState({
				trailersList: 	 removeElementFromList(trailersList, 'vehicleId', tralerForDelete.vehicleId),
				tralerForDelete: null
			});
		}
		trailersApi.deleteTrailer(tralerForDelete.vehicleId, afterDeleteFromServer)
	}
	callEditTrailer = (trailerForEdit) => {
		this.setState({
			trailerForEdit: trailerForEdit
		});
	}
	setSortParam = (sortParameterName) => {
		this.setState({
			[sortParameterName]: !this.state[sortParameterName],
			selectedParam: sortParameterName,
			...this.createListDisabledSort(sortParameterName, !this.state[sortParameterName])
		}, this.doRequest);
	}
	render() {
		const {login} = this.props;
		const {trailersList, trailerForEdit} = this.state;
		const {
			start_index,
			end_index,
			count_lines_for_view,
			totalElements,
			id,
			idSerial,
			name,
			trailerType,
			selectedParam,
		} = this.state;

		return 	<div className = 'trailers_info container content'>
					<h1 className = 'page_name'>{l_v.trailer_control}</h1>
					<div className = 'container_input_and_button'>
						<Search placeholder = 	{l_v.enter_trailer_id}
								onChange =		{(value)=>this.handleFilterChange(value)}>
						</Search>
						<AddTrailer addTrailerToList = {this.addNewTrailerToList} owner = {login}/>
					</div>
					<div className = 'trailers_list'>
						<TableHeader 	id =            { id }
										idSerial =      { idSerial }
										name =          { name }
										selectedParam = {selectedParam}
										setSortParam = 	{this.setSortParam}
										trailerType =   { trailerType }/>
						<TrailersList 	trailersList =  { trailersList }
										edit =          { this.callEditTrailer }
										deleteTrailer = { this.callDeleteTrailer } />
					</div>
					<TableFooter 	changePage = {this.changePage}
									show_pagination = {totalElements?true:false}
					  				count_of_available_lines = {totalElements}
					  				count_lines_for_view = {count_lines_for_view}
					  				setCountsLineForView = {this.updateCountForView}
					  				start_index = {start_index}
					  				end_index = {end_index} />
					<ConfirmRemove 		ref = 			{confirmDelete => this.confirmDelete = confirmDelete}
										confirmText = 	{this.ConfirmTextforRemove }
										clear = 		{()=>this.setState({ forDelete: undefined })}
										remove = 		{this.deleteTrailer}/>
					<EditTrailerForm 	updateTrailerInList = {this.updateTrailerInList}
										clearTrailerForEdit = {()=>this.setState({ trailerForEdit: null})}
										trailerForEdit =      {trailerForEdit} />

				</div>
	}
}




class AddTrailer extends Component {
	constructor(props){
		super(props);
		const {owner} = this.props;
		this.state = {
		    name:        "",
		    owner:       owner,
		    type:        "TRAILER",
		    trailerType: "AGRICULTURAL",
		    idSerial:    '',
		    width:       '',
		    period1:     30,
		    period2:     300
		}
		this.initialState = this.state;
	}
	componentDidMount() {
		this.mounted = true;
		// this.trailerForm.open()
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	enterToState = (e) =>{
		let value = e.target.value;
		let name = e.target.name;
		if(name == 'idSerial' ){
			value = value.replace(/[^\d]/g, '')
		}
		this.setState({
			[name]: value
		})
	}
	saveTrailer = () => {
		const afterSave = (trailerInfo) => {
			this.props.addTrailerToList(trailerInfo);
			if(this.mounted) this.preloader.hide()
			this.trailerForm.close();
			this.setState(this.initialState);
		}
		this.preloader.show();
		trailersApi.addTrailer(this.state, afterSave)
	}
	cancel  = () => {
		this.setState(this.initialState);
		this.trailerForm.close()
	}
	render() {
		return 	<Fragment>
					<button type = 		"button"
							className = 'btn_black add_trailer_btn'
							onClick = 	{() => this.trailerForm.open()}>
							{l_v.add_trailer}
					</button>
					<Pop_up head_text = 	{l_v.add_trailer}
		    	            ref = 			{(popup) => this.trailerForm = popup}
		    	            head_back = 	{{backgroundColor:"#666666", color: 'white', fill: 'white'}}
		    	           	popUp_size = 	{{maxWidth: '99%', width: '500px', height: '450px'}}>
		    	           	<Fragment>
			    	           	<AddTrailerFormСontent 	enterToState = {this.enterToState}
			    	           							{...this.state}
			    	           							cancel = {this.cancel}/>
			    	           	<div className = 'trailer_form_footer'>
			    	           		<button className = 'btn_black'
			    	           				onClick = {this.saveTrailer}>
			    	           				{l_v.save}
			    	           		</button>
			    	           	</div>
		    	           	</Fragment>
					</Pop_up>
					<Preloader 	handle = {true}
					 			ref = { preloader => this.preloader = preloader }/>
				</Fragment>
	}
}

class EditTrailerForm extends AddTrailer {
	constructor(props){
		super(props);
		const { trailerForEdit } = this.props;
		this.state = {
		};
		this.initialState = this.state;
	}
	componentWillReceiveProps(nextProps) {
		const {trailerForEdit} = nextProps;
		if(trailerForEdit){
			this.setState({
				    name:        trailerForEdit.name,
				    owner:       trailerForEdit.owner,
				    idSerial:    trailerForEdit.idSerial,
				    width:       trailerForEdit.width,
				    trailerType: trailerForEdit.trailerType,
				    period1:     trailerForEdit.period1,
				    period2:     trailerForEdit.period2,
				    vehicleId:   trailerForEdit.vehicleId
			});
			this.trailerForm ? this.trailerForm.open() : null

		}
	}
	componentDidMount() {
		this.mounted = true;
	}
	componentWillUnmount() {
		this.mounted = false;
	}
	saveTrailer = () => {
		const afterSave = (trailerInfo) => {
			if(this.mounted) this.preloader.hide()
			this.trailerForm.close();
			console.log("%c trailerInfo", coCSS, trailerInfo)
			this.props.updateTrailerInList(trailerInfo);
			this.setState(this.initialState);
		}
		this.preloader.show();
		trailersApi.updateTrailer(this.state, afterSave)
	}
	render() {
		return 	<Fragment>
					<Pop_up head_text = 	{`${l_v.trailer_editing} - ${this.state.name}` }
		    	            ref = 			{(popup) => this.trailerForm = popup}
		    	            onClose = 		{this.props.clearTrailerForEdit}
		    	            head_back = 	{{backgroundColor:"#666666", color: 'white', fill: 'white'}}
		    	           	popUp_size = 	{{maxWidth: '99%', width: '500px', height: '450px'}}>
		    	           	<Fragment>
			    	           	<AddTrailerFormСontent 	enterToState = {this.enterToState}
			    	           							{...this.state}
			    	           							cancel = {this.cancel}/>
			    	           	<div className = 'trailer_form_footer'>
			    	           		<button className = 'btn_black'
			    	           				onClick = {this.saveTrailer}>
			    	           				{l_v.save}
			    	           		</button>
			    	           	</div>
		    	           	</Fragment>
					</Pop_up>
					<Preloader 	handle = {true}
					 			ref = { preloader => this.preloader = preloader }/>
				</Fragment>
	}
}

function AddTrailerFormСontent(props) {

	return 	<div className ='trailer_form'>
				<label> <span>{l_v.trailer_name}</span>
					<input 	type = 			'text'
							name = 			'name'
							className = 	"input"
							value = 		{props.name}
							placeholder = 	"Scania trailer"
							onChange = 		{props.enterToState} />
				</label>
				<label> <span>{l_v.trailer_serial_number} </span>
					<input 	type = 			"text"
							key =			"idSerial"
							name =			"idSerial"
							value = 		{props.idSerial}
							className = 	{'input '}
							placeholder = 	"58934789*"
							onChange = 		{props.enterToState} />
				</label>
				<label ><span>{l_v.trailer_width_meters} </span>
					<input 	type = 			"text"
							key =			"width"
							name =			"width"
							value = 		{props.width}
							className = 	{'input '}
							placeholder = 	"2.5"
							onChange = 		{props.enterToState} />
				</label>
				<label> <span>{l_v.transmission_period_day} </span>
					<input 	name =        "period1"
							value =       { props.period1 }
							type =        "number"
							min =         { 1 }
							className =   { 'input ' }
							placeholder = "30"
							onChange =    { props.enterToState }/>
										</label>
				<label> <span>{l_v.transmission_period_night}</span>
					<input 	name = 					"period1"
							value = 				{props.period1}
							type = 					"number"
							min = 					{1}
							className = 			{'input ' }
							placeholder = 			"250"
							onChange = 				{props.enterToState} />
				</label>
			</div>
}

function TrailersList(props){
	const {trailersList, edit, deleteTrailer} = props;
	return 	arrayIsEmpty(trailersList) ?
			<div className = 'table_content'>
				{
					trailersList.map( el => <OneTrailer_OneLine {...el}
					                 							key = 			{el.vehicleId}
				                   								deleteTrailer = {deleteTrailer}
				                   								edit = 			{edit} /> )
				}
			</div> :
			<div className = 'no_data'>{l_v.no_data}</div>
}

function TableHeader(props) {
	const visibilityI = (nameOfColumn) => nameOfColumn == props.selectedParam ? {display: 'block'} : {display: 'none'}

	return 	<div className = 'oneLine head_line'>
				<div className="cell_id cell" onClick = {()=>props.setSortParam('id')}>
					ID
					<i className = 'sort_arrow' style = {visibilityI('id')}>{props.id ? '↑' : '↓'}</i>
				</div>
				<div className="cell_serialId cell" onClick = {()=>props.setSortParam('idSerial')}>
					Serial Id
					<i className = 'sort_arrow' style = {visibilityI('idSerial')}>{props.idSerial ? '↑' : '↓'}</i>
				</div>
				<div className="cell cell_name" onClick = {()=>props.setSortParam('name')}>
					{l_v.title}
					<i className = 'sort_arrow' style = {visibilityI('name')}>{props.name ? '↑' : '↓'}</i>
				</div>
				<div className="cell cell_trailer_type" onClick = {()=>props.setSortParam('trailerType')}>
					{l_v.trailer_type}
					<i className = 'sort_arrow' style = {visibilityI('trailerType')}>{props.trailerType ? '↑' : '↓'}</i>
				</div>
				<div className="cell cell_trailer_width">{l_v.width}</div>
				<div className="cell_edit cell"></div>
				<div className="cell_delete cell"></div>
			</div>
}

function OneTrailer_OneLine(props) {
	const {edit, deleteTrailer} = props;
	return 	<div className = 'oneLine'>
				<div className="cell_id cell">{props.vehicleId}</div>
				<div className="cell_serialId cell">{props.idSerial}</div>
				<div className="cell cell_name">{props.name}</div>
				<div className="cell cell_trailer_type">{props.trailerType}</div>
				<div className="cell cell_trailer_width">{props.width}</div>
				<div className = "cell_edit cell">
					{
						editImg("edit", () => edit(props) )
					}
				</div>
				<div className = "cell_delete cell">
					{
						deleteImg("delete", () => deleteTrailer({
							 vehicleId:    props.vehicleId,
							 name:  props.name
						}))
					}

				</div>
			</div>
}
// {
//     "name":"ArturTrailer274",
//     "owner":"test2419",
//     "type":"TRAILER",
//     "idSerial": 15185452,
//     "width": 10,
//     "trailerType": "AGRICULTURAL",
//     "period1": 30,
//     "period2": 300
// }




